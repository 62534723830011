import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Paper from "@mui/material/Paper";
import "./style.css";
import { Typography } from "@mui/material";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
      height: "6px",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

// for connector pipe
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  // height:5,
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      // backgroundImage:
      //   'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      backgroundColor: "#33D67C",
      // height:5
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      // backgroundImage:,
      backgroundColor: "#33D67C",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 5,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));
// text inside circle
const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",

  //
  ...(ownerState.active && {
    backgroundColor: "#FAC915",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#33D67C",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  const icons = {
    1: "01",
    2: "02",
    3: "03",
    4: "04",
    5: "05",
  };

  return (
    <ColorlibStepIconRoot
      sx={{
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "18px",
        fontFamily: "DM sans",
      }}
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
  "Basic Details",
  "Advance Details",
  "Declaration",
  "Publishing Details",
  "Summary",
];

// const isStepSucsess = (step) => {
//   return step === 0;
// };

export default function CustomizedSteppers({ page }) {
  return (
    <Paper elevation={1} sx={{ padding: { xs: "20px 20px", sm: "20px 50px" } }}>
      <Stack sx={{ width: "100%" }} spacing={4}>
        <Stepper
          alternativeLabel
          activeStep={page}
          connector={<ColorlibConnector />}
        >
          {/* {steps.map((label,key) => (


            <Step key={key} className="custom-stepper-label">
             
                <StepLabel StepIconComponent={ColorlibStepIcon} {...labelProps}  >
                  {label}
                </StepLabel>
            </Step>
          ))} */}

          {steps.map((label, index) => {
            // const labelProps = {};
            // if (isStepSucsess(index)) {
            //   labelProps.optional = (
            //     <Typography variant="caption" color="#33D67C">
            //       Alert message
            //     </Typography>
            //   );

            //   labelProps.error = true;
            // }

            return (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  // {...labelProps}
                >
                  <Paper
                    elevation={10}
                    sx={{
                      margin: "0 auto",
                      padding: "5px 20px",
                      width: "auto",
                      display: { xs: "none", sm: "inline-block" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "18px",
                        fontFamily: "DM sans",
                      }}
                    >
                      {label}
                    </Typography>
                  </Paper>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Stack>
    </Paper>
  );
}

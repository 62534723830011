import React from "react";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import Grid from "@mui/material/Grid";
import "./style.css";
import Typography from "@mui/material/Typography";
import CustomButton from "../../components/common/customButton/index";
import MangeDeshboard from "../../assets/img/static/whatDoWeDo.webp";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const Index = () => {
  const navigateSinguppage = useNavigate()
  const handleClick=()=>{
    navigateSinguppage('/signup/artist')
  }
  
  return (
    <Container sx={{ maxWidth:'1200px !important'}}>
      <Grid container spacing={{md:4,xs:4}} sx={{ marginTop:{md:"60px",xs:'0px'} , marginBottom: "60px" ,px:{xs:'0px',sm:'100px',md:'0px'} }} >
        <Grid item md={6} xs={12}>
          <Box
            className="manageimgBoxabout"
            sx={{ marginTop: { xs: "10px", md: "none" } }}
          >
            <Box className="manageBlueDotContainer">
              <Box className="manageBlueDot"></Box>
              <Box className="manageDot"></Box>
              <Box className="manageDot"></Box>
            </Box>
            <img
              src={MangeDeshboard}
              alt="image"
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box sx={{ marginTop: { xs: "0px", md: "-70px" } }}>
            <Typography variant="p" className="homemanageBoldText">
            WHAT DO WE DO
            </Typography>
          </Box>
          <Box sx={{ maxWidth: { xs: "100%", md: "90%" }, marginTop: "20px" }}>
            <Box>
            <Typography variant="p" className="homemanageNormalTextabout">
            Deliver My Tune is India’s first music distribution platform whose foundation stone was laid back in 2015 with the sole aim to help Indian artist, musicians and rockstars to distribute their songs on platforms like Spotify, JioSaavan, Itunes and 60 more.
            </Typography>
            </Box>
           <Box sx={{marginTop:'30px'}}>
           <Typography variant="p" className="homemanageNormalTextabout" >
            Whether you are a independent musician or a label, DeliverMyTune will assist you distribute your song to multiple platforms. Our state of the art technology and artist dashboard makes it easy for anyone to simply manage their releases, checkout their monthly earning and track back their royalties from multiple platforms.
            </Typography>
           </Box>
            <Box sx={{marginTop:'30px'}}>
            <Typography variant="p" className="homemanageNormalTextabout">
            We proud ourselves as the only platform who makes sure that artist remain the sole owner of their songs and thus no gimmickry action or conditions should refrain them from owning what is there already aka copyright.
            </Typography>
            </Box>
          </Box>
          <Link   href='/signup/artist'  underline="none"  > 
         
          <CustomButton
        //  onClick={handleshow}
         onClick={handleClick   }
         
            marginTop="15px"
            width="189px"
            height="54px"
            borderRadius="27px"
            border="1.35px solid #f36905!important;"
            fontWeight="700"
            btnText="Sign Up Here"
            buttonStyle='light-theme-btn'
          />
          </Link>
          
        </Grid>
      </Grid>
    </Container>
  );
};

export default Index;

import {
  Box,
  Card,
  Container,
  Dialog,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import headpone from "../../../assets/img/headpone.png";
import kameloc from "../../../assets/img/kam.png";
import lip from "../../../assets/img/lips.png";
import shoe from "../../../assets/img/shoe.png";
import star from "../../../assets/img/star.png";
import dotsone from "../../../assets/img/dotsone.png";
import dotstwo from "../../../assets/img/dotstwo.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import { useNavigate } from "react-router-dom";
import Form_Carrier from "./listenSecondForm";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import CustomButton from "../editportfoliosecondbtn";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AddIcon from "@mui/icons-material/Add";

const Index = ({ data, getOrder, tokenedit }) => {
  const [open, setOpen] = React.useState(false);
  const [viewmodal, setViewmodal] = useState([]);

  const handleClose = () => setOpen(false);
  const navigateSinguppage = useNavigate();
  const [number, setNumber] = useState(0);

  const handleOpen = (value) => {
    setOpen(true);
    setViewmodal(value);
  };

  const handleshow = () => {
    navigateSinguppage("/signup/artist");
  };
  const handleClick = () => {
    navigateSinguppage("/about");
  };

  function PaperComponent(props) {
    return <Paper sx={{ background: "#FF599D" }} {...props} />;
  }

  const slidesToShow = data?.listen.length < 2 ? data?.listen.length : 2;

  const settings = {
    fade: false,
    dots: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    initialSlide: 2,
    infinite: true,
    arrows: false,
    centerMode: true,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: slidesToShow,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
        },
      },

      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
          dots: false,
        },
      },
    ],
  };
  var obj = document.body; // obj=element for example body
  // bind mousewheel event on the mouseWheel function
  if (obj.addEventListener) {
    obj.addEventListener("DOMMouseScroll", mouseWheel, false);
    obj.addEventListener("mousewheel", mouseWheel, false);
  } else obj.onmousewheel = mouseWheel;
  function mouseWheel(e) {
    // disabling
    e = e ? e : window.event;
    if (e.ctrlKey) {
      if (e.preventDefault) e.preventDefault();
      else e.returnValue = false;
      return false;
    }
  }

  return (
    <>
      <Box
        id="listenrapper"
        sx={{ background: "#FF599D", position: "relative", minHeight: "700px" }}
      >
        <Box sx={{ position: "absolute", top: "0px", left: "0px" }}>
          <img src={dotsone} alt="" />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
            display: { xs: "none", sm: "block" },
          }}
        >
          <img src={dotstwo} alt="" />
        </Box>
        <Container>
          <Box
            textAlign="center"
            sx={{ display: "flex", justifyContent: "center", pt: 8 }}
          >
            <Box
              textAlign="center"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Typography
                className="textStroke"
                sx={{
                  color: "#FAC915",
                  fontSize: { xs: "40px", md: "64px" },
                  zIndex: "5",
                  fontFamily: "Anton",
                  transform: "matrix(1, 0.01, -0.29, 0.96, 0, 0) ",
                }}
              >
                Listen Music
              </Typography>
            </Box>
            <img
              src={headpone}
              alt=""
              style={{ marginLeft: "30px", zIndex: "5" }}
            />
          </Box>

          <Box
            className="lips"
            sx={{
              display: { xs: "none", sm: "inline-block" },
              right: { lg: "190px", md: "80px", sm: "20px" },
            }}
          >
            <img src={lip} alt="" />
          </Box>

          <Box
            sx={{
              // marginLeft: "20px",
              textAlign: "center",
              marginTop: { lg: "15px", md: "15px", xs: "0px" },
            }}
          >
            {tokenedit ? (
              <CustomButton
                btnText="Add"
                handleClick={() => {
                  handleOpen();
                  setNumber(0);
                }}
                startIcon={<AddIcon sx={{ marginRight: "5px" }} />}
                width="75px"
                height="30px"
                border="1px solid #ffffff !important"
                padding="15px"
              />
            ) : (
              ""
            )}
          </Box>

          <Box textAlign={"center"} sx={{ mt: 8 }}>
            {data?.listen?.length === 0 ? (
              <>
                <Box
                  sx={{
                    margin: "30px 0px",
                    padding: "0px !important",
                  }}
                >
                  {tokenedit ? (
                    <Box
                      sx={{
                        border: "2px dashed #00D8C0 ",
                        width: { md: "760px", xs: "280px" },
                        height: "210px !important",
                        textAlign: "center",
                        margin: "auto",
                      }}
                      onClick={() => {
                        handleOpen();
                        setNumber(0);
                      }}
                    >
                      <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                        <Typography
                          sx={{
                            color: "#00D8C0",
                            fontSize: "30px",
                            lineHeight: "20px",
                          }}
                        >
                          +
                        </Typography>
                        <Typography
                          sx={{
                            color: "#00D8C0",
                            fontSize: "20px",
                            fontWeight: "700",
                          }}
                        >
                          Add Your First Song Here
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        border: "2px dashed #00D8C0",
                        width: { md: "760px", xs: "280px" },
                        height: "210px !important",
                        textAlign: "center",
                        margin: "auto",
                      }}
                    >
                      <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                        {/* <Typography>+</Typography> */}
                        <Typography sx={{
                            color: "#00D8C0",
                            fontSize: "20px",
                            fontWeight: "700",
                          }}> Song is Empty</Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              <Slider className="listeslider" {...settings}>
                {data?.listen?.map((item, index) => (
                  <Box textAlign={"-webkit-center"}>
                    <Card
                      key={index}
                      sx={{
                        background: "#FCE23B",
                        mx: 2,
                        transform: "rotate(3deg)",
                        maxWidth: "500px",
                        p: 1.3,
                        borderRadius: "0px",
                        position: "relative",
                        my: 2,
                      }}
                    >
                      <Box sx={{ display: { xs: "block", sm: "flex" } }}>
                        <Box sx={{ height: "167px", width: "167px" }}>
                          <img
                            src={item?.listenimage}
                            style={{ height: "167px", width: "167px" }}
                            alt=""
                          />
                        </Box>
                        <Box>
                          <Box sx={{ pl: 1 }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{
                                  ml: 1,
                                  textAlign: "left",
                                  color: "#141414",
                                  fontSize: "21px !important",
                                  fontWeight: "600",
                                }}
                              >
                                {item?.listentitle}
                              </Typography>
                              {tokenedit ? (
                                <Box
                                  sx={{
                                    border: "1px solid #00D8C0",
                                    minWidth: "45px",
                                    height: "30px",
                                    borderRadius: "100px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    // position: "absolute",
                                    // top: "15px",
                                    // right: "10px",
                                    background: "#00D8C0",
                                  }}
                                  onClick={() => {
                                    handleOpen(item);
                                    setNumber(1);
                                  }}
                                >
                                  <ModeEditOutlinedIcon color="#00D8C0 !important" />
                                </Box>
                              ) : (
                                ""
                              )}
                            </Box>
                            <Typography
                              sx={{
                                ml: 1,
                                textAlign: "left",
                                color: "#00D8C0",
                                fontSize: "15px",
                                fontWeight: "600",
                                mt: 1,
                              }}
                            >
                              {item?.listensinger}
                            </Typography>
                            <Box sx={{ mt: 1.5 }}>
                              <audio controls className="audiotag">
                                <source
                                  src={item?.listensong}
                                  type="audio/wav"
                                />
                              </audio>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                ))}
                {data?.tracks?.map((value, index) => (
                  <Box textAlign={"-webkit-center"}>
                    <Card
                      key={index}
                      sx={{
                        background: "#FCE23B",
                        mx: 2,
                        transform: "rotate(3deg)",
                        maxWidth: "500px",
                        p: 1.3,
                        borderRadius: "0px",
                        my: 2,
                      }}
                    >
                      <Box sx={{ display: { xs: "block", sm: "flex" } }}>
                        <Box sx={{ height: "167px", width: "167px" }}>
                          <img
                            src={value?.cover}
                            style={{ height: "167px", width: "167px" }}
                            alt=""
                          />
                        </Box>
                        <Box>
                          <Box sx={{ pl: 1 }}>
                            <Typography
                              sx={{
                                ml: 1,
                                textAlign: "left",
                                color: "#141414",
                                fontSize: "21px !important",
                                fontWeight: "600",
                              }}
                            >
                              {value?.name}
                            </Typography>
                            <Typography
                              sx={{
                                ml: 1,
                                textAlign: "left",
                                color: "#00D8C0",
                                fontSize: "15px",
                                fontWeight: "600",
                                mt: 1,
                              }}
                            >
                              {value?.tracks.length > 0
                                ? value?.tracks[0]?.artists[0]?.lastname
                                  ? value?.tracks[0]?.artists[0]?.name +
                                    " " +
                                    value?.tracks[0]?.artists[0]?.lastname
                                  : value?.tracks[0]?.artists[0]?.name
                                : ""}
                            </Typography>
                            <Box sx={{ mt: 1.5 }}>
                              <audio controls className="audiotag">
                                <source
                                  src={
                                    value?.tracks?.length > 0
                                      ? value?.tracks[0]?.file
                                      : ""
                                  }
                                  type="audio/wav"
                                />
                              </audio>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                ))}
              </Slider>
            )}
          </Box>

          <Box sx={{ position: "absolute", bottom: "0px", right: "40% " }}>
            <img src={shoe} alt="" />
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: "0px",
              right: "0px",
              display: { xs: "none", sm: "block" },
            }}
          >
            <img src={star} alt="" />
          </Box>
        </Container>
      </Box>

      <div>
        <Dialog
          height="auto"
          sx={{}}
          style={{ zIndex: "9999922" }}
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <Box textAlign="end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <DialogTitle
              style={{ cursor: "move" }}
              align="center"
              mt="-30px"
              padding="0px !important"
              id="draggable-dialog-title"
            >
              Listen
            </DialogTitle>
          </Box>

          <Form_Carrier
            view={viewmodal}
            handleClose={handleClose}
            getOrder={getOrder}
            number={number}
            tokenedit={tokenedit}
          />
        </Dialog>
      </div>
      {}
    </>
  );
};

export default Index;

import {
  Box,
  Container,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import "./index.css";
import shoeTwo from "../../../assets/img/shoeTwo.png";
import dotsFour from "../../../assets/img/dotsFour.png";
import raper from "../../../assets/img/raper.png";
import playOne from "../../../assets/img/playOne.png";
import headphoneTwo from "../../../assets/img/headphoneTwo.png";
import hiphop from "../../../assets/img/hiphop.png";
import skates from "../../../assets/img/skates.png";
import triangle from "../../../assets/img/triangle.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ReactPlayer from "react-player";
import { useState } from "react";
import { useEffect } from "react";
import VideoEditForm from "./videoEditForm";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../editportfoliosecondbtn";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import test from '../../../assets/img/profile/test.png'

function PaperComponent(props) {
  return <Paper sx={{ background: "#FF599D", overflow: "hidden" }} {...props} />;

}

const Index = ({ data, getOrder, tokenedit }) => {
  const [videoUrl, setVideoUrl] = useState([]);
  const [videoplay, setVideoplay] = useState();
  const [number, setNumber] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [pics, setPics] = useState([]);
  const [openPlayer, setOpenPlayer] = React.useState(false);

  const removeImage = (id) => {
    setPics((oldState) => oldState.filter((item) => item.id !== id));
  };
  // useEffect(() => {
  //   setPics(Student_Data);
  // }, []);
  const handleOpen = (item) => {
    setOpen(true);
    setOpenPlayer(false);
    setVideoUrl(item);
  };

  const handleClose = () => setOpen(false);

  const slidesToShow = data?.videos?.length < 2 ? data?.videos?.length : 2.2;
  const settings = {
    fade: false,
    dots: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    initialSlide: 2,
    infinite: true,
    arrows: false,
    centerMode: true,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: slidesToShow,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: true,
          centerMode: false,
        },
      },
      // {
      //   breakpoint: 1000,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //     initialSlide: 1,
      //     centerMode: false,
      //   },
      // },

      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
          dots: false,
        },
      },
    ],
  };

  const handleClickOpen = (item) => {
    setOpenPlayer(true);
    setVideoplay(item);
  };

  const handleCloseplayer = () => {
    setOpenPlayer(false);
  };
  return (
    <>
      <Box
        sx={{ background: "#FF599D", position: "relative", minHeight: "800px" }}
      >
        <Box
          sx={{
            textAlign: "center",
            pt: 4,
            display: { xs: "none", sm: "block" },
          }}
        >
          <img
            src={triangle}
            alt=""
            width={"100px"}
            style={{ zIndex: "-1000000" }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "-13px",
            left: "-16px",
            mt: 2,
            ml: 2,
            display: { xs: "none", sm: "block" },
          }}
        >
          <img
            src={shoeTwo}
            alt=""
            width={"260px"}
            style={{ zIndex: "-1000000" }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "40px",
            right: "25%",
            mt: 2,
            ml: 2,
            display: { xs: "none", lg: "block" },
          }}
        >
          <img
            src={headphoneTwo}
            alt=""
            width={"160px"}
            style={{ zIndex: "-1000000" }}
          />
        </Box>
        <Box
          sx={{ position: "absolute", top: "0px", right: "0px", mt: 2, ml: 2 }}
        >
          <img
            src={hiphop}
            alt=""
            width={"350px"}
            style={{ zIndex: "-1000000" }}
          />
        </Box>

        <Container>
          <Box
            textAlign="center"
            sx={{ display: "flex", justifyContent: "center", pt: 8 }}
          >
            <Typography
              className="textStroke"
              sx={{
                color: "#FAC915",
                fontSize: { xs: "40px", md: "64px" },
                zIndex: "5",
                fontFamily: "Anton",
                transform: "matrix(1, 0.01, -0.29, 0.96, 0, 0) ",
              }}
            >
              Latest Videos
            </Typography>
          </Box>
          <Box
            sx={{
              textAlign: "center",
              marginLeft: { md: "20px", xs: "0px" },
              marginTop: { lg: "15px", md: "15px", xs: "0px" },
            }}
          >
            {tokenedit ? (
              <CustomButton
                btnText="Add"
                handleClick={() => {
                  handleOpen();
                  setNumber(0);
                }}
                startIcon={<AddIcon sx={{ marginRight: "5px" }} />}
                width="75px"
                height="30px"
                border="1px solid #ffffff !important"
                padding="15px !important"
                fontSize="14px !important"
                fontWeight="600 !important"
              />
            ) : (
              ""
            )}
          </Box>

          <Box sx={{ mt: 8 }}>
            {data?.videos?.length === 0 ? (
              <>
                <Box
                  sx={{
                    margin: "30px 0px",
                    padding: "0px !important",
                  }}
                >
                  {tokenedit ? (
                    <Box
                      sx={{
                        border: "2px dashed #00D8C0 ",
                        width: { md: "760px", xs: "280px" },
                        height: "210px !important",
                        textAlign: "center",
                        margin: "auto",
                      }}
                      onClick={() => {
                        handleOpen();
                        setNumber(0);
                      }}
                    >
                      <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                        <Typography
                          sx={{
                            color: "#00D8C0",
                            fontSize: "30px",
                            lineHeight: "20px",
                          }}
                        >
                          +
                        </Typography>
                        <Typography
                          sx={{
                            color: "#00D8C0",
                            fontSize: "20px",
                            fontWeight: "700",
                          }}
                        >
                          Add Your First Video Here
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        border: "2px dashed #00D8C0",
                        width: { md: "760px", xs: "280px" },
                        height: "210px !important",
                        textAlign: "center",
                        margin: "auto",
                      }}
                    >
                      <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                        {/* <Typography>X</Typography> */}
                        <Typography sx={{
                            color: "#00D8C0",
                            fontSize: "20px",
                            fontWeight: "700",
                          }}>Video is Empty</Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              <>
                <Slider className="videosSlider" {...settings}>
                {data?.videos?.map((item, index) => (
                  <Box textAlign="-webkit-center">
                    <Box
                      key={index}
                      className="rapperVideo"
                      sx={{
                        maxWidth: "450px",
                        maxHeight: "560px",
                        transform: "rotate(3deg)",
                        position: "relative",
                        m: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundImage: "url(" + item?.bannerImage + ")",
                          width: { sm: "350px", xs: "280px" },
                          backgroundSize: {xs:'100% 100%', sm:'100% 100%'},
                          height: { sm: "430px", xs: "330px" },
                          position: "relative"
                        }}
                      >
                        {tokenedit ? (
                          <Box

                            sx={{
                              marginLeft: {
                                lg: "-90px !important",
                                xs: "-90px !important",

                              },
                              position: "absolute", top: { md: "50px", xs: "70px" }, right: { xs: "7px" },
                              marginTop: { lg: "-44px", md: "-44px", xs: "-70px" },
                              // display: { lg: "block", xs: "flex" },
                            }}
                            onClick={() => {
                              handleOpen(item);
                              setNumber(1);
                            }}
                          >
                          
                            <ModeEditOutlinedIcon
                              className="eddtBtn"
                              sx={{ marginRight: "5px" }}
                            />
                          </Box>
                        ) : (
                          ""
                        )}
                        <Box
                          onClick={() => {
                            handleClickOpen(item);
                          }}
                          sx={{}}
                        >
                          <img src={playOne} alt="" className="playimage" />

                          <Typography className="playvideo">
                            Play Video
                          </Typography>
                          <hr className="playvideo" style={{ width: "80px" }} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
                
              </Slider>
          

              </>
            )}
          </Box>
        </Container>
        <Box pt={12} sx={{ display: { sm: "none", xs: "block" } }}></Box>
        <Box
          sx={{ textAlign: "end", mr: 8, display: { xs: "none", sm: "block" } }}
        >
          <img
            src={skates}
            alt=""
            width={"180px"}
            style={{ zIndex: "-1000000" }}
          />
        </Box>
        <Dialog open={openPlayer} onClose={handleCloseplayer} maxWidth="lg" sx={{ overflow: "hidden !important", }}>
        
          <ReactPlayer url={videoplay?.youtubelink} controls={true} className='videoplayer' />
        </Dialog>
      </Box>

      <div>
        <Dialog
          height="auto"
          style={{ zIndex: "9999922" }}
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <Box textAlign="end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <DialogTitle
              style={{ cursor: "move" }}
              align="center"
              mt="-50px"
              id="draggable-dialog-title"
              fontFamily="DM Sans"
            >
              Upload Youtube Video
            </DialogTitle>
          </Box>

          <VideoEditForm
            view={videoUrl}
            handleClose={handleClose}
            getOrder={getOrder}
            number={number}
            tokenedit={tokenedit}
          />
        </Dialog>
      </div>
    </>
  );
};

export default Index;

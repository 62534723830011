import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "@mui/material/TextField";
import "./style.css";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { useForgetPasswordMutation } from "../../redux/services/login";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

const theme = createTheme();
const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

export default function SignIn() {
  const [forget, responseInfo] = useForgetPasswordMutation  ();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit:async (values) => {
      const payload = {
        email: values.email,
      };
      await axios
        .post(
          `${process.env.REACT_APP_DMT_API_PATH}/authentication/local/forgot`,
          payload
        )
        .then((res)=>{
         
          toast.success("An email has been sent to your account if it exist!")
        })
      // if (responseInfo.isLoading) return <div>Loading....</div>;
      // if (responseInfo.isError)
      //   return <h1>An error occured {responseInfo.error.error}</h1>;
      //   forget(payload);
      // console.log("Success: ", responseInfo.isSuccess);
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" sx={{ textAlign: "-webkit-center" }}>
        <Paper className="paper-form-forgot" component={Box} marginTop="80px">
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            padding="1rem 1.25rem;"
          >
            <Typography variant="h3" className="forgot-data">
              Forget password
            </Typography>
            <Box sx={{ marginTop: "20px", paddingLeft: "1.25rem" }}>
              <Typography
                sx={{
                  textAlign: "start",
                  color: "black",
                  fontFamily: "Open Sans, sans-serif",
                  fontSize: "0.75rem",
                  fontWeight: 700,
                  lineHeight: "1rem",
                }}
              >
                Email
              </Typography>
              <TextField
                fullWidth
                size="small"
                id="email"
                name="email"
                placeholder="Email *"
                sx={{ background: "white",marginTop:'4px',border:'1px solid #1b1b38',borderRadius:'4px' }}
                value={formik.values.email}
                onChange={formik.handleChange}
                // error={formik.touched.email && Boolean(formik.errors.email)}
                // helperText={formik.touched.email && formik.errors.email}
              />
                   {formik.touched.email && formik.errors.email ? <Typography sx={{textAlign:'start'}} color='error'>{formik.errors.email}</Typography> : null}

            </Box>
            <Box textAlign="center" marginTop="25px" marginBottom="15px">
              <Button className="submit-btn" type="submit">
                <ClearIcon />
                Submit
              </Button>
            </Box>
            <Box
              sx={{
                textAlign: "center",
                marginTop: "30px",
                marginBottom: "20px",
              }}
            >
              <Typography className="gotologin">
                {" "}
                <Link
                  className="link-btn"
                  underline="hover"
                  color="inherit"
                  to="/login"
                >
                  {" "}
                  Go to Login
                </Link>
              </Typography>
            </Box>
            <ToastContainer />
          </Box>

        </Paper>
      </Container>
    </ThemeProvider>
  );
}

import React, { useEffect, useState } from "react";
import CustomButton from "../common/customButton/index";
import AddIcon from "@mui/icons-material/Add";
import { useDropzone } from "react-dropzone";
import MusicIcon from "../../assets/img/musicIcon.svg";
import Play from "../../assets/img/play.png";
import "../advancedDetails/index.css";
import Tracks from "../../components/tracks/index";
import ClearIcon from "@mui/icons-material/Clear";
import ChangeSong from "../changeSong/index";
import Coverfile from "./coverfile";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  OutlinedInput,
  RadioGroup,
  TextField,
  Typography,
  Radio,
  MenuItem,
  Checkbox,
  Card,
} from "@mui/material";
import * as yup from "yup";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

export const validationSchema = yup.object().shape({
  recordLabel: yup.string().required("required"),
});

const previewStart = [
  {
    value: "Primary Artist",
    label: "Primary Artist",
  },
  {
    value: "Featuring Artist",
    label: "Featuring Artist",
  },
  {
    value: "Lyricist",
    label: "Lyricist",
  },
  {
    value: "Composer",
    label: "Composer",
  },
  {
    value: "Arranger",
    label: "Arranger",
  },
  {
    value: "Orchestra",
    label: "Orchestra",
  },
  {
    value: "Choir",
    label: "Choir",
  },
  {
    value: "Actor",
    label: "Actor",
  },
  {
    value: "Actress",
    label: "Actress",
  },
];

const Index = ({
  setFormData,
  formData,
  data,
  setData,
  setRingtone,
  setTracktomaster,
  songValue,
  setSongValue,
  career,
  seconderror,
  firstslidedata,
  ringtone,
  secondBack,
  setCoverfile,
  coverfile,
  artistdetail,
  setArtistdetail,
  listData,
  setListData,
  tracktomaster,
  setSongprocessing,
  songprocessing,
  setIsInstagramprofile,
  isInstagramprofile,
}) => {
  const [checked, setChecked] = React.useState(false);
  const handleChangedata = (event) => {
    setIsInstagramprofile(event.target.checked);
    setChecked(event.target.checked);
  };

  const handleChangeartist = (e) => {
    setArtistdetail({
      ...artistdetail,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...listData];
    list[index][name] = value;
    setListData(list);
    setData(list);
  };
  const handleServiceRemove = (index) => {
    const list = [...listData];
    list.splice(index, 1);
    setListData(list);
    setData(list);
  };

  const handleAdd = () => {
    setListData([...listData, { role: "", name: "", lastname: "" }]);
  };

  const { getRootProps, getInputProps, open, open1, acceptedFiles } =
    useDropzone({
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      accept: {
        "audio/.wav": [".wav"],
      },
    });

  for (let ring of acceptedFiles) {
    setRingtone(ring);
  }

  // useEffect(() => {
  //   if (firstslidedata.tracks) {
  //     if (firstslidedata?.tracks[0]?.artists.length > 0) {
  //       const artistss = firstslidedata?.tracks[0]?.artists?.map((artist) => {
  //         return {
  //           role: artist?.role,
  //           name: artist?.name,
  //           lastname: artist?.lastname,
  //         };
  //       });
  //       setListData(artistss);
  //       setData(artistss);
  //     }
  //   }
  // }, [firstslidedata]);

  // useEffect(() => {
  //   if (secondBack === false) {
  //     if (career?.tracks[0]?.artists.length > 0) {
  //       const artisttop = [];
  //       const artistbottom = [];

  //       for (let artist of career?.tracks[0]?.artists) {
  //         if (
  //           artist.role === "Primary Artist" ||
  //           artist.role === "Featuring Artist" ||
  //           artist.role === "Lyricist" ||
  //           artist.role === "Composer"
  //         ) {
  //           const findArtist = artisttop.find((ev) => ev.role === artist.role);
  //           if (!findArtist) {
  //             artisttop.push({
  //               role: artist?.role,
  //               name: artist?.name,
  //               lastname: artist?.lastname,
  //             });
  //           } else {
  //             artistbottom.push({
  //               role: artist?.role,
  //               name: artist?.name,
  //               lastname: artist?.lastname,
  //             });
  //           }
  //         } else {
  //           artistbottom.push({
  //             role: artist?.role,
  //             name: artist?.name,
  //             lastname: artist?.lastname,
  //           });
  //         }
  //       }
  //       setListData(
  //         artistbottom.length > 0
  //           ? artistbottom
  //           : [{ role: "", name: "", lastname: "" }]
  //       );
  //       setData(
  //         artistbottom.length > 0
  //           ? artistbottom
  //           : [{ role: "", name: "", lastname: "" }]
  //       );
  //       const filterprimary = artisttop?.filter(
  //         (ev) => ev.role === "Primary Artist"
  //       );
  //       const filterfeaturing = artisttop?.filter(
  //         (ev) => ev.role === "Featuring Artist"
  //       );
  //       const filterlyricist = artisttop.filter((ev) => ev.role === "Lyricist");
  //       const filtercomposer = artisttop.filter((ev) => ev.role === "Composer");
  //       setArtistdetail({
  //         primaryfirstname: filterprimary[0]?.name,
  //         primarylastname: filterprimary[0]?.lastname,
  //         primaryspotifyid: filterprimary[0]?.spotifyid,
  //         primaryinstagram: filterprimary[0]?.instagram,
  //         primarytwitter: filterprimary[0]?.twitter,
  //         primaryfacebook: filterprimary[0]?.facebook,
  //         featuringfirstname: filterfeaturing[0]?.name,
  //         featuringlastname: filterfeaturing[0]?.lastname,
  //         featuringspotifyid: filterfeaturing[0]?.spotifyid,
  //         featuringinstagram: filterfeaturing[0]?.instagram,
  //         featuringtwitter: filterfeaturing[0]?.twitter,
  //         featuringfacebook: filterfeaturing[0]?.facebook,
  //         lyricistfirstname: filterlyricist[0]?.name,
  //         lyricistlastname: filterlyricist[0]?.lastname,
  //         lyricistspotifyid: filterlyricist[0]?.spotifyid,
  //         lyricistinstagram: filterlyricist[0]?.instagram,
  //         lyricisttwitter: filterlyricist[0]?.twitter,
  //         lyricistfacebook: filterlyricist[0]?.facebook,
  //         composerfirstname: filtercomposer[0]?.name,
  //         composerlastname: filtercomposer[0]?.lastname,
  //         composerspotifyid: filtercomposer[0]?.spotifyid,
  //         composerinstagram: filtercomposer[0]?.instagram,
  //         composertwitter: filtercomposer[0]?.twitter,
  //         composerfacebook: filtercomposer[0]?.facebook,
  //       });
        
  //     }
  //     // if (career?.tracks[0]?.artists.length > 0) {
  //     //   const artistss = career?.tracks[0]?.artists?.map((artist) => {
  //     //     return {
  //     //       role: artist?.role,
  //     //       name: artist?.name,
  //     //       lastname: artist?.lastname,
  //     //     };
  //     //   });
  //     //   setListData(artistss);
  //     //   setData(artistss);
  //     // }
  //   }
  // }, [career]);
  useEffect(() => {
    if (secondBack === false) {
      if (career?.tracks[0]?.artists.length > 0) {
        const artisttop = [];
        const artistbottom = [];
  
        for (let artist of career?.tracks[0]?.artists) {
          if (
            artist.role === "Primary Artist" ||
            artist.role === "Featuring Artist" ||
            artist.role === "Lyricist" ||
            artist.role === "Composer"
          ) {
            const findArtist = artisttop.find((ev) => ev.role === artist.role);
            if (!findArtist) {
              artisttop.push({
                role: artist?.role,
                name: artist?.name,
                lastname: artist?.lastname,
                spotifyid: artist?.spotifyid,
                instagram: artist?.instagram,
                twitter: artist?.twitter,
                facebook: artist?.facebook,
              });
            } else {
              artistbottom.push({
                role: artist?.role,
                name: artist?.name,
                lastname: artist?.lastname,
                spotifyid: artist?.spotifyid,
                instagram: artist?.instagram,
                twitter: artist?.twitter,
                facebook: artist?.facebook,
              });
            }
          } else {
            artistbottom.push({
              role: artist?.role,
              name: artist?.name,
              lastname: artist?.lastname,
              spotifyid: artist?.spotifyid,
              instagram: artist?.instagram,
              twitter: artist?.twitter,
              facebook: artist?.facebook,
            });
          }
        }
        setListData(
          artistbottom.length > 0
            ? artistbottom
            : [{ role: "", name: "", lastname: "" }]
        );
        setData(
          artistbottom.length > 0
            ? artistbottom
            : [{ role: "", name: "", lastname: "" }]
        );
        const filterprimary = artisttop?.filter(
          (ev) => ev.role === "Primary Artist"
        );
        const filterfeaturing = artisttop?.filter(
          (ev) => ev.role === "Featuring Artist"
        );
        const filterlyricist = artisttop.filter((ev) => ev.role === "Lyricist");
        const filtercomposer = artisttop.filter((ev) => ev.role === "Composer");
        setArtistdetail({
          primaryfirstname: filterprimary[0]?.name,
          primarylastname: filterprimary[0]?.lastname,
          primaryspotifyid: filterprimary[0]?.spotifyid,
          primaryinstagram: filterprimary[0]?.instagram,
          primarytwitter: filterprimary[0]?.twitter,
          primaryfacebook: filterprimary[0]?.facebook,
          featuringfirstname: filterfeaturing[0]?.name,
          featuringlastname: filterfeaturing[0]?.lastname,
          featuringspotifyid: filterfeaturing[0]?.spotifyid,
          featuringinstagram: filterfeaturing[0]?.instagram,
          featuringtwitter: filterfeaturing[0]?.twitter,
          featuringfacebook: filterfeaturing[0]?.facebook,
          lyricistfirstname: filterlyricist[0]?.name,
          lyricistlastname: filterlyricist[0]?.lastname,
          lyricistspotifyid: filterlyricist[0]?.spotifyid,
          lyricistinstagram: filterlyricist[0]?.instagram,
          lyricisttwitter: filterlyricist[0]?.twitter,
          lyricistfacebook: filterlyricist[0]?.facebook,
          composerfirstname: filtercomposer[0]?.name,
          composerlastname: filtercomposer[0]?.lastname,
          composerspotifyid: filtercomposer[0]?.spotifyid,
          composerinstagram: filtercomposer[0]?.instagram,
          composertwitter: filtercomposer[0]?.twitter,
          composerfacebook: filtercomposer[0]?.facebook,
        });
      }
    }
  }, [career]);
  
  return (
    <>
      <Tracks
        formData={formData}
        setFormData={setFormData}
        tracktomaster={tracktomaster}
        setTracktomaster={setTracktomaster}
        career={career}
        firstslidedata={firstslidedata}
      />

      <Card className="tracks" sx={{ background: "rgba(255, 255, 255, 0.05)" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ ml: 3, mt: 2 }} className="share">
            <Checkbox
              checked={isInstagramprofile}
              onChange={handleChangedata}
              inputProps={{ "aria-label": "controlled" }}
              sx={{
                width: "20px",
                marginRight: "12px",
                color: "#ffffff",
                "&.Mui-checked": {
                  color: "#fa9e0d",
                },
              }}
            />
            <Typography
              variant="h6"
              className="all"
              sx={{
                fontSize: { xs: "18px !important", sm: "24px !important" },
                lineHeight: { xs: "23px !important", sm: "31px !important" },
              }}
            >
              Want to link this song to your Instagram profile?
            </Typography>
          </Grid>
          {isInstagramprofile ? (
            <>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "21px",
                    mb: 1,
                  }}
                >
                  Registered Instagram Email ID
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  value={formData.instagramemail}
                  onChange={(event) =>
                    setFormData({ ...formData, instagramemail: event.target.value })
                  }
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "21px",
                    mb: 1,
                  }}
                >
                  Registered Facebook Email ID
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  value={formData.fbemail}
                  onChange={(event) =>
                    setFormData({ ...formData, fbemail: event.target.value })
                  }
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "21px",
                    mb: 1,
                  }}
                >
                  Instagram Artists Page
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  placeholder=" https://www.instagram.com/john.doe/"
                  value={formData.instagrampage}
                  onChange={(event) =>
                    setFormData({ ...formData, instagrampage: event.target.value })
                  }
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "21px",
                    mb: 1,
                  }}
                >
                  Facebook Artists Page
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  placeholder="https://www.facebook.com/johndoe/"
                  value={formData.fbpage}
                  onChange={(event) =>
                    setFormData({ ...formData, fbpage: event.target.value })
                  }
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                  }}
                />
              </Grid>
            </>
          ) : (
            ""
          )}
        </Grid>
      </Card>

      <Box
        sx={{
          background: "rgba(255, 255, 255, 0.05)",
          padding: "30px",
          color: "white",
          borderRadius: "16px",

          margin: "auto",
        }}
      >
        <Typography
          component={"div"}
          variant="h6"
          sx={{
            fontFamily: "DM Sans",
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "31.25px",
          }}
        >
          Advance Details
        </Typography>
        <Grid container spacing={3} marginTop="32px">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
                mb: 1,
              }}
            >
              Song Title*
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              value={formData.songtitle}
              onChange={(event) =>
                setFormData({ ...formData, songtitle: event.target.value })
              }
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            />
            {seconderror && formData.songtitle <= 0 ? (
              <label className="errorhandler">Song Title can't be Empty</label>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
                mb: 1,
              }}
            >
              Video Link
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              value={formData.videolink}
              onChange={(event) =>
                setFormData({ ...formData, videolink: event.target.value })
              }
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
                mb: 1,
              }}
            >
              ISRC code
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              value={formData.isrccode}
              placeholder="To be filled by Deliver My Tune"
              onChange={(event) =>
                setFormData({ ...formData, isrccode: event.target.value })
              }
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ mt: 1 }}>
            <Box
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "100%",
                padding: "20px",
                borderRadius: "8px",
              }}
            >
              <FormControl>
                <Typography
                  id="demo-row-radio-buttons-group-label"
                  sx={{
                    color: "#FFFFFF",
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "21px",
                    mb: 2,
                  }}
                >
                  Composition Type*
                </Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={formData.compositiontype}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      compositiontype: event.target.value,
                    })
                  }
                >
                  <FormControlLabel
                    value="original"
                    control={
                      <Radio
                        sx={{
                          color: "#E1E1E1",
                          "&.Mui-checked": {
                            color: "#33D67C",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "DM Sans !important",
                          fontSize: "14px !important",
                          fontWeight: 400,
                          lineHeight: "18px !important",
                        }}
                      >
                        Original Composition
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="cover"
                    control={
                      <Radio
                        sx={{
                          color: "#E1E1E1",
                          "&.Mui-checked": {
                            color: "#33D67C",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "DM Sans !important",
                          fontSize: "14px !important",
                          fontWeight: 400,
                          lineHeight: "18px !important",
                        }}
                      >
                        Cover Composition
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="public"
                    control={
                      <Radio
                        sx={{
                          color: "#E1E1E1",
                          "&.Mui-checked": {
                            color: "#33D67C",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "DM Sans !important",
                          fontSize: "14px !important",
                          fontWeight: 400,
                          lineHeight: "18px !important",
                        }}
                      >
                        Public Domain
                      </Typography>
                    }
                  />
                </RadioGroup>
                {formData.compositiontype === "cover" ? (
                  <Coverfile
                    coverfile={coverfile}
                    setCoverfile={setCoverfile}
                  />
                ) : (
                  ""
                )}
              </FormControl>
              {seconderror && formData.compositiontype <= 0 ? (
                <label className="errorhandler">
                  Composition Type can't be Empty
                </label>
              ) : (
                ""
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ mt: 1 }}>
            <Box
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "100%",
                padding: "20px",
                borderRadius: "8px",
              }}
            >
              <FormControl>
                <Typography
                  id="demo-row-radio-buttons-group-label"
                  sx={{
                    color: "#FFFFFF",
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "21px",
                    mb: 2,
                  }}
                >
                  Clean/Explicit*
                </Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={formData.cleansong}
                  onChange={(event) =>
                    setFormData({ ...formData, cleansong: event.target.value })
                  }
                >
                  <FormControlLabel
                    value="clean"
                    control={
                      <Radio
                        sx={{
                          color: "#E1E1E1",
                          "&.Mui-checked": {
                            color: "#33D67C",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "DM Sans !important",
                          fontSize: "14px !important",
                          fontWeight: 400,
                          lineHeight: "18px !important",
                        }}
                      >
                        Clean
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="explicit"
                    control={
                      <Radio
                        sx={{
                          color: "#E1E1E1",
                          "&.Mui-checked": {
                            color: "#33D67C",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "DM Sans !important",
                          fontSize: "14px !important",
                          fontWeight: 400,
                          lineHeight: "18px !important",
                        }}
                      >
                        Explicit Song
                      </Typography>
                    }
                  />
                </RadioGroup>
                {seconderror && formData.cleansong <= 0 ? (
                  <label className="errorhandler">
                    Clean/Explicit can't be Empty
                  </label>
                ) : (
                  ""
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Typography
          component={"div"}
          variant="h6"
          sx={{
            fontFamily: "DM Sans",
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "31.25px",
            marginTop: "40px !important",
          }}
        >
          Artist Details
        </Typography>
        <Grid container spacing={3} sx={{ my: 3 }}>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Primary Artist First Name*
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="primaryfirstname"
                value={artistdetail?.primaryfirstname}
                onChange={handleChangeartist}
                type="text"
                id="name"
                required
              />
            </Box>
            {seconderror && artistdetail.primaryfirstname <= 0 ? (
              <label className="errorhandler">
                Primary artist first name can't be Empty
              </label>
            ) : (
              ""
            )}
          </Grid>
          {/* <Grid item xs={6} md={3}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Primary Artist Last Name
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="primarylastname"
                value={artistdetail?.primarylastname}
                onChange={handleChangeartist}
                type="text"
                id="name"
                required
              />
            </Box>
          </Grid> */}
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Primary Artist Last Name
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="primarylastname"
                value={artistdetail?.primarylastname}
                onChange={handleChangeartist}
                type="text"
                id="name"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Primary Artist Spotify ID
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="primaryspotifyid"
                value={artistdetail?.primaryspotifyid}
                onChange={handleChangeartist}
                type="text"
                id="spotifyid"
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Primary Artist Instagram Handle
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="primaryinstagram"
                value={artistdetail?.primaryinstagram}
                onChange={handleChangeartist}
                type="text"
                id="instagram"
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Primary Artist Twitter Handle
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="primarytwitter"
                value={artistdetail?.primarytwitter}
                onChange={handleChangeartist}
                type="text"
                id="twitter"
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Primary Artist Facebook Page URL
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="primaryfacebook"
                value={artistdetail?.primaryfacebook}
                onChange={handleChangeartist}
                type="text"
                id="facebook"

              />
            </Box>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Featuring Artist First Name
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="featuringfirstname"
                value={artistdetail?.featuringfirstname}
                onChange={handleChangeartist}
                type="text"
                id="name"
                required
              />
            </Box>
            {/* {seconderror && artistdetail?.featuringfirstname <= 0 ? (
              <label className="errorhandler">
                Featuring first name can't be Empty
              </label>
            ) : (
              ""
            )} */}
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Featuring Artist Last Name
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="featuringlastname"
                value={artistdetail?.featuringlastname}
                onChange={handleChangeartist}
                type="text"
                id="name"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Featuring Artist Spotify ID
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="featuringspotifyid"
                value={artistdetail?.featuringspotifyid}
                onChange={handleChangeartist}
                type="text"
                id="spotifyid"
                
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Featuring Artist Instagram Handle
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="featuringinstagram"
                value={artistdetail?.featuringinstagram}
                onChange={handleChangeartist}
                type="text"
                id="instagram"
                
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Featuring Artist Twitter Handle
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="featuringtwitter"
                value={artistdetail?.featuringtwitter}
                onChange={handleChangeartist}
                type="text"
                id="twitter"
                
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Featuring Artist Facebook Page URL
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="featuringfacebook"
                value={artistdetail?.featuringfacebook}
                onChange={handleChangeartist}
                type="text"
                id="facebook"
                
              />
            </Box>

            {/* {seconderror && artistdetail?.featuringlastname <= 0 ? (
              <label className="errorhandler">
                Featuring last name can't be Empty
              </label>
            ) : (
              ""
            )} */}
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ my: 3 }}>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Lyricist First Name*
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="lyricistfirstname"
                value={artistdetail?.lyricistfirstname}
                onChange={handleChangeartist}
                type="text"
                id="name"
                required
              />
            </Box>
            {seconderror && artistdetail?.lyricistfirstname <= 0 ? (
              <label className="errorhandler">
                Lyricist first name can't be Empty
              </label>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Lyricist Last Name*
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="lyricistlastname"
                value={artistdetail?.lyricistlastname}
                onChange={handleChangeartist}
                type="text"
                id="name"
                required
              />
            </Box>
            {seconderror && artistdetail?.lyricistlastname <= 0 ? (
              <label className="errorhandler">
                Lyricist last name can't be Empty
              </label>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Lyricist Artist Spotify ID
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="lyricistspotifyid"
                value={artistdetail?.lyricistspotifyid}
                onChange={handleChangeartist}
                type="text"
                id="spotify"
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Lyricist Artist Instagram Handle
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="lyricistinstagram"
                value={artistdetail?.lyricistinstagram}
                onChange={handleChangeartist}
                type="text"
                id="instagram"
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Lyricist Artist Twitter Handle
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="lyricisttwitter"
                value={artistdetail?.lyricisttwitter}
                onChange={handleChangeartist}
                type="text"
                id="lyricisttwitter"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Lyricist Artist Facebook Page URL
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="lyricistfacebook"
                value={artistdetail?.lyricistfacebook}
                onChange={handleChangeartist}
                type="text"
                id="facebook"
                required
              />
            </Box>

            {/* {seconderror && artistdetail?.featuringlastname <= 0 ? (
              <label className="errorhandler">
                Featuring last name can't be Empty
              </label>
            ) : (
              ""
            )} */}
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Composer First Name*
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="composerfirstname"
                value={artistdetail?.composerfirstname}
                onChange={handleChangeartist}
                type="text"
                id="name"
                required
              />
            </Box>
            {seconderror && artistdetail?.composerfirstname <= 0 ? (
              <label className="errorhandler">
                Composer first name can't be Empty
              </label>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Composer Last Name*
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="composerlastname"
                value={artistdetail?.composerlastname}
                onChange={handleChangeartist}
                type="text"
                id="name"
                required
              />
            </Box>
            {seconderror && artistdetail?.composerlastname <= 0 ? (
              <label className="errorhandler">
                Composer last name can't be Empty
              </label>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Composer Artist Spotify ID
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="composerspotifyid"
                value={artistdetail?.composerspotifyid}
                onChange={handleChangeartist}
                type="text"
                id="composerspotifyid"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Composer Artist Instagram Handle
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="composerinstagram"
                value={artistdetail?.composerinstagram}
                onChange={handleChangeartist}
                type="text"
                id="composerinstagram"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Composer Artist Twitter Handle
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="composertwitter"
                value={artistdetail?.composertwitter}
                onChange={handleChangeartist}
                type="text"
                id="composertwitter"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Composer Artist Facebook Page URL
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="composerfacebook"
                value={artistdetail?.composerfacebook}
                onChange={handleChangeartist}
                type="text"
                id="composerfacebook"
                required
              />
            </Box>

            {/* {seconderror && artistdetail?.featuringlastname <= 0 ? (
              <label className="errorhandler">
                Featuring last name can't be Empty
              </label>
            ) : (
              ""
            )} */}
          </Grid>
        </Grid>
        <Typography
          component={"div"}
          variant="h6"
          sx={{
            fontFamily: "DM Sans",
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "31.25px",
            marginTop: "40px !important",
          }}
        >
          Add More Artists
        </Typography>
        <Box></Box>
        {/* {listData?.map((roleAdd, index) => (
          <Box key={index} className="role">
            <Grid container spacing={3} sx={{ my: 3 }}>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    mb: 1,
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "21px",
                  }}
                >
                  Role
                </Typography>
                <TextField
                  fullWidth
                  select
                  name="role"
                  id="role"
                  value={roleAdd.role}
                  onChange={(e) => handleChange(e, index)}
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "18px",
                    color: "#E1E1E1",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                  }}
                >
                  {previewStart?.map((option, index) => (
                    <MenuItem key={option.index} value={option.value}>
                      <Typography sx={{ fontFamily: "DM sans" }}>
                        {" "}
                        {option.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    mb: 1,
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "21px",
                  }}
                >
                  First Name
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    fullWidth
                    label=""
                    sx={{
                      background: "rgba(255, 255, 255, 0.05) !important",
                      height: "44px !important",
                      borderRadius: "5px",
                      p: 1,
                      fontFamily: "DM Sans",
                    }}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      background: "rgba(255, 255, 255, 0.05) !important",
                    }}
                    name="name"
                    type="text"
                    id="name"
                    value={roleAdd.name}
                    onChange={(e) => handleChange(e, index)}
                    required
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    mb: 1,
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "21px",
                  }}
                >
                  Last Name
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    fullWidth
                    label=""
                    sx={{
                      background: "rgba(255, 255, 255, 0.05) !important",
                      height: "44px !important",
                      borderRadius: "5px",
                      p: 1,
                      fontFamily: "DM Sans",
                    }}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      background: "rgba(255, 255, 255, 0.05) !important",
                    }}
                    name="lastname"
                    type="text"
                    id="name"
                    value={roleAdd.lastname}
                    onChange={(e) => handleChange(e, index)}
                    required
                  />

                  <Box sx={{ ml: "5px" }}>
                    {listData.length === 1 ? (
                      <DeleteOutlineOutlinedIcon
                        sx={{ color: "red", display: "none" }}
                      />
                    ) : (
                      <DeleteOutlineOutlinedIcon
                        onClick={() => handleServiceRemove(index)}
                        sx={{ color: "red" }}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
              {listData.length - 1 === index && (
                <Grid item xs={12}>
                  <CustomButton
                    btnText={"Add Artist"}
                    endIcon={<AddIcon sx={{ ml: "13px" }} />}
                    buttonStyle="artistbtn"
                    handleClick={handleAdd}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        ))} */}
{listData?.map((roleAdd, index) => (
  <Box key={index} className="role">
    <Grid container spacing={3} sx={{ my: 3 }}>
      {/* Role */}
      <Grid item xs={12} md={3}>
        <Typography
          sx={{
            mb: 1,
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "21px",
          }}
        >
          Role
        </Typography>
        <TextField
          fullWidth
          select
          name="role"
          id="role"
          value={roleAdd.role}
          onChange={(e) => handleChange(e, index)}
          sx={{
            background: "rgba(255, 255, 255, 0.05) !important",
            height: "44px !important",
            borderRadius: "5px",
            p: 1,
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "18px",
            color: "#E1E1E1",
          }}
          variant="standard"
          InputProps={{
            disableUnderline: true,
            background: "rgba(255, 255, 255, 0.05) !important",
          }}
        >
          {previewStart?.map((option, index) => (
            <MenuItem key={option.index} value={option.value}>
              <Typography sx={{ fontFamily: "DM sans" }}>
                {" "}
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {/* First Name */}
      <Grid item xs={12} md={3}>
        <Typography
          sx={{
            mb: 1,
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "21px",
          }}
        >
          First Name
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            fullWidth
            label=""
            sx={{
              background: "rgba(255, 255, 255, 0.05) !important",
              height: "44px !important",
              borderRadius: "5px",
              p: 1,
              fontFamily: "DM Sans",
            }}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              background: "rgba(255, 255, 255, 0.05) !important",
            }}
            name="name"
            type="text"
            id="name"
            value={roleAdd.name}
            onChange={(e) => handleChange(e, index)}
            required
          />
        </Box>
      </Grid>
      {/* Last Name */}
      <Grid item xs={12} md={3}>
        <Typography
          sx={{
            mb: 1,
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "21px",
          }}
        >
          Last Name
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            fullWidth
            label=""
            sx={{
              background: "rgba(255, 255, 255, 0.05) !important",
              height: "44px !important",
              borderRadius: "5px",
              p: 1,
              fontFamily: "DM Sans",
            }}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              background: "rgba(255, 255, 255, 0.05) !important",
            }}
            name="lastname"
            type="text"
            id="name"
            value={roleAdd.lastname}
            onChange={(e) => handleChange(e, index)}
            required
          />
        
        </Box>
      </Grid>
      {/* Spotify ID */}
      <Grid item xs={12} md={3}>
        <Typography
          sx={{
            mb: 1,
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "21px",
          }}
        >
          Spotify ID
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            fullWidth
            label=""
            sx={{
              background: "rgba(255, 255, 255, 0.05) !important",
              height: "44px !important",
              borderRadius: "5px",
              p: 1,
              fontFamily: "DM Sans",
            }}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              background: "rgba(255, 255, 255, 0.05) !important",
            }}
            name="spotifyid"
            type="text"
            id="spotifyid"
            value={roleAdd.spotifyid}
            onChange={(e) => handleChange(e, index)}
            required
          />
        </Box>
      </Grid>
      {/* Instagram Handle */}
      <Grid item xs={12} md={4}>
        <Typography
          sx={{
            mb: 1,
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "21px",
          }}
        >
          Instagram Handle
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            fullWidth
            label=""
            sx={{
              background: "rgba(255, 255, 255, 0.05) !important",
              height: "44px !important",
              borderRadius: "5px",
              p: 1,
              fontFamily: "DM Sans",
            }}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              background: "rgba(255, 255, 255, 0.05) !important",
            }}
            name="instagram"
            type="text"
            id="instagram"
            value={roleAdd.instagram}
            onChange={(e) => handleChange(e, index)}
            required
          />
        </Box>
      </Grid>
      {/* Twitter Handle */}
      <Grid item xs={12} md={4}>
        <Typography
          sx={{
            mb: 1,
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "21px",
          }}
        >
          Twitter Handle
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            fullWidth
            label=""
            sx={{
              background: "rgba(255, 255, 255, 0.05) !important",
              height: "44px !important",
              borderRadius: "5px",
              p: 1,
              fontFamily: "DM Sans",
            }}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              background: "rgba(255, 255, 255, 0.05) !important",
            }}
            name="twitter"
            type="text"
            id="twitter"
            value={roleAdd.twitter}
            onChange={(e) => handleChange(e, index)}
            required
          />
        </Box>
      </Grid>
      {/* Facebook Page URL */}
      <Grid item xs={12} md={4}>
        <Typography
          sx={{
            mb: 1,
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "21px",
          }}
        >
          Facebook Page URL
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            fullWidth
            label=""
            sx={{
              background: "rgba(255, 255, 255, 0.05) !important",
              height: "44px !important",
              borderRadius: "5px",
              p: 1,
              fontFamily: "DM Sans",
            }}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              background: "rgba(255, 255, 255, 0.05) !important",
            }}
            name="facebook"
            type="text"
            id="facebook"
            value={roleAdd.facebook}
            onChange={(e) => handleChange(e, index)}
            required
          />
        </Box>
        
       
      </Grid>
      <Box sx={{ ml: "26px",mt:"8px" }}>
            {listData.length === 1 ? (
              <DeleteOutlineOutlinedIcon
                sx={{ color: "red", display: "none" }}
              />
            ) : (
              <DeleteOutlineOutlinedIcon
                onClick={() => handleServiceRemove(index)}
                sx={{ color: "red" }}
              />
            )}
          </Box>
      {/* Add Artist Button */}
      {listData.length - 1 === index && (
        <Grid item xs={12}>
          <CustomButton
            btnText={"Add Artist"}
            endIcon={<AddIcon sx={{ ml: "13px" }} />}
            buttonStyle="artistbtn"
            handleClick={handleAdd}
          />
        </Grid>
      )}
    </Grid>
  </Box>
))}

        {/* Upload the song */}
        <Box>
          <Typography
            component={"div"}
            variant="h6"
            sx={{
              marginTop: "50px",
              mb: 4, //styleName: Heading/H6 Bold;
              fontFamily: "DM Sans !important",
              fontSize: "24px !important",
              fontWeight: "700 !important",
              lineHeight: "31px !important",
            }}
          >
            Upload Your Song &nbsp;
            <span
              className="album"
              sx={{
                fontFamily: "dm sans",
                fontWeight: "700 !important",
                color: "rgb(255, 255, 255) !important",
                fontStyle: "normal !important",
                margin: "0px auto 32px !important",
              }}
            >
              (Audio less then 1 minute or more then 6.30 minute will be
              rejected)
            </span>
          </Typography>
        </Box>

        <Grid
          container
          spacing={1.5}
          sx={{ background: "#1e1e1e ", pb: "0px", borderRadius: "10px" }}
        >
          <Grid item xs={12} md={6} px="28px">
            {/* <Box
              sx={{
                background: "#292929",
                borderRadius: "16px",
                width: "100%",
              }}
               {...getRootProps()} onClick={open1}
            >
              <Box textAlign="center" sx={{ py: "20px" }} >
              <input {...getInputProps()} />
                <Box>
                  <img src={Play} />
                </Box>
                <CustomButton btnText="Change Song" buttonStyle="basic-btn" />
                <Box>
                  <aside style={{ minHeight: "32px" }}>
                    <Typography
                      sx={{ listStyle: "none", mt: "17px !important", mb: "17px !important" }}
                    >
                      {files1}
                    </Typography>
                  </aside>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography className="quality">
                      Quality is important to us, please use the highest-quality
                      stereo assets available (WAV, AIFF or FLAC only).
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box> */}
            <ChangeSong
              setSongValue={setSongValue}
              songValue={songValue}
              career={career}
              firstslidedata={firstslidedata}
              songprocessing={songprocessing}
              setSongprocessing={setSongprocessing}
            />
          </Grid>
          {/* <Grid item xs={12} md={6} sx={{ pr: { xs: "28px", md: "0" } }}>
            <Box
              {...getRootProps({ className: "basic-song-drop" })}
              sx={{ py: "20px" }}
              onClick={open}
            >
              <input {...getInputProps()} />
              <Box sx={{ mt: "0px" }}>
                <img src={MusicIcon} />
              </Box>
              <Button type="button" className="upload-song">
                Upload Ringtone
              </Button>
              <Box>
            
                <Typography
                  sx={{
                    listStyle: "none",
                    mt: "17px !important",
                    mb: "17px !important",
                    wordBreak: "break-all",
                    px:'8px'
                  }}
                >
                  {ringtone?.path || ringtone}
                </Typography>
              </Box>
              <Typography className="drop-song1">
                Drop your Ringtone here, or{" "}
                <Typography variant="span" sx={{ color: "#FB900B" }}>
                  browse
                </Typography>
              </Typography>
              <Typography className="ringtone" pb="10px" width="350px">
                Quality is important to us, please use the highest-quality
                stereo assets available (WAV, AIFF or FLAC only).
              </Typography>
            </Box>
          </Grid> */}
        </Grid>
      </Box>
      <Box
        sx={{
          //   padding: "30px",
          paddingY: "48px",
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "1200px",
          margin: "auto",
        }}
      >
        {/* <CustomButton
          btnText={"Add More Song"}
          endIcon={<AddIcon sx={{ ml: "13px" }} />}
          buttonStyle="artistbtn"
        /> */}
      </Box>
    </>
  );
};

export default Index;

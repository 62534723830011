import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import moment from "moment/moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
}));
const Balance = [
  {
    id: 1,
    amount: "10",
  },
];

const rows = [
  {
    id: 1,
    type: " artis",
    email: "keshri@gmail.com",
    date: "8/12/2022",
    Amount: "1000",
  },
  {
    id: 2,
    type: "singer",
    email: " khushboo@gmail.com",
    date: "8/12/2022",
    Amount: " 2000",
  },
];

export default function CustomizedTables() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;
  const userid = loginToken?.user._id;

  async function getHistory() {
    await axios
      .get(`${process.env.REACT_APP_DMT_API_PATH}/wallet/?user_id=${userid}`)
      .then((res) => {
        setData(res.data.data.transactions);
        setTotal(res.data.data.total[0].total);
        // console.log(res.data.data)
      })
      .catch((err) => {
      });
  }

  useEffect(() => {
    getHistory();
  }, []);
  return (
    <Box className="custom-dmt-container">
      <Card className="masteraudiolist" sx={{background: 'rgba(255, 255, 255, 0.05)'}}>
        {/* {Balance.map((Balance) => ( */}
        <Box key={Balance.id}>
          <Typography sx={{fontFamily:'DM sans !important'}} className="account">
            Account Balance : &#x20b9;{total}
          </Typography>
        </Box>
        {/* ))} */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{fontFamily:'DM sans'}} align="center">Type </StyledTableCell>
                <StyledTableCell sx={{fontFamily:'DM sans'}} align="center">Email </StyledTableCell>
                <StyledTableCell sx={{fontFamily:'DM sans'}} align="center">Date</StyledTableCell>
                <StyledTableCell sx={{fontFamily:'DM sans'}} align="center"> Amount </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((data, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell sx={{fontFamily:'DM sans'}} align="center">{data.type}</StyledTableCell>
                  <StyledTableCell sx={{fontFamily:'DM sans'}} align="center">
                    {data.user_id.email}
                  </StyledTableCell>
                  <StyledTableCell sx={{fontFamily:'DM sans'}} align="center">
                    {" "}
                    {moment(data?.created_at).format(
                      "DD-MM-YYYY"
                    )}
                  </StyledTableCell>
                  <StyledTableCell sx={{fontFamily:'DM sans'}} align="center">
                    {data.amount}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Box>
  );
}

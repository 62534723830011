import {
  Box,
  Card,
  Container,
  Dialog,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Form_Carrier from "./listenForm";
import AudioPlayer from "react-h5-audio-player";
import "./listen.scss";
import { IoMdMore } from "react-icons/io";
import upcoming from "../../../assets/img/upcoming.png";
import CustomButton from "../thirdEditBtn";
import { useState } from "react";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Popover from "@mui/material/Popover";

const Listen = ({ data, getOrder, tokenedit }) => {
  const playlist = [
    {
      id: 1,
      songName: "Beautiful Apocalypse1  ",
      image: upcoming,
      src: "https://hanzluo.s3-us-west-1.amazonaws.com/music/ziyounvshen.mp3",
      time: "01:25",
    },
    {
      id: 2,
      songName: "Beautiful Apocalypse2",
      image: upcoming,
      src: "https://hanzluo.s3-us-west-1.amazonaws.com/music/wuyuwuqing.mp3",
      time: "01:25",
    },
    {
      id: 3,
      songName: "Beautiful Apocalypse3",
      image: upcoming,
      src: "https://hanzluo.s3-us-west-1.amazonaws.com/music/suipian.mp3",
      time: "01:25",
    },
  ];
  function PaperComponent(props) {
    return <Paper sx={{ background: "#fff" }} {...props} />;
  }
  const [combinedArr, setCombinedArr] = useState([]);

  const [currentTrack, setTrackIndex] = React.useState(0);
  const [buttonColor, setButtonColor] = useState(currentTrack);
  const [songTimes, setSongTimes] = useState([]);
  const handleClickNext = () => {
    setTrackIndex((currentTrack) =>
      currentTrack < combinedArr?.length - 1 ? currentTrack + 1 : 0
    );
  };
  const handleClickNextsong = (item) => {
    setTrackIndex(item?.listensong);
    setButtonColor();
  };
  const handleEnd = () => {
    setTrackIndex((currentTrack) =>
      currentTrack < combinedArr?.length - 1 ? currentTrack + 1 : 0
    );
  };
  const combinedtrackarray = () => {
    const mappedArr1 = data?.listen?.map((obj) => ({
      _id: obj._id,
      listenimage: obj.listenimage,
      listensinger: obj.listensinger,
      listensong: obj.listensong,
      listentitle: obj.listentitle,
      source: "listen",
    }));
    const mappedArr2 = data?.tracks?.map((obj) => ({
      _id: obj._id,
      listenimage: obj.cover,
      listensinger:
        obj?.tracks.length > 0
          ? obj?.tracks[0]?.artists[0]?.lastname
            ? obj?.tracks[0]?.artists[0]?.name +
              " " +
              obj?.tracks[0]?.artists[0]?.lastname
            : obj?.tracks[0]?.artists[0]?.name
          : "",
      listensong: obj?.tracks.length > 0 ? obj?.tracks[0]?.file : "",
      listentitle: obj.name,
      source: "track",
    }));
    setCombinedArr(mappedArr1.concat(mappedArr2));
  };
  const [open, setOpen] = React.useState(false);
  const [viewmodal, setViewmodal] = useState([]);

  const handleClose = () => setOpen(false);
  const [number, setNumber] = useState(0);

  const handleOpen = (item) => {
    setOpen(true);
    setViewmodal(item);
  };
  const [isloading, setIsloading] = useState(true);

  async function calculateTotalDuration(url, data) {
    const audio = new Audio(url);
    return new Promise((resolve, reject) => {
      audio.addEventListener("loadedmetadata", () => {
        const minutes = Math.floor(audio?.duration / 60);
        const seconds = Math.round(audio?.duration % 60);
        resolve({
          ...data,
          duration: `${minutes}:${seconds}`,
        });
      });
      audio.addEventListener("error", (error) => {
        console.log(error);
        reject({});
      });
    });
  }
  const datavlue = async (arr) => {
    const listenarray = combinedArr.map(async (item) =>
      item.listensong
        ? await calculateTotalDuration(item?.listensong, item)
        : {}
    );
    const dataset = await Promise.all(listenarray);
    const updatedData = {
      listen: dataset,
    };
    setSongTimes(dataset);
    setIsloading(false);
  };

  let updatedData;
  useEffect(() => {
    datavlue();
  }, [combinedArr]);
  useEffect(() => {
    combinedtrackarray();
  }, []);

  return (
    <Box id="listen2" sx={{ background: "#fff" }}>
      <Container>
        <Typography
          sx={{
            fontSize: { md: "56px", xs: "36px" },
            fontFamily: "Monoton",
            textAlign: "center",
            textTransform: "uppercase",
            pt: { xs: 6, md: 6 },
            color: "#1E1E1E",
          }}
        >
          Listen
        </Typography>

        <Box sx={{ textAlign: "-webkit-center", pb: 4 }}>
          <Box
            sx={{
              marginTop: { lg: "12px", md: "15px", xs: "0px" },
            }}
          >
            {tokenedit ? (
              <CustomButton
                btnText="Add"
                handleClick={() => {
                  handleOpen();
                  setNumber(0);
                }}
                startIcon={<AddIcon sx={{ marginRight: "5px" }} />}
                width="75px"
                height="30px"
                border="1px solid #ffffff !important"
                padding="15px"
              />
            ) : (
              ""
            )}
          </Box>
          <Box
            className="showscroll"
            sx={{ height: "500px", overflowY: "scroll", position: "relative" }}
          >
            <Box
              sx={{
                maxWidth: "800px",
                height: "35px",
                alignSelf: "center",
                position: "sticky",
                top: "0px",
                height: "50px",
                background: "white",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      className="tablesongtext2"
                      sx={{ width: "40px", textAlign: "center" }}
                    >
                      {" "}
                      #
                    </Typography>
                    <Box
                      width="32px"
                      height="32px"
                      style={{
                        borderRadius: "50%",
                        marginLeft: "12px",
                        marginRight: "12px",
                      }}
                    ></Box>
                  </Box>
                  <Typography
                    className="tablesongtext2"
                    sx={{ textAlign: "left", width: "100%", height: "100%" }}
                  >
                    Title
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography className="tablesongtext2">Time</Typography>
                  <Box sx={{ color: "black", width: "50px" }}></Box>
                </Box>
              </Box>
            </Box>
            {songTimes?.length === 0 ? (
              <>
                <Box
                  sx={{
                    margin: "30px 0px",
                    padding: "0px !important",
                  }}
                >
                  {tokenedit ? (
                    <Box
                      sx={{
                        border: "2px dashed #4dcab4",
                        width: { md: "760px", xs: "280px" },
                        height: "210px !important",
                        textAlign: "center",
                        margin: "auto",
                      }}
                      onClick={() => {
                        handleOpen();
                        setNumber(0);
                      }}
                    >
                      <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                        <Typography>+</Typography>
                        <Typography  sx={{
                            color: "#4dcab4",
                            fontSize: "20px",
                            fontWeight: "700",fontFamily: "DM Sans !important"
                         }}>
                          Add Your First Song Here
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        border: "2px dashed #4DCAB4",
                        width: { md: "760px", xs: "280px" },
                        height: "210px !important",
                        textAlign: "center",
                        margin: "auto",
                      }}
                    >
                      <Box sx={{ textAlign: "center", marginTop: "100px" }}>
                        <Typography sx={{ fontFamily: "DM Sans !important" ,color:"#4DCAB4", fontWeight:"600"}}>
                          {" "}
                          Song is Empty
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              songTimes?.map((item, index) => {
                console.log(item);
                if (Object.keys(item)?.length === 0) return <></>;
                return (
                  <Box key={index} sx={{ maxWidth: "800px", height: "75px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "100%",
                        background: buttonColor,
                        color: "black",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            sx={{ width: "40px", textAlign: "center" }}
                          >
                            {" "}
                            {index + 1}
                          </Typography>
                          <img
                            src={item?.listenimage}
                            width="32px"
                            height="32px"
                            style={{
                              borderRadius: "50%",
                              marginLeft: "12px",
                              marginRight: "12px",
                            }}
                            alt=""
                          />
                        </Box>
                        <Typography
                          onClick={() => handleClickNextsong(item)}
                          sx={{
                            cursor: "pointer",
                            textAlign: "left",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          {item?.listentitle}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Typography>{item?.duration}</Typography>
                        <Box sx={{ width: "50px", cursor: "pointer" }}>
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-popover"
                          >
                            {(popupState) => (
                              <div
                                style={{
                                  display: "inline-block",
                                  marginLeft: "20px !important",
                                }}
                              >
                                <IoMdMore
                                  {...bindTrigger(popupState)}
                                  sx={{ cursor: "pointer" }}
                                />
                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  {tokenedit && item?.source === "listen" ? (
                                    <Typography
                                      sx={{ p: 1, cursor: "pointer" }}
                                      onClick={() => {
                                        handleOpen(item);
                                        setNumber(1);
                                      }}
                                    >
                                      Edit
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </Popover>
                              </div>
                            )}
                          </PopupState>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })
            )}
          </Box>
          <Box sx={{ maxWidth: "800px" }}>
            <AudioPlayer
              volume="0.5"
              src={combinedArr[currentTrack]?.listensong || currentTrack}
              showSkipControls
              onClickNext={handleClickNext}
              onEnded={handleEnd}
              onPlay={(e) => console.log("onPlay")}
            />
          </Box>
        </Box>
      </Container>
      <div>
        <Dialog
          height="auto"
          sx={{}}
          style={{ zIndex: "9999922" }}
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <Box textAlign="end">
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ color:"black"}} />
            </IconButton>
            <DialogTitle
              style={{ cursor: "move",color:"black" }}
              align="center"
              mt="-30px"
              padding="0px !important"
              id="draggable-dialog-title"
            >
              Listen
            </DialogTitle>
          </Box>

          <Form_Carrier
            view={viewmodal}
            handleClose={handleClose}
            getOrder={getOrder}
            number={number}
            tokenedit={tokenedit}
          />
        </Dialog>
      </div>
    </Box>
  );
};

export default Listen;

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import Bannerimg from "../../../assets/img/profile/photosecond.png";
import "./banner.css";
import mike from "../../../assets/img/mike.png";
import zigzag from "../../../assets/img/zigzag.png";
import zigzag2 from "../../../assets/img/zigzag2.png";
import dotsthree from "../../../assets/img/dotsthree.png";
import CloseIcon from "@mui/icons-material/Close";
import Form_Carrier from "./editBanner";
import { useNavigate } from "react-router-dom";
import CustomButton from "../editportfoliosecondbtn.js";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";

const Index = ({ data, getOrder, tokenedit }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigateSinguppage = useNavigate();
  const handleshow = () => {
    navigateSinguppage("/signup/artist");
  };
  const handleClick = () => {
    navigateSinguppage("/about");
  };

  function PaperComponent(props) {
    return <Paper sx={{ background: "#FF599D" }} {...props} />;
  }

  return (
    <>
      <Container className="custom-containdata-second">
        <Box sx={{ position: "absolute", bottom: "0px", left: "0px" }}>
          <img src={dotsthree} alt="" />
        </Box>

        {tokenedit ? (
          <Box
            sx={{
              position: "absolute",
              top: "40px",
              left: { md: "25%", xs: "40%" },
              marginBottom: { md: "50px", xs: "20px" },
              marginLeft: { lg: "250px", xs: "10px" },
            }}
          >
            <CustomButton
              btnText="Edit"
              handleClick={handleOpen}
              startIcon={<ModeEditOutlinedIcon sx={{ marginRight: "5px" }} />}
              width="75px"
              height="30px"
              border="1px solid #ffffff !important"
              padding="15px"
            />
          </Box>
        ) : (
          ""
        )}
        <Grid container sx={{ pb: 2 }}>
          <Grid item xs={12} md={6}>
            <Box
              className="banner_backg"
              sx={{
                // marginTop: { md: "170px", xs: "none" },
                // marginLeft: { md: "150px", xs: "0px" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* gf */}
              <img
                alt=""
                style={{ borderRadius: "50%" }}
                src={data?.banner === "undefined" ? Bannerimg : data?.banner}
                className="banner_img"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ position: "relative" }}>
            <Box
              sx={{ marginLeft: { lg: "60px", xs: "none" } }}
              className="second-port-text-data"
            >
              <Box
              // sx={{ marginTop: { xs: "20px", lg: "180px" } }}
              >
                <Box
                  textAlign={"right"}
                  sx={{ mr: 5, display: { xs: "none", md: "block" } }}
                >
                  <img src={zigzag} alt="" />
                </Box>
                <Typography
                  variant="p"
                  className="homemanageBoldTextaboutsecond"
                >
                  <i> {data?.name}</i>
                </Typography>
              </Box>
              <Box
                sx={{
                  maxWidth: { xs: "100%", md: "80%" },
                  marginTop: { md: "40px", xs: "none" },
                }}
              >
                <Typography variant="p" className="homemanageNormalTextsecond">
                  {data?.description === "undefined"
                    ? "occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing occaecat cupidatat non proident, sunt inculpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing."
                    : data?.description}
                </Typography>
                <div className="hire-section">
                <a href="#contactrapper">
                  <Button className="hire-event">Hire Me For Event</Button>
                  </a>
                  <a href="#listenrapper">
                    {" "}
                    <Button className="hire-event-data">
                      Listen My Song
                    </Button>{" "}
                  </a>
                </div>
              </Box>
            </Box>
            <Grid display="flex">
              <Grid
                xs={6}
                item
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <img src={zigzag2} alt="" />
              </Grid>
              <Grid xs={6} item sx={{ textAlign: "end", mr: { xs: 4, md: 8 } }}>
                <img src={mike} alt="" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <div>
        <Dialog
          height="auto"
          sx={{}}
          style={{ zIndex: "9999922" }}
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <Box textAlign="end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <DialogTitle
              style={{ cursor: "move" }}
              align="center"
              mt="-30px"
              padding="0px !important"
              id="draggable-dialog-title"
            >
              Banner
            </DialogTitle>
          </Box>

          <Form_Carrier
            view={data}
            handleClose={handleClose}
            getOrder={getOrder}
            tokenedit={tokenedit}
          />
        </Dialog>
      </div>
    </>
  );
};

export default Index;

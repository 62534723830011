import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/system/Container";
import Grid from "@mui/material/Grid";
import WithAll from "../../assets/img/static/with-all.png";
import ReviewData from "../../assets/img/static/text.png";
import { TextField, Typography, Button } from "@mui/material";
import Static from "../../assets/img/static/mask-half.png";
 import {useNavigate} from "react-router-dom"
import "./home.css";

const Index = () => {
  const navigateSinguppage=useNavigate()
  const handleshow=()=>{
    navigateSinguppage('/signup/artist')
  }
  const  handleClick=()=>{
    navigateSinguppage('/about')
  }
   
  
  return (
    <>
      <Container
        maxWidth="90%"
        sx={{
          background: "white",
          color: "black",
          width: "100%",
          marginTop: { md: "40px", xs: "none" },
        }}
        className="custom-contain"
      >
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box className="heading-data" sx={{ marginTop: "100px" }}>
                <Box className="title-circle2"></Box>
                <Box className="title-circle"></Box>
                <Box
                  className="filter"
                  sx={{
                    fontFamily: "DM Sans",
                    background: "transparent !important",
                    zIndex: "100 !important",
                  }}
                >
                  <Typography
                    variant="h3"
                    className="distribute-text"
                    sx={{
                      fontFamily: "DM Sans",
                      background: "transparent !important",
                      zIndex: "100 !important",
                    }}
                  >
                    Distribute Your Music On 60+ Platforms Within 48 Hours
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ marginTop: "30px" }}>
                <Button className="home-get-started-btn"  onClick={handleshow}  >
                  Sign up for free
                </Button>
                <Button
                  sx={{
                    color: "#FB8E0B",
                    fontWeight: "700",
                    fontSize: "18px",
                    fontFamily: "DM Sans",
                  }}
                  onClick={handleClick}
                >
                  LEARN MORE
                </Button>
              </Box>
              <Box sx={{ marginTop: { md: "130px", xs: "50px" } }}>
                <img alt="" src={ReviewData} width="60%" height="100%" />
              </Box>
              <Box sx={{ marginTop: { md: "12px", xs: "0px" } }}>
                <img alt="" src={Static} width="30%" height="100%" />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{marginTop:{md:'60px',xs:'none'}}}>
                <img alt="" src={WithAll} width="100%" height="100%" />
                <Box className="title-circle3" marginTop='81px'  marginLeft='74px'   ></Box>
              </Box>
            </Grid>
          </Grid>
          
        </Box>
        
      </Container>
    </>
  );
};

export default Index;

import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";

export default function ReferralTable({
  count,
  HandleChangePage,
  OnRowsPerPageChange,
  page,
  rowsPerPage,
  columns,
  rows,
  isLoading,
}) {
  return (
    <Box
      sx={{
        marginTop: "20px",
        height: "auto",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          padding: "20px",
          fontWeight: "600",
          textAlign: "left",
          fontSize: "1.8rem",
        }}
      >
        Transactions
      </Typography>
      <Card
        sx={{
          background: "rgba(255, 255, 255, 0.05)",
          padding: "20px",
        }}
      >
        <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "20px" }}>
          <TableContainer sx={{ maxHeight: 440, position: "relative" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns?.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        textTransform: "capitalize",
                        fontWeight : "500"
                      }}
                    >
                      {column?.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableCell
                  key="loading"
                  align="center"
                  colSpan={columns.length}
                  sx={{
                    border : "none"
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress style={{color : "#fb8e0b"}} />
                  </Box>
                </TableCell>
              ) : (
                <TableBody>
                  {rows?.map((row, index) => (
                    <TableRow key={index}>
                      {columns.map((column) => (
                        <TableCell key={column.id}>
                          {column.var ? row[column.var] : index + 1}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={HandleChangePage}
            onRowsPerPageChange={OnRowsPerPageChange}
          />
        </Paper>
      </Card>
    </Box>
  );
}

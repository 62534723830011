import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ThumbnailOne from "../../../assets/img/thumbnailOne.svg";
import ThumbnailTwo from "../../../assets/img/thumbnailTwo.svg";
import ThumbnailThree from "../../../assets/img/thumbnailThree.svg";
import CustomButton from "../editButton";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AddIcon from "@mui/icons-material/Add";
import { LightBox, Lightbox } from "react-lightbox-pack";
import "react-lightbox-pack/dist/index.css";
import VideoForm from "./gallaryadd";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";
import "./style.css";


function PaperComponent(props) {
  return <Paper {...props} />;
}

const GalleryData = [
  {
    id: 1,
    image: ThumbnailOne,
  },
  {
    id: 2,
    image: ThumbnailTwo,
  },
  {
    id: 3,
    image: ThumbnailThree,
  },
];
const   Gallery = ({ value, data, getOrder, tokenedit }) => {
  const [pics, setPics] = useState([]);
  const removeImage = (id) => {
    setPics((oldState) => oldState.filter((item) => item.id !== id));
  };
  useEffect(() => {
    setPics(GalleryData);
  }, []);
  const [viewmodal, setViewmodal] = useState([]);
  const [number, setNumber] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (value) => {
    setOpen(true);
    setViewmodal(value);
  };
  const handleClose = () => setOpen(false);
  const [toggle, setToggle] = React.useState(false);
  const [sIndex, setSIndex] = React.useState(0);

  // Handler
  const lightBoxHandler = (state, sIndex) => {
    setToggle(state);
    setSIndex(sIndex);
  };

  return (
    <>
      <Container id="gallary" sx={{ maxWidth: "90% !important" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "18px !important",
            marginTop: { md: "60px", xs: "20px" },
          }}
        >
          <Typography
            variant="h3"
            className="contact-data"
            sx={{ fontSize: { md: "48px", xs: "32px" } }}
          >
            Gallery
          </Typography>
          {data?.gallary?.length < 6 ? (
            <Box
              sx={{
                marginLeft: "20px",
                marginTop: { lg: "15px", md: "15px", xs: "0px" },
              }}
            >
              {tokenedit ? (
                <CustomButton
                  btnText="Add"
                  handleClick={() => {
                    handleOpen();
                    setNumber(0);
                  }}
                  startIcon={<AddIcon sx={{ marginRight: "5px" }} />}
                  width="75px"
                  height="30px"
                  border="1px solid #ffffff !important"
                  padding="15px"
                />
              ) : (
                ""
              )}
            </Box>
          ) : (
            ""
          )}
        </Box>
        {/* <Grid container spacing={2}> */}
        {data?.gallary.length === 0 ? (
          <>
            <Box
              sx={{
                margin: "30px 0px",
                padding: "0px !important",
              }}
            >
              {tokenedit ? (
                <Box
                  sx={{
                    border: "2px dashed white",
                    width: { md: "760px", xs: "280px" },
                    height: "210px !important",
                    textAlign: "center",
                    margin: "auto",
                  }}
                  onClick={() => {
                    handleOpen();
                    setNumber(0);
                  }}
                >
                  <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                    <Typography>+</Typography>
                    <Typography>Add Your First Image Here</Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    border: "2px dashed white",
                    width: { md: "760px", xs: "280px" },
                    height: "210px !important",
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                  <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                    <Typography>Gallary is Empty</Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </>
        ) : (
          <Grid container spacing={3}>
            {data?.gallary?.map((value, index) => (
              <Grid item key={index} xs={12} md={6} lg={4}  >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  
                  }}
                >
                  <img
                    className="gallary-img"
                    style={{
                      width: "100%",
                      height: "100% !important",
                    }}
                    src={value.image}
                    onClick={() => {
                      lightBoxHandler(true, index);
                    }}
                    alt=""
                  />
                  {tokenedit ? (
                    <Box
                      sx={{
                        marginLeft: {
                          lg: "-70px !important",
                          xs: "-70px !important",
                        },
                        marginTop: { lg: "8px", md: "15px", xs: "15px" },
                        display: { lg: "block", xs: "flex" },
                      }}
                    >
                      <CustomButton
                      
                        handleClick={() => {
                          handleOpen(value);
                          setNumber(1);
                        }}
                        startIcon={
                          <ModeEditOutlinedIcon sx={{ marginRight: "5px" }} />
                        }
                        width="20px !important"
                        height="20px !important"
                        border="1px solid #ffffff !important"
                        padding="15px"
                      />{" "}
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
      <LightBox
        state={toggle}
        event={lightBoxHandler}
        data={data?.gallary}
        imageWidth="30vw"
        imageHeight="70vh"
        thumbnailHeight={50}
        thumbnailWidth={50}
        setImageIndex={setSIndex}
        imageIndex={sIndex}
      />
      <div>
        <Dialog
          height="auto"
          style={{ zIndex: "9999922" }}
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <Box textAlign="end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <DialogTitle
              style={{ cursor: "move" }}
              align="center"
              mt="-50px"
              id="draggable-dialog-title"
              fontFamily= "DM Sans"
            >
              {viewmodal ? "Update Gallary" : " Upload Gallary"}
            </DialogTitle>
          </Box>

          <VideoForm
            view={viewmodal}
            handleClose={handleClose}
            getOrder={getOrder}
            number={number}
            tokenedit={tokenedit}
          />
        </Dialog>
      </div>
    </>
  );
};

export default Gallery;

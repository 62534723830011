import * as React from "react";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import "./contact.css";
import { toast, ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import "react-toastify/dist/ReactToastify.css";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import CustomButton from "../editButton";
import wImage from "../../../assets/img/Group.png";
import FImage from "../../../assets/img/Facebook.png";
import iImage from "../../../assets/img/Instagram.png";
import LImage from "../../../assets/img/Linkedin.png";
import TImage from "../../../assets/img/Twitter.png";
import MImage from "../../../assets/img/email.png";
import youTube from "../../../assets/img/youTube.png";
import Spotify from "../../../assets/img/spotify.png";
import soundcloud from "../../../assets/img/soundcloud2.png";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { useParams } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import Formsocial from "./contactaddlink";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
function PaperComponent(props) {
  return <Paper {...props} />;
}

const theme = createTheme();
const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  name: yup.string("Enter your FirstName").required("First Name is required"),
  mobile: yup
    .number("Enter your Mobile Number")
    .required("Mobile Number is required"),
});

export default function SignIn({ data, getOrder, tokenedit }) {
  const [viewmodal, setViewmodal] = useState([]);
  const [number, setNumber] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [verfied, setVerifed] = useState(false);
  function onChange(value) {
    setVerifed(true);
  }
  const params = useParams();
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      const payload = {
        name: values.name,
        email: values.email,
        mobile: values.mobile,
      };
      await axios
        .post(
          `${process.env.REACT_APP_DMT_API_PATH}/creative/contact/${params?.id}`,
          payload
        )
        .then((res) => {
          if (res.data.error === false) {
            toast.success("Thank You for Connecting Us");
            resetForm();
          }
        });
    },
  });

  return (
    <Container
      id="contact"
      component="main"
      sx={{ textAlign: "-webkit-center", background: "#0D0D0D !important" }}
    >
      <Paper className="paper-form-contact" component={Box} marginTop="80px">
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{ padding: { md: "1rem 1.25rem;", xs: "none" } }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="h3"
              className="contact-data"
              sx={{ fontSize: { md: "48px", xs: "32px" } }}
            >
              Contact
            </Typography>
            <Box
              sx={{
                marginLeft: "20px",
                marginTop: "8px",
              }}
            >
              {data?.sociallink?.length > 0 && tokenedit ? (
                <CustomButton
                  btnText="Edit"
                  startIcon={
                    <ModeEditOutlinedIcon sx={{ marginRight: "5px" }} />
                  }
                  handleClick={() => {
                    handleOpen();
                    setNumber(1);
                  }}
                  width="75px"
                  height="30px"
                  border="1px solid #ffffff !important"
                  padding="15px"
                />
              ) : tokenedit ? (
                <CustomButton
                  btnText="Add"
                  startIcon={<AddIcon sx={{ marginRight: "5px" }} />}
                  handleClick={() => {
                    handleOpen(data);
                    setNumber(0);
                  }}
                  width="75px"
                  height="30px"
                  border="1px solid #ffffff !important"
                  padding="15px"
                />
              ) : (
                ""
              )}
            </Box>
          </Box>

          <Grid container spacing={4} mt={1}>
            <Grid item md={12} xs={12} className="">
              <Box sx={{ paddingLeft: { md: "1.25rem", xs: "none" } }}>
                <Grid
                  container
                  spacing={1}
                  sx={{ marginTop: { md: "5px", xs: "none" } }}
                >
                  <Grid item md={2.5} xs={12} className="">
                    <Typography
                      sx={{
                        textAlign: "start",
                        color: "white",
                        fontFamily: "Open Sans, sans-serif",
                        fontSize: "16px",
                        fontWeight: 700,
                        // lineHeight: "1rem",
                        marginTop: { md: "15px", xs: "-5px" },
                      }}
                    >
                      Name
                    </Typography>
                  </Grid>
                  <Grid item md={9.5} xs={12} className="">
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label=""
                      placeholder="Type Your Name"
                      {...formik.getFieldProps("name")}
                      onChange={(e) => {
                        formik.setFieldValue("name", e.target.value);
                      }}
                      sx={{
                        background: "rgba(255, 255, 255, 0.05) !important",
                        height: "44px !important",
                        borderRadius: "5px",
                        p: 1,
                        fontFamily: "DM Sans",
                      }}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        background: "rgba(255, 255, 255, 0.05) !important",
                      }}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <Typography
                        sx={{
                          textAlign: "start",
                          marginLeft: "8px",
                          fontSize: "12px",
                        }}
                        color="error"
                      >
                        {formik.errors.name}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item md={12} xs={12} className="">
              <Box sx={{ paddingLeft: { md: "1.25rem", xs: "none" } }}>
                <Grid
                  container
                  spacing={1}
                  sx={{ marginTop: { md: "10px", xs: "5px" } }}
                >
                  <Grid item md={2.5} xs={12} className="">
                    <Typography
                      sx={{
                        textAlign: "start",
                        color: "white",
                        fontFamily: "Open Sans, sans-serif",
                        fontSize: "16px",
                        fontWeight: 700,
                        marginTop: "15px",
                      }}
                    >
                      Mobile Number
                    </Typography>
                  </Grid>
                  <Grid item md={9.5} xs={12} className="">
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label=""
                      placeholder="Type Phone Number Here"
                      {...formik.getFieldProps("mobile")}
                      onChange={(e) => {
                        formik.setFieldValue("mobile", e.target.value);
                      }}
                      sx={{
                        background: "rgba(255, 255, 255, 0.05) !important",
                        height: "44px !important",
                        borderRadius: "5px",
                        p: 1,
                        fontFamily: "DM Sans",
                      }}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        background: "rgba(255, 255, 255, 0.05) !important",
                      }}
                    />
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <Typography
                        sx={{
                          textAlign: "start",
                          marginLeft: "8px",
                          fontSize: "12px",
                        }}
                        color="error"
                      >
                        {formik.errors.mobile}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item md={12} xs={12} className="">
              <Box sx={{ paddingLeft: { md: "1.25rem", xs: "none" } }}>
                <Grid
                  container
                  spacing={1}
                  sx={{ marginTop: { md: "10px", xs: "5px" } }}
                >
                  <Grid item md={2.5} xs={12} className="">
                    <Typography
                      sx={{
                        textAlign: "start",
                        color: "white",
                        fontFamily: "Open Sans, sans-serif",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "1rem",
                        marginTop: "15px",
                      }}
                    >
                      Email Id
                    </Typography>
                  </Grid>
                  <Grid item md={9.5} xs={12} className="">
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label=""
                      placeholder="Type Email Id Here"
                      {...formik.getFieldProps("email")}
                      onChange={(e) => {
                        formik.setFieldValue("email", e.target.value);
                      }}
                      sx={{
                        background: "rgba(255, 255, 255, 0.05) !important",
                        height: "44px !important",
                        borderRadius: "5px",
                        p: 1,
                        fontFamily: "DM Sans",
                      }}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        background: "rgba(255, 255, 255, 0.05) !important",
                      }}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <Typography
                        sx={{
                          textAlign: "start",
                          marginLeft: "8px",
                          fontSize: "12px",
                        }}
                        color="error"
                      >
                        {formik.errors.email}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {/*  <Box mt={2}>
            <ReCAPTCHA
              sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
              onChange={onChange}
            />
          </Box>
                      */}
          <Button
            className="submitprofilebtn"
            type="submit"
            // disabled={!verfied}
            sx={{ textTransform: "capitalize  !important" }}
          >
            Submit
          </Button>
          <Box className="social-second-box" mt={3}>
            <Box>
              {data?.sociallink[0]?.whatsapp ? (
                <a href={data?.sociallink[0]?.whatsapp} target="_blank">
                  <img
                    src={wImage}
                    alt="WhatsApp Link"
                    width={35}
                    height={35}
                  />
                </a>
              ) : (
                ""
              )}
            </Box>

            <Box>
              {data?.sociallink[0]?.facebook ? (
                <a href={data?.sociallink[0]?.facebook} target="_blank">
                  <img
                    src={FImage}
                    alt="Facebook Link"
                    width={45}
                    height={45}
                  />
                </a>
              ) : (
                ""
              )}
            </Box>

            <Box>
              {data?.sociallink[0]?.instagram ? (
                <a href={data?.sociallink[0]?.instagram} target="_blank">
                  <img
                    src={iImage}
                    alt="Instagram Link"
                    width={45}
                    height={45}
                  />
                </a>
              ) : (
                ""
              )}
            </Box>

            {/* <Box>
              <a href="https://in.linkedin.com/">
                <img src={LImage} alt="WhatsApp Link" width={45} height={45} />
              </a>
            </Box> */}

            <Box>
              {data?.sociallink[0]?.youtube ? (
                <a href={data?.sociallink[0]?.youtube} target="_blank">
                  <img
                    src={youTube}
                    alt="Youtube Link"
                    width={40}
                    height={40}
                  />
                </a>
              ) : (
                ""
              )}
            </Box>
            <Box>
            {data?.sociallink[0]?.twitter ? (
              <a href={data?.sociallink[0]?.twitter} target="_blank">
                <img
                  src={TImage}
                  alt="Twitter Link"
                  width={40}
                  height={40}
                />
              </a>
            ) : (
              ""
            )}
          </Box>

            <Box>
              {data?.sociallink[0]?.spotify ? (
                <a href={data?.sociallink[0]?.spotify} target="_blank">
                  <img
                    src={Spotify}
                    alt="Spotify Link"
                    width={40}
                    height={40}
                  />
                </a>
              ) : (
                ""
              )}
            </Box>
            <Box>
              {data?.sociallink[0]?.soundcloud ? (
                <a href={data?.sociallink[0]?.soundcloud} target="_blank">
                  <img
                    src={soundcloud}
                    alt="Soundcloud Link"
                    width={40}
                    height={40}
                  />
                </a>
              ) : (
                ""
              )}
            </Box>

            {/* <Box>
              <a href="https://mail.google.com/mail/">
                <img src={youTube} alt="WhatsApp Link" width={50} height={50} />
              </a>
            </Box> */}
          </Box>
        </Box>
      </Paper>
      <div>
        <Dialog
          height="auto"
          style={{ zIndex: "9999922" }}
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <Box textAlign="end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <DialogTitle
              style={{ cursor: "move" }}
              align="center"
              mt="-50px"
              id="draggable-dialog-title"
              fontFamily= "DM Sans"
            >
              Upload Social Links
            </DialogTitle>
          </Box>
          <Formsocial
            view={data?.sociallink[0]}
            handleClose={handleClose}
            getOrder={getOrder}
            number={number}
            tokenedit={tokenedit}
          />
        </Dialog>
      </div>
    </Container>
  );
}

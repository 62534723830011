import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import moment from "moment/moment";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
}));
const Balance = [
  {
    id: 1,
    amount: "10",
  },
];

const rows = [
  {
    id: 1,
    type: " artis",
    email: "keshri@gmail.com",
    date: "8/12/2022",
    Amount: "1000",
  },
  {
    id: 2,
    type: "singer",
    email: " khushboo@gmail.com",
    date: "8/12/2022",
    Amount: " 2000",
  },
];

export default function CustomizedTables() {
  const [data, setData] = useState([]);
  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;
  const userid = loginToken?.user._id;

  async function getHistory() {
    await axios
      .get(`${process.env.REACT_APP_DMT_API_PATH}/creative/check/${userid}`)
      .then((res) => {
        setData(res?.data?.data[0]?.contact)?.reverse();
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getHistory();
  }, []);
  let reversedArray;
  if (data?.length > 0) {
    reversedArray = [...data]?.reverse();
  }

  const [openSidebar, setOpenSidebar] = useState(false);
  const toggleSidebar = () => {
    setOpenSidebar((p) => !p);
  };
  return (
    // <div className="container">
    //   <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
    //   <div className="main-container">
    //     <Navbar toggleSidebar={toggleSidebar} />
    //     <div className="pages">
          <Box className="custom-dmt-container">
            <Card
              className="masteraudiolist"
              sx={{ background: "rgba(255, 255, 255, 0.05)" }}
            >
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        sx={{ fontFamily: "DM sans !important" }}
                        align="center"
                      >
                        Name{" "}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ fontFamily: "DM sans !important" }}
                        align="center"
                      >
                        Email{" "}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ fontFamily: "DM sans !important" }}
                        align="center"
                      >
                        Mobile
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reversedArray?.map((data, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          sx={{ fontFamily: "DM sans !important" }}
                          align="center"
                        >
                          {data?.name}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontFamily: "DM sans" }}
                          align="center"
                        >
                          {data?.email}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontFamily: "DM sans" }}
                          align="center"
                        >
                          {data?.mobile}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Box>
    //     </div>
    //   </div>
    // </div>
  );
}

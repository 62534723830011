import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import CustomButton from "../../common/customButton/index";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
// import "./banner.css";
import * as yup from "yup";
import { Typography } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import Song from "./song";
import { ScaleLoader } from "react-spinners";
import "react-toastify/dist/ReactToastify.css";

const validationSchema = yup.object().shape({
  listensinger: yup.string().required("Required*"),
  listentitle: yup.string().required("Required*"),
});

const Index = ({ handleClose, view, getOrder, number, tokenedit }) => {
  const [songValue, setSongValue] = useState("");
  const [error, setError] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [AnotherImages, setAnotherImages] = useState({
    banner: view?.listenimage || "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    listensinger: "",
    listentitle: "",
  };
  const handleChnage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
  };

  useEffect(() => {
    if (AnotherImages?.banner) {
      setError("");
    }
  }, [AnotherImages]);
  useEffect(() => {
    if (view) {
      formik.setFieldValue("listensinger", view?.listensinger);
      formik.setFieldValue("listentitle", view?.listentitle);
      setImagePreview(view?.listenimage);
      setSongValue(view?.listensong);
    }
  }, []);

  const onSubmit = async (values) => {
    if (!AnotherImages?.banner) {
      setError("Please select an image file");
      return;
    } else if (!songValue) {
      toast.error("Song is Required");
    } else {
      setIsLoading(true);
      // const loginToken = JSON.parse(localStorage.getItem("login"));
      // const token = loginToken?.token;
      const token = tokenedit;
      var formDatavalue = new FormData();
      formDatavalue.append("listensinger", values.listensinger);
      formDatavalue.append("listentitle", values.listentitle);
      formDatavalue.append("listenimage", AnotherImages.banner);
      formDatavalue.append("listensong", songValue);
      if (number == 0) {
        await axios
          .post(
            `${process.env.REACT_APP_DMT_API_PATH}/creative/listen`,
            formDatavalue,
            {
              headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.error !== true) {
              getOrder();
              setIsLoading(false);
              handleClose();
            } else {
              toast.error(res.data.message);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      } else if (number == 1) {
        await axios
          .put(
            `${process.env.REACT_APP_DMT_API_PATH}/creative/listen/${view?._id}`,
            formDatavalue,
            {
              headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.error !== true) {
              getOrder();
              handleClose();
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <>
      <Box id="contact">
        <Container maxWidth="md" sx={{ minWidth: { md: "600px", xs: "100%" }, padding:"12px" }}>
          {isLoading ? (
            <ScaleLoader
              size={350}
              textAlign="center"
              className="music-loader"
              color="#FBCE2E"
            />
          ) : (
            <Box
              component="form"
              sx={{ mt: "0px" }}
              onSubmit={formik.handleSubmit}
            >
              <Typography
                sx={{
                  marginTop: "10px",
                  marginLeft: "5px",color:"black"
                }}
              >
                Enter Title
              </Typography>
              <TextField
                fullWidth
                id="outlined-basic"
                label=""
                placeholder="Enter Title"
                {...formik.getFieldProps("listentitle")}
                onChange={(e) => {
                  formik.setFieldValue("listentitle", e.target.value);
                }}
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                  border: "1px solid black",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                    inputProps: { style: { color: 'black' }}
                }}
              />
              {formik.touched.listentitle && formik.errors.listentitle ? (
                <Typography
                  sx={{
                    textAlign: "start",
                    marginLeft: "4px",
                    fontSize: "12px",
                  }}
                  color="error"
                >
                  {formik.errors.listentitle}
                </Typography>
              ) : null}
              <Typography
                sx={{
                  marginTop: "10px",
                  marginLeft: "5px",color:"black"
                }}
              >
                Enter Singer Name
              </Typography>
              <TextField
                fullWidth
                id="outlined-basic"
                label=""
                // name="listensinger"
                placeholder="Enter Title"
                {...formik.getFieldProps("listensinger")}
                onChange={(e) => {
                  formik.setFieldValue("listensinger", e.target.value);
                }}
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                  border: "1px solid black",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                    inputProps: { style: { color: 'black' }}
                }}
              />
              {formik.touched.listensinger && formik.errors.listensinger ? (
                <Typography
                  sx={{
                    textAlign: "start",
                    marginLeft: "4px",
                    fontSize: "12px",
                  }}
                  color="error"
                >
                  {formik.errors.listensinger}
                </Typography>
              ) : null}
              <Typography
                sx={{
                  marginTop: "10px",
                  marginBottom: "-12px",
                  marginLeft: "6px",color:"black"
                }}
              >
                Upload Banner
              </Typography>
              <TextField
                type="file"
                name="banner"
                sx={{ marginLeft: "-8px" }}
                onChange={(e) => {
                  handleChnage(e);
                  setAnotherImages({
                    ...AnotherImages,
                    banner: e.target.files[0],
                  });
                }}
              />
              {error && (
                <Typography
                  sx={{
                    textAlign: "start",
                    marginLeft: "12px",
                    fontSize: "12px",
                    marginTop: "-8px",
                  }}
                  color="error"
                >
                  {error}
                </Typography>
              )}
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Preview"
                  width="50px"
                  height="50px"
                />
              )}
              <Song songValue={songValue} setSongValue={setSongValue} />
              <div
                style={{
                  textAlign: "center",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                <CustomButton
                buttonStyle='editFormBtnsecond'
                  btnText={view ? "Save" : "Add"}
                  btntype="Submit"
                />
              </div>
            </Box>
          )}
          <ToastContainer />
        </Container>
      </Box>
    </>
  );
};

export default Index;

import React, { useState } from "react";
import "../royalCong/congo.css";
import Container from "@mui/material/Container";
import { Box, Typography, Stack, TextField } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CustomButton from "../common/customButton/index";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookMessengerShareButton,
} from "react-share";
import {
  WhatsappIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import gif from "../../assets/img/animation1.gif";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import moment from "moment/moment";

function Rcongratulation() {
  const seachQuery = useSearchParams()[0];
  const location = useLocation();

  const [editformdata, setEditformdata] = useState(location?.state?.id);
  const referenceNum = seachQuery.get("reference");
  const handleCopy = () => {
    navigator.clipboard.writeText(this.state.textToCopy);
  };
  const loginToken = JSON?.parse(localStorage.getItem("login"));
  const token = loginToken.token;
  const userid = loginToken.user._id;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const orderID = localStorage?.getItem("order");

  const handleiconclick = async () => {
    const payload = {
      userID: userid,
      orderID: orderID,
      avaliable: "Free",
      music: "delivermytunes",
    };
    axios
      .post(
        `${process.env.REACT_APP_DMT_API_PATH}/socialshare/addsocial`,
        payload,
        config
      )
      .then((res) => {
        if (res.data.error !== true) {
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [openSidebar, setOpenSidebar] = useState(false);
  const toggleSidebar = () => {
    setOpenSidebar((p) => !p);
  };
  console.log(editformdata);
  const royalTitle = `Finally the wait is over! My ${editformdata?.type} ${
    editformdata?.tracks[0]?.name
  } is about to launch on ${moment(editformdata?.releaseDate).format(
    "DD-MM-YYYY"
  )} ${
    editformdata.type === "album" ? "with multiple amazing track" : ""
  } ! Show some of your love and like, share & comment on this post! #delivermytune #${
    editformdata?.tracks[0]?.name
  }`;

  const royalTitleTwitter = `Finally the wait is over! My ${
    editformdata?.type
  } ${editformdata?.tracks[0]?.name} is about to launch on ${moment(
    editformdata?.releaseDate
  ).format("DD-MM-YYYY")} ${
    editformdata.type === "album" ? "with multiple amazing track" : ""
  } ! Show some of your love and like, share & comment on this post! #delivermytune #${
    editformdata?.tracks[0]?.name
  }`;

  const onClickCopy = () => {
    navigator.clipboard.writeText("https://www.delivermytune.com/");
    // alert("Text Copied on Clipboard" , "success");
    toast.success("Text Copied on Clipboard");
  };

  const text = "Deliver My Tune";

  return (
    <div className="container">
      <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
      <div className="main-container">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="pages">
          <Box className="custom-dmt-container">
            <Container
              sx={{
                backgroundColor: "#1E1E1E",
                borderRadius: "15px !important",
                mt: "0px !important",
              }}
            >
              {/* -----------------------Top Box Start--------------------------- */}

              <Box className="rcong-first-box">
                <Box sx={{ mt: "30px !important" }}>
                  <Typography id="cong">Congratulations!</Typography>
                </Box>
                <Box className="cong-msg-box">
                  <Typography id="cong-msg-txt">
                    Your song is now submited to Deliver My Tune successfully.
                    We will get back to you with updates shortly.
                  </Typography>
                </Box>
                <Box>
                  <img src={gif} alt="" width="250px" height="250px" />
                </Box>
              </Box>
              {/* -----------------------Top Box End--------------------------- */}

              {/* <Box className='rcong-second-box'>
                <Box>
                    <a href="https://web.whatsapp.com/">
                        <img src={wImage} alt="WhatsApp Link" width={35} height={35} />
                    </a>
                </Box>

                <Box>
                    <a href="https://www.facebook.com/">
                        <img src={FImage} alt="WhatsApp Link" width={45} height={45} />
                    </a>
                </Box>

                <Box>
                    <a href="https://www.instagram.com/">
                        <img src={iImage} alt="WhatsApp Link" width={45} height={45} />
                    </a>
                </Box>

                <Box>
                    <a href="https://in.linkedin.com/">
                        <img src={LImage} alt="WhatsApp Link" width={45} height={45} />
                    </a>
                </Box>

                <Box>
                    <a href="https://twitter.com/i/flow/login">
                        <img src={TImage} alt="WhatsApp Link" width={45} height={45} />
                    </a>
                </Box>

                <Box>
                    <a href="https://mail.google.com/mail/">
                        <img src={MImage} alt="WhatsApp Link" width={50} height={50} />
                    </a>
                </Box>
            </Box> */}

              <Box sx={{ textAlign: "center !important" }}>
                <WhatsappShareButton url={" "} title={royalTitle}>
                  <WhatsappIcon
                    onClick={handleiconclick}
                    size={40}
                    className=""
                    logofillcolor="white"
                    round={true}
                  ></WhatsappIcon>
                </WhatsappShareButton>
                {/* <FacebookMessengerShareButton
                  className="share-icons"
                  url={royalTitle}
                  //   title={royalTitle}
                >
                  <FacebookIcon
                    onClick={handleiconclick}
                    size={40}
                    className="m-2"
                    logofillcolor="white"
                    round={true}
                  ></FacebookIcon>
                </FacebookMessengerShareButton> */}
                <FacebookShareButton
                  className="share-icons"
                  url={editformdata ? editformdata?.cover : ""}
                  quote={royalTitle}
                  // title={"test its me"}
                >
                  <FacebookIcon
                    onClick={handleiconclick}
                    size={40}
                    className="m-2"
                    logofillcolor="white"
                    round={true}
                  ></FacebookIcon>
                </FacebookShareButton>

                {/* <LinkedinShareButton
                  className="share-icons"
                  url={" "}
                  title={royalTitle}
                >
                  <LinkedinIcon
                    onClick={handleiconclick}
                    size={40}
                    className="m-2"
                    logofillcolor="white"
                    round={true}
                  ></LinkedinIcon>
                </LinkedinShareButton> */}
                <TwitterShareButton
                  className="share-icons"
                  url={" "}
                  title={royalTitleTwitter}
                >
                  <TwitterIcon
                    onClick={handleiconclick}
                    size={40}
                    className="m-2"
                    logofillcolor="white"
                    round={true}
                  ></TwitterIcon>
                </TwitterShareButton>

                <EmailShareButton
                  className="share-icons"
                  url={" "}
                  title={royalTitle}
                >
                  <EmailIcon
                    onClick={handleiconclick}
                    size={40}
                    className="m-2"
                    logofillcolor="white"
                    round={true}
                  ></EmailIcon>
                </EmailShareButton>
              </Box>

              <Box className="rcong-third-box">
                <TextField
                  // placeholder="https://youtu.be/-WEnXVLMjkc"
                  value={text}
                  sx={{
                    width: "500px",
                    backgroundColor: "rgba(255, 255, 255, 0.05) !important",
                    textAlign: "center !important",
                    ".css-9425fu-MuiOutlinedInput-notchedOutline": {
                      borderStyle: "none",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <CustomButton
                        btnText="Copy"
                        buttonStyle="pto"
                        handleClick={onClickCopy}
                        style={{ backgroundColor: "#fab612 !important" }}
                      />
                    ),
                  }}
                />
              </Box>

              <Box
                className="rcong-third-button"
                sx={{ pb: "40px !important", underline: "none" }}
              >
                <Link
                  to="/artist/dashboard"
                  className="dashboard-btn"
                  style={{ textDecoration: "none !important" }}
                >
                  <CustomButton
                    btnText="Go to Dashboard"
                    startIcon={<ArrowBackIcon sx={{ paddingRight: "3px" }} />}
                    buttonStyle="backbtn"
                  />
                </Link>
              </Box>
            </Container>
            <ToastContainer />
          </Box>
        </div>
      </div>
    </div>
  );
}

export default Rcongratulation;

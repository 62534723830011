import React, { useEffect } from "react";
import Earning from "../components/earning/index";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {
  ClickAwayListener,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Pagination from "@mui/material/Pagination";
import BackupIcon from "@mui/icons-material/Backup";
import CustomButton from "../components/common/customButton/index";
import Stack from "@mui/material/Stack";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import axios from "axios";
import moment from "moment/moment";
import usePagination from "../components/dashboardTable/pagination";
import GraphCard from "../components/graphCard/index";
import { BsXCircle } from "react-icons/bs";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Modal from "./royaltymodal";
import { RiErrorWarningLine } from "react-icons/ri";
import earned from "../assets/img/royalty/Rectangle 483 (traced).png";
import rejected from "../assets/img/royalty/Black.png";
import Royaltycard from "../components/dashboardCard/royaltycard";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "center",
  },
}));
const currencies = [
  {
    value: "10",
    label: "10",
  },
  {
    value: "20",
    label: "20",
  },
  {
    value: "30",
    label: "30",
  },
  {
    value: "40",
    label: "40",
  },
];

const selectType = [
  {
    value: "unpaid",
    label: "Unpaid",
  },
  {
    value: "paid",
    label: "Paid",
  },
];

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "153px",
  [theme.breakpoints.up("xs")]: {
    marginLeft: theme.spacing(),
    width: "153px",
    borderRadius: "77px",
    border: "0.5px",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.4, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing()})`,
    transition: theme.transitions.create("width"),
    width: "153px",
    [theme.breakpoints.up("xs")]: {
      width: "153px",
      fontSize: "12px",
    },
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

const RoyaltyEarned = () => {
  const [searchfilter, setSearchfilter] = useState([]);
  const [data, setData] = useState([]);
  const [totalShow, setTotalShow] = React.useState("10");
  let [page, setPage] = useState(1);
  const [positions, setPositions] = useState("");

  const [searchInput, setSearchInput] = useState("");
  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;
  const userid = loginToken?.user._id;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [royaltyreport, setRoyaltyreport] = useState(0);
  async function getRoyalty() {
    await axios
      .get(
        `${process.env.REACT_APP_DMT_API_PATH}/royalty?user_id=${userid}`,
        config
      )
      .then((res) => {
        setData(res?.data?.data.transactions);
        setSearchfilter(res?.data?.data.transactions);
        setRoyaltyreport(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getRoyalty();
  }, []);
  const [Royaltyreportledger, setRoyaltyreportledger] = useState();
  async function getRoyaltyledgeer() {
    await axios
      .get(
        `${process.env.REACT_APP_DMT_API_PATH}/royalty/ledger/${userid}`,
        config
      )
      .then((res) => {
        setData(res?.data?.data);
        setRoyaltyreportledger(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getRoyaltyledgeer();
  }, []);
  console.log(royaltyreport, "royaltyreport");
  const Mock_values = [
    {
      id: 1,
      img: earned,
      title: "Total Earnings Till Date",
      numbers: royaltyreport?.earnings?.toFixed(2),
      icon: <CurrencyRupeeIcon />,
    },
    {
      id: 2,
      img: rejected,
      title: "Royalty Available For Withdrawal",
      numbers: royaltyreport?.outstanding?.toFixed(2),
      icon: <CurrencyRupeeIcon />,
    },
    {
      id: 3,
      img: rejected,
      title: "Royalty 90%",
      numbers: royaltyreport?.royalty_ninety?.toFixed(2),
      icon: <CurrencyRupeeIcon />,
    },
    {
      id: 4,
      img: rejected,
      title: "Royalty 70%",
      numbers: royaltyreport?.royalty_seventy?.toFixed(2),
      icon: <CurrencyRupeeIcon />,
    },
    
  ];
  const [openSidebar, setOpenSidebar] = useState(false);
  const toggleSidebar = () => {
    setOpenSidebar((p) => !p);
  };

  const PER_PAGE = 10;
  const count = Math?.ceil(searchfilter?.length / PER_PAGE);
  const countdata = Math?.ceil(Royaltyreportledger?.length / PER_PAGE);
  const _DATA = usePagination(searchfilter, PER_PAGE);
  const datavalue = usePagination(Royaltyreportledger, PER_PAGE);

  const handleChange2 = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleChange3 = (e, p) => {
    setPage(p);
    datavalue.jump(p);
  };

  const handleChange = (event) => {
    setTotalShow(event.target.value);
  };
  const [opendata, setOpendata] = React.useState(false);
  const handleClickModal = () => {
    setOpendata(true);
  };
  const handleCloseModal = () => {
    setOpendata(false);
  };

  const [select, setSelect] = React.useState(_DATA);

  const handleSelctChange = (event) => {
    setSelect(event.target.value);
  };

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const [currentTab, setCurrentTab] = useState("royalty");

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  return (
    <div className="container">
      <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
      <div className="main-container">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="pages">
          <Box className="custom-dmt-container">
            <Earning />
            <Grid container columnSpacing={1} mt={5}>
              {Mock_values?.map((values, index) => (
                <Grid item xs={6} md={4} lg={3}>
                  <Royaltycard detalis={values} key={index} />
                </Grid>
              ))}
            </Grid>
            <Grid container columnSpacing={1}>
              <Grid item xs={12} lg={12}>
                <Box
                  sx={{
                    mt: "0px",
                    mb: "30px",
                    display: { xs: "block", md: "flex" },
                    justifyContent: "end",
                  }}
                ></Box>
                <Tabs
                  value={currentTab}
                  onChange={handleTabChange}
                  centered
                  indicatorColor="primary"
                  textColor="primary"
                  TabIndicatorProps={{
                    className: "tabs-indicator", // Custom class for the tabs line
                  }}
                >
                  <Tab
                    label="Royalty"
                    value="royalty"
                    sx={{ margin: "10px !important" }}
                    className={currentTab === "royalty" ? "nextbtn" : ""}
                  />
                  <Tab
                    label="Ledger"
                    value="ledger"
                    className={currentTab === "ledger" ? "nextbtn" : ""}
                    sx={{
                      margin: "10px !important",
                    }}
                  />
                </Tabs>
                <TableContainer
                  component={Paper}
                  sx={{
                    my: "20px",
                    borderRadius: "10px",
                    width: "auto !important",
                  }}
                >
                  {currentTab === "royalty" && (
                    <>
                      <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              sx={{ fontFamily: "DM sans" }}
                              align="center"
                            >
                              {" "}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ fontFamily: "DM sans" }}
                              align="center"
                            >
                              VALUES
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ fontFamily: "DM sans" }}
                              align="center"
                            >
                              DATE
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ fontFamily: "DM sans" }}
                              align="center"
                            >
                              STATUS
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ fontFamily: "DM sans" }}
                              align="center"
                            >
                              DOWNLOAD
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {_DATA.currentData()?.map((item, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell
                                align="center !important"
                                component="th"
                                scope="row"
                              >
                                <Box
                                  className={`${
                                    item?.status === "unpaid"
                                      ? "color-black"
                                      : item?.status === "pending"
                                      ? "hello"
                                      : item?.status === "paid"
                                      ? "color-ditrub"
                                      : "color-custom"
                                  }`}
                                  sx={{
                                    width: "40px",
                                    height: "40px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "12px",
                                    fontFamily: "DM sans",
                                  }}
                                >
                                  {item?.status === "paid" ? (
                                    <AiOutlineCheckCircle fontSize={"20px"} />
                                  ) : item?.status === "pending" ? (
                                    <RiErrorWarningLine fontSize={"20px"} />
                                  ) : item?.status === "unpaid" ? (
                                    <BsXCircle fontSize={"17px"} />
                                  ) : (
                                    ""
                                  )}
                                </Box>
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ fontFamily: "DM sans" }}
                                align="center"
                              >
                                {item?.value?.toFixed(2)}
                              </StyledTableCell>

                              <StyledTableCell
                                sx={{ fontFamily: "DM sans" }}
                                align="center"
                              >
                                {/* {moment(item?.updated_at).format("DD-MM-YYYY")} */}
                                {item?.month} {item?.year}
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ fontFamily: "DM sans" }}
                                align="center"
                              >
                                <Button
                                  sx={{
                                    borderRadius: "18px",
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    padding: "5px 20px",
                                    fontFamily: "DM sans",
                                    textTransform: "capitalize",
                                  }}
                                  className={`${
                                    item?.status === "unpaid"
                                      ? "color-black"
                                      : item?.status === "pending"
                                      ? "hello"
                                      : item?.status === "paid"
                                      ? "color-ditrub"
                                      : " color-custom"
                                  }`}
                                >
                                  {item?.status}
                                </Button>
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                sx={{ fontFamily: "DM sans" }}
                              >
                                <a href={item?.file} rel="noreferrer">
                                  <DownloadIcon sx={{ color: "#f9a40d " }} />
                                </a>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Box
                        sx={{
                          display: { sm: "flex" },
                          justifyContent: "space-between",
                          mt: "35px",
                          mb: "15px",
                        }}
                      >
                        <Typography
                          sx={{
                            pl: { xs: "10px", sm: "50px" },
                            fontFamily: "DM sans",
                          }}
                        >
                          {/* Showing 1 to {PER_PAGE} of {data.length} entries */}
                        </Typography>
                        <Stack spacing={2} sx={{ pr: { sm: "50px" } }}>
                          <Pagination
                            color="warning"
                            count={count}
                            onChange={handleChange2}
                            page={page}
                            variant="outlined"
                          />
                        </Stack>
                      </Box>
                    </>
                  )}
                  {currentTab === "ledger" && (
                    <>
                      <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              sx={{ fontFamily: "DM sans" }}
                              align="center"
                            >
                              ITEMS
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ fontFamily: "DM sans" }}
                              align="center"
                            >
                              AMOUNT
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ fontFamily: "DM sans" }}
                              align="center"
                            >
                              DATE
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ fontFamily: "DM sans" }}
                              align="center"
                            >
                              TRAN.TYPE
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ fontFamily: "DM sans" }}
                              align="center"
                            >
                              STATUS
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {datavalue?.currentData()?.map((item, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell
                                sx={{ fontFamily: "DM sans" }}
                                align="center"
                              >
                                {item?.item}
                              </StyledTableCell>

                              <StyledTableCell
                                sx={{
                                  fontFamily: "DM sans",
                                  color:
                                    item?.status === "Debit"
                                      ? "red"
                                      : item?.status === "Credit"
                                      ? "#49CD61"
                                      : "white",
                                }}
                                align="center"
                              >
                                {/* {moment(item?.updated_at).format("DD-MM-YYYY")} */}
                                {item?.amount?.toFixed(2)}
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ fontFamily: "DM sans" }}
                                align="center"
                              >
                                {moment(item?.date)?.format("DD-MM-YYYY")}
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ fontFamily: "DM sans" }}
                                align="center"
                              >
                                <Button
                                  sx={{
                                    borderRadius: "18px",
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    padding: "5px 20px",
                                    fontFamily: "DM sans",
                                    textTransform: "capitalize",
                                  }}
                                  className={`${
                                    item?.status === "Debit"
                                      ? "color-black"
                                      : item?.status === "Credit"
                                      ? "color-ditrub"
                                      : " color-custom"
                                  }`}
                                >
                                  {item?.status}
                                </Button>
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ fontFamily: "DM sans" }}
                                align="center"
                              >
                                {item?.status === "Credit"
                                  ? "Payable By DMT"
                                  : "Paid to DMT"}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Box
                        sx={{
                          display: { sm: "flex" },
                          justifyContent: "space-between",
                          mt: "35px",
                          mb: "15px",
                        }}
                      >
                        <Typography
                          sx={{
                            pl: { xs: "10px", sm: "50px" },
                            fontFamily: "DM sans",
                          }}
                        >
                          {/* Showing 1 to {PER_PAGE} of {data.length} entries */}
                        </Typography>
                        <Stack spacing={2} sx={{ pr: { sm: "50px" } }}>
                          <Pagination
                            color="warning"
                            count={countdata}
                            onChange={handleChange3}
                            page={page}
                            variant="outlined"
                          />
                        </Stack>
                      </Box>
                    </>
                  )}
                </TableContainer>
              </Grid>
              {/* <Modal opendata={opendata} handleCloseModal={handleCloseModal} handleClickModal ={handleClickModal}/> */}
            </Grid>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default RoyaltyEarned;

import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import upcoming from "../../../assets/img/upcoming.png";
import { MdOutlineLocationOn } from "react-icons/md";
import { AiOutlineClockCircle } from "react-icons/ai";
import "./upcoming.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CustomButtonedit from "../thirdEditBtn";
import CloseIcon from "@mui/icons-material/Close";
import ProgramEdit from "./upcomingEditForm";
import moment from "moment";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";

function convertTimeFormat(time) {
  var hours = parseInt(time.substr(0, 2));
  var minutes = parseInt(time.substr(3, 2));
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

function PaperComponent(props) {
  return <Paper sx={{ background: "white" }} {...props} />;
}

const Upcoming = ({ data, getOrder, tokenedit }) => {
  const [viewmodal, setViewmodal] = useState([]);
  const [number, setNumber] = useState(0);
  const [open, setOpen] = React.useState(false);

  const handleOpen = (value) => {
    setOpen(true);
    setViewmodal(value);
  };

  const handleClose = () => setOpen(false);
  const cardData = [
    {
      id: 1,
      image: upcoming,
      Date: "Feb 5, 2022 | Sunday",
      title:
        " Nulla porttitor accumsan tincidunt. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.",
      name: "  Cadillac Arena",
    },
    {
      id: 2,
      image: upcoming,
      Date: "Feb 9, 2022 | Thursday",
      title:
        "  Nulla porttitor accumsan tincidunt. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.2",
      name: "Arlo Brown Ft. Sza",
    },
    {
      id: 3,
      image: upcoming,
      Date: "Feb 17, 2022 | Thursday",
      title:
        "  Nulla porttitor accumsan tincidunt. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.3",
      name: "Arlo Brown Ft. Sza",
    },
    {
      id: 4,
      image: upcoming,
      Date: "Feb 9, 2022 | Thursday",
      title:
        "  Nulla porttitor accumsan tincidunt. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.4",
      name: "  Cadillac Arena",
    },
  ];

  const slidesToShow = data?.events?.length >= 2 ? 1.7 : 1;
  const settings = {
    fade: false,
    dots: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    initialSlide: 1,
    infinite: true,
    arrows: false,
    centerMode: false,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: slidesToShow,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <Box
      className="upcomingprogram"
      sx={{ background: "#4DCAB4", minHeight: "600px" }}
    >
      <Container>
        <Typography
          sx={{
            fontSize: { md: "56px", xs: "36px" },
            fontFamily: "Monoton",
            textAlign: "center",
            textTransform: "uppercase",
            pt: { xs: 6, md: 6 },
          }}
        >
          Upcoming Programs
        </Typography>
        <Box
          sx={{
            textAlign: "center",
            marginLeft: { md: "20px", xs: "0px" },
            marginTop: { lg: "15px", md: "15px", xs: "0px" },
          }}
        >
          {tokenedit ? (
            <CustomButtonedit
              btnText="Add"
              handleClick={() => {
                handleOpen();
                setNumber(0);
              }}
              startIcon={<AddIcon sx={{ marginRight: "5px" }} />}
              width="75px"
              height="30px"
              border="1px solid #ffffff !important"
              padding="15px"
            />
          ) : (
            ""
          )}
        </Box>

        {data?.events?.length === 0 ? (
          <>
            <Box
              sx={{
                margin: "30px 0px",
                padding: "0px !important",
              }}
            >
              {tokenedit ? (
                <Box
                  sx={{
                    border: "2px dashed white",
                    width: { md: "760px", xs: "280px" },
                    height: "210px !important",
                    textAlign: "center",
                    margin: "auto",
                  }}
                  onClick={() => {
                    handleOpen();
                    setNumber(0);
                  }}
                >
                  <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "30px",
                        lineHeight: "20px",
                      }}
                    >
                      +
                    </Typography>
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      Add Your Upcoming Concerts Here
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    border: "2px dashed white",
                    width: { md: "760px", xs: "280px" },
                    height: "210px !important",
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      marginTop: "75px",
                      color: "white",
                    }}
                  >
                    {/* <Typography></Typography> */}
                    <Typography>Programs is Empty</Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </>
        ) : (
          <Box textAlign="-webkit-center">
            <Slider className="upcomingConcerts" {...settings}>
              {data?.events?.map((item, index) => (
                <Box>
                  <Card
                    key={index}
                    sx={{
                      borderRadius: "16px",
                      height: { xs: "100%", md: "306px" },
                      width: { lg: "800px", md: "700px", xs: "auto" },
                      background: "white",
                      m: 3,
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            height: {
                              xs: "280px",
                              sm: "306px",
                              md: "306px",
                              width: { md: "256px", sm: "280px", xs: "250px" },
                              overflow: "hidden",
                            },
                          }}
                        >
                          <img
                            src={item?.eventimage}
                            alt=""
                            width={"auto"}
                            height="100%"
                            style={{ borderRadius: "16px" }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={8} sx={{ p: 2.3 }}>
                        <Box>
                          <Box
                            sx={{
                              display: { sm: "flex" },
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "26px",
                                lineHeight: "36px",
                                fontFamily: "Monoton",
                                color: "#1E1E1E",
                              }}
                            >
                              {item?.eventtitle}
                            </Typography>
                            <Box
                              sx={{
                                marginLeft: {
                                  lg: "70px",
                                  xs: "10px !important",
                                },

                                display: { lg: "block", xs: "flex" },
                              }}
                            >
                              {tokenedit ? (
                                <CustomButtonedit
                                  handleClick={() => {
                                    handleOpen(item);
                                    setNumber(1);
                                  }}
                                  startIcon={
                                    <ModeEditOutlinedIcon
                                      sx={{ marginRight: "5px" }}
                                    />
                                  }
                                  width="20px !important"
                                  height="20px !important"
                                  border="1px solid #00D8C0 !important"
                                  padding="15px"
                                />
                              ) : (
                                ""
                              )}
                            </Box>
                          </Box>
                          <Typography
                            sx={{
                              textAlign: "left",
                              mt: 1.5,
                              fontSize: "14px",
                              lineHeight: "24px",
                              fontFamily: "Open Sans",
                              textTransform: "uppercase",
                              color: "rgba(30, 30, 30, 0.8)",
                              wordBreak: "break-all",
                            }}
                          >
                            {item?.eventdesc}
                          </Typography>

                          <Box sx={{ display: "flex", mt: 1.5 }}>
                            <MdOutlineLocationOn
                              color="#00D8C0"
                              fontSize="22px"
                            />
                            <Typography
                              sx={{
                                textAlign: "left",
                                fontSize: "14px",
                                ml: 2,
                                lineHeight: "16px",
                                fontFamily: "Open Sans",
                                color: "#141414",
                              }}
                            >
                              {item?.eventlocation}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", mt: 1.5, mb: 2 }}>
                            <AiOutlineClockCircle
                              color="#00D8C0"
                              fontSize="18px"
                            />
                            <Typography
                              sx={{
                                textAlign: "left",
                                fontSize: "14px",
                                ml: 2,
                                lineHeight: "16px",
                                fontFamily: "Open Sans",
                                color: "#141414",
                              }}
                            >
                              {" "}
                              {convertTimeFormat(item?.eventtime)}{" "}
                            </Typography>
                          </Box>
                          <Typography
                            sx={{
                              textAlign: "left",
                              fontSize: "14px",
                              lineHeight: "16px",
                              fontFamily: "Open Sans",
                              color: "#141414",
                              mt: 1,
                            }}
                          >
                            {moment(item?.eventDate).format("ll")} |{" "}
                            {moment(item?.eventDate).format("dddd")}
                          </Typography>
                          <Box textAlign="left">
                            <a href={item?.eventlink} target="_blank">
                              <Button
                                variant="contained"
                                className="book"
                                sx={{ mt: 2.5 }}
                              >
                                Book Now
                              </Button>
                            </a>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                </Box>
              ))}
            </Slider>
          </Box>
        )}
      </Container>

      <div>
        <Dialog
          height="auto"
          style={{ zIndex: "9999922" }}
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <Box textAlign="end">
            <IconButton sx={{ color: "black" }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <DialogTitle
              style={{ cursor: "move" }}
              align="center"
              mt="-50px"
              id="draggable-dialog-title"
              sx={{ color: "black" }}
            >
              Programs
            </DialogTitle>
          </Box>

          <ProgramEdit
            view={viewmodal}
            handleClose={handleClose}
            getOrder={getOrder}
            number={number}
            tokenedit={tokenedit}
          />
        </Dialog>
      </div>
    </Box>
  );
};
export default Upcoming;

import React from "react";
import Plan from "../components/plan/index";
import Billing from "../components/billing/index";
import BasicDetailsdata from "../components/basicDetails/index";
import Box from "@mui/material/Box";
import AdvancedDetails from "../components/advancedDetails/advanceMulti";
import PublishDetails from "../components/publishingDetails/index";
import { useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import Container from "@mui/material/Container";
import Stepper from "../components/progressBar/index";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IoIosArrowForward } from "react-icons/io";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { userSlice } from "../redux/slices/authSlice";
import dayjs from "dayjs";
import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ScaleLoader } from "react-spinners";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { choosePlatfrom } from "../components/publishingDetails/utils";
import { isEmptyObject } from "../utils/isEmptyObject";
import Declaration from "../components/declaration/index";

const DistributeSingleTrack = ({
  songValue,
  setSongValue,
  career,
  songalbum,
  saveImage,
  setSaveimage,
}) => {
  const navigate = useNavigate();

  //useState Call
  const [albumkey, setAlbumkey] = useState("album");
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = React.useState(career?.releaseDate || null);
  const [ringtone, setRingtone] = useState();
  const [paymentdetail, setPaymentDetail] = useState(0);
  const [songprocessing,setSongprocessing]=useState(false);
  const [moviesong, setMoviesong] = useState(false);
  const [tracktomaster, setTracktomaster] = useState(false);
  const [platform, setPlatform] = useState(choosePlatfrom);
  const [paymentget, setPaymentget] = useState(0);
  const [paymentidget, setPaymentidget] = useState([]);
  const [coverSongFile, setCoverfile] = useState();
  const [totalamount, setTotalamount] = useState(0);
  const [walletamount, setWalletamount] = useState(0);
  const [billshow, setBillshow] = useState();
  const [isUnsplash,setisUnsplash]=useState(false);
  const [error, setError] = useState(false);
  const [userinfo, setUserinfo] = useState();
  const [backflow, setBackflow] = useState(false);
  const [isInstagramprofile, setIsInstagramprofile] = useState(false);
  const [seconderror, setSecondError] = useState(false);
  const [thirdError, setThirdError] = useState(false);
  const [showuserdata, setShowuserdata] = useState([]);
  const [amount, setAmount] = useState();
  const [donate, setDonateValue] = useState(0);
  const firstslidedata = useSelector((state) => state?.user);
  const [filedata, setFiledata] = useState(
    career?.cover || firstslidedata?.cover || ""
  );
  const [songLyrics, setSongLyrics] = useState(
    career?.songLyrics || firstslidedata?.songLyrics || ""
  );
  const [finalvalue, setFinalvalue] = useState(career?.tracks || []);
  const [checkeddeclaration, setCheckeddeclaration] = useState({
    artwork: false,
    declaration: false,
    copyrighted: false,
    lyrics: false,
    pause: false,
    composition: false,
  });

  const [formData, setFormData] = useState({
    name: career?.name || "",
    artwork: career?.cover || firstslidedata?.cover || "",
    recordlabel: career?.recordLabel || "",
    songlang: career?.language || "",
    songgenre: career?.genre || "",
    spotifyId: userinfo?.spotifyId || "",
    appleId: userinfo?.appleId || "",
    channelId: userinfo?.channelId || "",
    previewstart: career?.tracks[0]?.previewStartTime || "",
    releasedate: career?.releaseDate || "",
    moviesong: career?.filmBoolean || moviesong,
    filmname: career?.filmDetail?.name || "",
    directorname: career?.filmDetail?.artists[0]?.name || "",
    actorname: career?.filmDetail?.artists[1]?.name || "",
    actressname: career?.filmDetail?.artists[2]?.name || "",
    isInstagramprofile:career?.isInstagramprofile || isInstagramprofile,
    fbemail: userinfo?.fbemail || "",
    instagramemail: userinfo?.instagramemail || "",
    instagrampage: userinfo?.instagrampage || "",
    fbpage: userinfo?.fbpage || "",
    tracktomaster: career?.isMastered || tracktomaster,
    copyrightyear: career?.copyright?.name || "",
    productionright: career?.productionRight?.name || "",
    chooseplatform: career?.platforms,
    plan: "",
    phone: "",
    otp: "",
    coupon: "",
  });

  const [multiformadv, setmultiformadv] = useState({
    _id: "",
    name: "",
    videoLink: "",
    isrcCode: "",
    compositionType: "",
    coverSongFile: "",
    cleanExplicit: "",
    file: "",
    ringtone: "",
  });
  const [artistdetail, setArtistdetail] = useState({
    primaryfirstname: "",
    primarylastname: "",
    primaryspotifyid: "",
    primaryinstagram: "",
    primarytwitter: "",
    primaryfacebook: "",
    featuringfirstname: "",
    featuringlastname: "",
    featuringspotifyid: "",
    featuringinstagram: "",
    featuringtwitter: "",
    featuringfacebook: "",
    lyricistfirstname: "",
    lyricistlastname: "",
    lyricistspotifyid: "",
    lyricistinstagram: "",
    lyricisttwitter: "",
    lyricistfacebook: "",
    composerfirstname: "",
    composerlastname: "",
    composerspotifyid: "",
    composerinstagram: "",
    composertwitter: "",
    composerfacebook: "",
  });
  const [listData, setListData] = useState([
    { role: "", name: "", lastname: "",spotifyid: "", facebook: "", instagram: "", twitter: "" },
  ]);
  //useEffect Call
  useEffect(() => {
    if (songalbum) {
      setmultiformadv({
        ...multiformadv,
        file: songalbum,
      });
    }
  }, [songalbum]);

  useEffect(() => {
    if (career?.tracks[0]?.file) {
      setSongValue(career?.tracks[0]?.file);
    }
    if(career?.isInstagramprofile){
      setIsInstagramprofile(career.isInstagramprofile)
    }
  }, [career]);

  useEffect(() => {
    if (career?.tracks[0]?.ringtone) {
      setRingtone(career?.tracks[0]?.ringtone);
    }
  }, [career]);
  useEffect(() => {
    if (career?.releaseDate) {
      setValue(career?.releaseDate);
    }
  }, [career]);
  useEffect(() => {
    if (career?.filmBoolean) {
      setMoviesong(career?.filmBoolean);
    }
  }, [career]);
  useEffect(() => {
    if (career?.isMastered) {
      setTracktomaster(career?.isMastered);
    }
  }, [career]);
  useEffect(() => {
    if (career) {
      setCheckeddeclaration({
        artwork: career.declaration.artwork ?? false,
        declaration: career.declaration.declaration ?? false,
        copyrighted: career.declaration.copyrighted ?? false,
        lyrics: career.declaration.lyrics ?? false,
        pause: career.declaration.pause ?? false,
        composition: career.declaration.composition ?? false,
      });
    }
  }, [career]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_DMT_API_PATH}/users`, config);
        if (response?.data?.error !== true) {
          const userData = response?.data?.data;
          setUserinfo(userData);
          setFormData((prevFormData) => ({
            ...prevFormData,
            spotifyId: userData?.spotifyId || "",
            appleId: userData?.appleId || "",
            channelId: userData?.channelId || "",
            fbemail: userData?.fbemail || "",
            instagramemail: userData?.instagramemail || "",
            instagrampage: userData?.instagrampage || "",
            fbpage: userData?.fbpage || "",
            // Update other fields as needed
          }));
          setIsLoading(false);
        }
      } catch (error) {
        // Handle errors if necessary
        console.log(error);
      }
    };
    fetchData();
  }, []);
  //Function Call
  const handleAddtag = () => {
    return "return gtag_report_conversion('http://example.com/your-link')";
  };
  const toggleSidebar = () => {
    dispatch(userSlice.actions.saveUser(""));
    setSongValue(undefined);
    setOpenSidebar((p) => !p);
  };
  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;
  const seachQuery = useSearchParams()[0];
  const referenceNum = seachQuery.get(":id");
  let datevalue = JSON.stringify(value?.$d);
  datevalue = datevalue?.slice(1, 11);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const handleChange = async () => {
    const today = new Date();
    const lastDay = new Date(today.getTime() + 4* 24 * 60 * 60 * 1000);
    var mydate = new Date(value);
    if (
      formData.name.length == 0 ||
      formData.recordlabel.length == 0 ||
      formData.songlang.length == 0 ||
      formData.songgenre.length == 0
    ) {
      setError(true);
      return;
    }
    if (!filedata) {
      toast.error("Cover is required");
    } else if (formData?.songlang === "Others") {
      toast.error("Song Language is required");
    } else if (!career && value?.$d === undefined) {
      toast.error("Release Date is required");
    } else if (mydate < lastDay) {
      toast.error("Invalid Release Date");
    } else {
      const payload = {
        name: formData.name,
        cover: formData.artwork,
        recordLabel: formData.recordlabel,
        language: formData.songlang,
        genre: formData.songgenre,
        trackspreviewStartTime: formData.previewstart,
        releaseDate: formData.releasedate,
        name: formData.filmname,
        mainArtist: formData.actorname,
      };
      setIsLoading(true);
      var formDatavalue = new FormData();

      if (moviesong === true) {
        formDatavalue.append("filmBoolean", moviesong);
        formDatavalue.append("filmDetail[name]", formData.filmname);
        formDatavalue.append("filmDetail[artists][0][role]", "director");
        formDatavalue.append(
          "filmDetail[artists][0][name]",
          formData.directorname
        );
        formDatavalue.append("filmDetail[artists][1][role]", "actor");
        formDatavalue.append(
          "filmDetail[artists][1][name]",
          formData.actorname
        );
        formDatavalue.append("filmDetail[artists][2][role]", "actress");
        formDatavalue.append(
          "filmDetail[artists][2][name]",
          formData.actressname
        );
      } else {
        formDatavalue.append("filmBoolean", moviesong);
      }
      formDatavalue.append("name", formData.name);
      formDatavalue.append("cover", filedata);
      formDatavalue.append("recordLabel", formData.recordlabel);
      formDatavalue.append("language", formData.songlang);
      formDatavalue.append("genre", formData.songgenre);
      formDatavalue.append("songLyrics", songLyrics);
      formDatavalue.append("releaseDate", value?.$d || value);
      formDatavalue.append("type", "album");
      formDatavalue.append("platforms[0]", "");
      if (formData.spotifyId || formData.appleId || formData.channelId) {
        await axios.put(
          `${process.env.REACT_APP_DMT_API_PATH}/users`, 
          {
            spotifyId: formData.spotifyId,
            appleId: formData.appleId,
            channelId: formData.channelId,
          },
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        ).then((res) => {
        })
        .catch((err) => {
          console.log(err);
        });
      }
      if (career) {
        await axios
          .put(
            `${process.env.REACT_APP_DMT_API_PATH}/orders/${career._id}`,
            formDatavalue,
            {
              headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.error !== true) {
              dispatch(userSlice.actions.saveUser(res.data.data));
              setIsLoading(false);
              setPage((currPage) => currPage + 1);
              window.scrollTo(0, 0);
            } else {
              toast.error(res.data.message);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (firstslidedata._id) {
        await axios
          .put(
            `${process.env.REACT_APP_DMT_API_PATH}/orders/${firstslidedata._id}`,
            formDatavalue,
            {
              headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.error !== true) {
              setIsLoading(false);
              setPage((currPage) => currPage + 1);
              window.scrollTo(0, 0);
            } else {
              toast.error(res.data.message);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        await axios
          .post(`${process.env.REACT_APP_DMT_API_PATH}/orders`, formDatavalue, {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.data.error !== true) {
              dispatch(userSlice.actions.saveUser(res.data.data));
              setIsLoading(false);
              setPage((currPage) => currPage + 1);
              window.scrollTo(0, 0);
            } else {
              toast.error(res.data.message);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const handleSecondSubmit = async () => {
    if (finalvalue.length === 0) {
      toast.error("Please Click Add Or Update song Button for Moving ahead");
    } else if (
      multiformadv.name.length > 0 ||
      multiformadv.videoLink.length > 0 ||
      multiformadv.compositionType.length > 0 ||
      multiformadv.cleanExplicit.length > 0
    ) {
      toast.error("Please Click Add Or Update song Button for Moving ahead");
    } else {
      const formDatavalue = new FormData();
      for (let i = 0; i < finalvalue.length; i++) {
        formDatavalue.append(`tracks[${i}][name]`, finalvalue[i].name);
        formDatavalue.append(
          `tracks[${i}][videoLink]`,
          finalvalue[i].videoLink
        );
        formDatavalue.append(`tracks[${i}][isrcCode]`, finalvalue[i].isrcCode);
        formDatavalue.append(
          `tracks[${i}][compositionType]`,
          finalvalue[i].compositionType
        );
        if (finalvalue[i]?.compositionType === "cover") {
          formDatavalue.append(
            `tracks[${i}][coverSongFile]`,
            finalvalue[i].coverSongFile
          );
        }
        
        formDatavalue.append(`tracks[${i}][file]`, finalvalue[i].file);
        formDatavalue.append(
          `tracks[${i}][cleanExplicit]`,
          finalvalue[i].cleanExplicit
        );
        formDatavalue.append(`tracks[${i}][ringtone]`, finalvalue[i].ringtone);
        for (let j = 0; j < finalvalue[i].artists.length; j++) {
          for (let k in finalvalue[i].artists[j]) {
            if (finalvalue[i].artists[j][k] !== "") {
              formDatavalue.append(
                `tracks[${i}][artists][${j}][${k}]`,
                finalvalue[i].artists[j][k]
              );
            }
          }
        }
      }
      setIsLoading(true);

      formDatavalue.append("type", "album");
      formDatavalue.append("cover", firstslidedata.cover);
      formDatavalue.append("recordLabel", formData.recordlabel);
      formDatavalue.append("language", firstslidedata.language);
      formDatavalue.append("genre", firstslidedata.genre);
      formDatavalue.append("songLyrics", firstslidedata.songLyrics);
      formDatavalue.append(
        "tracks[0][previewStartTime]",
        formData.previewstart
      );
      formDatavalue.append("releaseDate", value?.$d || value);
      formDatavalue.append("isMastered", tracktomaster);
      formDatavalue.append("isInstagramprofile", isInstagramprofile);
      formDatavalue.append("platforms[0]", "");

      await axios
        .put(
          `${process.env.REACT_APP_DMT_API_PATH}/orders/${firstslidedata._id}`,
          formDatavalue,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error !== true) {
            dispatch(userSlice.actions.saveUser(res.data.data));
            setPage((currPage) => currPage + 1);
            window.scrollTo(0, 0);
            setIsLoading(false);
            setSongValue(undefined);
          } else {
            toast.error(res.data.message);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handledeclarationSubmit = async () => {
    console.log(checkeddeclaration, "checkeddeclaration");
    if (
      checkeddeclaration.artwork &&
      checkeddeclaration.declaration &&
      checkeddeclaration.copyrighted &&
      checkeddeclaration.lyrics &&
      checkeddeclaration.composition &&
      checkeddeclaration.pause
    ) {
      setIsLoading(true);
      const payload = {
        artwork: checkeddeclaration?.artwork,
        declaration: checkeddeclaration?.declaration,
        copyrighted: checkeddeclaration?.copyrighted,
        lyrics: checkeddeclaration?.lyrics,
        composition: checkeddeclaration?.composition,
        pause: checkeddeclaration?.pause,
      };
      await axios
        .put(
          `${process.env.REACT_APP_DMT_API_PATH}/orders/${firstslidedata._id}/declaration`,
          payload,
          config
        )
        .then((res) => {
          if (res.data.error !== true) {
            setIsLoading(false);
            setPage((currPage) => currPage + 1);
            window.scrollTo(0, 0);
          } else {
            toast.error(res.data.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      toast.error("Please check all checkboxes");
    }
  };

  const handleThirdSubmit = async () => {
    if (
      formData.productionright.length === 0 ||
      formData.copyrightyear.length === 0
    ) {
      setThirdError(true);
    } else {
      const filtered = platform.filter((employee) => {
        return employee.isChecked === true;
      });
      const formDatavalue = new FormData();
      const listItems = filtered.map((details, index) =>
        formDatavalue.append(`platforms[${index}]`, details.name)
      );
      setIsLoading(true);
      if (moviesong === true) {
        formDatavalue.append("filmBoolean", moviesong);
        formDatavalue.append("filmDetail[name]", formData.filmname);
        formDatavalue.append("filmDetail[artists][0][role]", "director");
        formDatavalue.append(
          "filmDetail[artists][0][name]",
          formData.directorname
        );
        formDatavalue.append("filmDetail[artists][1][role]", "actor");
        formDatavalue.append(
          "filmDetail[artists][1][name]",
          formData.actorname
        );
        formDatavalue.append("filmDetail[artists][2][role]", "actress");
        formDatavalue.append(
          "filmDetail[artists][2][name]",
          formData.actressname
        );
      } else {
        formDatavalue.append("filmBoolean", moviesong);
      }

      formDatavalue.append("name", formData?.name);
      formDatavalue.append("type", "album");
      formDatavalue.append("cover", firstslidedata?.cover);
      formDatavalue.append("recordLabel", formData?.recordlabel);
      formDatavalue.append("language", firstslidedata?.language);
      formDatavalue.append("genre", firstslidedata?.genre);
      formDatavalue.append("songLyrics", firstslidedata.songLyrics);
      formDatavalue.append(
        "tracks[0][previewStartTime]",
        formData.previewstart
      );
      formDatavalue.append("releaseDate", value?.$d || value);
      formDatavalue.append("isMastered", tracktomaster);
      formDatavalue.append("isInstagramprofile", isInstagramprofile);
      for (let i = 0; i < finalvalue.length; i++) {
        formDatavalue.append(`tracks[${i}][name]`, finalvalue[i].name);
        formDatavalue.append(
          `tracks[${i}][videoLink]`,
          finalvalue[i].videoLink
        );
        formDatavalue.append(`tracks[${i}][isrcCode]`, finalvalue[i].isrcCode);
        formDatavalue.append(
          `tracks[${i}][compositionType]`,
          finalvalue[i].compositionType
        );
        if (finalvalue[i]?.compositionType === "cover") {
          formDatavalue.append(
            `tracks[${i}][coverSongFile]`,
            finalvalue[i].coverSongFile
          );
        }
        formDatavalue.append(`tracks[${i}][file]`, finalvalue[i].file);
        formDatavalue.append(
          `tracks[${i}][cleanExplicit]`,
          finalvalue[i].cleanExplicit
        );
        formDatavalue.append(`tracks[${i}][ringtone]`, finalvalue[i].ringtone);
        for (let j = 0; j < finalvalue[i]?.artists?.length; j++) {
          for (let k in finalvalue[i].artists[j]) {
            if (finalvalue[i].artists[j][k] !== "") {
              formDatavalue.append(
                `tracks[${i}][artists][${j}][${k}]`,
                finalvalue[i].artists[j][k]
              );
            }
          }
        }
      }
      formDatavalue.append("copyright[name]", formData?.copyrightyear);
      formDatavalue.append("productionRight[name]", formData?.productionright);

      await axios
        .put(
          `${process.env.REACT_APP_DMT_API_PATH}/orders/${firstslidedata._id}`,
          formDatavalue,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error !== true) {
            dispatch(userSlice.actions.saveUser(res.data.data));

            axios
              .post(
                `${process.env.REACT_APP_DMT_API_PATH}/orders/${firstslidedata._id}/payment`,
                formDatavalue,
                {
                  headers: {
                    "content-type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                if (res.data.error !== true) {
                  setPaymentidget(res.data);
                  setPaymentget(res.data);
                  setIsLoading(false);
                  setSongValue(undefined);
                  localStorage.setItem("order", res?.data?.data?._id);
                  setPage((currPage) => currPage + 1);
                  window.scrollTo(0, 0);
                }
              })
              .catch((err) => {
                toast.error(err.message);
                setIsLoading(false);
              });
          } else {
            toast.error("Something Went Wrong");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          toast.error(err.message);
          setIsLoading(false);
        });
    }
  };

  const checkoutHandler = async () => {
    if (totalamount !== 0 && walletamount <= 0) {
      const payload = {
        amount: totalamount,
        distribution_type: billshow,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_DMT_API_PATH}/orders/${firstslidedata._id}/payment/${paymentidget?.data?.order_id}`,
        payload,
        config
      );

      const orderData = res.data.data;
      const options = {
        key: "rzp_live_UOZD55VDM3YeQI",
        amount: totalamount,
        currency: "INR",
        name: "DELIVER MY TUNE",
        description: "RazorPay",
        order_id: orderData.order_id,
        handler: async function (response) {
          const data = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
            donation_amount: 0,
          };
          const result = await axios.post(
            `${process.env.REACT_APP_DMT_API_PATH}/public/payments/verify`,
            data
          );
          if (result.data.error !== true) {
            window.scrollTo(0, 0);
            dispatch(userSlice.actions.saveUser(""));
            navigate("/paymentsuccess", {
              state: {
                id: firstslidedata,
              },
            });
          }
        },

        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#121212",
        },
      };
      const razor = new window.Razorpay(options);
      razor.open();
    } else if (walletamount > 0) {
      if (totalamount <= 0) {
        const payload2 = {
          additional_amount: totalamount,
          amount: donate ? walletamount - donate : walletamount,
          distribution_type: billshow,
          donation_amount: donate,
          wallet_amount: walletamount,
          order_id: paymentidget?.data?.identity?.id,
          payment_id: paymentidget?.data?._id,
          user_id: loginToken?.user._id,
        };

        await axios
          .post(
            `${process.env.REACT_APP_DMT_API_PATH}/wallet/pay`,
            payload2,
            config
          )
          .then((res) => {
            if (res.data.error !== true) {
              navigate("/paymentsuccess", {
                state: {
                  id: firstslidedata,
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (totalamount > 0) {
        const payload = {
          amount: totalamount,
          distribution_type: billshow,
        };
        const res = await axios.post(
          `${process.env.REACT_APP_DMT_API_PATH}/orders/${firstslidedata._id}/payment/${paymentidget?.data?.order_id}`,
          payload,
          config
        );
        const orderData = res.data.data;
        const options = {
          key: "rzp_live_UOZD55VDM3YeQI",
          amount: totalamount,
          currency: "INR",
          name: "DELIVER MY TUNE",
          description: "RazorPay",
          order_id: orderData.order_id,
          handler: async function (response) {
            const data = {
              //  orderCreationId: datavalue,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              donation_amount: 0,
            };
            const result = await axios.post(
              `${process.env.REACT_APP_DMT_API_PATH}/public/payments/verify`,
              data
            );
            if (result.data.error !== true) {
              window.scrollTo(0, 0);
              setSongValue(undefined);
              dispatch(userSlice.actions.saveUser(""));
              navigate("/paymentsuccess", {
                state: {
                  id: firstslidedata,
                },
              });
              const payload2 = {
                additional_amount: 0,
                amount: donate ? walletamount - donate : walletamount,
                distribution_type: billshow,
                donation_amount: donate,
                wallet_amount: walletamount,
                order_id: paymentidget?.data?.identity?.id,
                payment_id: paymentidget?.data?._id,
                user_id: loginToken?.user._id,
                totalamount:totalamount
              };
              await axios
                .post(
                  `${process.env.REACT_APP_DMT_API_PATH}/wallet/pay`,
                  payload2,
                  config
                )
                .then((res) => {
                  if (res.data.error !== true) {
                    dispatch(userSlice.actions.saveUser(""));
                    setSongValue(undefined);
                    navigate("/paymentsuccess", {
                      state: {
                        id: firstslidedata,
                      },
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#121212",
          },
        };
        const razor = new window.Razorpay(options);
        razor.open();
      }
    } else {
      const payload2 = {
        additional_amount: totalamount,
        amount: totalamount,
        distribution_type: "Free",
        donation_amount: 0,
        order_id: paymentidget?.data?.identity?.id,
        payment_id: paymentidget?.data?._id,
        user_id: loginToken?.user._id,
      };
      await axios
        .post(
          `${process.env.REACT_APP_DMT_API_PATH}/wallet/pay`,
          payload2,
          config
        )
        .then((res) => {
          if (res.data.error !== true) {
            window.scrollTo(0, 0);
            dispatch(userSlice.actions.saveUser(""));
            navigate("/paymentsuccess", {
              state: {
                id: firstslidedata,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const PageDisplay = () => {
    if (page === 0) {
      return isLoading ? (
        <ScaleLoader
          size={350}
          textAlign="center"
          className="music-loader"
          color="#FBCE2E"
        />
      ) : (
        <BasicDetailsdata
          formData={formData}
          setFormData={setFormData}
          setFiledata={setFiledata}
          filedata={filedata}
          value={value}
          setSongLyrics={setSongLyrics}
          songLyrics={songLyrics}
          setValue={setValue}
          setMoviesong={setMoviesong}
          career={career}
          error={error}
          firstslidedata={firstslidedata}
          albumkey={albumkey}
          saveImage={saveImage}
          setSaveimage={setSaveimage}
          isUnsplash={isUnsplash}
          setisUnsplash={setisUnsplash}
        />
      );
    } else if (page === 1) {
      return isLoading ? (
        <ScaleLoader
          size={350}
          className="music-loader"
          textAlign="center"
          color="#FBCE2E"
        />
      ) : (
        <AdvancedDetails
          finalvalue={finalvalue}
          setFinalvalue={setFinalvalue}
          ringtone={ringtone}
          formData={formData}
          setFormData={setFormData}
          data={data}
          setData={setData}
          setRingtone={setRingtone}
          setTracktomaster={setTracktomaster}
          tracktomaster={tracktomaster}
          setSongValue={setSongValue}
          setIsInstagramprofile={setIsInstagramprofile}
          isInstagramprofile={isInstagramprofile}
          songValue={songValue}
          career={career}
          seconderror={seconderror}
          firstslidedata={firstslidedata}
          listData={listData}
          setListData={setListData}
          multiformadv={multiformadv}
          setmultiformadv={setmultiformadv}
          setSecondError={setSecondError}
          songalbum={songalbum}
          coverSongFile={coverSongFile}
          setCoverfile={setCoverfile}
          setArtistdetail={setArtistdetail}
          artistdetail={artistdetail}
          setSongprocessing={setSongprocessing}
          songprocessing={songprocessing}
        />
      );
    } else if (page === 2) {
      return isLoading ? (
        <ScaleLoader
          size={350}
          className="music-loader"
          textAlign="center"
          color="#FBCE2E"
        />
      ) : (
        <Declaration
          checkeddeclaration={checkeddeclaration}
          setCheckeddeclaration={setCheckeddeclaration}
          handledeclarationSubmit={handledeclarationSubmit}
          career={career}
          firstslidedata={firstslidedata}
          setBackflow={setBackflow}
          backflow={backflow}
        />
      );
    } else if (page === 3) {
      return isLoading ? (
        <ScaleLoader
          size={350}
          className="music-loader"
          textAlign="center"
          color="#FBCE2E"
        />
      ) : (
        <PublishDetails
          formData={formData}
          setFormData={setFormData}
          setPlatform={setPlatform}
          setBackflow={setBackflow}
          backflow={backflow}
          platform={platform}
          thirdError={thirdError}
          career={career}
          firstslidedata={firstslidedata}
        />
      );
    } else {
      return (
        <Billing
        setWalletamount={setWalletamount}
          walletamount={walletamount}
          billshow={billshow}
          setBillshow={setBillshow}
          formData={formData}
          setFormData={setFormData}
          setPaymentDetail={setPaymentDetail}
          paymentdetail={paymentdetail}
          setAmount={setAmount}
          amount={amount}
          paymentget={paymentget}
          setTotalamount={setTotalamount}
          totalamount={totalamount}
          setShowuserdata={setShowuserdata}
          donate={donate}
          setDonateValue={setDonateValue}
        />
      );
    }
  };

  const FormTitles = [
    "Basic Details",
    "Advance Details",
    "Publising Details",
    "Summary",
  ];
  return (
    <div className="container">
      <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
      <div className="main-container">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="pages">
          <Box className="custom-dmt-container">
            <Stepper page={page} />

            <div className="body">{PageDisplay()}</div>
            <div
              style={{
                width: page === 0 ? "33.3%" : page == 1 ? "66.6%" : "100%",
              }}
            ></div>
            {isLoading === true ? (
              ""
            ) : (
              <div className="stepperBtn">
                {page == 0 ? (
                  ""
                ) : (
                  <Button
                    disabled={page == 0}
                    onClick={() => {
                      setPage((currPage) => currPage - 1);
                      setIsLoading(false);
                      if (page === 4) {
                        setBillshow("");
                        setBackflow(true);
                      }else  if (page === 3) {
                        setBillshow("");
                        setBackflow(true);
                      }
                    }}
                    sx={{
                      color: "#f9a40d !important",
                      background: "black !important",
                      width: "112px !important",
                      margin: "0px !important",
                      textTransform: "capitalize  !important",
                    }}
                    className="backbtn"
                  >
                    <ArrowBackIcon sx={{ paddingRight: "3px" }} /> Back
                  </Button>
                )}

                <Button
                  className="nextbtn"
                  disabled={songprocessing == true}
                  id={
                    page === FormTitles.length - 1
                      ? "conversion-dmt-distribution"
                      : ""
                  }
                  sx={{ textTransform: "capitalize  !important" }}
                  onClick={(event) => {
                    if (page === 0) {
                      handleChange();
                    } else if (page === 1) {
                      handleSecondSubmit();
                    } else if (page === 2) {
                      handledeclarationSubmit();
                    } else if (page === 3) {
                      handleThirdSubmit();
                    } else if (page === 4) {
                      if (billshow) {
                        checkoutHandler();
                        handleAddtag();
                      } else {
                        toast.error("Please Select Plan");
                      }
                    }
                  }}
                >
                  {page === FormTitles.length - 1
                    ? "Proceed To Payment"
                    : `Save & Next`}{" "}
                  &nbsp; <IoIosArrowForward fontSize="16px" />
                </Button>
              </div>
            )}
          </Box>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DistributeSingleTrack;

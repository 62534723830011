import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
 
const Partner = () => {

  
  return (
    <Container >
      <Typography
        sx={{
          fontSize: {md:"3rem",xs:"2rem"},
          maxWidth: "80%",
          lineHeight: "1.3",
          fontFamily: "DM Sans",
          fontWeight: "700",
          color: "#343a40!important",
          margin:"auto",
          padding:{md:"10px",sm:"20px"},
          textAlign:{xs:'center',md:"center"}
          
        }}
      >
        Your go-to music distribution partner
      </Typography>
      <Box
        sx={{
          display: {md:"flex",xs:"block",sm:"flex"},
          justifyContent:{md:'center',xs:"left",sm:"center"},
          backgroundColor: "#fb8e0b",
          borderRadius: "20.5px",
          maxWidth: "780px",
          padding:{md:"5px",xs:"20px",sm:"2px"},
          margin:"auto"
          
        }}
      >
        <Box
          display="flex"
          sx={{
            position: "relative",
            width: "100%",
            paddingRight: "15px",
            paddingLeft: "15px",
            marginTop: "0.5rem",
            marginBottom: " 0.5rem",
            color:"white !important"
            
          }}
        >
          <CheckIcon /> <Typography>  Release on 60+ platforms</Typography>
        </Box>
        <Box
          display="flex"
          sx={{
            position: "relative",
            width: "100%",
            paddingRight: "15px",
            paddingLeft: "15px",
            marginTop: "0.5rem",
            marginBottom: " 0.5rem",
            color:"white !important"
          }}
        >
          <CheckIcon /> <Typography> Detailed Royalty Report</Typography>
        </Box>
        <Box
          display="flex"
          sx={{
            position: "relative",
            width: "100%",
            paddingRight: "15px",
            paddingLeft: "15px",
            marginTop: "0.5rem",
            marginBottom: " 0.5rem",
            color:"white !important"
          }}
        >
          <CheckIcon /> <Typography> Keep 100% Copyrights</Typography>
        </Box>
      </Box>
      
    </Container>
  );
};

export default Partner;

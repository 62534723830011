import { Box } from "@mui/system";
import React from "react";
import BundlePricing from "../../components/pricinglist/bundlePricing";
import Navbar from "../../components/common/navbar/index";
import Footer from "../../components/common/footer/index";
import Partner from "../../components/pricinglist/partner";
import Toggle from "../../components/pricinglist/toggle";
import Singlealbum from "../../components/pricinglist/singlealbum";
import Multiplealbum from "../../components/pricinglist/multiplealbum";
import { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ArtistNumber from "../../components/artistLabel/index";
import UspProducts from '../../components/uspProduct/index';
import HowtoSell from '../../components/sellMusic/index';
import CommonQuestion from '../../components/commonQuestions/CommomQuestion'
import ArtistCommunity from '../../components/ourArtistCommunty/index'

const themeLight = createTheme({
  palette: {
    mode: "light",
  },
});

const Pricing = () => {
  const [first, setFirst] = useState(true);
  const [checked, setChecked] = useState(false);
  const handleShow = () => {
    if (first == true) {
      setFirst(false);
      setChecked(true);
    } else {
      setFirst(true);
      setChecked(false);
    }
  };

  return (
    <Box>
      <ThemeProvider theme={themeLight}>
        <CssBaseline />
        <Navbar />

        <Box sx={{ mt: "75px" }}></Box>
        <Partner />
        <Toggle first={first} handleShow={handleShow} checked={checked} />

        {first ? <Singlealbum /> : <Multiplealbum />}
        <BundlePricing />
        <ArtistNumber />
        <UspProducts/>
        <HowtoSell/>
        <ArtistCommunity/>
        <CommonQuestion/>
        <Footer />
      </ThemeProvider>
    </Box>
  );
};

export default Pricing;

import React, { Component, useEffect } from "react";
import Slider from "react-slick";
import { useRef } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Container, Card, Box, Typography, Grid, Button } from "@mui/material";
import ReactPlayer from "react-player";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CustomButton from "../../../components/common/customButton";
import MultiSlider, { Progress, Dot } from "react-multi-bar-slider";
import Massa from "../../../assets/img/massa.svg";
import Magna from "../../../assets/img/magna.svg";
import CustomButtonedit from "../editButton";
import AddIcon from "@mui/icons-material/Add";
import styled from "@emotion/styled";
import moment from "moment/moment";
import "./style.css";
import { useState } from "react";
import { current } from "@reduxjs/toolkit";
import Eventform from "./eventadd";
import ClearIcon from "@mui/icons-material/Clear";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";

function PaperComponent(props) {
  return <Paper {...props} />;
}
function convertTimeFormat(time) {
  var hours = parseInt(time.substr(0, 2));
  var minutes = parseInt(time.substr(3, 2));
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}
const Student_Data = [
  {
    id: 1,
    image: Massa,
    title: "Rockparty with DJ",
    description:
      "Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Cras ultricies ligula sed magna dictum porta",
    address: "AKM Auditorium, Korba, Chhattisgarh",
  },

  {
    id: 2,
    image: Magna,
    title: "Rockparty with DJ",
    description:
      "Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Cras ultricies ligula sed magna dictum porta",
    address: "AKM Auditorium, Korba, Chhattisgarh",
  },

  {
    id: 3,
    image: Massa,
    title: "Rockparty with DJ",
    description:
      "Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Cras ultricies ligula sed magna dictum porta",
    address: "AKM Auditorium, Korba, Chhattisgarh",
  },
  {
    id: 4,
    image: Magna,
    title: "Rockparty with DJ",
    description:
      "Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Cras ultricies ligula sed magna dictum porta",
    address: "AKM Auditorium, Korba, Chhattisgarh",
  },
];

const Index = ({ value, data, getOrder, tokenedit }) => {
  const [progress, setProgress] = useState(0);
  const [slideToShow, setSlideToShow] = useState(1);
  const [viewmodal, setViewmodal] = useState([]);
  const [number, setNumber] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [pics, setPics] = useState([]);
  const removeImage = (id) => {
    setPics((oldState) => oldState.filter((item) => item.id !== id));
  };

  useEffect(() => {
    setPics(Student_Data);
  }, []);
  const handleOpen = (value) => {
    setOpen(true);
    setViewmodal(value);
  };
  const handleClose = () => setOpen(false);
  const setSlides = () => {
    if (window.innerWidth <= 1200 && window.innerWidth > 1000) {
      setSlideToShow(1);
    }
  };
  useEffect(() => {
    setSlides();
    setProgress(100 / (data?.events?.length - slideToShow));
    window.addEventListener("resize", () => {
      setSlides();
    });
  }, []);
  const sliderRef = useRef(null);

  const settings = {
    fade: false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    infinite: true,
    arrows: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 2040,
        settings: {
          slidesToShow: data?.events?.length > 1 ? 2 : 1,
          slidesToScroll: 1,
          centerMode: false,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: data?.events?.length > 1 ? 1.13 : 1,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          initialSlide: 1,
        },
      },
    ],
    afterChange: (current) => {
      setProgress(
        (100 / (data?.events?.length - slideToShow + 1)) * (current + 1)
      );
    },
  };

  return (
    <>
      <Container
        sx={{
          maxWidth: "90% !important",
          marginBottom: { md: "30px", xs: "8px" },
          marginTop: { md: "60px", xs: "0px" },
        }}
      >
        <Box
          id="Event"
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: { xs: "0px !important", md: "12px !important" },
          }}
        >
          <Typography
            variant="h3"
            className="contact-data"
            sx={{
              fontSize: { md: "48px", xs: "32px" },
              textAlign: { xs: "center" },
            }}
          >
            Upcoming Events
          </Typography>
          <Box
            sx={{
              marginLeft: { md: "20px", xs: "0px" },
              marginTop: { lg: "15px", md: "15px", xs: "0px" },
            }}
          >
            {tokenedit ? (
              <CustomButtonedit
                btnText="Add"
                handleClick={() => {
                  handleOpen();
                  setNumber(0);
                }}
                startIcon={<AddIcon sx={{ marginRight: "5px" }} />}
                width="75px"
                height="30px"
                border="1px solid #ffffff !important"
                padding="15px"
              />
            ) : (
              ""
            )}
          </Box>
        </Box>
        <Box sx={{ position: "relative" }}>
          {data?.events?.length === 0 ? (
            <>
              <Box
                sx={{
                  margin: "30px 0px",
                  padding: "0px !important",
                }}
              >
                {tokenedit ? (
                  <Box
                    sx={{
                      border: "2px dashed white",
                      width: { md: "760px", xs: "280px" },
                      height: "210px !important",
                      textAlign: "center",
                      margin: "auto",
                    }}
                    onClick={() => {
                      handleOpen();
                      setNumber(0);
                    }}
                  >
                    <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                      <Typography>+</Typography>
                      <Typography>Add Your First Events Here</Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      border: "2px dashed white",
                      width: { md: "760px", xs: "280px" },
                      height: "210px !important",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  >
                    <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                      {/* <Typography></Typography> */}
                      <Typography>Events is Empty</Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <Slider ref={sliderRef} {...settings}>
              {data?.events?.map((value, index) => (
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                    margin: "20px 0px",
                    pr: 4,
                  }}
                >
                  <Card
                    id="cardevent"
                    sx={{
                      maxWidth: {
                        xs: "933px",
                      },
                      height: { lg: "250px !important" },
                      margin: "auto !important",

                      borderRadius: "15px",
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item lg={3} md={5} sm={12} xs={12}>
                        <img
                          src={value?.eventimage}
                          width="100%"
                          style={{
                            width: {
                              lg: "180px !important",
                              sm: "150px !important",
                              xs: "100% !important",
                            },
                          }}
                          height="100%"
                          className="imagesongvalue"
                          alt=""
                        />
                      </Grid>
                      <Grid item lg={7} md={5} sm={12} xs={12}>
                        <Typography
                          component="div"
                          sx={{
                            fontSize: {
                              xs: "18px",
                              sm: "24px",
                            },
                            fontWeight: 700,
                            fontFamily: "DM Sans",
                            color: "#f1f1f1",
                            marginTop: { md: "30px", xs: "-10px" },
                            marginLeft: { xs: "10px", md: "10px" },
                          }}
                        >
                          {value?.eventtitle}
                        </Typography>
                        <Typography
                          sx={{
                            mt: { md: "10px", xs: "0px" },
                            fontSize: "12px",
                            fontWeight: "400",
                            fontFamily: "DM Sans",
                            maxWidth: { md: "75%", xs: "90%" },
                            marginLeft: { xs: "10px", md: "10px" },
                          }}
                        >
                          {value?.eventdesc}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            marginLeft: { xs: "10px", md: "10px" },
                          }}
                        >
                          <LocationOnIcon sx={{ mt: 1, color: "#FB900B" }} />
                          <Typography
                            sx={{
                              mt: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "DM Sans",
                              maxWidth: { md: "72%", xs: "90%" },
                            }}
                          >
                            {value?.eventlocation}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            marginLeft: { xs: "10px", md: "10px" },
                          }}
                        >
                          <QueryBuilderIcon sx={{ mt: 1, color: "#FB900B" }} />
                          <Typography
                            sx={{
                              mt: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              fontFamily: "DM Sans",
                              maxWidth: "72%",
                              marginLeft: "5px",
                            }}
                          >
                            {convertTimeFormat(value?.eventtime)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={2} md={2} xs={12}>
                        <Box
                          sx={{
                            marginLeft: { lg: "70px", xs: "10px !important" },
                            marginTop: { lg: "8px", md: "15px", xs: "0px" },
                            display: { lg: "block", xs: "flex" },
                          }}
                        >
                          {tokenedit ? (
                            <CustomButtonedit
                              handleClick={() => {
                                handleOpen(value);
                                setNumber(1);
                              }}
                              startIcon={
                                <ModeEditOutlinedIcon
                                  sx={{ marginRight: "5px" }}
                                />
                              }
                              width="20px !important"
                              height="20px !important"
                              border="1px solid #ffffff !important"
                              padding="15px"
                            />
                          ) : (
                            ""
                          )}
                        </Box>
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "24px",
                              sm: "28px",
                            },
                            fontWeight: 700,
                            fontFamily: "DM Sans",
                            color: "#f1f1f1",
                            marginLeft: { xs: "10px", md: "0px" },
                          }}
                        >
                          {moment(value?.eventDate).format("ll")}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { md: "18px  !important" },
                            fontWeight: "500  !important",
                            fontFamily: "DM Sans",
                            color: "#E1E1E1 !important",
                            textAlign: "center",
                            marginRight: { md: "93px", xs: "-150px" },
                            marginTop: { xs: "-30px", md: "0px" },
                          }}
                        >
                          {moment(value?.eventDate).format("dddd")}
                        </Typography>
                        <a href={value?.eventlink} target="_blank" >
                          <CustomButton
                            className="submitprofilebtn"
                            btnText={"Learn More"}
                            buttonStyle="submitprofilebtndree"
                          />
                        </a>
                      </Grid>
                    </Grid>
                  </Card>
                </Box>
              ))}
            </Slider>
          )}

          <Box
            className="arrow-drive"
            style={{
              height: "3px",
              backgroundColor: "white",
              width: "250px",
              position: "absolute",
              justifyContent: "center",
              textAlign: "center",
              left: "40%",
              right: "50%",
            }}
            onClick={() => sliderRef.current.slickNext()}
          >
            <div
              style={{
                backgroundColor: "#f9a40d",
                position: "absolute",
                height: "100%",
                transition: "all",
                width: `${progress}%`,
                minWidth: "20px !important",
                borderRadius: "50% !important",
              }}
            ></div>
          </Box>
        </Box>
        <div>
          <Dialog
            height="auto"
            style={{ zIndex: "9999922" }}
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
          >
            <Box textAlign="end">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <DialogTitle
                style={{ cursor: "move" }}
                align="center"
                mt="-50px"
                id="draggable-dialog-title"
                fontFamily="DM Sans"
              >
                 Events
              </DialogTitle>
            </Box>

            <Eventform
              view={viewmodal}
              handleClose={handleClose}
              getOrder={getOrder}
              number={number}
              tokenedit={tokenedit}
            />
          </Dialog>
        </div>
      </Container>
    </>
  );
};

export default Index;

import React, { useState } from "react";
import { Box, Container, Dialog, DialogTitle, Grid, IconButton, Paper, Typography } from "@mui/material";
import lipTwo from "../../../assets/img/lipTwo.png";
import square from "../../../assets/img/square.png";
import halfTriangle from "../../../assets/img/halfTriangle.png";
import zigzag from "../../../assets/img/zigzag.png";
import bottle from "../../../assets/img/bottle.png";
import star from "../../../assets/img/starfour.png";
import CloseIcon from "@mui/icons-material/Close";
import VideoForm from './galleryform'
import CustomButton from "../editportfoliosecondbtn";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import './gallery.css'

function PaperComponent(props) {
  return <Paper  sx={{background:'#FF599D'}} {...props} />;
}

const Index = ({ data, tokenedit, getToken, getOrder }) => {

  const [viewmodal, setViewmodal] = useState([]);
  const [number, setNumber] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (value) => {
    setOpen(true);
    setViewmodal(value);
  };
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ background: "#FCE23B", position: "relative", pb: 15 }}>
      <Box
        sx={{
          position: "absolute",
          left: "40%",
          top: "-70px",
          display: { xs: "none", md: "block" },
        }}
      >
        <img src={bottle} alt="" width="100%" />
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: "100px",
          top: "-100px",
          display: { xs: "block", zIndex: 100 },
        }}
      >
        <img src={star} alt="" width="100%" />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "20px",
          right: "20px",
          display: { xs: "none", sm: "block" },
        }}
      >
        <img src={lipTwo} alt="" />
      </Box>
      <Box sx={{ position: "absolute", top: "0px", left: "0px" }}>
        <img src={halfTriangle} alt="" width={"100%"} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "3px",
          left: "57%",
          transform: "rotate(-3deg)",
          display: { xs: "none", lg: "block" },
        }}
      >
        <img src={zigzag} alt="" />
      </Box>
      <Container>
        <Box sx={{ display: "flex", justifyContent: "center", pt: 8 }}>
          <Typography
            className="textStroke"
            sx={{
              color: "#00D8C0",
              fontSize: { xs: "40px", md: "64px" },
              zIndex: "5",
              fontFamily: "Anton",
              transform: "matrix(1, 0.01, -0.29, 0.96, 0, 0) ",
            }}
          >
            Gallery
          </Typography>
        </Box>
        {data?.gallary?.length < 6 ? (
          <Box
            sx={{
              marginLeft: "20px",textAlign:"center",
              marginTop: { lg: "15px", md: "15px", xs: "0px" },mb:3
            }}
          >
            {tokenedit ? (
              <CustomButton
                btnText="Add"
                handleClick={() => {
                  handleOpen();
                  setNumber(0);
                }}
                startIcon={<AddIcon sx={{ marginRight: "5px" }} />}
                width="75px"
                height="30px"
                border="1px solid #ffffff !important"
                padding="15px"
              />
            ) : (
              ""
            )}
          </Box>
        ) : (
          ""
        )}

        <Box textAlign="-webkit-center">
          {data?.gallary.length === 0 ? (
            <>
              <Box
                sx={{
                  margin: "30px 0px",
                  padding: "0px !important",
                }}
              >
                {tokenedit ? (
                  <Box
                    sx={{
                      border: "2px dashed #00D8C0 ",
                      width: { md: "760px", xs: "280px" },
                      height: "210px !important",
                      textAlign: "center",
                      margin: "auto",
                    }}
                    onClick={() => {
                      // handleOpen();
                      // setNumber(0);
                    }}
                  >
                    <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                      <Typography sx={{ color: "#00D8C0", fontSize: "30px", lineHeight: '20px' }}>+</Typography>
                      <Typography sx={{ color: "#00D8C0", fontSize: "20px", fontWeight: '700' }}>Add Your First Image Here</Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      border: "2px dashed #00D8C0",
                      width: { md: "760px", xs: "280px" },
                      height: "210px !important",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  >
                    <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                      <Typography sx={{
                            color: "#00D8C0",
                            fontSize: "20px",
                            fontWeight: "700",
                          }}>Gallary is Empty</Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <Grid
              container
              maxWidth={{ md: "1250px", xs: "600px" }}
              spacing={2}
            >
              {data?.gallary?.slice(0,6 ).map((curElm, index) => (
                <Grid
                  key={index}
                  item
                  md={4}
                  xs={6}
                  sx={{ transform: "rotate(3deg)", zIndex: 5 }}
                >
                  <Box
                    sx={{
                      background: "#FF599D",
                      p: "4px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center", position: "relative"
                    }}
                  >
                    <img
                      src={curElm.image}
                      width="100%"
                      className="galleryImage"
                      style={{ maxWidth: "100%", maxHeight: "305px",}}
                      alt=""
                    />
                    {tokenedit ? (
                      <Box
                        sx={{
                          marginLeft: {
                            // lg: "-70px !important",
                            // xs: "-70px !important",

                          },
                          position: "absolute", top: "10px", right: '10px',
                          // marginTop: { lg: "8px", md: "15px", xs: "15px" },
                          display: { lg: "block", xs: "flex" },
                        }}
                          onClick={() => {
                            handleOpen(curElm);
                            setNumber(1);
                          }}
                      >
             {/*           <CustomButton

                          handleClick={() => {
                            handleOpen(curElm);
                            setNumber(1);
                          }}
                          startIcon={
                            <ModeEditOutlinedIcon  sx={{ marginRight: "5px" }} />
                          }
                          width="10px !important"
                          height="10px !important"
                          border="1px solid #ffffff !important"
                          padding="15px"
                        />*/}
                        <ModeEditOutlinedIcon  sx={{ marginRight: "5px" }} />
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: { md: "-6px", xs: "-12px" },
            right: "0px",
          }}
        >
          <img src={square} alt="" />
        </Box>
      </Container>

      <div>
        <Dialog
          height="auto"
          style={{ zIndex: "9999922" }}
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <Box textAlign="end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <DialogTitle
              style={{ cursor: "move" }}
              align="center"
              mt="-50px"
              id="draggable-dialog-title"
              fontFamily="DM Sans"
            >
              {viewmodal ? "Update Gallary" : " Upload Gallary"}
            </DialogTitle>
          </Box>

          <VideoForm
            view={viewmodal}
            handleClose={handleClose}
            getOrder={getOrder}
            number={number}
            tokenedit={tokenedit}
          />
        </Dialog>
      </div>

    </Box>
  );
};

export default Index;

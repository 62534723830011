import React, { useState } from "react";
import { Typography, Card, Box, Popover} from "@mui/material";
import { MdContentCopy } from "react-icons/md";

const ReferralCode = ({ code }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        setAnchorEl(document.activeElement);
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover-msg" : undefined;

  return (
    <Box>
      <Card
        sx={{
          background: "rgba(255, 255, 255, 0.05)",
          padding: "20px 30px",
        }}
      >
        <Typography fontWeight="600" fontSize="1.1rem">
          Referral Code: {code}
          <MdContentCopy
            className="copyIcon"
            onClick={copyToClipboard}
          />
        </Typography>
      </Card>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography>Referral code copied to clipboard!</Typography>
        </Box>
      </Popover>
    </Box>
  );
};

export default ReferralCode;

import Spotify from "../../assets/img/spotify.svg";
import Gaana from "../../assets/img/gaana.svg";
import YoutubeMusic from "../../assets/img/YoutubeMusic.svg";
import Hungama from "../../assets/img/hungama.png";
import JioSavan from "../../assets/img/JioSavan.svg";
import amazonMusic from "../../assets/img/amazonMusic.png";
import GooglePlay from "../../assets/img/GooglePlay.svg";
import Heart from "../../assets/img/Heart.svg";
import Apple from "../../assets/img/Apple.svg";
import Youtube from "../../assets/img/Youtube.svg";
import NewYoutubeMusic from "../../assets/img/NewYoutubeMusic.svg";
import WynkMusic from "../../assets/img/WynkMusic.svg";
import Napster from "../../assets/img/Napster.svg";
import Shazam from "../../assets/img/Shazam.svg";
import Pandora from "../../assets/img/Pandora.svg";
import Deezer from "../../assets/img/Deezer.svg";
import SimfyAfrica from "../../assets/img/SimfyAfrica.svg";
import Spinlet from "../../assets/img/Spinlet.svg";
import PrimePhonic from "../../assets/img/Prime.svg";
import Kuack from "../../assets/img/Kuacks.svg";
import Kkbox from "../../assets/img/KkBox.svg";
import GraceNote from "../../assets/img/GraceNote.svg";
import AudibleMagic from "../../assets/img/AudibleMagic.svg";
import Anghami from "../../assets/img/Anghami.svg";
import SiriusXm from "../../assets/img/SiriusXm.svg";
import Melon from "../../assets/img/Melon.svg";
import SevenDigital from "../../assets/img/SevenDigital.svg";
import BoomPlay from "../../assets/img/BoomPlay.svg";
import JooxMusic from "../../assets/img/JooxMusic.svg";
import MixCloud from "../../assets/img/MixCloud.svg";
import Slacker from "../../assets/img/Slacker.svg";
import TouchTunes from "../../assets/img/TouchTunes.svg";
import TikTok from "../../assets/img/TikTok.svg";
import Resso from "../../assets/img/Resso.svg";
import Nuuday from "../../assets/img/Nuuday.svg";
import Jaxsta from "../../assets/img/Jaxsta.svg";
import TencentMusic from "../../assets/img/TencentMusic.svg";
import Qobuz from "../../assets/img/Qobuz.svg";
import FacebookAudioLibrary from "../../assets/img/FacebookAudioLibrary.svg";
import FacebookFingerPrinting from "../../assets/img/FacebookFingerPrinting.svg";
import NetEaseCloudMusic from "../../assets/img/NetEaseCloudMusic.svg";
import HitsNl from "../../assets/img/HitsNl.svg";
import NaverMusic from "../../assets/img/NaverMusic.svg";
import YandexMusic from "../../assets/img/YandexMusic.svg";
import AlibabaXiami from "../../assets/img/AlibabaXiami.svg";
import Soundcloud from "../../assets/img/Soundcloud.png";
import UMA from "../../assets/img/UMA.svg";
import iam from "../../assets/img/iam.svg";
import Fizy from "../../assets/img/Fizy.svg";
import imusica from "../../assets/img/imusica.png";
import awa from "../../assets/img/awa.png";
import tidal from "../../assets/img/tidal.png";
import lineMusic from "../../assets/img/lineMusic.png";
import target from "../../assets/img/target.png";
import touchtunes from "../../assets/img/touchtunes.png";
import Ultimatemusic from "../../assets/img/Ultimatemusic.png";
import Timmusic from "../../assets/img/Timmusic.png";
import Neurotic from "../../assets/img/Neurotic.png";

export const choosePlatfrom = [
    {
      id: 1,
      img: Spotify,
      name: "Spotify",
      isChecked:true
    },
    {
      id: 2,
      img: YoutubeMusic,
      name: "YoutubeMusic",
      isChecked:true
    },
    {
      id: 3,
      img: Hungama,
      name: "Hungama",
      isChecked:true
    },
    {
      id: 4,
      img: JioSavan,
      name: "JioSavan",
      isChecked:true
    },
    {
      id: 5,
      img: amazonMusic,
      name: "amazonMusic",
      isChecked:true
    },
    {
      id: 6,
      img: GooglePlay,
      name: "GooglePlay",
      isChecked:true
    },
    {
      id: 7,
      img: Heart,
      name: "Heart",
      isChecked:true
    },
    {
      id: 8,
      img: Apple,
      name: "Apple",
      isChecked:true
    },
    {
      id: 9,
      img: Youtube,
      name: "Youtube",
      isChecked:true
    },
    {
      id: 10,
      img: Soundcloud,
      name: "Soundcloud",
      isChecked:true
    },
    {
      id: 11,
      img: WynkMusic,
      name: "WynkMusic",
      isChecked:true
    },
    {
      id: 12,
      img: Napster,
      name: "Napster",
      isChecked:true
    },
    {
      id: 13,
      img: Shazam,
      name: "Shazam",
      isChecked:true
    },
    {
      id: 14,
      img: Pandora,
      name: "Pandora",
      isChecked:true
    },
    {
      id: 15,
      img: Deezer,
      name: "Deezer",
      isChecked:true
    },
    {
      id: 16,
      img: SimfyAfrica,
      name: "SimfyAfrica",
      isChecked:true
    },
    {
      id: 17,
      img: Spinlet,
      name: "Spinlet",
      isChecked:true
    },
    {
      id: 18,
      img: PrimePhonic,
      name: "PrimePhonic",
      isChecked:true
    },
    {
      id: 19,
      img: Kuack,
      name: "Kuack",
      isChecked:true
    },
    {
      id: 20,
      img: Kkbox,
      name: "Kkbox",
      isChecked:true
    },
    {
      id: 21,
      img: GraceNote,
      name: "GraceNote",
      isChecked:true
    },
    {
      id: 22,
      img: AudibleMagic,
      name: "AudibleMagic",
      isChecked:true
    },
    {
      id: 23,
      img: Anghami,
      name: "Anghami",
      isChecked:true
    },
    {
      id: 24,
      img: SiriusXm,
      name: "SiriusXm",
      isChecked:true
    },
    {
      id: 25,
      img: Melon,
      name: "Melon",
      isChecked:true
    },
    {
      id: 26,
      img: SevenDigital,
      name: "SevenDigital",
      isChecked:true
    },
    {
      id: 27,
      img: BoomPlay,
      name: "BoomPlay",
      isChecked:true
    },
    {
      id: 28,
      img: JooxMusic,
      name: "JooxMusic",
      isChecked:true
    },
    {
      id: 29,
      img: MixCloud,
      name: "MixCloud",
      isChecked:true
    },
    {
      id: 30,
      img: Slacker,
      name: "Slacker",
      isChecked:true
    },
    {
      id: 31,
      img: TouchTunes,
      name: "TouchTunes",
      isChecked:true
    },
    {
      id: 32,
      img: TikTok,
      name: "TikTok",
      isChecked:true
    },
    {
      id: 33,
      img: Resso,
      name: "Resso",
       isChecked:true
    },
    {
      id: 34,
      img: Nuuday,
      name: "Nuuday",
      isChecked:true
    },
    {
      id: 35,
      img: Jaxsta,
      name: "Jaxsta",
      isChecked:true
    },
    {
      id: 36,
      img: TencentMusic,
      name: "TencentMusic",
      isChecked:true
    },
    {
      id: 37,
      img: Qobuz,
      name: "Qobuz",
      isChecked:true
    },
    {
      id: 38,
      img: YandexMusic,
      name: "YandexMusic",
      isChecked:true
    },
    {
      id: 39,
      img: FacebookAudioLibrary,
      name: "FacebookAudioLibrary",
      isChecked:true
    },
    {
      id: 40,
      img: FacebookFingerPrinting,
      name: "FacebookFingerPrinting",
      isChecked:true
    },
    {
      id: 41,
      img: NetEaseCloudMusic,
      name: "NetEaseCloudMusic",
      isChecked:true
    },
    {
      id: 42,
      img: HitsNl,
      name: "HitsNl",
      isChecked:true
    },
    {
      id: 43,
      img: NaverMusic,
      name: "NaverMusic",
      isChecked:true
    },
    {
      id: 44,
      img: AlibabaXiami,
      name: "AlibabaXiami",
      isChecked:true
    },
    {
      id: 45,
      img: UMA,
      name: "UMA",
      isChecked:true
    },
    {
      id: 46,
      img: lineMusic,
      name: "lineMusic",
      isChecked:true
    },

    {
      id: 47,
      img: imusica,
      name: "imusica",
      isChecked:true
    },
    {
      id: 48,
      img: Fizy,
      name: "Fizy",
      isChecked:true
    },
    {
      id: 49,
      img: iam,
      name: "iam",
      isChecked:true
    },
    {
      id: 50,
      img: awa,
      name: "awa",
      isChecked:true
    },
    
    {
      id: 51,
      img: target,
      name: "target",
      isChecked:true
    },
    {
      id: 52,
      img: touchtunes,
      name: "touchtunes",
      isChecked:true
    },

    {
      id: 53,
      img: Ultimatemusic,
      name: "Ultimate music",
       isChecked:true
    },
    {
      id: 54,
      img: Neurotic,
      name: "Neurotic",
      isChecked:true
    },
    {
      id: 55,
      img: Timmusic,
      name: "Timmusic",
      isChecked:true
    },
  ];

import Wallet from "../../assets/sidebarImages/wallet.png";
import { BiHomeAlt, BiAlbum, BiRupee, } from "react-icons/bi";
import { BsMusicNoteBeamed, BsXCircle, BsMusicNoteList } from "react-icons/bs";
import { SlDiamond } from "react-icons/sl";
import { GiMusicSpell } from "react-icons/gi";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";
import { BsBank2 } from "react-icons/bs";
import { TfiWallet } from "react-icons/tfi";
import { RiAccountCircleLine } from "react-icons/ri";
import { PiHandCoinsLight } from "react-icons/pi";
import { BsFillBagFill } from "react-icons/bs";


export const sidebarData = [
  {
    path: "/artist/dashboard",
    name: "Dashboard",
    icon: <BiHomeAlt />,
  },
  {
    path: "/artist/singletrack",
    name: "Distribute Single Track",
    icon: <BsMusicNoteBeamed />,
  },
  {
    path: "/artist/album",
    name: "Distribute Whole Album",
    icon: <BiAlbum />,
  },
  {
    path: "/artist/songmastering",
    name: "Song Mastering",
    icon: <BsMusicNoteList />,
  },
  {
    path: "/artist/portfolio",
    name: "Portfolio",
    icon: <BsFillBagFill />,
  },
  {
    path: "/artist/mymusic",
    name: "My Music",
    icon: <BsMusicNoteBeamed />,
  },
  {
    path: "/artist/rejection",
    name: "Avoid Song Rejection",
    icon: <BsXCircle />,
  },
  {
    //   path: "/wallet",
    name: "Wallet",
    icon: <TfiWallet />,
    subNav: [
      {
        path: "/artist/bank",
        name: "Bank",
        icon: <BsBank2 />,
      },
      {
        path: "/artist/wallet",
        name: "Wallet",
        icon: Wallet,
      },
      {
        path: "/artist/payment_history",
        name: "Payment History",
        icon: Wallet,
      },
      {
        path: "/artist/referrals",
        name: "Referrals",
        icon: <PiHandCoinsLight/>,
      },
    ],
  },
  {
    path: "/artist/package",
    name: "Bundle Package",
    icon: <SlDiamond />,
  },
  {
    path: "/artist/earned",
    name: "Royalty Earned",
    icon: <BiRupee />,
  },

  {
    path: "/artist/audio",
    name: "Mastered Audio",
    icon: <GiMusicSpell />,
  },

  {
    //   path: "/wallet",
    name: "Account",
    icon: <RiAccountCircleLine />,
    subNav: [
      {
        path: "/artist/profile",
        name: "Profile",
        icon: <BsBank2 />,
      },
      {
        path: "/artist/change_password",
        name: "Change Password",
        icon: Wallet,
      },
      // {
      //   path: "/artist/payment_history",
      //   name: "Payment History",
      //   icon: Wallet,
      // },
    ],
  },

  {
    path: "/artist/help",
    name: "Help Center",
    icon: <HiOutlineQuestionMarkCircle />,
  },
 
];

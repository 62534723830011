import React from "react";
import { useState, useEffect } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import MyMusic from "../components/myMusic";
import { Box } from "@mui/material";
import { ScaleLoader } from "react-spinners";
import axios from "axios";
const Profile = ({ setCareer }) => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const toggleSidebar = () => {
    setOpenSidebar((p) => !p);
  };
  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;
  const userid = loginToken?.user._id;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [openTable, setOpenTable] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  async function getOrder() {
    await axios
      .get(`${process.env.REACT_APP_DMT_API_PATH}/orders`, config)
      .then((res) => {
        if (res.data.error !== true && res.data.data.length > 0) {
          setOpenTable(true);
          setData(res.data.data);
          if (res.data === []) {
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getOrder();
  }, []);
  return (
    <>
      <div className="container">
        <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
        <div className="main-container">
          <Navbar toggleSidebar={toggleSidebar} />
          <div className="pages">
            <Box>
              {isLoading ? (
                <ScaleLoader
                  size={350}
                  textAlign="center"
                  className="music-loader"
                  color="#FBCE2E"
                />
              ) : (
                <MyMusic data={data} setData={setData} setCareer={setCareer} />
              )}
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;

import React from "react";
import Link from "@mui/material/Link";
import { Container, Grid, ListItem, Typography, Box } from "@mui/material";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Custombutton from "../../components/common/customButton";

const Multialbum = () => {
  const Pricingcard = {
    maxWidth: "400px",
    backgroundColor: " #fff",
    borderRadius: "calc(.25rem - 1px) calc(0.25rem - 1px) 0 0",
    margin: "auto",
  };
  const Pricingcardtwo = {
    maxWidth: "400px",
    color: "#fff",
    borderRadius: "calc(.25rem - 1px) calc(0.25rem - 1px) 0 0",
    minHeight: "547px",
    margin: "auto",
    background:"#2c3140"
  };
  const Pricingcardthree = {
    maxWidth: "400px",
    backgroundColor: " #fff",
    color: "#fff",
    borderRadius: "calc(.25rem - 1px) calc(0.25rem - 1px) 0 0",
    minHeight: "547px",
    margin: "auto",
  };
  return (
    <Box sx={{ maxWidth: "940px", margin: "auto" }}>
      <Typography
        variant="h2"
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          fontFamily: "DM Sans",
          color: " #333",
          //   fontSize: "2rem",
          fontSize: { md: "2rem", xs: "20px" },
          marginBottom: "50px",
          marginTop: "50px",
        }}
      >
        Multi Album Pricing
      </Typography>
      <Grid container   rowSpacing={4}  columnSpacing={2}>
        <Grid item xs={12} md={6} sm={6}>
          <Card sx={Pricingcard}>
            <Box
              display="flex"
              justifyContent="center"
              bgcolor="#fefbf6"
              padding="10px 30px"
            >
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    color: "#000",
                    fontSize: {md:"31px",xs:"26px"},
                    fontWeight: "700",
                    marginTop: "15px",
                    marginLeft: "10px",
                    fontStyle: "normal",
                  }}
                >
                  ₹ 3,999
                </Typography>
                <Typography
                  variant="p"
                  sx={{ fontSize: " 14px", fontWeight: " 100", color: "#333" }}
                >
                  {" "}
                  + 10% Royalties
                </Typography>
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  color: "#000",
                  fontSize: "0.9375rem",
                  marginBottom: "1rem",
                  padding: "10px",
                }}
              >
                <Typography variant="p"> |</Typography>
                <Typography variant="h6" sx={{ fontSize: "0.9375rem" }}>
                  {" "}
                  OR
                </Typography>
                <Typography variant="p"> |</Typography>
              </Box>{" "}
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    color: "#000",
                    fontSize: {md:"31px",xs:"26px"},
                    fontWeight: "700",
                    marginTop: "15px",
                    marginLeft: "10px",
                    fontStyle: "normal",
                  }}
                >
                  No Fees
                </Typography>
                <Typography
                  variant="p"
                  sx={{ fontSize: " 14px", fontWeight: " 100", color: "#333" }}
                >
                  {" "}
                  + 30% Royalties
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: "20px",
                color: "#000",
                marginBottom: "1rem",
                textAlign: "center",
                background: "#fefbf6",
                mt: "0px !important",
              }}
            >
              (Upto 7 songs)
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "21px",
                textAlign: "center",
                fontFamily: "DM Sans",
                fontWeight: "bolder",
                color: " #000",
              }}
            >
              ALBUM
            </Typography>
            <List>
              <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: " #333",
                    float: "right",
                    textAlign: " center",
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#fb8e0b !important",
                      marginRight: "1rem",
                      marginTop: "-10px",
                      width: "20px",
                      height: "20px",
                      marginBottom: "-7px",
                    }}
                  />{" "}
                  Unlimited Suite of Services
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: " #333",
                    float: "right",
                    textAlign: " center",
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#fb8e0b !important",
                      marginRight: "1rem",
                      marginTop: "-10px",
                      width: "20px",
                      height: "20px",
                      marginBottom: "-7px",
                    }}
                  />{" "}
                  Free UPC and ISRC codes
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: " #333",
                    float: "right",
                    textAlign: " center",
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#fb8e0b !important",
                      marginRight: "1rem",
                      marginTop: "-10px",
                      width: "20px",
                      height: "20px",
                      marginBottom: "-7px",
                    }}
                  />{" "}
                  No Hidden Costs
                </Typography>
              </ListItem>
              {/* <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: " #333",
                    float: "right",
                    textAlign: " center",
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#fb8e0b !important",
                      marginRight: "1rem",
                      marginTop: "-10px",
                      width: "20px",
                      height: "20px",
                      marginBottom: "-7px",
                    }}
                  />
                  Keep 90% of Your Royalties
                </Typography>
              </ListItem> */}
              <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: " #333",
                    float: "right",
                    textAlign: " center",
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#fb8e0b !important",
                      marginRight: "1rem",
                      marginTop: "-10px",
                      width: "20px",
                      height: "20px",
                      marginBottom: "-7px",
                    }}
                  />
                  Life-time Distribution
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: " #333",
                    float: "right",
                    textAlign: " center",
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#fb8e0b !important",
                      marginRight: "1rem",
                      marginTop: "-10px",
                      width: "20px",
                      height: "20px",
                      marginBottom: "-7px",
                    }}
                  />
                  No Yearly Fee
                </Typography>
              </ListItem>
              <Box textAlign="center" padding="20px">
                {" "}
                <Link href='/signup/artist'  underline="none" > 
                <Custombutton
                  btnText="Distribute Now"
                  borderRadius="2rem"
                  fontSize="1.25rem"
                  padding="8px 40px"
                  buttonStyle='light-theme-btn'
                  width="244px"
                  height="56px"
                />
                </Link>
              </Box>
            </List>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} sm={6}>
          <Card sx={Pricingcardtwo}  backgroundColor='#2c3140 !important'  >
            <Box
              display="flex"
              justifyContent="center"
                bgcolor="#2c3140"
              padding="10px 30px"
              color="#000"
            >
              <Box >
                <Typography
                  variant="h5"
                  sx={{
                    color: "white",
                    fontSize:  {md:"31px",xs:"26px"},
                    fontWeight: "700",
                    marginTop: "15px",
                    marginLeft: "10px",
                    fontStyle: "normal",
                     
                  }}
                >
                  ₹ 7,999
                </Typography>
                <Typography
                  variant="p"
                  sx={{ fontSize: " 14px", fontWeight: " 100", color: "white" }}
                >
                  {" "}
                  + 10% Royalties
                </Typography>
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  color: "white",
                  fontSize: "0.9375rem",
                  marginBottom: "1rem",
                  padding: "10px",
                }}
              >
                <Typography variant="p"> |</Typography>
                <Typography variant="h6" sx={{ fontSize: "0.9375rem" }}>
                  {" "}
                  OR
                </Typography>
                <Typography variant="p"> |</Typography>
              </Box>{" "}
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    color: "white",
                    fontSize:  {md:"31px",xs:"26px"},
                    fontWeight: "700",
                    marginTop: "15px",
                    marginLeft: "10px",
                    fontStyle: "normal",
                  }}
                >
                  No Fees
                </Typography>
                <Typography
                  variant="p"
                  sx={{ fontSize: " 14px", fontWeight: " 100", color: "white" }}
                >
                  {" "}
                  + 30% Royalties
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: "20px",
                color: "white",
                // marginBottom: "0.5rem",
                textAlign: "center",
                background: "#2c3140",
                mt: "0px !important",
                
              }}
            >
              (8-14 songs)
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "21px",
                textAlign: "center",
                fontFamily: "DM Sans",
                fontWeight: "bolder",
                color: "white",
                background:"#2c3140",
              }}
            >
              ALBUM
            </Typography>
            <List sx={{ background:"#2c3140"}}  >
              <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
                // bgcolor="#2c3140"  
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: "white",
                    float: "right",
                    textAlign: " center",
                    
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#fb8e0b !important",
                      marginRight: "1rem",
                      marginTop: "-10px",
                      width: "20px",
                      height: "20px",
                      marginBottom: "-7px",
                     
                    }}
                  />{" "}
                  Unlimited Suite of Services
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: "white",
                    float: "right",
                    textAlign: " center",
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#fb8e0b !important",
                      marginRight: "1rem",
                      marginTop: "-10px",
                      width: "20px",
                      height: "20px",
                      marginBottom: "-7px",
                    }}
                  />{" "}
                  Free UPC and ISRC codes
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: "white",
                    float: "right",
                    textAlign: " center",
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#fb8e0b !important",
                      marginRight: "1rem",
                      marginTop: "-10px",
                      width: "20px",
                      height: "20px",
                      marginBottom: "-7px",
                    }}
                  />{" "}
                  No Hidden Costs
                </Typography>
              </ListItem>
              {/* <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: "white",
                    float: "right",
                    textAlign: " center",
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#fb8e0b !important",
                      marginRight: "1rem",
                      marginTop: "-10px",
                      width: "20px",
                      height: "20px",
                      marginBottom: "-7px",
                    }}
                  />
                  Keep 90% of Your Royalties
                </Typography>
              </ListItem> */}
              <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: "white",
                    float: "right",
                    textAlign: " center",
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#fb8e0b !important",
                      marginRight: "1rem",
                      marginTop: "-10px",
                      width: "20px",
                      height: "20px",
                      marginBottom: "-7px",
                    }}
                  />
                  Life-time Distribution
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: "white",
                    float: "right",
                    textAlign: " center",
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#fb8e0b !important",
                      marginRight: "1rem",
                      marginTop: "-10px",
                      width: "20px",
                      height: "20px",
                      marginBottom: "-7px",
                    }}
                  />
                  No Yearly Fee
                </Typography>
              </ListItem>
              <Box textAlign="center" padding="20px">
                {" "}
                <Link href='/signup/artist'  underline="none" > 
                <Custombutton
                  btnText="Distribute Now"
                  borderRadius="2rem"
                  fontSize="1.25rem"
                  padding="8px 40px"
                  buttonStyle='light-theme-btn'
                  width="244px"
                  height="56px"
                />
                </Link>
              </Box>
            </List>
          </Card>
        </Grid>
        <Grid item xs={12} >
          <Card sx={Pricingcardthree}>
            <Box
              display="flex"
              justifyContent="center"
              bgcolor="#fefbf6"
              padding="10px 30px"
            >
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    color: "#000",
                   fontSize:{md:"31px",sx:"26px"},
                    fontWeight: "700",
                    marginTop: "15px",
                    marginLeft: "10px",
                    fontStyle: "normal",
                  }}
                >
                  ₹ 11,999
                </Typography>
                <Typography
                  variant="p"
                  sx={{ fontSize: " 14px", fontWeight: " 100", color: "#333" }}
                >
                  {" "}
                  + 10% Royalties
                </Typography>
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  color: "#000",
                  fontSize: "0.9375rem",
                  marginBottom: "1rem",
                  padding: "10px",
                }}
              >
                <Typography variant="p"> |</Typography>
                <Typography variant="h6" sx={{ fontSize: "0.9375rem" }}>
                  {" "}
                  OR
                </Typography>
                <Typography variant="p"> |</Typography>
              </Box>{" "}
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    color: "#000",
                    fontSize:{md:"31px",sx:"26px"},
                    fontWeight: "700",
                    marginTop: "15px",
                    marginLeft: "10px",
                    fontStyle: "normal",
                  }}
                >
                  No Fees
                </Typography>
                <Typography
                  variant="p"
                  sx={{ fontSize: " 14px", fontWeight: " 100", color: "#333" }}
                >
                  {" "}
                  + 30% Royalties
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: "20px",
                color: "#000",
                marginBottom: "1rem",
                textAlign: "center",
                background: "#fefbf6",
                mt: "0px !important",
              }}
            >
              (15-21 songs)
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "21px",
                textAlign: "center",
                fontFamily: "DM Sans",
                fontWeight: "bolder",
                color: " #000",
              }}
            >
              ALBUM
            </Typography>
            <List>
              <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: " #333",
                    float: "right",
                    textAlign: " center",
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#fb8e0b !important",
                      marginRight: "1rem",
                      marginTop: "-10px",
                      width: "20px",
                      height: "20px",
                      marginBottom: "-7px",
                    }}
                  />{" "}
                  Unlimited Suite of Services
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: " #333",
                    float: "right",
                    textAlign: " center",
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#fb8e0b !important",
                      marginRight: "1rem",
                      marginTop: "-10px",
                      width: "20px",
                      height: "20px",
                      marginBottom: "-7px",
                    }}
                  />{" "}
                  Free UPC and ISRC codes
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: " #333",
                    float: "right",
                    textAlign: " center",
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#fb8e0b !important",
                      marginRight: "1rem",
                      marginTop: "-10px",
                      width: "20px",
                      height: "20px",
                      marginBottom: "-7px",
                    }}
                  />{" "}
                  No Hidden Costs
                </Typography>
              </ListItem>
              {/* <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: " #333",
                    float: "right",
                    textAlign: " center",
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#fb8e0b !important",
                      marginRight: "1rem",
                      marginTop: "-10px",
                      width: "20px",
                      height: "20px",
                      marginBottom: "-7px",
                    }}
                  />
                  Keep 90% of Your Royalties
                </Typography>
              </ListItem> */}
              <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: " #333",
                    float: "right",
                    textAlign: " center",
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#fb8e0b !important",
                      marginRight: "1rem",
                      marginTop: "-10px",
                      width: "20px",
                      height: "20px",
                      marginBottom: "-7px",
                    }}
                  />
                  Life-time Distribution
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: " #333",
                    float: "right",
                    textAlign: " center",
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#fb8e0b !important",
                      marginRight: "1rem",
                      marginTop: "-10px",
                      width: "20px",
                      height: "20px",
                      marginBottom: "-7px",
                    }}
                  />
                  No Yearly Fee
                </Typography>
              </ListItem>
              <Box textAlign="center" padding="20px">
                {" "}
                <Link href='/signup/artist'  underline="none" > 
                <Custombutton
                  btnText="Distribute Now"
                  borderRadius="2rem"
                  fontSize="1.25rem"
                  padding="8px 40px"
                  buttonStyle='light-theme-btn'
                  width="244px"
                  height="56px"
                />
                </Link>
              </Box>
            </List>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Multialbum;

import { Container } from "@mui/system";
import React from "react";
import "./Navbar.css";
import backArrow from "../../assets/Navbar/coolicon.png";
import Rupees from "../../assets/Navbar/rupees.png";
import Avtar from "../../assets/Navbar/avtar.png";
import { GoThreeBars } from "react-icons/go";
import {
  Box,
  Button,
  ClickAwayListener,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useEffect } from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        sx={{ color: "#33D67C" }}
        variant="determinate"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          fontSize="8px"
          sx={{
            background: " #FB9B24",
            borderRadius: "50%",
            width: "28px",
            height: "28px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "900",
            fontFamily: "DM sans",
          }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const Navbar = (props) => {
  const user = useSelector((state) => state.token);
  const { toggleSidebar } = props;
  const navigate = useNavigate();
  const [profilecheck, setProfilecheck] = useState([]);
  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;
  const userid = loginToken?.user?._id;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  async function getOrder() {
    if (!props.data) {
      axios
        .get(`${process.env.REACT_APP_DMT_API_PATH}/users`, config)
        .then((res) => {
          if (res?.data?.error !== true) {
            setProfilecheck(res?.data?.data);
          }
        })
        .catch((err) => {});
    }
  }
  useEffect(() => {
    getOrder();
  }, []);

  const [credit, setCredit] = useState([]);
  async function getCredit() {
    await axios
      .get(
        `${process.env.REACT_APP_DMT_API_PATH}/royalty?user_id=${userid}`,
        config
      )
      .then((res) => {
        setCredit(res?.data?.data?.total);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getCredit();
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const toolbtn = {
    backgroundColor: "red !important",
  };
  const handleLogout = () => {
    localStorage.removeItem("login");
    localStorage.removeItem("caution");
    navigate("/home");
  };
  return (
    <Box className="custom-dmt-container">
      <Container className="Container_navbar">
        <Box className="main">
          <Box className="navbar-left">Dashboard </Box>
          <Box className="toggle-icon" onClick={toggleSidebar}>
            {/* <GoThreeBars /> */}
          </Box>
          <Box className="navbar-right">
            <Link to="/artist/profile" className="underLine-profile">
              {props?.data?.profilePercentage === 100 ||
              profilecheck?.profilePercentage === 100 ? (
                <Box className="profile2" sx={{ my: "3px" }}>
                  {/* <CircularProgressWithLabel
                  value={
                    props?.data?.profilePercentage ||
                    profilecheck?.profilePercentage
                  }
                /> */}
                </Box>
              ) : (
                <Box className="profile" sx={{ my: "3px" }}>
                  <CircularProgressWithLabel
                    value={
                      props?.data?.profilePercentage ||
                      profilecheck?.profilePercentage
                    }
                  />

                  <p className="compltete">
                    {/* {props?.data?.profilePercentage === 100 ||
                      profilecheck?.profilePercentage === 100
                        ? ""
                        : "Complete your profile"} */}
                    Complete your profile
                  </p>
                  <img src={backArrow} alt="" />
                </Box>
              )}
              {/* <Box className="profile" sx={{ my: "3px" }}>
                <CircularProgressWithLabel
                  value={
                    props?.data?.profilePercentage ||
                    profilecheck?.profilePercentage
                  }
                />
               
                <p className="compltete">
                  {" "}
                  {props?.data?.profilePercentage === 100 ||
                  profilecheck?.profilePercentage === 100
                    ? ""
                    : "Complete your profile"}
                </p>{" "}
                <img src={backArrow} alt="" />
              </Box> */}

              {props?.data?.profilePercentage === 100 ||
              profilecheck?.profilePercentage === 100 ? (
                ""
              ) : (
                <Box
                  sx={{
                    display: { md: "none", xs: "block" },
                    marginRight: { md: "none", xs: "10px" },
                  }}
                >
                  <CircularProgressWithLabel
                    value={
                      props?.data?.profilePercentage ||
                      profilecheck?.profilePercentage
                    }
                  />
                </Box>
              )}
            </Link>
            <Box className="rupees">
              <img src={Rupees} alt="" />
              <div className="credit">
                <CurrencyRupeeIcon
                  sx={{
                    fontSize: "14px",
                    marginTop: { xs: "0px", md: "-2px" },
                  }}
                />
                <p> {credit != "" ? credit?.toFixed(0) : "0"}</p>
              </div>
            </Box>
            {/* <Box className="notification">
              <img src={Notification} alt="" />
              <IoMdNotificationsOutline />
            </Box> */}
            <Box className="avtar-details">
              <Box>
                <h5>
                  {props?.data?.name?.first || profilecheck?.name?.first}{" "}
                  {props?.data?.name?.last || profilecheck?.name?.last}
                </h5>
                <p>Artist</p>
              </Box>
              {/* <Box className='avtar-img'>
                    <img src={Avtar} alt="" />
                </Box> */}
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div className="avtar-logout" style={{}}>
                  <Tooltip
                    sx={toolbtn}
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <Button
                        sx={{
                          marginTop: "3px",
                          fontWeight: "600",
                          color: "white !important",
                        }}
                        onClick={handleLogout}
                      >
                        Logout
                      </Button>
                    }
                  >
                    {props?.data?.profilePicture ? (
                      <img
                        src={props?.data?.profilePicture}
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                        }}
                        onClick={handleTooltipOpen}
                        alt=""
                      />
                    ) : profilecheck?.profilePicture ? (
                      <img
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                        }}
                        src={profilecheck?.profilePicture}
                        onClick={handleTooltipOpen}
                        alt=""
                      />
                    ) : (
                      <img onClick={handleTooltipOpen} src={Avtar} alt="" />
                    )}
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Navbar;

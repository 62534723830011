// import "./App.scss";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import DistributeSingleTrack from "./pages/DistributeSingleTrack";
import DistributeWholeAlbum from "./pages/DistributeWholeAlbum";
import AvoidSongRejection from "./pages/AvoidSongRejection";
import Bank from "./pages/Bank";
import Wallet from "./pages/Wallet";
import PaymentHistory from "./pages/PaymentHistory";
import Profile from "./pages/Profile";
import BundlePackage from "./pages/BundlePackage";
import RoyaltyEarned from "./pages/RoyaltyEarned";
import Advertisement from "./pages/Advertisement";
import MasteredAudio from "./pages/MasteredAudio";
import ChangePassword from "./pages/ChangePassword";
import ReferEarn from "./pages/ReferEarn";
import Connect from "./pages/Connect ";
import HelpCenter from "./pages/HelpCenter";
import Sidebar from "./components/sidebar/Sidebar";
import Navbar from "./components/Navbar/Navbar";
import { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Home from "../src/container/home/home";
import About from "./container/about/about";
import Pricing from "../src/container/pricing/pricing";
import Login from "./container/login/login";
import Loginconv from "./container/loginConversion/loginConversion";
import ForgetPassword from "./container/forgot/forget";
import Signup from "../src/container/signup/signup";
import Contact from "../src/container/contact/contact";
import Stores from "../src/container/stores/store";
import Summary from "../src/components/summary/index";
import TakeDown from "../src/components/takeDown/index";
import Artwork from "./components/changeArtwork/index";
import ArtworkAlbum from "./components/changeArtwork/albumArtwork";
import Faq from "../src/container/faq/faq";
import PaymentSuccess from "./components/royalCong/Rcongratulation";
import Sitemapbutton from "./pages/sitemap";
import ChangePasswordByEmail from "./components/changePWDbyEmail/ChangePWDbyEmail";
import { Box } from "@mui/material";
import Mymusic from "./pages/Music";
import ScrollToTop from "./scroll";
import AdminLogin from "./pages/adminLogin";
import ErrorPage from "./pages/errorPage/errorPage";
import UserNavbar from "./pages/showcase";
import Portfolio from "./pages/portfolio";
import Secondportfolio from "./pages/secondportfolio";
import Thirdportfolio from "./pages/ThirdPortfolio";
import Contacttable from "../src/components/contactTable/index";
import Splash from "../src/components/imageDrop/unsplashimage";
import AuthMiddleware from './middleware';
import { createBrowserHistory } from 'history';
import ArtworkSplash from '../src/components/changeArtwork/artUnsplash'
import { useEffect } from "react";
import SongMastering from "./pages/SongMastering";
import NewFaq from "./pages/NewFaq";
import FloatingAction from "./pages/FloatingAction";
import Referrals from "./pages/Referrals";

const history = createBrowserHistory();


const themeDark = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [releasesong, setReleasesong] = useState();
  const [songalbum, setsongalbum] = useState();
  const [career, setCareer] = useState();
  const [songValue, setSongValue] = useState("");
  const [saveImage, setSaveimage] = useState(null);

  const toggleSidebar = () => {
    setOpenSidebar((p) => !p);
  }
  useEffect(() => {
    AuthMiddleware(history);
  }, []);

  return (
    <Box sx={{ overFlow: "hidden !important" }}>
      <Router>
        <ScrollToTop />
        <ThemeProvider theme={themeDark}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/login-conversion" element={<Loginconv />} />
            <Route path="/login/forget" element={<ForgetPassword />} />
            <Route path="/signup/artist" element={<Signup />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/stores" element={<Stores />} />
            <Route path="/sitemap" element={<Sitemapbutton />} />
            <Route
              exact
              path="/login/password-reset"
              element={<ChangePasswordByEmail />}
            />
            {/* <Route path="/test" element={<Testredux />} /> */}
            <Route path="/Faq" element={<Faq />} />
            <Route path="/queries" element={<NewFaq />} />
            {/* <Route path="*" element={<ErrorPage/>}/>  */}
            <Route
              path="/artist/dashboard"
              element={
                <Dashboard
                  setSongValue={setSongValue}
                  songValue={songValue}
                  setCareer={setCareer}
                  setsongalbum={setsongalbum}
                  songalbum={songalbum}
                  releasesong={releasesong}
                  setReleasesong={setReleasesong}
                />
              }
            />
            <Route
              path="/artist/singletrack"
              element={
                <DistributeSingleTrack
                  setSongValue={setSongValue}
                  songValue={songValue}
                  saveImage={saveImage}
                  setSaveimage={setSaveimage}
                />
              }
            />
            <Route
              path="/artist/singletrack/:id"
              element={
                <DistributeSingleTrack
                  setSongValue={setSongValue}
                  songValue={songValue}
                  career={career}
                />
              }
            />
            <Route
              path="/artist/album"
              element={
                <DistributeWholeAlbum
                  setSongValue={setSongValue}
                  songValue={songValue}
                  songalbum={songalbum}
                  setSaveimage={setSaveimage}
                  saveImage={saveImage}
                />
              }
            />{" "}
            <Route
              path="/artist/album/:id"
              element={
                <DistributeWholeAlbum
                  setSongValue={setSongValue}
                  songValue={songValue}
                  career={career}
                />
              }
            />
            <Route path="/artist/rejection" element={<AvoidSongRejection />} />
            <Route path="/artist/bank" element={<Bank />} />
            <Route path="/artist/wallet" element={<Wallet />} />
            <Route path="/artist/referrals" element={<Referrals/>} />
            <Route
              path="/artist/payment_history"
              element={<PaymentHistory />}
            />
            <Route path="/artist/package" element={<BundlePackage />} />
            <Route path="/artist/profile" element={<Profile />} />
            <Route
              path="/artist/change_password"
              element={<ChangePassword />}
            />
            <Route
              path="/artist/mymusic"
              element={<Mymusic setCareer={setCareer} />}
            />
            <Route path="/artist/earned" element={<RoyaltyEarned />} />
            <Route path="/artist/advertisement" element={<Advertisement />} />
            <Route path="/artist/songmastering" element={<SongMastering />} />
            <Route path="/artist/audio" element={<MasteredAudio />} />
            <Route path="/artist/connect" element={<Connect />} />
            <Route path="/artist/help" element={<HelpCenter />} />
            <Route path="/artist/refer_earn" element={<ReferEarn />} />
            <Route path="/paymentsuccess" element={<PaymentSuccess />} />
            <Route
              path="/artist/summary/:id"
              element={<Summary career={career} />}
            />
            <Route
              path="/artist/take_down/:id"
              element={<TakeDown career={career} />}
            />
            <Route
              path="/artist/change_artwork/:id"
              element={<Artwork career={career} setSaveimage={setSaveimage} saveImage={saveImage}/>}
            />
            <Route
              path="/artist/change_artworkalbum/:id"
              element={<ArtworkAlbum career={career} setSaveimage={setSaveimage} saveImage={saveImage}/>}
            />
            <Route path="/login-by-admin" element={<AdminLogin />} />
            <Route path="/login-by-admin" element={<BundlePackage />} />
            {/* <Route path="/Recongratulations" element={<Recongratulations />} /> */}
            {/* <Route path="*" element={<>Page Not Found</>} /> */}
            <Route path="/artist/portfolio" element={<Portfolio />} />
            <Route
              path="/creative-professionals/:id"
              element={<UserNavbar />}
            />
            <Route
              path="/creative-professionals-second/:id"
              element={<Secondportfolio />}
            />
            <Route
              path="/creative-professionals-third/:id"
              element={<Thirdportfolio />}
            />
            <Route path="/contacttable" element={<Contacttable />} />
            <Route
              path="/splashimage"
              element={
                <Splash setSaveimage={setSaveimage} saveImage={saveImage} />
              }
            />
            <Route
              path="/artwork-splashimage"
              element={
                <ArtworkSplash setSaveimage={setSaveimage} saveImage={saveImage} />
              }
            />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </ThemeProvider>
      </Router>
     {/* <FloatingAction/> */}
      {/* floating action button add call back phone number */}
    </Box>
  );
}

export default App;

import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef } from "react";
import {
  Container,
  Card,
  Box,
  Typography,
  Grid,
  Button,
  Divider,
} from "@mui/material";

// import dynamic from "react/dynamic";
// const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });
import ReactPlayer from "react-player";

import fogMusic from "../../assets/img/fogMusic.jpg";
import wired from "../../assets/img/wired.jpg";
import consert from "../../assets/img/consert.jpg";
import audioTrack from "../../assets/img/audioTrack.jpg";
import musicWave from "../../assets/img/musicWave.jpg";
import robot from "../../assets/img/robot.jpg";
import resso from "../../assets/img/resso.jpg";
import stream from "../../assets/img/stream.jpg";
import girl from "../../assets/img/girl.jpg";
import mike from "../../assets/img/mike.jpg";
import axios from "axios";
import { Link } from "react-router-dom";

const Student_Data = [
  {
    id: 1,
    icon: fogMusic,
    title:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias, illo?",
  },

  {
    id: 2,
    icon: wired,
    title:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias, illo?",
  },

  {
    id: 3,
    icon: consert,
    title:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias, illo?",
  },
  {
    id: 4,
    icon: audioTrack,
    title:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias, illo?",
  },
  {
    id: 5,
    icon: musicWave,
    title:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias, illo?",
  },
  {
    id: 6,
    icon: robot,
    title:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias, illo?",
  },
  {
    id: 7,
    icon: resso,
    title:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias, illo?",
  },
  {
    id: 8,
    icon: stream,
    title:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias, illo?",
  },
  {
    id: 9,
    icon: girl,
    title:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias, illo?",
  },
  {
    id: 10,
    icon: mike,
    title:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Alias, illo?",
  },
];

const Video_Data = [
  {
    id: 1,
    vidUrl: "https://www.youtube.com/watch?v=7cxUv4d1d_Y",
  },

  {
    id: 2,
    vidUrl: "https://www.youtube.com/shorts/6BmBmfw66Mo",
  },
  {
    id: 3,
    vidUrl: "https://youtu.be/i1wkdzzu0UA",
  },

  // {
  //     id: 3,
  //     vidUrl:
  //         "https://www.youtube.com/shorts/6BmBmfw66Mo",
  // },
  // {
  //     id: 4,
  //     vidUrl:
  //         "https://www.youtube.com/shorts/6BmBmfw66Mo",
  // },
];

const Speed = () => {
  const [data, setData] = useState([]);

  async function getBlogs() {
    await axios
      .get(
        "https://blog.delivermytune.com/wp-json/wp/v2/posts?categories=3&_embed"
      )
      .then((res) => {
        setData(res?.data);
        // console.log(res?.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getBlogs();
  }, []);

  return (
    <Container
      sx={{ maxWidth: "lg !important", margin: "20px auto !important" }}
    >
      <Typography
        variant="paragraph"
        sx={{ fontSize: "15px", lineHeight: "20px" }}
      >
        We believe that getting your music distributed should be the least of
        your problems, checkout some of our guidebooks and videos below to
        understand how to use this platform!
      </Typography>
      <br />
      <br />
      <Typography
        variant="div"
        sx={{
          fontSize: "25px !important",
          lineHeight: "20px",
          fontWeight: "bold",
          paddingTop: "10px !important",
        }}
      >
        Guidebooks:
      </Typography>
      <Grid container spacing={2}>
        {data?.map((value, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            xl={3}
            // key={index}
            sx={{
              margin: "30px 0px",
              paddingBottom: "0px !important",
              textAlign: "-webkit-center",
            }}
          >
            <Card
              lg={4}
              sx={{
                maxWidth: "362px",
                border: "1px solid black",
                borderRadius: "20px !important",
                minHeight: "370px",
                margin: "auto",
              }}
            >
              <Box>
                <img
                  width="100%"
                  height="210px"
                  src={
                    value?._embedded["wp:featuredmedia"][0]?.media_details
                      ?.sizes?.full?.source_url
                  }
                  alt="type image"
                />
              </Box>
              <Typography
                // gutterBottom
                variant="h5"
                component="div"
                sx={{
                  fontFamily: "DM sans",
                  textAlign: "justify",
                  fontSize: {
                    // xs: "13px",
                    xs: "22px",
                  },
                  fontWeight: 500,
                  lineHeight: {
                    // xs: "17px",
                    xs: "2rem",
                  },
                  padding: "20px",
                  // backgroundColor: "#2b2b2b",
                  color: "#ffffff",
                }}
              >
                <a
                  href={value.link}
                  target="_blank"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  {value.title.rendered}
                </a>
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Divider />
      {/* {/ <Container sx={{maxWidth: "lg"}}> /} */}
      <Typography sx={{ fontSize: "22px", margin: "8px 0px" }}>
        Videos:
      </Typography>
      <Grid container spacing={2}>
        {Video_Data.map((value, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            xl={3}
            key={index}
            sx={{
              margin: "30px 0px",
              padding: "0px",
              textAlign: "-webkit-center",
            }}
          >
            <Card
              lg={4}
              sx={{
                maxWidth: "320px",
                border: "1px solid black",
                borderRadius: "20px !important",
                height: "500px",
                // margin: "auto",
              }}
            >
              <ReactPlayer
                loop={true}
                width="100%"
                height="100%"
                volume
                autoPlay={false}
                url={value.vidUrl}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
      {/* {/ /</Container> /} */}
    </Container>
  );
};

export default Speed;

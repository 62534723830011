import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_DMT_API_PATH,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("login")}`,
  },
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
 
    if (error.response.status === 401) {
      // localStorage.removeItem("login");
      // window.location.href = "/login"; // redirect to login page
    }
    return Promise.reject(error);
  }
);

const AxiosClient = async (type, apiPath, payload, toolkit, params) => {
  const AxiosTypeString = {
    GET: "get",
    POST: "post",
    PUT: "put",
    PATCH: "patch",
    DELETE: "delete",
  };

  return api({
    method: AxiosTypeString[type],
    url: apiPath,
    data: payload,
    params,
  })
    .then((response) => {
      return toolkit.fulfillWithValue(response.data);
    })
    .catch((error) => {
      return toolkit.rejectWithValue(error.response.data);
    });
};

export { AxiosClient };
export default api;

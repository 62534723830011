import React, { useEffect, useState } from "react";
import { ScaleLoader } from "react-spinners";
import "../bankDetails/bankForm.css";
import {
  Box,
  Stack,
  Typography,
  TextField,
  Button,
  Grid,
  MenuItem,
  IconButton,
} from "@mui/material";
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import { Container } from "@mui/system";
import { useDropzone } from "react-dropzone";
import "./drag.css";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Gst from "./Gst";
import Pan from "./Pan";
import Passbook from "./Passbook";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// -----------------------------------------Formik Area---------------------------
function BankForm({ data, setData, getOrder, formData }) {
  const [loading, setLoading] = useState(false);
  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;
  const userid = loginToken?.user._id;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [gstfile, setgstFile] = useState();
  const [panfile, setPanfile] = useState();
  const [passbookfile, setPassbookfile] = useState();
  const initialValues = {
    fullName: "",
    accountType: "",
    accountNumber: "",
    ifscCode: "",
    payPal: "",
    gstNumber: "",
    panNumber: "",
    gstData: "",
    panData: "",
    passBookData: "",
  };
  useEffect(() => {
    if (data) {
      formik.setFieldValue("fullName", data?.fullName);
      formik.setFieldValue("accountType", data?.accountType);
      formik.setFieldValue("accountNumber", data?.accountNo);
      formik.setFieldValue("ifscCode", data?.ifscCode);
      formik.setFieldValue("payPal", data?.paypalId);
      formik.setFieldValue("gstNumber", data?.gstNo);
      formik.setFieldValue("panNumber", data?.panNo);
    }
  }, [data]);
  useEffect(() => {
    if (data?.panFile) {
      setPanfile(data?.panFile);
    }
  }, [data]);
  useEffect(() => {
    if (data?.gstFile) {
      setgstFile(data?.gstFile);
    }
  }, [data]);
  useEffect(() => {
    if (data?.bankFile) {
      setPassbookfile(data?.bankFile);
    }
  }, [data]);

  const validationSchema = Yup.object({
    fullName: Yup.string().required("Please Enter Your Full Name"),
    payPal: Yup.string().required("Please Provide Your Paypal ID"),
  });

  const onSubmit = async (values) => {
    setLoading(true);
    var formDatavalue = new FormData();
    formDatavalue.append("fullName", values.fullName);
    formDatavalue.append("accountType", values.accountType);
    formDatavalue.append("accountNo", values.accountNumber);
    formDatavalue.append("ifscCode", values.ifscCode);
    formDatavalue.append("paypalId", values.payPal);
    formDatavalue.append("gstNo", values.gstNumber);
    formDatavalue.append("panNo", values.panNumber);
    formDatavalue.append("is_non_indian", formData?.Nationality);
    formDatavalue.append("gstFile", gstfile);
    formDatavalue.append("panFile", panfile);
    formDatavalue.append("bankFile", passbookfile);
    await axios
      .put(`${process.env.REACT_APP_DMT_API_PATH}/users/bank`, formDatavalue, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.error !== true) {
          toast.success("updated Bank details");
          setLoading(false);
          getOrder();
        }
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <>
      {loading ? (
        <ScaleLoader
          size={350}
          textAlign="center"
          className="music-loader"
          color="#FBCE2E"
        />
      ) : (
        <Container
          id="wraper-container"
          sx={{ borderRadius: "15px", pt: "30px", pb: "20px" }}
        >
          <Formik>
            <Form onSubmit={formik.handleSubmit}>
              <Typography variant="h5" fontWeight="bold" mt={2}>
                Payment Details
              </Typography>

              <Grid container id="grid-master-container" spacing={5}>
                <Grid item xs={12}>
                  {/* -------------------------Full Name Section Start----------------------------- */}
                  <Box>
                    <Typography id="bfText-label">
                      Full Name (As Per Bank Account)
                    </Typography>

                    <TextField
                      id="bfTextField"
                      sx={{
                        background: "rgba(255, 255, 255, 0.05) !important",
                        height: "44px !important",
                        borderRadius: "5px",
                        p: 1,
                        fontFamily: "DM Sans",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        background: "rgba(255, 255, 255, 0.05) !important",
                      }}
                      variant="standard"
                      fullWidth
                      label=""
                      name="fullName"
                      value={formik.values.fullName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />

                    {formik.touched.fullName && formik.errors.fullName ? (
                      <Typography color="error">
                        {formik.errors.fullName}
                      </Typography>
                    ) : null}
                  </Box>
                  {/* -------------------------Full Name Section End----------------------------- */}
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  {/* -------------------------Account Type Section Start------------------------ */}

                  <Box>
                    <Typography id="bfText-label">Account Type</Typography>

                    <TextField
                      className="bfTextField"
                      sx={{
                        background: "rgba(255, 255, 255, 0.05) !important",
                        height: "44px !important",
                        borderRadius: "5px",
                        p: 1,
                        fontFamily: "DM Sans",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        background: "rgba(255, 255, 255, 0.05) !important",
                      }}
                      select
                      variant="standard"
                      label=""
                      fullWidth
                      name="accountType"
                      value={formik.values.accountType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value="IN">
                        {" "}
                        <Typography sx={{ fontFamily: "DM sans" }}>
                          {" "}
                          Current
                        </Typography>{" "}
                      </MenuItem>
                      <MenuItem value="US">
                        {" "}
                        <Typography sx={{ fontFamily: "DM sans" }}>
                          {" "}
                          Saving
                        </Typography>{" "}
                      </MenuItem>
                    </TextField>
                  </Box>

                  {/* -------------------------Account Type Section End------------------------ */}
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  {/* ------------------------- Account Number Section Start------------------------ */}

                  <Box>
                    <Typography id="bfText-label">Account Number</Typography>
                    <TextField
                      className="bfTextField"
                      sx={{
                        background: "rgba(255, 255, 255, 0.05) !important",
                        height: "44px !important",
                        borderRadius: "5px",
                        p: 1,
                        fontFamily: "DM Sans",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        background: "rgba(255, 255, 255, 0.05) !important",
                      }}
                      variant="standard"
                      label=""
                      fullWidth
                      name="accountNumber"
                      value={formik.values.accountNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Box>

                  {/* ------------------------- Account Number Section End------------------------ */}
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  {/* ------------------------- IFSC Code Section Start------------------------ */}

                  <Box>
                    <Typography id="bfText-label">IFSC Code</Typography>
                    <TextField
                      className="bfTextField"
                      sx={{
                        background: "rgba(255, 255, 255, 0.05) !important",
                        height: "44px !important",
                        borderRadius: "5px",
                        p: 1,
                        fontFamily: "DM Sans",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        background: "rgba(255, 255, 255, 0.05) !important",
                      }}
                      variant="standard"
                      label=""
                      fullWidth
                      name="ifscCode"
                      value={formik.values.ifscCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Box>

                  {/* ------------------------- IFSC Code Section End------------------------ */}
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  {/* ------------------------- PayPal ID Section Start------------------------ */}

                  <Box>
                    <Typography id="bfText-label">PayPal ID</Typography>
                    <TextField
                      className="bfTextField"
                      sx={{
                        background: "rgba(255, 255, 255, 0.05) !important",
                        height: "44px !important",
                        borderRadius: "5px",
                        p: 1,
                        fontFamily: "DM Sans",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        background: "rgba(255, 255, 255, 0.05) !important",
                      }}
                      variant="standard"
                      label=""
                      fullWidth
                      name="payPal"
                      value={formik.values.payPal}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />

                    {formik.touched.payPal && formik.errors.payPal ? (
                      <Typography color="error">
                        {formik.errors.payPal}
                      </Typography>
                    ) : null}
                  </Box>

                  {/* ------------------------- PayPal ID Section End------------------------ */}
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  {/* -------------------------  GST Number Section Start-------------------- */}
                  <Box>
                    <Typography id="bfText-label">GST Number</Typography>
                    <TextField
                      className="bfTextField"
                      sx={{
                        background: "rgba(255, 255, 255, 0.05) !important",
                        height: "44px !important",
                        borderRadius: "5px",
                        p: 1,
                        fontFamily: "DM Sans",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        background: "rgba(255, 255, 255, 0.05) !important",
                      }}
                      variant="standard"
                      label=""
                      fullWidth
                      name="gstNumber"
                      value={formik.values.gstNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Box>
                  {/* -------------------------  GST Number Section End-------------------- */}
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  {/* -------------------------  PAN Number Section Start------------------ */}
                  <Box>
                    <Typography id="bfText-label">PAN Number</Typography>

                    <TextField
                      className="bfTextField"
                      sx={{
                        background: "rgba(255, 255, 255, 0.05) !important",
                        height: "44px !important",
                        borderRadius: "5px",
                        p: 1,
                        fontFamily: "DM Sans",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        background: "rgba(255, 255, 255, 0.05) !important",
                      }}
                      variant="standard"
                      label=""
                      fullWidth
                      name="panNumber"
                      value={formik.values.panNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Box>
                  {/* -------------------------  PAN Number Section End------------------ */}
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <Box className="bfPage-documentArrenger">
                    <Typography id="upLoadFile-Lable">
                      GST Certification Document
                    </Typography>
                    {/* -----------------------------GST Section Start----------- */}
                    <Gst
                      setgstFile={setgstFile}
                      data={data}
                      gstfile={gstfile}
                    />
                    {/* -----------------------------GST Section End----------- */}
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <Box className="bfPage-documentArrenger">
                    <Typography id="upLoadFile-Lable">
                      PAN Card Document
                    </Typography>
                    {/* -------------------------PAN Section Start----------- */}
                    <Pan
                      setPanfile={setPanfile}
                      panfile={panfile}
                      data={data}
                    />
                    {/* ----------------------------PAN Section End----------- */}
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <Box className="bfPage-documentArrenger">
                    <Typography id="upLoadFile-Lable">
                      Bank Detail Document
                    </Typography>
                    {/* -------------------------Bank DOcument Section Start----------- */}
                    <Passbook
                      setPassbookfile={setPassbookfile}
                      passbookfile={passbookfile}
                      data={data}
                    />
                    {/* ------------------------Bank Document Section End----------- */}
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box textAlign={"center"}>
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      id="bankDetailsForm"
                      sx={{
                        background: "#f9a40d !important",
                        fontWeight: "700",
                        textTransform: "capitalize",
                        borderRadius: "21px",
                        fontFamily: "'DM Sans', sans-serif !important",
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          </Formik>
          <ToastContainer />
        </Container>
      )}
    </>
  );
}

export default BankForm;

import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "@mui/material/TextField";
import "./style.css";
import * as yup from "yup";
import AddIcon from "@mui/icons-material/Add";
// import { toast, ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import { useLoginArtistMutation } from "../../redux/services/login";
import { useCopyrightQuery } from "../../redux/services/dashboard";
import axios from "axios";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { userSlice } from "../redux/slices/authSlice";
import { tokenSlice } from "../../redux/slices/tokenSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import flags from "react-phone-number-input/flags";
import styled from "styled-components";

const theme = createTheme();
const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

export default function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [createPost, responseInfo] = useLoginArtistMutation();
  const [data, setData] = useState([]);
  const [id, setId] = useState();
  const [otp, setOtp] = useState("");
  const [counter, setCounter] = useState();
  const [datasave, setDatasave] = useState();
  const [code, setCode] = useState(false);
  const [sendotpshow, setSendotpshow] = useState(true);
  const [storedata, setStoredata] = useState();
  const [phoneDetails, setPhoneDetails] = useState({});
  const [idstore, setIdstore] = useState();
  const handlePhoneNumberChange = (value, country, event) => {
    setPhoneDetails({
      phone: value,
    });
  };
  useEffect(() => {
    const timer =
      counter > 0 &&
      setInterval(() => {
        setCounter(counter - 1);
      }, 1000);

    return () => clearInterval(timer);
  }, [counter]);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      phone: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        email: values.email,
        password: values.password,
      };

      await axios
        .post(
          `${process.env.REACT_APP_DMT_API_PATH}/authentication/local/check-mobile`,
          payload
        )
        .then(async (res) => {
          if (res.data.error === true) {
            toast.error(res.data.message);
          } else if (res.data.phoneregsiter === true) {
            await axios
              .post(
                `${process.env.REACT_APP_DMT_API_PATH}/authentication/local`,
                payload
              )
              .then((res) => {
                if (res.data.error === true) {
                  toast.error(res.data.message);
                } else if (res.data.error === false) {
                  localStorage.setItem(
                    "login",
                    JSON.stringify(res?.data?.user)
                  );
                  dispatch(tokenSlice.actions.saveToken(res?.data?.user));

                  axios
                    .get(
                      `${process.env.REACT_APP_DMT_API_PATH}/admin/orders/copyright/${res?.data?.user?.user?.id}`
                    )
                    .then((response) => {
                      setData(response?.data?.data);
                      if (
                        response?.data?.data.length < 2 &&
                        !res?.data?.user?.user?.disable &&
                        res?.data?.user?.user?.status_user !== "InActive"
                      ) {
                        navigate("/artist/dashboard");
                      } else if (
                        response?.data?.data.length > 3 ||
                        res?.data?.user?.user?.disable ||
                        res?.data?.user?.user?.status_user === "InActive"
                      ) {
                        toast.error("Account Blocked");
                      } else if (
                        response?.data?.data.length == 2 ||
                        (response?.data?.data.length == 3 &&
                          !res?.data?.user?.user?.disable &&
                          res?.data?.user?.user?.status_user !== "InActive")
                      ) {
                        localStorage.setItem(
                          "caution",
                          JSON.stringify(
                            "CAUTION! You have attempted to upload copyrighted song twice on dashboard. After 3 failed attempts, This account will be blocked from distribution permanently *DeliverMyTune reserves the right to admission and seizer of royalty distribution"
                          )
                        );
                        navigate("/artist/dashboard", {
                          state: {
                            id: "CAUTION! You have attempted to upload copyrighted song twice on dashboard. After 3 failed attempts, This account will be blocked from distribution permanently *DeliverMyTune reserves the right to admission and seizer of royalty distribution",
                          },
                        });
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              })
              .catch((err) => {});
          } else if (res.data.phoneregsiter === false) {
            toast.error(
              "Please complete the phone number verification process to log in."
            );
            setIdstore(res?.data?.id);
            setSendotpshow(false);
            setStoredata(payload, ...values.phone);
            console.log(res.data);
          }
        });
    },
  });
  const handlesendotpmobile = async () => {
    setCounter(45);
    const payloaddata = {
      ...storedata,
      phone: phoneDetails.phone,
      id: idstore,
    };
    await axios
      .post(
        `${process.env.REACT_APP_DMT_API_PATH}/authentication/mobile/send-otp-mobileUpdate`,
        payloaddata
      )
      .then((response) => {
        console.log(response);
        if (response?.data?.error === false) {
          setCode(true);
        }
        else if (response?.data?.error === true){
          toast.error(response?.data.message);
        }
      })
      .catch((err) => toast.error(err.message));
  };

  const handleotpsend = (event) => {
    setOtp(event.target.value);
  };
  const ResendButton = styled.button`
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 16px;
    color: ${({ disabled }) => (disabled ? "#ccc" : "#000")};
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  `;

  const handleResendClick = async () => {
    setCounter(45);
    const payloaddata = {
      ...storedata,
      phone: phoneDetails.phone,
    };
    await axios
      .post(
        `${process.env.REACT_APP_DMT_API_PATH}/authentication/mobile/send-otp-mobileUpdate`,
        payloaddata
      )
      .then((res) => {
        if (res.data.error === true) {
          toast.error(res?.data?.message);
        } else {
          toast.success("Otp has been sent again");
        }
      });
  };

  const sendotpinmobile = async (values) => {
    const payloaddata = {
      ...storedata,
      phone: phoneDetails.phone,
      otp: otp,
    };
    await axios
      .post(
        `${process.env.REACT_APP_DMT_API_PATH}/authentication/mobile/verify-otp-mobileUpdate`,
        payloaddata
      )
      .then((res) => {
        if (res.data.error === true) {
          toast.error(res?.data?.message);
        } else if (res.data.error === false) {
          console.log(res.data, "res.data");
          localStorage.setItem("login", JSON.stringify(res?.data?.user));
          dispatch(tokenSlice.actions.saveToken(res?.data?.user));

          axios
            .get(
              `${process.env.REACT_APP_DMT_API_PATH}/admin/orders/copyright/${res?.data?.user?.user?.id}`
            )
            .then((response) => {
              setData(response?.data?.data);
              if (
                response?.data?.data.length < 2 &&
                !res?.data?.user?.user?.disable &&
                res?.data?.user?.user?.status_user !== "InActive"
              ) {
                navigate("/artist/dashboard");
              } else if (
                response?.data?.data.length > 3 ||
                res?.data?.user?.user?.disable ||
                res?.data?.user?.user?.status_user === "InActive"
              ) {
                toast.error("Account Blocked");
              } else if (
                response?.data?.data.length == 2 ||
                (response?.data?.data.length == 3 &&
                  !res?.data?.user?.user?.disable &&
                  res?.data?.user?.user?.status_user !== "InActive")
              ) {
                localStorage.setItem(
                  "caution",
                  JSON.stringify(
                    "CAUTION! You have attempted to upload copyrighted song twice on dashboard. After 3 failed attempts, This account will be blocked from distribution permanently *DeliverMyTune reserves the right to admission and seizer of royalty distribution"
                  )
                );
                navigate("/artist/dashboard", {
                  state: {
                    id: "CAUTION! You have attempted to upload copyrighted song twice on dashboard. After 3 failed attempts, This account will be blocked from distribution permanently *DeliverMyTune reserves the right to admission and seizer of royalty distribution",
                  },
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" sx={{ textAlign: "-webkit-center" }}>
        <Paper className="paper-form" component={Box} marginTop="80px">
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            padding="1rem 1.25rem;"
          >
            <Box sx={{ marginTop: "20px", paddingLeft: "1.25rem" }}>
              <Typography
                sx={{
                  textAlign: "start",
                  color: "black",
                  fontFamily: "Open Sans, sans-serif",
                  fontSize: "0.75rem",
                  fontWeight: 700,
                  lineHeight: "1rem",
                }}
              >
                Email
              </Typography>
              <TextField
                fullWidth
                size="small"
                id="email"
                name="email"
                placeholder="Email *"
                sx={{
                  background: "white",
                  marginTop: "4px",
                  borderRadius: "4px",
                  border: "1px solid #1b1b38",
                }}
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.touched.email && formik.errors.email ? (
                <Typography sx={{ textAlign: "start" }} color="error">
                  {formik.errors.email}
                </Typography>
              ) : null}
            </Box>
            <Box sx={{ marginTop: "20px", paddingLeft: "1.25rem" }}>
              <Typography
                sx={{
                  textAlign: "start",
                  color: "black",
                  fontFamily: "Open Sans, sans-serif",
                  fontSize: "0.75rem",
                  fontWeight: 700,
                  lineHeight: "1rem",
                }}
              >
                Pasword
              </Typography>
              <TextField
                fullWidth
                size="small"
                id="password"
                name="password"
                placeholder="Password *"
                type="password"
                sx={{
                  background: "white",
                  marginTop: "4px",
                  border: "1px solid #1b1b38",
                  borderRadius: "4px",
                }}
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              {formik.touched.password && formik.errors.password ? (
                <Typography sx={{ textAlign: "start" }} color="error">
                  {formik.errors.password}
                </Typography>
              ) : null}
            </Box>

            {sendotpshow === false ? (
              <Box sx={{ marginTop: "20px", paddingLeft: "1.25rem" }}>
                <Typography
                  sx={{
                    textAlign: "start",
                    color: "black",
                    fontFamily: "Open Sans, sans-serif",
                    fontSize: "0.75rem",
                    fontWeight: 700,
                    lineHeight: "1rem",
                  }}
                >
                  Mobile Number
                </Typography>
                <PhoneInput
                  international
                  defaultCountry="IN"
                  flags={flags}
                  value={phoneDetails.phone}
                  onChange={handlePhoneNumberChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter Your Mobile Number"
                  inputProps={{
                    style: {
                      height: "40px",
                      paddingLeft: "36px",
                      background: "white",
                      marginTop: "4px",
                      border: "1px solid #1b1b38",
                      borderRadius: "4px",
                    },
                    name: "phone",
                    id: "phone",
                  }}
                  buttonStyle={{
                    borderRadius: "4px 0 0 4px",
                    background: "white",
                    border: "1px solid #1b1b38",
                    height: "40px",
                  }}
                  countryCodeEditable={false}
                  renderButton={(props) => (
                    <Box
                      component="button"
                      {...props}
                      sx={{
                        position: "absolute",
                        left: "0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "36px",
                        height: "40px",
                        borderRadius: "4px 0 0 4px",
                        background: "white",
                        border: "1px solid #1b1b38",
                      }}
                    >
                      <span
                        style={{
                          backgroundImage: `url(${
                            flags[props.country]?.emoji
                          })`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "contain",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </Box>
                  )}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <Typography sx={{ textAlign: "start" }} color="error">
                    {formik.errors.phone}
                  </Typography>
                ) : null}
              </Box>
            ) : (
              ""
            )}

            {sendotpshow ? (
              <Box textAlign="center" marginTop="25px" marginBottom="15px">
                <Button className="submit-btn" type="submit">
                  <ClearIcon />
                  Login
                </Button>
              </Box>
            ) : (
              <>
                {code === true ? (
                  <>
                    <Box sx={{ marginTop: "20px", paddingLeft: "0.6rem" }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          color: "black",
                          fontFamily: "Open Sans, sans-serif",
                          fontSize: "0.75rem",
                          fontWeight: 700,
                          lineHeight: "1rem",
                          // border:"1px solid black"
                        }}
                      >
                        OTP
                      </Typography>
                      <TextField
                        halfWidth
                        size="small"
                        id="otp"
                        name="otp"
                        placeholder="Enter OTP"
                        // value={formik.values.otp}
                        onChange={handleotpsend}
                        sx={{
                          background: "white",
                          marginTop: "4px",
                          border: "1px solid #1b1b38",
                          borderRadius: "4px",
                          width: "140px",
                        }}
                      />
                    </Box>
                    {/* <Button
                sx={{marginTop:'15px'}}
                className="submit-btn"
                  onClick={() => {
                    sendotpinmobile();
                  }}
                >
                  SignUp
                </Button> */}
                    <Button
                      sx={{ marginTop: "25px" }}
                      className="submit-btn"
                      // type="submit"
                      onClick={() => {
                        sendotpinmobile();
                        // "return gtag_report_conversion('http://example.com/your-link')"
                      }}
                      // id="conversion-dmt-signup"
                    >
                      Verify Otp
                    </Button>
                    <Typography
                      className="already-account"
                      style={{ marginTop: "10px" }}
                    >
                      {counter > 0
                        ? `Resend OTP in ${counter}s`
                        : "OTP expired, please resend"}
                    </Typography>
                    <ResendButton
                      disabled={counter > 0}
                      onClick={() => {
                        handleResendClick();
                      }}
                    >
                      Resend OTP
                    </ResendButton>
                  </>
                ) : (
                  <Box textAlign="center" marginTop="25px" marginBottom="15px">
                    <Button
                      className="submit-btn"
                      onClick={() => {
                        handlesendotpmobile();
                      }}
                    >
                      <AddIcon sx={{ marginRight: "10px", color: "white" }} />
                      SEND OTP
                    </Button>
                  </Box>
                )}
              </>
            )}
            <Grid
              container
              // spacing={{ md: 12, xs: 4 }}
              sx={{ marginTop: "10px", marginBottom: "20px" }}
            >
              <Grid item md={6} xs={12}>
                <Typography className="signup">
                  <Link
                    className="link-btn"
                    underline="hover"
                    color="inherit"
                    to="/signup/artist"
                  >
                    Go to Sign up
                  </Link>
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography className="signup">
                  {" "}
                  <Link
                    className="link-btn"
                    underline="hover"
                    color="inherit"
                    to="/login/forget"
                  >
                    Forget Password?
                  </Link>
                </Typography>
              </Grid>
            </Grid>
            <ToastContainer />
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}

import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import CustomButton from "../../common/customButton/index";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import * as yup from "yup";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";

const Index = ({ handleClose, view, getOrder, number, tokenedit }) => {
  const validationSchema = yup.object().shape({
    youtubetitle: yup.string().min(2, "Too Short!").required("Required*"),
    youtubelink: yup.string().required("Required*"),
  });

  const initialValues = {
    youtubetitle: "",
    youtubelink: "",
  };

  useEffect(() => {
    if (view) {
      formik.setFieldValue("youtubetitle", view?.youtubetitle);
      formik.setFieldValue("youtubelink", view?.youtubelink);
    }
  }, []);

  const onSubmit = async (values) => {
    // const loginToken = JSON.parse(localStorage.getItem("login"));
    // const token = loginToken?.token;
    const token = tokenedit;
    var formDatavalue = new FormData();
    formDatavalue.append("youtubetitle", values.youtubetitle);
    formDatavalue.append("youtubelink", values.youtubelink);
    formDatavalue.append("bannerImage", "https://delivermytunedmt.s3.us-west-2.amazonaws.com/banner/e895fd385e8d4e70_Shape%20%282%29.png");
    if (number == 0) {
      await axios
        .post(
          `${process.env.REACT_APP_DMT_API_PATH}/creative/video`,
          formDatavalue,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error !== true) {
            getOrder();
            handleClose();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (number == 1) {
      await axios
        .put(
          `${process.env.REACT_APP_DMT_API_PATH}/creative/video/${view?._id}`,
          formDatavalue,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error !== true) {
            getOrder();
            handleClose();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <>
      <Box id="contact">
        <Container maxWidth="md" sx={{padding:"12px"}}>
          <Box
            component="form"
            sx={{ mt: "0px", minWidth: { md: "400px", xs: "100%" } }}
            onSubmit={formik.handleSubmit}
          >
            <Typography
              sx={{
                marginTop: "10px",
                marginLeft: "5px",
                fontFamily: "DM Sans",
              }}
            >
              Enter Video Title
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              placeholder="Enter Title"
              {...formik.getFieldProps("youtubetitle")}
              onChange={(e) => {
                formik.setFieldValue("youtubetitle", e.target.value);
              }}
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            />
            {formik.touched.youtubetitle && formik.errors.youtubetitle ? (
              <Typography
                sx={{
                  textAlign: "start",
                  marginLeft: "4px",
                  fontSize: "12px",
                  fontFamily: "DM Sans",
                }}
                color="error"
              >
                {formik.errors.youtubetitle}
              </Typography>
            ) : null}
            <Typography
              sx={{
                marginTop: "10px",
                marginLeft: "5px",
              }}
            >
              Enter Youtube Link
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              name="youtubelink"
              placeholder="Enter Youtube Link"
              {...formik.getFieldProps("youtubelink")}
              onChange={(e) => {
                formik.setFieldValue("youtubelink", e.target.value);
              }}
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            />
            {formik.touched.youtubelink && formik.errors.youtubelink ? (
              <Typography
                sx={{
                  textAlign: "start",
                  marginLeft: "4px",
                  fontSize: "12px",
                  fontFamily: "DM Sans",
                }}
                color="error"
              >
                {formik.errors.youtubelink}
              </Typography>
            ) : null}
            <div
              style={{
                textAlign: "center",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                <CustomButton
                  buttonStyle="editFormBtn"
                  btnText={view ? "Save" : "Add"}
                  btntype="Submit"
                />
              </div>
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Index;

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Grid } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid black",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({
  openModal,
  setOpenModal,
  handleOpen,
  handleClose,
  commentdata,
}) {
  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container sx={{ display:'flex', justifyContent:'space-between',border:'none'}}>
           
            <Grid item xs={10}>
            <Typography
              id="modal-modal-title"
              sx={{ textAlign: "center" }}
              variant="h6"
            >
              Comments
            </Typography>
           
            </Grid>
            <Grid item xs={1} onClick={handleClose}>
              <CloseIcon />
            </Grid>
          </Grid>

          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "center" }}
          >
            {commentdata?.comment}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

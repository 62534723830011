import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/system";
import { Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const BundlePricing = () => {
  return (
    <Container sx={{ my: "24px", maxWidth: "1200px !important" }}>
      <Box
        sx={{
          marginTop: "20px !important",
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            fontFamily: "DM Sans",
            color: "#333",
            fontSize: "2.25rem",
            // marginLeft:{md:"-189px",xs:"-49px"}
          }}
        >
          Bundle Pricing
        </Typography>
      </Box>
      <Grid container>
        <Grid item md={4} sm={6} sx={{ maxWidth: "100" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ padding: { md: "50px 40px", xs: "32px" } }}
            className="box"
          >
            <Box marginRight="50px">
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "21px",
                  fontWeight: "bold",
                  color: " #333",
                  fontFamily: "DM Sans",
                  height: "42px",
                }}
              >
                Pay
              </Typography>
              <br />
              <List>
                <ListItem
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "DM Sans",
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    lineHeight: "1.25rem",
                  }}
                >
                  <CurrencyRupeeIcon sx={{ fontSize: " 0.9375rem" }} /> 10,000
                </ListItem>
                <ListItem
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "DM Sans",
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    lineHeight: "1.25rem",
                  }}
                >
                  <CurrencyRupeeIcon sx={{ fontSize: " 0.9375rem" }} /> 15,000
                </ListItem>
                <ListItem
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "DM Sans",
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    lineHeight: "1.25rem",
                  }}
                >
                  <CurrencyRupeeIcon sx={{ fontSize: " 0.9375rem" }} /> 20,000
                </ListItem>
                <ListItem
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "DM Sans",
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    lineHeight: "1.25rem",
                  }}
                >
                  <CurrencyRupeeIcon sx={{ fontSize: " 0.9375rem" }} /> 25,000
                </ListItem>
                <ListItem
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "DM Sans",
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    lineHeight: "1.25rem",
                  }}
                >
                  <CurrencyRupeeIcon sx={{ fontSize: " 0.9375rem" }} /> 30,000
                </ListItem>
                <ListItem
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "DM Sans",
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    lineHeight: "1.25rem",
                  }}
                >
                  <CurrencyRupeeIcon sx={{ fontSize: " 0.9375rem" }} /> 40,000
                </ListItem>
                <ListItem
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "DM Sans",
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    lineHeight: "1.25rem",
                  }}
                >
                  <CurrencyRupeeIcon sx={{ fontSize: " 0.9375rem" }} /> 50,000
                </ListItem>
              </List>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "21px",
                  fontWeight: "bold",
                  color: " #333",
                  fontFamily: "DM Sans",
                  height: "42px",
                }}
              >
                Get in wallet
              </Typography>
              <br />
              <List>
                <ListItem
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "DM Sans",
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    lineHeight: "1.25rem",
                  }}
                >
                  <CurrencyRupeeIcon sx={{ fontSize: " 0.9375rem" }} /> 15,000
                </ListItem>
                <ListItem
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "DM Sans",
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    lineHeight: "1.25rem",
                  }}
                >
                  <CurrencyRupeeIcon sx={{ fontSize: " 0.9375rem" }} /> 22,500
                </ListItem>
                <ListItem
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "DM Sans",
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    lineHeight: "1.25rem",
                  }}
                >
                  <CurrencyRupeeIcon sx={{ fontSize: " 0.9375rem" }} /> 30,000
                </ListItem>
                <ListItem
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "DM Sans",
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    lineHeight: "1.25rem",
                  }}
                >
                  <CurrencyRupeeIcon sx={{ fontSize: " 0.9375rem" }} /> 37,500
                </ListItem>
                <ListItem
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "DM Sans",
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    lineHeight: "1.25rem",
                  }}
                >
                  <CurrencyRupeeIcon sx={{ fontSize: " 0.9375rem" }} /> 45,000
                </ListItem>
                <ListItem
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "DM Sans",
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    lineHeight: "1.25rem",
                  }}
                >
                  <CurrencyRupeeIcon sx={{ fontSize: " 0.9375rem" }} /> 60,000
                </ListItem>
                <ListItem
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "DM Sans",
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    lineHeight: "1.25rem",
                  }}
                >
                  <CurrencyRupeeIcon sx={{ fontSize: " 0.9375rem" }} /> 75,000
                </ListItem>
              </List>
            </Box>
          </Box>
        </Grid>
        <Grid item md={8} sm={6}>
          <Box
            sx={{
              padding: "20px 50px",
              backgroundColor: "#2c3140",
              borderRadius: "4px",
              height: "100%",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "24px",
                fontWeight: "bold",
                color: " #FFFFFF",
                fontFamily: "DM Sans",
              }}
            >
              Bundle Package
            </Typography>

            <List
              sx={{
                margin: { md: " 0.25rem", xs: "0px" },
                fontFamily: "DM Sans",
                fontSize: "0.9375rem",
                fontWeight: "400",
                lineHeight: "1.25rem  !important",
                padding: { md: "55px", xs: "0px" },
                color: "#FFFFFF !important",
              }}
            >
              <ListItem sx={{ padding: "15px" }}>
                {" "}
                Pay Less, Get More.{" "}
              </ListItem>
              <ListItem sx={{ padding: "15px" }}>
                Distribute, Master or Advertise with Credits whenever you want.
              </ListItem>
              <ListItem sx={{ padding: "15px" }}>
                Credit Never Expires, use it in a month or in years.
              </ListItem>
              <ListItem sx={{ padding: "15px" }}>
                All Additional Discounts and Coupons valid on Credits.
              </ListItem>
            </List>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BundlePricing;

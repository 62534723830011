import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import "./drag.css";
import { Box, Button, Typography } from "@mui/material";
import axios from "axios";

const Index = ({ details, setSongValue, setsongdesk, songValue }) => {
  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [internetSpeed, setInternetSpeed] = useState(0);
  const [totalUploadPercent, setTotalUploadPercent] = useState(0);
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
      "audio/.wav": [".wav"],
    },
  });

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setSongValue(file);
      let percentCompleted;
      const handleUpload = async () => {
        setUploadPercentage(0);
        const formData = new FormData();
        formData.append("track", file);

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_DMT_API_PATH}/orders/upload-song`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
              onUploadProgress: (progressEvent) => {
                const fileSizeInBytes = file.size;
                const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
                const uploadedSizeInMB = progressEvent.loaded / (1024 * 1024);
                percentCompleted =
                  (uploadedSizeInMB / fileSizeInMB) * 100;
                const elapsedSeconds = progressEvent.timeStamp / 1000;
                const uploadingSpeedInMBps =
                  uploadedSizeInMB / elapsedSeconds;

                setInternetSpeed(Math.floor(uploadingSpeedInMBps));
                setTotalUploadPercent(Math.floor(percentCompleted));


              },
            }
          );
          console.log(percentCompleted)
          if (percentCompleted === 99 && !response.data) {
            setUploadPercentage(99);
          }
          console.log(response.data, "response.data")
          if (response.data) {
            console.log("Upload success:", response.data.url);
            setSongValue(response.data.url);

            setUploadPercentage(100);
          }
          if (response?.data) {
            setsongdesk(response.data.url)
          }
        } catch (error) {
          console.error("Upload error:", error);
          // Handle error
        }
      };

      handleUpload();
    }
  }, [acceptedFiles]);
  const [status, setStatus] = useState("");
  useEffect(() => {
    if (uploadPercentage === 100) {
      setStatus("Uploading... 100%");
      
      setTimeout(() => {
        setStatus("Upload Complete");
       
      }, 1000);
    } else {
      setStatus(`Uploading... ${uploadPercentage}%`);

    }
  }, [uploadPercentage]);

  return (
    <>
      <Box>
        <Box
          sx={{ height: "350px !important" }}
          {...getRootProps({ className: "drop" })}
          onClick={() => { open() }}
        >
          <input {...getInputProps()} />
          <Box sx={{}}>
            <img src={details.img} alt="" />
          </Box>
          <Button type="button" className="upload-song">
            {/* Upload Song */}
            {details.btn}
          </Button>
          <Typography className="drop-song">
            Drop your {details.type} here, or{" "}
            <Typography variant="span" sx={{ color: "#FB900B" }}>
              browse
            </Typography>
          </Typography>
          <Typography className="support">Supports: .WAV Only </Typography>
          <aside>
            <ul>{songValue?.path || songValue}</ul>
            {totalUploadPercent > 0 && (
              <Typography
                sx={{
                  listStyle: "none",
                  mt: "17px !important",
                  mb: "17px !important",
                  wordBreak: "break-all",
                  textAlign: "center"
                }}
              >
                {uploadPercentage === 100
                ? status
                : `Uploading... ${totalUploadPercent===100?99:totalUploadPercent}%`}
              </Typography>
            )}
          </aside>
        </Box>
      </Box>
    </>
  );
};

export default Index;

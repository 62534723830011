import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
export const userSlice = createSlice({
  name: "user",
  initialState: {
    artwork: "",
    recordlabel: "",
    songlang: "",
    songgenre: "",
    previewstart: "",
    releasedate: "",
    moviesong: "",
    filmname: "",
    directorname: "",
    actorname: "",
    actressname: "",
    tracktomaster: "",
    songtitle: "",
    videolink: "",
    isrccode: "",
    compositiontype: "",
    cleansong: "",
    role: "",
    fullname: "",
    lastname:"",
    songurl: "",
    uploadringtone: "",
    copyrightyear: "",
    productionright: "",
    chooseplatform: "",
    plan: "",
    phone: "",
    otp: "",
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    saveUser: (state, action) => action.payload,
  },
  extraReducers: {
    // Extra reducer comes here
  },
})
export const userSelector = state => state.user
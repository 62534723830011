import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Play from "../../assets/img/play.png";
import CustomButton from "../../components/common/customButton/index";
import axios from "axios";

const Index = ({
  songValue,
  setSongValue,
  career,
  firstslidedata,
  multiformadv,
  setmultiformadv,
  songalbum,
  songprocessing,
  setSongprocessing
}) => {
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [internetSpeed, setInternetSpeed] = useState(0);
  const [totalUploadPercent, setTotalUploadPercent] = useState(0);
  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const { getRootProps, getInputProps, open, open1, acceptedFiles } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      accept: {
        "audio/.wav": [".wav"],
      },
    });

  // useEffect(() => {
  //   if (acceptedFiles) {
      // setmultiformadv({
      //   ...multiformadv,
      //   file: acceptedFiles[0],
      // });
  //   }
  // }, [acceptedFiles]);
  useEffect(() => {
    if (acceptedFiles) {
      setmultiformadv({
        ...multiformadv,
        file: acceptedFiles[0],
      });
      let percentCompleted;
      const handleUpload = async () => {
        setUploadPercentage(0);
        setSongprocessing(true);
        const formData = new FormData();
        formData.append("track", acceptedFiles[0]);
  
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_DMT_API_PATH}/orders/upload-song`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
              onUploadProgress: (progressEvent) => {
                const fileSizeInBytes = acceptedFiles[0]?.size;
                const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
                const uploadedSizeInMB = progressEvent.loaded / (1024 * 1024);
                 percentCompleted =
                  (uploadedSizeInMB / fileSizeInMB) * 100;
                const elapsedSeconds = progressEvent.timeStamp / 1000;
                const uploadingSpeedInMBps =
                  uploadedSizeInMB / elapsedSeconds;
  
                setInternetSpeed(Math.floor(uploadingSpeedInMBps));
                setTotalUploadPercent(Math.floor(percentCompleted));
  
                
              },
            }
          );
          console.log(percentCompleted)
          if (percentCompleted === 99 && !response.data) {
            setUploadPercentage(99);
          }
          if (response.data ) {
            setmultiformadv({
              ...multiformadv,
              file: response.data.url,
            });
            setUploadPercentage(100);
          }
          console.log(response);
        } catch (error) {
          console.error("Upload error:", error);
          // Handle error
        }
      };
  
      handleUpload();
    }
  }, [acceptedFiles]);

  const [status, setStatus] = useState("");
  useEffect(() => {
    if (uploadPercentage === 100) {
      setStatus("Uploading... 100%");
      
      setTimeout(() => {
        setStatus("");
        setSongprocessing(false);
      }, 1000);
    } else {
      setStatus(`Uploading... ${uploadPercentage}%`);

    }
  }, [uploadPercentage]);
  return (
    <div>
      <Box
        sx={{
          background: "#292929",
          borderRadius: "16px",
          width: "100%",
        }}
        {...getRootProps()}
        onClick={open}
      >
        <Box
          textAlign="center"
          sx={{ py: "20px", px: "20px", overflow: "hidden" }}
        >
          <input {...getInputProps()} />
          <Box>
            <img src={Play} />
          </Box>
          <CustomButton
            btnText={
              multiformadv?.file?.path || multiformadv?.file
                ? "Change Song"
                : "Upload Song"
            }
            buttonStyle="basic-btn"
            marginTop="10px"
          />
          <Typography
            sx={{
              listStyle: "none",
              mt: "17px !important",
              mb: "17px !important",
              wordBreak: "break-all",
            }}
          >
            {multiformadv?.file?.path || multiformadv?.file}
          </Typography>
          {totalUploadPercent > 0 && (
            <Typography
              sx={{
                listStyle: "none",
                mt: "17px !important",
                mb: "17px !important",
                wordBreak: "break-all",
              }}
            >
              {uploadPercentage === 100
                ? status
                : `Uploading... ${totalUploadPercent===100?99:totalUploadPercent}%`}
            </Typography>
          )}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography className="quality">
              Quality is important to us, please use the highest-quality stereo
              assets available (WAV, AIFF or FLAC only).
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Index;
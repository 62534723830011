import { Typography, Box, Button } from "@mui/material";
import React from "react";
import Link from "@mui/material/Link";
import CustomButton from "../common/customButton/index";
import { useNavigate } from "react-router-dom";
import "./style.css";

const Index = () => {
  return (
    <Box className="master-service">
      <Box sx={{ paddingTop: "52px", textAlign: "center", px: "15px" }}>
        <Typography
          variant="p"
          className="musicMasteringUpperText"
          sx={{ padding: { md: "20px", xs: "20px" } }}
        >
          Master your music for <br /> free- Radio ready quality
        </Typography>
        <Box className="button-distributed-class" mt={4}>
          <Link href="/signup/artist" underline="none">
            {" "}
            <CustomButton
              btnText="Distribute Now"
              width="189px"
              height="54px"
              fontWeight="700"
              borderRadius="27px"
              border="none"
              buttonStyle="light-theme-btn"
            />
          </Link>
          <Link href="/about" underline="none">
            <Button
              className="musicMasteringAboutUsBtn"
              sx={{ marginLeft: { md: "30px !important", xs: "none" } }}
            >
              About Us
            </Button>
          </Link>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
        >
          <Box className="musicMasteringBox">
            <Box className="manageBlueDotContainer">
              <Box className="manageBlueDot"></Box>
              <Box className="manageDot mx-1"></Box>
              <Box className="manageDot mx-1"></Box>
            </Box>
            <iframe
              width="100%"
              height="100%"
              title=""
              className=""
              style={{ marginTop: "20px" }}
              src="https://www.youtube.com/embed/RP5JJMZkHDQ"
            ></iframe>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Index;

import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";

const BankDetailsWithNri = ({ setFormData, formData }) => {
  return (
    <div>
      <Typography
        id="demo-row-radio-buttons-group-label"
        sx={{
          fontFamily: "DM Sans",
          fontWeight: "700",
          mb: "8px",
          marginLeft: "20px",
        }}
      >
        Are You Indian Or Non-Indian Customer?
      </Typography>
      <RadioGroup
        row
        value={formData.Nationality}
        sx={{ marginLeft: "20px" }}
        onChange={(event) => {
          setFormData({
            ...formData,
            Nationality: event.target.value === "true" ? true : false,
          });
        }}
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel
          value={false}
          control={
            <Radio
              sx={{
                color: "#E1E1E1",

                "&.Mui-checked": {
                  color: "#33D67C",
                },
              }}
            />
          }
          label={
            <Typography
              variant="body2"
              sx={{
                fontFamily: "DM Sans !important",
                fontSize: "14px !important",
                fontWeight: 400,
                lineHeight: "18px !important",
              }}
            >
              Indian
            </Typography>
          }
          sx={{
            ".css-1yq3e2k-MuiButtonBase-root-MuiRadio-root.Mui-checked ": {
              // color: "#33D67C",
            },
            ".css-ahj2mt-MuiTypography-root ": {
              fontSize: "14px !important",
              fontFamily: "DM Sans",
            },
          }}
        />
        <FormControlLabel
          value={true}
          control={
            <Radio
              sx={{
                color: "#E1E1E1",
                "&.Mui-checked": {
                  color: "#33D67C",
                },
              }}
            />
          }
          label={
            <Typography
              variant="body2"
              sx={{
                fontFamily: "DM Sans !important",
                fontSize: "14px !important",
                fontWeight: 400,
                lineHeight: "18px !important",
              }}
            >
              Non-Indian/NRI
            </Typography>
          }
        />
      </RadioGroup>
    </div>
  );
};

export default BankDetailsWithNri;

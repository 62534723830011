import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
// import bankIcon from '../../assets/img/bankIcon.svg';
import CustomButton from "../common/customButton/index";
import "./view.css";
import BackupIcon from "@mui/icons-material/Backup";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

const Earning = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#1E1E1E",
        padding: { xs: "24px 16px", sm: "24px 32px" },
        borderRadius: "16px",
        position: "relative",
      }}
    >
      <Grid container spacing={0.2}>
        <Grid item xs={11} md={11.6} lg={11.5}>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "dm sans",
              fontWeight: "700",
              fontSize: { xs: "16px", sm: "24px" },
              lineHeight: { xs: "21px", sm: "31px" },
              color: "#ffffff",
              fontStyle: "normal",
              marginBottom: "24px",
            }}
          >
            DeliverMyTune Assured Program
          </Typography>
        </Grid>
        <Grid item xs={1} md={0.4} lg={0.5}>
          {/* <Box sx={{backgroundColor: "#a7a5a4", borderRadius: "7.95px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "right", marginRight: "0px !important", position: "absolute", top: "24px", right: "32px"}}>
        <CloseIcon/>
</Box> */}
          {/* <Box>
    <CloseIcon/>
</Box> */}
        </Grid>
      </Grid>
      <Typography
        variant="body4"
        sx={{
          fontFamily: "dm sans",
          fontWeight: "400",
          fontSize: { xs: "9px", sm: "16px" },
          lineHeight: { xs: "12px", sm: "18px" },
          color: "#E1E1E1",
          fontStyle: "normal",
          marginBottom: "16px !important",
        }}
      >
        The appearance of your sales report is governed by a few key factors
      </Typography>
      <br />
      <Typography
        variant="body4"
        sx={{
          fontFamily: "dm sans",
          fontWeight: "400",
          fontSize: { xs: "9px", sm: "14px" },
          // lineHeight: { xs: "12px", sm: "18px" },
          color: "#E1E1E1",
          fontStyle: "normal",
          marginBottom: "16px !important",
        }}
      >
        1. All royalty payments over & above 3000 will get automatically
        transferred to your bank account on 20th of every month. Subject to
        correct bank details filled by you.
      </Typography>
      <br />
      <Typography
        variant="body4"
        sx={{
          fontFamily: "dm sans",
          fontWeight: "400",
          fontSize: { xs: "9px", sm: "14px" },
          // lineHeight: { xs: "12px", sm: "18px" },
          color: "#E1E1E1",
          fontStyle: "normal",
          marginBottom: "16px !important",
        }}
      >
        2. Royalty reports are uploaded in a span of 3 months. The January month
        will be made available on April 25th and so on.
      </Typography>
      <br />
      <Typography
        variant="body4"
        sx={{
          fontFamily: "dm sans",
          fontWeight: "400",
          fontSize: { xs: "9px", sm: "14px" },
          // lineHeight: { xs: "12px", sm: "18px" },
          color: "#E1E1E1",
          fontStyle: "normal",
          marginBottom: "16px !important",
        }}
      >
        3. DeliverMyTune guarantees a minimum royalty income of INR 50 every
        month to each artist. Checkout our FAQ for more details.
      </Typography>
      {/* <Typography
        variant="body4"
        sx={{
          fontFamily: "dm sans",
          fontWeight: "400",
          fontSize: { xs: "9px", sm: "14px" },
          lineHeight: { xs: "12px", sm: "18px" },
          color: "#E1E1E1",
          fontStyle: "normal",
          marginBottom: "16px !important",
        }}
      >
        {" "}
        This is where you can find your monthly earning with Deliver My Tune are
        published a month and 25 days after the close of the month,i.e you will
        get the statistics from January on March 25th.
      </Typography> */}
      <br />
      {/* <Typography
        variant="body4"
        sx={{
          fontFamily: "dm sans",
          fontWeight: "400",
          fontSize: { xs: "9px", sm: "14px" },
          lineHeight: { xs: "12px", sm: "18px" },
          color: "#E1E1E1",
          fontStyle: "normal",
          marginBottom: "16px !important",
        }}
      >
        View your earning and see exactly how your music are performing across
        all stores and services.
      </Typography> */}
      <br />
      {/* <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: " flex", justifyContent: "end" }}>
          <Link className="bankbtn" to="/artist/bank">
            <CustomButton
              startIcon={<BackupIcon sx={{ mr: "13px" }} />}
              btnText={" Add bank details"}
              // bankIcon={<img src={bankIcon} alt="bank icon" />}
              //  src={bankIcon} alt="bank icon"
              buttonStyle="artistbtn"
            />
          </Link>
        </Grid>
      </Grid> */}
    </Box>
  );
};

export default Earning;

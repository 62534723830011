import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { useState } from "react";
import { Box, Divider, Grid, Typography, Checkbox } from "@mui/material";
import MusicIcon from "../../assets/img/musicIcon.svg";
import "./takedown.css";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import CustomButton from "../common/customButton/index";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";

import {
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import * as Yup from "yup";
import { Form, Formik, useFormik } from "formik";
const Index = ({ career }) => {
  const params = useParams();
  const songId = params?.id;
  const navigate = useNavigate();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [songName, setSongName] = useState();
  const [dialogue, setDialogue] = useState(false);
  const [btnEnable, setBtnAnable] = useState(false);
  const toggleSidebar = () => {
    setOpenSidebar((p) => !p);
  };
  const initialValues = {
    songName: "",
    ISRC: "",
    UPC: "",
    artistName: "",
    labelName: "",
    check: false,
  };
  const validationSchema = Yup.object({
    songName: Yup.string().oneOf([career?.name],"Mismatched Song Name").required("Please Enter Song Name"),
    ISRC: Yup.string().required("Please Enter ISRC"),
    UPC: Yup.string().required("Please Enter UPC"),
    artistName: Yup.string().required("Please Enter Artist Name"),
    labelName: Yup.string().required("Please Enter Label Name"),
    check: Yup.boolean().oneOf([true], "Please check the box to proceed"),
  });
  const onSubmit = async (values) => {
    setDialogue(true);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const checkoutHandler = async () => {
    const loginToken = JSON.parse(localStorage.getItem("login"));
    const token = loginToken?.token;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const payload = formik.values;
    setDialogue(true);
    const res = await axios.post(
      `${process.env.REACT_APP_DMT_API_PATH}/orders/${songId}/takedown-payment`,
      payload,
      config
    );
    if (!res?.data?.data?.error) {
      setDialogue(false);
      toast.error("Take Down song successfully !!");
      navigate("/artist/dashboard");
    }
    // const orderData = res.data.data;

    // const options = {
    //   key: "rzp_live_UOZD55VDM3YeQI",
    //   amount: orderData?.amount,
    //   currency: "INR",
    //   name: "DELIVER MY TUNE",
    //   description: "RazorPay",
    //   // image: "https://avatars.githubusercontent.com/u/25058652?v=4",
    //   order_id: orderData.order_id,
    //   handler: async function (response) {
    //     const data = {
    //       //  orderCreationId: datavalue,
    //       razorpay_payment_id: response.razorpay_payment_id,
    //       razorpay_order_id: response.razorpay_order_id,
    //       razorpay_signature: response.razorpay_signature,
    //     };
    //     const result = await axios.post(
    //       `${process.env.REACT_APP_DMT_API_PATH}/public/payments/verify`,
    //       data
    //     );
    //     if (result.data.error !== true) {
    //       navigate("/artist/dashboard");
    //     }
    //   },
    //   notes: {
    //     address: "Razorpay Corporate Office",
    //   },
    //   theme: {
    //     color: "#121212",
    //   },
    // };
    // const razor = new window.Razorpay(options);
    // razor.open();
  };

  const onChangeSong = (e) => {
    setSongName(e.target.value);
    if (e.target.value === career?.name) {
      setBtnAnable(true);
    } else {
      setBtnAnable(false);
    }
  };

  return (
    <>
      <div className="container">
        <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
        <div className="main-container">
          <Navbar toggleSidebar={toggleSidebar} />
          <div className="pages">
            <Box className="custom-dmt-container">
              <Typography className="take_sale">Take Down from Sale</Typography>

              <Box sx={{ my: "0px", background: " " }}>
                <Box
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "auto",
                    padding: "30px",
                    borderRadius: "8px",
                    mt: "0px",
                  }}
                >
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={5} lg={4}>
                      <Grid>
                        <Typography className="cover_title">
                          Cover Art
                        </Typography>

                        <img
                          src={career?.cover}
                          width="100%"
                          height="100%"
                          alt=""
                        />
                      </Grid>
                      <Grid mt="20px">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            pb: 1,
                          }}
                        >
                          <Box className="summary_card_subTitle">Cost:</Box>
                          <Box className="summary_card_subTitle">
                            &#x20b9; Free
                          </Box>
                        </Box>
                        <Divider />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            pt: 1,
                          }}
                        >
                          <Box className="summary_card_title">Total Cost:</Box>
                          <Box className="summary_card_title">
                            &#x20b9; Free
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={7} lg={8}>
                      {/* <Typography className='cover_title'>Cover Art</Typography> */}
                      <Grid container mb={2} mt={2}>
                        <Grid item xs={6} spacing={5} sm={5}>
                          <Typography className="summary_card_title">
                            Name
                          </Typography>
                        </Grid>
                        <Grid item xs={6} spacing={5} sm={7}>
                          <Typography className="summary_card_subTitle">
                            {career?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider className="divider_card" />

                      <Grid container mb={2} mt={2}>
                        <Grid item xs={6} spacing={5} sm={5}>
                          <Typography className="summary_card_title">
                            Copyright
                          </Typography>
                        </Grid>
                        <Grid item xs={6} spacing={5} sm={7}>
                          <Typography className="summary_card_subTitle">
                            {career?.copyright?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider className="divider_card" />

                      <Grid container mb={2} mt={2}>
                        <Grid item xs={6} spacing={5} sm={5}>
                          <Typography className="summary_card_title">
                            Production Right
                          </Typography>
                        </Grid>
                        <Grid item xs={6} spacing={5} sm={7}>
                          <Typography className="summary_card_subTitle">
                            {career?.productionRight?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider className="divider_card" />

                      <Grid container mb={2} mt={2}>
                        <Grid item xs={6} spacing={5} sm={5}>
                          <Typography className="summary_card_title">
                            Genre
                          </Typography>
                        </Grid>
                        <Grid item xs={6} spacing={5} sm={7}>
                          <Typography className="summary_card_subTitle">
                            {career?.genre}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider className="divider_card" />

                      <Grid container mb={2} mt={2}>
                        <Grid item xs={6} spacing={5} sm={5}>
                          <Typography className="summary_card_title">
                            Record Label
                          </Typography>
                        </Grid>
                        <Grid item xs={6} spacing={5} sm={7}>
                          <Typography className="summary_card_subTitle">
                            {career?.recordLabel}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider className="divider_card" />
                      <Grid container mb={2} mt={2}>
                        <Grid item xs={6} spacing={5} sm={5}>
                          <Typography className="summary_card_title">
                            Main Artist
                          </Typography>
                        </Grid>
                        <Grid item xs={6} spacing={5} sm={7}>
                          <Typography className="summary_card_subTitle">
                            {career?.mainArtist}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider className="divider_card" />

                      <Grid container mb={2} mt={2}>
                        <Grid item xs={6} spacing={5} sm={5}>
                          <Typography className="summary_card_title">
                            Digital Release Date
                          </Typography>
                        </Grid>
                        <Grid item xs={6} spacing={5} sm={7}>
                          <Typography className="summary_card_subTitle">
                            {moment(career?.releaseDate).format("DD-MM-YYYY")}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider className="divider_card" />
                      <Formik>
                        <Form onSubmit={formik.handleSubmit}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="body1"
                                sx={{
                                  fontFamily: "DM Sans",
                                  fontWeight: "700",
                                  mb: "8px",
                                }}
                              >
                                Song Name
                              </Typography>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                value={formik.values.songName}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "songName",
                                    e.target.value
                                  )
                                }
                                onBlur={formik.handleBlur}
                                variant="standard"
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    background: "rgba(255, 255, 255, 0.05)",
                                    height: "44px",
                                    borderRadius: "5px",
                                    fontFamily: "DM Sans",
                                  },
                                }}
                              />
                              {formik.touched.songName &&
                              formik.errors.songName ? (
                                <Typography color="error">
                                  {" "}
                                  {formik.errors.songName}{" "}
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="body1"
                                sx={{
                                  fontFamily: "DM Sans",
                                  fontWeight: "700",
                                  mb: "8px",
                                }}
                              >
                                IRSC
                              </Typography>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                value={formik.values.ISRC}
                                onChange={(e) =>
                                  formik.setFieldValue("ISRC", e.target.value)
                                }
                                onBlur={formik.handleBlur}
                                variant="standard"
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    background: "rgba(255, 255, 255, 0.05)",
                                    height: "44px",
                                    borderRadius: "5px",
                                    fontFamily: "DM Sans",
                                  },
                                }}
                              />
                              {formik.touched.ISRC && formik.errors.ISRC ? (
                                <Typography color="error">
                                  {" "}
                                  {formik.errors.ISRC}{" "}
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="body1"
                                sx={{
                                  fontFamily: "DM Sans",
                                  fontWeight: "700",
                                  mb: "8px",
                                }}
                              >
                                UPC
                              </Typography>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                value={formik.values.UPC}
                                onChange={(e) =>
                                  formik.setFieldValue("UPC", e.target.value)
                                }
                                onBlur={formik.handleBlur}
                                variant="standard"
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    background: "rgba(255, 255, 255, 0.05)",
                                    height: "44px",
                                    borderRadius: "5px",
                                    fontFamily: "DM Sans",
                                  },
                                }}
                              />
                              {formik.touched.UPC && formik.errors.UPC ? (
                                <Typography color="error">
                                  {" "}
                                  {formik.errors.UPC}{" "}
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="body1"
                                sx={{
                                  fontFamily: "DM Sans",
                                  fontWeight: "700",
                                  mb: "8px",
                                }}
                              >
                                Artist Name
                              </Typography>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                value={formik.values.artistName}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "artistName",
                                    e.target.value
                                  )
                                }
                                onBlur={formik.handleBlur}
                                variant="standard"
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    background: "rgba(255, 255, 255, 0.05)",
                                    height: "44px",
                                    borderRadius: "5px",
                                    fontFamily: "DM Sans",
                                  },
                                }}
                              />
                              {formik.touched.artistName &&
                              formik.errors.artistName ? (
                                <Typography color="error">
                                  {" "}
                                  {formik.errors.artistName}{" "}
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="body1"
                                sx={{
                                  fontFamily: "DM Sans",
                                  fontWeight: "700",
                                  mb: "8px",
                                }}
                              >
                                Label Name
                              </Typography>
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                value={formik.values.labelName}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "labelName",
                                    e.target.value
                                  )
                                }
                                onBlur={formik.handleBlur}
                                variant="standard"
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    background: "rgba(255, 255, 255, 0.05)",
                                    height: "44px",
                                    borderRadius: "5px",
                                    fontFamily: "DM Sans",
                                  },
                                }}
                              />
                              {formik.touched.labelName &&
                              formik.errors.labelName ? (
                                <Typography color="error">
                                  {" "}
                                  {formik.errors.labelName}{" "}
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid container alignItems="center">
                              <Grid
                                item
                                xs={12}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Checkbox
                                  value={formik.values.check}
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      "check",
                                      e.target.checked
                                    )
                                  }
                                  onBlur={formik.handleBlur}
                                  // onChange={handleChangedata}
                                  // checked={isInstagramprofile}
                                  inputProps={{ "aria-label": "controlled" }}
                                  sx={{
                                    width: "20px",
                                    marginRight: "12px",
                                    color: "#ffffff",
                                    "&.Mui-checked": {
                                      color: "#fa9e0d",
                                    },
                                  }}
                                />
                                <Typography
                                  variant="subtitle2"
                                  className="all"
                                  sx={{
                                    fontSize: {
                                      xs: "16px !important",
                                      sm: "16 !important",
                                    },
                                    lineHeight: {
                                      xs: "23px !important",
                                      sm: "31px !important",
                                    },
                                  }}
                                >
                                  I agree that song will be taken down from all
                                  platforms
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                {formik.touched.check && formik.errors.check ? (
                                  <Typography color="error" varient="caption">
                                    {formik.errors.check}
                                  </Typography>
                                ) : null}
                              </Grid>
                            </Grid>

                            <Grid item xs={12}>
                              <Grid container justifyContent="center">
                                <Grid item>
                                  <CustomButton
                                    btnText="Submit"
                                    // disabled={!btnEnable}
                                    handleClick={() => formik.handleSubmit()}
                                    // handleClick={formik.handleSubmit(onSubmit)}
                                    buttonStyle="paybtn"
                                    marginTop={"30px"}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Form>
                      </Formik>
                    </Grid>
                  </Grid>

                  <Link
                    to="/artist/dashboard"
                    className="dashboard-btn"
                    style={{ textDecoration: "none !important" }}
                  >
                    <CustomButton
                      handleClick={() => window.scrollTo(0, 0)}
                      btnText="Go to Dashboard"
                      startIcon={<ArrowBackIcon sx={{ paddingRight: "3px" }} />}
                      buttonStyle="dashbtn"
                    />
                  </Link>
                </Box>
              </Box>
            </Box>
          </div>
        </div>
      </div>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={dialogue}
        onClose={() => {
          setDialogue(false);
        }}
      >
        <DialogTitle sx={{ pb: 2 }}>Take Down</DialogTitle>

        <DialogContent sx={{ typography: "body2" }}>
          Please be aware that cancelling your music distribution service with
          us is an irreversible action. Once you decide to cancel, your music
          will be permanently removed from all platforms and cannot be
          reinstated under the same distribution agreement. We advise you to
          consider this decision carefully. If you have any questions or need
          further assistance, please contact our support team before proceeding.
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              checkoutHandler();
            }}
          >
            Ok
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => {
              setDialogue(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default Index;

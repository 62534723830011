import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 70,
  height: 70,
  padding: 4,
  marginBottom: 2,
  boxSizing: "border-box",
  borderRadius: "10px",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "70px",
  height: "70px",
};

const Index = ({
  formData,
  setFormData,
  setFiledata,
  career,
  error,
  filedata,
  firstslidedata,
  saveImage,
  setSaveimage,
}) => {
  const [files, setFiles] = useState(
    career?.cover || firstslidedata?.cover || ""
  );
  useEffect(() => {
    if (saveImage) {
      var _URL = window.URL || window.webkitURL;
      var image = new Image();
      image.src = _URL.createObjectURL(saveImage);
      image.onload = function () {
        console.log(image.naturalWidth);
        console.log(image.naturalHeight);
        if (image.naturalWidth === 3000 && image.naturalHeight === 3000) {
          setFiles(_URL.createObjectURL(saveImage));
          setFiledata(saveImage);
          setSaveimage()
        } else {
          toast.error("Please Upload 3000X3000 Dimension Image!");
        }
      };
      // setFiles(saveImage);
      // setFiledata(saveImage)
    }
  }, [saveImage]);

  const ref = useRef();
  const navigate = useNavigate();
  const handlesplash = () => {
    navigate("/splashimage");
  };
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },

    onDrop: (acceptedFiles) => {
      var _URL = window.URL || window.webkitURL;
      var image = new Image();
      console.log(acceptedFiles[0]);
      image.src = _URL.createObjectURL(acceptedFiles[0]);
      image.onload = function () {
        if (image.naturalWidth === 3000 && image.naturalHeight === 3000) {
          setFiles(_URL.createObjectURL(acceptedFiles[0]));
          setFiledata(acceptedFiles[0]);
        } else {
          toast.error("Please Upload 3000X3000 Dimension Image!");
        }
      };

      setFormData({ ...formData, artwork: files });
    },
  });

  // console.log(firstslidedata);
  const thumbs = files && (
    <div style={thumb}>
      <div style={thumbInner}>
        <img
          ref={ref}
          src={files}
          style={img}
          onLoad={() => {
            URL.revokeObjectURL(files);
          }}
        />
      </div>
    </div>
  );
  return (
    <>
      <div>
        <div {...getRootProps({ className: "basic-song-drop" })}>
          <input {...getInputProps()} />

          <Box sx={{ width: "70px", height: "70px", mb: "20px" }}>
            <aside style={thumbsContainer}>{thumbs}</aside>
          </Box>
          <Button
            type="button"
            // onClick={open}
            className="upload-song"
            sx={{ mt: "20px" }}
          >
            Upload Artwork
          </Button>
          <Typography className="drop-song">
            Drop your Artwork here, or{" "}
            <Typography variant="span" sx={{ color: "#FB900B" }}>
              browse
            </Typography>
          </Typography>
          <Typography className="support">
            Supports: JPEG or PNG image file It must be a square image and 3000
            X 3000 px.{" "}
          </Typography>
          <Typography className="support2">
            Cover art with contact details or misleading song name will be
            rejected{" "}
          </Typography>
        </div>

        {/* <div>
          <Button
            type="button"
            onClick={handlesplash}
            className="upload-song"
            sx={{ mt: "20px" }}
          >
            Click me
          </Button>
        </div> */}
      </div>
    </>
  );
};

export default Index;

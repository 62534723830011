import React, { useEffect } from 'react'
import { useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import WalletCard from '../components/walletCard/index';
import Box from "@mui/material/Box";
import axios from 'axios';

const Wallet = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const toggleSidebar = () => {
    setOpenSidebar((p) => !p);
  };

  const [data, setData] = useState([]);
  const loginToken = JSON.parse(localStorage.getItem("login"));
  // const token = loginToken?.token;
  const userid = loginToken?.user._id;

  async function getCredit() {
    await axios
      .get(`${process.env.REACT_APP_DMT_API_PATH}/wallet/?user_id=${userid}`)
      .then((res) => {
        setData(res.data.data.total[0].total);
      
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getCredit();
  }, []);
  
  return (
    <>
      <div className="container">
        <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
        <div className="main-container">
          <Navbar toggleSidebar={toggleSidebar}  />
          <div className="pages">
            <Box className='custom-dmt-container'>
              <WalletCard data={data} />
            </Box>
          </div>
        </div>
      </div>
    </>
  )
}

export default Wallet
import React from "react";
import Button from "@mui/material/Button";

const Index = ({
  handleClick,
  btnText,
  btntype,
  buttonStyle,
  width,
  height,
  borderRadius,
  fontSize,
  fontWeight,
  border,
  marginTop,
  background,
  color,
  startIcon,
  endIcon,
  disabled,
  padding,
}) => {
  return (
    <>
      <Button
        onClick={handleClick}
        variant="outlined"
        disabled={disabled}
        sx={{
          background: "#00D8C0 !important",
          color: "white",
          width: { width },
          height: { height },
          borderRadius: "100px",
          fontSize: "11px",
          fontWeight: 500,
          border: { border },
          textTransform: "capitalize",
          textAlign: "center",
          marginTop: { marginTop },
          padding: { padding },
        }}
        type={btntype}
        className={`${buttonStyle || ""}`}
      >
        {startIcon}
        {btnText}
        {endIcon}
      </Button>
    </>
  );
};

export default Index;

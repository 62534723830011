import { Container } from "@mui/system";
import React from "react";
import Box from "@mui/material/Box";
import "./style.css";
import { Grid, Typography } from "@mui/material";
import Uploadimage from "../../assets/img/static/sell-section.jpg";
import Mobile from "../../assets/img/static/sell-section1.webp";
import Music from "../../assets/img/static/sell-section2.PNG";
import Arrow from '../../assets/img/static/Vector 332 (1).png'

const Index = () => {
  return (
    <Box className="sell-number-banner" paddingTop="40px" >
        <Box>
            <Typography variant="h4" className="sellmusichead">How To Sell Music Online</Typography>
        </Box>
      <Container>
        <Grid container columnSpacing={{xs:0,sm:2,md:2,lg:1}} columns={{xs:12,sm:12,md:12,lg:14}}  mt={2} sx={{paddingBottom:'60px',placeContent:'center',margin:'auto',pl:{lg:'40px'},pr:{lg:'48px'}}}>
          <Grid item xs={12} sm={6} md={4}  className="block">
            <Box className="Number-item-label">
              <img src={Uploadimage} width="344.5px" height="230px" className="uploadimagesell" />
              <Typography variant="p" className="Number-item-music">
              Upload Your Music On Our Platform
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={1}  sx={{display:{lg:'block',xs:'none'}}} >
          <img src={Arrow} width="100%" height="auto" style={{marginTop:"100px" }}/>
            </Grid>
          <Grid item xs={12} sm={6} md={4} >
            <Box className="Number-item-label">
              <img src={Mobile}width="344.5px" height="230px" className="uploadimagesell"  />
              <Typography variant="p" className="Number-item-music">
              We Send Your Music to digital Stores
              </Typography>
            </Box>
          </Grid>
          <Grid item md={1}  sx={{display:{lg:'block',xs:'none'}}}>
          <img src={Arrow} width="100%" height="auto" style={{marginTop:"100px" }}/>
            </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box className="Number-item-label">
              <img src={Music} width="344.5px" height="230px" className="uploadimagesell" />
              <Typography variant="p" className="Number-item-music">
              You get paid <br/> (Monthly Royalty Received)
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Index;

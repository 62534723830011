import React from "react";
import "../pricingCard/Pricing.css";
import {
    Stack, Box, Typography, Card, CardContent,
    CardActions, Button, CardMedia, Grid, Link
} from '@mui/material'
import JioSavan from '../pricingCard/Images/JioSaavn_Logo.svg.png'
import Wynk from '../pricingCard/Images/Wynk.png'
import Hungama from '../pricingCard/Images/Hungama_logo.png'
import Spotify from '../pricingCard/Images/Spotify.png'
import Apple from '../pricingCard/Images/Apple.svg.png'
import TikTok from '../pricingCard/Images/TikTok.svg.png'
import YT from '../pricingCard/Images/YT.png'
import YTM from '../pricingCard/Images/YTM.png'
import Soundcloud from '../pricingCard/Images/Soundcloud.png'
import Napster from '../pricingCard/Images/Napster.jpg'
import Shazam from '../pricingCard/Images/Shazam.svg.png'
import Pandora from '../pricingCard/Images/Pandora.png'
import AmazonMusic from '../pricingCard/Images/AmazonMusic.png'
import Googleplay from '../pricingCard/Images/Googleplay.png'
import Deezer from '../pricingCard/Images/Deezer.svg.png'
import Facebook from '../pricingCard/Images/Facebook.png'
import Mixcloud from '../pricingCard/Images/Mixcloud.svg.png'
import SimfyAfrica from '../pricingCard/Images/SimfyAfrica.png'
import Tidal from '../pricingCard/Images/Tidal.svg.png'
import UMA from '../pricingCard/Images/UMA.jpg'
import iHeartRadio from '../pricingCard/Images/iHeartRadio.png'
import YandexMusic from '../pricingCard/Images/YandexMusic.png'
import Spinlet from '../pricingCard/Images/Spinlet.png'
import Primephonic from '../pricingCard/Images/Primephonic.png'
import AlibabaX from '../pricingCard/Images/AlibabaX.png'
import KuackMediafrom from '../pricingCard/Images/KuackMedia.png'
import LineMusic from '../pricingCard/Images/LineMusic.png'
import KKBox from '../pricingCard/Images/KKBox.png'
import iMusica from '../pricingCard/Images/iMusica.png'
import Gracenote from '../pricingCard/Images/Gracenote.png'
import Boomplay from '../pricingCard/Images/Boomplay.png'
import AudibleMagic from '../pricingCard/Images/AudibleMagic.png'
import AWA from '../pricingCard/Images/AWA.png'
import Anghami from '../pricingCard/Images/Anghami.png'
import SevenDigital from '../pricingCard/Images/SevenDigital.png'
import NeuroticMedia from '../pricingCard/Images/NeuroticMedia.png'
import Nuuday from '../pricingCard/Images/Nuuday.webp'
import Resso from '../pricingCard/Images/Resso.jpg'
import Slackerfrom from '../pricingCard/Images/Slacker.png'
import Tencent from '../pricingCard/Images/Tencent.png'
import TargetMedia from '../pricingCard/Images/TargetMedia.png'
import BMAT from '../pricingCard/Images/BMAT.png'
import Fizy from '../pricingCard/Images/Fizy.png'
import HitsNL from '../pricingCard/Images/HitsNL.jpg'
import IAM from '../pricingCard/Images/IAM.png'
import JaxtaMusic from '../pricingCard/Images/JaxtaMusic.webp'
import Joox from '../pricingCard/Images/Joox.png'
import Qobuz from '../pricingCard/Images/Qobuz.png'
import SiriusXM from '../pricingCard/Images/SiriusXM.png'
import TimMusic from '../pricingCard/Images/TimMusic.jpg'
import TouchTunes from '../pricingCard/Images/TouchTunes.png'
import UltimateMusicChina from '../pricingCard/Images/UltimateMusicChina.png'
import MelonKakao from '../pricingCard/Images/MelonKakao.png'
import NaverMusic from '../pricingCard/Images/NaverMusic.png'
import NetEaseCloudMusic from '../pricingCard/Images/NetEaseCloudMusic.png'
function PriceCard() {
    return (

        <Stack maxWidth='1800px' margin='70px auto'>

            <Grid container spacing={3} sx={{px:'25px'}}>
                {
                    cardData.map((cardData) => <Grid item
                        xs={12} sm={6} md={4} lg={3}  margin='10px auto'
                        
                        key={cardData.id}>


                        <Card elevation={6} className='flip-card'

                            sx={{ backgroundColor: 'white', color: 'black' }}>
                            <Box className='flip-card-inner' height='420px'>

                                <Box className='flip-card-front' display='flex' flexDirection='column'
                                    alignItems='center' justifyContent='flex-start'>

                                    <Box mt={10}>
                                        <img src={cardData.img} alt='logo' height='30px' />
                                    </Box>

                                    <Box mt={10}>
                                        <Typography variant='h5' component='div' gutterBottom sx={{ fontWeight: 'bold' }}>
                                            {cardData.title1}
                                        </Typography>
                                    </Box>


                                </Box>

                                {/* ---------------------------------------------------------------------Seprater---------- */}


                                <Box className='flip-card-back' isplay='flex' flexDirection='column'
                                    alignItems='center' justifyContent='flex-start' padding='7px'>


                                    <Box mt={3}>
                                        <Typography variant='h5' component='div' gutterBottom sx={{ fontWeight: 'bold' }}>
                                            {cardData.title1}
                                        </Typography>
                                    </Box>

                                    <Box mt={5}>
                                        <Typography component='div' gutterBottom sx={{ fontSize: '15px' }}>
                                            {cardData.discription}
                                        </Typography>
                                    </Box>

                                    <Box mt={5}>
                                    <Link  href={cardData.link}
                                            sx={{ fontSize: '15px', color: 'violet', fontWeight: '400',textDecoration:'none' }}>
                                            {cardData.title2}
                                        </Link>
                                    </Box>

                                </Box>

                            </Box>
                        </Card>

                    </Grid>
                    )}
            </Grid>
        </Stack>
    )
}

export default PriceCard

const cardData = [
    {
        id: 1,
        img: JioSavan,
        title1: 'JioSavan',
        discription: 'Saavn was launched in 2007 and merged with JioMusic in 2018. The Indian streaming service has an epic catalog of 45 million Bollywood, English, Hindi, and Indian regional songs, top artists, expertly curated playlists, and custom radio stations for any mood or genre. JioSaavn has over 100 million monthly active users with an audience across 150 countries.',
        title2: 'JioSavan',
        link:'https://www.jiosaavn.com/'
    },
    // {
    //     id: 2,
    //     img: Wynk,
    //     title1: 'Wynk',
    //     discription: 'Wynk is an Indian music streaming service launched in 2014 by Bharti Airtel which is an Indian global telecommunications services company. The platform claims to be the first among entertainment apps in India with more than 3 million daily active users and 60 million daily streams.',
    //     title2: 'Wynk',
    //     link:' https://wynk.in/music'
    // },
    {
        id: 3,
        img: Hungama,
        title1: 'Hungama',
        discription: 'Hungama claims to be South Asia’s largest digital media entertainment company. It allows users to sample, select and download music tracks and videos, and stream movies and TV shows. Launched in 2010, the platform has over 20 million users, and caters to an audience that is over 1.5 billion. Its multi-genre library has more than 10 million songs and music videos which entertains listeners in 20+ Indian and international languages.',
        title2: 'Hungama',
        link:' https://www.hungama.com/'
    },
    {
        id: 4,
        img: Spotify,
        title1: 'Spotify',
        discription: 'Spotify is known as the largest music streaming service in the world with over 286 million users, including 130 million subscribers, across 79 markets. Spotify was founded in 2006 in Sweden, and is the largest driver of revenue to the music business today with over 50 million tracks.',
        title2: 'Spotify',
        link:'  https://open.spotify.com/'
    },
    {
        id: 5,
        img: Apple,
        title1: 'Apple Music/iTunes',
        discription: 'Apple Music/iTunes is known as the largest music streaming service in the world with over 286 million users, including 130 million subscribers, across 79 markets. Apple Music/iTunes was founded in 2006 in Sweden, and is the largest driver of revenue to the music business today with over 50 million tracks.',
        title2: 'Apple Music/iTunes',
        link:'  https://www.apple.com/in/apple-music/'
    },
    {
        id: 6,
        img: TikTok,
        title1: 'TikTok',
        discription: 'TikTok is a Chinese video-sharing social networking service which allows users to create 15 second videos, soundtracked by music clips. The app’s monthly active users are estimated to be a whopping 800 million. It is available in over 150 markets around the world, in 39 languages.',
        title2: 'TikTok',
        link:"https://www.tiktok.com/about"
    },
    {
        id: 7,
        img: YT,
        title1: 'YouTube',
        discription: 'YouTube is an online video-sharing platform that launched in 2005. The platform allows users to upload videos, view, rate, share, comment on videos, and subscribe to other users. It has over 2 billion users worldwide who consume nearly 250 million hours of content on the platform daily.',
        title2: 'YouTube',
        link:"https://www.youtube.com/"
    },
    {
        id: 8,
        img: YTM,
        title1: 'YouTube Music',
        discription: 'YouTube Music is a music streaming service launched by YouTube which allows users to browse through songs and music videos based on genres, playlists, and recommendations. It offers music videos, live shows, song covers and much more becoming the one stop shop for all things music.',
        title2: 'YouTube Music',
        link:"https://www.youtube.com/musicpremium"
    },
    {
        id: 9,
        img: Soundcloud,
        title1: 'SoundCloud',
        discription: 'SoundCloud is a music streaming service launched by YouTube which allows users to browse through songs and music videos based on genres, playlists, and recommendations. It offers music videos, live shows, song covers and much more becoming the one stop shop for all things music.',
        title2: 'SoundCloud',
        link:"https://soundcloud.com/discover"

    },
    {
        id: 10,
        img: Napster,
        title1: 'Napster/Rhapsody',
        discription: 'Rhapsody International is a pioneer in digital music and leading provider of music streaming technologies. It operates the Napster platform in 33 countries with a premium subscription service giving millions of consumers unlimited ad-free access to music on any device – online or offline. Napster has a massive catalog of over 60 million songs.',
        title2: 'Napster/Rhapsody',
        link:"https://www.napster.com/us"
    },
    {
        id: 11,
        img: Shazam,
        title1: 'Shazam',
        discription: "Shazam is a very popular mobile app owned by Apple Inc that recognises music and TV around you. It identifies the title and artist of a song when users put their phone near the audio source while the song is playing. A digital fingerprint of the audio is created and, within seconds, matched against Shazam's database of millions of tracks and TV shows. The app boasts of connecting more than 1 billion people.",
        title2: 'Shazam',
        link:"https://www.shazam.com/gb"
    },
    {
        id: 12,
        img: Pandora,
        title1: 'Pandora',
        discription: "TikTok is a Chinese video-sharing social networking service which allows users to create 15 second videos, soundtracked by music clips. The app’s monthly active users are estimated to be a whopping 800 million. It is available in over 150 markets around the world, in 39 languages.",
        title2: 'Pandora',
        link:"https://www.pandora.com/restricted"
    },
    {
        id: 13,
        img: AmazonMusic,
        title1: 'Amazon Music',
        discription: "Amazon Music is a music streaming platform and online music store. In 2008, it became the first music store to sell music without digital rights management (DRM). It has over 55 million listeners featuring more than 2 million songs.",
        title2: 'Amazon Music',
        link:"https://music.amazon.in/"
    },
    {
        id: 13,
        img: Googleplay,
        title1: 'Google Music',
        discription: "Google Music is a music streaming platform and online music store. In 2008, it became the first music store to sell music without digital rights management (DRM). It has over 55 million listeners featuring more than 2 million songs.",
        title2: 'Google Music',
        link:'https://play.google.com/store/apps/theme/promotion_gpm_shutdown_ctp?pli=1'
    },
    {
        id: 14,
        img: Deezer,
        title1: 'Deezer',
        discription: "TikTok is a Chinese video-sharing social networking service which allows users to create 15 second videos, soundtracked by music clips. The app’s monthly active users are estimated to be a whopping 800 million. It is available in over 150 markets around the world, in 39 languages.",
        title2: 'Deezer',
        link:"https://www.deezer.com/explore/features/"
    },
    {
        id: 15,
        img: Facebook,
        title1: 'Facebook Audio Library',
        discription: "The Facebook Sound Collection is an audio library of sound effects and royalty free music that you can download for your videos. You'll find tracks from established composers and songwriters and a range of sound effects. These tracks are owned by Facebook, and are free and clear to use in any videos you create and share on Facebook and Instagram.",
        title2: 'Facebook Audio Library',
        link:"https://www.facebook.com/sound"
    },
    {
        id: 16,
        img: Facebook,
        title1: 'Facebook Fingerprinting',
        discription: "Facebook, unveiled in 2014, its audio fingerprinting technology. It automatically shows the songs a user is listening to or shows they’re watching so that they can be tagged in a status update. The app listens to surroundings and makes a digital audio fingerprint of the music and sounds around. This audio fingerprint is compared to a database of registered songs, and if it finds a match in the audio fingerprint database, it gives you the option to share the song.",
        title2: 'Facebook Fingerprinting',
        link:"https://about.fb.com/news/2019/10/introducing-fingerprint-lock-for-android/"
    },
    {
        id: 17,
        img: Mixcloud,
        title1: 'Mixcloud',
        discription: "Mixcloud is a British online music streaming service which boasts of 1 million+ creators and over 20 million listeners. It allows its users to listen and distribute radio shows, DJ mixes and podcasts. It was founded as a startup company in 2008 and has several notable users including Wired, Harvard Business School, TED Talks, and Barack Obama.",
        title2: 'Mixcloud',
        link:"https://www.mixcloud.com/"
    },
    {
        id: 18,
        img: SimfyAfrica,
        title1: 'Simfy Africa',
        discription: "Simfy Africa gives you access to a music library of more than 27 million songs. Users can listen to music, create playlists, and share them via social networks. Launched in 2012, Simfy has rapidly grown to become one of the leading music streaming providers in Africa.",
        title2: 'Simfy Africa',
        link:"https://simfy.africa/za/Start/Index_Optout"
    },
    {
        id: 19,
        img: Tidal,
        title1: 'Tidal',
        discription: "TIDAL is the first global music streaming service with high fidelity sound, hi-def video quality, along with expertly curated playlists and original content. It is available in 53 countries, and we have over 60 million songs and over 250,000 high-quality videos in our catalog. Tidal was launched in 2014 by Norwegian public company Aspiro, currently owned by Jay-Z. It claims to pay the highest percentage of royalties to music artists and songwriters within the music streaming market.",
        title2: 'Tidal',
        link:"https://tidal.com/"
    },
    {
        id: 20,
        img: UMA,
        title1: 'United Media Agency',
        discription: "United Media Agency provides a music service to customers directly and on a B2B basis with the Mail.ru group. Mail.ru owns and operates Mail.ru, one of largest email providers in Russia / the CIS (Commonwealth of Independent States) and the three largest social media networks in Russia/the CIS; VKontakte (VK), Odnoklassniki (OK) and Moi Mir (MM) or ‘My World’ which have a potential to reach more than 150 million users.",
        title2: 'United Media Agency',
        link:"https://www.um-agency.com/#splash"
    },
    {
        id: 21,
        img: iHeartRadio,
        title1: 'iHeartRadio',
        discription: "iHeartRadio is a free broadcast, podcast and streaming radio platform which was founded in 2008. It reported 128 million registered users within its service and app in 2019 and offers over 1500 live radio stations that makes it one of the top streamers in the world.",
        title2: 'iHeartRadio',
        link:"https://www.iheart.com/podcast/"
    },
    {
        id: 22,
        img: Mixcloud,
        title1: 'Mixcloud',
        discription: "Mixcloud is a British online music streaming service which boasts of 1 million+ creators and over 20 million listeners. It allows its users to listen and distribute radio shows, DJ mixes and podcasts. It was founded as a startup company in 2008 and has several notable users including Wired, Harvard Business School, TED Talks, and Barack Obama.",
        title2: 'Mixcloud',
        link:"https://www.mixcloud.com/"
    },
    {
        id: 23,
        img: YandexMusic,
        title1: 'Yandex Music',
        discription: "Yandex.Music is a music streaming service in which users can select musical compositions, albums, collections of musical tracks to stream to their device on demand and receive personalized recommendations. With 3.5 million licensed recordings, Yandex.Music offers a wide range of genres and styles. It is available in Russia, and a few Asian and European countries.",
        title2: 'Yandex Music',
        link:"https://music.yandex.com/home"
    },
    {
        id: 24,
        img: Spinlet,
        title1: 'Spinlet',
        discription: "Spinlet gives you access to the largest catalog of Africa-centric music in the world. Spinlet's technology encourages the purchasing and discovery of new music while offering seamless integration and storage of the users'​ music library on their mobile device.",
        title2: 'Spinlet',
        link:"https://spinlet.com/"
    },
    {
        id: 25,
        img: Primephonic,
        title1: 'Primephonic',
        discription: "Primephonic is a Dutch-American streaming service designed for classical music. Users can stream and download nearly all classical music that has ever been recorded in CD-quality from a catalog of over a million recorded songs.",
        title2: 'Primephonic',
        link:"https://www.primephonic.com/"
    },
    {
        id: 26,
        img: AlibabaX,
        title1: 'Alibaba (Xiami)',
        discription: "Xiami Music Network (owned by Alibaba group) is a Chinese online music website that provides recommendations and MP3 and P2P download services, offline music activities, and other interactive content. It has a large music library with more than 3 million tracks, 330 kinds of music styles, tens of thousands of music radios, over 500 thousand hits packages created entirely by users and self-developed algorithms, which can recommend good music to users.",
        title2: 'Alibaba (Xiami)',
        link:"https://www.facebook.com/alibabamusic.tn/"
    },
    {
        id: 27,
        img: KuackMediafrom,
        title1: 'Kuack Media',
        discription: "Kuack Media Group is the market leader in white label music streaming services for mobile telecommunication companies in Latin America and the Caribbean. They work with 8 mobile operators in 48 countries and over 200 million mobile subscribers have access to their services. The company has distributed over 25 million songs.",
        title2: 'Kuack Media',
        link:"https://www.kuackmedia.com/home"
    },
    {
        id: 28,
        img: LineMusic,
        title1: 'Line Music',
        discription: "Line Music is a subscription-based music streaming service by Line Corporation that combines the existing Line Messenger app with the entertainment system. Users can stream the music on-demand and share the music directly to Line messenger. You can listen to more that 62 million songs on this platform.",
        title2: 'Line Music',
        link:"https://music.line.me/about/"
    },
    {
        id: 29,
        img: KKBox,
        title1: 'KKBox',
        discription: "Founded by a group of Taiwanese software programmers in 2005, KKBox provides music streaming services with original cloud technology. It has more than 50 million tracks, serving regions including Taiwan, Hong Kong, Japan, Singapore and Malaysia with over 10 million users.",
        title2: 'KKBox',
        link:"https://www.kkbox.com/about/en"
    },
    {
        id: 30,
        img: iMusica,
        title1: 'iMusica',
        discription: "Operating since 2000, iMusica is a digital content provider for the main mobile operators and music services in Brazil and worldwide. It develops download & streaming platforms and creates music branding solutions for major brands. It has a catalog of 18 million tracks and licensing agreements with more than 20,000 brazilian and international labels such as Sony Music, Universal Music, Warner Music.",
        title2: 'iMusica',
        link:"https://in.bookmyshow.com/baraut/cinemas/imusica-entertainment-baraut/IAET"
    },
    {
        id: 31,
        img: Gracenote,
        title1: 'Gracenote',
        discription: "Gracenote provides music, video and sports metadata and automatic content recognition technologies to entertainment services and companies, worldwide. It is known for its MusicID which enables music monitoring for rights holders and industry professionals. Deep descriptive data and algorithms from Gracenote help music services deliver engaging playlists, surfacing a wider range of artists and styles, to help fans find more of the music they love.",
        title2: 'Gracenote',
        link:"https://www.gracenote.com/"
    },
    {
        id: 32,
        img: Boomplay,
        title1: 'Boomplay',
        discription: "Boomplay is a music and video streaming & download service launched in 2015 with the vision to create the largest, most reliable digital music ecosystem for artists and content creators in Africa. It has over 68 million users, a catalog of 20 million songs and videos, and is currently the biggest and fastest growing music app in Africa.",
        title2: 'Boomplay',
        link:"https://www.boomplay.com/"
    },
    {
        id: 33,
        img: AudibleMagic,
        title1: 'Audible Magic',
        discription: "Audible magic is a digital content identification, licensing and monetization platform providing services to social networks, record labels, music publishers, and television and movie studios. Founded in 1999, the company has 37 granted patents related to its audio and video identification technology. Their services help companies identify and protect copyrighted content, manage rights and monetize media.",
        title2: 'Audible Magic',
        link:"https://www.audiblemagic.com/"
    },
    {
        id: 34,
        img: AWA,
        title1: 'AWA',
        discription: "AWA is a Japanese music streaming subscription service which was released in 2015 and has a user base of over 19 million people.. It is known for its unique music recommendation engine which automatically displays playlists according to your taste, mood, and scene. It has a catalog of about 65 million songs including Japanese, Western, K-Pop, major indie, and age.",
        title2: 'AWA',
        link:"https://awa.fm/"
    },
    {
        id: 35,
        img: Anghami,
        title1: 'Anghami',
        discription: "Anghami was launched in 2012 with the goal to reduce music piracy in the middle east. It was the first of its kind in the Middle East and North Africa (MENA) region. Besides leading Arabic labels, Anghami also features music from major international labels such as Universal, Sony, EMI, Warner. It is the number 1 music platform in the MENA region comprising over 30 million songs for more than 70 million users.",
        title2: 'Anghami',
        link:"https://www.anghami.com/"
    },
    {
        id: 36,
        img: SevenDigital,
        title1: '7 Digital',
        discription: "Founded in 2004 as a B2B music services company, 7 Digital built music download stores for brands and retailers. The direct to consumer store was launched next year, making it the world’s first store to offer digital rights management free downloads. 7digital music download stores are available globally and have major label catalogue in over 40 countries.",
        title2: '7 Digital',
        link:"https://uk.7digital.com/"

    },
    {
        id: 37,
        img: NeuroticMedia,
        title1: 'Neurotic Media',
        discription: "Neurotic Media helps brands and mobile companies to influence consumer behavior using popular songs and artists. The company's services enable businesses to create custom digital experiences across multiple devices and promote their brands using the universal power of music. They provide white label music solutions that bring brands, music, and listeners together.",
        title2: 'Neurotic Media',
        link:"https://www.neuroticmedia.com/"
    },
    {
        id: 38,
        img: Nuuday,
        title1: 'Nuuday',
        discription: "Nuuday provides digital services, cloud solutions and entertainment to all of Denmark. It caters to people's musical needs by working together with Danish telecommunications to power the entertainment on their platforms.",
        title2: 'Nuuday',
        link:"https://nuuday.com/"
    },
    {
        id: 39,
        img: Resso,
        title1: 'Resso',
        discription: "Resso is a music service from the makers of TikTok and offers an enhanced music discovery platform. The platform encourages users to share lyrics, comments and other user-generated content with each other.",
        title2: 'Resso',
        link:"https://www.resso.com/in"
    },
    {
        id: 40,
        img: Slackerfrom,
        title1: 'Slacker',
        discription: "Slacker radio acquired by LiveXLive is a music streaming platform, combining audio and (often live) video, available in the US and Canada. It gives listeners access to the world's best music and entertainment wherever they are. It has more than 420 expert-curated music stations.",
        title2: 'Slacker',
        link:"https://www.liveone.com/?utm_campaign=slacker-redirect"
    },
    {
        id: 41,
        img: Tencent,
        title1: 'Tencent',
        discription: "Tencent Music Entertainment Group is the leading online music entertainment platform in China, operating the country's highly popular and innovative music apps: QQ Music, Kugou Music, Kuwo Music and WeSing reaching more than 800 million highly diverse and engaged users. This is a joint venture between Tencent and Spotify. Their music content library is complemented by diverse user-generated content on our platform, including online karaoke songs, short videos, live streaming of music performances and much more.",
        title2: 'Tencent',
        link:"https://www.tencent.com/"
    },
    {
        id: 42,
        img: TargetMedia,
        title1: 'Target Media',
        discription: "Target Media Central was formed several years ago to provide in-store merchandising solutions for retailers and the in-store media industry. They obtain licenses required to play a particular music at a public location from performance royalty organizations such as Sound Exchange, ASCAP and BMI.",
        title2: 'Target Media',
        link:"https://targetmediacentral.com/in-store-media/licensing/"
    },
    {
        id: 43,
        img: BMAT,
        title1: 'BMAT',
        discription: "BMAT (Barcelona Music and Audio Technologies).was started in 2005 by a group of engineers with a mission to index all the music in the world. Their audio technology monitors music all around the world and provides the means to analyze singing performance, recommend music and ensure the protection of performing rights. Today their monitoring platform delivers 92 million identifications monthly and overviews 230 million digital transactions hourly.",
        title2: 'BMAT',
        link:"https://www.admissionstesting.org/for-test-takers/bmat/"
    },
    {
        id: 44,
        img: Fizy,
        title1: 'Fizy',
        discription: "Fizy is a Turkey-based music streaming and digital music platform which gives you access to over 35 million songs and video clips for free. The platform immediately reached millions after being founded in 2008 and won the award for ‘Best Music Discovery Service’ at the 2010 Mashable Awards. The site supports 26 languages and also offers live concert broadcasts, acoustic performances and unique special video contents of popular artists.",
        title2: 'Fizy',
        link:"https://fizy.com/"
    },
    {
        id: 45,
        img: HitsNL,
        title1: 'HitsNL',
        discription: "HitsNL is a subscription based platform dedicated completely to Dutch music. Launched in 2015, the platform allows users to listen to Dutch music, and curated playlists on the web as well as on Android and ios devices. It is 100% advertising and commercial-free, and gives users the access to websites and social media of their favourite artists.",
        title2: 'HitsNL',
        link:"https://www.hitsnl.nl/"
    },
    {
        id: 46,
        img: IAM,
        title1: 'IAM+',
        discription: "Founded by global music artist will.i.am, i.am+ is a Hollywood-based technology company whose mission is to create technology hits that create a ripple effect across pop culture. The company believes that through music and technology as the first step, they can lead into a powerful new era built on Artificial Intelligence.",
        title2: 'IAM+',
        link:"https://www.iamplus.services/"

    },
    {
        id: 47,
        img: JaxtaMusic,
        title1: 'Jaxta Music',
        discription: "Jaxsta is an Australian music company, founded in 2015. It uses technology to develop the world’s most comprehensive resource of official music credits. Jaxsta delivers official data sourced exclusively and directly from the custodians of that data, such as major and independent record labels, publishers, royalty agencies and industry associations across the globe. The company’s open beta database has over 100 million credits from 19 million recordings.",
        title2: 'Jaxta Music',
        link:"https://soundcloud.com/split-n-jaxta"
    },
    {
        id: 48,
        img: Joox,
        title1: 'Joox',
        discription: "oox is the biggest music streaming app in Asian markets like Hong Kong, Indonesia, Malaysia, Myanmar, Thailand and also the South African market. Launched in 2015, it has a music library of 30 million tracks and over 4 million users.",
        title2: 'Joox',
        link:"https://www.joox.com/intl"
    },
    {
        id: 49,
        img: Qobuz,
        title1: 'Qobuz',
        discription: "Qobuz is a French commercial music streaming and downloading service which was formed in 2008. It offers streaming and download subscriptions at CD and Hi-Res Audio quality. With more than 40 million tracks, it is also the worldwide leader in 24-Bit Hi-Res downloads.",
        title2: 'Qobuz',
        link:"https://www.qobuz.com/gb-en/discover"
    },
    {
        id: 50,
        img: SiriusXM,
        title1: 'Sirius XM',
        discription: "SiriusXM is an American broadcasting company that provides satellite radio and online radio services operating in the U.S. with over 175 channels. SiriusXM and Pandora (its subsidiary) together reach more than 100 million people each month with their audio products.",
        title2: 'Sirius XM',
        link:"https://www.siriusxm.com/"
    },
    {
        id: 51,
        img: TimMusic,
        title1: 'TimMusic',
        discription: "TIMMUSIC is the streaming music service that offers TIM and non-TIM customers a catalog full of Italian and international record news, exclusive previews, albums told by the artists, interviews and exclusive podcasts.",
        title2: 'TimMusic',
        link:"https://www.timmusic.it/landing"
    },
    {
        id: 52,
        img: TouchTunes,
        title1: 'TouchTunes/Play Network',
        discription: "TouchTunes is the largest in-venue interactive music and entertainment platform, featured in over 65,000 bars and restaurants across North America and Europe. Its jukeboxes and TV lets customers control their music experience.",
        title2: 'TouchTunes/Play Network',
        link:"https://www.playnetwork.com/services/touchtunes/"
    },
    {
        id: 53,
        img: UltimateMusicChina,
        title1: 'Ultimate Music China',
        discription: "Ultimate music comes under Tencent Music Entertainment and provides services to various smart devices and automobile manufacturers, enabling them to develop built-in music players. Focused on bringing music to everything, Ultimate Music aims to create a vibrant B2B music ecosystem.",
        title2: 'Ultimate Music China',
        link:"https://www.ultimatemusic.cn/"
    },
    {
        id: 54,
        img: MelonKakao,
        title1: 'Melon/Kakao',
        discription: "Melon is South Korea's largest music subscription service, with over 3.2 million customers. Short for ‘melody on’, it also provides professional music information and music contents. They even host their own awards show every year which has become a global K-Pop festival.",
        title2: 'Melon/Kakao',
        link:"https://www.kakaocorp.com/page/service/service/Melon"
    },
    {
        id: 55,
        img: NaverMusic,
        title1: 'Naver Music',
        discription: "NAVER Music is a digital music service operated by Korea’s leading mobile service operator, NAVER. It has over 10 million listeners.",
        title2: 'Naver Music',
        link:"https://support.music.naver.com/seeyousoon"
    },
    {
        id: 56,
        img: NetEaseCloudMusic,
        title1: 'NetEase Cloud Music',
        discription: "NetEase Cloud Music is a freemium music streaming service launched in 2013. It is the fastest growing music service in China with more than 800 million registered users. and a music database consisting of more than 10 million songs.",
        title2: 'NetEase Cloud Music',
        link:"https://music.163.com/"
    },
  ];
  
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import CustomButton from "../../common/customButton/index";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import * as yup from "yup";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import { ScaleLoader } from "react-spinners";

const Index = ({ handleClose, view, getOrder, number, tokenedit }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [AnotherImages, setAnotherImages] = useState({
    image: view?.image || "",
  });
  useEffect(() => {
    if (AnotherImages?.image) {
      setError("");
    }
  }, [AnotherImages]);
  useEffect(() => {
    if (view && number == 1) {
      setImagePreview(view?.image);
    }
  }, []);

  const handleChnage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
  };

  const handleSubmit = async (values) => {
    if (!AnotherImages?.image) {
      setError("Please select an image file");
      return;
    }
    setIsLoading(true);
    const loginToken = JSON.parse(localStorage.getItem("login"));
    // const token = loginToken?.token;
    const token = tokenedit;
    var formDatavalue = new FormData();
    formDatavalue.append("image", AnotherImages?.image);
    if (number == 0) {
      await axios
        .post(
          `${process.env.REACT_APP_DMT_API_PATH}/creative/gallary`,
          formDatavalue,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error !== true) {
            getOrder();
            setIsLoading(false);
            handleClose();
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else if (number == 1) {
      await axios
        .put(
          `${process.env.REACT_APP_DMT_API_PATH}/creative/gallary/${view?._id}`,
          formDatavalue,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error !== true) {
            getOrder();
            setIsLoading(false);
            handleClose();
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Box id="contact">
        <Container maxWidth="md">
          <Box sx={{ mt: "0px", width: { md: "400px", xs: "100%" } }}>
            {isLoading ? (
              <ScaleLoader
                size={350}
                textAlign="center"
                className="music-loader"
                color="#FBCE2E"
              />
            ) : (
              <>
                <Typography
                  sx={{
                    marginTop: "10px",
                    marginBottom: "-12px",
                    marginLeft: "10px", fontFamily: "DM Sans",
                  }}
                >
                  Upload Gallary
                </Typography>
                <TextField
                  type="file"
                  name="image"
                  onChange={(e) => {
                    handleChnage(e);
                    setAnotherImages({
                      ...AnotherImages,
                      image: e.target.files[0],
                    });
                  }}
                />
                {error && (
                  <Typography
                    sx={{
                      textAlign: "start",
                      marginLeft: "12px",
                      fontSize: "12px",
                      marginTop: "-8px",
                    }}
                    color="error"
                  >
                    {error}
                  </Typography>
                )}
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    width="50px"
                    height="50px"
                  />
                )}
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "5px",
                    marginBottom: "15px",
                  }}
                >
                  <CustomButton
                  buttonStyle='editFormBtn'
                    btnText={view ? "Save" : "Add"}
                    handleClick={handleSubmit}
                    btntype="Submit"
                  />
                </div>
              </>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Index;

import React from "react";
import { useState, useEffect } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import BankForm from "../components/bankDetails/bankDetailsWithNri";
import Box from "@mui/material/Box";
import InidanBank from "../components/bankDetails/BankForm";
import NRIBank from "../components/bankDetails/NriForm";
import axios from "axios";

const Bank = () => {
  const [data, setData] = useState();
  const [valueget, setValueget] = useState();
  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  async function getOrder() {
    await axios
      .get(`${process.env.REACT_APP_DMT_API_PATH}/users`, config)
      .then((res) => {
        if (res?.data?.error !== true) {
          if (res?.data?.data?.bankDetails) {
            setData(res?.data?.data?.bankDetails);
            setValueget(res?.data?.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getOrder();
  }, []);
  const [formData, setFormData] = useState({
    Nationality: false,
  });
  useEffect(() => {
    if (valueget) {
      setFormData({
        ...formData,
        Nationality: valueget?.is_non_indian,
      });
    }
  }, [valueget]);
  const [openSidebar, setOpenSidebar] = useState(false);
  const toggleSidebar = () => {
    setOpenSidebar((p) => !p);
  };
  console.log(formData?.Nationality);
  return (
    <>
      <div className="container">
        <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
        <div className="main-container">
          <Navbar toggleSidebar={toggleSidebar} />
          <div className="pages">
            <Box className="custom-dmt-container">
              <BankForm
                formData={formData}
                setFormData={setFormData}
                data={data}
              />
              {formData?.Nationality ? (
                <NRIBank
                  data={data}
                  setData={setData}
                  getOrder={getOrder}
                  formData={formData}
                />
              ) : (
                <InidanBank
                  data={data}
                  setData={setData}
                  getOrder={getOrder}
                  formData={formData}
                />
              )}
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bank;

import { Container } from "@mui/system";
import React from "react";
import Box from "@mui/material/Box";
import "./style.css";
import { Grid, Typography } from "@mui/material";
import Featureimage from "../../assets/img/p1.png";
import Featureimage1 from "../../assets/img/p2.png";
import Featureimage2 from "../../assets/img/p3.png";

const Index = () => {
  return (
    <Box className="master-tune">
      <Box sx={{ marginTop: { xs: "10px", md: "30px" },px:'15px' }}>
        <Typography variant="h2" className="heading-feature-top-data">
          Deliver My Tune Supports The Young Scholars
        </Typography>
        <Typography variant="h4" className="heading-feature-text-data">
          Manzil Is A Non-profit, Youth-Led Learning Center. Manzil is Providing
          A Supportive Community And Resources For Students To Empower
          Themselves. One Another Through Learning, Teaching, Creativity And
          Encouraging Each Other To See The World In New Ways.
        </Typography>
      </Box>
      <Container sx={{ maxWidth:'1200px !important' ,px:{xs:'30px',sm:'100px',md:'0px'}}}>
        <Grid container spacing={{ md: 12, xs: 4 }} sx={{placeContent:'center'}}>
          <Grid item sm={6} md={4} xs={12}>
            <Box sx={{ marginTop: { xs: "0px", md: "50px" } }}>
              <Box>
                <img
                  src={Featureimage}
                  alt="Our Features"
                  width="100%"
                  height="100%"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item sm={6} md={4} xs={12}>
            <Box sx={{ marginTop: { xs: "0px", md: "50px" } }}>
              <Box>
                <img
                  src={Featureimage1}
                  alt="Our Features"
                  width="100%"
                  height="100%"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item sm={6} md={4} xs={12}>
            <Box sx={{ marginTop: { xs: "0px", md: "50px" } }}>
              <Box>
                <img
                  src={Featureimage2}
                  alt="Our Features"
                  width="100%"
                  height="100%"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Index;

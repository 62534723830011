import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { AiOutlineRightCircle } from "react-icons/ai";
import "./card.css";
import { Link } from "react-router-dom";

const Index = ({ detalis, releasesong, setReleasesong }) => {
  return (
    <>
      <Card
        sx={{
          maxWidth: "280px",
          minHeight: "200px",
          borderRadius: "10px",
          mb: 5,
        }}
      >
        <Link to={detalis.path} className="dashcard-link">
          <CardContent
            sx={{
              paddingX: { xs: "8px", sm: "10px" },
              paddingY: { xs: "15px", md: "12px" },
            }}
          >
            <Box sx={{ display: "flex" }}>
              <img
                className="dashboard-card-img"
                src={detalis.img}
                width="41px"
                height="41px"
                alt=""
              />
              <Typography
                className="dashboard-card-title"
                variant="p"
                ml={2}
                mt={{ xs: 0, md: 1.5 }}
                sx={{ color: " #E1E1E1", fontSize: { xs: "12px", md: "16px" } }}
                fontWeight="700"
              >
                {detalis.title}
              </Typography>
            </Box>
            <Box
              sx={{
                mt: 4,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                justifyContent: "center !important",
              }}
            >
              <Box sx={{ display: "inline-block" }}>
                {/* <CurrencyRupeeIcon
                color="white"
                fontWeight="700"
                sx={{ fontSize: { xs: "24px", sm: "24px", md: "30px" }, pt: 0 }}
              /> */}
                {/* <Icon src={detalis?.icons} /> */}

                <Typography
                  variant="span"
                  sx={{ mt: "-10px", textAlign: "center" }}
                  fontSize={{ xs: "30px", md: "40px" }}
                  fontWeight="bold"
                >
                  {detalis.icon} {detalis.numbers}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Link>
      </Card>
    </>
  );
};

export default Index;

import React from "react";
// import "./Style.scss";
import AlbumDrop from "../components/dragDrop/dropAlbum";
import DargDrop from "../components/dragDrop/dropSong";
import Grid from "@mui/material/Grid";
import earned from "../assets/img/earned.png";
import rejected from "../assets/img/rejected.png";
import released from "../assets/img/released.png";
import interview from "../assets/img/interview.png";
import DashboradCard from "../components/dashboardCard/index";
import DashboardTable from "../components/dashboardTable/index";
import Arrow from "../../src/assets/img/arrow.svg";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useState, useEffect } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

export const Mock_values_drag = [
  {
    id: 1,
    img: Arrow,
    btn: "Upload Song",
    type: "song",
  },
  // {
  //   id: 2,
  //   img: Arrow,
  //   btn: " Upload Album",
  //   type: "album",
  // },
];

export const Mock_values_Album = [
  {
    id: 1,
    img: Arrow,
    btn: " Upload Album",
    type: "album",
  },
];
const Dashboard = ({
  setSongValue,
  songValue,
  setCareer,
  songalbum,
  setsongalbum,
  setReleasesong,
  releasesong,
}) => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openTable, setOpenTable] = useState(false);
  const [songdesk, setsongdesk] = useState();
  const [albumdesk, setalbumdesk] = useState();

  const navigate = useNavigate();

  const toggleSidebar = () => {
    setOpenSidebar((p) => !p);
  };
  if (songdesk !== undefined) {
    navigate("/artist/singletrack", {
      state: {
        id: songdesk,
      },
    });
  } 
   else if (albumdesk !== undefined) {
    navigate("/artist/album", {
      state: {
        id: songalbum,
      },
    });
  }

  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [tabledata, setTabledata] = useState();

  const [editformdata, setEditformdata] = useState(location?.state?.id);
  const Captiondata = JSON.parse(localStorage.getItem("caution"));

  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;

  const [data, setData] = useState([]);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  async function getOrder() {
    await axios
      .get(`${process.env.REACT_APP_DMT_API_PATH}/orders`, config)
      .then((res) => {
        if (res.data.error !== true && res.data.data.length > 0) {
          setOpenTable(true);
          setData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getOrder();
  }, []);
  async function getDashboard() {
    await axios
      .get(
        `${process.env.REACT_APP_DMT_API_PATH}/royalty/user-dashboard`,
        config
      )
      .then((res) => {
        setTabledata(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getDashboard();
  }, []);
  const Mock_values = [
    {
      id: 1,
      img: earned,
      title: "Total Royalty Earned",
      numbers: tabledata?.earnings?.toFixed(0),
      path: "/artist/earned",
      icon: <CurrencyRupeeIcon />,
    },
    {
      id: 2,
      img: rejected,
      title: "Released Song",
      path: "/artist/mymusic?song=releasedsong",
      numbers: tabledata?.ReleasedSongCount,
    },
    {
      id: 3,
      img: released,
      path: "/artist/mymusic?song=inreviewsong",
      title: "Inreview Song",
      numbers: tabledata?.InReviewSongCount,
    },
    {
      id: 4,
      img: interview,
      path: "/artist/mymusic?song=editrequiredsong",
      title: "Edit Required Song",
      numbers: tabledata?.rejectSongCount,
    },
  ];

  return (
    <>
      <div className="container">
       
        <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar}   />
   
        <div className="main-container">
          <Navbar toggleSidebar={toggleSidebar} />
          <div className="pages">
            <Box className="custom-dmt-container" sx={{pb:5,mb:{xs:27,md:0}}}>
              {Captiondata ? (
                <Box sx={{ width: "100%" }}>
                  <Collapse in={open}>
                    <Alert sx={{ mb: 2 }}>{Captiondata}</Alert>
                  </Collapse>
                </Box>
              ) : (
                ""
              )}

              {openTable ? (
                <>
                  <Grid
                    container
                    spacing={{ xs: 0, sm: 2 }}
                    sx={{ marginTop: "10px !important" }}
                  >
                    {Mock_values.map((values, index) => (
                      <Grid
                        item
                        xs={6}
                        md={4}
                        lg={3}
                        sx={{ pr: { xs: "8px", sm: "0px" } }}
                      >
                        <DashboradCard
                          detalis={values}
                          key={index}
                          releasesong={releasesong}
                          setReleasesong={setReleasesong}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <DashboardTable data={data} setCareer={setCareer} />
                  <Grid container spacing={3}>
                    {Mock_values_drag.map((values, index) => (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                          mr: {
                            xs: 2,
                            sm: "0px",
                            marginBottom: "20px !important",
                          },
                        }}
                      >
                        <DargDrop
                          details={values}
                          key={index}
                          songValue={songValue}
                          setsongdesk={setsongdesk}
                          setSongValue={setSongValue}
                        />
                      </Grid>
                    ))}
                    {Mock_values_Album.map((values, index) => (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{ mr: { xs: 2, sm: "0px" } }}
                      >
                        <AlbumDrop
                          details={values}
                          key={index}
                          songalbum={songalbum}
                          setsongalbum={setsongalbum}
                          setSongValue={setSongValue}
                          setalbumdesk={setalbumdesk}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container spacing={3}>
                    {Mock_values_drag.map((values, index) => (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                          mr: {
                            xs: 2,
                            sm: "0px",
                            marginBottom: "20px !important",
                          },
                        }}
                      >
                        <DargDrop
                          details={values}
                          key={index}
                          setsongdesk={setsongdesk}
                          setSongValue={setSongValue}
                        />
                      </Grid>
                    ))}
                    {Mock_values_Album.map((values, index) => (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{ mr: { xs: 2, sm: "0px" } }}
                      >
                        <AlbumDrop
                          details={values}
                          key={index}
                          setsongalbum={setsongalbum}
                          setSongValue={setSongValue}
                          setalbumdesk={setalbumdesk}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid
                    container
                    spacing={{ xs: 0, sm: 2 }}
                    sx={{ marginTop: "30px !important" }}
                  >
                    {Mock_values.map((values, index) => (
                      <Grid
                        item
                        xs={6}
                        md={4}
                        lg={3}
                        sx={{ pr: { xs: "8px", sm: "0px" } }}
                      >
                        <DashboradCard
                          detalis={values}
                          key={index}
                          releasesong={releasesong}
                          setReleasesong={setReleasesong}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

import React from 'react'
import { useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";

import Guidlines from '../components/guidlines/index'
const AvoidSongRejection = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const toggleSidebar = () => {
    setOpenSidebar((p) => !p);
  };

  return (
    <>
      <div className="container">
        <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
        <div className="main-container">
          <Navbar toggleSidebar={toggleSidebar} />
          <div className="pages">
          <Guidlines/>
          </div>
        </div>
      </div>
    </>
  )
}

export default AvoidSongRejection
import { Search } from "@mui/icons-material";
import { Button, Grid, InputBase, TextField, Typography } from "@mui/material";
import { Box, maxWidth } from "@mui/system";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Image from "../../assets/img/bar.svg";
import { styled } from "@mui/material/styles";
import { GiWhiteBook } from "react-icons/gi";
import Modal from "./artModal";
import { useLocation } from "react-router-dom";

const Unsplashimage = ({ setSaveimage, saveImage }) => {
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.4, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing()})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      height: "44px",
      border: "1px solid white",
      background: "transparent",
      [theme.breakpoints.up("xs")]: {
        width: "100%",
        fontSize: "12px",

        height: "22px",
        background: "transparent",
      },
    },
  }));
  const [data, setData] = useState([]);
  const [sendimage, setSendimage] = useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = (item) => {
    setOpen(true);
    setSendimage(item?.urls?.small);
  };
  const location=useLocation()
  const isArtWork = location?.state?.id;
  const handleClose = () => setOpen(false);
  const [searchInput, setSearchInput] = useState("");
const isId = location?.state?.userId


  const handleSearch = async (e) => {
    e.preventDefault();
    await axios
      .get(
        `https://api.unsplash.com/search/photos?query=${searchInput}&per_page=100&client_id=qPl5z3Z7_TYZyaaSdDv3OLiOvob3IyOzccHrb6vWg7A`
      )
      .then((res) => {
        setData(res?.data?.results);
      })
      .catch((err) => {});
  };
  async function getHistory() {
    await axios
      .get(
        `https://api.unsplash.com/search/photos?query=music&per_page=100&client_id=qPl5z3Z7_TYZyaaSdDv3OLiOvob3IyOzccHrb6vWg7A`
      )
      .then((res) => {
        setData(res?.data?.results);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getHistory();
  }, []);
  return (
    <Box>
      <Box sx={{ textAlign: "center", marginTop: "12px", display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              borderRadius: "25px",
              padding: "8px",
              width: {xs:"220px", sm:"270px"},height:"45px",
              border: "1px solid white",display:"flex", justifyContent:"center",alignItems:"center"
            }}
          >
            {/* <Search sx={{ mr: "8px",padding: "2px", }} /> */}
            <InputBase
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search"
              sx={{
                color: "white",
                "&::placeholder": { color: "white" },
                flexGrow: 1,
              }}
            />
          </Box>

          <Button
            onClick={handleSearch}
            variant="contained"
            sx={{ marginLeft: "10px" }}
            className='searchSplash'
          >
            Search
          </Button>
        </Box>
      </Box>

      <Grid container mt={2} spacing={2}>
        {data?.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <div>
              <img
                src={item?.urls?.small_s3}
                onClick={() => handleOpen(item)}
                width="100%"
                height="400px"
                alt=""
              />
            </div>
          </Grid>
        ))}
      </Grid>
      <Modal
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        handleOpen={handleOpen}
        sendimage={sendimage}
        setSaveimage={setSaveimage}
        saveImage={saveImage}
        isArtWork={isArtWork}
        isId={isId}
      />
    </Box>
  );
};

export default Unsplashimage;

import React from "react";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import Grid from "@mui/material/Grid";
import "./style.css";
import Typography from "@mui/material/Typography";
import CustomButton from "../../components/common/customButton/index";
import MangeDeshboard from "../../assets/img/static/manage_dashboard.png";
import { Link } from "@mui/material";

const Index = () => {
  return (
    <Container sx={{ maxWidth:'1200px !important'}}>
      <Grid container sx={{ marginTop: "80px", marginBottom: "60px",px:{xs:'20px', sm:'100px' ,md:'0px'}}}>
        <Grid item md={6} xs={12}>
          <Box sx={{marginTop:{xs:'0px',md:'70px'}}}>
            <Typography variant="p" className="homemanageBoldText">
              Manage All Your Songs, Releases, Royalties, Reports At One Place{" "}
            </Typography>
          </Box>
          <Box sx={{ maxWidth: { xs: "100%", md: "90%" }, marginTop: "20px" }}>
            <Typography variant="p" className="homemanageNormalText">
              Simple and easy-to-use dashboard which makes music distribution as
              easy as using your own social media accounts. Still not convinced?
              Click the button below.
            </Typography>
          </Box>
          <Link  href="/signup/artist" underline="none"  >  <CustomButton
            marginTop="15px"
            width="189px"
            height="54px"
            borderRadius="27px"
            border="1.35px solid #f36905!important;"
            fontWeight="700"
            btnText="Know More"
            color='white !important'
            background='#FB8E0B !important'
            buttonStyle='light-theme-btn'
          /></Link>
         
        </Grid>
        <Grid item md={6} xs={12}>
          <Box className="manageimgBox" sx={{marginTop:{xs:'40px',md:'none'}}}>
            <Box className="manageBlueDotContainer">
              <Box className="manageBlueDot"></Box>
              <Box className="manageDot"></Box>
              <Box className="manageDot"></Box>
            </Box>
            <img
              src={MangeDeshboard}
              alt="image"
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Index;

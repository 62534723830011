import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import CustomButton from "../common/customButton/index";
import "./bundle.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const Index = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },
    // // hide last border
    // "&:last-child td, &:last-child th": {
    //   border: 0,
    // },
  }));

  const BundleData = [
    {
      id: 1,
      pay: 10000,
      action: "fdbdf",
      wallet: 15000,
    },
    {
      id: 2,
      pay: 15000,
      action: "fdbdf",
      wallet: 22500,
    },
    {
      id: 3,
      pay: 20000,
      action: "fdbdf",
      wallet: 30000,
    },
    {
      id: 4,
      pay: 25000,
      action: "fdbdf",
      wallet: 37500,
    },
    {
      id: 5,
      pay: 30000,
      action: "fdbdf",
      wallet: 45000,
    },
    {
      id: 6,
      pay: 40000,
      action: "fdbdf",
      wallet: 60000,
    },
    {
      id: 7,
      pay: 50000,
      action: "fdbdf",
      wallet: 75000,
    },
  ];
  const handlebundlepay = async (data) => {
    const loginToken = JSON.parse(localStorage.getItem("login"));
    const token = loginToken.token;
    const userid = loginToken.user._id;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const payload = {
      actual_amount: data?.pay,
      wallet_amount: data?.wallet,
    };
    await axios
      .post(
        `${process.env.REACT_APP_DMT_API_PATH}/bundlepricing/pay`,
        payload,
        config
      )
      .then((res) => {
        if (res.data.error !== true) {
          const options = {
            key: "rzp_live_UOZD55VDM3YeQI",
            amount: res?.data?.data?.amount,
            currency: "INR",
            name: "DELIVER MY TUNE",
            description: "RazorPay",
            // image: "https://avatars.githubusercontent.com/u/25058652?v=4",
            order_id: res?.data?.data?.order_id,
            handler: async function (response) {
              const data = {
                //  orderCreationId: datavalue,+
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
              };
              const result = await axios.post(
                `${process.env.REACT_APP_DMT_API_PATH}/public/payments/verify`,
                data
              );
              if (result.data.error !== true) {
                toast.success("Wallet Ammount Added Successfully");
              }
            },
            notes: {
              address: "Razorpay Corporate Office",
            },
            theme: {
              color: "#121212",
            },
          };
          const razor = new window.Razorpay(options);
          razor.open();
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      <Box
        sx={{ background: " rgba(255, 255, 255, 0.05)", borderRadius: "16px" }}
      >
        <Box sx={{ p: { xs: 1.5, sm: 3 } }}>
          <Grid
            container
            spacing={2}
            flexDirection={{ xs: "column-reverse", lg: "row" }}
          >
            <Grid item xs={12} lg={4} textAlign="center">
              <Box sx={{ p: 3 }}>
                <Typography
                  sx={{
                    maxWidth: "250px",
                    textAlign: "center",
                    margin: "auto",
                    pb: 4,
                    fontFamily: "DM sans",
                  }}
                >
                  Pay Less, Get More.
                </Typography>
                <Typography
                  sx={{
                    maxWidth: "200px",
                    textAlign: "center",
                    margin: "auto",
                    pb: 4,
                    fontFamily: "DM sans",
                  }}
                >
                  Distribute, Master or Advertise with Credits whenever you
                  want.
                </Typography>
                <Typography
                  sx={{
                    maxWidth: "215px",
                    textAlign: "center",
                    margin: "auto",
                    pb: 4,
                    fontFamily: "DM sans",
                  }}
                >
                  Credit Never Expires, use it in a month or in years.
                </Typography>
                <Typography
                  sx={{
                    maxWidth: "250px",
                    textAlign: "center",
                    margin: "auto",
                    fontFamily: "DM sans",
                  }}
                >
                  All Additional Discounts and Coupons valid on Credits.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Box>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 440 }}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          sx={{ fontFamily: "DM sans" }}
                          align="center"
                        >
                          PAY{" "}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontFamily: "DM sans" }}
                          align="center"
                        >
                          GET IN Wallet
                        </StyledTableCell>

                        <StyledTableCell
                          sx={{ fontFamily: "DM sans" }}
                          align="center"
                        >
                          {" "}
                          ACTION
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {BundleData.map((data, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell
                            sx={{ fontFamily: "DM sans" }}
                            align="center"
                          >
                            Rs. {data.pay}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ fontFamily: "DM sans" }}
                            align="center"
                          >
                            Rs. {data.wallet}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ fontFamily: "DM sans" }}
                            align="center"
                          >
                            {/* {data.action} */}
                            <CustomButton
                              btnText="Pay Now"
                              buttonStyle="bundlebtn"
                              handleClick={() => {
                                handlebundlepay(data);
                              }}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <ToastContainer />
      </Box>
    </>
  );
};

export default Index;

import { Button, Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import "./style.css";
import CustomButton from "../customButton/index";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
 import privacy from '../../../assets/privacy-policy.pdf'
 import terms from '../../../assets/terms.pdf'

const Index = () => {
  const navigateSinguppage = useNavigate();
  const handleshow = () => {
    navigateSinguppage("/signup/artist");
  };
  // const  handleClick=()=>{
  //   navigateSinguppage('/about')
  // }

  return (
    <Box sx={{mt:5 ,background:'#f8f8fa'}} >
      <Box sx={{ paddingTop: "40px !important",maxWidth:'1300px',margin:'auto' }}>
        <Typography
          variant="h3"
          className="custom-list"
          sx={{ fontSize: { xs: "16px !important",sm:'32px !important',lg:'56px !important' },lineHeight:{xs:'22px !important',sm:'40px !important',lg:'73px !important'}}}
        >
          Ready to have everyone groove to your beats? <br /> Let Deliver My
          Tune help you boost your music career
        </Typography>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        {/* <Link href='/signup/artist' underline='none'>   */}
        <CustomButton
          handleClick={() => {
            handleshow();
          }}
          btnText="Sign up for free"
          marginTop="25px"
          width="237px"
          height="54px"
          borderRadius="27px"
          border="1.35px solid #f36905!important;"
          fontWeight="700"
          buttonStyle="light-theme-btn"
        />
        {/* </Link> */}
      </Box>
      <Grid container columnSpacing={{ md: 4, xs: 0 }}>
        <Grid item lg={7} xs={12} sx={{ position: "relative", }}>
          <Box
            sx={{
              display: { md: "flex", xs: "grid" },
              justifyContent: { lg: "end", xs: "center" },
              gap: { md: "25px", xs: "none" },
              marginTop: "25px",
              marginBottom: { lg: "50px", xs: "5px" },
            }}
          >
            <Button sx={{ color: "#FFFFFF", textTransform: "capitalize" }}>
              <Link
                className="link-btn"
                underline="hover"
                color="inherit"
                to="/home"
              >
                Home
              </Link>
            </Button>
            <Button sx={{ color: "#FFFFFF", textTransform: "capitalize" }}>
              <Link
                className="link-btn"
                underline="hover"
                color="inherit"
                to="/pricing"
              >
                Pricing
              </Link>
            </Button>
            <Button sx={{ color: "#FFFFFF", textTransform: "capitalize" }}>
              <Link
                className="link-btn"
                underline="hover"
                color="inherit"
                to="/about"
              >
                About
              </Link>
            </Button>
            <Button sx={{ color: "#FFFFFF", textTransform: "capitalize" }}>
              <Link
                className="link-btn"
                underline="hover"
                color="inherit"
                to="/stores"
              >
                Store
              </Link>
            </Button>
            <Button sx={{ color: "#FFFFFF", textTransform: "capitalize" }}>
              <a
                className="link-btn"
                underline="hover"
                color="inherit"
                target='blank'
                href="https://blog.delivermytune.com/"
              >
                Blog
              </a>
            </Button>
            <Button sx={{ color: "#FFFFFF", textTransform: "capitalize" }}>
              <Link
                className="link-btn"
                underline="hover"
                color="inherit"
                to="/Faq"
              >
                FAQs
              </Link>
            </Button>
            <Button sx={{ color: "#FFFFFF", textTransform: "capitalize" }}>
              <Link
                className="link-btn"
                underline="hover"
                color="inherit"
                to="/queries"
              >
              Queries
              </Link>
            </Button>
            <Button sx={{ color: "#FFFFFF", textTransform: "capitalize" }}>
              <Link
                className="link-btn"
                underline="hover"
                color="inherit"
                to="/contact"
              >
                Contact
              </Link>
            </Button>
            <Button sx={{ color: "#FFFFFF", textTransform: "capitalize" }}>
              <Link
                className="link-btn"
                underline="hover"
                color="inherit"
                to="/login"
              >
                Login
              </Link>
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          lg={5}
          xs={12}
          sx={{ position: "relative", textAlign: "end" }}
        >
          <Box
            alignItems="right"
            sx={{
              marginLeft: {
                xs: "auto",
                sm: "auto",
                // md: "200px",
                lg: "275px",
              },
              marginTop: {
                xs: "25.64px",
                lg: "40px",
              },
            }}
          >
            <Stack
              direction="row"
              spacing={3}
              size="small"
              sx={{
                marginBottom: { xs: "20px", sm: "30px", md: "40px" },
                display: "flex",
                justifyContent: "center",
              }}
            >
              <a
                target="_blank"
                href="https://www.instagram.com/deliver_my_tune/"
              >
                <IconButton aria-label="delete" size="small">
                  <InstagramIcon
                    fontSize="inherit"
                    sx={{ color: "#FD6003;" }}
                  />
                </IconButton>
              </a>
              <a target="_blank" href="https://www.facebook.com/delivermytune/">
                <IconButton aria-label="" size="small">
                  <FacebookIcon fontSize="inherit" sx={{ color: "#FD6003;" }} />
                </IconButton>
              </a>

              <a target="_blank" href="https://twitter.com/delivermytune">
                <IconButton aria-label="" size="small">
                  <TwitterIcon fontSize="inherit" sx={{ color: "#FD6003;" }} />
                </IconButton>
              </a>

              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCtMUJDqOaZvutHL3adHr1kA"
              >
                <IconButton aria-label="" size="small">
                  <YouTubeIcon fontSize="inherit" sx={{ color: "#FD6003;" }} />
                </IconButton>
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/deliver-my-tune/"
              >
                <IconButton aria-label="" size="small">
                  <LinkedInIcon fontSize="inherit" sx={{ color: "#FD6003;" }} />
                </IconButton>
              </a>
            </Stack>
          </Box>
        </Grid>
      </Grid>

      <hr />
      <Grid container spacing={{ md: 4, xs: 0 }}>
        <Grid item md={4} xs={12} sx={{ position: "relative" }}>
          <Box
            textAlign="center"
            sx={{
              marginTop: { lg: "25px", xs: "none" },
              color: "#6c757d !important;",
              marginBottom: { md: "16px", xs: "none" },
              fontSize:'16px'
            }}
            pb={{ xs: 1, sm: 0 }}
            fontFamily="Open Sans, sans-serif"
          >
            &#169; Copyright 2022, All Rights Reserved
          </Box>
        </Grid>
        <Grid item md={8} xs={12} sx={{ position: "relative" }}>
          <Box
            sx={{
              display: { md: "flex", xs: "grid" },
              justifyContent: { md: "end", xs: "center" },
              gap: { md: "25px", xs: "none" },
              marginTop: { md: "25px", xs: "0px" },
              marginBottom: { md: "50px", xs: "30px" },
              maxWidth: {xs:'100%',sm:"95%"},
            }}
          >
            <Button sx={{ color: "#FFFFFF", textTransform: "capitalize" }}>
              <a
               className="link-btn"
                underline="hover"
                color="inherit"
                target="_blank"
                href={privacy}
              >
                Privacy Policy
              </a>
            </Button>
            
            <Button sx={{ color: "#FFFFFF", textTransform: "capitalize" }}>
              <a
                className="link-btn"
                underline="hover"
                color="inherit"
                target="_blank"
                href={terms}
              >
                Terms & Conditions
              </a>
            </Button>
            <Button sx={{ color: "#FFFFFF", textTransform: "capitalize" }}>
              <a
                className="link-btn"
                underline="hover"
                color="inherit"
                target="_blank"
                href="/sitemap.xml"
              >
               Sitemap
              </a>
            </Button>
            {/* <Button sx={{ color: "#FFFFFF", textTransform: "capitalize" }}>
              <Link
                className="link-btn"
                underline="hover"
                color="inherit"
                 to="/home"
              >
                Sitemap
              </Link>
            </Button> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Index;

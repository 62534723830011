import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import CustomButton from "../../common/customButton/index";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
// import "./banner.css";
import * as yup from "yup";
import { Typography } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import moment from "moment/moment";
import { ScaleLoader } from "react-spinners";
import "react-toastify/dist/ReactToastify.css";
import './upcoming.css'

const Index = ({ handleClose, view, getOrder, number, tokenedit }) => {
  const validationSchema = yup.object().shape({
    eventtitle: yup.string().min(2, "Too Short!").max(20, 'maximum 20 characters is allowed.').required("Required*"),
    eventdesc: yup
      .string()
      .min(50, "minimum 50 characters required.").max(110,'maximum 110 characters is allowed.')
      .required("Required*"),
    eventlocation: yup.string().required("Required*"),
    eventDate: yup.date().required("Required*"),
    eventtime: yup.string().required("Required*"),
    eventlink: yup.string().required("Required*"),
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [AnotherImages, setAnotherImages] = useState({
    banner: view?.eventimage || "",
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    eventtitle: "",
    eventdesc: "",
    eventlocation: "",
    eventDate: "",
    eventtime: "",
    eventlink: "",
  };
  const handleChnage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
  };
  useEffect(() => {
    if (AnotherImages?.banner) {
      setError("");
    }
  }, [AnotherImages]);

  useEffect(() => {
    if (view) {
      formik.setFieldValue("eventtitle", view?.eventtitle);
      formik.setFieldValue("eventdesc", view?.eventdesc);
      formik.setFieldValue("eventlocation", view?.eventlocation);
      formik.setFieldValue(
        "eventDate",
        moment(view?.eventDate).format("YYYY-MM-DD")
      );
      formik.setFieldValue("eventtime", view?.eventtime);
      formik.setFieldValue("eventlink", view?.eventlink);
      setImagePreview(view?.eventimage);
    }
  }, []);

  const onSubmit = async (values) => {
    if (!AnotherImages?.banner) {
      setError("Please select an image file");
      return;
    }
    setIsLoading(true);
    const token = tokenedit;
    var formDatavalue = new FormData();
    formDatavalue.append("eventtitle", values.eventtitle);
    formDatavalue.append("eventdesc", values.eventdesc);
    formDatavalue.append("eventlocation", values.eventlocation);
    formDatavalue.append("eventDate", values.eventDate);
    formDatavalue.append("eventtime", values.eventtime);
    formDatavalue.append("eventlink", values.eventlink);
    formDatavalue.append("eventimage", AnotherImages.banner);
    if (number == 0) {
      await axios
        .post(
          `${process.env.REACT_APP_DMT_API_PATH}/creative/events`,
          formDatavalue,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error !== true) {
            getOrder();
            handleClose();
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (number == 1) {
      await axios
        .put(
          `${process.env.REACT_APP_DMT_API_PATH}/creative/events/${view?._id}`,
          formDatavalue,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error !== true) {
            getOrder();
            handleClose();
            setIsLoading(false);
          } else {
            toast.error(res.data.message);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <>
      <Box id="contact" className='upcomingform'>
        <Container maxWidth="md" sx={{padding:"12px"}}>
          <Box
            component="form"
            sx={{ mt: "0px", width: { md: "500px", xs: "100%" } }}
            onSubmit={formik.handleSubmit}
          >
            {isLoading ? (
              <ScaleLoader
                size={350}
                textAlign="center"
                className="music-loader"
                color="#FBCE2E"
              />
            ) : (
              <>
                <Typography
                  sx={{
                    marginTop: "10px",
                    marginLeft: "5px",
                    color:"black"
                  }}
                >
                  Enter Events Title
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  placeholder="Enter Title"
                  {...formik.getFieldProps("eventtitle")}
                  onChange={(e) => {
                    formik.setFieldValue("eventtitle", e.target.value);
                  }}
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                    border: "1px solid black",
                  }}
                  variant="standard"
        

                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                      inputProps: { style: { color: 'black' }}
                  }}
                />
                {formik.touched.eventtitle && formik.errors.eventtitle ? (
                  <Typography
                    sx={{
                      textAlign: "start",
                      marginLeft: "4px",
                      fontSize: "12px",
                    }}
                    color="error"
                  >
                    {formik.errors.eventtitle}
                  </Typography>
                ) : null}
                <Typography
              sx={{
                marginTop: "10px",
                marginLeft: "5px",marginBottom:"-15px",   color:"black"
              }}
            >
              Enter Description
            </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  minRows="2"
                  multiline
                  placeholder="Enter Description"
                  {...formik.getFieldProps("eventdesc")}
                  onChange={(e) => {
                    formik.setFieldValue("eventdesc", e.target.value);
                  }}
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                    mt: 2, 
                    border: "1px solid black",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                      inputProps: { style: { color: 'black' }}
                  }}
                />
                {formik.touched.eventdesc && formik.errors.eventdesc ? (
                  <Typography
                    sx={{
                      textAlign: "start",
                      marginLeft: "4px",
                      fontSize: "12px",
                    }}
                    color="error"
                  >
                    {formik.errors.eventdesc}
                  </Typography>
                ) : null}
                <Typography
                  sx={{
                    marginTop: "10px",
                    marginLeft: "5px",
                    color:"black"
                  }}
                >
                  Enter Location
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  name="eventlocation"
                  placeholder="Enter Title"
                  {...formik.getFieldProps("eventlocation")}
                  onChange={(e) => {
                    formik.setFieldValue("eventlocation", e.target.value);
                  }}
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                    border: "1px solid black",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                      inputProps: { style: { color: 'black' }}
                  }}
                />
                {formik.touched.eventlocation && formik.errors.eventlocation ? (
                  <Typography
                    sx={{
                      textAlign: "start",
                      marginLeft: "4px",
                      fontSize: "12px",
                    }}
                    color="error"
                  >
                    {formik.errors.eventlocation}
                  </Typography>
                ) : null}
                <Typography
                  sx={{
                    marginTop: "10px",
                    marginLeft: "5px",
                    color:"black"
                  }}
                >
                  Enter Events Date
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  type="date"
                  name="eventDate"
                  placeholder="Enter Title"
                  {...formik.getFieldProps("eventDate")}
                  onChange={(e) => {
                    formik.setFieldValue("eventDate", e.target.value);
                  }}
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                    border: "1px solid black",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                      inputProps: { style: { color: 'black' }}
                  }}
                />
                {formik.touched.eventDate && formik.errors.eventDate ? (
                  <Typography
                    sx={{
                      textAlign: "start",
                      marginLeft: "4px",
                      fontSize: "12px",
                    }}
                    color="error"
                  >
                    {formik.errors.eventDate}
                  </Typography>
                ) : null}
                <Typography
                  sx={{
                    marginTop: "10px",
                    marginLeft: "5px",   color:"black"
                  }}
                >
                  Enter Events Time
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  type="time"
                  label=""
                  name="eventtime"
                  placeholder="Enter Title"
                  {...formik.getFieldProps("eventtime")}
                  onChange={(e) => {
                    formik.setFieldValue("eventtime", e.target.value);
                  }}
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                    border: "1px solid black",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                      inputProps: { style: { color: 'black' }}
                  }}
                />
                {formik.touched.eventtime && formik.errors.eventtime ? (
                  <Typography
                    sx={{
                      textAlign: "start",
                      marginLeft: "4px",
                      fontSize: "12px",
                    }}
                    color="error"
                  >
                    {formik.errors.eventtime}
                  </Typography>
                ) : null}
                <Typography
                  sx={{
                    marginTop: "10px",
                    marginLeft: "5px",   color:"black"
                  }}
                >
                  Enter Link
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  name="eventlink"
                  placeholder="Enter Title"
                  {...formik.getFieldProps("eventlink")}
                  onChange={(e) => {
                    formik.setFieldValue("eventlink", e.target.value);
                  }}
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                    border: "1px solid black",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                      inputProps: { style: { color: 'black' }}
                  }}
                />
                {formik.touched.eventlink && formik.errors.eventlink ? (
                  <Typography
                    sx={{
                      textAlign: "start",
                      marginLeft: "4px",
                      fontSize: "12px",
                    }}
                    color="error"
                  >
                    {formik.errors.eventlink}
                  </Typography>
                ) : null}
                <Typography
                  sx={{
                    marginTop: "10px",
                    marginBottom: "-12px",
                    marginLeft: "6px",   color:"black"
                  }}
                >
                  Upload Banner
                </Typography>
                <TextField
                  type="file"
                  name="banner"
                  sx={{ marginLeft: "-8px" }}
                  onChange={(e) => {
                    handleChnage(e);
                    setAnotherImages({
                      ...AnotherImages,
                      banner: e.target.files[0],
                    });
                  }}
                />
                {error && (
                  <Typography
                    sx={{
                      textAlign: "start",
                      marginLeft: "12px",
                      fontSize: "12px",
                      marginTop: "-8px",
                      border: "1px solid black",
                    }}
                    color="error"
                  >
                    {error}
                  </Typography>
                )}
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    width="50px"
                    height="50px"
                  />
                )}
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "2px",
                    marginBottom: "2px",
                  }}
                >
                  <CustomButton
                    buttonStyle="editFormBtnsecond"
                    btnText={view ? "Save" : "Add"}
                    btntype="Submit"
                  />
                </div>
              </>
            )}
          </Box>
        </Container>
        <ToastContainer />
      </Box>
    </>
  );
};

export default Index;

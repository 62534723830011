import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import Wallet from "./walletcard.css";
import axios from "axios";

import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
const Walletcard = ({data}) => {
  // const [data, setData] = useState([]);
  // const loginToken = JSON.parse(localStorage.getItem("login"));
  // const token = loginToken.token;
  // const userid = loginToken.user._id;

  // async function getCredit() {
  //   await axios
  //     .get(`${process.env.REACT_APP_DMT_API_PATH}/wallet/?user_id=${userid}`)
  //     .then((res) => {
  //       setData(res.data.data.total[0].total);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  // useEffect(() => {
  //   getCredit();
  // }, []);

  return (
    <Box>
      <Card className="card" sx={{background: 'rgba(255, 255, 255, 0.05)'}}>
        <Typography className="yourwallet"> Your Wallet</Typography>
        <Typography fontWeight="700" fontSize=" 1.625rem">
          Total Credits: &nbsp;&#x20b9;{data}
        </Typography>
      </Card>
    </Box>
  );
};

export default Walletcard;

const TotalCredits = [{ id: 1, totalCredit: "0" }];

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
export const tokenSlice = createSlice({
  name: "token",
  initialState: {
    token:'',
    data:"",
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    saveToken: (state, action) => action.payload
  },
  extraReducers: {
    // Extra reducer comes here
  },
})
export const userSelector = state => state.token
import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/system/Container";
import Grid from "@mui/material/Grid";
import WithAll from "../../../assets/img/profile/user-banner.png";
import Sign from "../../../assets/img/profile/sign.png";
import ReviewData from "../../../assets/img/static/text.png";
import CloseIcon from "@mui/icons-material/Close";
import { TextField, Typography, Button } from "@mui/material";
import Static from "../../../assets/img/static/mask-half.png";
import { useNavigate } from "react-router-dom";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import "./banner.css";
import CustomButton from "../editButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Form_Carrier from "./bannereditform";
import Paper from "@mui/material/Paper";
import portfolionebg from '../../../assets/img/profile/portfolionebg.png'

function PaperComponent(props) {
  return <Paper {...props} />;
}

const Index = ({ data, getOrder, tokenedit }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigateSinguppage = useNavigate();
  const handleshow = () => {
    navigateSinguppage("/signup/artist");
  };
  const handleClick = () => {
    navigateSinguppage("/about");
  };
  return (
    <>
      <Container
        sx={{
          background: "#141414 !important",
          color: "white",
          width: "100%",
          marginTop: { md: "20px", xs: "none" },
          maxWidth: { md: "80%", xs: "100%" },
        }}
        className="custom-containdata"
      >
        <Box>
          <Grid container spacing={{ md: 2, xs: "0" }}>
            <Grid item xs={12} md={6}>
              <Box sx={{ marginLeft: { lg: "80px", xs: "none" } }}>
                <Box sx={{ marginTop: { xs: "20px", lg: "140px" } }}>
                  <Box sx={{ display: "flex" }}>
                    <Button className="musician"> {data?.title}</Button>
                    {tokenedit ? (
                      <Box
                        sx={{
                          marginBottom: { md: "50px", xs: "20px" },
                          marginLeft: { lg: "250px", xs: "10px" },
                        }}
                      >
                        <CustomButton
                          btnText="Edit"
                          handleClick={handleOpen}
                          startIcon={
                            <ModeEditOutlinedIcon sx={{ marginRight: "5px" }} />
                          }
                          width="75px"
                          height="30px"
                          border="2px solid #ffffff !important"
                          padding="15px"
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          marginBottom: { md: "50px", xs: "20px" },
                          marginLeft: { lg: "250px", xs: "10px" },
                        }}
                      ></Box>
                    )}
                  </Box>
                  <Typography variant="p" className="homemanageBoldTextabout">
                    {data?.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    maxWidth: { xs: "100%", md: "90%" },
                    marginTop: { md: "40px", xs: "none" },
                  }}
                >
                  <Typography variant="p" className="homemanageNormalText">
                    {data?.description === "undefined"
                      ? "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Donec sollicitudin molestie malesuada. Sed porttitor lectus nibh. Curabitur aliquet quam id dui posuere blandit."
                      : data?.description}
                  </Typography>
                </Box>
                <Box mt={2.5}>
                  <img
                    alt=""
                    src={
                      data?.autograph === "undefined" ? Sign : data?.autograph
                    }
                    className="banner-signature"
                    width="30%"
                    height="100%"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{display:"flex", justifyContent:"center", alignItems:'center'}} >
              <Box sx={{ marginTop: { md: "50px", xs: "none" }, background:'url('+ portfolionebg+')' , backgroundSize:'100% 100%',width:{lg:'450px', sm:'400px', xs:'300px'}, height:{lg:'450px', sm:'400px', xs:'300px'}, display:"flex", justifyContent:"center", alignItems:'center' }}>
               <img
                  alt=""
                  src={data?.banner === "undefined" ? WithAll : data?.banner}
                  width="100px"
                  height="100px"
                  style={{borderRadius:"50%"}}
                  className="image-banner-port"
                  />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <div>
        <Dialog
          height="auto"
          style={{ zIndex: "9999922" }}
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <Box textAlign="end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <DialogTitle
              style={{ cursor: "move" }}
              align="center"
              mt="-30px"
              padding="0px !important"
              id="draggable-dialog-title"
              fontFamily='DM Sans !important'
            >
              Creative Banner
            </DialogTitle>
          </Box>

          <Form_Carrier
            view={data}
            handleClose={handleClose}
            getOrder={getOrder}
            tokenedit={tokenedit}
          />
        </Dialog>
      </div>
    </>
  );
};

export default Index;

import React from "react";
import { useState, useEffect } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import Account from "../components/account/account";
import { Box } from "@mui/material";
import axios from "axios";

const Profile = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const toggleSidebar = () => {
    setOpenSidebar((p) => !p);
  };
  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;
  const userid = loginToken?.user._id;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [data, setData] = useState([]);
  async function getContact() {
    await axios
      .get(`${process.env.REACT_APP_DMT_API_PATH}/users`, config)
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    getContact();
  }, []);
  return (
    <>
      <div className="container">
        <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
        <div className="main-container">
          <Navbar toggleSidebar={toggleSidebar} data={data} setData={setData} />
          <div className="pages">
            <Box>
              <Account
                data={data}
                setData={setData}
                getContact={getContact}
                config={config}
              />
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;

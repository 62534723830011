import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Card } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0,
  // },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  {
    id: 1,
    email: "singer@gmail.com",
    OrderId: "01",
    SongName: "nakhre",
    MasterAudio: " masteraudio",
  },
  {
    id: 1,
    email: "happines@gmail.com",
    OrderId: "02",
    SongName: " khawb",
    MasterAudio: " masteraudio",
  },
];

export default function CustomizedTables() {
  const [data, setData] = useState([]);
  //`${'https://dmtapi.anshuagrawal.in/mastered?user_id=5f52856705ea57779802e1aa'}`
  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;
  const userid = loginToken?.user._id;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  async function getMasterAudio() {
    await axios
      .get(
        `${process.env.REACT_APP_DMT_API_PATH}/mastered?user_id=${userid}`,
        config
      )
      .then((res) => {
        setData(res?.data?.data);
        // console.log(res?.data?.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getMasterAudio();
  }, []);

  const handleClick = () => {
    <a href={data.document} target="_blank" rel="noreferrer" />;
  };

  return (
    <Box className="custom-dmt-container">
      <Card className="masteraudiolist">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ fontFamily: "DM sans" }} align="center">
                  {" "}
                  Email
                </StyledTableCell>
                {/* <StyledTableCell sx={{ fontFamily: "DM sans" }} align="center">
                  Order Id
                </StyledTableCell> */}
                <StyledTableCell sx={{ fontFamily: "DM sans" }} align="center">
                  {" "}
                  Song Name
                </StyledTableCell>
                <StyledTableCell sx={{ fontFamily: "DM sans" }} align="center">
                  {" "}
                 Status
                </StyledTableCell>
                <StyledTableCell sx={{ fontFamily: "DM sans" }} align="center">
                  {" "}
                 Date
                </StyledTableCell>
                <StyledTableCell sx={{ fontFamily: "DM sans" }} align="center">
                  {" "}
                  Master Audio
                </StyledTableCell>
                <StyledTableCell sx={{ fontFamily: "DM sans" }} align="center">
                  {" "}
                  Original Mastered Audio
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell
                    sx={{ fontFamily: "DM sans" }}
                    align="center"
                  >
                    {row?.user_id?.email}
                  </StyledTableCell>
                  {/* <StyledTableCell
                    sx={{ fontFamily: "DM sans" }}
                    align="center"
                  >
                    {row?.track_id || row?._id}
                  </StyledTableCell> */}
                  <StyledTableCell
                    sx={{ fontFamily: "DM sans" }}
                    align="center"
                  >
                    {row?.songName}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ fontFamily: "DM sans" }}
                    align="center"
                  >
                    {row?.status}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ fontFamily: "DM sans" }}
                    align="center"
                  >
                     {moment(row?.created_at).format("DD-MM-YYYY")}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ fontFamily: "DM sans" }}
                    align="center"
                  >
                    <a href={row?.file} target="_blank" rel="noreferrer">
                      <DownloadIcon sx={{ color: "#f9a40d" }} />
                    </a>
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ fontFamily: "DM sans" }}
                    align="center"
                  >
                    {row?.updatedsongbyadmin && (
                      <a
                        href={row.updatedsongbyadmin}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <DownloadIcon sx={{ color: "#f9a40d" }} />
                      </a>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import Homebanner from "../../components/homeBanner/index";
import Navbar from "../../components/common/navbar/index";
import Channel from "../../components/ourChanner/index";
import Music from "../../components/musicMastering/index";
import ManageSong from "../../components/manageSongs/index";
import ArtistNumber from "../../components/artistLabel/index";
import Ourfeatures from "../../components/ourFeatures/index";
import Testimonial from "../../components/testimonial/index";
import Footer from "../../components/common/footer/index";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";

// import EmailPassword from '../../components/changePWDbyEmail/ChangePWDbyEmail'

const themeLight = createTheme({
  palette: {
    mode: "light",
  },
});

const Home = () => {
  return (
    <Box>
      <ThemeProvider theme={themeLight}>
        <CssBaseline />
        <Navbar />
        <Homebanner />
        {/* <EmailPassword/> */}
        <Channel />
        <Music />
        <ManageSong />
        <ArtistNumber />
        <Ourfeatures />
        <Testimonial />
        <Footer />
      </ThemeProvider>
    </Box>
  );
};

export default Home;

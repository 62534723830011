import { Container } from "@mui/system";
import React from "react";
import Box from "@mui/material/Box";
import "./style.css";
import { Grid, Typography } from "@mui/material";
import img01 from '../ourFeatures/Images/img01.webp'
import img02 from '../ourFeatures/Images/img02.jpg'
import img03 from '../ourFeatures/Images/img03.png'

const Index = () => {
  return (
    <Box className="master-feature">
      <Container sx={{ maxWidth:'1200px !important',px:{xs:'15px',sm:'100px' ,md:'auto'}}}>
        <Box sx={{ marginTop: { xs: "40px", md: "60px" } }}>
          <Typography variant="h2" className="heading-feature">
            Our Features
          </Typography>
        </Box>
        <Grid container spacing={{ md: 8, xs: 4 }}>
          <Grid item md={4} xs={12}>
            <Box sx={{ marginTop: { xs: "0px", md: "50px" } }}>
              <Box className="img-block">
                <img
                  src={img01}
                  alt="Our Features"
                  width="100%"
                  height="100%"
                />
              </Box>
              <Typography className="feature-text" mt={3}>
                One single dashboard to account for all your music
              </Typography>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box sx={{ marginTop: { xs: "0px", md: "50px" } }}>
              <Box className="img-block">
                <img
                  src={img02}
                  alt="Our Features"
                  width="100%"
                  height="100%"
                />
              </Box>
              <Typography className="feature-text" mt={3}>
              Distribution on 60+ music streaming platforms
              </Typography>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box sx={{ marginTop: { xs: "0px", md: "50px" } }}>
              <Box className="img-block">
                <img
                  src={img03}
                  alt="Our Features"
                  width="100%"
                  height="150%"
                />
              </Box>
              <Typography className="feature-text" mt={3}>
              Detailed royalty report & regular payouts
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Index;

import { Box, Checkbox, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";

const Index = ({
  checkeddeclaration,
  setCheckeddeclaration,
  handledeclarationSubmit,
}) => {
  const handleCheckboxChange = (event) => {
    setCheckeddeclaration({
      ...checkeddeclaration,
      [event.target.name]: event.target.checked,
    });
  };
  return (
    <>
      <Box sx={{ my: "30px", background: " " }}>
        <Box
          sx={{
            background: "rgba(255, 255, 255, 0.05) !important",
            height: "auto",
            padding: "30px",
            borderRadius: "8px",
            mt: "28px",
          }}
        >
          <Box>
            <form>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  className="checkbox"
                  name="artwork"
                  checked={checkeddeclaration.artwork}
                  checkeddeclaration={checkeddeclaration.artwork}
                  onChange={handleCheckboxChange}
                  inputProps={{ "aria-label": "controlled" }}
                  required
                  sx={{
                    color: "#fac915 !important",
                    "&.Mui-checkeddeclaration": {
                      color: "#fac915 !important",
                    },
                    mr: 1,
                  }}
                />

                <Typography sx={{ fontFamily: "DM sans !important" }}>
                  {" "}
                  I agree that my artwork has proper & exact artist and song
                  name written over it.
                </Typography>
              </Box>
              <br />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  name="declaration"
                  checked={checkeddeclaration.declaration}
                  checkeddeclaration={checkeddeclaration.declaration}
                  onChange={handleCheckboxChange}
                  inputProps={{ "aria-label": "controlled" }}
                  required
                  sx={{
                    color: "#fac915 !important",
                    "&.Mui-checkeddeclaration": {
                      color: "#fac915 !important",
                    },
                    mr: 1,
                  }}
                />

                <Typography sx={{ fontFamily: "DM sans !important" }}>
                  {" "}
                  I agree that my song will be rejected if my artwork is found
                  with any text other then song & artist name.
                </Typography>
              </Box>
              <br />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  name="copyrighted"
                  checked={checkeddeclaration.copyrighted}
                  checkeddeclaration={checkeddeclaration.copyrighted}
                  onChange={handleCheckboxChange}
                  inputProps={{ "aria-label": "controlled" }}
                  required
                  sx={{
                    color: "#fac915 !important",
                    "&.Mui-checkeddeclaration": {
                      color: "#fac915 !important",
                    },
                    mr: 1,
                  }}
                />

                <Typography sx={{ fontFamily: "DM sans !important" }}>
                  {" "}
                  I agree that usage of copyrighted pictures including those of
                  public figures/celebrities/famous personalities are not
                  allowed.
                </Typography>
              </Box>
              <br />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  name="lyrics"
                  checked={checkeddeclaration.lyrics}
                  checkeddeclaration={checkeddeclaration.lyrics}
                  onChange={handleCheckboxChange}
                  inputProps={{ "aria-label": "controlled" }}
                  required
                  sx={{
                    color: "#fac915 !important",
                    "&.Mui-checkeddeclaration": {
                      color: "#fac915 !important",
                    },
                    mr: 1,
                  }}
                />

                <Typography sx={{ fontFamily: "DM sans !important" }}>
                  {" "}
                  I agree that my song will be rejected if artwork has lyrics,
                  producer name, contact details, social media icons, text like
                  Audio/Video, release date etc written over it.
                </Typography>
              </Box>
              <br />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  name="composition"
                  checked={checkeddeclaration.composition}
                  checkeddeclaration={checkeddeclaration.composition}
                  onChange={handleCheckboxChange}
                  inputProps={{ "aria-label": "controlled" }}
                  required
                  sx={{
                    color: "#fac915 !important",
                    "&.Mui-checkeddeclaration": {
                      color: "#fac915 !important",
                    },
                    mr: 1,
                  }}
                />

                <Typography sx={{ fontFamily: "DM sans !important" }}>
                  {" "}
                  I agree that copyrighted songs/music/tune/lyrics/composition
                  etc are not allowed. If found, my royalties and all sorts of
                  payment will be forfeited.
                </Typography>
              </Box>
              <br />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  name="pause"
                  checked={checkeddeclaration.pause}
                  checkeddeclaration={checkeddeclaration.pause}
                  onChange={handleCheckboxChange}
                  inputProps={{ "aria-label": "controlled" }}
                  required
                  sx={{
                    color: "#fac915 !important",
                    "&.Mui-checkeddeclaration": {
                      color: "#fac915 !important",
                    },
                    mr: 1,
                  }}
                />

                <Typography sx={{ fontFamily: "DM sans !important" }}>
                  {" "}
                  I agree that my song does not have a pause/silence of more
                  than 8 seconds and my song length is between 1-12 minutes
                  only. I also agree that my song is not ending
                  abruptly/suddenly.
                </Typography>
              </Box>
              <br />
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Index;

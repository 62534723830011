import React, { useState } from "react";
import { Typography } from "@mui/material";
import Logo from "../../assets/sidebarImages/logo.svg";
// import "./Sidebar.scss";
import { sidebarData } from "./sidebarData";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { ImCancelCircle } from "react-icons/im";
import { IoMdArrowDropdown } from "react-icons/io";
import { RiLogoutCircleRLine } from "react-icons/ri";
import "./Sidebar.css";
import axios from "axios";
import { useEffect } from "react";
import { setSelectedMenu } from "../../redux/slices/sidebar";
import { useDispatch, useSelector } from "react-redux";
const Sidebar = ({ childern, ...props }) => {
  const dispatch = useDispatch();
  const selectedMenu = useSelector((state) => state.sidebar.selectedMenu);
  const navigate = useNavigate();
  const location = useLocation();
  const { openSidebar, toggleSidebar } = props;
  const [isOpen, setIsOpen] = useState("");
  // const toggle = () => setIsOpen(!isOpen);
  const handleLogout = () => {
    localStorage.removeItem("login");
    localStorage.removeItem("caution");
    navigate("/login");
  };
  const handlemove = () => {
    navigate("/contacttable");
  };

  const [data, setData] = useState([]);
  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;
  const userid = loginToken?.user._id;

  async function getHistory() {
    await axios
      .get(`${process.env.REACT_APP_DMT_API_PATH}/creative/check/${userid}`)
      .then((res) => {
        setData(res?.data?.data[0]?.contact).reverse();
      })
      .catch((err) => {});
  }

  useEffect(() => {
    if(!selectedMenu){
      dispatch(setSelectedMenu(location.pathname))
    }
    getHistory();
  }, []);

  return (
    <div className={openSidebar ? "sidebar open" : "sidebar"}>
      <div className="top_section">
        <div className="logo">
          <Link to="/artist/dashboard">
            <img src={Logo} alt="" />
          </Link>
          <ImCancelCircle onClick={toggleSidebar} className="toggle-icon" />
        </div>
      </div>
      <div className="bottom-section" onClick={toggleSidebar}>
        {sidebarData.map((item, index) => {
          if (!item?.subNav) {
            return (
              <NavLink
                to={item.path}
                key={index}
                className={({ isActive }) =>
                  isActive ? "link active" : "link"
                }
                onClick={() =>{
                    const menu  = (selectedMenu === item?.name || item?.subNav?.map((nav)=>nav.path)?.includes(selectedMenu)) ? null : item?.name ;
                    dispatch(setSelectedMenu(menu))
                  }}
              >
                <div className="icon">
                  {/* <img src={item.icon} alt="icon" /> */}
                  {item.icon}
                </div>
                <div className="name">{item.name}</div>
              </NavLink>
            );
          }
          if (item.subNav) {
            return (
              <div>
                <div
                  className="link"
                  onClick={() =>{
                    const menu  = (selectedMenu === item?.name || item?.subNav?.map((nav)=>nav.path)?.includes(selectedMenu)) ? null : item?.name ;
                    dispatch(setSelectedMenu(menu))
                  }
                  }
                >
                  <div className="icon">
                    {/* <img src={item.icon} alt="icon" /> */}
                    {item.icon}
                  </div>

                  <div className="name">{item.name}</div>
                  <IoMdArrowDropdown className="dropDownMenu" />
                </div>
                <div className="sidemenu">
                  { (selectedMenu === item?.name || item?.subNav?.map((nav)=>nav.path)?.includes(selectedMenu) ) &&
                    item.subNav.map((subItem, subIndex) => (
                        <NavLink
                          to={subItem.path}
                          key={subIndex}
                          className={({ isActive }) =>
                            isActive ? "link active" : "link"
                          }
                        >
                          <div className="icon">
                            {/* <img src={subItem.icon} alt="icon" /> */}
                            {/* {subItem.icon} */}
                          </div>
                          <div className="name">{subItem.name}</div>
                        </NavLink>
                    ))}
                </div>
              </div>
            );
          }
        })}
        {/* <div className="logout" onClick={handlemove}>
          <AiFillContacts />
          <Typography className="text">Portfolio Enquiries</Typography>
        </div> */}
        <div className="logout" onClick={handleLogout}>
          <RiLogoutCircleRLine />
          <Typography className="text">Logout</Typography>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { useState } from "react";

const Toggle = ({handleShow,checked}) => {
  
  const Setthing={
 checked:checked
  }
     

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
      {...Setthing}
    />
  ))(({ theme }) => ({
    width: 46,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#fb8e0b" : "#fb8e0b",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#cccccc" : "#cccccc",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <Container sx={{ mt: "50px" }}>
      <Box sx={{ display: "flex", justifyContent: "center" ,}}>
        <Box>
          <Typography
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              fontFamily: "DM Sans",
              color: "#000",
              fontSize: "0.9375rem",
              margin:"auto"
            }}
          >
            Single Album Pricing
          </Typography>
        </Box>
        <Box sx={{ marginLeft: "20px", marginTop: "-5px"  }}>
          {" "}
          <FormControlLabel  
            onChange={handleShow}
            control={<IOSSwitch sx={{ m: 1 }} />}
            
          />
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              fontFamily: "DM Sans",
              color: "#000",
              marginLeft: "-77px",
              fontSize: "0.9375rem",
              margin:"auto"
            }}
          >
            Multi Album Pricing
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Toggle;

import React from "react";
import music from "../../assets/img/music.svg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./audio.css";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

const Master_Data = [
  {
    id: 1,
    img: music,
    title: "Sample Before Mastered Audio",
    text: "By selecting below option, your song will be sent for mastering purpose.",
  },

  {
    id: 2,
    img: music,
    title: "Sample After Mastered Audio",
    text: "Your mastered audio will be sent to platforms and you can download the same from your dashboard as well.",
  },
];

const Tracks = ({
  value,
  setFormData,
  formData,
  setTracktomaster,
  tracktomaster,
  career,
  firstslidedata,
}) => {
  const [checked, setChecked] = React.useState(false);
  const handleChangedata = (event) => {
    setChecked(event.target.checked);
    setTracktomaster(event.target.checked);
  };

  return (
    <Card className="tracks" sx={{ background: "rgba(255, 255, 255, 0.05)" }}>
      <Typography
        variant="h6"
        className="all"
        sx={{
          fontSize: { xs: "18px !important", sm: "24px !important" },
          lineHeight: { xs: "23px !important", sm: "31px !important" },
          marginBottom: { xs: "24px !important", sm: "32px !important" },
        }}
      >
        Do you want your all tracks to be mastered?
      </Typography>
      <Grid container spacing={3.5}>
        {Master_Data.map((value, index) => (
          <Grid item key={index} xs={12} md={6}>
            <Paper elevation={10} className="sample">
              <Grid container spacing={2}>
                <Grid item xs={3.8} sm={2} md={3.5} lg={2.4}>
                  <Typography
                    className="track"
                    component="img"
                    src={value.img}
                    alt="music"
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={8.2}
                  direction="row"
                  sm={10}
                  md={8.5}
                  lg={9.6}
                >
                  <Grid item xs={12}>
                    <Typography
                      component="div"
                      variant="body2"
                      className="before"
                      sx={{
                        fontSize: {
                          xs: "14px !important",
                          sm: "18px !important",
                        },
                        lineHeight: {
                          xs: "18px !important",
                          sm: "23px !important",
                        },
                      }}
                    >
                      {value.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      component="div"
                      variant="body5"
                      className="amet"
                    >
                      {value.text}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          md={4.3}
          lg={4}
          sx={{ marginTop: { xs: "12px", md: "32px" } }}
          className="share"
        >
          <Checkbox
            checked={tracktomaster}
            onChange={handleChangedata}
            inputProps={{ "aria-label": "controlled" }}
            sx={{
              width: "20px",
              marginRight: "12px",
              color: "#ffffff",
              "&.Mui-checked": {
                color: "#fa9e0d",
              },
            }}
          />
          <Typography
            sx={{
              fontSize: { xs: "14px", sm: "18px" },
              lineHeight: { xs: "18px", sm: "23px" },
            }}
            className="title"
            variant="body2"
          >
            Yes, I want to do my song Mastered
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={2.2}
          lg={1.5}
          sx={{ marginTop: { xs: "12px", md: "32px" } }}
          className="share"
        >
          {/* <Typography
            sx={{
              padding: "5px 20px",
              borderRadius: "33px",
              fontSize: { xs: "12px", sm: "14px" },
              backgroundColor: "#29261d",
              lineHeight: { xs: "16px", sm: "18px", color: "#faab10" },
            }}
            className="only"
            variant="body4"
          >
            Only &nbsp; &#8377; 299
          </Typography> */}
        </Grid>
        <Grid
          item
          xs={12}
          md={5.5}
          lg={6.5}
          sx={{ marginTop: { xs: "12px", md: "32px" } }}
          className="share"
        >
          <Typography
            sx={{
              fontSize: { xs: "8px", sm: "14px" },
              lineHeight: { xs: "10px", sm: "18px" },
              fontFamily: "DM sans",
              fontWeight: 700,
            }}
            className="free"
          >
            Share your song distribution details on social media and we will
            refund your mastering amount making it{" "}
            <Typography variant="span" className="free1">
              FREE&#46;
            </Typography>
            &nbsp; Sharing option is available at the end of the form.
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Tracks;

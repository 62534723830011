import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import CustomButton from "../../common/customButton/index";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
// import "./banner.css";
import * as yup from "yup";
import { Typography } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import moment from "moment/moment";
import { ScaleLoader } from "react-spinners";
import "react-toastify/dist/ReactToastify.css";
// import './upcoming.css'

const Index = ({ handleClose, view, getOrder, number, tokenedit }) => {
  const validationSchema = yup.object().shape({
    testname: yup.string().min(2, "Too Short!").max(20, 'maximum 20 characters is allowed.').required("Required*"),
    description: yup
      .string()
      .min(50, "minimum 50 characters required.").max(110,'maximum 110 characters is allowed.')
      .required("Required*"),
    testdesign: yup.string().required("Required*"),
 
    rating: yup.string().required("Required*"),
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [AnotherImages, setAnotherImages] = useState({
    banner: view?.testimg || "",
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    testname: "",
    description: "",
    testdesign: "",
    rating: "",
  };
  const handleChnage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
  };
  useEffect(() => {
    if (AnotherImages?.banner) {
      setError("");
    }
  }, [AnotherImages]);

  useEffect(() => {
    if (view) {
      formik.setFieldValue("testname", view?.testname);
      formik.setFieldValue("description", view?.description);
      formik.setFieldValue("testdesign", view?.testdesign);
      formik.setFieldValue("rating", view?.rating);
      setImagePreview(view?.testimg);
    }
  }, []);

  const onSubmit = async (values) => {
    if (!AnotherImages?.banner) {
      setError("Please select an image file");
      return;
    }
    setIsLoading(true);
    const token = tokenedit;
    var formDatavalue = new FormData();
    formDatavalue.append("testname", values.testname);
    formDatavalue.append("description", values.description);
    formDatavalue.append("testdesign", values.testdesign);
    formDatavalue.append("rating", values.rating);
    formDatavalue.append("testimg", AnotherImages.banner);
    if (number == 0) {
      await axios
        .post(
          `${process.env.REACT_APP_DMT_API_PATH}/creative/testimonial`,
          formDatavalue,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error !== true) {
            getOrder();
            handleClose();
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (number == 1) {
      await axios
        .put(
          `${process.env.REACT_APP_DMT_API_PATH}/creative/testimonial/${view?._id}`,
          formDatavalue,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error !== true) {
            getOrder();
            handleClose();
            setIsLoading(false);
          } else {
            toast.error(res.data.message);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <>
      <Box id="contact" className='upcomingform'>
        <Container maxWidth="md" sx={{padding:"12px"}}>
          <Box
            component="form"
            sx={{ mt: "0px", width: { md: "500px", xs: "100%" } }}
            onSubmit={formik.handleSubmit}
          >
            {isLoading ? (
              <ScaleLoader
                size={350}
                textAlign="center"
                className="music-loader"
                color="#FBCE2E"
              />
            ) : (
              <>
                <Typography
                  sx={{
                    marginTop: "10px",
                    marginLeft: "5px",
                    color:"black"
                  }}
                >
                  Enter Name
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  placeholder="Enter Name"
                  {...formik.getFieldProps("testname")}
                  onChange={(e) => {
                    formik.setFieldValue("testname", e.target.value);
                  }}
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                    border: "1px solid black",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                      inputProps: { style: { color: 'black' }}
                  }}
                />
                {formik.touched.testname && formik.errors.testname ? (
                  <Typography
                    sx={{
                      textAlign: "start",
                      marginLeft: "4px",
                      fontSize: "12px",
                    }}
                    color="error"
                  >
                    {formik.errors.testname}
                  </Typography>
                ) : null}
                <Typography
              sx={{
                marginTop: "10px",
                marginLeft: "5px",marginBottom:"-15px",   color:"black"
              }}
            >
              Enter Description
            </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  minRows="2"
                  multiline
                  placeholder="Enter Description"
                  {...formik.getFieldProps("description")}
                  onChange={(e) => {
                    formik.setFieldValue("description", e.target.value);
                  }}
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                    mt: 2, 
                    border: "1px solid black",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                      inputProps: { style: { color: 'black' }}
                  }}
                />
                {formik.touched.description && formik.errors.description ? (
                  <Typography
                    sx={{
                      textAlign: "start",
                      marginLeft: "4px",
                      fontSize: "12px",
                    }}
                    color="error"
                  >
                    {formik.errors.description}
                  </Typography>
                ) : null}
                <Typography
                  sx={{
                    marginTop: "10px",
                    marginLeft: "5px",
                    color:"black"
                  }}
                >
                  Enter Designation
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  name="testdesign"
                  placeholder="Enter Title"
                  {...formik.getFieldProps("testdesign")}
                  onChange={(e) => {
                    formik.setFieldValue("testdesign", e.target.value);
                  }}
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                    border: "1px solid black",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                      inputProps: { style: { color: 'black' }}
                  }}
                />
                {formik.touched.testdesign && formik.errors.testdesign ? (
                  <Typography
                    sx={{
                      textAlign: "start",
                      marginLeft: "4px",
                      fontSize: "12px",
                    }}
                    color="error"
                  >
                    {formik.errors.testdesign}
                  </Typography>
                ) : null}
               
                <Typography
                  sx={{
                    marginTop: "10px",
                    marginLeft: "5px",   color:"black"
                  }}
                >
                  Enter Rating
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  name="rating"
                  placeholder="Enter Rating"
                  {...formik.getFieldProps("rating")}
                  onChange={(e) => {
                    formik.setFieldValue("rating", e.target.value);
                  }}
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                    border: "1px solid black",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                      inputProps: { style: { color: 'black' }}
                  }}
                />
                {formik.touched.rating && formik.errors.rating ? (
                  <Typography
                    sx={{
                      textAlign: "start",
                      marginLeft: "4px",
                      fontSize: "12px",
                    }}
                    color="error"
                  >
                    {formik.errors.rating}
                  </Typography>
                ) : null}
                <Typography
                  sx={{
                    marginTop: "10px",
                    marginBottom: "-12px",
                    marginLeft: "6px",   color:"black"
                  }}
                >
                  Upload Banner
                </Typography>
                <TextField
                  type="file"
                  name="banner"
                  sx={{ marginLeft: "-8px" }}
                  onChange={(e) => {
                    handleChnage(e);
                    setAnotherImages({
                      ...AnotherImages,
                      banner: e.target.files[0],
                    });
                  }}
                />
                {error && (
                  <Typography
                    sx={{
                      textAlign: "start",
                      marginLeft: "12px",
                      fontSize: "12px",
                      marginTop: "-8px",
                      border: "1px solid black",
                    }}
                    color="error"
                  >
                    {error}
                  </Typography>
                )}
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    width="50px"
                    height="50px"
                  />
                )}
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "2px",
                    marginBottom: "2px",
                  }}
                >
                  <CustomButton
                    buttonStyle="editFormBtnsecond"
                    btnText={view ? "Save" : "Add"}
                    btntype="Submit"
                  />
                </div>
              </>
            )}
          </Box>
        </Container>
        <ToastContainer />
      </Box>
    </>
  );
};

export default Index;

import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import raperOne from "../../../assets/img/raperOne.png";
import raperTwo from "../../../assets/img/raperTwo.png";
import speakerOne from "../../../assets/img/speakerOne.png";
import rapperGirl from "../../../assets/img/rapperGirl.png";
import rapperBoy from "../../../assets/img/rapperBoy.png";
import speakerTwo from "../../../assets/img/speakerTwo.png";
import wood from "../../../assets/img/wood.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import kameloc from "../../../assets/img/kameloc.png";
import { MdOutlineLocationOn } from "react-icons/md";
import { AiOutlineClockCircle } from "react-icons/ai";
import "./index.css";
import moment from "moment";
import { GrAdd } from "react-icons/gr";
import CustomButtonedit from "../editportfoliosecondbtn";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Eventform from "./concertEdit";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";

function convertTimeFormat(time) {
  var hours = parseInt(time.substr(0, 2));
  var minutes = parseInt(time.substr(3, 2));
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

function PaperComponent(props) {
  return <Paper sx={{ background: "#FF599D" }} {...props} />;
}

const Index = ({ data, getOrder, tokenedit }) => {
  const [viewmodal, setViewmodal] = useState([]);
  const [number, setNumber] = useState(0);
  const [open, setOpen] = React.useState(false);

  const handleOpen = (value) => {
    setOpen(true);
    setViewmodal(value);
  };

  const handleClose = () => setOpen(false);



  const slidesToShow = data?.events.length < 2 ? data?.events.length : 2;

  const settings = {
    fade: false,
    dots: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    initialSlide: 2,
    infinite: true,
    arrows: false,
    centerMode: true,
    speed: 500,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: slidesToShow,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
        },
      },

      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
          dots: false,
        },
      },
    ],
  };
  return (
    <>
      <Box
        sx={{ background: "#FCE23B", position: "relative", minHeight: "800px" }}
      >
        <Box sx={{ position: "absolute", mt: 2, ml: 2 }}>
          <img
            src={raperOne}
            alt=""
            width={"100%"}
            style={{ zIndex: "-1000000" }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: "50px",
            mt: 2,
            display: { xs: "none", md: "block " },
          }}
        >
          <img src={raperTwo} alt="" />
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: "20%",
            top: "50px",
            display: { xs: "none", md: "block" },
          }}
        >
          <img
            src={speakerOne}
            alt=""
            style={{ marginLeft: "30px", zIndex: "5" }}
          />
        </Box>
        <Container>
          <Box
            textAlign="center"
            sx={{ display: "flex", justifyContent: "center", pt: 8 }}
          >
            <Typography
              className="textStroke"
              sx={{
                color: "#00D8C0",
                fontSize: { xs: "40px", md: "64px" },
                zIndex: "5",
                fontFamily: "Anton",
                transform: "matrix(1, 0.01, -0.29, 0.96, 0, 0) ",
              }}
            >
              Upcoming concerts
            </Typography>
          </Box>

          <Box
            sx={{
              textAlign: "center",
              marginLeft: { md: "20px", xs: "0px" },
              marginTop: { lg: "15px", md: "15px", xs: "0px" },
            }}
          >
            {tokenedit ? (
              <CustomButtonedit
                btnText="Add"
                handleClick={() => {
                  handleOpen();
                  setNumber(0);
                }}
                startIcon={<AddIcon sx={{ marginRight: "5px" }} />}
                width="75px"
                height="30px"
                border="1px solid #ffffff !important"
                padding="15px"
              />
            ) : (
              ""
            )}
          </Box>

          <Box sx={{ mt: 8 }}>
            {data?.events?.length === 0 ? (
              <>
                <Box
                  sx={{
                    margin: "30px 0px",
                    padding: "0px !important",
                  }}
                >
                  {tokenedit ? (
                    <Box
                      sx={{
                        border: "2px dashed #00D8C0",
                        width: { md: "760px", xs: "280px" },
                        height: "210px !important",
                        textAlign: "center",
                        margin: "auto",
                      }}
                      onClick={() => {
                        handleOpen();
                        setNumber(0);
                      }}
                    >
                      <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                        <Typography
                          sx={{
                            color: "#00D8C0",
                            fontSize: "30px",
                            lineHeight: "20px",
                          }}
                        >
                          +
                        </Typography>
                        <Typography
                          sx={{
                            color: "#00D8C0",
                            fontSize: "20px",
                            fontWeight: "700",
                          }}
                        >
                          Add Your Upcoming Concerts Here
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        border: "2px dashed #00D8C0",
                        width: { md: "760px", xs: "280px" },
                        height: "210px !important",
                        textAlign: "center",
                        margin: "auto",
                      }}
                    >
                      <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                        {/* <Typography></Typography> */}
                        <Typography sx={{
                            color: "#00D8C0",
                            fontSize: "20px",
                            fontWeight: "700",
                          }}>Events is Empty</Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              <Slider className="concertslider" {...settings}>
                {data?.events?.map((item, index) => (
                  <Box textAlign={"-webkit-center"}>
                    <Card
                      key={index}
                      sx={{
                        background: "#FF599D",
                        p: { xs: 1, md: 2 },
                        maxWidth: "500px",
                        transform: "rotate(3deg)",
                        minHeight: "360px",
                        zIndex: "5000",
                        m: 2,
                      }}
                    >
                      <Box sx={{ display: { xs: "block", sm: "flex" } }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // transform: " rotate(-3deg)",
                            mr: 1.5,
                            width: { xs: "200px", sm: "auto" },
                            height: { xs: "200px", sm: "auto" },
                          }}
                        >
                          <img
                            src={item?.eventimage}
                            className="rappergirl"
                            alt=""
                            height="100%"
                          />
                        </Box>
                        <Box textAlign={"left"}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "16px",
                                fontFamily: "Anton",
                                color: "#141414",
                                mt: 1,
                              }}
                            >
                              {moment(item?.eventDate).format("ll")}
                            </Typography>
                            <Box
                              sx={{
                                marginLeft: {
                                  lg: "70px",
                                  xs: "10px !important",
                                },

                                display: { lg: "block", xs: "flex" },
                              }}
                            >
                              {tokenedit ? (
                                <CustomButtonedit
                                  handleClick={() => {
                                    handleOpen(item);
                                    setNumber(1);
                                  }}
                                  startIcon={
                                    <ModeEditOutlinedIcon
                                      sx={{ marginRight: "5px" }}
                                    />
                                  }
                                  width="20px !important"
                                  height="20px !important"
                                  border="1px solid #00D8C0 !important"
                                  padding="15px"
                                />
                              ) : (
                                ""
                              )}
                            </Box>
                          </Box>
                          <Typography
                            sx={{
                              fontSize: "26px",
                              lineHeight: "32px",
                              fontFamily: "Anton",
                              color: "#00D8C0",
                              mt: 2,
                            }}
                          >
                            {item?.eventtitle}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "18px",
                              lineHeight: "30px",
                              fontFamily: "Anton",
                              color: "#141414",
                              mt: 1,
                              wordBreak:'break-all'
                            }}
                          >
                            {item?.eventdesc}
                          </Typography>
                          <Box sx={{ display: "flex", mt: 1.5 }}>
                            <MdOutlineLocationOn
                              color="#00D8C0"
                              fontSize="22px"
                            />
                            <Typography
                              sx={{
                                fontSize: "14px",
                                ml: 2,
                                lineHeight: "16px",
                                fontFamily: "Anton",
                                color: "#141414",
                              }}
                            >
                              {item?.eventlocation}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", mt: 1.5, mb: 3 }}>
                            <AiOutlineClockCircle
                              color="#00D8C0"
                              fontSize="18px"
                            />
                            <Typography
                              sx={{
                                fontSize: "14px",
                                ml: 2,
                                lineHeight: "16px",
                                fontFamily: "Anton",
                                color: "#141414",
                              }}
                            >
                              {convertTimeFormat(item?.eventtime)}
                            </Typography>
                          </Box>
                          <a href={item?.eventlink} target='_blank'>
                          <Button
                            className="hire-event-data2"
                            sx={{ width: { xs: "150px", sm: "217px" } }}
                          >
                            Learn More
                          </Button>
                          </a>
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                ))}
              </Slider>
            )}
          </Box>
        </Container>

        <Box sx={{ mt: 5, ml: { xs: 5, md: 12 } }}>
          <img src={speakerTwo} alt="" style={{ zIndex: "-1000000" }} />
        </Box>
        <Box
          sx={{
            position: "absolute",
            left: "43%",
            right: "43%",
            bottom: "-6.5px",
            display: { xs: "none", md: "block" },
          }}
        >
          <img src={wood} alt="" width={"400px"} />
        </Box>
      </Box>

      <div>
        <Dialog
          height="auto"
          style={{ zIndex: "9999922" }}
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <Box textAlign="end">
            <IconButton onClick={handleClose}>
              <CloseIcon color="black" />
            </IconButton>
            <DialogTitle
              style={{ cursor: "move" }}
              align="center"
              mt="-50px"
              id="draggable-dialog-title"
            
            >
            <Typography   sx={{color:"black !important"}}>
              Events
              </Typography>
            </DialogTitle>
          </Box>

          <Eventform
            view={viewmodal}
            handleClose={handleClose}
            getOrder={getOrder}
            number={number}
            tokenedit={tokenedit}
          />
        </Dialog>
      </div>
    </>
  );
};

export default Index;

import React from "react";
import { Box, TextField, Button, Card, Typography } from "@mui/material";
import { Container } from "@mui/system";
import ClearIcon from "@mui/icons-material/Clear";

import "../changePWDbyEmail/Psd.css";

import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Navbar from "../common/navbar/index";
import Footer from "../common/footer/index";
import { useSearchParams,useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

// import Navbar from '../common/navbar/index';

// import EmailPassword from '../../components/changePWDbyEmail/ChangePWDbyEmail'
function ChangePWDbyEmail() {
    const navigate=useNavigate();
  const themeLight = createTheme({
    palette: {
      mode: "light",
    },
  });
  const seachQuery = useSearchParams()[0];

  const Tokenforget = seachQuery.get("token");
  const initialValues = {
    passWord: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    passWord: Yup.string()
      .min(6, "Password must have at least 6 characters")
      .required("Password is required"),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref("passWord"), null], "Password must match")
      .required("Confirm password is required"),
  });

  const onSubmit = async (values) => {
    const payload = {
      password: values.passWord,
      token: Tokenforget,
    };
    await axios
      .post(
        `${process.env.REACT_APP_DMT_API_PATH}/authentication/local/reset`,
        payload
      )
      .then((res) => {
        if (res.data.error === true) {
          toast.error(res?.data?.message);
        } else {
          navigate("/login");
        }
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <>
      <ThemeProvider theme={themeLight}>
        <CssBaseline />
        <Navbar />
        <Container sx={{ mt: 10 }}>
          <Formik>
            <Form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
              <Card elevation={15} id="psd-card">
                <Box id="psd-card-box">
                  <Box>
                    <Typography id="pwd-typography"> New Password </Typography> 

                    <TextField
                      variant="outlined"
                      placeholder="New Password"
                      type="password"
                      fullWidth
                      id="pwd-textfield"
                      name="passWord"
                      value={formik.values.passWord}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{ border: "1px solid #1b1b38", borderRadius: "4px" }}
                    />

                    {formik.touched.passWord && formik.errors.passWord ? (
                      <Typography color="error">
                        {formik.errors.passWord}
                      </Typography>
                    ) : null}
                  </Box>

                  <Box>
                    <Typography id="pwd-typography">
                      {" "}
                      Confirm Password{" "}
                    </Typography>

                    <TextField
                      variant="outlined"
                      placeholder="Confirm Password"
                      type="password"
                      fullWidth
                      id="pwd-textfield"
                      name="confirmPassword"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{ border: "1px solid #1b1b38", borderRadius: "4px" }}
                    />

                    {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword ? (
                      <Typography color="error">
                        {formik.errors.confirmPassword}
                      </Typography>
                    ) : null}
                  </Box>
                  {/* ----------For Value Restoring-------------- */}
                  <Box id="psd-button">
                    {/* <Button type='reset'
                                    variant='contained'
                                    disabled={!formik.isValid}
                                    startIcon={<ClearIcon />}
                                    sx={{ marginTop: '20px' }}

                                >RESET</Button> */}
                    {/* ----------For Value Restoring-------------- */}

                    {/* ----------For Value Submition-------------- */}
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={!formik.isValid}
                      startIcon={<ClearIcon />}
                      sx={{
                        marginTop: "20px",
                        backgroundColor: "#fb8e0b",
                        ":hover": {
                          backgroundColor: "#fb8e0b",
                        },
                      }}
                    >
                      Reset
                    </Button>
                    {/* ----------For Value Submition-------------- */}
                  </Box>
                </Box>
              </Card>
            </Form>
          </Formik>
        </Container>
        <Footer />
      </ThemeProvider>
      <div>
        <ToastContainer />
      </div>
    </>
  );
}

export default ChangePWDbyEmail;

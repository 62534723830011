import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

import { Typography } from "@mui/material";

const Help = () => {
  return (
    <Box className="custom-dmt-container">
       
      <Card sx={{ padding:{md:"30px",xs:"10px"},borderRadius:"10px" }}>
      <Typography   sx={{textAlign:"center ",fontSize:"1.2rem !important",lineHeight:"1.5rem",fontFamily:"DM Sans"}}>
      Kindly send an email to <a href="mailto:contact@delivermytune.com" rel="noreferrer" target='_blank' style={{ color: '#f1a110', textDecoration: 'none' }}><span style={{ opacity: '0.7' }}>contact@delivermytune.com</span></a>   for all your queries. <br/>
      Our team will reply via email itself

      {/* <Link className="linkpage" > contact@delivermytune.com </Link> */}
      </Typography>
   
      </Card>
    </Box>
  );
};

export default Help;
import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { AiOutlineRightCircle } from "react-icons/ai";
import './card.css'
import { Link } from "react-router-dom";

const Index = ({ detalis,releasesong,setReleasesong }) => {
  return (
    <>
      <Card sx={{ maxWidth: "280px", borderRadius: "10px" ,mb:5}}>
        <Link to={detalis.path} className='dashcard-link'>
        <CardContent sx={{paddingX:{xs:'8px', sm:'10px'}, paddingY:{xs:'15px',md:"12px"}}}>
          <Box sx={{ display: "flex" }}>
            <img  className='dashboard-card-img'  src={detalis.img} width="41px" height="41px" alt="" />
            <Typography
             className='dashboard-card-title' 
              variant="p"
              ml={2}
              mt={{xs:0,md:1.5}}
              sx={{ color: " #E1E1E1", fontSize: { xs: "12px", md: "16px" } }}
              fontWeight="700"
            >
              {detalis.title}
            </Typography>
          </Box>
          <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between", alignItems:'baseline' }}>
            <Box sx={{ display: "inline-block" }}>
              {/* <CurrencyRupeeIcon
                color="white"
                fontWeight="700"
                sx={{ fontSize: { xs: "24px", sm: "24px", md: "30px" }, pt: 0 }}
              /> */}
              {/* <Icon src={detalis?.icons} /> */}
             
              <Typography
                variant="span"
                sx={{ mt: "-10px" }}
                fontSize={{ xs: "20px", md: "30px" }}
              >
                 {detalis.icon} {detalis.numbers}
              </Typography>
            </Box>

            <Box sx={{ display: "inline-block" }}>
              <Box  className='dashboard-icon-box'
                sx={{
                  display: "inline-block",
                  background:
                    "linear-gradient(180deg, #FAC915 0%, #FB900B 100%)",
                  marginTop: "9px",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  display: { xs: "flex" },
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AiOutlineRightCircle
                  color="black" className =' dashboard-card-icon'
                  sx={{ fontSize: { xs: "10px !important", md: "18px" } }}
                />
              </Box>
            </Box>
          </Box>
        </CardContent>
        </Link>
      </Card>
    </>
  );
};

export default Index;

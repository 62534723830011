import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./summary.css";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import CustomButton from "../common/customButton/index";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Plan from "../plan";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DoneAllIcon from "@mui/icons-material/DoneAll";

const Billing = [
  {
    id: 1,
    name: "Yuvraj Patel",
    phone: "+91 9340574158",
    email: "evdt.yuvrajpatel@gmail.com",
    address:
      "Infront of Fitness Mantra Gym, Plot No. 667/7, Second Floor Ward, 02, DDM Rd, Korba, Chhattisgarh 495677",
  },
];

const Summary = [
  {
    id: 1,
    donate: "Donate for Manzil",
    center:
      "Manzil Is A Non-profit, Youth-Led Learning Center. Manzil is Providing A Supportive Community And Resources For Students To Empower Themselves. One Another Through Learning, Teaching, Creativity And Encouraging Each Other To See The World In New Ways",
  },
];

const Data = [
  {
    id: 1,
    subtotal: 1999,
    service: 10,
    donation: "10",
    discount: "20",
    total: "1989",
  },
];

const ExpandMore = styled((props, value) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard({
  value,
  formData,
  setFormData,
  paymentdetail,
  proceedpayment,
  setAmount,
  amount,
  paymentget,
  totalamount,
  setTotalamount,
  billshow,
  setBillshow,
  setShowuserdata,
  setDistribution_type,
  walletamount,
  setWalletamount,
  donate,
  setDonateValue,
}) {
  const [coupon, setCoupon] = useState({ couponcheck: "" });
  const [paymentdata, setPaymentdata] = useState(0);
  const [discountamount, setDiscountamount] = useState(0);
  const [discountshow, setDiscountshow] = useState(0);
  const [phoneverify, setFormVerify] = useState({
    phone: "",
    otp: "",
  });
  const [wallet, setWallet] = useState();
  const handlecheckcoupon = async () => {
    const loginToken = JSON.parse(localStorage.getItem("login"));
    const token = loginToken.token;

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const payload = {
      code: coupon.couponcheck,
      order_id: paymentget?.data?.order_id,
      totalamount:paymentdata
    };
    await axios
      .post(
        `${process.env.REACT_APP_DMT_API_PATH}/coupons/apply`,
        payload,
        config
      )
      .then((res) => {
        if (res.data.error !== true) {
          setDiscountamount(res.data);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {});
  };
  const [expanded, setExpanded] = React.useState(true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [expand, setExpand] = React.useState(true);
  const handleExpandClickOn = () => {
    setExpand(!expand);
  };

  const [checked, setChecked] = React.useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked === true) {
      setDonateValue(10);
    } else if (event.target.checked === false) {
      setDonateValue(0);
    }
  };

  const [checkedvalue, setCheckedvalue] = React.useState(false);
  const handleChangedvalue = (event) => {
    setCheckedvalue(event.target.checked);
    if (event.target.checked === true) {
      if (wallet > totalamount) {
        setWalletamount(totalamount);
      }else if(wallet <= totalamount){
        setWalletamount(wallet); 
      }
    } else {
      setWalletamount(0);
    }
  };
  useEffect(() => {
    if (walletamount) {
      const valueamount = totalamount;
      setTotalamount(valueamount - walletamount);
    }
  }, [walletamount]);

  const loginToken = JSON.parse(localStorage.getItem("login"));
  const userid = loginToken.user._id;
  const config = {
    headers: {
      Authorization: `Bearer ${loginToken.token}`,
    },
  };
  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);
  async function getContact() {
    await axios
      .get(`${process.env.REACT_APP_DMT_API_PATH}/users`, config)
      .then((respose) => {
        if (respose.data.error !== true) {
          setData(respose.data.data);
          setShowuserdata(respose.data.data);
        }
      })
      .catch((err) => {});
  }
  useEffect(() => {
    getContact();
  }, []);
  async function getCredit() {
    await axios
      .get(`${process.env.REACT_APP_DMT_API_PATH}/wallet/?user_id=${userid}`)
      .then((res) => {
        console.log(res.data.data.total[0].total);
        setWallet(res.data.data.total[0].total);
        setShow(res.data.data.total[0].total);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getCredit();
  }, []);
  const [servicecharge, setServicecharge] = useState(0);
  let newTotal;
  useEffect(() => {
    if (paymentget?.additional_amount) {
      setServicecharge(0);
    }
    if (
      discountamount?.error === false &&
      discountamount?.data?.category === "distribution"
    ) {
      newTotal = discountamount?.data?.amount + servicecharge + donate;
      setTotalamount(newTotal);
      setDiscountshow(paymentdata - discountamount?.data?.amount);
    } else {
      newTotal = paymentdata + servicecharge + donate;
      setTotalamount(newTotal);
      setDiscountshow(0);
    }

    if (walletamount) {
      setTotalamount(newTotal - walletamount);
    }
  }, [
    totalamount,
    paymentdata,
    donate,
    newTotal,
    walletamount,
    discountamount,
    discountshow,
    paymentget,
    servicecharge,
  ]);
  return (
    <>
      <Plan
        setFormData={setFormData}
        formData={formData}
        paymentdetail={paymentdetail}
        setPaymentdata={setPaymentdata}
        paymentdata={paymentdata}
        paymentget={paymentget}
        setBillshow={setBillshow}
        data={data}
        setDistribution_type={setDistribution_type}
      />
      {billshow ? (
        <Card
          className="platform"
          sx={{ background: "rgba(255, 255, 255, 0.05)" }}
        >
          <Typography
            variant="h6"
            className="bill"
            sx={{
              marginBottom: { xs: "24px !important", sm: "32px !important" },
            }}
          >
            Choose Platform
          </Typography>
          <Typography
            variant="h6"
            className="bill"
            sx={{
              marginBottom: { xs: "24px !important", sm: "32px !important" },
            }}
          >
            Billing Summary
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <Paper
                className="manzil"
                elevation={5}
                sx={{ marginBottom: { xs: "16px", md: "0px" } }}
              >
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={11}
                    sx={{ padding: "0px 0px 20px 20px !important" }}
                  >
                    <CardContent
                      sx={{ padding: "20px 0px 0px 0px !important" }}
                    >
                      <label for="billing">
                        <Typography
                          variant="body1"
                          className="head"
                          sx={{
                            fontSize: {
                              xs: "14.41px !important",
                              sm: "24px !important",
                            },
                            lineHeight: {
                              xs: "19px !important",
                              sm: "10px !important",
                            },
                          }}
                        >
                          Billing Details
                        </Typography>
                      </label>
                    </CardContent>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "0px !important",
                      alignItems: "center",
                    }}
                  >
                    <CardActions disableSpacing>
                      <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        id="billing"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>
                  </Grid>
                </Grid>

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  {Billing.map((value, index) => (
                    <Grid key={index} container spacing={1}>
                      <Grid
                        item
                        xs={5}
                        sx={{
                          borderBottom: "1px solid #626262",
                          paddingBottom: { xs: "0px", md: "5px" },
                        }}
                      >
                        <Typography variant="paragraph" className="names">
                          Name
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        sx={{
                          borderBottom: "1px solid #626262",
                          wordBreak: "break-all",
                          paddingBottom: { xs: "0px", md: "5px" },
                        }}
                      >
                        <Typography variant="paragraph" className="price">
                          {data?.name?.first} {data?.name?.last}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={5}
                        sx={{
                          borderBottom: "1px solid #626262",
                          paddingBottom: { xs: "0px", md: "5px" },
                        }}
                      >
                        <Typography variant="paragraph" className="names">
                          Phone
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={7}
                        sx={{
                          borderBottom: "1px solid #626262",
                          wordBreak: "break-all",
                          paddingBottom: { xs: "0px", md: "5px" },
                        }}
                      >
                        {data?.phone ? (
                          <Typography variant="paragraph" className="price">
                            {data?.phone} &nbsp;{" "}
                            <DoneAllIcon sx={{ color: "#33D67C" }} />
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        sx={{
                          borderBottom: "1px solid #626262",
                          paddingBottom: { xs: "0px", md: "5px" },
                        }}
                      >
                        <Typography variant="paragraph" className="names">
                          Email
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        sx={{
                          borderBottom: "1px solid #626262",
                          wordBreak: "break-all",
                          paddingBottom: { xs: "0px", md: "5px" },
                        }}
                      >
                        <Typography variant="paragraph" className="price">
                          {data?.email ? data?.email : ""}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        sx={{
                          borderBottom: "1px solid #626262",
                          paddingBottom: { xs: "0px", md: "5px" },
                        }}
                      >
                        <Typography variant="paragraph" className="names">
                          Address
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        sx={{
                          borderBottom: "1px solid #626262",
                          wordBreak: "break-all",
                          paddingBottom: { xs: "0px", md: "5px" },
                        }}
                      >
                        <Typography variant="paragraph" className="price">
                          {data?.address?.line1 && data?.address?.pincode
                            ? data?.address?.line1 +
                              " " +
                              data?.address?.pincode
                            : data?.address?.line1
                            ? data?.address?.line1
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Collapse>
              </Paper>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Paper className="manzil" elevation={5}>
                <Grid container spacing={3.5}>
                  <Grid
                    item
                    xs={11}
                    sx={{ padding: "0px 0px 20px 20px !important" }}
                  >
                    <CardContent
                      sx={{ padding: "20px 20px 0px 0px !important" }}
                    >
                      <label for="details">
                        <Typography
                          variant="body1"
                          className="head"
                          sx={{
                            fontSize: {
                              xs: "14.41px !important",
                              sm: "24px !important",
                            },
                            lineHeight: {
                              xs: "19px !important",
                              sm: "20px !important",
                              paddingTop: "-10px",
                            },
                          }}
                        >
                          Summary
                        </Typography>
                      </label>
                    </CardContent>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      padding: "0px !important",
                    }}
                  >
                    <CardActions disableSpacing>
                      <ExpandMore
                        expand={expand}
                        onClick={handleExpandClickOn}
                        aria-expanded={expand}
                        aria-label="show more"
                        id="details"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>
                  </Grid>
                </Grid>

                <Collapse in={expand} timeout="auto" unmountOnExit>
                  {Summary.map((value, index) => (
                    <Box sx={{ padding: "0px !important" }}>
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={checked}
                          onChange={handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{
                            width: "20px",
                            marginRight: "12px",
                            color: "#ffffff",
                            "&.Mui-checked": {
                              color: "#fa9e0d",
                            },
                          }}
                        />
                        <Typography variant="paragraph" className="names">
                          {value.donate}
                        </Typography>
                      </Box>
                      <Typography variant="paragraph" className="price">
                        {value.center}
                      </Typography>
                    </Box>
                  ))}
                </Collapse>
              </Paper>
              <Box>
                <Card
                  elevation={5}
                  className="manzil"
                  sx={{
                    marginTop: { xs: "16px", sm: "32px" },
                    position: "relative !important",
                  }}
                >
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    value={coupon?.couponcheck}
                    onChange={(event) =>
                      setCoupon({ ...coupon, couponcheck: event.target.value })
                    }
                    label=""
                    sx={{
                      background: "rgba(255, 255, 255, 0.05) !important",
                      height: "44px !important",
                      borderRadius: "5px",
                      p: 1,
                      fontFamily: "DM Sans",
                    }}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      background: "rgba(255, 255, 255, 0.05) !important",
                    }}
                    placeholder="Enter Your code"
                  />

                  <Stack
                    sx={{
                      position: "absolute !important",
                      top: { xs: "15px !important", sm: "23px !important" },
                      right: { xs: "20px !important", sm: "27px !important" },
                    }}
                  >
                    <CustomButton
                      btnText="Apply"
                      handleClick={handlecheckcoupon}
                      buttonStyle="apply"
                      style={{ backgroundColor: "#fab612 !important" }}
                    />
                  </Stack>
                  <Typography
                    variant="body1"
                    className="head"
                    sx={{
                      fontSize: {
                        xs: "14.41px !important",
                        sm: "24px !important",
                      },
                      lineHeight: {
                        xs: "19px !important",
                        sm: "31px !important",
                      },
                      margin: {
                        xs: "9.61px 0px 16px 0px !important",
                        sm: "16px 0px 26px 0px !important",
                      },
                    }}
                  >
                    Order Details
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Typography variant="paragraph" className="names">
                        Subtotal
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "end" }}>
                      <Typography variant="paragraph" className="price">
                        {paymentdata}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="paragraph" className="names">
                        Mastering Charges
                      </Typography>
                    </Grid>

                    <Grid item xs={6} sx={{ textAlign: "end" }}>
                      <Typography variant="paragraph" className="price">
                        &#8377; {servicecharge}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="paragraph" className="names">
                        Donation
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "end" }}>
                      <Typography variant="paragraph" className="price">
                        &#8377; {donate == undefined ? 0 : donate}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      sx={{
                        borderBottom: "1px solid #ffffff",
                        paddingBottom: "19px",
                      }}
                    >
                      <div>
                        <Typography variant="paragraph" className="names">
                          Discount
                        </Typography>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      sx={{
                        textAlign: "end",
                        borderBottom: "1px solid #ffffff",
                        paddingBottom: "19px",
                      }}
                    >
                      <Typography variant="paragraph" className="price">
                        - &#8377;{discountshow}
                      </Typography>
                    </Grid>
                    {show > 0 && billshow ? (
                      <>
                        <Grid item xs={6}>
                          <div>
                            <Typography variant="paragraph" className="names">
                              Total Wallet Amount
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "end" }}>
                          <Typography variant="paragraph" className="price">
                            &#8377; {show}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <div>
                            <Checkbox
                              checked={checkedvalue}
                              onChange={handleChangedvalue}
                              inputProps={{ "aria-label": "controlled" }}
                              sx={{
                                width: "20px",
                                marginRight: "12px",
                                color: "#ffffff",
                                "&.Mui-checked": {
                                  color: "#fa9e0d",
                                },
                              }}
                            />
                            <Typography variant="paragraph" className="names">
                              Payment With Wallet
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "end" }}>
                          <Typography variant="paragraph" className="price">
                            <span style={{ textAlign: "center" }}>
                              &#8377; {walletamount}
                            </span>
                            <br />
                            <span style={{ color: "#fa9e0d" }}>
                              [Wallet Balance:  &#8377;{show}]
                            </span>
                          </Typography>
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}
                    <Grid item xs={6}>
                      <Typography variant="paragraph" className="names">
                        Grand Total
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "end" }}>
                      <Typography variant="paragraph" className="price">
                        &#8377; {totalamount}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Box>
              <ToastContainer />
            </Grid>
          </Grid>
        </Card>
      ) : (
        ""
      )}
    </>
  );
}

import { Box, Button, Typography, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import "./drag.css";

import { useDropzone } from "react-dropzone";

// const thumbsContainer = {
//   display: "flex",
//   flexDirection: "row",
//   flexWrap: "wrap",
//   marginTop: 16,
// };

// const thumb = {
//   display: "inline-flex",
//   borderRadius: 2,
//   border: "1px solid #eaeaea",
//   marginBottom: 8,
//   marginRight: 8,
//   width: 70,
//   height: 70,
//   padding: 4,
//   marginBottom: 2,
//   boxSizing: "border-box",
//   borderRadius: "10px",
// };

// const thumbInner = {
//   display: "flex",
//   minWidth: 0,
//   overflow: "hidden",
// };

// const img = {
//   display: "block",
//   width: "`100%`",
//   height: "100%",
// };

// function Gst({setgstFile,data}) {
//   const [files, setFiles] = useState([]);
//   const { getRootProps, getInputProps, open } = useDropzone({
//     accept: {
//       "document/.pdf": ['.pdf'],
//       // "image/png": [],
//     },

//     onDrop: (acceptedFiles) => {
//       setFiles(
//         acceptedFiles.map((file) =>
//           Object.assign(file, {
//             preview: URL.createObjectURL(file),
//           })
//         )
//       );
//     },
//   });

//   const thumbs = files.map((file) => (
//     <div style={thumb} key={file.name}>
//       <div style={thumbInner}>
//         <img
//           src={file.preview}
//           style={img}
//           onLoad={() => {
//             URL.revokeObjectURL(file.preview);
//           }}
//         />
//       </div>
//     </div>
//   ));
//   setgstFile(files[0]);
// console.log(files)

function Gst({ setgstFile, data, gstfile }) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "document/.pdf": [".pdf", ".png", ".jpg", ".jpeg"],
    },
  });

  // const files = acceptedFiles.map((file) => (
  //   <p key={file.path}>
  //     {setgstFile(file)}
  //     {file.path}
  //   </p>
  // ));
  // setgstFile(files)
  const files = acceptedFiles.map((file) => setgstFile(file));

  return (
    <div {...getRootProps({ className: "basic-song-drop" })}>
      <input {...getInputProps()} />
      <Box
        sx={{
          height: "70px",
          display: "flex",
          alignItems: "center",
          wordBreak: "break-all",
        }}
      >
        <Typography
          sx={{
            listStyle: "none",
            mt: "17px !important",
            mb: "17px !important",
            wordBreak: "break-all",
            fontSize:{xs:'10px' ,md:"none"}
          }}
        >
          {gstfile?.path || gstfile}
        </Typography>
      </Box>
      <IconButton
        type="button"
        // onClick={open}
        className="upload-song"
        // sx={{ mt: "20px" }}
      >
        <UploadFileIcon />
      </IconButton>
      <Typography className="drop-song">
        Drop or Choose your File
        {/* <Typography variant="span" sx={{ color: "#FB900B" }}>
      browse
    </Typography> */}
      </Typography>
    </div>
  );
}

export default Gst;

import React from "react";
import {
  Box,
  Container,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import About from "../../../assets/img/about.png";
import signature from "../../../assets/img/signature.png";
import { IoMic } from "react-icons/io5";
import CustomButton from "../thirdEditBtn";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Bannerimg from "../../../assets/img/profile/photosecond.png";
import Sign from "../../../assets/img/profile/sign.png";
import AboutEditForm from "./aboutEditForm";

function PaperComponent(props) {
  return <Paper sx={{ background: "white" }} {...props} />;
}

const AboutMe = ({ data, getOrder, tokenedit }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ background: "#CDFFF6", pb: 6, position: "relative" }}>
      <Container>
        <Typography
          sx={{
            fontSize: { md: "56px", xs: "36px" },
            color: "black",
            fontFamily: "Monoton",
            textAlign: "center",
            textTransform: "uppercase",
            pt: { xs: 6, md: 6 },
            pb: 3,
          }}
        >
          About Me
        </Typography>

        <Box>
          <Grid container spacing={3}>
            <Grid
              item
              md={5}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <img
                  src={data?.banner === "undefined" ? Bannerimg : data?.banner}
                  className="banner_img"
                  width="100%"
                  height="100%"
                />
              </Box>
            </Grid>
            <Grid item md={7} xs={12} alignSelf="center">
              {tokenedit ? (
                <Box
                  sx={{
                    // position: "absolute",
                    // top: "190px",
                    // left: { md: "27%", xs: "40%" },
                    marginBottom: { md: "50px", xs: "20px" },
                    // marginLeft: { lg: "250px", xs: "10px" },
                  }}
                >
                  <CustomButton
                    btnText="Edit"
                    handleClick={handleOpen}
                    startIcon={
                      <ModeEditOutlinedIcon sx={{ marginRight: "5px" }} />
                    }
                    width="75px"
                    height="30px"
                    border="1px solid #ffffff !important"
                    padding="15px"
                  />
                </Box>
              ) : (
                ""
              )}
              <Box
                sx={{
                  width: "166px",
                  height: "30px",
                  borderRadius: "66px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#fff",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#4DCAB4",
                    fontFamily: "Montserrat",
                  }}
                >
                  {data?.title}{" "}
                </Typography>
                &nbsp; &nbsp; <IoMic color="#4DCAB4" />
              </Box>
              <Box textAlign={"left"} sx={{ maxWidth: "620px", my: 3 }}>
                <Typography
                  sx={{
                    fontSize: "24px",
                    lineHeight: "33px",
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    color: "#1E1E1E",
                  }}
                >
                  {data?.description === "undefined"
                    ? "occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing occaecat cupidatat non proident, sunt inculpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing."
                    : data?.description}
                </Typography>
              </Box>
              <img
                src={data?.autograph === "undefined" ? Sign : data?.autograph}
                alt=""
                width="20%"
                height="100%"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <div>
        <Dialog
          height="auto"
          style={{ zIndex: "9999922" }}
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <Box textAlign="end">
            <IconButton sx={{ color: "black" }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <DialogTitle
              style={{ cursor: "move" }}
              align="center"
              mt="-30px"
              padding="0px !important"
              id="draggable-dialog-title"
              sx={{ color: "black" }}
            >
              About
            </DialogTitle>
          </Box>

          <AboutEditForm
            view={data}
            handleClose={handleClose}
            getOrder={getOrder}
            tokenedit={tokenedit}
          />
        </Dialog>
      </div>
    </Box>
  );
};

export default AboutMe;

import React from "react";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import "./form.css";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

export default function FormPropsTextFields() {
  const initialValues = {
    oldPassword: "",
    password: "",
    passwordConfirmation: "",
  };

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required("Current Password is required"),

    password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),

    passwordConfirmation: Yup.string().min(6, "Password must be at least 6 characters").oneOf(
      [Yup.ref("password"), null],
      "Confirm Password Must Match With New Password"
    ),
  });

  const onSubmit = async (values) => {
    const loginToken = JSON.parse(localStorage.getItem("login"));
    const token = loginToken?.token;
    const userid = loginToken?.user._id;

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const payload = {
      currentPassword: values.oldPassword,
      newPassword: values.password,
    };

    await axios
      .put(
        `${process.env.REACT_APP_DMT_API_PATH}/users/password`,
        payload,
        config
      )
      .then((res) => {
        if (res.data.error !== true) {
          // alert("password change successfully");
          toast.success('password Changed Successfully ')
        }
      })
      .catch((err) => {
        toast.error(err)
      });


  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <Container
      sx={{
        background: "#1e1e1e !important",
        borderRadius: "15px",
        pt: "30px",
        pb: "20px",
      }}
    >
      <Formik>
        <Form onSubmit={formik.handleSubmit}>
          {/* ------------------------------Old Password Section Start----------------------------- */}

          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            mb={3}
          >
            <Typography className="pass" sx={{ mb: "5px", fontFamily: 'DM sans' }}>
              Current Password
            </Typography>
            <TextField
              fullWidth
              variant="standard"
              type="password"
              name="oldPassword"
              id="outlined-password-input"
              // placeholder="Enter your current password"
              // autoComplete="current-password"

              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
              value={formik.values.oldPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.oldPassword && formik.errors.oldPassword ? (
              <Typography color="error">{formik.errors.oldPassword}</Typography>
            ) : null}
          </Box>
          {/* ------------------------------Old Password Section End----------------------------- */}

          {/* ------------------------------New Password Section Start----------------------------- */}

          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            mb={3}
          >
            <Typography className="pass" sx={{ mb: "5px", fontFamily: 'DM sans' }}>
              New Password{" "}
            </Typography>
            <TextField
              fullWidth
              variant="standard"
              type="password"
              name="password"
              id="outlined-password-input"
              // placeholder="Enter your new password"
              // autoComplete="current-password"

              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.password && formik.errors.password ? (
              <Typography color="error">{formik.errors.password}</Typography>
            ) : null}
          </Box>

          {/* ------------------------------New Password Section End----------------------------- */}

          {/* ------------------------------Confirm Password Section Start------------------------- */}

          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Typography className="pass" sx={{ mb: "5px", fontFamily: 'DM sans' }}>
              Confirm Password
            </Typography>

            <TextField
              fullWidth
              variant="standard"
              type="password"
              name="passwordConfirmation"
              id="outlined-password-input"
              // placeholder="Renter your new password"
              // autoComplete="current-password"

              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
              value={formik.values.passwordConfirmation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            {formik.touched.passwordConfirmation &&
              formik.errors.passwordConfirmation ? (
              <Typography color="error">
                {formik.errors.passwordConfirmation}
              </Typography>
            ) : null}
          </Box>

          {/* ------------------------------Confirm Password Section End------------------------- */}

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={7}
          >
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={!formik.isValid}
              startIcon={<ClearIcon />}
              sx={{
                background: "#f9a40d !important",
                fontWeight: "700",
                textTransform: "capitalize",
                borderRadius: "24px",
                fontFamily: 'DM sans'
              }}
            >
              Change Password
            </Button>

          </Box>
          <ToastContainer />
        </Form>
      </Formik>
    </Container>
  );
}

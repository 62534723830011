import { Box, Typography } from "@mui/material";
import React from "react";
import { useDropzone } from "react-dropzone";
import Play from "../../../assets/img/play.png";
import CustomButton from "../../common/customButton/index";

const Index = ({ songValue, setSongValue }) => {
  const { getRootProps, getInputProps, open, open1, acceptedFiles } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      accept: {
        "audio/.wav": [".wav"],
      },
    });
  const files = acceptedFiles.map((file) => setSongValue(file));
  return (
    <div>
      <Box
        sx={{
          background: "#ff61a2 ",
          borderRadius: "16px",
          width: "100%",
          marginTop: "8px ",
          border:'1px solid white'
        }}
        {...getRootProps()}
        onClick={open}
      >
        <Box
          textAlign="center"
          sx={{ py: "20px", px: "20px", overflow: "hidden" }}
        >
          <input {...getInputProps()} />
          <Box>
            <img src={Play} />
          </Box>
          <CustomButton
            btnText={songValue ? "Change Song" : "Upload Song"}
            buttonStyle="basic-btn2"
            marginTop="10px"
          />
          <Typography
            sx={{
              listStyle: "none",
              mt: "17px !important",
              mb: "17px !important",
              wordBreak: "break-all",
            }}
          >
            {songValue?.path || songValue}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography className="quality2">
              Quality is important to us, please use the highest-quality stereo
              assets available (WAV, AIFF or FLAC only).
            </Typography>
          </Box>
          {/* </Box> */}
        </Box>
      </Box>
    </div>
  );
};

export default Index;
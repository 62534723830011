import React, { useState } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Box,
  Typography,
  Card,
  Divider,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "../faqPage/FaqCss.css";
import ReactPlayer from "react-player";

function Faq() {
  const [expandOne, setExpandOne] = useState(false);
  const handleChange = (isExpanded, pannel) => {
    setExpandOne(isExpanded ? pannel : false);
  };
  return (
    <Stack id="master-stack">
      <Box id="master-box">
        {/* ------------------------------------------------------------------1st Card Heading Start */}

        <Typography id="card-out-heading">GENERAL QUESTIONS</Typography>

        {/* --------------------------------------------------------------------1st Card Heading End */}

        <Card elevation={24} sx={{ boxShadow: "2px 15px 40px 5px gray" }}>
          <Box id="accordian-container">
            {/* --------------------------------------------------------------------According 01 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel1"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel1")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  What do I need to get started with Deliver My Tune?
                </Typography>
                <Divider />
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withTextandList">
                  You can sign up with your email address and you must be at
                  least 14 years old to be eligible to sign up. <br />
                  <br />
                  Your submitted audio file should be in the following format:
                  <br />
                  <ul id="accordianList">
                    <li>Mp3 or Wav</li>
                    <li>Files must be stereo</li>
                    <li>44.1 kHz sampling frequency</li>
                    <li>320 Kbps or 24 bit per sample</li>
                  </ul>
                  Your submitted artwork file should be in the following format:{" "}
                  <br />
                  <ul id="accordianList">
                    <li>3000 x 3000 Pixels</li>
                    <li>JPG file type</li>
                    <li>RGB color scheme</li>
                    <li>
                      No Contact information, social media links/logo are
                      allowed
                    </li>
                    <li> Credits to none Audio Performers allowed</li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 01 End */}

            {/* --------------------------------------------------------------------According 02 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel2"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel2")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  How does Deliver My Tune work?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content">
                  <ul>
                    <li>
                      {" "}
                      After signing up, artists deliver their music to Deliver
                      My Tune
                    </li>
                    <li>
                      {" "}
                      After content verification by the Deliver My Tune team,
                      the artist’s music is distributed on all partner digital
                      music stores.
                    </li>
                    <li>
                      {" "}
                      The store’s content team verifies the audio content and
                      makes the song live on their platforms
                    </li>
                    <li>
                      {" "}
                      Artists start receiving the sales report via Deliver My
                      Tune.
                    </li>
                    <li>
                      {" "}
                      Deliver My Tune will transfer 90% of the earned royalty
                      directly to the artist bank account.
                    </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 02 End */}

            {/* --------------------------------------------------------------------According 03 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel3"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel3")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  Will Deliver My Tune take any of my royalties?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withoutList">
                  Yes, Deliver My Tune keeps 10% of your royalties earned from
                  all of the stores. The 10% is for song maintenance on the
                  digital stores, the quarterly transactions, and new services
                  provided on a regular basis.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 03 End */}

            {/* ------------------------------------------------------------------According 04 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel4"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel4")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  How do I move my music from another distributor to Deliver My
                  Tune?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withTextandList">
                  The steps to move your music from another distributor to
                  Deliver My Tune is really simple: <br />
                  <ul id="accordianList">
                    <li>
                      {" "}
                      Find all of the metadata associated with your music: ISRC,
                      UPC, original audio file and original artwork
                    </li>
                    <li>Send a takedown request to your current distributor</li>
                    <li>
                      {" "}
                      Once the takedown request has been processed and your
                      music is no longer on the online store, re-upload your
                      music on Deliver My Tune but make sure that it has the
                      exact same metadata which was associated with your music.
                    </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 04 End */}

            {/* ------------------------------------------------------------------According 05 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel5"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel5")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  Can I choose which stores my music is released on?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withoutList">
                  Yes, you have full control over where your music is released.
                  Deliver My Tune can release your music on some major digital
                  music stores, such as iTunes/Apple Music, Deezer, Spotify,
                  Anghami, or even all. However, we do recommend that you
                  distribute your music on all stores to ensure maximum audience
                  exposure.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 05 End */}

            {/* ------------------------------------------------------------------According 06 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel6"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel6")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  How long does it take to release my music?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withTextandList">
                  If you want to make sure that your music is launched on all
                  digital music stores at the same time, we recommend that you
                  deliver your music 2-3 weeks ahead. <br />
                  <ul id="accordianList">
                    <li>
                      {" "}
                      Although Deliver My Tune sends the song to all the stores
                      within 48 hours.
                    </li>
                    <li>
                      {" "}
                      Most of the digital distribution partner reviews releases,
                      processes it and launches it within 5 working days, but
                      sometimes some stores take more time.
                    </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 06 End */}

            {/* ------------------------------------------------------------------According 07 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel7"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel7")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  Can I edit my release after it's been confirmed?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withTextandList">
                  If you want to edit your release, you can request to do so for
                  free as long as Deliver My Tune hasn’t sent it to the digital
                  music stores yet. However, if we noticed a mistake while
                  carrying out a quality check, we will send you an email to
                  notify you. But if you need to make an edit after the release
                  has been sent to the stores, you will have to: <br />
                  <ul id="accordianList">
                    <li> Note down the release’s UPC and ISRC code</li>
                    <li> Submit a takedown request</li>
                    <li> Edit your release</li>
                    <li>
                      {" "}
                      Upload it again as a new release, but make sure you use
                      the same UPC and ISRC code for your release.
                    </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 07 End */}

            {/* ------------------------------------------------------------------According 08 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel8"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel8")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  Do I need a label?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withoutList">
                  You do not need a label as long as you are releasing your
                  music. The music which you created and you hold the copyright
                  to. However, if you create a label in your account, just make
                  sure you don't use some other artist's name and not to
                  infringe trademark rights.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 08 End */}

            {/* ------------------------------------------------------------------According 09 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel9"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel9")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  Does Deliver My Tune own the copyright to my work?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withoutList">
                  No, artists own 100% copyright to their work. Deliver My Tune
                  will only work as a representative.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 09 End */}

            {/* ------------------------------------------------------------------According 10 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel10"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel10")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  Will my song be distributed to all countries?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withoutList">
                  Deliver My Tune distributes music to 200 + countries around
                  the world.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 10 End */}

            {/* ------------------------------------------------------------------According 11 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel11"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel11")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  Can I sign-up for Deliver My Tune additional services only or
                  do I need to purchase a distribution subscription plan?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withoutList">
                  You can sign-up for Deliver My Tune’s marketing services
                  without the need to purchase a distribution subscription plan,
                  but for the Fan Management System, artists should at least
                  distribute a song through the platform.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 11 End */}

            {/* ---------------------------------------------------------------According 12 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel12"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel12")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  I noticed that some of the information listed for my release
                  is not correct (song order, spelling, album title, featured
                  artist name not listed, etc) How do I get this corrected?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withoutList">
                  We can make very few corrections to the text on the partner
                  page but we cannot make any changes to the album. For example:
                  if your track title is misspelled, our partners will let us
                  fix that but if you want to change your artist name or artwork
                  to something new, you would need to submit a new album. But
                  don’t worry we provide our artists with every opportunity to
                  edit their project before submitting it for global
                  distribution.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 12 End */}

            {/* ---------------------------------------------------------------According 13 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel13"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel13")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  Do you guys distribute cover music?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withoutList">
                  Deliver My Tune distributes any musical content as long as you
                  are the copyright holder and have the permission from the
                  original production rights holder to have the clearance to
                  distribute the content.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 13 End */}

            {/* ---------------------------------------------------------------According 14 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel14"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel14")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  How does the referral program work and how much do I receive
                  from the referral?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withoutList">
                  Once you create a Deliver My Tune account and make your first
                  successful music distribution, you will automatically enroll
                  in the referral program. <br />
                  After enrolling in the program, you will keep receiving Rs 500
                  for the first 5 releases your referral makes. The money earned
                  from the referral program will be added to your account
                  wallet. And it can only be used for distribution or
                  advertisement purposes. It cannot be withdrawn from the
                  account.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel23"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel23")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  How can I get free mastering services?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withoutList">
                  Deliver My Tune is here to ease the music mastering journey
                  for our independent artist. Our mastering services starts
                  @₹99/- but here’s a trick you can get for free.
                  <Typography style={{ fontWeight: 700 }}>
                    Wondering how??
                  </Typography>
                  <Typography>
                    <span style={{ fontWeight: 700 }}> Step 1</span>: Fill the
                    basic details which includes track name, genre, release date
                    etc.
                  </Typography>
                  <Typography>
                    <span style={{ fontWeight: 700 }}> Step 2</span>: Here
                    you’ll find the mastering option. First you need to select
                    the option “Yes I want to do my song mastered” after
                    selecting this option you can get it for free by following
                    this easy step: Share your song distribution details on
                    social media and we will refund your mastering amount making
                    it FREE. Sharing option is available at the end of the form
                    after completing all the four steps.
                  </Typography>
                  <Typography mt={1}>
                    <span style={{ fontWeight: 700 }}> Note</span>: For some
                    glitch if you didn't get the refund even after sharing
                    details on social media. You can reach out to us at
                    contact@delivermytune.com . We’ll look into it and will
                    process your refund within 24 hours.
                  </Typography>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel24"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel24")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  How you can edit your songs?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <ReactPlayer
                  loop={true}
                  className="custom-react-player"
                  width="100%"
                  height="400px"
                  volume
                  autoPlay={false}
                  url={"https://youtu.be/i1wkdzzu0UA"}
                />
              </AccordionDetails>
            </Accordion>
            {/* --------------------------------------------------------------------According 14 End */}
          </Box>
        </Card>

        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}

        {/* ------------------------------------------------------------------2nd Card Heading Start */}

        <Typography id="card-out-heading" mt={3}>
          PRICING AND PAYMENTS
        </Typography>

        {/* --------------------------------------------------------------------2nd Card Heading End */}

        <Card elevation={24} sx={{ boxShadow: "2px 15px 40px 5px gray" }}>
          <Box id="accordian-container">
            {/* ---------------------------------------------------------------According 15 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel15"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel15")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  What is included in the fee?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content">
                  <ul>
                    <li>
                      {" "}
                      The fee is for your account set-up: Creating an album page
                      and an artist page
                    </li>
                    <li> Life-time distribution on partner sites</li>
                    <li> Access to the fan page management</li>
                    <li>
                      {" "}
                      Converting audio files as requested for the partners’
                      sites
                    </li>
                    <li>
                      {" "}
                      Editing and resizing album covers for the partners’ sites
                    </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 15 End */}

            {/* ---------------------------------------------------------------According 16 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel16"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel16")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  How and when do I get paid for my music?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withoutList">
                  After receiving the sales/royalty report on a monthly or
                  quarterly basis from our digital partners, 90% of your royalty
                  payment gets transferred into your bank account or through
                  paypal, when it touches the threshold of Rs 3000/ $50.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 16 End */}

            {/* ---------------------------------------------------------------According 17 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel17"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel17")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  How does Bundle purchase work?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withoutList">
                  Bundle purchases are for artists or labels who are looking to
                  release multiple songs and it includes a lot of privileges.
                  From the additional balance in the wallet to coupons,
                  discounts & much more. Refer to the pricing page for more
                  about the Bundle Purchase prices.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 17 End */}
          </Box>
        </Card>

        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}

        {/* ------------------------------------------------------------------3rd Card Heading Start */}

        <Typography id="card-out-heading" mt={3}>
          REPORTS
        </Typography>

        {/* --------------------------------------------------------------------3rd Card Heading End */}

        <Card elevation={24} sx={{ boxShadow: "2px 15px 40px 5px gray" }}>
          <Box id="accordian-container">
            {/* -------------------------------------------------------------According 18 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel18"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel18")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  How do I view my sales data & earnings?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withoutList">
                  To view your sales data and earnings, all you have to do is
                  log into your Deliver My Tune account, and then select
                  ‘Royalty Reports’
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 18 End */}

            {/* -------------------------------------------------------------According 19 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel19"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel19")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  When will my sales reports appear?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withoutList">
                  Deliver My Tune receives sale reports for artists either on a
                  monthly or quarterly basis and we update your sales report,
                  once we receive them from the stores. Most of the partners
                  report back to us monthly. So for example, you want to see the
                  royalty reports for the months of (January) it will be
                  provided by (April) <br /> <br />
                  Another detailed example: If you release a song in the month
                  of April, details about this release will be available in the
                  sales report, which will be provided by the 25th of July. And
                  the royalty you've earned will be credited into your bank
                  account by the 20th of August. But make sure that you will
                  only receive the royalty by the 5th, if you have your bank
                  details updated on the Deliver My Tune account by the 25th of
                  June.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 19 End */}
          </Box>
        </Card>

        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}

        {/* ------------------------------------------------------------------4th Card Heading Start */}

        <Typography id="card-out-heading" mt={3}>
          PARTNERS RELATED
        </Typography>

        {/* --------------------------------------------------------------------4th Card Heading End */}

        <Card elevation={24} sx={{ boxShadow: "2px 15px 40px 5px gray" }}>
          <Box id="accordian-container">
            {/* -------------------------------------------------------------According 20 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel20"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel20")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  As a label, can we monetize our YouTube channel and
                  SoundCloud?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withTextandList">
                  Yes, we do monetize on YouTube and SoundCloud. <br /> <br />
                  About YouTube Monetization: <br />
                  <ul id="accordianList">
                    <li>
                      {" "}
                      Deliver My Tune distributes your music on YouTube Music
                      only.
                    </li>
                    <li>
                      {" "}
                      Deliver My Tune provides you with video content ID for
                      YouTube video release, but it doesn’t release the video on
                      the platform.
                    </li>
                    <li>
                      {" "}
                      However, Deliver My Tune does collect the royalty on
                      behalf of the artist from YouTube Video as well.
                    </li>
                    <li>
                      {" "}
                      Deliver My Tune also protects its artist by collecting
                      their share of royalty from other artists on the YouTube
                      Video platform, if they are using our artist’s music.
                    </li>
                  </ul>
                  About SoundCloud Monetization: <br />
                  <ul id="accordianList">
                    <li>
                      {" "}
                      If a digital distributor distributes music on SoundCloud,
                      it is monetized. But when an independent artist goes to
                      SoundCloud and releases his music, it is never monetized.
                    </li>
                    <li>
                      {" "}
                      SoundCloud doesn’t offer monetization in every country
                      across the world. And it is not available in India. Yet
                      Deliver My Tune can distribute music on SoundCloud for an
                      artist in India and it will be available only in the
                      countries where SoundCloud offers the monetized service.
                    </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 20 End */}

            {/* -------------------------------------------------------------According 21 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel21"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel21")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  What is the YouTube content ID?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withoutList">
                  YouTube content ID is a digital fingerprint system, developed
                  by Google. It is used to identify and manage copyrighted
                  content on YouTube. Videos uploaded on Youtube are usually
                  compared against other audio and video files that are
                  registered by content owners and have a Content ID, and when
                  this happens, the video gets claimed for a content ID.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 21 End */}
          </Box>
        </Card>

        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}

        {/* ------------------------------------------------------------------5th Card Heading Start */}

        <Typography id="card-out-heading" mt={3}>
          PROMOTIONS
        </Typography>

        {/* --------------------------------------------------------------------5th Card Heading End */}

        <Card elevation={24} sx={{ boxShadow: "2px 15px 40px 5px gray" }}>
          <Box id="accordian-container">
            {/* -------------------------------------------------------------According 22 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel22"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel22")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  UPC and ISRC CODES
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withoutList">
                  The Deliver My Tune distribution service does not include
                  promotional services, but there are promotional services
                  packages sold separately.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 22 End */}
          </Box>
        </Card>

        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}

        {/* ------------------------------------------------------------------6th Card Heading Start */}

        <Typography id="card-out-heading" mt={3}>
          UPC and ISRC CODES
        </Typography>

        {/* --------------------------------------------------------------------6th Card Heading End */}

        <Card elevation={24} sx={{ boxShadow: "2px 15px 40px 5px gray" }}>
          <Box id="accordian-container">
            {/* -------------------------------------------------------------According 23 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel23"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel23")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  Do I need an ISRC code?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withoutList">
                  An International Standard Recording Code (ISRC) is a code for
                  identifying music recording. Each recording has its own unique
                  and permanent identifier encoded into it. And it can be used
                  to track sales. In case you don't have an existing ISRC code
                  for your release, you can get one from us.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 23 End */}

            {/* -------------------------------------------------------------According 24 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel24"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel24")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  What is a UPC bar code and do I need one?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withoutList">
                  A Universal Product Codes (UPC Code) is a unique 12-digit
                  serial number that identifies your specific product (in this
                  case, an album) amongst all the other products on the
                  marketplace. So if your dream is to drop an album or a single,
                  you are going to need a UPC.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 24 End */}
          </Box>
        </Card>

        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}

        {/* ------------------------------------------------------------------7th Card Heading Start */}

        <Typography id="card-out-heading" mt={3}>
          CANCELLING AND REFUNDS
        </Typography>

        {/* --------------------------------------------------------------------7th Card Heading End */}

        <Card elevation={24} sx={{ boxShadow: "2px 15px 40px 5px gray" }}>
          <Box id="accordian-container">
            {/* -------------------------------------------------------------According 25 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel25"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel25")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  How do I remove my release from stores?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withoutList">
                  You can remove your release from stores through a tab on the
                  artist dashboard and Deliver My Tune will issue take-down
                  notices to our partners and your content will be removed from
                  their sites within 60 days. However, there is a token fee for
                  Rs 99 token fee to remove your release. The fee is to make
                  sure that no one accidentally cancels their distribution. To
                  cancel any digital distribution, please email us at
                  contact@delivermytune.com
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 25 End */}
          </Box>
        </Card>

        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------ */}

        {/* ------------------------------------------------------------------8th Card Heading Start */}

        <Typography id="card-out-heading" mt={3}>
          MASTERING
        </Typography>

        {/* --------------------------------------------------------------------8th Card Heading End */}

        <Card elevation={24} sx={{ boxShadow: "2px 15px 40px 5px gray" }}>
          <Box id="accordian-container">
            {/* -------------------------------------------------------------According 26 Start */}

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel26"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel26")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  How will my music be mastered?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withTextandList">
                  We use a cloud-based, automated music mastering service that
                  allows you to upload your audio tracks in various formats, and
                  then it is digitally mastered using AI algorithms. The music
                  can then be distributed on digital streaming services sites,
                  like iTunes, Spotify, Shazam, etc. <br />
                  <br />
                  What is mastering? <br />
                  <br />
                  Mastering does audio restoration, stereo enhancement, and EQ
                  to ensure consistency of sound between various tracks on an
                  album. It fixes the small mistakes which were missed in the
                  final mix, such as hisses and clicks. <br /> <br />
                  So before uploading your music, you can find a mastering
                  option to enhance the quality of your release. <br />
                  <br />
                  Mastering Fees: <br />
                  <ul id="accordianList">
                    <li> Mastering: 24 bit - $6.99 </li>
                    <li> Mastering: 24 bit - Rs 99</li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* --------------------------------------------------------------------According 26 End */}
          </Box>
        </Card>
      </Box>
    </Stack>
  );
}

export default Faq;

import React, { useState, useEffect } from "react";
import Banner from "../components/secondPortfolio/banner/index";
import { Box } from "@mui/material";
import { ScaleLoader } from "react-spinners";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ListenMusic from "../components/secondPortfolio/listenMusic/index";
import UpcomingConcert from "../components/secondPortfolio/upcomimgConcert/index";
import LatestVideos from "../components/secondPortfolio/latestVideos/index";
import Gallery from "../components/secondPortfolio/gallery/index";
import Admire from "../components/secondPortfolio/admire/index";
import Contact from "../components/secondPortfolio/contact/contact";

const About = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [editformdata, setEditformdata] = useState(true);
  const [datafind, setDatafind] = useState();
  const [data, setData] = useState();
  const params = useParams();

  const seachQuery = useSearchParams()[0];

  const Tokenforget = seachQuery.get("token");
  const loginToken = JSON?.parse(localStorage?.getItem("login"));
  const [token, setToken] = useState();
  useEffect(() => {
    if (Tokenforget) {
      setToken(Tokenforget);
    } else {
      setToken(loginToken?.token);
    }
  }, [Tokenforget, loginToken]);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [userfind, setUserfind] = useState();
  const [tokenedit, setTokenedit] = useState();
  useEffect(() => {
    if (token) {
      console.log(token, "token");
      axios
        .get(`${process.env.REACT_APP_DMT_API_PATH}/users`, config)
        .then((res) => {
          if (res?.data?.error !== true) {
            setUserfind(res.data.data._id);
            setDatafind(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);
  async function getOrder() {
    await axios
      .get(`${process.env.REACT_APP_DMT_API_PATH}/creative/${params?.id}`)
      .then((res) => {
        if (res?.data?.error !== true) {
          setTimeout(() => {
            if (res?.data?.data) {
              setData(res?.data?.data);
              if (res.data.data.length == 0) {
                toast.error("Creative Showcase Not Found");
                setEditformdata(false);
              }
              setIsLoading(false);
            }
          }, 2000);
        } else if (res?.data?.error === true) {
          setEditformdata(false);
          setIsLoading(false);
          if (userfind) {
            navigate("/artist/portfolio");
          } else {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setEditformdata(false);
      });
  }

  useEffect(() => {
    getOrder();
  }, []);
  const isTestPreview = params.id === 'Deliver My Tune';
  useEffect(() => {
    if (isTestPreview) {
      setTokenedit('');
    }else{
    if (userfind === data?.userid) {
      console.log(token, "setTokenedit");
      setTokenedit(token);
    } else if (userfind !== data?.userid) {
      setTokenedit("");
    }
  }
  }, [data, token]);
  return isLoading ? (
    <ScaleLoader
      size={350}
      textAlign="center"
      className="music-loader"
      color="#FBCE2E"
    />
  ) : (
    <>
      <Box>
        <Banner data={data} getOrder={getOrder} tokenedit={tokenedit} />
        <ListenMusic data={data} getOrder={getOrder} tokenedit={tokenedit} />
        <UpcomingConcert
          data={data}
          getOrder={getOrder}
          tokenedit={tokenedit}
        />
        <LatestVideos data={data} getOrder={getOrder} tokenedit={tokenedit} />
        <Gallery data={data} getOrder={getOrder} tokenedit={tokenedit} />
        <Admire data={data} getOrder={getOrder} tokenedit={tokenedit} />
        <Contact data={data} getOrder={getOrder} tokenedit={tokenedit} />
      </Box>
      <ToastContainer />
    </>
  );
};

export default About;

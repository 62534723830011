import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "@mui/material/TextField";
import "./style.css";
import * as yup from "yup";
// import { toast, ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLoginArtistMutation } from "../../redux/services/login";
import { useCopyrightQuery } from "../../redux/services/dashboard";
import axios from "axios";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { userSlice } from "../redux/slices/authSlice";
import { tokenSlice } from "../../redux/slices/tokenSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Alert } from "@mui/material";

const theme = createTheme();
const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

export default function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [createPost, responseInfo] = useLoginArtistMutation();
  const [data, setData] = useState([]);
  const [id, setId] = useState();
  const location = useLocation();
  const [editformdata, setEditformdata] = useState(location?.state?.id);
  useEffect(() => {
    if (!editformdata?.token) {
      navigate("/signup/artist");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        email: values.email,
        password: values.password,
      };
      // createPost(payload);
      await axios
        .post(
          `${process.env.REACT_APP_DMT_API_PATH}/authentication/local`,
          payload
        )
        .then((res) => {
          if (res.data.error === true) {
            console.log("error");
            toast.error(res.data.message);
          } else {

            localStorage.setItem("login", JSON.stringify(res?.data?.user));
            dispatch(tokenSlice.actions.saveToken(res?.data?.user));
            axios
              .get(
                `${process.env.REACT_APP_DMT_API_PATH}/admin/orders/copyright/${res?.data?.user?.user?.id}`
              )
              .then((response) => {
                setData(response?.data?.data);
                if (response?.data?.data.length < 2) {
                  navigate("/artist/dashboard");
                } else if (response?.data?.data.length > 3) {
                  toast.error("Account Blocked");
                } else if (
                  response?.data?.data.length == 2 ||
                  response?.data?.data.length == 3
                ) {
                  console.log("Nice ");
                  localStorage.setItem(
                    "caution",
                    JSON.stringify(
                      "CAUTION! You have attempted to upload copyrighted song twice on dashboard. After 3 failed attempts, This account will be blocked from distribution permanently *DeliverMyTune reserves the right to admission and seizer of royalty distribution"
                    )
                  );
                  navigate("/artist/dashboard", {
                    state: {
                      id: "CAUTION! You have attempted to upload copyrighted song twice on dashboard. After 3 failed attempts, This account will be blocked from distribution permanently *DeliverMyTune reserves the right to admission and seizer of royalty distribution",
                    },
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" sx={{ textAlign: "-webkit-center" }}>
        <Paper className="paper-form" component={Box} marginTop="80px">
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            padding="1rem 1.25rem;"
          >
            <Box sx={{ marginTop: "20px", paddingLeft: "1.25rem" }}>
              <Alert sx={{ mb: 2 }}>
                "Thank you so much for signing up, login below to access your
                dashboard"
              </Alert>
              <Typography
                sx={{
                  textAlign: "start",
                  color: "black",
                  fontFamily: "Open Sans, sans-serif",
                  fontSize: "0.75rem",
                  fontWeight: 700,
                  lineHeight: "1rem",
                }}
              >
                Email
              </Typography>
              <TextField
                fullWidth
                size="small"
                id="email"
                name="email"
                placeholder="Email *"
                sx={{
                  background: "white",
                  marginTop: "4px",
                  borderRadius: "4px",
                  border: "1px solid #1b1b38",
                }}
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.touched.email && formik.errors.email ? (
                <Typography sx={{ textAlign: "start" }} color="error">
                  {formik.errors.email}
                </Typography>
              ) : null}
            </Box>
            <Box sx={{ marginTop: "20px", paddingLeft: "1.25rem" }}>
              <Typography
                sx={{
                  textAlign: "start",
                  color: "black",
                  fontFamily: "Open Sans, sans-serif",
                  fontSize: "0.75rem",
                  fontWeight: 700,
                  lineHeight: "1rem",
                }}
              >
                Pasword
              </Typography>
              <TextField
                fullWidth
                size="small"
                id="password"
                name="password"
                placeholder="Password *"
                type="password"
                sx={{
                  background: "white",
                  marginTop: "4px",
                  border: "1px solid #1b1b38",
                  borderRadius: "4px",
                }}
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              {formik.touched.password && formik.errors.password ? (
                <Typography sx={{ textAlign: "start" }} color="error">
                  {formik.errors.password}
                </Typography>
              ) : null}
            </Box>
            <Box textAlign="center" marginTop="25px" marginBottom="15px">
              <Button className="submit-btn" type="submit">
                <ClearIcon />
                Login
              </Button>
            </Box>
            <Grid
              container
              spacing={{ md: 12, xs: 4 }}
              sx={{ marginTop: "10px", marginBottom: "20px" }}
            >
              <Grid item md={6} xs={12}>
                <Typography className="signup">
                  <Link
                    className="link-btn"
                    underline="hover"
                    color="inherit"
                    to="/signup/artist"
                  >
                    Go to Sign up
                  </Link>
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography className="signup">
                  {" "}
                  <Link
                    className="link-btn"
                    underline="hover"
                    color="inherit"
                    to="/login/forget"
                  >
                    Forget Password?
                  </Link>
                </Typography>
              </Grid>
            </Grid>
            <ToastContainer />
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}

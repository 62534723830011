import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminLogin = () => {
  const navigate = useNavigate();
  const seachQuery = useSearchParams()[0];
  const [data, setData] = useState();
  const [loading, setIsLoading] = useState(false);
  //   if (token) {
  useEffect(() => {
    if (seachQuery) {
      const token = seachQuery.get("token");
      console.log(token, "token");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      setIsLoading(true);
      axios
        .get(`${process.env.REACT_APP_DMT_API_PATH}/users`, config)
        .then((res) => {
          if (res?.data?.error !== true) {
            // console.log(res?.data?.data);
            localStorage.setItem(
              "login",
              JSON.stringify({
                token: token,
                user: res?.data?.data,
              })
            );
            axios
              .get(
                `${process.env.REACT_APP_DMT_API_PATH}/admin/orders/copyright/${res?.data?.data?._id}`
              )
              .then((response) => {
                console.log(response.data.data);
                setData(response?.data?.data);
                if (response?.data?.data.length < 2 || response?.data === []) {
                  navigate("/artist/dashboard");
                  setIsLoading(false);
                  // console.log("me");
                } else if (response?.data?.data.length > 3) {
                  toast.error("Account Blocked");
                  setIsLoading(false);

                  setTimeout(() => {
                    navigate("/signup/artist");
                  }, 800);
                } else if (
                  response?.data?.data.length == 2 ||
                  response?.data?.data.length == 3
                ) {
                  localStorage.setItem(
                    "caution",
                    JSON.stringify(
                      "CAUTION! You have attempted to upload copyrighted song twice on dashboard. After 3 failed attempts, This account will be blocked from distribution permanently *DeliverMyTune reserves the right to admission and seizer of royalty distribution"
                    )
                  );
                  //  navigate("/artist/dashboard");
                  navigate("/artist/dashboard", {
                    state: {
                      id: "CAUTION! You have attempted to upload copyrighted song twice on dashboard. After 3 failed attempts, This account will be blocked from distribution permanently *DeliverMyTune reserves the right to admission and seizer of royalty distribution",
                    },
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });

      //   localStorage.setItem("login", JSON.stringify(token));
      //   navigate("/artist/dashboard");
    }
  }, [seachQuery]);

  //   }
  return loading ? (
    <ScaleLoader
      size={350}
      className="music-loader"
      textAlign="center"
      color="#FBCE2E"
    />
  ) : (
    <div>
      <ToastContainer />
    </div>
  );
};

export default AdminLogin;

import React from "react";
import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useEffect } from "react";
import Arrow from "../../../src/assets/img/arrow.svg";
import axios from "axios";

const LyricsUpload = ({songLyrics,setSongLyrics}) => {

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
        "doc/pdf": [".pdf", ".doc", ".docx"]
    },
 
  });
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setSongLyrics(file);
    }
  }, [acceptedFiles]);

  return (
    <>
      <Box sx={{ mb: "30px", background: " " }}>
        <Box
          sx={{
            background: "rgba(255, 255, 255, 0.05) !important",
            height: "auto",
            padding: "20px",
            borderRadius: "8px",
            // mt: "28px",
            // mx: { sm: "22px", xs: "0px" },
          }}
        >
          <Box
            sx={{ height: "280px !important" }}
            {...getRootProps({ className: "drop" })}
            onClick={() => {
              open();
            }}
          >
            <input {...getInputProps()} />
            <Box sx={{}}>
              <img src={Arrow} alt="img" />
            </Box>
            <Button type="button" className="upload-song">
              {"Upload Lyrics"}
            </Button>
            <Typography className="drop-song">
              Drop your Lyrics here, or{" "}
              <Typography variant="span" sx={{ color: "#FB900B" }}>
                browse
              </Typography>
            </Typography>
            <Typography className="support">Supports: .pdf .doc .docx Only </Typography>
            <aside>
              <ul style={{fontSize:'15px'}}>{songLyrics?.path || songLyrics}</ul>
            </aside>
          </Box>
        </Box>

      </Box>
    </>
  );
};

export default LyricsUpload;

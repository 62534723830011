import React from "react";
import Sitemap from "../components/sitemap/sitemap";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Navbar from "../components/common/navbar/index";
import Footer from "../components/common/footer/index";
import CssBaseline from "@mui/material/CssBaseline";
const themeLight = createTheme({
  palette: {
    mode: "light",
  },
});
const Sitemapfun = () => {
  return (
    <div>
      <ThemeProvider theme={themeLight}>
        <CssBaseline />
        <Navbar />
        <Sitemap />
        <Footer />
      </ThemeProvider>
    </div>
  );
};

export default Sitemapfun;

import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MOCK_CORE_VALUES } from "../utils/constant";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { ClickAwayListener, Divider, Tooltip, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Pagination from "@mui/material/Pagination";
import usePagination from "./pagination";
import Stack from "@mui/material/Stack";
import "./style.css";
import moment from "moment/moment";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EditModal from "./commentModal";
import plusicon from "../../assets/img/+1icon.svg";
import { BsForwardFill } from "react-icons/bs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "center",
  },
}));

const currencies = [
  {
    value: "10",
    label: "10",
  },
  {
    value: "20",
    label: "20",
  },
  {
    value: "30",
    label: "30",
  },
  {
    value: "40",
    label: "40",
  },
];

const selectType = [
  {
    value: "select status",
    label: "Select Status",
  },
  {
    value: "incomplete",
    label: "Incomplete",
  },
  {
    value: "edit",
    label: "Edit",
  },
  {
    value: "required",
    label: "Required",
  },
  {
    value: "inreview",
    label: "Inreview",
  },
  {
    value: "distributed",
    label: "Distributed",
  },
  {
    value: "take down",
    label: "Taken Down From Sale",
  },
];

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "153px",
  [theme.breakpoints.up("xs")]: {
    marginLeft: theme.spacing(),
    width: "153px",
    borderRadius: "77px",
    // backgroundColor:"transparent",
    border: "0.5px",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.4, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing()})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    height: "44px",
    background: "transparent",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      fontSize: "12px",

      height: "22px",
      background: "transparent",
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));
export default function CustomizedTables({ data, setCareer }) {
  const navigate = useNavigate();
  // console.log(data,"andjkshgfgseufdsgfgsdufg")
  const [totalShow, setTotalShow] = React.useState("10");
  let [page, setPage] = useState(1);
  const [positions, setPositions] = useState("");
  const [searchfilter, setSearchfilter] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const PER_PAGE = 10;
  const [openModal, setOpenModal] = React.useState(false);
  const [commentdata, setCommentdata] = useState();
  const handleOpen = (row) => {
    setOpenModal(true);
    setCommentdata(row);
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  const [datawithfilter, setDatawithfilter] = useState(data);
  const handleSubmit = (id) => {
    // if(data?.status === 'incomplete'){
    //   setCareer(id)
    //   console.log("clicked")
    // }
    if (id.status === "incomplete" && id.type === "track") {
      setCareer(id);
      navigate(`/artist/singletrack/${id._id}`);
      window.scrollTo(0, 0);
    } else if (id.status === "incomplete" && id.type === "album") {
      setCareer(id);
      navigate(`/artist/album/${id._id}`);
      window.scrollTo(0, 0);
    } else if (id.status === "distributed") {
      setCareer(id);
      navigate(`/artist/summary/${id._id}`);
      window.scrollTo(0, 0);
    } else if (id.status === "inreview" || id.status === "approved") {
      setCareer(id);
      navigate(`/artist/summary/${id._id}`);
      window.scrollTo(0, 0);
    } else if (id.status === "take down") {
      setCareer(id);
      navigate(`/artist/summary/${id._id}`);
      window.scrollTo(0, 0);
    } else if (id.status === "edit required") {
      setCareer(id);
      if (id?.type === "album") {
        navigate(`/artist/change_artworkalbum/${id._id}`);
      } else {
        navigate(`/artist/change_artwork/${id._id}`);
      }
      window.scrollTo(0, 0);
    }
  };

  const handleSubmit2 = (id) => {
    // if(data?.status === 'incomplete'){
    //   setCareer(id)
    //   console.log("clicked")
    // }
    if (id.status === "distributed") {
      setCareer(id);
      navigate(`/artist/take_down/${id._id}`);
      window.scrollTo(0, 0);
    }
  };

  useEffect(
    () => {
      const results = !searchInput
        ? datawithfilter
        : datawithfilter?.filter((row) =>
            row?.tracks[0]?.name?.toLowerCase().includes(searchInput)
          );
      setSearchfilter(results);
      // console.log(results);
    },
    //   [data,searchInput]);                                                                                                                                                                               09
    [datawithfilter, searchInput]
  );

  useEffect(() => {
    setSearchfilter(
      datawithfilter?.filter((data) => data?.status?.match(positions))
    );
  }, [positions]);

  const count = Math.ceil(searchfilter.length / PER_PAGE);
  const _DATA = usePagination(searchfilter, PER_PAGE);

  const handleChange2 = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleChange = (event) => {
    setTotalShow(event.target.value);
  };
  const [select, setSelect] = React.useState(_DATA);

  const handleSelctChange = (event) => {
    setSelect(event.target.value);
  };

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          mb: "40px",
          mt: { xs: "10px", sm: "40px" },
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{ display: { xs: "block", sm: "none" }, mt: "10px", ml: "10px" }}
        >
          <Typography
            sx={{
              fontFamily: "Dm sans !important",
              fontWeight: "700",
              display: "flex",
            }}
          >
            Swipe Right To See Table Data{" "}
            <BsForwardFill
              style={{ color: "#f9a40d", fontSize: "32px", marginLeft: "10px" }}
            />
          </Typography>
        </Box>
        <Box
          sx={{
            mt: { sm: "40px" },
            mb: "30px",
            ml: "10px",
            display: { xs: "block", md: "flex" },
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", ml: "50px" }}></Box>

          <Box display={"flex"}>
            <Box
              display={"flex"}
              sx={{
                ml: { xs: "4px", sm: "50px" },
                mt: { xs: "10px", md: "0px" },
              }}
            >
              <Typography
                sx={{
                  mt: "15px",
                  mr: "12px",
                  color: "#E1E1E1",
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                  fontWeight: 400,
                  lineHeight: "21px",
                }}
              >
                {" "}
                Search
              </Typography>
              <Search sx={{ background: "rgb(30,30,30)" }}>
                <StyledInputBase
                  sx={{
                    border: "1px solid #E1E1E1",
                    borderRadius: "25px",
                    background: "rgb(30,30,30)",
                  }}
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  placeholder="Type here..."
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Box>
            <Box sx={{ mt: { xs: "10px", md: "0px" }, ml: "12px" }}>
              <TextField
                variant="standard"
                id="outlined-select-currency"
                select
                // onClick={showLabel}
                // label='select'
                className="select-status"
                defaultValue={"Select Status"}
                onChange={(e) => setPositions(e.target.value)}
                // helperText="Please select your currency"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                sx={{
                  borderRadius: "77px",
                  width: "151px",
                  height: "42px !important",
                  fontSize: "12px !important",
                  color: "#B9B9C3",
                  mr: "50px",
                  fontFamily: "DM sans !important",
                  border: "1px solid white",
                  padding: "11px !important",
                  lineHeight: "9px",
                  mt: {
                    xs: "4px !important",
                    sm: "5px !important",
                    md: "1px !important",
                  },
                  // marginBottom: "-8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "space-around",
                }}
              >
                {/* {selectType.map((option) => (
                    
                  <MenuItem key={option.value} value={option.value}>
                  <Typography  sx={{fontSize:'12px !important',fontWeight:400,fontFamily:'DM sans',pt:'4px'}}> {option.label}</Typography>
                  </MenuItem>
                ))} */}

                <MenuItem
                  value="Select Status"
                  sx={{
                    fontSize: "12px !important",
                    fontWeight: 400,
                    fontFamily: "DM sans",
                    pt: "4px",
                  }}
                >
                  <>Select Status</>
                </MenuItem>
                <MenuItem
                  value={"incomplete"}
                  sx={{
                    fontSize: "12px !important",
                    fontWeight: 400,
                    fontFamily: "DM sans",
                    pt: "4px",
                  }}
                >
                  Incomplete
                </MenuItem>
                <MenuItem
                  value={"inreview"}
                  sx={{
                    fontSize: "12px !important",
                    fontWeight: 400,
                    fontFamily: "DM sans",
                    pt: "4px",
                  }}
                >
                  Inreview
                </MenuItem>
                <MenuItem
                  value={"edit required"}
                  sx={{
                    fontSize: "12px !important",
                    fontWeight: 400,
                    fontFamily: "DM sans",
                    pt: "4px",
                  }}
                >
                  Edit Required
                </MenuItem>
                {/* <MenuItem
                  value={"required"}
                  sx={{
                    fontSize: "12px !important",
                    fontWeight: 400,
                    fontFamily: "DM sans",
                    pt: "4px",
                  }}
                >
                  Required
                </MenuItem> */}
                <MenuItem
                  value={"distributed"}
                  sx={{
                    fontSize: "12px !important",
                    fontWeight: 400,
                    fontFamily: "DM sans",
                    pt: "4px",
                  }}
                >
                  Distributed
                </MenuItem>
                <MenuItem
                  value={"take down"}
                  sx={{
                    fontSize: "12px !important",
                    fontWeight: 400,
                    fontFamily: "DM sans",
                    pt: "4px",
                  }}
                >
                  Taken Down From Sale
                </MenuItem>
                <MenuItem
                  value={"take down requested"}
                  sx={{
                    fontSize: "12px !important",
                    fontWeight: 400,
                    fontFamily: "DM sans",
                    pt: "4px",
                  }}
                >
                  Taken Down In Review
                </MenuItem>
              </TextField>
            </Box>
          </Box>
        </Box>
        <Table sx={{ minWidth: 1200 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontFamily: "DM sans" }} align="center">
                S.N.
              </StyledTableCell>
              <StyledTableCell sx={{ fontFamily: "DM sans" }} align="center">
                SONG TITLE
              </StyledTableCell>
              <StyledTableCell sx={{ fontFamily: "DM sans" }} align="center">
                IMAGE
              </StyledTableCell>
              <StyledTableCell sx={{ fontFamily: "DM sans" }} align="center">
                UPC
              </StyledTableCell>
              <StyledTableCell sx={{ fontFamily: "DM sans" }} align="center">
                DATE
              </StyledTableCell>
              <StyledTableCell sx={{ fontFamily: "DM sans" }} align="center">
                STATUS
              </StyledTableCell>
              <StyledTableCell sx={{ fontFamily: "DM sans" }} align="center">
                COMMENT
              </StyledTableCell>
              <StyledTableCell sx={{ fontFamily: "DM sans" }} align="center">
                ACTION
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_DATA.currentData()?.map((row, index) => (
              <StyledTableRow key={row}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ fontFamily: "DM sans" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{ display: "inline-block", fontSize: { xs: "14px" } }}
                    >
                      {" "}
                      {index + 1}
                    </Box>

                    <Box sx={{ display: "inline-block", marginLeft: "8px" }}>
                      {row?.status === "edit required" ? (
                        <img src={plusicon} width="26px" height="26px" />
                      ) : (
                        ""
                      )}
                    </Box>
                    <Box sx={{ display: "inline-block", marginLeft: "8px" }}>
                      {row?.status === "distributed" ? (
                        <img src={plusicon} width="26px" height="26px" />
                      ) : (
                        ""
                      )}
                    </Box>
                    <Box sx={{ display: "inline-block", marginLeft: "8px" }}>
                      {row?.status === "take down" ? (
                        <img src={plusicon} width="26px" height="26px" />
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                </StyledTableCell>

                {row?.tracks ? (
                  <StyledTableCell
                    align="center"
                    sx={{ fontFamily: "DM sans" }}
                  >
                    {row?.tracks[0]?.name}
                  </StyledTableCell>
                ) : (
                  ""
                )}
                <StyledTableCell align="center" sx={{ fontFamily: "DM sans" }}>
                  <img src={row?.cover} alt="" width="40px" height="40px" />
                </StyledTableCell>

                <StyledTableCell align="center" sx={{ fontFamily: "DM sans" }}>
                  {row?.upcCode}
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ fontFamily: "DM sans" }}>
                  {" "}
                  {moment(row?.releaseDate).format("DD-MM-YYYY")}
                </StyledTableCell>

                <StyledTableCell align="center" sx={{ fontFamily: "DM sans" }}>
                  <Button
                    sx={{
                      // background: "rgba(251, 206, 46, 0.2) !important",
                      color: "#FBCE2E",
                      borderRadius: "18px",
                      fontSize: "12px",
                      fontWeight: "400",
                      padding: "5px 20px",
                      fontFamily: "DM sans",
                      textTransform: "capitalize",
                    }}
                    className={`${
                      row?.status === "incomplete"
                        ? "hello"
                        : row?.status === "inreview"
                        ? "color-custom"
                        : row?.status === "distributed"
                        ? "color-ditrub"
                        : row?.status === "take down"
                        ? "color-black"
                        : row?.status === "approved"
                        ? "color-custom"
                        : "color-pink"
                    }`}
                    onClick={() => handleSubmit(row)}
                  >
                    {row.status === "take down"
                      ? "taken down from sale"
                      : row.status === "approved"
                      ? "inreview"
                      : row.status}
                  </Button>
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ fontFamily: "DM sans" }}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography sx={{ width: "130px" }}>
                      {" "}
                      {row?.comment?.length >= 30
                        ? `${row.comment.split(0, 30)}...`
                        : `${row.comment.split(0, 29)}`}
                    </Typography>
                  </Box>
                </StyledTableCell>
                {/* <StyledTableCell align="center">
                  <MoreVertIcon />
                </StyledTableCell> */}
                <StyledTableCell align="center">
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <PopupState
                      variant="popover"
                      popupId="demo-popup-popover"
                      sx={{ display: "inline-block" }}
                    >
                      {(popupState) => (
                        <div>
                          <MoreVertIcon
                            {...bindTrigger(popupState)}
                            sx={{ cursor: "pointer" }}
                          />
                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            {row?.status === "incomplete" ? (
                              <Typography
                                sx={{ p: 2, cursor: "pointer" }}
                                onClick={() => handleSubmit(row)}
                              >
                                Edit Song
                              </Typography>
                            ) : row?.status === "inreview" ||
                              row?.status === "approved" ? (
                              <Typography
                                sx={{ p: 2, cursor: "pointer" }}
                                onClick={() => handleSubmit(row)}
                              >
                                View
                              </Typography>
                            ) : row?.status === "take down" ? (
                              <div>
                                <Typography
                                  sx={{ p: 2, cursor: "pointer" }}
                                  onClick={() => handleSubmit(row)}
                                >
                                  View
                                </Typography>
                                {/* <Divider /> */}

                                <Typography
                                  onClick={() => {
                                    handleOpen(row);
                                  }}
                                  sx={{ p: 1, cursor: "pointer" }}
                                >
                                  View Comment
                                </Typography>
                              </div>
                            ) : row?.status === "edit required" ? (
                              <Box>
                                <Typography
                                  sx={{ p: 2, cursor: "pointer" }}
                                  onClick={() => handleSubmit(row)}
                                >
                                  Edit Now
                                </Typography>
                                <Divider />

                                <Typography
                                  onClick={() => {
                                    handleOpen(row);
                                  }}
                                  sx={{ p: 1, cursor: "pointer" }}
                                >
                                  View Comment
                                </Typography>
                              </Box>
                            ) : row?.status === "distributed" ? (
                              <>
                                <Typography
                                  sx={{
                                    p: 2,
                                    cursor: "pointer",
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleSubmit(row)}
                                >
                                  View
                                </Typography>
                                <Divider />
                                <Typography
                                  sx={{ p: 2, cursor: "pointer" }}
                                  onClick={() => handleSubmit2(row)}
                                >
                                  Take Down from Sale
                                </Typography>
                                <Divider />

                                <Typography
                                  onClick={() => {
                                    handleOpen(row);
                                  }}
                                  sx={{
                                    p: 1,
                                    cursor: "pointer",
                                    textAlign: "center",
                                  }}
                                >
                                  View Comment
                                </Typography>
                              </>
                            ) : (
                              ""
                            )}
                          </Popover>
                        </div>
                      )}
                    </PopupState>
                    <Box sx={{ display: "inline-block", marginLeft: "8px" }}>
                      {row?.status === "edit required" ? (
                        <img src={plusicon} width="26px" height="26px" />
                      ) : (
                        ""
                      )}
                    </Box>
                    <Box sx={{ display: "inline-block", marginLeft: "8px" }}>
                      {row?.status === "distributed" ? (
                        <img src={plusicon} width="26px" height="26px" />
                      ) : (
                        ""
                      )}
                    </Box>
                    <Box sx={{ display: "inline-block", marginLeft: "8px" }}>
                      {row?.status === "take down" ? (
                        <img src={plusicon} width="26px" height="26px" />
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <EditModal
          openModal={openModal}
          handleOpen={handleOpen}
          handleClose={handleClose}
          commentdata={commentdata}
        />

        <Box
          sx={{
            display: { sm: "flex" },
            alignItems: "baseline",
            justifyContent: "space-between",
            mt: "35px",
            mb: "15px",
          }}
        >
          <Typography
            sx={{
              pl: { xs: "10px", sm: "50px" },
              mb: { xs: 1, sm: 0 },
              fontFamily: "DM sans",
            }}
          >
            {/* Showing 1 to {PER_PAGE} of {data.length} Entries */}
          </Typography>
          <Stack spacing={2} sx={{ pr: { xs: "0px", sm: "50px" } }}>
            <Pagination
              color="warning"
              count={count}
              onChange={handleChange2}
              page={page}
              variant="outlined"

              // background='red'
              // sx={{color:'white',border:'2px solid red'}}
              // sx={{
              //   " .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon": {
              //     color: "#f9a40d  !important",
              //   },
              //   ".css-ykxwy-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected ":
              //     {
              //       background: "  #f9a40d ",
              //     },
              //   ".css-ykxwy-MuiButtonBase-root-MuiPaginationItem-root": {
              //     background: "Black",
              //   },
              // }}
            />
          </Stack>
        </Box>
      </TableContainer>
    </>
  );
}

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { getuser } from "./services/user";
import { LoginApi } from "./services/login";
import { getDashboard } from "../redux/services/dashboard";
import { userSlice } from "./slices/authSlice";
import { tokenSlice } from "./slices/tokenSlice";
import { sidebarSlice } from "./slices/sidebar";

export const store = configureStore({
  // reducerPath and reducer are created for us, which we can pass straight into the reducer parameter of configureStore.
  reducer: {
    // [getuser.reducerPath]: getuser.reducer,
    [LoginApi.reducerPath]: LoginApi.reducer,
    token: tokenSlice.reducer,
    user: userSlice.reducer,
    sidebar: sidebarSlice.reducer,
    [getDashboard.reducerPath]: getDashboard.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      // getuser.middleware,
      LoginApi.middleware,
      getDashboard.middleware,
    ]),
});

// It will enable to refetch the data on certain events, such as refetchOnFocus and refetchOnReconnect.
setupListeners(store.dispatch);

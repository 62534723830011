import { Box, Button, Typography, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import "./drag.css";
import { useDropzone } from "react-dropzone";

function Passbook({setPassbookfile,data,passbookfile}) {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    accept: {
  "document/.pdf": ['.pdf','.png','.jpg','.jpeg'],
    },
  });
const files = acceptedFiles.map((file) =>
    setPassbookfile(file)
  );

  return (
    <div {...getRootProps({ className: "basic-song-drop" })}>
      <input {...getInputProps()} />
      <Box sx={{ height: "70px",display:"flex" ,alignItems:'center' ,wordBreak:'break-all' }}>
      <Typography
            sx={{
              listStyle: "none",
              mt: "17px !important",
              mb: "17px !important",
              wordBreak: "break-all",
              fontSize:{xs:'10px' ,md:"none"}
            }}
          >
            {passbookfile?.path || passbookfile}
          </Typography>
      </Box>
      <IconButton
        type="button"
        className="upload-song"
      >
        <UploadFileIcon />
      </IconButton>
      <Typography className="drop-song">
      Drop or Choose your File
      </Typography>
    </div>
  );
}

export default Passbook;

import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import CustomButton from "../../common/customButton/index";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import * as yup from "yup";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";

const Index = ({ handleClose, view, getOrder, number, tokenedit }) => {
  const validationSchema = yup.object().shape({
    // whatsapp: yup.string().min(2, "Too Short!").required("Required*"),
    youtube: yup.string().required("Required*"),
    facebook: yup.string().required("Required*"),
    twitter: yup.string().required("Required*"),
  });

  const initialValues = {
    whatsapp: "",
    facebook: "",
    instagram: "",
    twitter: "",
    spotify: "",
    soundcloud: "",
    youtube: "",
  };
  useEffect(() => {
    if (view) {
      formik.setFieldValue("whatsapp", view?.whatsapp);
      formik.setFieldValue("facebook", view?.facebook);
      formik.setFieldValue("instagram", view?.instagram);
      formik.setFieldValue("twitter", view?.twitter);
      formik.setFieldValue("spotify", view?.spotify);
      formik.setFieldValue("soundcloud", view?.soundcloud);
      formik.setFieldValue("youtube", view?.youtube);
    }
  }, []);

  const onSubmit = async (values) => {
    const token = tokenedit;
    var formDatavalue = new FormData();
    formDatavalue.append("whatsapp", values.whatsapp);
    formDatavalue.append("facebook", values.facebook);
    formDatavalue.append("instagram", values.instagram);
    formDatavalue.append("twitter", values.twitter);
    formDatavalue.append("spotify", values.spotify);
    formDatavalue.append("soundcloud", values.soundcloud);
    formDatavalue.append("youtube", values.youtube);
    if (number == 0) {
      await axios
        .post(
          `${process.env.REACT_APP_DMT_API_PATH}/creative/social`,
          formDatavalue,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error !== true) {
            getOrder();
            handleClose();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (number == 1) {
      await axios
        .put(
          `${process.env.REACT_APP_DMT_API_PATH}/creative/social/${view?._id}`,
          formDatavalue,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error !== true) {
            getOrder();
            handleClose();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <>
      <Box id="contact">
        <Container maxWidth="md" sx={{padding:"12px"}}>
          <Box
            component="form"
            sx={{ mt: "0px", width: { md: "500px", xs: "100%" } }}
            onSubmit={formik.handleSubmit}
          >
            <Typography
              sx={{
                marginTop: "10px",
                marginLeft: "5px",
                fontFamily: "DM Sans",
              }}
            >
              Enter Whatsapp Link
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              placeholder="Enter Whatsapp Link"
              {...formik.getFieldProps("whatsapp")}
              onChange={(e) => {
                formik.setFieldValue("whatsapp", e.target.value);
              }}
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            />
            {formik.touched.whatsapp && formik.errors.whatsapp ? (
              <Typography
                sx={{
                  textAlign: "start",
                  marginLeft: "4px",
                  fontSize: "12px",
                }}
                color="error"
              >
                {formik.errors.whatsapp}
              </Typography>
            ) : null}

            <Typography
              sx={{
                marginTop: "10px",
                marginLeft: "5px",
                fontFamily: "DM Sans",
              }}
            >
              Enter Youtube Link
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              placeholder="Enter Youtube Link"
              {...formik.getFieldProps("youtube")}
              onChange={(e) => {
                formik.setFieldValue("youtube", e.target.value);
              }}
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            />
            {formik.touched.youtube && formik.errors.youtube ? (
              <Typography
                sx={{
                  textAlign: "start",
                  marginLeft: "4px",
                  fontSize: "12px",
                }}
                color="error"
              >
                {formik.errors.youtube}
              </Typography>
            ) : null}
            <Typography
              sx={{
                marginTop: "10px",
                marginLeft: "5px",
                fontFamily: "DM Sans",
              }}
            >
              Enter Twitter Link
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              placeholder="Enter Twitter Link"
              {...formik.getFieldProps("twitter")}
              onChange={(e) => {
                formik.setFieldValue("twitter", e.target.value);
              }}
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            />
            {formik.touched.twitter && formik.errors.twitter ? (
              <Typography
                sx={{
                  textAlign: "start",
                  marginLeft: "4px",
                  fontSize: "12px",
                }}
                color="error"
              >
                {formik.errors.twitter}
              </Typography>
            ) : null}
            <Typography
              sx={{
                marginTop: "10px",
                marginLeft: "5px",
                fontFamily: "DM Sans",
              }}
            >
              Enter Facebook Link
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              placeholder="Enter Facebook Link"
              {...formik.getFieldProps("facebook")}
              onChange={(e) => {
                formik.setFieldValue("facebook", e.target.value);
              }}
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            />
            {formik.touched.facebook && formik.errors.facebook ? (
              <Typography
                sx={{
                  textAlign: "start",
                  marginLeft: "4px",
                  fontSize: "12px",
                }}
                color="error"
              >
                {formik.errors.facebook}
              </Typography>
            ) : null}
            <Typography
              sx={{
                marginTop: "10px",
                marginLeft: "5px",
                fontFamily: "DM Sans",
              }}
            >
              Enter Spotify Link
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              name="spotify"
              placeholder="Enter Spotify Link"
              {...formik.getFieldProps("spotify")}
              onChange={(e) => {
                formik.setFieldValue("spotify", e.target.value);
              }}
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            />
            <Typography
              sx={{
                marginTop: "10px",
                marginLeft: "5px",
                fontFamily: "DM Sans",
              }}
            >
              Enter Instagram Link
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              name="instagram"
              placeholder="Enter Instagram Link"
              {...formik.getFieldProps("instagram")}
              onChange={(e) => {
                formik.setFieldValue("instagram", e.target.value);
              }}
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            />
            <Typography
              sx={{
                marginTop: "10px",
                marginLeft: "5px",
                fontFamily: "DM Sans",
              }}
            >
              Enter Soundcloud Link
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              name="soundcloud"
              placeholder="Enter Soundcloud Link"
              {...formik.getFieldProps("soundcloud")}
              onChange={(e) => {
                formik.setFieldValue("soundcloud", e.target.value);
              }}
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            />

            <div
              style={{
                textAlign: "center",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                <CustomButton
                buttonStyle='editFormBtn'
                  btnText={number == 1 ? "Save" : "Add"}
                  btntype="Submit"
             
                />
              </div>
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Index;

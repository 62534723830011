import { Button, Grid, InputBase } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import Modal from "./modal";
import { useLocation } from "react-router-dom";

const Unsplashimage = ({ setSaveimage, saveImage }) => {
  const [data, setData] = useState([]);
  const [sendimage, setSendimage] = useState();
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const loaderRef = useRef(null);
  const location = useLocation();
  const isAlbum = location?.state?.id;

  const handleOpen = (item) => {
    setOpen(true);
    setSendimage(item?.urls?.small);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      const randomQuery = generateRandomQuery();
      const response = await axios.get(
        `https://api.unsplash.com/search/photos`,
        {
          params: {
            query: randomQuery,
            per_page: 20,
            page: page,
            client_id: "qPl5z3Z7_TYZyaaSdDv3OLiOvob3IyOzccHrb6vWg7A",
          },
        }
      );
      const shuffledResults = response.data?.results.sort(
        () => 0.5 - Math.random()
      );
      const limitedResults = shuffledResults.slice(0, 20);
      setData(limitedResults); // Update state with new data
    };
    fetchData();

    // Fetch data every 320 mints
    const interval = setInterval(fetchData, 14400000);

    return () => clearInterval(interval);
  }, [page]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1 }
    );
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, []);

  const generateRandomQuery = () => {
    const words = ["song", "travel", "food", "nature", "love", "art"];
    const randomWord = words[Math.floor(Math.random() * words.length)];
    return randomWord;
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    const response = await axios.get(
      `https://api.unsplash.com/search/photos`,
      {
        params: {
          query: searchInput,
          per_page: 20,
          client_id: "qPl5z3Z7_TYZyaaSdDv3OLiOvob3IyOzccHrb6vWg7A",
        },
      }
    );
    setData(response.data?.results); // Update state with new data
  };

  return (
    <Box>
      <Box
        sx={{
          textAlign: "center",
          marginTop: "12px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderRadius: "25px",
            padding: "8px",
            width: { xs: "220px", sm: "270px" },
            height: "45px",
            border: "1px solid white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <InputBase
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Search"
            sx={{
              color: "white",
              "&::placeholder": { color: "white" },
              flexGrow: 1,
            }}
          />
        </Box>
        <Button
          onClick={handleSearch}
          variant="contained"
          sx={{ marginLeft: "10px" }}
        >
          Search
        </Button>
      </Box>

      <Grid container mt={2} spacing={2}>
        {data?.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <div>
              <img
                src={item?.urls?.small}
                onClick={() => handleOpen(item)}
                width="100%"
                height="400px"
                alt=""
              />
            </div>
          </Grid>
        ))}
      </Grid>
      <div ref={loaderRef}>Loading...</div>
      <Modal
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        handleOpen={handleOpen}
        sendimage={sendimage}
        setSaveimage={setSaveimage}
        saveImage={saveImage}
        isAlbum={isAlbum}
      />
    </Box>
  );
};

export default Unsplashimage;

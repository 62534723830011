import React from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
} from "@mui/material";
import { GoPlay } from "react-icons/go";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import upcoming from "../../../assets/img/upcoming.png";
import "./index.css";
import { useState } from "react";
import CustomButton from "../thirdEditBtn";
import AddIcon from "@mui/icons-material/Add";
import ReactPlayer from "react-player";
import VideoEditForm from "./upcomingForm";
import CloseIcon from "@mui/icons-material/Close";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";

function PaperComponent(props) {
  return <Paper sx={{ background: "white", overflow: "hidden" }} {...props} />;
}

const UpcomingConcerts = ({ data, getOrder, tokenedit }) => {
  const [videoUrl, setVideoUrl] = useState([]);
  const [videoplay, setVideoplay] = useState();
  const [number, setNumber] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [pics, setPics] = useState([]);
  const [openPlayer, setOpenPlayer] = React.useState(false);

  const removeImage = (id) => {
    setPics((oldState) => oldState.filter((item) => item.id !== id));
  };
  // useEffect(() => {
  //   setPics(Student_Data);
  // }, []);
  const handleOpen = (item) => {
    setOpen(true);
    setOpenPlayer(false);
    setVideoUrl(item);
  };

  const handleClose = () => setOpen(false);

  const handleClickOpen = (item) => {
    setOpenPlayer(true);
    setVideoplay(item);
  };

  const handleCloseplayer = () => {
    setOpenPlayer(false);
  };
  const cardData = [
    {
      id: 1,
      image: upcoming,

      add: " Pilton, Somerset, England",
      name: "    Primavera Sound",
    },
    {
      id: 2,
      image: upcoming,

      add: "  Pilton, Somerset, England2",
      name: "Glastonbury Festival",
    },
    {
      id: 3,
      image: upcoming,

      add: "    Barcelona, Spain 3",
      name: "Glastonbury Festival",
    },
    {
      id: 4,
      image: upcoming,

      add: "    Barcelona, Spain 4",
      name: "    Primavera Sound",
    },
  ];

  const slidesToShow = data?.videos?.length > 3 ? 3.1 : data?.videos?.length;
  const settings = {
    fade: false,
    dots: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    initialSlide: 1,
    infinite: true,
    arrows: false,
    centerMode: true,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: slidesToShow,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: data?.videos?.length >= 2 ? 2 : 1,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: false,
          centerMode: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <Box sx={{ background: "#fff", minHeight: "500px" }}>
      <Container>
        <Typography
          sx={{
            fontSize: { md: "56px", xs: "36px" },
            color: "black",
            fontFamily: "Monoton",
            textAlign: "center",
            textTransform: "uppercase",
            pt: { xs: 6, md: 6 },
          }}
        >
          our concerts
        </Typography>
        <Box
          sx={{
            textAlign: "center",
            marginLeft: { md: "20px", xs: "0px" },
            marginTop: { lg: "15px", md: "15px", xs: "0px" },
          }}
        >
          {tokenedit ? (
            <CustomButton
              btnText="Add"
              handleClick={() => {
                handleOpen();
                setNumber(0);
              }}
              startIcon={<AddIcon sx={{ marginRight: "5px" }} />}
              width="75px"
              height="30px"
              border="1px solid #ffffff !important"
              padding="15px !important"
              fontSize="14px !important"
              fontWeight="600 !important"
            />
          ) : (
            ""
          )}
        </Box>

        <Box textAlign="-webkit-center">
          {data?.videos?.length === 0 ? (
            <>
              <Box
                sx={{
                  margin: "30px 0px",
                  padding: "0px !important",
                }}
              >
                {tokenedit ? (
                  <Box
                    sx={{
                      border: "2px dashed #4DCAB4 ",
                      width: { md: "760px", xs: "280px" },
                      height: "210px !important",
                      textAlign: "center",
                      margin: "auto",
                    }}
                    onClick={() => {
                      handleOpen();
                      setNumber(0);
                    }}
                  >
                    <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                      <Typography
                        sx={{
                          color: "#4DCAB4",
                          fontSize: "30px",
                          lineHeight: "20px",
                        }}
                      >
                        +
                      </Typography>
                      <Typography
                        sx={{
                          color: "#4DCAB4",
                          fontSize: "20px",
                          fontWeight: "700",
                        }}
                      >
                        Add Your First Video Here
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      border: "2px dashed #4DCAB4",
                      width: { md: "760px", xs: "280px" },
                      height: "210px !important",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  >
                    <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                      {/* <Typography>X</Typography> */}
                      <Typography sx={{ color: "#4DCAB4" }}>
                        Video is Empty
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <Slider className="concert2" {...settings}>
              {data?.videos?.map((item, index) => (
                <Box sx={{ p: 2 }}>
                  <Card
                    key={index}
                    sx={{
                      borderRadius: "16px",
                      maxWidth: "400px",
                      height: "227px",
                    }}
                  >
                    <Box sx={{ position: "relative", height: "227px" }}>
                      <img
                        src={item?.bannerImage}
                        alt=""
                        style={{ width: "100%", height: "100%" }}
                      />
                      {tokenedit ? (
                        <Box
                          sx={{
                            marginLeft: {
                              lg: "-90px !important",
                              xs: "-90px !important",
                            },
                            position: "absolute",
                            top: { md: "50px", xs: "70px" },
                            right: { xs: "7px" },
                            marginTop: {
                              lg: "-44px",
                              md: "-44px",
                              xs: "-70px",
                            },
                            // display: { lg: "block", xs: "flex" },
                          }}
                          onClick={() => {
                            handleOpen(item);
                            setNumber(1);
                          }}
                        >
                          <ModeEditOutlinedIcon
                            className="eddtBtn"
                            sx={{ marginRight: "5px" }}
                          />
                        </Box>
                      ) : (
                        ""
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          position: "absolute",
                          bottom: "20px",
                          left: "20px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <GoPlay
                            fontSize="45px"
                            color="white"
                            cursor="pointer"
                            onClick={() => {
                              handleClickOpen(item);
                            }}
                          />
                        </Box>
                        <Box sx={{ ml: 1 }}>
                          <Typography
                            sx={{
                              fontSize: "21px",
                              lineHeight: "30px",
                              fontFamily: "Montserrat",
                              fontWeight: "900",
                              textAlign: "left",
                            }}
                          >
                            {item?.youtubetitle}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                </Box>
              ))}
            </Slider>
          )}
        </Box>
        <Dialog
          open={openPlayer}
          onClose={handleCloseplayer}
          maxWidth="lg"
          sx={{ overflow: "hidden !important" }}
        >
          <ReactPlayer
            url={videoplay?.youtubelink}
            controls={true}
            className="videoplayer"
          />
        </Dialog>
      </Container>

      <div>
        <Dialog
          height="auto"
          style={{ zIndex: "9999922" }}
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <Box textAlign="end">
            <IconButton sx={{ color: "black" }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <DialogTitle
              style={{ cursor: "move" }}
              align="center"
              mt="-50px"
              id="draggable-dialog-title"
              fontFamily="DM Sans"
              sx={{ color: "black" }}
            >
              Upload Youtube Video
            </DialogTitle>
          </Box>

          <VideoEditForm
            view={videoUrl}
            handleClose={handleClose}
            getOrder={getOrder}
            number={number}
            tokenedit={tokenedit}
          />
        </Dialog>
      </div>
    </Box>
  );
};

export default UpcomingConcerts;

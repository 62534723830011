import { Container } from "@mui/system";
import React from "react";
import Box from "@mui/material/Box";
import "./style.css";
import { Grid, Typography } from "@mui/material";
import Uploadimage from "../../assets/img/c1.png";
import Mobile from "../../assets/img/c2.png";
import Music from "../../assets/img/c3.png";
import Arrow from "../../assets/img/c4.png";

const Index = () => {
  return (
    <Box sx={{px:{xs:'25px',sm:'40px'}}}>
      <Box sx={{textAlign:'center',marginTop:{md:'60px',xs:'20px'}}}> 
        <Typography variant="h4" className="sellartisthead">
        Our Artist Community
        </Typography>
        <br/>
        <Typography variant="p" className="sellartist">
        Creators, believers & artist who believes in everything music!
        </Typography>
      </Box>
      <Grid container spacing={4} mt={2} sx={{ paddingBottom: "60px" }}>
        <Grid item md={3} sm={6} xs={12} className="block">
          <Box className="Number-item-label-artist">
            <img
              src={Uploadimage}
              width="100%"
              height="100%"
              className="uploadimage"
            />
          </Box>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Box className="Number-item-label-artist">
            <img
              src={Mobile}
              width="100%"
              height="100%"
              className="uploadimage"
            />
          </Box>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Box className="Number-item-label-artist">
            <img
              src={Music}
              width="100%"
              height="100%"
              className="uploadimage"
            />
          </Box>
        </Grid>
        <Grid item md={3} sm={6} xs={12} className="block">
          <Box className="Number-item-label-artist">
            <img
              src={Arrow}
              width="100%"
              height="100%"
              className="uploadimage"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Index;

import React from "react";
import {
  Box,
  Card,
  Container,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import upcoming from "../../../assets/img/upcoming.png";
import "../upcomingConcerts/index.css";
import { AiFillStar } from "react-icons/ai";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CustomButtonedit from "../thirdEditBtn";
import TestimonialEdit from "./testimonialForm";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import './style.css';
import { Rating } from "react-simple-star-rating";

function PaperComponent(props) {
  return <Paper sx={{ background: "white" }} {...props} />;
}

const Testimonial = ({ data, getOrder, tokenedit }) => {
  const [viewmodal, setViewmodal] = useState([]);
  const [number, setNumber] = useState(0);
  const [open, setOpen] = React.useState(false);

  const handleOpen = (value) => {
    setOpen(true);
    setViewmodal(value);
  };

  const handleClose = () => setOpen(false);
  const cardData = [
    {
      id: 1,
      image: upcoming,
      Date: "Feb 5, 2022 | Sunday",
      title:
        ' "We love Landingfolio! Our designers were using it for their projects, so we already knew what kind of design they want."',
      name: " Jenny Wilson",
      post: "Grower.io",
    },
    {
      id: 2,
      image: upcoming,
      Date: "Feb 9, 2022 | Thursday",
      title:
        '  "We love Landingfolio! Our designers were using it for their projects, so we already knew what kind of design they want."2',
      name: "Devon Lane",
      post: "Grower.io",
    },
    {
      id: 3,
      image: upcoming,
      Date: "Feb 17, 2022 | Thursday",
      title:
        '  "We love Landingfolio! Our designers were using it for their projects, so we already knew what kind of design they want."3',
      name: "Devon Lane",
      post: "DLDesign.co",
    },
    {
      id: 4,
      image: upcoming,
      Date: "Feb 9, 2022 | Thursday",
      title:
        '  "We love Landingfolio! Our designers were using it for their projects, so we already knew what kind of design they want."4',
      name: " Jenny Wilson",
      post: "DLDesign.co",
    },
  ];

  const [rating, setRating] = useState();

  // Catch Rating value
  //   const handleRating = () => {
  //     setRating(4);
  //   };

  const slidesToShow = data?.testimonial?.length >= 2 ? 2 : 1;

  const settings = {
    fade: false,
    dots: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    initialSlide: 1,
    infinite: true,
    arrows: false,
    centerMode: false,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: slidesToShow,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: slidesToShow,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        background: "#fff",
        minHeight: { md: "500px", xs: "750px", sm: "600px" },
      }}
    >
      <Container>
        <Typography
          sx={{
            fontSize: { md: "56px", xs: "36px" },
            fontFamily: "Monoton",
            textAlign: "center",
            textTransform: "uppercase",
            color: "#1E1E1E",
          }}
        >
          Testimonial
        </Typography>
        <Box
          sx={{
            textAlign: "center",
            marginLeft: { md: "20px", xs: "0px" },
            marginTop: { lg: "15px", md: "15px", xs: "0px" },
          }}
        >
          {tokenedit ? (
            <CustomButtonedit
              btnText="Add"
              handleClick={() => {
                handleOpen();
                setNumber(0);
              }}
              startIcon={<AddIcon sx={{ marginRight: "5px" }} />}
              width="75px"
              height="30px"
              border="1px solid #ffffff !important"
              padding="15px"
            />
          ) : (
            ""
          )}
        </Box>

        <Box textAlign="-webkit-center" sx={{ mb: 5 }}>
          {data?.testimonial?.length === 0 ? (
            <>
              <Box
                sx={{
                  margin: "30px 0px",
                  padding: "0px !important",
                }}
              >
                {tokenedit ? (
                  <Box
                    sx={{
                      border: "2px dashed #4DCAB4 ",
                      width: { md: "760px", xs: "280px" },
                      height: "210px !important",
                      textAlign: "center",
                      margin: "auto",
                    }}
                    onClick={() => {
                      handleOpen();
                      setNumber(0);
                    }}
                  >
                    <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                      <Typography
                        sx={{
                          color: "#4DCAB4",
                          fontSize: "30px",
                          lineHeight: "20px",
                        }}
                      >
                        +
                      </Typography>
                      <Typography
                        sx={{
                          color: "#4DCAB4",
                          fontSize: "20px",
                          fontWeight: "700",
                        }}
                      >
                        Add Your First Video Here
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      border: "2px dashed #4DCAB4",
                      width: { md: "760px", xs: "280px" },
                      height: "210px !important",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  >
                    <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                      {/* <Typography>X</Typography> */}
                      <Typography sx={{ color: "#4DCAB4" }}>
                        Video is Empty
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <Box textAlign="-webkit-center">
              <Slider className="concert2" {...settings}>
                {data?.testimonial?.map((item, index) => (
                  <Box textAlign="left" sx={{ m: { sm: 3, xs: 0 } }}>
                    <Card
                      key={index}
                      sx={{
                        borderRadius: "20px",
                        border: "1px solid #4DCAB4",
                        height: { xs: "100%", md: "240px" },
                        maxWidth: { lg: "620px", md: "620px", xs: "auto" },
                        background: "white",
                        m: 3,
                      }}
                    >
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Box
                            sx={{
                              height: { xs: "240px", sm: "240px", md: "240px" },
                              width: { xs: "240px", sm: "240px" },
                              borderRadius: "20px",
                              overflow: "hidden",
                              mt: { xs: 2.5, sm: 0 },
                            }}
                          >
                            <img
                              src={item?.testimg}
                              alt=""
                              width={"100%"}
                              height="100%"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} sx={{ p: 2.3 }}>
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            ></Box>
                            <Typography
                              sx={{ color: "#4DCAB4", fontSize: "22px" }}
                            >
                              {/* <AiFillStar color="#4DCAB4" fontSize="22px" />{" "}
                              <AiFillStar color="#4DCAB4" fontSize="22px" />{" "}
                              <AiFillStar color="#4DCAB4" fontSize="22px" />{" "}
                              <AiFillStar color="#4DCAB4" fontSize="22px" />{" "}
                              <AiFillStar color="#4DCAB4" fontSize="22px" /> */}
                              <Rating
                                initialValue={item?.rating}
                                readonly={true}
                               className="custom-rating"
                                // size="22px !important"
                                disableFillHover={true}
                              />
                            </Typography>

                            <Box
                              sx={{
                                marginLeft: {
                                  lg: "70px",
                                  xs: "10px !important",
                                },

                                display: { lg: "block", xs: "flex" },
                              }}
                            >
                              {tokenedit ? (
                                <CustomButtonedit
                                  handleClick={() => {
                                    handleOpen(item);
                                    setNumber(1);
                                  }}
                                  startIcon={
                                    <ModeEditOutlinedIcon
                                      sx={{ marginRight: "5px" }}
                                    />
                                  }
                                  width="20px !important"
                                  height="20px !important"
                                  border="1px solid #00D8C0 !important"
                                  padding="15px"
                                />
                              ) : (
                                ""
                              )}
                            </Box>
                            <Typography
                              sx={{
                                fontSize: "18px",
                                lineHeight: "28px",
                                fontFamily: "Montserrat",
                                color: "#1E1E1E",
                                fontWeight: "700",
                              }}
                            >
                              {item?.description}
                            </Typography>
                            <Box sx={{ display: "flex", mt: 2 }}>
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  lineHeight: "28px",
                                  fontFamily: "Montserrat",
                                  color: "#1E1E1E",
                                  fontWeight: "700",
                                }}
                              >
                                {item?.testname}
                              </Typography>

                              <Typography
                                sx={{
                                  ml: 1.5,
                                  fontSize: "16px",
                                  lineHeight: "28px",
                                  fontFamily: "Open Sans",
                                  textTransform: "uppercase",
                                  color: "#64748B",
                                  fontWeight: "500",
                                }}
                              >
                                {item?.testdesign}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  </Box>
                ))}
              </Slider>
            </Box>
          )}
        </Box>
      </Container>

      <div>
        <Dialog
          height="auto"
          style={{ zIndex: "9999922" }}
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <Box textAlign="end">
            <IconButton sx={{ color: "black" }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <DialogTitle
              style={{ cursor: "move" }}
              align="center"
              mt="-50px"
              id="draggable-dialog-title"
              sx={{ color: "black" }}
            >
              Testimonial
            </DialogTitle>
          </Box>

          <TestimonialEdit
            view={viewmodal}
            handleClose={handleClose}
            getOrder={getOrder}
            number={number}
            tokenedit={tokenedit}
          />
        </Dialog>
      </div>
    </Box>
  );
};

export default Testimonial;

export const TableHeader = [
    {
        id : 1 ,
        label : "S.No" ,
    },
    {
        id: 2,
        label : "Referred Number",
        var: "referedNumber"
    },
    {
        id : 3 ,
        label : "Name" ,
        var : "name"
    },
    {
        id : 4 ,
        label : "Coins" ,
        var : "coins"
    },
    {
        id : 5 ,
        label : "User Type",
        var : "referralType"
    },
    {
        id : 6 ,
        label : "Transaction Type",
        var : "transactionType"
    },
    {
        id : 7,
        label : "Sign-up Date" ,
        var : "creadtedAt"
    },
]
import React, { useState, useEffect } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import {
  Box,
  Button,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import Arrow from "../../src/assets/img/arrow.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { toast } from "react-toastify";

const SongMastering = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [songValue, setSongValue] = useState("");
  const [songName, setSongName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("online");
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: { "audio/.wav": [".wav"] },
  });
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setOpenSidebar((p) => !p);
  };

  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;
  const userid = loginToken?.user?._id;

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setSongValue(file);
      setSongName(file?.name);
    }
  }, [acceptedFiles]);

  const fetchWalletBalance = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await axios
      .get(
        `${process.env.REACT_APP_DMT_API_PATH}/wallet/?user_id=${userid}`,
        config
      )
      .then((res) => {
        setWalletBalance(res.data.data.total[0].total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchWalletBalance();
  }, []);

  const checkoutHandler = async () => {
    setIsLoading(true);

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const formData = new FormData();
    formData.append("file", songValue);
    formData.append("songName", songName);

    if (selectedPaymentOption === "wallet-online") {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_DMT_API_PATH}/mastered/mastering-payment`,
          formData,
          config
        );

        const orderData = res.data.data;
        if (orderData) {
          setIsLoading(false);
        }

        if (res.data.error !== true) {
          const totalAmount = orderData.amount / 100;
          let remainingAmount = totalAmount;

          if (walletBalance > 0) {
            if (walletBalance >= totalAmount) {
              remainingAmount = 0;
              formData.append("wallet_used", true);
              formData.append("wallet_amount", totalAmount);
            } else {
              remainingAmount -= walletBalance;
            }
          }

          const payload2 = {
            amount: totalAmount,
            userId: userid,
            paymentId: orderData.id,
            paymentmode: selectedPaymentOption,
          };

          const data = await axios.post(
            `${process.env.REACT_APP_DMT_API_PATH}/mastered/deduct-wallet`,
            payload2,
            config
          );

          if (remainingAmount > 0) {
            const options = {
              key: "rzp_live_UOZD55VDM3YeQI",
              amount: data.data.data.amount,
              currency: "INR",
              name: "DELIVER MY TUNE",
              description: "RazorPay",
              order_id: data.data.data.razorpayOrderId,
              handler: async function (response) {
                const payload = {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                };

                try {
                  const verifyRes = await axios.post(
                    `${process.env.REACT_APP_DMT_API_PATH}/public/payments/verify`,
                    payload,
                    config
                  );

                  if (verifyRes.data.error !== true) {
                    toast.success("Payment successful");
                    navigate("/artist/audio");
                  } else {
                    toast.error(verifyRes.data.message);
                  }
                } catch (error) {
                  toast.error("Failed to verify payment");
                }
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#121212",
              },
            };

            const razor = new window.Razorpay(options);
            razor.open();
          } else {
            toast.success("Payment successful");
            navigate("/artist/audio");
          }
        } else {
          toast.error(res.data.message);
        }
      } catch (err) {
        toast.error(err.message);
      }
    } else {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_DMT_API_PATH}/mastered/mastering-payment`,
          formData,
          config
        );

        const orderData = res.data.data;
        if (orderData) {
          setIsLoading(false);
        }

        if (res.data.error !== true) {
          const razorpayOrderId = orderData.order_id;
          const orderAmount = orderData.amount / 100;

          if (
            selectedPaymentOption === "wallet" &&
            walletBalance >= orderAmount
          ) {
            formData.append("wallet_used", true);
            formData.append("wallet_amount", orderAmount);
            const payload2 = {
              amount: orderAmount,
              userId: userid,
              paymentId: orderData.identity.id,
              paymentmode: selectedPaymentOption,
            };

            await axios
              .post(
                `${process.env.REACT_APP_DMT_API_PATH}/mastered/deduct-wallet`,
                payload2,
                config
              )
              .then((res) => {
                if (res.data.error !== true) {
                  toast.success("Payment successful");
                  navigate("/artist/audio");
                } else {
                  toast.error(res.data.message);
                }
              })
              .catch((err) => {
                toast.error(err.message);
              });
          } else {
            const options = {
              key: "rzp_live_UOZD55VDM3YeQI",
              amount: orderAmount,
              currency: "INR",
              name: "DELIVER MY TUNE",
              description: "RazorPay",
              order_id: razorpayOrderId,
              handler: async function (response) {
                const payload = {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                };

                try {
                  const verifyRes = await axios.post(
                    `${process.env.REACT_APP_DMT_API_PATH}/public/payments/verify`,
                    payload,
                    config
                  );

                  if (verifyRes.data.error !== true) {
                    toast.success("Payment successful");
                    navigate("/artist/audio");
                  } else {
                    toast.error(verifyRes.data.message);
                  }
                } catch (error) {
                  toast.error("Failed to verify payment");
                }
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#121212",
              },
            };

            const razor = new window.Razorpay(options);
            razor.open();
          }
        } else {
          toast.error("Something went wrong");
        }
      } catch (err) {
        toast.error(err.message);
      }
    }
  };

  return (
    <>
      <div className="container">
        <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
        <div className="main-container">
          <Navbar toggleSidebar={toggleSidebar} />
          {isLoading ? (
            <ScaleLoader
              size={350}
              textAlign="center"
              className="music-loader"
              color="#FBCE2E"
            />
          ) : (
            <div className="pages">
              <Box sx={{ my: "30px" }}>
                <Box
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "auto",
                    padding: "30px",
                    borderRadius: "8px",
                    mt: "28px",
                    mx: { sm: "22px", xs: "0px" },
                  }}
                >
                  <Typography className="summary_card_title" sx={{ mb: 3 }}>
                    Get Your Song Mastered At Rs. 999 Only.
                  </Typography>
                  {/* Display selected payment option */}
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body1">
                      Selected Payment Option:{" "}
                      {selectedPaymentOption === "online"
                        ? "Online Payment"
                        : selectedPaymentOption === "wallet"
                        ? "Wallet Payment"
                        : "Wallet and Online Payment"}
                    </Typography>
                  </Box>

                  <RadioGroup
                    value={selectedPaymentOption}
                    onChange={(e) => setSelectedPaymentOption(e.target.value)}
                  >
                    <FormControlLabel
                      value="online"
                      control={<Radio />}
                      label="Online Payment"
                    />
                    {walletBalance > 0 && (
                      <>
                        {walletBalance >= 999 && (
                          <FormControlLabel
                            value="wallet"
                            control={<Radio />}
                            label={`Wallet Payment (₹${walletBalance})`}
                          />
                        )}
                        {walletBalance < 999 && (
                          <FormControlLabel
                            value="wallet-online"
                            control={<Radio />}
                            label={`Wallet and Online Payment (Wallet Balance: ₹${walletBalance})`}
                          />
                        )}
                      </>
                    )}
                  </RadioGroup>
                  <Box
                    sx={{ height: "350px !important", marginTop: "20px" }}
                    {...getRootProps({ className: "drop" })}
                    onClick={() => {
                      open();
                    }}
                  >
                    <input {...getInputProps()} />
                    <Box>
                      <img src={Arrow} alt="img" />
                    </Box>
                    <Button type="button" className="upload-song">
                      {"Upload Song"}
                    </Button>
                    <Typography className="drop-song">
                      Drop your Upload Song here, or{" "}
                      <Typography variant="span" sx={{ color: "#FB900B" }}>
                        browse
                      </Typography>
                    </Typography>
                    <Typography className="support">
                      Supports: .WAV Only{" "}
                    </Typography>
                    <aside>
                      <ul>{songValue?.path || songValue}</ul>
                    </aside>
                  </Box>
                </Box>

                <Box textAlign={"end"}>
                  <Button
                    variant="contained"
                    className="paymentBtn"
                    sx={{ mr: { sm: "33px", xs: "0px" } }}
                    onClick={checkoutHandler}
                  >
                    Proceed to Payment
                  </Button>
                </Box>
              </Box>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SongMastering;

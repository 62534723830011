import React, { Component } from "react";
import "./style.css";
import Slider from "react-slick";
import img01 from "../ourChanner/Images/img01.png";
import img02 from "../ourChanner/Images/img02.png";
import img03 from "../ourChanner/Images/img03.png";
import img04 from "../ourChanner/Images/img04.png";
import img05 from "../../assets/img/partner-1.svg";
import img06 from "../ourChanner/Images/img06.png";
import img07 from "../ourChanner/Images/img07.png";
import img08 from "../ourChanner/Images/img08.png";
import img09 from "../ourChanner/Images/img09.png";
import img10 from "../ourChanner/Images/img10.png";
import img11 from "../../assets/img/partner-2.svg";
import img12 from "../ourChanner/Images/img12.png";
import img13 from "../ourChanner/Images/img13.png";
import img14 from "../ourChanner/Images/img14.png";
// import img15 from '../ourChanner/Images/img15.png'
import img16 from "../ourChanner/Images/img16.png";
import img17 from "../ourChanner/Images/img17.png";
import img18 from "../ourChanner/Images/img18.png";
import img19 from "../../assets/img/partner.svg";
import img20 from "../ourChanner/Images/img20.png";
// import img21 from "../ourChanner/Images/img21.png";

import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export default class AutoPlay extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000,
      speed: 500,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <Container
        sx={{ background: "white !important", maxWidth: "1200px !important" }}
      >
        <Box sx={{ marginTop: "50px" }}>
          <Typography className="trust-client" variant="h4">
            {" "}
            Our Channel Partners{" "}
          </Typography>
        </Box>
        <Box sx={{ marginTop: "20px", marginBottom: "50px" }}>
          <Slider {...settings} className="musicapp">
            {imageData.map((partnerImg) => (
              <Box
                key={partnerImg.id}
                sx={{ px: "10px", width: "100px", height: "40px" }}
                mb={1}
              >
                <img
                  src={partnerImg.img}
                  width="100%"
                  alt=""
                  height="100%"
                  className="four-clients py-5"
                />
              </Box>
            ))}
          </Slider>
        </Box>
      </Container>
    );
  }
}

const imageData = [
  {
    id: 1,
    img: img01,
  },
  {
    id: 2,
    img: img02,
  },
  {
    id: 3,
    img: img03,
  },
  {
    id: 4,
    img: img04,
  },
  {
    id: 5,
    img: img05,
  },
  {
    id: 6,
    img: img06,
  },
  {
    id: 7,
    img: img07,
  },
  {
    id: 8,
    img: img08,
  },
  {
    id: 9,
    img: img09,
  },
  {
    id: 10,
    img: img10,
  },
  {
    id: 11,
    img: img11,
  },
  {
    id: 12,
    img: img12,
  },
  {
    id: 13,
    img: img13,
  },
  {
    id: 14,
    img: img14,
  },
  {
    id: 15,
    img: img01,
  },
  {
    id: 16,
    img: img16,
  },
  {
    id: 17,
    img: img17,
  },
  {
    id: 18,
    img: img18,
  },
  {
    id: 19,
    img: img19,
  },
  {
    id: 20,
    img: img20,
  },
  // {
  //   id: 21,
  //   img: img21,
  // },
];

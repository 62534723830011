import React from 'react'
import PublishingDetails from '../components/publishingDetails/index'
import { useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import { Container } from '@mui/material';
import BundlePackagetable from '../components/bundlePackage/index'

const BundlePackage = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const toggleSidebar = () => {
    setOpenSidebar((p) => !p);
  };
  return (
    <>
      <div className="container">
        <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
        <div className="main-container">
          <Navbar toggleSidebar={toggleSidebar} />
          <div className="pages">
       <Container>  
        <BundlePackagetable/>
       </Container>
          </div>
        </div>
      </div>
    </>
  )
}

export default BundlePackage
import React, { Component, useEffect } from "react";
import Slider from "react-slick";
import { useRef } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Container, Card, Box, Typography, Grid, Button } from "@mui/material";
import ReactPlayer from "react-player";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import CustomButton from "../editButton";
import "./style.css";
import VideoForm from "./videoform";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import "react-toastify/dist/ReactToastify.css";

function PaperComponent(props) {
  return <Paper {...props} />;
}
const Student_Data = [
  {
    id: 1,
    title: "Gangster Mashup | Slowed and Reverb | Sidhu Moose Wala |",
    url: "https://www.youtube.com/watch?v=kvdA9EsdcLc",
  },

  {
    id: 2,
    title: "Phool Gajra Gajra_ Dj Sagar Kanker",
    url: "https://www.youtube.com/watch?v=kvdA9EsdcLc",
  },

  {
    id: 3,
    title: "Brown munde | Slowed and reverb | Ap Dhillon | Gurinder Gill",
    url: "https://www.youtube.com/watch?v=kvdA9EsdcLc",
  },
  {
    id: 4,
    title: "Gangster Mashup | Slowed and Reverb | Sidhu Moose Wala |",
    url: "https://www.youtube.com/watch?v=kvdA9EsdcLc",
  },
];
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "green" }}
      onClick={onClick}
    />
  );
}

const Index = ({ value, data, getOrder, tokenedit }) => {
  const [viewmodal, setViewmodal] = useState([]);
  const [number, setNumber] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [pics, setPics] = useState([]);
  const removeImage = (id) => {
    setPics((oldState) => oldState.filter((item) => item.id !== id));
  };
  useEffect(() => {
    setPics(Student_Data);
  }, []);
  const handleOpen = (value) => {
    setOpen(true);
    setViewmodal(value);
  };
  const handleClose = () => setOpen(false);
  const sliderRef = useRef(null);
  console.log(data?.videos);
  const slidesToShow = data?.videos?.length < 3 ? data?.videos?.length : 3;
  console.log(slidesToShow);
  const settings = {
    fade: false,
    dots: false,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    initialSlide: 3,
    infinite: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: slidesToShow > 3 ? slidesToShow : slidesToShow,
          slidesToScroll: 1,
          initialSlide: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1384,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },

      {
        breakpoint: 930,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <>
      <Container
        sx={{
          maxWidth: "90% !important",
          marginTop: { md: "40px", xs: "0px" },
        }}
      >
        <Box
          id="video"
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "12px !important",
          }}
        >
          <Typography
            variant="h3"
            className="contact-data"
            sx={{
              fontSize: { md: "48px", xs: "32px" },
              textAlign: { xs: "center" },
            }}
          >
            YouTube Videos
          </Typography>
          <Box
            sx={{
              marginLeft: { md: "20px", xs: "0px" },
              marginTop: { lg: "15px", md: "15px", xs: "0px" },
            }}
          >
            {tokenedit ? (
              <CustomButton
                btnText="Add"
                handleClick={() => {
                  handleOpen();
                  setNumber(0);
                }}
                startIcon={<AddIcon sx={{ marginRight: "5px" }} />}
                width="75px"
                height="30px"
                border="1px solid #ffffff !important"
                padding="15px"
              />
            ) : (
              ""
            )}
          </Box>
        </Box>
        {data?.videos?.length !== 0 ? (
          <Grid container rowSpacing={{ lg: 3, md: 0, sm: 0, xs: 0 }}>
            <Grid
              item
              xs={12}
              sx={{ textAlign: "center", padding: "none !important" }}
            >
              <Box
                display={"flex"}
                sx={{
                  justifyContent: "center",
                  alignItems: "center !important",
                }}
              >
                <Button
                  onClick={() => sliderRef.current.slickPrev()}
                  sx={{
                    border: { md: "1px solid #ffffff", xs: "none" },
                    padding: "4px",
                    borderRadius: "50%",
                    width: "62px",
                    height: "62px",
                    mx: 1,
                    color: "#1B1B1B",
                    position: "absolute",
                    left: { lg: "90px", md: "2%", sm: "1%", xs: "-1%" },
                    marginTop: { lg: "300px", xs: "250px" },
                    zIndex: "1111",
                  }}
                >
                  <ArrowBackIosIcon
                    sx={{ color: "#ffffff !important" }}
                    className="arrow"
                  />
                </Button>
                <Button
                  onClick={() => sliderRef.current.slickNext()}
                  sx={{
                    border: { md: "1px solid #ffffff", xs: "none" },
                    padding: "4px",
                    borderRadius: "50%",
                    width: "62px",
                    height: "62px",
                    color: "#1B1B1B",
                    position: "absolute",
                    right: { lg: "94px", md: "3%", sm: "2%", xs: "1%" },
                    marginTop: { lg: "300px", xs: "250px" },
                    zIndex: "1111",
                  }}
                >
                  <ArrowForwardIosIcon
                    sx={{ color: "#ffffff !important" }}
                    className="arrow"
                  />
                </Button>
              </Box>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        <Box
          sx={{ position: "relative", margin: { lg: "30px auto", xs: "none" } }}
        >
          {data?.videos?.length === 0 ? (
            <>
              <Box
                sx={{
                  margin: "30px 0px",
                  padding: "0px !important",
                }}
              >
                {tokenedit ? (
                  <Box
                    sx={{
                      border: "2px dashed white",
                      width: { md: "760px", xs: "280px" },
                      height: "210px !important",
                      textAlign: "center",
                      margin: "auto",
                    }}
                    onClick={() => {
                      handleOpen();
                      setNumber(0);
                    }}
                  >
                    <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                      <Typography>+</Typography>
                      <Typography>Add Your First Youtube Video Here</Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      border: "2px dashed white",
                      width: { md: "760px", xs: "280px" },
                      height: "210px !important",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  >
                    <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                      <Typography>Youtube Video is Empty</Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <Slider ref={sliderRef} {...settings}>
              {data?.videos?.map((value, index) => (
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Card
                    sx={{
                      maxWidth: {
                        xs: "390px",
                      },
                      padding: "0px !important",
                      height: { sm: "310px", xs: "340px" },
                      background: "#121212",
                      margin: "auto !important",
                      borderRadius: "15px",
                    }}
                  >
                    <ReactPlayer
                      width={"100%"}
                      height={"221px"}
                      url={value?.youtubelink}
                      controls={true}
                      playing={false}
                      volume={1}
                    />

                    <Typography
                      component="div"
                      sx={{
                        fontSize: {
                          xs: "14px",
                          sm: "18px",
                        },
                        fontWeight: 500,
                        lineHeight: {
                          xs: "21px",
                          sm: "27px",
                        },
                        color: "#f1f1f1 !important",
                        padding: "10px",
                        fontFamily: "DM Sans",
                        backgroundColor: "#121212",
                      }}
                    >
                      {value?.youtubetitle}
                    </Typography>
                  </Card>
                  {tokenedit ? (
                    <Box
                      sx={{
                        // marginLeft: {
                        //   lg: "-90px !important",
                        //   xs: "-90px !important",
                        // },
                        marginTop: { lg: "-44px", md: "-44px", xs: "-70px" },
                        display: { lg: "block", xs: "flex" },
                        position: "absolute",
                        bottom: "10px",
                        right: { xs: "10px", sm: "23%", md: "10px" },
                      }}
                    >
                      <CustomButton
                        handleClick={() => {
                          console.log(value);
                          handleOpen(value);
                          setNumber(1);
                        }}
                        startIcon={
                          <ModeEditOutlinedIcon sx={{ marginRight: "5px" }} />
                        }
                        width="20px !important"
                        height="20px !important"
                        border="1px solid #ffffff !important"
                        padding="15px"
                      />
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              ))}
            </Slider>
          )}
        </Box>
        <div>
          <Dialog
            height="auto"
            style={{ zIndex: "9999922" }}
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
          >
            <Box textAlign="end">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <DialogTitle
                style={{ cursor: "move" }}
                align="center"
                mt="-50px"
                id="draggable-dialog-title"
                fontFamily="DM Sans"
              >
                Upload Youtube Video
              </DialogTitle>
            </Box>

            <VideoForm
              view={viewmodal}
              handleClose={handleClose}
              getOrder={getOrder}
              number={number}
              tokenedit={tokenedit}
            />
          </Dialog>
        </div>
      </Container>
    </>
  );
};

export default Index;

import { Container, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React from "react";
import TestM01 from "../testimonial/Images/TestM01.jpg";
import TestM02 from "../testimonial/Images/TestM02.jpg";

import "./style.css";

const Index = () => {
  return (
    <Box className="test-background">
      <Container sx={{ maxWidth: "1200px !important" }}>
        <Box>
          <Box className="testimonial-heading">
            <Typography
              variant="h1"
              className="testimonial-heading-para"
              color="white"
            >
              Words from our clients 😍
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12} sx={{ position: "relative" }}>
            <Card
              sx={{
                minWidth: 275,
                background: "white",
                height: { lg: "270px", md: "350px", xs: "auto" },
              }}
              className="card-item"
            >
              <CardContent sx={{ textAlign: "center", marginBottom: "25px" }}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ textAlign: "center" }}
                  className="title-testmonial"
                >
                  “Incredible Experience”
                </Typography>
                <Typography variant="p" className="description">
                  A truly fantastic choice for sharing music is Deliver My Tune.
                  It makes the procedure much less hectic and is a welcome break
                  from other distributors where you don't really get to speak to
                  people. The service is really individualized, and Deliver My
                  Tune clearly takes pride in their job."
                </Typography>
              </CardContent>
            </Card>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <img
                src={TestM01}
                alt="user1"
                style={{ width: "63px", height: "63px", borderRadius: "50%" }}
              />
              <Box sx={{ marginLeft: "10px" }}>
                <Typography
                  component={"div"}
                  sx={{
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "#E1E1E1",
                  }}
                >
                  Rakshit
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "white",
                  }}
                >
                  Independent Artist- Listen on Spotify
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} xs={12} sx={{ position: "relative" }}>
            <Card
              sx={{
                minWidth: 275,
                background: "white",
                height: { lg: "270px", md: "350px", xs: "auto" },
              }}
              className="card-item"
            >
              <CardContent sx={{ textAlign: "center", marginBottom: "25px" }}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ textAlign: "center" }}
                  className="title-testmonial"
                >
                  “Dependable, Responsive, Professional”
                </Typography>
                <Typography variant="p" className="description">
                  Words cannot adequately express how AMAZING Deliver My Tune
                  is. DMT is fully committed to supporting your goals for a
                  successful musical career! I've been doing music for more than
                  ten years. I've used services like CD Baby, Tunecore, and
                  Distrokid. Deliver My Tune is superior to everything else
                  available.
                </Typography>
              </CardContent>
            </Card>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <img
                src={TestM02}
                alt="user1"
                style={{ width: "63px", height: "63px", borderRadius: "50%" }}
              />
              <Box sx={{ marginLeft: "10px" }}>
                <Typography
                  component={"div"}
                  sx={{
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "#E1E1E1",
                  }}
                >
                  Darshita
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "white",
                  }}
                >
                  Independent Artist- Listen on Spotify
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Index;

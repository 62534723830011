import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { useState } from "react";
import { Box, Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./summaryCard.css";
import MusicIcon from "../../assets/img/musicIcon.svg";
import song from "../../assets/music/file_example_WAV_1MG.wav";
import { Link } from "react-router-dom";
import CustomButton from "../common/customButton/index";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
// interface ExpandMoreProps extends IconButtonProps {
//     expand: boolean;
//   }

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const production_images = [
  {
    id: 1,
    iamge: MusicIcon,
  },
  {
    id: 2,
    iamge: MusicIcon,
  },
  {
    id: 3,
    iamge: MusicIcon,
  },
  {
    id: 4,
    iamge: MusicIcon,
  },
  {
    id: 5,
    iamge: MusicIcon,
  },
  {
    id: 6,
    iamge: MusicIcon,
  },
  {
    id: 7,
    iamge: MusicIcon,
  },
  {
    id: 8,
    iamge: MusicIcon,
  },
  {
    id: 9,
    iamge: MusicIcon,
  },
];

const Index = ({ career }) => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const toggleSidebar = () => {
    setOpenSidebar((p) => !p);
  };

  const [expanded, setExpanded] = React.useState(true);
  const [expandedadv, setExpandedadv] = React.useState(true);
  const [expandedpub, setExpandedpub] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleExpandClickadv = (index) => {
    setExpandedadv(!expandedadv);
  };
  const handleExpandClickpub = () => {
    setExpandedpub(!expandedpub);
  };

  return (
    <>
      <div className="container">
        <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
        <div className="main-container">
          <Navbar toggleSidebar={toggleSidebar} />
          <div className="pages">
            <Box className="custom-dmt-container">
              <Typography className="take_sale">Summary</Typography>
              {/* -----------------------------Basic details--------------------------------------- */}
              <Card sx={{ maxWidth: "auto", borderRadius: "16px", px: 1 }}>
                <label for="basic">
                  <CardActions disableSpacing>
                    <IconButton
                      aria-label="add to favorites"
                      sx={{
                        fontFamily: "DM Sans",
                        fontSize: "24px",
                        fontWeight: "700",
                      }}
                    >
                      Basic Details
                    </IconButton>

                    <ExpandMore
                      expand={expanded}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                      id="basic"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </CardActions>
                </label>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Grid container spacing={0}>
                      <Grid xs={12} sm={7.8}>
                        {/* <Grid container mb={2} mr={5}>

                                                    <Grid item xs={6} spacing={5} sm={4}>
                                                        <Typography className='summary_card_title'>Your artwork</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} spacing={5} sm={8}>
                                                        <img src={MusicIcon} alt='' width={98} height={98} />
                                                    </Grid>
                                                </Grid>

                                                <Divider className='divider_card' /> */}

                        <Grid container mb={2} mt={4}>
                          <Grid item xs={6} spacing={5} sm={4}>
                            <Typography className="summary_card_title">
                              Record Label
                            </Typography>
                          </Grid>
                          <Grid item xs={6} spacing={5} sm={8}>
                            <Typography className="summary_card_subTitle">
                              {career?.recordLabel}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider className="divider_card" />

                        <Grid container mb={2} mt={4}>
                          <Grid item xs={6} spacing={5} sm={4}>
                            <Typography className="summary_card_title">
                              Release date
                            </Typography>
                          </Grid>
                          <Grid item xs={6} spacing={5} sm={8}>
                            <Typography className="summary_card_subTitle">
                              {moment(career?.releaseDate).format("DD-MM-YYYY")}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider className="divider_card" />

                        {/* <Grid container mb={2} mr={5} mt={4}>

                                                    <Grid item xs={6} spacing={5} sm={4}>
                                                        <Typography className='summary_card_title'>Your artwork</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} spacing={5} sm={8}>
                                                        <img src={MusicIcon} alt='' width={98} height={98} />
                                                    </Grid>
                                                </Grid>

                                                <Divider className='divider_card' /> */}
                      </Grid>
                      <Grid sm={0.2}></Grid>
                      <Grid xs={12} sm={4} mt={{ xs: 1.5, sm: 0 }}>
                        <Typography
                          className="summary_card_title"
                          sx={{ mb: { xs: "20px", sm: "20px" } }}
                        >
                          Your artwork
                        </Typography>

                        <img
                          src={career?.cover}
                          alt=""
                          style={{
                            paddingBottom: "0px",
                            paddingRight: "0px",
                            maxWidth: "100%",
                            maxHeight: "350px",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Collapse>
              </Card>
              {/* -----------------------------Advanced details--------------------------------------- */}

              {career?.tracks?.length > 1 ? (
                <>
                  <IconButton
                    aria-label="add to favorites"
                    sx={{
                      fontFamily: "DM Sans",
                      fontSize: "24px",
                      fontWeight: "700",
                    }}
                  >
                    Advanced Details
                  </IconButton>
                  {career?.tracks?.map((value, index) => (
                    <>
                      <Card
                        sx={{
                          maxWidth: "auto",
                          borderRadius: "16px",
                          px: 1,
                          mt: 1,
                        }}
                      >
                        <label for="advanced">
                          <CardActions disableSpacing>
                            <ExpandMore
                              expand={expanded}
                              onClick={(index) => {
                                handleExpandClickadv(index);
                              }}
                              aria-expanded={expandedpub}
                              aria-label="show more"
                              id="advanced"
                            >
                              <ExpandMoreIcon />
                            </ExpandMore>
                          </CardActions>
                        </label>
                        <Collapse in={expandedadv} timeout="auto" unmountOnExit>
                          <CardContent>
                            <Grid container mb={2}>
                              <Grid item xs={6} spacing={5} sm={4}>
                                <Typography className="summary_card_title">
                                  Composition Type
                                </Typography>
                              </Grid>
                              <Grid item xs={6} spacing={5} sm={8}>
                                <Typography className="summary_card_subTitle">
                                  {value?.compositionType}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Divider className="divider_card" />

                            <Grid container mb={2} mt={4}>
                              <Grid item xs={6} spacing={5} sm={4}>
                                <Typography className="summary_card_title">
                                  Clean / Explicit
                                </Typography>
                              </Grid>
                              <Grid item xs={6} spacing={5} sm={8}>
                                <Typography className="summary_card_subTitle">
                                  {value?.cleanExplicit}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Divider className="divider_card" />

                            <Grid container mb={2} mt={4}>
                              <Grid item xs={6} spacing={5} sm={4}>
                                <Typography className="summary_card_title">
                                  Do you want your all tracks to be mastered?
                                </Typography>
                              </Grid>
                              <Grid item xs={6} spacing={5} sm={8}>
                                <Typography className="summary_card_subTitle">
                                  {career?.isMastered === true ? "Yes" : "No"}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Divider className="divider_card" />
                            <Grid container mb={2} mt={4}>
                              <Grid item xs={6} spacing={5} sm={4}>
                                <Typography className="summary_card_title">
                                  ISRC Code
                                </Typography>
                              </Grid>
                              <Grid item xs={6} spacing={5} sm={8}>
                                <Typography className="summary_card_subTitle">
                                  {value?.isrcCode}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Divider className="divider_card" />
                            <Grid container mb={2} mt={4}>
                              <Grid item xs={6} spacing={5} sm={4}>
                                <Typography className="summary_card_title">
                                  UPC Code
                                </Typography>
                              </Grid>
                              <Grid item xs={6} spacing={5} sm={8}>
                                <Typography className="summary_card_subTitle">
                                  {career?.upcCode}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Divider className="divider_card" />

                            <Grid container mb={2} mt={4}>
                              <Grid item xs={12} spacing={5} sm={4}>
                                <Typography className="summary_card_title">
                                  Song
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                spacing={5}
                                sm={8}
                                mt={{ xs: 1, sm: 0 }}
                              >
                                <Typography className="summary_card_subTitle">
                                  <audio controls>
                                    <source
                                      src={value?.file}
                                      type="audio/wav"
                                    />
                                  </audio>
                                </Typography>
                              </Grid>
                            </Grid>
                            <Divider className="divider_card" />

                            <Grid container mb={2} mt={4}>
                              <Grid item xs={12} spacing={5} sm={4}>
                                <Typography className="summary_card_title">
                                  Your Ringtone
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                spacing={5}
                                sm={8}
                                mt={{ xs: 1, sm: 0 }}
                              >
                                <Typography className="summary_card_subTitle">
                                  <audio controls>
                                    <source
                                      src={value?.ringtone}
                                      type="audio/wav"
                                    />
                                  </audio>
                                </Typography>
                              </Grid>
                            </Grid>
                            <Divider className="divider_card" />

                            <Grid container mb={2} mt={4}>
                              <Grid item xs={12} spacing={5} sm={4}>
                                <Typography className="summary_card_title">
                                  Artist Details
                                </Typography>
                              </Grid>
                              <Grid item xs={12} spacing={5} sm={8}>
                                <Grid container mb={2} mt={{ xs: 4, sm: 0 }}>
                                  <Grid item xs={4} spacing={5} sm={4}>
                                    <Typography className="summary_card_title">
                                      Role
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4} spacing={5} sm={4}>
                                    <Typography className="summary_card_title">
                                      First Name
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4} spacing={5} sm={4}>
                                    <Typography className="summary_card_title">
                                      Last Name
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Divider className="divider_card" />

                                {/* <Grid container mb={2} mt={4}>
                          <Grid item xs={6} spacing={5} sm={4}>
                            <Typography className="summary_card_subTitle">
                              {career?.tracks[0]?.artists[0]?.role}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} spacing={5} sm={8}>
                            <Typography className="summary_card_subTitle">
                              {career?.tracks[0]?.artists[0]?.name}
                            </Typography>
                          </Grid>
                        </Grid> */}
                                {career?.tracks ? (
                                  <>
                                    <Grid container mb={2} mt={2}>
                                      {/* <Grid item xs={12} spacing={5} sm={4}> */}
                                      {value?.artists?.map((option) => (
                                        <>
                                          <Grid container mb={2}>
                                            <Grid
                                              item
                                              xs={4}
                                              spacing={5}
                                              sm={4}
                                            >
                                              <Typography className="summary_card_title">
                                                {option?.role}
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={4}
                                              spacing={5}
                                              sm={4}
                                            >
                                              <Typography className="summary_card_title">
                                                {option?.name}
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={4}
                                              spacing={5}
                                              sm={4}
                                            >
                                              <Typography className="summary_card_title">
                                                {option?.lastname}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </>
                                      ))}
                                      {/* </Grid> */}
                                    </Grid>
                                  </>
                                ) : (
                                  ""
                                )}

                                {/* <Typography className='summary_card_subTitle'>Role</Typography>
                                                <Typography className='summary_card_subTitle'>Full Name</Typography> */}
                              </Grid>
                            </Grid>
                            <Divider className="divider_card" />
                          </CardContent>
                        </Collapse>
                      </Card>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <Card
                    sx={{
                      maxWidth: "auto",
                      borderRadius: "16px",
                      px: 1,
                      mt: 4,
                    }}
                  >
                    <label for="advanced">
                      <CardActions disableSpacing>
                        <IconButton
                          aria-label="add to favorites"
                          sx={{
                            fontFamily: "DM Sans",
                            fontSize: "24px",
                            fontWeight: "700",
                          }}
                        >
                          Advanced Details
                        </IconButton>

                        <ExpandMore
                          expand={expanded}
                          onClick={handleExpandClickadv}
                          aria-expanded={expandedpub}
                          aria-label="show more"
                          id="advanced"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                      </CardActions>
                    </label>
                    <Collapse in={expandedadv} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Grid container mb={2}>
                          <Grid item xs={6} spacing={5} sm={4}>
                            <Typography className="summary_card_title">
                              Composition Type
                            </Typography>
                          </Grid>
                          <Grid item xs={6} spacing={5} sm={8}>
                            <Typography className="summary_card_subTitle">
                              {career?.tracks[0]?.compositionType}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider className="divider_card" />

                        <Grid container mb={2} mt={4}>
                          <Grid item xs={6} spacing={5} sm={4}>
                            <Typography className="summary_card_title">
                              Clean / Explicit
                            </Typography>
                          </Grid>
                          <Grid item xs={6} spacing={5} sm={8}>
                            <Typography className="summary_card_subTitle">
                              {career?.tracks[0]?.cleanExplicit}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider className="divider_card" />

                        <Grid container mb={2} mt={4}>
                          <Grid item xs={6} spacing={5} sm={4}>
                            <Typography className="summary_card_title">
                              Do you want your all tracks to be mastered?
                            </Typography>
                          </Grid>
                          <Grid item xs={6} spacing={5} sm={8}>
                            <Typography className="summary_card_subTitle">
                              {career?.isMastered === true ? "Yes" : "No"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider className="divider_card" />

                        {/* <Grid container mb={2} mt={4}>
                                            <Grid item xs={6} spacing={5} sm={4}>
                                                <Typography className='summary_card_title'>Release date</Typography>
                                            </Grid>
                                            <Grid item xs={6} spacing={5} sm={8}>
                                                <Typography className='summary_card_subTitle'>12/12/2022</Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider className='divider_card' /> */}
                        <Grid container mb={2} mt={4}>
                          <Grid item xs={6} spacing={5} sm={4}>
                            <Typography className="summary_card_title">
                              ISRC Code
                            </Typography>
                          </Grid>
                          <Grid item xs={6} spacing={5} sm={8}>
                            <Typography className="summary_card_subTitle">
                              {career?.tracks[0]?.isrcCode}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider className="divider_card" />
                        <Grid container mb={2} mt={4}>
                          <Grid item xs={6} spacing={5} sm={4}>
                            <Typography className="summary_card_title">
                              UPC Code
                            </Typography>
                          </Grid>
                          <Grid item xs={6} spacing={5} sm={8}>
                            <Typography className="summary_card_subTitle">
                              {career?.upcCode}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider className="divider_card" />

                        <Grid container mb={2} mt={4}>
                          <Grid item xs={12} spacing={5} sm={4}>
                            <Typography className="summary_card_title">
                              Song
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            spacing={5}
                            sm={8}
                            mt={{ xs: 1, sm: 0 }}
                          >
                            <Typography className="summary_card_subTitle">
                              <audio controls>
                                <source
                                  src={career?.tracks[0]?.file}
                                  type="audio/wav"
                                />
                              </audio>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider className="divider_card" />

                        <Grid container mb={2} mt={4}>
                          <Grid item xs={12} spacing={5} sm={4}>
                            <Typography className="summary_card_title">
                              Your Ringtone
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            spacing={5}
                            sm={8}
                            mt={{ xs: 1, sm: 0 }}
                          >
                            <Typography className="summary_card_subTitle">
                              <audio controls>
                                <source
                                  src={career?.tracks[0]?.ringtone}
                                  type="audio/wav"
                                />
                              </audio>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider className="divider_card" />

                        <Grid container mb={2} mt={4}>
                          <Grid item xs={12} spacing={5} sm={4}>
                            <Typography className="summary_card_title">
                              Artist Details
                            </Typography>
                          </Grid>
                          <Grid item xs={12} spacing={5} sm={8}>
                            <Grid container mb={2} mt={{ xs: 4, sm: 0 }}>
                              <Grid item xs={4} spacing={5} sm={4}>
                                <Typography className="summary_card_title">
                                  Role
                                </Typography>
                              </Grid>
                              <Grid item xs={4} spacing={5} sm={4}>
                                <Typography className="summary_card_title">
                                  First Name
                                </Typography>
                              </Grid>
                              <Grid item xs={4} spacing={5} sm={4}>
                                <Typography className="summary_card_title">
                                  Last Name
                                </Typography>
                              </Grid>
                            </Grid>
                            <Divider className="divider_card" />

                            {/* <Grid container mb={2} mt={4}>
                          <Grid item xs={6} spacing={5} sm={4}>
                            <Typography className="summary_card_subTitle">
                              {career?.tracks[0]?.artists[0]?.role}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} spacing={5} sm={8}>
                            <Typography className="summary_card_subTitle">
                              {career?.tracks[0]?.artists[0]?.name}
                            </Typography>
                          </Grid>
                        </Grid> */}
                            {career?.tracks ? (
                              <>
                                <Grid container mb={2} mt={2}>
                                  {/* <Grid item xs={12} spacing={5} sm={4}> */}
                                  {career?.tracks[0]?.artists?.map((option) => (
                                    <>
                                      <Grid container mb={2}>
                                        <Grid item xs={4} spacing={5} sm={4}>
                                          <Typography className="summary_card_title">
                                            {option?.role}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={4} spacing={5} sm={4}>
                                          <Typography className="summary_card_title">
                                            {option?.name}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={4} spacing={5} sm={4}>
                                          <Typography className="summary_card_title">
                                            {option?.lastname}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </>
                                  ))}
                                  {/* </Grid> */}
                                </Grid>
                              </>
                            ) : (
                              ""
                            )}

                            {/* <Typography className='summary_card_subTitle'>Role</Typography>
                                                <Typography className='summary_card_subTitle'>Full Name</Typography> */}
                          </Grid>
                        </Grid>
                        <Divider className="divider_card" />
                      </CardContent>
                    </Collapse>
                  </Card>
                </>
              )}

              {/* -----------------------------publishing details--------------------------------------- */}
              <Card
                sx={{ maxWidth: "auto", borderRadius: "16px", px: 1, mt: 4 }}
              >
                <label for="publishing">
                  <CardActions disableSpacing>
                    <IconButton
                      aria-label="add to favorites"
                      sx={{
                        fontFamily: "DM Sans",
                        fontSize: "24px",
                        fontWeight: "700",
                      }}
                    >
                      Publishing Details
                    </IconButton>

                    <ExpandMore
                      expand={expandedpub}
                      onClick={handleExpandClickpub}
                      aria-expanded={expandedpub}
                      aria-label="show more"
                      id="publishing"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </CardActions>
                </label>
                <Collapse in={expandedpub} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Grid container mb={2} mt={0}>
                      <Grid item xs={6} spacing={5} sm={4}>
                        <Typography className="summary_card_title">
                          Copyright Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6} spacing={5} sm={8}>
                        <Typography className="summary_card_subTitle">
                          {career?.copyright?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider className="divider_card" />

                    <Grid container mb={2} mt={4}>
                      <Grid item xs={6} spacing={5} sm={4}>
                        <Typography className="summary_card_title">
                          Production Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6} spacing={5} sm={8}>
                        <Typography className="summary_card_subTitle">
                          {career?.productionRight?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider className="divider_card" />
                    <Grid container mb={2} mt={4}>
                      <Grid item xs={12} sm={4}>
                        <Typography className="summary_card_title">
                          Platforms
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Grid container spacing={2}>
                          {career?.platforms?.map((values, index) => (
                            <Grid key={index} item xs={6} md={4} lg={3}>
                              <Box className="mainGridBox_summary">
                                <Box className="image_summary">
                                  {/* <img src={values.iamge} width={38} height={38} alt="" /> */}
                                  <Typography
                                    sx={{
                                      textAlign: "center",
                                      fontFamily: "DM sans",
                                    }}
                                  >
                                    {values}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider className="divider_card" />
                  </CardContent>
                </Collapse>
              </Card>

              <Link
                to="/artist/dashboard"
                className="dashboard-btn"
                style={{ textDecoration: "none !important" }}
              >
                <CustomButton
                  handleClick={() => window.scrollTo(0, 0)}
                  btnText="Go to Dashboard"
                  startIcon={<ArrowBackIcon sx={{ paddingRight: "3px" }} />}
                  buttonStyle="dashbtn"
                />
              </Link>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;

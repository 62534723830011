import { Typography, Box, Button } from "@mui/material";
import React from "react";
import CustomButton from "../common/customButton/index";
import "./style.css";

const Index = () => {
  return (
    <Box className="master-about" sx={{mt:5}}>
      <Box
        sx={{ paddingTop: "72px", textAlign: "center", position: "relative",px:'15px' }}
      >
        <Typography variant="p" className="aboutMasteringUpperText">
          One Platform For Music Creators And Label Studios
        </Typography>
        <div class="title-circle-about"></div>
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
        >
          <div class="title-circle-about-data2"></div>
          <Box className="musicMasteringBoxdata">
            <Box className="manageBlueDotContainerdata">
              <Box className="manageBlueDotdata"></Box>
              <Box className="manageDotdata mx-1"></Box>
              <Box className="manageDotdata mx-1"></Box>
            </Box>
            <iframe
              width="100%"
              height="100%"
              className=""
              title=""
              style={{ marginTop: "20px" }}
              src="https://www.youtube.com/embed/RP5JJMZkHDQ"
            ></iframe>
            <div class="title-circle-about-data"></div>
            <div class="title-circle-about-data3"></div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Index;

import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import CustomButton from "../../common/customButton/index";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import "./banner.css";
import * as yup from "yup";
import { Typography } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { ScaleLoader } from "react-spinners";

const Index = ({ handleClose, view, getOrder, tokenedit }) => {
  const validationSchema = yup.object().shape({
    name: yup.string().min(2, "Too Short!").required("Required*"),
    title: yup.string().required("Required*"),
    description: yup.string().min(75, 'Min 75 Characters required.').max(250, 'Max 250 Characters required.').required("Required*"),
  });
  const [autograph, setAutograph] = useState();
  const [bannerpreview, setBanner] = useState();
  const [error, setError] = useState("");
  const [imageSize, setImageSize] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [autoerror, setAutoerror] = useState("");
  const handleChnage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setAutograph(reader.result);
    };
  };
  const handleChnage2 = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const size = Math.ceil(reader.result.length / 1024); // Size in KB
      setImageSize(size);
    });
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setBanner(reader.result);
    };
  };
  const [AnotherImages, setAnotherImages] = useState({
    banner: view?.banner || "",
    autograph: view?.autograph || "",
  });
  const initialValues = {
    title: "",
    name: "",
    description: "",
  };

  useEffect(() => {
    if (view) {
      formik.setFieldValue("title", view?.title);
      formik.setFieldValue("name", view?.name);
      formik.setFieldValue("description", view?.description);
      setAutograph(view?.autograph);
      setBanner(view?.banner);
    }
  }, []);
  useEffect(() => {
    if (AnotherImages?.banner) {
      setError("");
    }
    if (AnotherImages?.autograph) {
      setAutoerror("");
    }
  }, [AnotherImages]);
  const onSubmit = async (values) => {
    // const loginToken = JSON.parse(localStorage.getItem("login"));
    // const token = loginToken?.token;
    const token = tokenedit;
    if (!AnotherImages?.banner) {
      setError("Please select an image file");
      return;
    }
    if (!AnotherImages?.autograph) {
      setAutoerror("Please select an image file");
      return;
    }
    if (imageSize >= 1024) {
      setError("Please Upload image Less than 1Mb");
      return;
    }
    setIsLoading(true);
    var formDatavalue = new FormData();
    formDatavalue.append("title", values.title);
    formDatavalue.append("name", values.name);
    formDatavalue.append("description", values.description);
    formDatavalue.append("portfoliono", 1);
    formDatavalue.append("banner", AnotherImages.banner);
    formDatavalue.append("autograph", AnotherImages.autograph);
    await axios
      .put(
        `${process.env.REACT_APP_DMT_API_PATH}/creative/${view?._id}`,
        formDatavalue,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.error !== true) {
          getOrder();
          setIsLoading(false);
          handleClose();
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast.error("Something Went Wrong");
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <>
      <Box id="contact">
        <Container maxWidth="md" sx={{width:{md:"600px",xs:"100%", padding:"12px"}}}>
        {isLoading ? (
            <ScaleLoader
              size={350}
              textAlign="center"
              className="music-loader"
              color="#FBCE2E"
            />
          ) : 
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{ mt: "0px" }}
          >
            <Typography
              sx={{
                marginTop: "0px",
                marginLeft: "4px",
                fontFamily:'DM Sans'
              }}
            >
              Enter Title
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              placeholder="Enter Title"
              {...formik.getFieldProps("title")}
              onChange={(e) => {
                formik.setFieldValue("title", e.target.value);
              }}
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily:'DM Sans !important'
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            />
            {formik.touched.title && formik.errors.title ? (
              <Typography
                sx={{ textAlign: "start", marginLeft: "4px", fontSize: "12px" }}
                color="error"
              >
                {formik.errors.title}
              </Typography>
            ) : null}
            <Typography
              sx={{
                marginTop: "10px",
                marginBottom: "-12px",
                marginLeft: "4px",
                fontFamily:'DM Sans !important'
              }}
            >
              Enter Your Name
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              placeholder="Enter Your Name"
              {...formik.getFieldProps("name")}
              onChange={(e) => {
                formik.setFieldValue("name", e.target.value);
              }}
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                mt: 2,
                fontFamily:'DM Sans !important'
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            />
            {formik.touched.name && formik.errors.name ? (
              <Typography
                sx={{ textAlign: "start", marginLeft: "4px", fontSize: "12px" }}
                color="error"
              >
                {formik.errors.name}
              </Typography>
            ) : null}
            <Typography
              sx={{
                marginTop: "10px",
                marginBottom: "-12px",
                marginLeft: "4px",
                fontFamily:'DM Sans !important'
              }}
            >
              Enter Description
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              minRows="3"
              multiline
              placeholder="Enter Description"
              {...formik.getFieldProps("description")}
              onChange={(e) => {
                formik.setFieldValue("description", e.target.value);
              }}
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                // height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily:'DM Sans !important',
                mt: 2,
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            />
            {formik.touched.description && formik.errors.description ? (
              <Typography
                sx={{ textAlign: "start", marginLeft: "4px", fontSize: "12px" }}
                color="error"
              >
                {formik.errors.description}
              </Typography>
            ) : null}
            {bannerpreview ? (
              <div className="text-start">
                <img
                  src={bannerpreview}
                  alt=""
                  style={{
                    width: "50px",
                    height: "50px",
                    marginTop: "10px",
                    marginBottom: "-12px",
                    marginLeft: "10px",
                  }}
                />
              </div>
            ) : (
              ""
            )}
            <Typography
              sx={{
                marginTop: "10px",
                marginBottom: "-12px",
                marginLeft: "10px",
                fontFamily:'DM Sans !important'
              }}
            >
              Upload Banner
            </Typography>
            <TextField
              type="file"
              name="banner"
              onChange={(e) => {
                setAnotherImages({
                  ...AnotherImages,
                  banner: e.target.files[0],
                });
                handleChnage2(e);
              }}
            />
            {error && (
              <Typography
                sx={{
                  textAlign: "start",
                  marginLeft: "12px",
                  fontSize: "12px",
                  marginTop: "-8px",
                }}
                color="error"
              >
                {error}
              </Typography>
            )}

            {autograph ? (
              <div className="text-start">
                <img
                  src={autograph}
                  alt=""
                  style={{
                    width: "40px",
                    height: "40px",
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                />
              </div>
            ) : (
              ""
            )}
            <Typography
              sx={{
                marginTop: "0px",
                marginBottom: "-12px",
                marginLeft: "10px",
                fontFamily:'DM Sans !important'
              }}
            >
              Upload Autograph
            </Typography>
            <TextField
              type="file"
              name="autograph"
              onChange={(e) => {
                setAutograph(e.target.files[0]);
                setAnotherImages({
                  ...AnotherImages,
                  autograph: e.target.files[0],
                });
                handleChnage(e);
              }}
            />
            {autoerror && (
              <Typography
                sx={{
                  textAlign: "start",
                  marginLeft: "12px",
                  fontSize: "12px",
                  marginTop: "-8px",
                }}
                color="error"
              >
                {autoerror}
              </Typography>
              
            )}
            <Box>
            <CustomButton buttonStyle='editFormBtn' btnText={view ? "Save" : "Add"} btntype="Submit" />
            </Box>
          </Box>
}
        </Container>
        <ToastContainer />
      </Box>
    </>
  );
};

export default Index;

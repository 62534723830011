import React from "react";
import { useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import Payment from "../components/paymentHistory/index";
import Box from "@mui/material/Box";

const PaymentHistory = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const toggleSidebar = () => {
    setOpenSidebar((p) => !p);
  };
  return (
    <>
      <div className="container">
        <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
        <div className="main-container">
          <Navbar toggleSidebar={toggleSidebar} />
          <div className="pages">
            <Box className="custom-dmt-container">
              <Payment />
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentHistory;

import { Box, Button, Typography, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
// import "./drag.css";
import { useDropzone } from "react-dropzone";

function Pan({ setCoverfile, coverfile, data }) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "document/.pdf": [".pdf"],
    },
  });

  const files = acceptedFiles.map((file) => setCoverfile(file));

  return (
    <div {...getRootProps({ className: "basic-song-drop" })}>
      <input {...getInputProps()} />
      <Box
        sx={{
          height: "70px",
          display: "flex",
          alignItems: "center",
          wordBreak: "break-all",
          fontSize: { xs: "12px" },
        }}
      >
        <Typography
          sx={{
            listStyle: "none",
            mt: "17px !important",
            mb: "17px !important",
            wordBreak: "break-all",
            fontSize: { xs: "10px", md: "none" },
          }}
        >
          {coverfile?.path || coverfile}
        </Typography>
      </Box>
      <IconButton type="button" className="upload-song">
        <UploadFileIcon />
      </IconButton>
      <Typography className="drop-song">Drop or Choose your File</Typography>
    </div>
  );
}

export default Pan;

import * as React from "react";
import "./style.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import CustomButton from "../editButton";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function SignIn({ data }) {
  const navigate=useNavigate();
  const handlemove= ()=>{
    navigate("/signup/artist");
  }
  return (
    <>
      <Box className="free-show-box">
        <Container
          component="main"
          sx={{ textAlign: "-webkit-center" }}
          className="free-show-container"
        >
          <Paper className="paper-freeshow" component={Box}>
            <Box
              sx={{ marginTop: { md: "10px !important", xs: "none" } }}
              className="maincontainer"
            >
              <Typography
                sx={{
                  fontSize: { md: "24px", xs: "20px" },
                  fontWeight: "500",
                  paddingTop: "20px",
                  maxWidth: { md: "70% !important", xs: "100% !important" },
                  color: "#0D0D0D !important",
                  fontFamily: "DM Sans",
                }}
              >
                Would you like a free showcase page just like {data?.name}?
              </Typography>
              <Button
                className="profilefree"
                sx={{
                  textTransform: "capitalize  !important",
                  fontFamily: "DM Sans",
                }}
                onClick={handlemove}
              >
                Join Deliver My Tune For Free
              </Button>
            </Box>
          </Paper>
        </Container>
      </Box>
    </>
  );
}

import React from 'react'
import { useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import Password from '../components/changePassword/index'
import { Box } from '@mui/material';


const ChangePassword = () => {
    const [openSidebar, setOpenSidebar] = useState(false);
    const toggleSidebar = () => {
        setOpenSidebar((p) => !p);
    };
    return (
        <>
            <div className="container">
                <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
                <div className="main-container">
                    <Navbar toggleSidebar={toggleSidebar} />
                    <div className="pages">
                        <Box className='custom-dmt-container'>
                            <Password />
                        </Box>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ChangePassword
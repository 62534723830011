import React from "react";
import BasicDetailsdata from "./basicDetail";
import Box from "@mui/material/Box";
import AdvancedDetails from "./advanceDetails";
import { useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import Stepper from "../progressBar/index";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IoIosArrowForward } from "react-icons/io";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { userSlice } from "../../redux/slices/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ScaleLoader } from "react-spinners";
import { useEffect } from "react";

const DistributeSingleTrack = ({ career ,saveImage ,setSaveimage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [songValue, setSongValue] = useState();
  const [albumkey, setAlbumkey] = useState("track");
  const [openSidebar, setOpenSidebar] = useState(false);
  const toggleSidebar = () => {
    dispatch(userSlice.actions.saveUser(""));
    setSongValue(undefined);
    setOpenSidebar((p) => !p);
  };
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [value, setValue] = React.useState(career?.releaseDate || null);
  const [timevalue, setTimevalue] = React.useState(career?.recordLabel || null);
  useEffect(() => {
    if (career?.releaseDate) {
      setValue(career?.releaseDate);
    }
  }, [career]);

  let datevalue = JSON.stringify(value?.$d);
  datevalue = datevalue?.slice(1, 11);
  const [ringtone, setRingtone] = useState();
  const [moviesong, setMoviesong] = useState(false);
  const [tracktomaster, setTracktomaster] = useState(false);
  const [error, setError] = useState(false);
  const [seconderror, setSecondError] = useState(false);
  const [id, setId] = useState();
  const location = useLocation();
  const firstslidedata = useSelector((state) => state?.user);
  const [filedata, setFiledata] = useState(
    career?.cover || firstslidedata?.cover || ""
  );
  const [coverfile, setCoverfile] = useState(
    career?.tracks[0]?.coverSongFile || ""
  );
  const [formData, setFormData] = useState({
    name: career?.name || "",
    artwork: career?.cover || firstslidedata?.cover || "",
    recordlabel: career?.recordLabel || "",
    songlang: career?.language || "",
    songgenre: career?.genre || "",
    previewstart: career?.tracks[0]?.previewStartTime || "",
    releasedate: career?.releaseDate || "",
    moviesong: career?.filmBoolean || moviesong,
    filmname: career?.filmDetail?.name || "",
    directorname: career?.filmDetail?.artists[0]?.name || "",
    actorname: career?.filmDetail?.artists[1]?.name || "",
    actressname: career?.filmDetail?.artists[2]?.name || "",
    tracktomaster: career?.isMastered || tracktomaster,
    songtitle: career?.tracks[0]?.name || "",
    videolink: career?.tracks[0]?.videoLink || "",
    isrccode: career?.tracks[0]?.isrcCode || "",
    compositiontype: career?.tracks[0]?.compositionType || "",
    cleansong: career?.tracks[0]?.cleanExplicit || "",
    role: career?.tracks[0]?.compositionType || "",
    fullname: career?.tracks[0]?.compositionType || "",
    songurl: career?.tracks[0]?.file || "",
    uploadringtone: career?.tracks[0]?.ringtone || "",
  });
  const [artistdetail, setArtistdetail] = useState({
    primaryfirstname: "",
    primarylastname: "",
    featuringfirstname: "",
    featuringlastname: "",
    lyricistfirstname: "",
    lyricistlastname: "",
    composerfirstname: "",
    composerlastname: "",
  });
  const [listData, setListData] = useState([
    {
      role: "",
      name: "",
      lastname: "",
    },
  ]);
  useEffect(() => {
    if (career?.tracks[0]?.file) {
      setSongValue(career?.tracks[0]?.file);
    }
  }, [career]);
  useEffect(() => {
    if (career?.tracks[0]?.ringtone) {
      setRingtone(career?.tracks[0]?.ringtone);
    }
  }, [career]);
  useEffect(() => {
    if (career?.filmBoolean) {
      setMoviesong(career?.filmBoolean);
    }
  }, [career]);
  useEffect(() => {
    if (career?.isMastered) {
      setTracktomaster(career?.isMastered);
    }
  }, [career]);

  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;
  useEffect(() => {
    if (career) {
      setId(career?._id);
      const userid = career?._id;
    } else {
      setId(loginToken?.user?._id);
      const userid = loginToken?.user._id;
    }
  }, [career, setId]);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleChange = async () => {
    if (
      formData.name.length == 0 ||
      formData.recordlabel.length == 0 ||
      formData.songlang.length == 0 ||
      formData.songgenre.length == 0
    ) {
      setError(true);
      return;
    } else if (!filedata) {
      toast.error("Cover is required");
    } else if (!career && value?.$d === undefined) {
      toast.error("Release Date is required");
    }
    if (
      formData.songtitle.length == 0 ||
      formData.compositiontype.length == 0 ||
      formData.cleansong.length == 0
    ) {
      setSecondError(true);
    } else if (
      artistdetail?.primaryfirstname?.length == 0 ||
      // artistdetail?.primarylastname?.length == 0 ||
      // artistdetail?.featuringfirstname?.length == 0 ||
      // artistdetail?.featuringlastname?.length == 0 ||
      artistdetail?.lyricistfirstname?.length == 0 ||
      artistdetail?.lyricistlastname?.length == 0 ||
      artistdetail?.composerfirstname?.length == 0 ||
      artistdetail?.composerlastname?.length == 0
    ) {
      setSecondError(true);
    } else if (!songValue) {
      toast.error("Song is required");
    } else if (!artistdetail?.primaryfirstname) {
      toast.error("Primary artist first name is required");
    }
    // else if (!artistdetail?.primarylastname) {
    //   toast.error("Primary artist last name is required");
    // } else if (!artistdetail?.featuringfirstname) {
    //   toast.error("Featuring first name is required");
    // } else if (!artistdetail?.featuringlastname) {
    //   toast.error("Featuring last name is required");
    // }
    else if (!artistdetail?.lyricistfirstname) {
      toast.error("Lyricist first name is required");
    } else if (!artistdetail?.lyricistlastname) {
      toast.error("Lyricist last name is required");
    } else if (!artistdetail?.composerfirstname) {
      toast.error("Composer first name is required");
    } else if (!artistdetail?.composerlastname) {
      toast.error("Composer first name is required");
    } else if (formData?.compositiontype === "cover" && !coverfile) {
      toast.error("Cover Song File is required");
    } else {
      setIsLoading(true);
      var formDatavalue = new FormData();

      if (moviesong === true) {
        formDatavalue.append("filmBoolean", moviesong);
        formDatavalue.append("filmDetail[name]", formData.filmname);
        formDatavalue.append("filmDetail[artists][0][role]", "director");
        formDatavalue.append(
          "filmDetail[artists][0][name]",
          formData.directorname
        );
        formDatavalue.append("filmDetail[artists][1][role]", "actor");
        formDatavalue.append(
          "filmDetail[artists][1][name]",
          formData.actorname
        );
        formDatavalue.append("filmDetail[artists][2][role]", "actress");
        formDatavalue.append(
          "filmDetail[artists][2][name]",
          formData.actressname
        );
      } else {
        formDatavalue.append("filmBoolean", moviesong);
      }
      formDatavalue.append("name", formData.name);
      formDatavalue.append("cover", filedata);
      formDatavalue.append("recordLabel", formData.recordlabel);
      formDatavalue.append("language", formData.songlang);
      formDatavalue.append("genre", formData.songgenre);
      formDatavalue.append("releaseDate", value.$d || value);
      formDatavalue.append("type", "track");

      const finalartist = [
        {
          role: "Primary Artist",
          name: artistdetail.primaryfirstname,
          lastname: artistdetail.primarylastname,
        },
        {
          role: "Featuring Artist",
          name: artistdetail.featuringfirstname,
          lastname: artistdetail.featuringlastname,
        },
        {
          role: "Lyricist",
          name: artistdetail.lyricistfirstname,
          lastname: artistdetail.lyricistlastname,
        },
        {
          role: "Composer",
          name: artistdetail.composerfirstname,
          lastname: artistdetail.composerlastname,
        },
        ...data,
      ];

      for (let i = 0; i < finalartist?.length; i++) {
        if (
          finalartist[i]?.role !== "" ||
          finalartist[i]?.name !== "" ||
          finalartist[i]?.lastname !== ""
        ) {
          for (let j in finalartist[i]) {
            formDatavalue.append(
              `tracks[0][artists][${i}][${j}]`,
              finalartist[i][j]
            );
          }
        }
      }
      setIsLoading(true);
      formDatavalue.append(
        "tracks[0][previewStartTime]",
        formData.previewstart
      );
      formDatavalue.append("tracks[0][name]", formData.songtitle);
      formDatavalue.append("tracks[0][videoLink]", formData.videolink);
      formDatavalue.append("tracks[0][isrcCode]", formData.isrccode);
      formDatavalue.append(
        "tracks[0][compositionType]",
        formData.compositiontype
      );
      if (formData.compositiontype === "cover") {
        formDatavalue.append("tracks[0][coverSongFile]", coverfile);
      }
      formDatavalue.append("tracks[0][file]", songValue);

      formDatavalue.append("tracks[0][cleanExplicit]", formData.cleansong);
      formDatavalue.append("tracks[0][ringtone]", ringtone);

      await axios
        .post(
          `${process.env.REACT_APP_DMT_API_PATH}/orders/updateartwork/${career?._id}`,
          formDatavalue,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res?.data?.error !== true) {
            toast.success(res?.data?.message);
            navigate("/artist/dashboard");
            setIsLoading(false);
            window.scrollTo(0, 0);
          } else {
            toast.error(res?.message);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          // toast.error(err?.message);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (firstslidedata?.tracks) {
      if (firstslidedata?.tracks[0]?.file?.length > 0) {
        setSongValue(firstslidedata?.tracks[0]?.file);
      }
    }
  }, [firstslidedata, setSongValue]);
  useEffect(() => {
    if (firstslidedata?.tracks) {
      if (firstslidedata?.tracks[0]?.ringtone?.length > 0) {
        setRingtone(firstslidedata?.tracks[0]?.ringtone);
      }
    }
  }, [firstslidedata, setRingtone]);

  const PageDisplay = () => {
    if (page === 0) {
      return isLoading ? (
        <ScaleLoader
          size={350}
          textAlign="center"
          className="music-loader"
          color="#FBCE2E"
        />
      ) : (
        <>
          <BasicDetailsdata
            formData={formData}
            setFormData={setFormData}
            setFiledata={setFiledata}
            filedata={filedata}
            value={value}
            setValue={setValue}
            timevalue={timevalue}
            setTimevalue={setTimevalue}
            setMoviesong={setMoviesong}
            career={career}
            error={error}
            firstslidedata={firstslidedata}
            setSaveimage={setSaveimage}
            saveImage={saveImage}
          />
          <AdvancedDetails
            formData={formData}
            setFormData={setFormData}
            data={data}
            setData={setData}
            setRingtone={setRingtone}
            ringtone={ringtone}
            setTracktomaster={setTracktomaster}
            tracktomaster={tracktomaster}
            setSongValue={setSongValue}
            songValue={songValue}
            career={career}
            seconderror={seconderror}
            firstslidedata={firstslidedata}
            coverfile={coverfile}
            setCoverfile={setCoverfile}
            artistdetail={artistdetail}
            setArtistdetail={setArtistdetail}
            listData={listData}
            setListData={setListData}
          />
        </>
      );
    }
  };

  const FormTitles = [
    "Basic Details",
    "Advance Details",
    "Publising Details",
    "Summary",
  ];
  return (
    <div className="container">
      <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
      <div className="main-container">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="pages">
          <Box className="custom-dmt-container">
            <div className="body">{PageDisplay()}</div>

            {isLoading === true ? (
              ""
            ) : (
              <div className="stepperBtn">
                <Button
                  className="nextbtn"
                  sx={{
                    textTransform: "capitalize  !important",
                    marginTop: "-60px !important",
                  }}
                  onClick={() => {
                    if (page === 0) {
                      handleChange();
                    }
                  }}
                >
                  {page === FormTitles.length - 1 ? "Update" : `Update`} &nbsp;{" "}
                  <IoIosArrowForward fontSize="16px" />
                </Button>
              </div>
            )}
          </Box>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DistributeSingleTrack;

import React from "react";
import { useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import Masteredaudiolist from "../components/masterAudio/index";

const MasteredAudio = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const toggleSidebar = () => {
    setOpenSidebar((p) => !p);
  };
  return (
    <>
      <div className="container">
        <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
        <div className="main-container">
          <Navbar toggleSidebar={toggleSidebar} />
          <div className="pages">
            <Masteredaudiolist />
          </div>
        </div>
      </div>
    </>
  );
};

export default MasteredAudio;

import React from 'react'
import { useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";

const Advertisement = () => {

  const [openSidebar, setOpenSidebar] = useState(false);
const toggleSidebar = () => {
  setOpenSidebar((p) => !p);
};
  return (
    <>
      <div className="container">
        <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
        <div className="main-container">
          <Navbar toggleSidebar={toggleSidebar} />
          <div className="pages">
          Advertisement
        </div>
      </div>
      </div>



      </>
  )
}

export default Advertisement
import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import thirdbg from "../../../assets/img/thirdbg.png";
import "./introduction.css";

const Introduction = ({ data }) => {
  return (
    <Box
      sx={{
        background: "url(" + thirdbg + ")",
        backgroundSize: "100% 100%",
        height: "100%",
        width: "100%",
      }}
    >
      <Container>
        <Typography
          sx={{
            fontSize: { md: "80px", xs: "36px" },
            textTransform: "uppercase",
            fontFamily: "Monoton",
            textAlign: "center",
            pt: { xs: 10, md: 17 },
          }}
        >
          {data?.name}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontFamily: "Montserrat",
              textAlign: "center",
              width: "700px",
              lineHeight: "36px",
            }}
          >
            {data?.description === "undefined"
              ? "Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Donec sollicitudin molestie malesuada. Sed porttitor lectus nibh. Curabitur aliquet quam id dui posuere blandit."
              : data?.description}
          </Typography>
        </Box>

        <Box textAlign={"center"} mt={3} sx={{ pb: { xs: 14, md: 17 } }}>
          <a href="#listen2">
            {" "}
            <Button className="listen" variant="contained">
              Listen
            </Button>
          </a>
          <a href="#listen3">
            {" "}
            <Button className="listen2" variant="contained">
              Contact Me
            </Button>
          </a>
        </Box>
      </Container>
    </Box>
  );
};

export default Introduction;

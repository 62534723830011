import React from "react";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import {
  Grid,
  ListItem,
  Typography,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Custombutton from "../../components/common/customButton";

const Singlealbum = () => {
  const Pricingcard = {
    maxWidth: "400px",
    backgroundColor: " #fff",
    borderRadius: "calc(.25rem - 1px) calc(0.25rem - 1px) 0 0",
  };
  const Pricingcardtwo = {
    maxWidth: "400px",
    backgroundColor: "  #2c3140",
    color: "#fff",
    borderRadius: "calc(.25rem - 1px) calc(0.25rem - 1px) 0 0",
  };
  return (
    <Box sx={{ maxWidth: "940px", margin: "auto" }}>
      <Typography
        variant="h2"
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          fontFamily: "DM Sans",
          color: " #333",
          fontSize: { md: "2rem", xs: "1.5rem" },
          marginBottom: "60px",
          marginTop: "50px",
        }}
      >
        Single Album Pricing
      </Typography>
      <Grid container Spacing={5} sx={{ textAlign: "-webkit-center" }}>
        <Grid item xs={12} md={6}>
          <Card sx={Pricingcard}>
            <Typography
              sx={{
                padding: "40px 60px",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "32px",
                color: "#000",
                textAlign: "center",
                paddingBlock: "45px",
              }}
            >
              Rs.599 Per song
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "21px",
                textAlign: "center",
                fontFamily: "DM Sans",
                fontWeight: "bolder",
                color: " #000",
              }}
            >
              SINGLE
            </Typography>
            <List>
              {data?.map((option) => (
                <ListItem
                  sx={{
                    width: " 306px",
                    textAlign: "center !important",
                    margin: "auto",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: " 0.9375rem",
                      fontWeight: "400",
                      color: " #333",
                      float: "right",
                      textAlign: " center",
                    }}
                  >
                    <CheckCircleIcon
                      sx={{
                        color: "#fb8e0b !important",
                        marginRight: "1rem",
                        marginTop: "-10px",
                        width: "20px",
                        height: "20px",
                        marginBottom: "-7px",
                      }}
                    />{" "}
                    {option}
                  </Typography>
                </ListItem>
              ))}
              <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
              >
                <CheckCircleIcon
                  sx={{
                    color: "#fb8e0b !important",
                    marginRight: "1rem",
                    marginTop: "-10px",
                    width: "20px",
                    height: "20px",
                    marginBottom: "-7px",
                  }}
                />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: " #333",
                    float: "left",
                    textAlign: " left",
                  }}
                >
                  Keeps 100% of copyrights and controls
                </Typography>
              </ListItem>

              <Box textAlign="center" padding="20px">
                <Link href="/signup/artist" underline="none">
                  <Custombutton
                    btnText="Distribute Now"
                    borderRadius="2rem"
                    fontSize="1.25rem"
                    padding="8px 40px"
                    buttonStyle="light-theme-btn"
                    width="244px"
                    height="56px"
                  />
                </Link>
              </Box>
            </List>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={Pricingcardtwo}>
            <Typography
              sx={{
                padding: "40px 60px",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "32px",
                color: "# fff",
                textAlign: "center",
                paddingBlock: "45px",
              }}
            >
              Rs.1999 Per song
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "21px",
                textAlign: "center",
                fontFamily: "DM Sans",
                fontWeight: "bolder",
                color: " #fff",
              }}
            >
              SINGLE
            </Typography>
            <List>
              {data2?.map((option) => (
                <ListItem
                  sx={{
                    width: " 306px",
                    textAlign: "center !important",
                    margin: "auto",
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#fb8e0b !important",
                      marginRight: "1rem",
                      marginTop: "-10px",
                      width: "20px",
                      height: "20px",
                      maarginBottom: "-7px",
                    }}
                  />
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: " 0.9375rem",
                      fontWeight: "400",
                      color: " #fff",
                      float: "right",
                      textAlign: " left",
                    }}
                  >
                    {" "}
                    {option}
                  </Typography>
                </ListItem>
              ))}
              <ListItem
                sx={{
                  width: " 306px",
                  textAlign: "center !important",
                  margin: "auto",
                }}
              >
                <CheckCircleIcon
                  sx={{
                    color: "#fb8e0b !important",
                    marginRight: "1rem",
                    marginTop: "-10px",
                    width: "20px",
                    height: "20px",
                    maarginBottom: "-7px",
                  }}
                />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: " 0.9375rem",
                    fontWeight: "400",
                    color: " #fff",
                    float: "right",
                    textAlign: " left",
                    display:'flex',
                    mt:'-5px',
                    alignItems:'center'
                  }}
                >
                  Editorial Playlist Pitch{" "}
                  <Tooltip title="we can not guarantee the addition of playlist but we can raise the request">
                    <IconButton>
                      <ErrorOutlineRoundedIcon sx={{color:'#fff'}}/>
                    </IconButton>
                  </Tooltip>
                </Typography>
              </ListItem>

              <Box textAlign="center" padding="20px">
                <Link href="/signup/artist" underline="none">
                  <Custombutton
                    btnText="Distribute Now"
                    borderRadius="2rem"
                    fontSize="1.25rem"
                    padding="8px 40px"
                    buttonStyle="light-theme-btn"
                    width="244px"
                    height="56px"
                  />
                </Link>
              </Box>
            </List>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Singlealbum;

const data = [
  " Unlimited Suite of Services",
  "Release on 60+ Digital stores",
  "Release on social platforms",
  "Free UPC & Free ISRC",
  "Distribute within 48 hours",
  "Revenue from platforms 70%",
  "Customizable Label name",
  "Official sales report",
  "Cover art creator",
  "Instagram profile linking ",
];

const data2 = [
  " Unlimited Suite of Services",
  "Release on 60+ Digital stores",
  "Release on social platforms",
  "Free UPC & Free ISRC",
  "Distribute within 48 hours",
  "Revenue from platforms 90%",
  "Customizable Label name",
  "Official sales report",
  "Cover art creator",
  "Music mastering",
  "Instagram profile linking ",
  "Keeps 100% of copyrights and controls",
  "Sync licensing for TV, films, and games",
  "Youtube Official artist channel Request",
  "Lyrics Royalty from Major Platforms",
  "Verification request with Spotify for Artists",
  "Verification request with Apple Music for Artists",
  // "Editorial Playlist Pitch (we can not guarantee the addition of playlist but we can raise the request)",
];

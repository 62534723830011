import React from "react";
import Aboutbanner from "../../components/aboutBanner/index";
import Navbar from "../../components/common/navbar/index";
import WhatDo from "../../components/whatDo/index";
import Footer from "../../components/common/footer/index";
import DeliverTune from "../../components/deliverTune/index";
import OfferingTools from "../../components/offeringTools/index";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const themeLight = createTheme({
  palette: {
    mode: "light",
  },
});
const About = () => {
  return (
    <Box>
      <ThemeProvider theme={themeLight}>
        <CssBaseline />
        <Navbar />
        <Aboutbanner />
        <WhatDo />
        <OfferingTools />
        <DeliverTune />
        <Footer />
      </ThemeProvider>
    </Box>
  );
};

export default About;

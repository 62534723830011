import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import Arrow from "../../assets/img/arrow.svg";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "./basic.css";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Play from "../../assets/img/play.png";
import CustomButton from "../common/customButton/index";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import { blue } from "@mui/material/colors";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Imagedrop from "../../components/imageDrop/index";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { useEffect } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Card } from "@mui/material";
import LyricsUpload from "../LyricsUpload/LyricsUpload";
import { toast } from "react-toastify";

// import { useSelector } from "react-redux";

// const ExpandMore = styled((props, value) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
//   marginLeft: "auto",
//   transition: theme.transitions.create("transform", {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));
const Index = ({
  formData,
  setFormData,
  setFiledata,
  value,
  setValue,
  timevalue,
  setTimevalue,
  setMoviesong,
  career,
  error,
  filedata,
  firstslidedata,
  albumkey,
  saveImage,
  setSaveimage,
  setisUnsplash,
  isUnsplash,
  setSongLyrics,
  songLyrics
}) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const params = useParams();

  const songGenre = [
    {
      value: "Alternative",
      label: "Alternative",
    },
    {
      value: "Audiobooks",
      label: "Audiobooks",
    },
    {
      value: "Blues",
      label: "Blues",
    },
    {
      value: "ChildrensMusic",
      label: "Childrens Music",
    },
    {
      value: "Classical",
      label: "Classical",
    },
    {
      value: "Comedy",
      label: "Comedy",
    },
    {
      value: "Country",
      label: "Country",
    },
    {
      value: "Dance",
      label: "Dance",
    },
    {
      value: "Electronic",
      label: "Electronic",
    },
    {
      value: "Folk",
      label: "Folk",
    },
    {
      value: "Hip Hop/Rap",
      label: "Hip Hop/Rap",
    },
    {
      value: "Holiday ",
      label: "Holiday ",
    },
    {
      value: "Inspirational",
      label: "Inspirational",
    },
    {
      value: "Jazz",
      label: "Jazz",
    },
    {
      value: "Latin",
      label: "Latin",
    },
    {
      value: "New Age",
      label: "New Age",
    },
    {
      value: "Opera",
      label: "Opera",
    },
    {
      value: "Opera",
      label: "Opera",
    },
    {
      value: "Pop",
      label: "Pop",
    },
    {
      value: "R&B/Soul",
      label: "R&B/Soul",
    },
    {
      value: "Reggae",
      label: "Reggae",
    },
    {
      value: "Rock",
      label: "Rock",
    },
    {
      value: "Spoken words",
      label: "Spoken words",
    },
    {
      value: "Soundtrack ",
      label: "Soundtrack ",
    },
    {
      value: "Vocal",
      label: "Vocal",
    },
    {
      value: "World",
      label: "World",
    },
  ];
  const previewStart = [
    {
      value: "Inreview",
      label: "Inreview",
    },
    {
      value: "Accepted",
      label: "Accepted",
    },
    {
      value: "Rejected",
      label: "Rejected",
    },
  ];
  const releaseDate = [
    {
      value: "Inreview",
      label: "Inreview",
    },
    {
      value: "Accepted",
      label: "Accepted",
    },
    {
      value: "Rejected",
      label: "Rejected",
    },
  ];
  const [expanded, setExpanded] = React.useState(career?.filmBoolean || false);

  const [valuedata, setValuedata] = useState(0);
  const [isAlbum, setIsAlbum] = useState(false);
  const [donate, setDonateValue] = useState(0);
  const navigate = useNavigate();
  const urlPath = window?.location.pathname;
  useEffect(() => {
    if (urlPath?.includes("album")) {
      setIsAlbum(true);
    } else {
      setIsAlbum(false);
    }
  }, []);

  const [expand, setExpand] = React.useState(
    career?.filmBoolean || firstslidedata?.filmBoolean || false
  );

  const [checked, setChecked] = React.useState(
    career?.filmBoolean || firstslidedata?.filmBoolean || false
  );
  const handleChangedata = (event) => {
    setChecked(event.target.checked);
    setMoviesong(event.target.checked);

    if (event.target.checked === true) {
      setExpand(true);
    } else if (event.target.checked === false) {
      setExpand(false);
    }
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/*": [".mp3", ".wav"],
    },
  });
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  const [totalShow, setTotalShow] = React.useState("");
  const [preview, setPreview] = React.useState("");
  const [release, setRelease] = React.useState("");

  const handlePreviewChange = (event) => {
    setPreview(event.target.value);
  };

  const handleReleaseChange = (event) => {
    setTotalShow(event.target.value);
  };

  const handleChange = (event) => {
    setRelease(event.target.value);
  };

  const handleChanged = (newValue) => {
    setValue(newValue);
  };

  const handleChanged2 = (newValue) => {
    setTimevalue(newValue);
  };
  const handlesplash = () => {
    navigate("/splashimage", {
      state: {
        id: isAlbum,
      },
    });
  };
  const today = new Date();
  const lastDay = new Date(today.getTime() + 5 * 24 * 60 * 60 * 1000);
  const [errormessage,setErrormessage]=useState(false);
  const [selectlang, setSelectlang] = useState(false);
  useEffect(() => {
    if (formData?.songlang === "Hindi") {
      setSelectlang(false);
      setErrormessage(false);
    } else if (formData?.songlang === "English") {
      setSelectlang(false);
      setErrormessage(false);
    } else if (formData?.songlang === "Instrumental") {
      setErrormessage(true);
      setSelectlang(false);
    } else if (formData?.songlang === "Punjabi") {
      setSelectlang(false);
      setErrormessage(false);
    } else if (formData?.songlang !== "") {
      setSelectlang(true);
      setErrormessage(false);
    }
  }, [formData]);

  return (
    <>
      <Box sx={{ my: "30px", background: " " }}>
        <Box
          sx={{
            background: "rgba(255, 255, 255, 0.05) !important",
            height: "auto",
            padding: "30px",
            borderRadius: "8px",
            mt: "28px",
          }}
        >
          <Grid container spacing={1}>
            <Grid item lg={6} xs={12}>
              <Imagedrop
                formData={formData}
                setFormData={setFormData}
                setFiledata={setFiledata}
                career={career}
                error={error}
                filedata={filedata}
                firstslidedata={firstslidedata}
                saveImage={saveImage}
                setSaveimage={setSaveimage}
                isAlbum={isAlbum}
                setIsAlbum={setIsAlbum}
                setisUnsplash={setisUnsplash}
                isUnsplash={isUnsplash}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <Box className="splash-image">
                {/* <Link to="/splashimage"> */}
                <Button
                  type="button"
                  onClick={handlesplash}
                  className="upload-splash"
                  sx={{ mt: "20px" }}
                >
                  Upload From Unsplash
                </Button>
                {/* </Link> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            background: "#1e1e1e ",
            py: "28px",
            borderRadius: "10px",
            px: "30px",
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "700",
              fontFamily: "DM Sans",
              mb: "20px",
            }}
          >
            Song Details
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{ fontFamily: "DM Sans", fontWeight: "700", mb: "8px" }}
              >
                {albumkey === "album" ? "Album Title*" : "Track Title*"}
              </Typography>
              <TextField
                fullWidth
                id="outlined-basic"
                value={formData.name}
                onChange={(event) =>
                  setFormData({ ...formData, name: event.target.value })
                }
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
              />
              {error && formData.name.length <= 0 ? (
                <label className="errorhandler">
                  {albumkey
                    ? "Album Title can't be Empty"
                    : "Track Title can't be Empty"}
                </label>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{ fontFamily: "DM Sans", fontWeight: "700", mb: "8px" }}
              >
                Record Label*
              </Typography>
              <TextField
                fullWidth
                id="outlined-basic"
                value={formData.recordlabel}
                onChange={(event) =>
                  setFormData({ ...formData, recordlabel: event.target.value })
                }
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
              />
              {error && formData.recordlabel.length <= 0 ? (
                <label className="errorhandler">
                  Record Label can't be Empty
                </label>
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "auto",
                  padding: "20px",
                  borderRadius: "8px",
                }}
              >
                <FormControl>
                  <Typography
                    id="demo-row-radio-buttons-group-label"
                    sx={{ fontFamily: "DM Sans", fontWeight: "700", mb: "8px" }}
                  >
                    Song Language*
                  </Typography>
                  <RadioGroup
                    row
                    value={formData.songlang}
                    onChange={(event) =>
                      setFormData({ ...formData, songlang: event.target.value })
                    }
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="English"
                      control={
                        <Radio
                          sx={{
                            color: "#E1E1E1",

                            "&.Mui-checked": {
                              color: "#33D67C",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: "DM Sans !important",
                            fontSize: "14px !important",
                            fontWeight: 400,
                            lineHeight: "18px !important",
                          }}
                        >
                          English
                        </Typography>
                      }
                      sx={{
                        ".css-1yq3e2k-MuiButtonBase-root-MuiRadio-root.Mui-checked ":
                          {},
                        ".css-ahj2mt-MuiTypography-root ": {
                          fontSize: "14px !important",
                          fontFamily: "DM Sans",
                        },
                      }}
                    />
                    <FormControlLabel
                      value="Hindi"
                      control={
                        <Radio
                          sx={{
                            color: "#E1E1E1",
                            "&.Mui-checked": {
                              color: "#33D67C",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: "DM Sans !important",
                            fontSize: "14px !important",
                            fontWeight: 400,
                            lineHeight: "18px !important",
                          }}
                        >
                          Hindi
                        </Typography>
                      }
                      sx={{
                        ".css-1yq3e2k-MuiButtonBase-root-MuiRadio-root.Mui-checked ":
                          {},
                      }}
                    />
                    <FormControlLabel
                      value="Instrumental"
                      control={
                        <Radio
                          sx={{
                            color: "#E1E1E1",
                            "&.Mui-checked": {
                              color: "#33D67C",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: "DM Sans !important",
                            fontSize: "14px !important",
                            fontWeight: 400,
                            lineHeight: "18px !important",
                          }}
                        >
                          Instrumental
                        </Typography>
                      }
                      sx={{
                        ".css-1yq3e2k-MuiButtonBase-root-MuiRadio-root.Mui-checked ":
                          {},
                      }}
                    />

                    <FormControlLabel
                      value="Punjabi"
                      control={
                        <Radio
                          sx={{
                            color: "#E1E1E1",
                            "&.Mui-checked": {
                              color: "#33D67C",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: "DM Sans !important",
                            fontSize: "14px !important",
                            fontWeight: 400,
                            lineHeight: "18px !important",
                          }}
                        >
                          Punjabi
                        </Typography>
                      }
                      sx={{
                        ".css-1yq3e2k-MuiButtonBase-root-MuiRadio-root.Mui-checked ":
                          {},
                      }}
                    />

                    {selectlang === true ? (
                      <>
                        <FormControlLabel
                          value="Others"
                          checked="true"
                          control={
                            <Radio
                              sx={{
                                color: "#E1E1E1",
                                "&.Mui-checked": {
                                  color: "#33D67C",
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              sx={{
                                fontFamily: "DM Sans !important",
                                fontSize: "14px !important",
                                fontWeight: 400,
                                lineHeight: "18px !important",
                              }}
                            >
                              Others
                            </Typography>
                          }
                          sx={{
                            ".css-1yq3e2k-MuiButtonBase-root-MuiRadio-root.Mui-checked ":
                              {},
                          }}
                        />
                        <TextField
                          fullWidth
                          id="outlined-select-currency"
                          select
                          value={formData.songlang}
                          onChange={(event) =>
                            setFormData({
                              ...formData,
                              songlang: event.target.value,
                            })
                          }
                          sx={{
                            background: "rgba(255, 255, 255, 0.05) !important",
                            height: "44px !important",
                            borderRadius: "5px",
                            p: 1,
                            fontFamily: "DM Sans",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "18px",
                            color: "#E1E1E1",
                          }}
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                            background: "rgba(255, 255, 255, 0.05) !important",
                          }}
                        >
                          <MenuItem value="Afar">Afar</MenuItem>
                          <MenuItem value="Abkhazian">Abkhazian</MenuItem>
                          <MenuItem value="Avestan">Avestan</MenuItem>
                          <MenuItem value="Afrikaans"> Afrikaans</MenuItem>
                          <MenuItem value="Akan">Akan</MenuItem>
                          <MenuItem value="Amharic">Amharic</MenuItem>
                          <MenuItem value="Aragonese">Aragonese</MenuItem>
                          <MenuItem value="Arabic">Arabic</MenuItem>
                          <MenuItem value="Assamese">Assamese</MenuItem>
                          <MenuItem value="Avaric">Avaric</MenuItem>
                          <MenuItem value="Aymara">Aymara</MenuItem>
                          <MenuItem value="Azerbaijani">Azerbaijani</MenuItem>
                          <MenuItem value="Bashkir">Bashkir</MenuItem>
                          <MenuItem value="Belarusian">Belarusian</MenuItem>
                          <MenuItem value="Bulgarian">Bulgarian</MenuItem>
                          <MenuItem value="Bhojpuri">Bhojpuri</MenuItem>
                          <MenuItem value="Bislama">Bislama</MenuItem>
                          <MenuItem value="Bambara">Bambara</MenuItem>
                          <MenuItem value="Bengali">Bengali</MenuItem>

                          <MenuItem value="Tibetan">Tibetan</MenuItem>
                          <MenuItem value="Breton">Breton</MenuItem>
                          <MenuItem value="Bosnian">Bosnian</MenuItem>
                          <MenuItem value="Catalan">Catalan</MenuItem>

                          <MenuItem value="Chechen">Chechen</MenuItem>
                          <MenuItem value="Chamorro">Chamorro</MenuItem>
                          <MenuItem value="Corsican">Corsican</MenuItem>
                          <MenuItem value="Creole,English based">
                            Creole,English based
                          </MenuItem>
                          <MenuItem value="Creole,French based">
                            Creole,French based
                          </MenuItem>
                          <MenuItem value="Creole,Portuguese based">
                            Creole,Portuguese based
                          </MenuItem>
                          <MenuItem value="Cree">Cree</MenuItem>
                          <MenuItem value="Czech">Czech</MenuItem>
                          <MenuItem value="Church Slavic">
                            Church Slavic
                          </MenuItem>
                          <MenuItem value="Chuvash">Chuvash</MenuItem>
                          <MenuItem value="Welsh">Welsh</MenuItem>
                          <MenuItem value="Danish">Danish</MenuItem>
                          <MenuItem value="German">German</MenuItem>
                          <MenuItem value="Divehi">Divehi</MenuItem>
                          <MenuItem value="Dzongkha">Dzongkha</MenuItem>
                          <MenuItem value="Ewe">Ewe</MenuItem>
                          <MenuItem value="Greek">Greek</MenuItem>
                          <MenuItem value="Esperanto">Esperanto</MenuItem>
                          <MenuItem value="Spanish">Spanish</MenuItem>
                          <MenuItem value="Estonian">Estonian</MenuItem>
                          <MenuItem value="Basque">Basque</MenuItem>
                          <MenuItem value="Persian">Persian</MenuItem>
                          <MenuItem value="Fulah">Fulah</MenuItem>

                          <MenuItem value="Finnish">Finnish</MenuItem>
                          <MenuItem value="Fijian">Fijian</MenuItem>
                          <MenuItem value="Faroese">Faroese</MenuItem>
                          <MenuItem value="French">French</MenuItem>
                          <MenuItem value="Western Frisian">
                            Western Frisian
                          </MenuItem>
                          <MenuItem value="Irish">Irish</MenuItem>
                          <MenuItem value="Gaelic">Gaelic</MenuItem>
                          <MenuItem value="Galician">Galician</MenuItem>
                          <MenuItem value="Guarani">Guarani</MenuItem>
                          <MenuItem value="Gujarati ">Gujarati </MenuItem>
                          <MenuItem value="Manx">Manx</MenuItem>
                          <MenuItem value="Hausa">Hausa</MenuItem>
                          <MenuItem value="Haitian Creole">
                            Haitian Creole
                          </MenuItem>
                          <MenuItem value="Hebrew">Hebrew</MenuItem>
                          <MenuItem value="Hiri Motu">Hiri Motu</MenuItem>
                          <MenuItem value="Croatian">Croatian</MenuItem>
                          <MenuItem value="Haitian">Haitian</MenuItem>
                          <MenuItem value="Hungarian">Hungarian</MenuItem>
                          <MenuItem value="Armenian">Armenian</MenuItem>
                          <MenuItem value="Herero">Herero</MenuItem>
                          <MenuItem value="Interlingua">Interlingua</MenuItem>
                          <MenuItem value="Indonesian">Indonesian</MenuItem>
                          <MenuItem value="Interlingue">Interlingue</MenuItem>
                          <MenuItem value="Igbo">Igbo</MenuItem>
                          <MenuItem value="Sichuan Yi">Sichuan Yi</MenuItem>
                          <MenuItem value="Inupiaq">Inupiaq</MenuItem>
                          <MenuItem value="Ido">Ido</MenuItem>
                          <MenuItem value="Icelandic">Icelandic</MenuItem>
                          <MenuItem value="Italian">Italian</MenuItem>
                          <MenuItem value="Inuktitut">Inuktitut</MenuItem>
                          <MenuItem value="Japanese">Japanese</MenuItem>
                          <MenuItem value="Javanese">Javanese</MenuItem>
                          <MenuItem value="Georgian">Georgian</MenuItem>
                          <MenuItem value="Kongo">Kongo</MenuItem>
                          <MenuItem value="Kikuyu">Kikuyu</MenuItem>
                          <MenuItem value="Kuanyama">Kuanyama</MenuItem>
                          <MenuItem value="Kazakh">Kazakh</MenuItem>
                          <MenuItem value="Kalaallisut">Kalaallisut</MenuItem>
                          <MenuItem value="Central Khmer">
                            Central Khmer
                          </MenuItem>
                          <MenuItem value="Kannada">Kannada</MenuItem>
                          <MenuItem value="Korean">Korean</MenuItem>
                          <MenuItem value="Kanuri">Kanuri</MenuItem>
                          <MenuItem value="Kashmiri">Kashmiri</MenuItem>
                          <MenuItem value="Kurdish">Kurdish</MenuItem>
                          <MenuItem value="Komi">Komi</MenuItem>
                          <MenuItem value="Cornish">Cornish</MenuItem>
                          <MenuItem value="Kirghiz">Kirghiz</MenuItem>
                          <MenuItem value="Latin">Latin</MenuItem>
                          <MenuItem value="Luxembourgish">
                            Luxembourgish
                          </MenuItem>
                          <MenuItem value="Luganda">Luganda</MenuItem>
                          <MenuItem value="Limburgan">Limburgan</MenuItem>
                          <MenuItem value="Lingala">Lingala</MenuItem>
                          <MenuItem value="Lao">Lao</MenuItem>
                          <MenuItem value="Lithuanian">Lithuanian</MenuItem>
                          <MenuItem value="Luba-Katanga">Luba-Katanga</MenuItem>
                          <MenuItem value="Latvian">Latvian</MenuItem>
                          <MenuItem value="Magahi">Magahi</MenuItem>
                          <MenuItem value="Maithili">Maithili</MenuItem>
                          <MenuItem value="Malagasy">Malagasy</MenuItem>
                          <MenuItem value="Marshallese">Marshallese</MenuItem>
                          <MenuItem value="Maori">Maori</MenuItem>
                          <MenuItem value="Macedonian">Macedonian</MenuItem>
                          <MenuItem value="Malayalam">Malayalam</MenuItem>
                          <MenuItem value="Mongolian">Mongolian</MenuItem>
                          <MenuItem value="Marathi">Marathi</MenuItem>
                          <MenuItem value="Malay">Malay</MenuItem>
                          <MenuItem value="Maltese">Maltese</MenuItem>
                          <MenuItem value="Burmese">Burmese</MenuItem>
                          <MenuItem value="Nauru">Nauru</MenuItem>
                          <MenuItem value="Norwegian Bokmål">
                            Norwegian Bokmål
                          </MenuItem>
                          <MenuItem value="North Ndebele">
                            North Ndebele
                          </MenuItem>
                          <MenuItem value="Nepali">Nepali</MenuItem>
                          <MenuItem value="Ndonga">Ndonga</MenuItem>
                          <MenuItem value="Dutch">Dutch</MenuItem>
                          <MenuItem value="Norwegian Nynorsk">
                            Norwegian Nynorsk
                          </MenuItem>
                          <MenuItem value="Norwegian">Norwegian</MenuItem>
                          <MenuItem value="South Ndebele">
                            South Ndebele
                          </MenuItem>
                          <MenuItem value="Navajo">Navajo</MenuItem>
                          <MenuItem value="Chichewa">Chichewa</MenuItem>
                          <MenuItem value="Occitan">Occitan</MenuItem>
                          <MenuItem value="Ojibwa">Ojibwa</MenuItem>
                          <MenuItem value="Oromo">Oromo</MenuItem>
                          <MenuItem value="Oriya">Oriya</MenuItem>
                          <MenuItem value="Ossetian">Ossetian</MenuItem>
                          <MenuItem value="Pali">Pali</MenuItem>
                          <MenuItem value="Polish">Polish</MenuItem>
                          <MenuItem value="Pushto">Pushto</MenuItem>
                          <MenuItem value="Portuguese">Portuguese</MenuItem>
                          <MenuItem value="Quechua">Quechua</MenuItem>
                          <MenuItem value="Romansh">Romansh</MenuItem>
                          <MenuItem value="Rundi">Rundi</MenuItem>
                          <MenuItem value="Romanian">Romanian</MenuItem>
                          <MenuItem value="Russian">Russian</MenuItem>
                          <MenuItem value="Kinyarwanda">Kinyarwanda</MenuItem>
                          <MenuItem value="Sanskrit">Sanskrit</MenuItem>
                          <MenuItem value="Sardinian">Sardinian</MenuItem>
                          <MenuItem value="Sindhi">Sindhi</MenuItem>
                          <MenuItem value="Northern Sami">
                            Northern Sami
                          </MenuItem>
                          <MenuItem value="Sango">Sango</MenuItem>
                          <MenuItem value="Sinhala">Sinhala</MenuItem>
                          <MenuItem value="Slovak">Slovak</MenuItem>
                          <MenuItem value="Slovene">Slovene</MenuItem>
                          <MenuItem value="Samoan">Samoan</MenuItem>
                          <MenuItem value="Shona">Shona</MenuItem>
                          <MenuItem value="Somali">Somali</MenuItem>
                          <MenuItem value="Albanian">Albanian</MenuItem>
                          <MenuItem value="Serbian">Serbian</MenuItem>
                          <MenuItem value="Swati">Swati</MenuItem>
                          <MenuItem value="Sotho, Southern">
                            Sotho, Southern
                          </MenuItem>
                          <MenuItem value="Sundanese">Sundanese</MenuItem>
                          <MenuItem value="Swedish">Swedish</MenuItem>
                          <MenuItem value="Swahili">Swahili</MenuItem>
                          <MenuItem value="Tamil">Tamil</MenuItem>
                          <MenuItem value="Telugu">Telugu</MenuItem>
                          <MenuItem value="Tajik">Tajik</MenuItem>
                          <MenuItem value="Thai">Thai</MenuItem>
                          <MenuItem value="Tigrinya">Tigrinya</MenuItem>
                          <MenuItem value="Turkmen">Turkmen</MenuItem>
                          <MenuItem value="Tagalog">Tagalog</MenuItem>
                          <MenuItem value="Tswana">Tswana</MenuItem>
                          <MenuItem value="Tonga (Tonga Islands)">
                            Tonga (Tonga Islands)
                          </MenuItem>
                          <MenuItem value="Turkish">Turkish</MenuItem>
                          <MenuItem value="Tsonga">Tsonga</MenuItem>
                          <MenuItem value="Tatar">Tatar</MenuItem>
                          <MenuItem value="Twi">Twi</MenuItem>
                          <MenuItem value="Tahitian">Tahitian</MenuItem>
                          <MenuItem value="Uighur">Uighur</MenuItem>
                          <MenuItem value="Ukrainian">Ukrainian</MenuItem>
                          <MenuItem value="Urdu">Urdu</MenuItem>
                          <MenuItem value="Uzbek">Uzbek</MenuItem>
                          <MenuItem value="Venda">Venda</MenuItem>
                          <MenuItem value="Vietnamese">Vietnamese</MenuItem>
                          <MenuItem value="Volapük">Volapük</MenuItem>
                          <MenuItem value="Wallon">Wallon</MenuItem>
                          <MenuItem value="Wolof">Wolof</MenuItem>
                          <MenuItem value="Xhosa">Xhosa</MenuItem>
                          <MenuItem value="Yiddish">Yiddish</MenuItem>
                          <MenuItem value="Cantonese">Cantonese</MenuItem>
                          <MenuItem value="Yoruba">Yoruba</MenuItem>
                          <MenuItem value="Zhuang">Zhuang</MenuItem>
                          <MenuItem value="Chinese">Chinese</MenuItem>
                          <MenuItem value="Zulu">Zulu</MenuItem>
                        </TextField>
                      </>
                    ) : (
                      <FormControlLabel
                        value="Others"
                        control={
                          <Radio
                            sx={{
                              color: "#E1E1E1",
                              "&.Mui-checked": {
                                color: "#33D67C",
                              },
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: "DM Sans !important",
                              fontSize: "14px !important",
                              fontWeight: 400,
                              lineHeight: "18px !important",
                            }}
                          >
                            Others
                          </Typography>
                        }
                        sx={{
                          ".css-1yq3e2k-MuiButtonBase-root-MuiRadio-root.Mui-checked ":
                            {},
                        }}
                      />
                    )}
                  </RadioGroup>
                </FormControl>
                {error && formData.songlang.length <= 0 ? (
                  <label className="errorhandler">
                    Song Language can't be Empty
                  </label>
                ) : (
                  ""
                )}
              {errormessage  && (
                      <Typography className="errorhandler">
                        Instrumental songs distribution are often checked and scrutinised more due to high artificial streaming detection rates and streaming fraud in instrumental songs and as an aggregator we check the instrumental song very closely, hence delay in distribution can be expected. Make sure that the song is 100% original.
                      </Typography>
                    )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{ fontFamily: "DM Sans", fontWeight: "700", mb: "8px" }}
              >
                Song Genre*
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-currency"
                select
                value={formData.songgenre}
                onChange={(event) =>
                  setFormData({ ...formData, songgenre: event.target.value })
                }
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "18px",
                  color: "#E1E1E1",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
              >
                {songGenre.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Typography sx={{ fontFamily: "DM sans" }}>
                      {option.label}
                    </Typography>
                  </MenuItem>
                ))}
              </TextField>
              {error && formData.songgenre.length <= 0 ? (
                <label className="errorhandler">
                  Song genre can't be Empty
                </label>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{ fontFamily: "DM Sans", fontWeight: "700", mb: "8px" }}
              >
                Preview Start Time(In Seconds Only)
              </Typography>
              <Box>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  type="number"
                  placeholder="40"
                  value={formData.previewstart}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      previewstart: event.target.value,
                    })
                  }
                  label=""
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{ fontFamily: "DM Sans", fontWeight: "700", mb: "8px" }}
              >
                Release Date
              </Typography>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  sx={{ background: "red !important" }}
                >
                  <DesktopDatePicker
                    label=""
                    inputFormat="DD/MM/YYYY"
                    minDate={lastDay}
                    slots={{ Day: <h1>HEllo</h1> }}
                    className="orange-date"
                    input
                    value={value}
                    onChange={handleChanged}
                    renderInput={(params) => (
                      <TextField
                        onKeyDown={onKeyDown}
                        {...params}
                        fullWidth
                        sx={{
                          backgroundColor:
                            "rgba(255, 255, 255, 0.05) !important",
                          ".css-9425fu-MuiOutlinedInput-notchedOutline": {
                            borderStyle: "none",
                          },
                          "& .Mui-selected": {
                            backgroundColor: "orange",
                            color: "orange",
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ display: { xs: "block", sm: "none" } }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label=""
                    inputFormat="DD/MM/YYYY"
                    minDate={lastDay}
                    input
                    value={value}
                    onChange={handleChanged}
                    renderInput={(params) => (
                      <TextField
                        onKeyDown={onKeyDown}
                        {...params}
                        fullWidth
                        sx={{
                          backgroundColor:
                            "rgba(255, 255, 255, 0.05) !important",
                          ".css-9425fu-MuiOutlinedInput-notchedOutline": {
                            borderStyle: "none",
                          },
                          "& .Mui-selected": {
                            backgroundColor: "orange",
                            color: "orange",
                          },
                        }}
                      />
                    )}
                    sx={{}}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            background: "rgba(255, 255, 255, 0.05) !important",
            height: "auto",
            padding: "30px",
            borderRadius: "8px",
            mt: "28px",
          }}
        >
          <Box sx={{ display: "flex", ml: "9px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChangedata}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    width: "20px",
                    color: "#ffffff",
                    "&.Mui-checked": {
                      color: "#fa9e0d",
                    },
                  }}
                />
              }
              sx={{
                textAlign: "start",
                color: "black",
                fontFamily: "Open Sans, sans-serif",
                fontSize: "0.75rem",
                fontWeight: 700,
                lineHeight: "1rem",
              }}
            />
            <Typography sx={{ mt: "8px", fontSize: "18px", fontWeight: "700" }}>
              Yes, it is a movie song
            </Typography>
          </Box>
          <Collapse in={expand} timeout="auto" unmountOnExit>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{ fontFamily: "DM Sans", fontWeight: "700", mb: "8px" }}
                >
                  Film Name
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  value={formData.filmname}
                  onChange={(event) =>
                    setFormData({ ...formData, filmname: event.target.value })
                  }
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                  }}
                />
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{ fontFamily: "DM Sans", fontWeight: "700", mb: "8px" }}
                >
                  Director Name
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  value={formData.directorname}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      directorname: event.target.value,
                    })
                  }
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                  }}
                />
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{ fontFamily: "DM Sans", fontWeight: "700", mb: "8px" }}
                >
                  Actor Name
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  value={formData.actorname}
                  onChange={(event) =>
                    setFormData({ ...formData, actorname: event.target.value })
                  }
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                  }}
                />
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{ fontFamily: "DM Sans", fontWeight: "700", mb: "8px" }}
                >
                  Actress Name
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  value={formData.actressname}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      actressname: event.target.value,
                    })
                  }
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                  }}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Box>

        <Card
          className="tracks"
          sx={{ background: "rgba(255, 255, 255, 0.05)" }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "700",
              fontFamily: "DM Sans",
              mb: "20px",
            }}
          >
            Song Lyrics
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} >
              <LyricsUpload setSongLyrics={setSongLyrics} songLyrics={songLyrics}/>
            </Grid>
            <Grid container item xs={12} md={6} >
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "21px",
                    mb: 1,
                  }}
                >
                  Spotify ID
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  value={formData.spotifyId}
                  onChange={(event) =>
                    setFormData({ ...formData, spotifyId: event.target.value })
                  }
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                  }}
                />
              </Grid>

              <Grid item xs={12} >
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "21px",
                    mb: 1,
                  }}
                >
                  Apple ID
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  value={formData.appleId}
                  onChange={(event) =>
                    setFormData({ ...formData, appleId: event.target.value })
                  }
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "21px",
                    mb: 1,
                  }}
                >
                  Channel ID
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  value={formData.channelId}
                  onChange={(event) =>
                    setFormData({ ...formData, channelId: event.target.value })
                  }
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default Index;

import { Container } from "@mui/system";
import React from "react";
import Box from "@mui/material/Box";
import "./style.css";
import { Grid, Typography } from "@mui/material";

const Index = () => {
  return (
    <Box className="artist-number-banner">
        <Container sx={{ maxWidth:'90% !important', color:'white'}}>
      <Grid container spacing={1}>
        <Grid item md={4} xs={12} sx={{padding:{md:"0px",xs:"0px"}}} >
        <Box className="Number-item-label" sx={{ padding:{md:"20px",xs:"0px"} }}    >
        <Typography className="inner" sx={{fontSize:{xs:'32px',sm:'48px'}}}>56000+</Typography>
        <Typography variant="p" className="Number-item">Artist and Labels</Typography>
      </Box>
        </Grid>
        <Grid item md={4} xs={12} sx={{padding:{md:"0px",xs:"0px"}}} >
        <Box className="Number-item-label" sx={{ padding:{md:"20px",xs:"0px"} }}  >
        <Typography variant="h1" className="inner" sx={{fontSize:{xs:'32px',sm:'48px'}}}>1M+</Typography>
        <Typography variant="p" className="Number-item">Content pieces distributed</Typography>
      </Box>
        </Grid>
        <Grid item md={4} xs={12} sx={{padding:{md:"0px",xs:"0px"}}}  >
        <Box className="Number-item-label"  sx={{ padding:{md:"20px",xs:"15px"} }}  >
        <Typography variant="h1" className="inner" sx={{fontSize:{xs:'32px',sm:'48px'}}}>100M+</Typography>
        <Typography variant="p" className="Number-item">Royalty paid In Total</Typography>
      </Box>
        </Grid>
      </Grid>
      </Container>
    </Box>
  );
};

export default Index;

import React from 'react'
import {Typography, Card, Box} from "@mui/material";
import { PiCoins } from "react-icons/pi";
const ReferralTopCard = ({totalCoins}) => {
  return (
    <Box>
    <Card className="card" sx={{background: 'rgba(255, 255, 255, 0.05)'}}>
      <Typography className="yourwallet"> Your Referrals</Typography>
      <Typography fontWeight="700" fontSize=" 1.3rem">
        Total Coins: {totalCoins}{" "}<PiCoins style={{color : "#fb8e0b"}}/>
      </Typography>
    </Card>
  </Box>
  )
}

export default ReferralTopCard
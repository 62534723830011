import { Box } from "@mui/system";
import React from "react";
import Contact from "../../components/contactForm/index";
import Navbar from "../../components/common/navbar/index";
import Footer from "../../components/common/footer/index";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
const themeLight = createTheme({
  palette: {
    mode: "light",
  },
});

const pricing = () => {
  return (
    <Box>
      <ThemeProvider theme={themeLight}>
        <CssBaseline />
        <Navbar />
        <Contact />
        <Footer />
      </ThemeProvider>
    </Box>
  );
};

export default pricing;

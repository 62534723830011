import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import "./style.css";
import * as yup from "yup";
import LoopIcon from "@mui/icons-material/Loop";
import { toast, ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  useSignupArtistMutation,
  useSendOtpSignupMutation,
} from "../../redux/services/login";
import styled from "styled-components";

import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import flags from "react-phone-number-input/flags";

const theme = createTheme();
const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  firstName: yup
    .string("Enter your First Name")
    .required("First Name is required"),
  lastName: yup
    .string("Enter your Last Name")
    .required("Last Name is required"),
  phone: yup.number("Phone Number is required").required("Phone is required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

const ResendButton = styled.button`
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 16px;
  color: ${({ disabled }) => (disabled ? "#ccc" : "#000")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

export default function SignIn() {
  const [formData, setFormData] = useState({
    sum: "",
  });
  const [refercodeError, setRefercodeError] = useState(null);
  const navigate = useNavigate();
  const [code, setCode] = useState(false);
  const [otp, setOtp] = useState("");
  const [datafetch, setdatafetch] = useState();
  const [sendotp, responseInfo] = useSendOtpSignupMutation();
  const [signup, responseInfodata] = useSignupArtistMutation();
  const [counter, setCounter] = useState();
  const [showMessage, setShowMessage] = useState(false);
  // const [showMessage, setShowMessage] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    const timer =
      counter > 0 &&
      setInterval(() => {
        setCounter(counter - 1);
      }, 1000);

    return () => clearInterval(timer);
  }, [counter]);

  const [state, setState] = useState({
    textInputHolder: 0,
    sumHolder: 0,
    randomNumberOne: Math.floor(Math.random() * 100) + 1,
    randomNumberTwo: Math.floor(Math.random() * 100) + 1,
  });
  const [checksum, setChecksum] = useState(
    state?.randomNumberOne + state?.randomNumberTwo
  );
  const generateCaptcha = () => {
    var numberOne = Math.floor(Math.random() * 100) + 1;
    var numberTwo = Math.floor(Math.random() * 100) + 1;
    var sum = numberOne + numberTwo;
    setState({ randomNumberOne: numberOne, randomNumberTwo: numberTwo });
    setChecksum(numberOne + numberTwo);
  };
  const [datasave, setDatasave] = useState();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
      nonIndian: false,
      otp: "",
      refercode: "",
    },
    
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setCounter(45);
      if (values?.refercode && values?.refercode?.trim()?.length !== 6) {
        setRefercodeError("Invalid Referral Code");
        return;
      }
      const payload = {
        name: {
          first: values.firstName,
          last: values.lastName,
        },
        email: values.email,
        password: values.password,
        role: "artist",
        phone: values.phone,
        is_non_indian: Boolean(values.nonIndian),
      };
      setDatasave({...payload, refercode: values?.refercode,});
      await axios
        .post(
          `${process.env.REACT_APP_DMT_API_PATH}/authentication/mobile/send-register-otp`,
          payload
        )
        .then((res) => {
          if (res.data.error === true) {
            toast.error(res?.data?.message);
          } else {
            toast.success("Otp send successfully");
            setCode(true);
            setdatafetch(payload);
            // navigate("/login-conversion", {
            //   state: {
            //     id: res?.data?.user,
            //   },
            // });
            // window.scrollTo(0, 0);
          }
        });
    },
  });
  const handlePhoneNumberChange = (value) => {
    formik.setFieldValue("phone", value);
  };
  useEffect(() => {
    setShowMessage(formik.values.nonIndian);
  }, [formik.values.nonIndian]);
  const handleotpsend = (event) => {
    setOtp(event.target.value);
  };
  const sendotpinmobile = async (values) => {
    const payloaddata = {
      name: {
        first: datafetch.name.first,
        last: datafetch.name.last,
      },
      email: datafetch.email,
      password: datafetch.password,
      phone: datafetch.phone,
      refercode: datasave?.refercode,
      is_non_indian: Boolean(datafetch.is_non_indian),
      terms: datafetch.terms,
      otp: otp,
      role: "artist",
    };
    await axios
      .post(
        `${process.env.REACT_APP_DMT_API_PATH}/authentication/mobile/verify-register-otp`,
        payloaddata
      )
      .then((res) => {
        if (res.data.error === true) {
          toast.error(res?.data?.message);
        } else {
          navigate("/login-conversion", {
            state: {
              id: res?.data?.user,
            },
          });
          window.scrollTo(0, 0);
        }
      });
  };
  const handleResendClick = async () => {
    setCounter(45);
    await axios
      .post(
        `${process.env.REACT_APP_DMT_API_PATH}/authentication/mobile/send-register-otp`,
        datasave
      )
      .then((res) => {
        if (res.data.error === true) {
          toast.error(res?.data?.message);
        } else {
          toast.success("Otp has been sent again");
        }
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" sx={{ textAlign: "-webkit-center" }}>
        <Paper className="paper-form-signup" component={Box} marginTop="80px">
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            padding="1rem 1.25rem;"
          >
            <Typography variant="h3" className="forgot-data">
              Artist Signup
            </Typography>

            <Grid container spacing={4} mt={2}>
              <Grid item md={6} xs={12} className="">
                <Box sx={{ marginTop: "20px", paddingLeft: "1.25rem" }}>
                  <Typography
                    sx={{
                      textAlign: "start",
                      color: "black",
                      fontFamily: "Open Sans, sans-serif",
                      fontSize: "0.75rem",
                      fontWeight: 700,
                      lineHeight: "1rem",
                    }}
                  >
                    First Name
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    id="firstName"
                    name="firstName"
                    placeholder="Enter Your First Name"
                    sx={{
                      background: "white",
                      marginTop: "4px",
                      border: "1px solid #1b1b38",
                      borderRadius: "4px",
                    }}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <Typography sx={{ textAlign: "start" }} color="error">
                      {formik.errors.firstName}
                    </Typography>
                  ) : null}
                </Box>
              </Grid>

              <Grid item md={6} xs={12} className="">
                <Box
                  sx={{
                    marginTop: { md: "20px", xs: "0px" },
                    paddingLeft: "1.25rem",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "start",
                      color: "black",
                      fontFamily: "Open Sans, sans-serif",
                      fontSize: "0.75rem",
                      fontWeight: 700,
                      lineHeight: "1rem",
                    }}
                  >
                    Last Name
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    id="lastName"
                    name="lastName"
                    placeholder="Enter Your Last Name"
                    sx={{
                      background: "white",
                      marginTop: "4px",
                      border: "1px solid #1b1b38",
                      borderRadius: "4px",
                    }}
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <Typography sx={{ textAlign: "start" }} color="error">
                      {formik.errors.lastName}
                    </Typography>
                  ) : null}
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid
                item
                md={6}
                xs={12}
                className=""
                sx={{ paddingBottom: "20px !important" }}
              >
                {/* <Box sx={{ marginTop: "20px", paddingLeft: "1.25rem" }}>
                  <Typography
                    sx={{
                      textAlign: "start",
                      color: "black",
                      fontFamily: "Open Sans, sans-serif",
                      fontSize: "0.75rem",
                      fontWeight: 700,
                      lineHeight: "1rem",
                    }}
                  >
                    Mobile Number
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    id="phone"
                    name="phone"
                    type="number"
                    placeholder="Enter Your Mobile Number"
                    sx={{
                      background: "white",
                      marginTop: "4px",
                      border: "1px solid #1b1b38",
                      borderRadius: "4px",
                    }}
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <Typography sx={{ textAlign: "start" }} color="error">
                      {formik.errors.phone}
                    </Typography>
                  ) : null}
                  {showMessage && <p style={{ textAlign: "start" }}>Kindly enter your whatsapp number only for OTP purpose</p>}
                </Box> */}
                <Box sx={{ marginTop: "20px", paddingLeft: "1.25rem" }}>
                  <Typography
                    sx={{
                      textAlign: "start",
                      color: "black",
                      fontFamily: "Open Sans, sans-serif",
                      fontSize: "0.75rem",
                      fontWeight: 700,
                      lineHeight: "1rem",
                    }}
                  >
                    Mobile Number
                  </Typography>
                  <PhoneInput
                    international
                    defaultCountry="IN"
                    flags={flags}
                    style={{ marginTop: "5px" }}
                    value={formik.values.phone}
                    onChange={handlePhoneNumberChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Your Mobile Number"
                    inputProps={{
                      style: {
                        height: "40px",
                        paddingLeft: "36px",
                        background: "white",
                        marginTop: "4px",
                        border: "1px solid #1b1b38",
                        borderRadius: "4px",
                      },
                      name: "phone",
                      id: "phone",
                    }}
                    buttonStyle={{
                      borderRadius: "4px 0 0 4px",
                      background: "white",
                      border: "1px solid #1b1b38",
                      height: "40px",
                    }}
                    countryCodeEditable={false}
                    renderButton={(props) => (
                      <Box
                        component="button"
                        {...props}
                        sx={{
                          position: "absolute",
                          left: "0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "36px",
                          height: "40px",
                          borderRadius: "4px 0 0 4px",
                          background: "white",
                          border: "1px solid #1b1b38",
                        }}
                      >
                        <span
                          style={{
                            backgroundImage: `url(${
                              flags[props.country]?.emoji
                            })`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </Box>
                    )}
                  />
                  {showMessage && (
                    <Typography
                      sx={{ textAlign: "start" }}
                      color="text.secondary"
                    >
                      Kindly enter your whatsapp number only for OTP purpose
                    </Typography>
                  )}
                  {formik.touched.phone && formik.errors.phone ? (
                    <Typography sx={{ textAlign: "start" }} color="error">
                      {formik.errors.phone}
                    </Typography>
                  ) : null}
                </Box>
              </Grid>
              <Grid item md={6} xs={12} className="">
                <Box
                  sx={{
                    marginTop: { md: "20px", xs: "0px" },
                    paddingLeft: "1.25rem",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "start",
                      color: "black",
                      fontFamily: "Open Sans, sans-serif",
                      fontSize: "0.75rem",
                      fontWeight: 700,
                      lineHeight: "1rem",
                    }}
                  >
                    Email
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    id="email"
                    name="email"
                    placeholder="Enter Your Email ID"
                    sx={{
                      background: "white",
                      marginTop: "4px",
                      border: "1px solid #1b1b38",
                      borderRadius: "4px",
                    }}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <Typography sx={{ textAlign: "start" }} color="error">
                      {formik.errors.email}
                    </Typography>
                  ) : null}
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item md={6} xs={12} className="">
                <Box
                  sx={{
                    marginTop: { md: "20px", xs: "0px" },
                    paddingLeft: "1.25rem",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "start",
                      color: "black",
                      fontFamily: "Open Sans, sans-serif",
                      fontSize: "0.75rem",
                      fontWeight: 700,
                      lineHeight: "1rem",
                    }}
                  >
                    Password
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Enter Your Password"
                    sx={{
                      background: "white",
                      marginTop: "4px",
                      border: "1px solid #1b1b38",
                      borderRadius: "4px",
                    }}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <Typography sx={{ textAlign: "start" }} color="error">
                      {formik.errors.password}
                    </Typography>
                  ) : null}
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box
                  sx={{
                    marginTop: { md: "20px", xs: "0px" },
                    paddingLeft: "1.25rem",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "start",
                      color: "black",
                      fontFamily: "Open Sans, sans-serif",
                      fontSize: "0.75rem",
                      fontWeight: 700,
                      lineHeight: "1rem",
                    }}
                  >
                    Referral Code
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    id="refercode"
                    name="refercode"
                    placeholder="Enter Referral Code Here.."
                    sx={{
                      background: "white",
                      marginTop: "4px",
                      border: "1px solid #1b1b38",
                      borderRadius: "4px",
                    }}
                    value={formik.values.refercode}
                    onChange={formik.handleChange}
                    inputProps={{
                      maxLength: 6,
                    }}
                  />
                  {refercodeError && (
                    <Typography sx={{ textAlign: "start" }} color="error">
                      {refercodeError}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Box
              textAlign="start"
              sx={{ marginLeft: "10px", marginTop: "20px" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ marginTop: "-2px" }}
                    name="nonIndian"
                    value={formik.values.nonIndian}
                    onChange={formik.handleChange}
                  />
                }
                label="Non-Indian/NRI ?"
                sx={{
                  textAlign: "start",
                  color: "black",
                  fontFamily: "Open Sans, sans-serif",
                  fontSize: "0.75rem",
                  fontWeight: 700,
                  lineHeight: "1rem",
                }}
              />
            </Box>

            {code === true ? (
              <>
                <Box sx={{ marginTop: "20px", paddingLeft: "0.6rem" }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "black",
                      fontFamily: "Open Sans, sans-serif",
                      fontSize: "0.75rem",
                      fontWeight: 700,
                      lineHeight: "1rem",
                      // border:"1px solid black"
                    }}
                  >
                    OTP
                  </Typography>
                  <TextField
                    halfWidth
                    size="small"
                    id="otp"
                    name="otp"
                    placeholder="Enter OTP"
                    // value={formik.values.otp}
                    onChange={handleotpsend}
                    sx={{
                      background: "white",
                      marginTop: "4px",
                      border: "1px solid #1b1b38",
                      borderRadius: "4px",
                      width: "140px",
                    }}
                  />
                </Box>
                {/* <Button
                sx={{marginTop:'15px'}}
                className="submit-btn"
                  onClick={() => {
                    sendotpinmobile();
                  }}
                >
                  SignUp
                </Button> */}
                <Button
                  sx={{ marginTop: "25px" }}
                  className="submit-btn"
                  // type="submit"
                  onClick={() => {
                    sendotpinmobile();
                    // "return gtag_report_conversion('http://example.com/your-link')"
                  }}
                  id="conversion-dmt-signup"
                >
                  SignUp
                </Button>
                <Typography
                  className="already-account"
                  style={{ marginTop: "10px" }}
                >
                  {counter > 0
                    ? `Resend OTP in ${counter}s`
                    : "OTP expired, please resend"}
                </Typography>
                <ResendButton
                  disabled={counter > 0}
                  onClick={handleResendClick}
                >
                  Resend OTP
                </ResendButton>
              </>
            ) : (
              <Box textAlign="center" marginTop="25px" marginBottom="15px">
                <Button className="submit-btn" type="submit">
                  <AddIcon sx={{ marginRight: "10px", color: "white" }} />
                  SEND OTP
                </Button>
              </Box>
            )}

            {/* <Button
              sx={{ marginTop: "25px" }}
              className="submit-btn"
              type="submit"
              onClick={
                "return gtag_report_conversion('http://example.com/your-link')"
              }
              id="conversion-dmt-signup"
            >
              SignUp
            </Button> */}
            <Box
              sx={{
                textAlign: "center",
                marginTop: "40px",
                marginBottom: "20px",
              }}
            >
              <Typography className="already-account">
                <Link
                  to="/login"
                  className="AlreadyHaveAnAccount"
                  underline="hover"
                  color="inherit"
                >
                  Already have an account?
                </Link>
                <Link
                  className="gotologin"
                  underline="hover"
                  color="inherit"
                  to="/login"
                >
                  {" "}
                  Signin
                </Link>
              </Typography>
            </Box>
            <ToastContainer />
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}

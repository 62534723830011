import React from "react";
import { Box, Typography, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Error from "../errorPage/Images/Error.jpg";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import "../errorPage/error.css";

function ErrorPage() {
  const themeLight = createTheme({
    palette: {
      mode: "light",
    },
  });
  return (
    <ThemeProvider theme={themeLight}>
      <CssBaseline />
      <Box className="error-page-div" sx={{ background: "white" }}>
        <img src={Error} />

        <Box id="error-message-wraper-box">
          <Box>
            <Typography
              id="error-first-text"
              sx={{
                marginTop: { xs: "10px", sm: "20px" },
                marginLeft: { xs: "-40px", sm: "-30px", lg: "-10px" },
              }}
            >
              Ooops...
            </Typography>
          </Box>

          <Box>
            <Typography
              id="error-second-text"
              sx={{
                marginTop: { xs: "-70px", sm: "-50px", xl: "35px" },
                marginLeft: {
                  xs: "-100px",
                  sm: "-50px",
                  lg: "-10px",
                  xl: "15px",
                },
              }}
            >
              Page not found
            </Typography>
          </Box>

          <Box>
            <Typography
              id="error-third-text"
              sx={{
                marginTop: { xs: "-80px", sm: "-80px", xl: "10px" },
                marginLeft: {
                  xs: "-110px",
                  sm: "-90px",
                  md: "-83px",
                  lg: "-20px",
                  xl: "20px",
                },
                marginRight: { xs: "12px" },
              }}
            >
              The page you're looking for does not seem to exist
            </Typography>
          </Box>

          <Box>
            <Button
              href="/home"
              variant="contained"
              size="large"
              type="submit"
              id="errorPageButton"
              startIcon={<ArrowBackIcon />}
              sx={{
                background: "#f9a40d !important",
                fontWeight: "700",
                textTransform: "capitalize",
                borderRadius: "21px",

                marginTop: { xs: "-10px", sm: "0px", xl: "70px" },
                marginLeft: {
                  xs: "-10px",
                  sm: "35px",
                  md: "70px",
                  lg: "110px",
                  xl: "150px",
                },
              }}
            >
              Go Back
            </Button>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ErrorPage;

import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/system";
import { Divider } from "@mui/material";
import Link from "@mui/material/Link";

const sitemap = () => {
  return (
    <Box marginTop="70px">
      <Container>
        <Button sx={{ color: "#a16eff", textTransform: "capitalize" }}>
          <Link
            sx={{ textDecoration: "none" }}
            className="link-btn"
            color="inherit"
            href="/home"
          >
            Home
          </Link>
        </Button>
        <Divider />
        <Button sx={{ color: "#a16eff", textTransform: "capitalize" }}>
          <Link
            sx={{ textDecoration: "none" }}
            className="link-btn"
            color="inherit"
            href="/about"
          >
            About
          </Link>
        </Button>
        <Divider />
        <Button sx={{ color: "#a16eff", textTransform: "capitalize" }}>
          <Link
            sx={{ textDecoration: "none" }}
            className="link-btn"
            color="inherit"
            href="/contact"
          >
            Contact
          </Link>
        </Button>
        <Divider />
        <Button sx={{ color: "#a16eff", textTransform: "capitalize" }}>
          <Link
            sx={{ textDecoration: "none" }}
            className="link-btn"
            color="inherit"
            href="/Faq"
          >
            Faqs
          </Link>
        </Button>
        <Divider />
        <Button sx={{ color: "#a16eff", textTransform: "capitalize" }}>
          <Link
            sx={{ textDecoration: "none" }}
            className="link-btn"
            href="/login"
            color="inherit"
          >
            Login
          </Link>
        </Button>
        <Divider />
        <Button sx={{ color: "#a16eff", textTransform: "capitalize" }}>
          <Link
            sx={{ textDecoration: "none" }}
            className="link-btn"
            color="inherit"
            href="/pricing"
          >
            Price
          </Link>
        </Button>
        <Divider />
        <Button sx={{ color: "#a16eff", textTransform: "capitalize" }}>
          <Link
            sx={{ textDecoration: "none" }}
            className="link-btn"
            color="inherit"
            href="/pricing"
          >
            service
          </Link>
        </Button>
        <Divider />
        <Button sx={{ color: "#a16eff", textTransform: "capitalize" }}>
          <Link
            sx={{ textDecoration: "none" }}
            className="link-btn"
            color="inherit"
            href="signup/artist"
          >
            Signup
          </Link>
        </Button>
        {/* <Divider /> */}
        {/* <Button sx={{ color: "#a16eff", textTransform: "capitalize" }}>
          Fan
        </Button> */}
        {/* <Divider /> */}
        {/* <Button sx={{ color: "#a16eff", textTransform: "capitalize" }}>
          Label
        </Button> */}
        <Divider />
        <Button sx={{ color: "#a16eff", textTransform: "capitalize" }}>
          <Link
            sx={{ textDecoration: "none" }}
            className="link-btn"
            color="inherit"
            href="/stores"
          >
            Store
          </Link>
        </Button>
        <Divider />
      </Container>
    </Box>
  );
};

export default sitemap;

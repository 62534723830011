import React from "react";
import Plan from "../components/plan/index";
import Billing from "../components/billing/index";
import BasicDetailsdata from "../components/basicDetails/index";
import Box from "@mui/material/Box";
import AdvancedDetails from "../components/advancedDetails/index";
import PublishDetails from "../components/publishingDetails/index";
import { useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import Container from "@mui/material/Container";
import Stepper from "../components/progressBar/index";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IoIosArrowForward } from "react-icons/io";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { userSlice } from "../redux/slices/authSlice";
import dayjs from "dayjs";
import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ScaleLoader } from "react-spinners";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { choosePlatfrom } from "../components/publishingDetails/utils";
import Declaration from "../components/declaration/index";

const DistributeSingleTrack = ({
  songValue,
  setSongValue,
  career,
  saveImage,
  setSaveimage,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = React.useState(career?.releaseDate || null);
  const [timevalue, setTimevalue] = React.useState(career?.recordLabel || null);
  const [ringtone, setRingtone] = useState();
  const [paymentdetail, setPaymentDetail] = useState(0);
  const [moviesong, setMoviesong] = useState(false);
  const [tracktomaster, setTracktomaster] = useState(false);
  const firstslidedata = useSelector((state) => state?.user);
  const [Instagramprofile, setIsInstagramprofile] = useState(false);
  const [userinfo, setUserinfo] = useState();
  const [platform, setPlatform] = useState(choosePlatfrom);
  const [paymentget, setPaymentget] = useState(0);
  const [paymentidget, setPaymentidget] = useState([]);
  const [totalamount, setTotalamount] = useState(0);
  const [billshow, setBillshow] = useState();
  const [walletamount, setWalletamount] = useState(0);
  const [error, setError] = useState(false);
  const [isUnsplash, setisUnsplash] = useState(false);
  const [seconderror, setSecondError] = useState(false);
  const [thirdError, setThirdError] = useState(false);
  const [showuserdata, setShowuserdata] = useState([]);
  const [id, setId] = useState();
  const [amount, setAmount] = useState();
  const [donate, setDonateValue] = useState(0);
  const [filedata, setFiledata] = useState(
    career?.cover || firstslidedata?.cover || ""
  );
  const [songLyrics, setSongLyrics] = useState(
    career?.songLyrics || firstslidedata?.songLyrics || ""
  );
  const [backflow, setBackflow] = useState(false);
  const [secondBack, setSecondback] = useState(false);
  const [coverfile, setCoverfile] = useState(
    career?.tracks[0]?.coverSongFile || ""
  );
  const [checkeddeclaration, setCheckeddeclaration] = useState({
    artwork: false,
    declaration: false,
    copyrighted: false,
    lyrics: false,
    pause: false,
    composition: false,
  });
  const [songprocessing, setSongprocessing] = useState(false);
  const [formData, setFormData] = useState({
    name: career?.name || "",
    artwork: career?.cover || firstslidedata?.cover || "",
    recordlabel: career?.recordLabel || "",
    spotifyId: userinfo?.spotifyId || "",
    appleId: userinfo?.appleId || "",
    channelId: userinfo?.channelId || "",
    songlang: career?.language || "",
    songgenre: career?.genre || "",
    previewstart: career?.tracks[0]?.previewStartTime || "",
    releasedate: career?.releaseDate || "",
    moviesong: career?.filmBoolean || moviesong,
    filmname: career?.filmDetail?.name || "",
    directorname: career?.filmDetail?.artists[0]?.name || "",
    actorname: career?.filmDetail?.artists[1]?.name || "",
    actressname: career?.filmDetail?.artists[2]?.name || "",
    tracktomaster: career?.isMastered || tracktomaster,
    isInstagramprofile: career?.isInstagramprofile || Instagramprofile,
    fbemail: userinfo?.fbemail || "",
    instagramemail: userinfo?.instagramemail || "",
    instagrampage: userinfo?.instagrampage || "",
    fbpage: userinfo?.fbpage || "",
    songtitle: career?.tracks[0]?.name || "",
    videolink: career?.tracks[0]?.videoLink || "",
    isrccode: career?.tracks[0]?.isrcCode || "",
    compositiontype: career?.tracks[0]?.compositionType || "",
    cleansong: career?.tracks[0]?.cleanExplicit || "",
    role: career?.tracks[0]?.compositionType || "",
    fullname: career?.tracks[0]?.compositionType || "",
    songurl: career?.tracks[0]?.file || "",
    uploadringtone: career?.tracks[0]?.ringtone || "",
    copyrightyear: career?.copyright?.name || "",
    productionright: career?.productionRight?.name || "",
    chooseplatform: career?.platforms,
    plan: "",
    phone: "",
    otp: "",
    coupon: "",
  });
  const [artistdetail, setArtistdetail] = useState({
    primaryfirstname: "",
    primarylastname: "",
    primaryspotifyid: "",
    primaryinstagram: "",
    primarytwitter: "",
    primaryfacebook: "",
    featuringfirstname: "",
    featuringlastname: "",
    featuringspotifyid: "",
    featuringinstagram: "",
    featuringtwitter: "",
    featuringfacebook: "",
    lyricistfirstname: "",
    lyricistlastname: "",
    lyricistspotifyid: "",
    lyricistinstagram: "",
    lyricisttwitter: "",
    lyricistfacebook: "",
    composerfirstname: "",
    composerlastname: "",
    composerspotifyid: "",
    composerinstagram: "",
    composertwitter: "",
    composerfacebook: "",
  });
  const [listData, setListData] = useState([
    {
      role: "",
      name: "",
      lastname: "",
      spotifyid:"",
      instagram:"",
      twitter:"",
      facebook:""
    },
  ]);
  let datevalue = JSON.stringify(value?.$d);
  datevalue = datevalue?.slice(1, 11);
  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;

  useEffect(() => {
    if (career?.releaseDate) {
      setValue(career?.releaseDate);
    }
  }, [career]);
  useEffect(() => {
    if (career?.tracks[0]?.file) {
      setSongValue(career?.tracks[0]?.file);
    }
  }, [career]);
  useEffect(() => {
    if (career?.tracks[0]?.ringtone) {
      setRingtone(career?.tracks[0]?.ringtone);
    }
  }, [career]);
  useEffect(() => {
    if (career?.filmBoolean) {
      setMoviesong(career?.filmBoolean);
    }
  }, [career]);
  useEffect(() => {
    console.log(career, "career");
    if (career?.isMastered) {
      setTracktomaster(career?.isMastered);
    }
    if (career?.isInstagramprofile) {
      setIsInstagramprofile(career?.isInstagramprofile);
    }
  }, [career]);

  useEffect(() => {
    if (career) {
      setId(career?._id);
      const userid = career?._id;
    } else {
      setId(loginToken?.user?._id);
      const userid = loginToken?.user._id;
    }
  }, [career, setId]);
  useEffect(() => {
    if (firstslidedata?.tracks) {
      if (firstslidedata?.tracks[0]?.file?.length > 0) {
        setSongValue(firstslidedata?.tracks[0]?.file);
      }
    }
  }, [firstslidedata, setSongValue]);
  useEffect(() => {
    if (firstslidedata?.tracks) {
      if (firstslidedata?.tracks[0]?.ringtone?.length > 0) {
        setRingtone(firstslidedata?.tracks[0]?.ringtone);
      }
    }
  }, [firstslidedata, setRingtone]);
  useEffect(() => {
    if (career) {
      setCheckeddeclaration({
        artwork: career.declaration.artwork ?? false,
        declaration: career.declaration.declaration ?? false,
        copyrighted: career.declaration.copyrighted ?? false,
        lyrics: career.declaration.lyrics ?? false,
        pause: career.declaration.pause ?? false,
        composition: career.declaration.composition ?? false,
      });
    }
  }, [career]);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_DMT_API_PATH}/users`, config);
        if (response?.data?.error !== true) {
          const userData = response?.data?.data;
          setUserinfo(userData);
          setFormData((prevFormData) => ({
            ...prevFormData,
            spotifyId: userData?.spotifyId || "",
            appleId: userData?.appleId || "",
            channelId: userData?.channelId || "",
            fbemail: userData?.fbemail || "",
            instagramemail: userData?.instagramemail || "",
            instagrampage: userData?.instagrampage || "",
            fbpage: userData?.fbpage || "",
            // Update other fields as needed
          }));
          setIsLoading(false);
        }
      } catch (error) {
        // Handle errors if necessary
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleAddtag = () => {
    return "return gtag_report_conversion('http://example.com/your-link')";
  };
  const toggleSidebar = () => {
    dispatch(userSlice.actions.saveUser(""));
    setSongValue(undefined);
    setOpenSidebar((p) => !p);
  };

  const handleChange = async () => {
    const today = new Date();
    const lastDay = new Date(today.getTime() + 4 * 24 * 60 * 60 * 1000);
    var mydate = new Date(value);
    if (
      formData.name.length == 0 ||
      formData.recordlabel.length == 0 ||
      formData.songlang.length == 0 ||
      formData.songgenre.length == 0
    ) {
      setError(true);
      return;
    }

    if (!filedata) {
      toast.error("Cover is required");
    } else if (formData?.songlang === "Others") {
      toast.error("Song Language is required");
    } else if (!career && value?.$d === undefined) {
      toast.error("Release Date is required");
    } else if (mydate < lastDay) {
      toast.error("Invalid Release Date");
    } else {
      setIsLoading(true);
      var formDatavalue = new FormData();

      if (moviesong === true) {
        formDatavalue.append("filmBoolean", moviesong);
        formDatavalue.append("filmDetail[name]", formData.filmname);
        formDatavalue.append("filmDetail[artists][0][role]", "director");
        formDatavalue.append(
          "filmDetail[artists][0][name]",
          formData.directorname
        );
        formDatavalue.append("filmDetail[artists][1][role]", "actor");
        formDatavalue.append(
          "filmDetail[artists][1][name]",
          formData.actorname
        );
        formDatavalue.append("filmDetail[artists][2][role]", "actress");
        formDatavalue.append(
          "filmDetail[artists][2][name]",
          formData.actressname
        );
      } else {
        formDatavalue.append("filmBoolean", moviesong);
      }
      formDatavalue.append("name", formData.name);
      formDatavalue.append("songLyrics", songLyrics);
      formDatavalue.append("cover", filedata);
      formDatavalue.append("isUnsplash", isUnsplash);
      formDatavalue.append("recordLabel", formData.recordlabel);
      formDatavalue.append("language", formData.songlang);
      formDatavalue.append("genre", formData.songgenre);
      formDatavalue.append("releaseDate", value?.$d || value);
      formDatavalue.append("type", "track");
      formDatavalue.append("platforms[0]", "");
      if (formData.spotifyId || formData.appleId || formData.channelId) {
        await axios
          .put(
            `${process.env.REACT_APP_DMT_API_PATH}/users`,
            {
              spotifyId: formData.spotifyId,
              appleId: formData.appleId,
              channelId: formData.channelId,
            },
            {
              headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {})
          .catch((err) => {
            console.log(err);
          });
      }
      if (career) {
        await axios
          .put(
            `${process.env.REACT_APP_DMT_API_PATH}/orders/${career._id}`,
            formDatavalue,
            {
              headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.error !== true) {
              dispatch(userSlice.actions.saveUser(res.data.data));
              setIsLoading(false);
              setPage((currPage) => currPage + 1);
              window.scrollTo(0, 0);
            } else {
              toast.error(res.data.message);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (firstslidedata?._id) {
        await axios
          .put(
            `${process.env.REACT_APP_DMT_API_PATH}/orders/${firstslidedata._id}`,
            formDatavalue,
            {
              headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.error !== true) {
              dispatch(userSlice.actions.saveUser(res.data.data));
              setIsLoading(false);
              setPage((currPage) => currPage + 1);
              window.scrollTo(0, 0);
            } else {
              toast.error(res.data.message);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        await axios
          .post(`${process.env.REACT_APP_DMT_API_PATH}/orders`, formDatavalue, {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.data.error !== true) {
              dispatch(userSlice.actions.saveUser(res.data.data));
              setIsLoading(false);
              setPage((currPage) => currPage + 1);
              window.scrollTo(0, 0);
            } else {
              toast.error(res.data.message);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const handleSecondSubmit = async () => {
    if (
      formData.songtitle.length == 0 ||
      formData.compositiontype.length == 0 ||
      formData.cleansong.length == 0
    ) {
      setSecondError(true);
    } else if (
      artistdetail?.primaryfirstname?.length == 0 ||
      artistdetail?.lyricistfirstname?.length == 0 ||
      artistdetail?.lyricistlastname?.length == 0 ||
      artistdetail?.composerfirstname?.length == 0 ||
      artistdetail?.composerlastname?.length == 0
    ) {
      setSecondError(true);
    } else if (!songValue) {
      toast.error("Song is required");
    } else if (!artistdetail?.primaryfirstname) {
      toast.error("Primary artist first name is required");
    } else if (!artistdetail?.lyricistfirstname) {
      toast.error("Lyricist first name is required");
    } else if (!artistdetail?.lyricistlastname) {
      toast.error("Lyricist last name is required");
    } else if (!artistdetail?.composerfirstname) {
      toast.error("Composer first name is required");
    } else if (!artistdetail?.composerlastname) {
      toast.error("Composer first name is required");
    } else if (formData?.compositiontype === "cover" && !coverfile) {
      toast.error("Cover Song File is required");
    } else {
      for (let i = 0; i < listData?.length; i++) {
        if (listData[i]?.role === "Composer") {
          if (listData[i]?.name === "") {
            toast.error("Composer first name is required");
            return;
          } else if (listData[i]?.lastname === "") {
            toast.error("Composer last name is required");
            return;
          }
        } else if (listData[i]?.role === "Lyricist") {
          if (listData[i]?.name === "") {
            toast.error("Lyricist first name is required");
            return;
          } else if (listData[i]?.lastname === "") {
            toast.error("Lyricist last name is required");
            return;
          }
        } else if (listData[i]?.role === "Primary Artist") {
          if (listData[i]?.name === "") {
            toast.error("Primary Artist first name is required");
            return;
          }
        }
      }
      const finalartist = [
        {
          role: "Primary Artist",
          name: artistdetail.primaryfirstname,
          lastname: artistdetail.primarylastname,
          spotifyid: artistdetail.primaryspotifyid,
          instagram: artistdetail.primaryinstagram,
          twitter: artistdetail.primarytwitter,
          facebook: artistdetail.primaryfacebook,
        },
        {
          role: "Featuring Artist",
          name: artistdetail.featuringfirstname,
          lastname: artistdetail.featuringlastname,
          spotifyid: artistdetail.featuringspotifyid,
          instagram: artistdetail.featuringinstagram,
          twitter: artistdetail.featuringtwitter,
          facebook: artistdetail.featuringfacebook,
        },
        {
          role: "Lyricist",
          name: artistdetail.lyricistfirstname,
          lastname: artistdetail.lyricistlastname,
          spotifyid: artistdetail.lyricistspotifyid,
          instagram: artistdetail.lyricistinstagram,
          twitter: artistdetail.lyricisttwitter,
          facebook: artistdetail.lyricistfacebook,
        },
        {
          role: "Composer",
          name: artistdetail.composerfirstname,
          lastname: artistdetail.composerlastname,
          spotifyid: artistdetail.composerspotifyid,
          instagram: artistdetail.composerinstagram,
          twitter: artistdetail.composertwitter,
          facebook: artistdetail.composerfacebook,
        },
        ...data,
      ];
      
      const formDatavalue = new FormData();
      for (let i = 0; i < finalartist?.length; i++) {
        if (
          finalartist[i]?.role !== "" ||
          finalartist[i]?.name !== "" ||
          finalartist[i]?.lastname !== ""
        ) {
          for (let j in finalartist[i]) {
            formDatavalue.append(
              `tracks[0][artists][${i}][${j}]`,
              finalartist[i][j]
            );
          }
        }
      }
      setIsLoading(true);
      formDatavalue.append("type", "track");
      formDatavalue.append("songLyrics", firstslidedata.songLyrics);
      formDatavalue.append("cover", firstslidedata.cover);
      formDatavalue.append("recordLabel", formData.recordlabel);
      formDatavalue.append("language", firstslidedata.language);
      formDatavalue.append("genre", firstslidedata.genre);
      formDatavalue.append(
        "tracks[0][previewStartTime]",
        formData.previewstart
      );
      formDatavalue.append("isUnsplash", isUnsplash);
      formDatavalue.append("releaseDate", value?.$d || value);
      formDatavalue.append("tracks[0][name]", formData.songtitle);
      formDatavalue.append("isMastered", tracktomaster);
      formDatavalue.append("isInstagramprofile", Instagramprofile);
      formDatavalue.append("tracks[0][videoLink]", formData.videolink);
      formDatavalue.append("tracks[0][isrcCode]", formData.isrccode);
      formDatavalue.append(
        "tracks[0][compositionType]",
        formData.compositiontype
      );
      if (formData.compositiontype === "cover") {
        formDatavalue.append("tracks[0][coverSongFile]", coverfile);
      }
      formDatavalue.append("tracks[0][file]", songValue);

      formDatavalue.append("tracks[0][cleanExplicit]", formData.cleansong);
      formDatavalue.append("tracks[0][ringtone]", ringtone);

      //this is for testing only
      formDatavalue.append("platforms[0]", "");

      if (
        formData.fbemail ||
        formData.instagramemail ||
        formData.instagrampage ||
        formData.fbpage
      ) {
        await axios
          .put(
            `${process.env.REACT_APP_DMT_API_PATH}/users`,
            {
              fbemail: formData.fbemail,
              instagramemail: formData.instagramemail,
              instagrampage: formData.instagrampage,
              fbpage: formData.fbpage,
            },
            {
              headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {})
          .catch((err) => {
            console.log(err);
          });
      }
      await axios
        .put(
          `${process.env.REACT_APP_DMT_API_PATH}/orders/${firstslidedata._id}`,
          formDatavalue,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error !== true) {
            dispatch(userSlice.actions.saveUser(res.data.data));
            setPage((currPage) => currPage + 1);
            window.scrollTo(0, 0);
            setIsLoading(false);
            // setSongValue(undefined);
          } else {
            toast.error(res.data.message);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handledeclarationSubmit = async () => {
    if (
      checkeddeclaration.artwork &&
      checkeddeclaration.declaration &&
      checkeddeclaration.copyrighted &&
      checkeddeclaration.lyrics &&
      checkeddeclaration.composition &&
      checkeddeclaration.pause
    ) {
      setIsLoading(true);
      const payload = {
        artwork: checkeddeclaration?.artwork,
        declaration: checkeddeclaration?.declaration,
        copyrighted: checkeddeclaration?.copyrighted,
        lyrics: checkeddeclaration?.lyrics,
        composition: checkeddeclaration?.composition,
        pause: checkeddeclaration?.pause,
      };
      await axios
        .put(
          `${process.env.REACT_APP_DMT_API_PATH}/orders/${firstslidedata._id}/declaration`,
          payload,
          config
        )
        .then((res) => {
          if (res.data.error !== true) {
            setIsLoading(false);
            setPage((currPage) => currPage + 1);
            window.scrollTo(0, 0);
          } else {
            toast.error(res.data.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      toast.error("Please check all checkboxes");
    }
  };

  const handleThirdSubmit = async () => {
    if (
      formData.productionright.length === 0 ||
      formData.copyrightyear.length === 0
    ) {
      setThirdError(true);
    } else {
      const filtered = platform.filter((employee) => {
        return employee.isChecked === true;
      });
      const formDatavalue = new FormData();
      const listItems = filtered.map((details, index) =>
        formDatavalue.append(`platforms[${index}]`, details.name)
      );
      setIsLoading(true);
      if (moviesong === true) {
        formDatavalue.append("filmBoolean", moviesong);
        // formDatavalue.append("isVariousArtist", true);
        formDatavalue.append("filmDetail[name]", formData.filmname);
        formDatavalue.append("filmDetail[artists][0][role]", "director");
        formDatavalue.append(
          "filmDetail[artists][0][name]",
          formData.directorname
        );
        formDatavalue.append("filmDetail[artists][1][role]", "actor");
        formDatavalue.append(
          "filmDetail[artists][1][name]",
          formData.actorname
        );
        formDatavalue.append("filmDetail[artists][2][role]", "actress");
        formDatavalue.append(
          "filmDetail[artists][2][name]",
          formData.actressname
        );
      } else {
        formDatavalue.append("filmBoolean", moviesong);
      }

      const finalartist = [
        {
          role: "Primary Artist",
          name: artistdetail.primaryfirstname,
          lastname: artistdetail.primarylastname,
        },
        {
          role: "Featuring Artist",
          name: artistdetail.featuringfirstname,
          lastname: artistdetail.featuringlastname,
        },
        {
          role: "Lyricist",
          name: artistdetail.lyricistfirstname,
          lastname: artistdetail.lyricistlastname,
        },
        {
          role: "Composer",
          name: artistdetail.composerfirstname,
          lastname: artistdetail.composerlastname,
        },
        ...data,
      ];

      for (let i = 0; i < finalartist?.length; i++) {
        if (
          finalartist[i]?.role !== "" ||
          finalartist[i]?.name !== "" ||
          finalartist[i]?.lastname !== ""
        ) {
          for (let j in finalartist[i]) {
            formDatavalue.append(
              `tracks[0][artists][${i}][${j}]`,
              finalartist[i][j]
            );
          }
        }
      }
      formDatavalue.append("name", formData?.name);
      formDatavalue.append("type", "track");
      formDatavalue.append("cover", firstslidedata?.cover);
      formDatavalue.append("songLyrics", firstslidedata.songLyrics);
      formDatavalue.append("recordLabel", formData?.recordlabel);
      formDatavalue.append("language", firstslidedata?.language);
      formDatavalue.append("genre", firstslidedata?.genre);
      formDatavalue.append(
        "tracks[0][previewStartTime]",
        formData.previewstart
      );
      formDatavalue.append("releaseDate", value?.$d || value);
      formDatavalue.append("tracks[0][name]", formData?.songtitle);
      formDatavalue.append("isMastered", tracktomaster);
      formDatavalue.append("isInstagramprofile", Instagramprofile);
      formDatavalue.append("tracks[0][videoLink]", formData?.videolink);
      formDatavalue.append("tracks[0][isrcCode]", formData?.isrccode);
      formDatavalue.append(
        "tracks[0][compositionType]",
        formData.compositiontype
      );
      if (formData.compositiontype === "cover") {
        formDatavalue.append("tracks[0][coverSongFile]", coverfile);
      }
      formDatavalue.append("tracks[0][cleanExplicit]", formData?.cleansong);
      formDatavalue.append("tracks[0][ringtone]", ringtone);
      formDatavalue.append("tracks[0][file]", songValue);
      formDatavalue.append("copyright[name]", formData?.copyrightyear);
      formDatavalue.append("productionRight[name]", formData?.productionright);

      await axios
        .put(
          `${process.env.REACT_APP_DMT_API_PATH}/orders/${firstslidedata._id}`,
          formDatavalue,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error !== true) {
            dispatch(userSlice.actions.saveUser(res.data.data));

            axios
              .post(
                `${process.env.REACT_APP_DMT_API_PATH}/orders/${firstslidedata._id}/payment`,
                formDatavalue,
                {
                  headers: {
                    "content-type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                if (res.data.error !== true) {
                  setPaymentidget(res.data);
                  setPaymentget(res.data);
                  localStorage.setItem("order", res?.data?.data?.identity?.id);
                  setIsLoading(false);
                  setPage((currPage) => currPage + 1);
                  window.scrollTo(0, 0);
                }
              })
              .catch((err) => {
                toast.error(err.message);
                setIsLoading(false);
              });
          } else {
            toast.error("Please choose atleast one platform");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          toast.error(err.message);
          setIsLoading(false);
        });
    }
  };

  const checkoutHandler = async () => {
    if (totalamount !== 0 && walletamount <= 0) {
      const payload = {
        amount: totalamount,
        distribution_type: billshow,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_DMT_API_PATH}/orders/${firstslidedata._id}/payment/${paymentidget?.data?.order_id}`,
        payload,
        config
      );
      const orderData = res.data.data;
      const options = {
        key: "rzp_live_UOZD55VDM3YeQI",
        amount: totalamount,
        currency: "INR",
        name: "DELIVER MY TUNE",
        description: "RazorPay",
        order_id: orderData.order_id,
        handler: async function (response) {
          const data = {
            //  orderCreationId: datavalue,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
            donation_amount: 0,
          };
          const result = await axios.post(
            `${process.env.REACT_APP_DMT_API_PATH}/public/payments/verify`,
            data
          );
          if (result.data.error !== true) {
            window.scrollTo(0, 0);
            setSongValue(undefined);
            dispatch(userSlice.actions.saveUser(""));
            navigate("/paymentsuccess", {
              state: {
                id: firstslidedata,
              },
            });
          }
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#121212",
        },
      };
      const razor = new window.Razorpay(options);
      razor.open();
    } else if (walletamount > 0) {
      if (totalamount <= 0) {
        const payload2 = {
          additional_amount: totalamount,
          amount: donate ? walletamount - donate : walletamount,
          distribution_type: billshow,
          donation_amount: donate,
          wallet_amount: walletamount,
          order_id: paymentidget?.data?.identity?.id,
          payment_id: paymentidget?.data?._id,
          user_id: loginToken?.user._id,
        };

        await axios
          .post(
            `${process.env.REACT_APP_DMT_API_PATH}/wallet/pay`,
            payload2,
            config
          )
          .then((res) => {
            if (res.data.error !== true) {
              navigate("/paymentsuccess", {
                state: {
                  id: firstslidedata,
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (totalamount > 0) {
        const payload = {
          amount: totalamount,
          distribution_type: billshow,
        };
        const res = await axios.post(
          `${process.env.REACT_APP_DMT_API_PATH}/orders/${firstslidedata._id}/payment/${paymentidget?.data?.order_id}`,
          payload,
          config
        );
        const orderData = res.data.data;
        const options = {
          key: "rzp_live_UOZD55VDM3YeQI",
          amount: totalamount,
          currency: "INR",
          name: "DELIVER MY TUNE",
          description: "RazorPay",
          order_id: orderData.order_id,
          handler: async function (response) {
            const data = {
              //  orderCreationId: datavalue,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              donation_amount: 0,
            };
            const result = await axios.post(
              `${process.env.REACT_APP_DMT_API_PATH}/public/payments/verify`,
              data
            );
            if (result.data.error !== true) {
              window.scrollTo(0, 0);
              setSongValue(undefined);
              dispatch(userSlice.actions.saveUser(""));
              navigate("/paymentsuccess", {
                state: {
                  id: firstslidedata,
                },
              });
              const payload2 = {
                additional_amount: 0,
                amount: donate ? walletamount - donate : walletamount,
                distribution_type: billshow,
                donation_amount: donate,
                wallet_amount: walletamount,
                order_id: paymentidget?.data?.identity?.id,
                payment_id: paymentidget?.data?._id,
                user_id: loginToken?.user._id,
                totalamount:totalamount
              };

              await axios
                .post(
                  `${process.env.REACT_APP_DMT_API_PATH}/wallet/pay`,
                  payload2,
                  config
                )
                .then((res) => {
                  if (res.data.error !== true) {
                    dispatch(userSlice.actions.saveUser(""));
                    setSongValue(undefined);
                    navigate("/paymentsuccess", {
                      state: {
                        id: firstslidedata,
                      },
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#121212",
          },
        };
        const razor = new window.Razorpay(options);
        razor.open();
      }
    } else {
      const payload2 = {
        additional_amount: totalamount,
        amount: totalamount,
        distribution_type: billshow,
        donation_amount: 0,
        order_id: paymentidget?.data?.identity?.id,
        payment_id: paymentidget?.data?._id,
        user_id: loginToken?.user._id,
      };
      await axios
        .post(
          `${process.env.REACT_APP_DMT_API_PATH}/wallet/pay`,
          payload2,
          config
        )
        .then((res) => {
          if (res.data.error !== true) {
            window.scrollTo(0, 0);
            dispatch(userSlice.actions.saveUser(""));
            setSongValue(undefined);
            navigate("/paymentsuccess", {
              state: {
                id: firstslidedata,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const PageDisplay = () => {
    if (page === 0) {
      return isLoading ? (
        <ScaleLoader
          size={350}
          textAlign="center"
          className="music-loader"
          color="#FBCE2E"
        />
      ) : (
        <BasicDetailsdata
          saveImage={saveImage}
          setSaveimage={setSaveimage}
          formData={formData}
          setFormData={setFormData}
          setFiledata={setFiledata}
          filedata={filedata}
          setSongLyrics={setSongLyrics}
          songLyrics={songLyrics}
          value={value}
          setValue={setValue}
          timevalue={timevalue}
          setTimevalue={setTimevalue}
          setMoviesong={setMoviesong}
          career={career}
          error={error}
          firstslidedata={firstslidedata}
          isUnsplash={isUnsplash}
          setisUnsplash={setisUnsplash}
        />
      );
    } else if (page === 1) {
      return isLoading ? (
        <ScaleLoader
          size={350}
          className="music-loader"
          textAlign="center"
          color="#FBCE2E"
        />
      ) : (
        <AdvancedDetails
          formData={formData}
          setFormData={setFormData}
          data={data}
          setData={setData}
          setRingtone={setRingtone}
          ringtone={ringtone}
          setTracktomaster={setTracktomaster}
          tracktomaster={tracktomaster}
          setIsInstagramprofile={setIsInstagramprofile}
          isInstagramprofile={Instagramprofile}
          setSongValue={setSongValue}
          songValue={songValue}
          career={career}
          seconderror={seconderror}
          firstslidedata={firstslidedata}
          setSecondback={secondBack}
          secondBack={secondBack}
          coverfile={coverfile}
          setCoverfile={setCoverfile}
          artistdetail={artistdetail}
          setArtistdetail={setArtistdetail}
          listData={listData}
          setListData={setListData}
          setSongprocessing={setSongprocessing}
          songprocessing={songprocessing}
        />
      );
    } else if (page === 2) {
      return isLoading ? (
        <ScaleLoader
          size={350}
          className="music-loader"
          textAlign="center"
          color="#FBCE2E"
        />
      ) : (
        <Declaration
          checkeddeclaration={checkeddeclaration}
          setCheckeddeclaration={setCheckeddeclaration}
          handledeclarationSubmit={handledeclarationSubmit}
          career={career}
          firstslidedata={firstslidedata}
          setBackflow={setBackflow}
          backflow={backflow}
        />
      );
    } else if (page === 3) {
      return isLoading ? (
        <ScaleLoader
          size={350}
          className="music-loader"
          textAlign="center"
          color="#FBCE2E"
        />
      ) : (
        <PublishDetails
          formData={formData}
          setFormData={setFormData}
          setPlatform={setPlatform}
          platform={platform}
          thirdError={thirdError}
          career={career}
          firstslidedata={firstslidedata}
          setBackflow={setBackflow}
          backflow={backflow}
        />
      );
    } else {
      return (
        <Billing
          setWalletamount={setWalletamount}
          walletamount={walletamount}
          billshow={billshow}
          setBillshow={setBillshow}
          formData={formData}
          setFormData={setFormData}
          setPaymentDetail={setPaymentDetail}
          paymentdetail={paymentdetail}
          setAmount={setAmount}
          amount={amount}
          paymentget={paymentget}
          setTotalamount={setTotalamount}
          totalamount={totalamount}
          setShowuserdata={setShowuserdata}
          donate={donate}
          setDonateValue={setDonateValue}
        />
      );
    }
  };

  const FormTitles = [
    "Basic Details222",
    "Advance Details",
    "Declaration",
    "Publising Details",
    "Summary",
  ];
  return (
    <div className="container">
      <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
      <div className="main-container">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="pages">
          <Box className="custom-dmt-container">
            <Stepper page={page} />

            <div className="body">{PageDisplay()}</div>
            <div
              style={{
                width: page === 0 ? "33.3%" : page == 1 ? "66.6%" : "100%",
              }}
            ></div>
            {isLoading === true ? (
              ""
            ) : (
              <div className="stepperBtn">
                {page == 0 ? (
                  ""
                ) : (
                  <Button
                    disabled={page == 0}
                    onClick={() => {
                      setPage((currPage) => currPage - 1);
                      setIsLoading(false);

                      if (page === 4) {
                        setBillshow("");
                        setBackflow(true);
                      }else  if (page === 3) {
                        setBillshow("");
                        setBackflow(true);
                      } else if (page === 2) {
                        setSecondback(true);
                        // setBackflow(false);
                      } else if (page === 1) {
                        setSecondback(false);
                      }
                    }}
                    sx={{
                      color: "#f9a40d !important",
                      background: "black !important",
                      width: "112px !important",
                      margin: "0px !important",
                      textTransform: "capitalize  !important",
                    }}
                    className="backbtn"
                  >
                    <ArrowBackIcon sx={{ paddingRight: "3px" }} /> Back
                  </Button>
                )}

                <Button
                  disabled={songprocessing == true}
                  className="nextbtn"
                  sx={{ textTransform: "capitalize  !important" }}
                  id={
                    page === FormTitles.length - 1
                      ? "conversion-dmt-distribution"
                      : ""
                  }
                  onClick={(event) => {
                    if (page === 0) {
                      handleChange();
                    } else if (page === 1) {
                      handleSecondSubmit();
                    } else if (page === 2) {
                      handledeclarationSubmit();
                    } else if (page === 3) {
                      handleThirdSubmit();
                    } else if (page === 4) {
                      if (billshow) {
                        checkoutHandler();
                        handleAddtag();
                      } else {
                        toast.error("Please Select Plan");
                      }
                    }
                  }}
                >
                  {page === FormTitles.length - 1
                    ? "Proceed To Payment"
                    : `Save & Next`}{" "}
                  &nbsp; <IoIosArrowForward fontSize="16px" />
                </Button>
              </div>
            )}
          </Box>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DistributeSingleTrack;

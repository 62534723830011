import React, { useEffect, useState } from "react";
import CustomButton from "../common/customButton/index";
import AddIcon from "@mui/icons-material/Add";
import { useDropzone } from "react-dropzone";
import MusicIcon from "../../assets/img/musicIcon.svg";
import Play from "../../assets/img/play.png";
import "../advancedDetails/index.css";
import Tracks from "../../components/tracks/index";
import ClearIcon from "@mui/icons-material/Clear";
import ChangeSong from "../changeSong/changeSongMulti";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  OutlinedInput,
  RadioGroup,
  TextField,
  Typography,
  Radio,
  MenuItem,
  Card,
  Checkbox,
} from "@mui/material";
import "./index.css";
import { useFormik } from "formik";
import Coverfile from "./multiCover";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const validationSchema = yup.object().shape({
  recordLabel: yup.string().required("required"),
});

const previewStart = [
  {
    value: "Primary Artist",
    label: "Primary Artist",
  },
  {
    value: "Featuring Artist",
    label: "Featuring Artist",
  },
  {
    value: "Lyricist",
    label: "Lyricist",
  },
  {
    value: "Composer",
    label: "Composer",
  },
  {
    value: "Arranger",
    label: "Arranger",
  },
  {
    value: "Orchestra",
    label: "Orchestra",
  },
  {
    value: "Choir",
    label: "Choir",
  },
  {
    value: "Actor",
    label: "Actor",
  },
  {
    value: "Actress",
    label: "Actress",
  },
];

const Index = ({
  setFormData,
  formData,
  data,
  setData,
  setRingtone,
  setTracktomaster,
  songValue,
  setSongValue,
  career,
  seconderror,
  firstslidedata,
  ringtone,
  setFinalvalue,
  finalvalue,
  setmultiformadv,
  multiformadv,
  listData,
  setListData,
  setSecondError,
  songalbum,
  setCoverfile,
  coverSongFile,
  artistdetail,
  setArtistdetail,
  tracktomaster,
  songprocessing,
  setSongprocessing,
  setIsInstagramprofile,
  isInstagramprofile,
}) => {
  const handleChangeartist = (e) => {
    setArtistdetail({
      ...artistdetail,
      [e.target.name]: e.target.value,
    });
  };
  const [showsong, setShowsong] = useState(false);
  const [editbtn, setEditbtn] = useState(false);

  const [checked, setChecked] = useState(false);
  const handleChangedata = (event) => {
    setIsInstagramprofile(event.target.checked);
    setChecked(event.target.checked);
  };

  const handleChangeValue = (e) => {
    setmultiformadv({
      ...multiformadv,
      [e.target.name]: e.target.value,
    });
  };
  // const addsong = () => {
  //   if (
  //     multiformadv?.name?.length == 0 ||
  //     multiformadv?.compositionType?.length == 0 ||
  //     multiformadv?.cleanExplicit?.length == 0
  //   ) {
  //     setSecondError(true);
  //   } else if (
  //     artistdetail?.primaryfirstname?.length == 0 ||
  //     artistdetail?.lyricistfirstname?.length == 0 ||
  //     artistdetail?.lyricistlastname?.length == 0 ||
  //     artistdetail?.composerfirstname?.length == 0 ||
  //     artistdetail?.composerlastname?.length == 0
  //   ) {
  //     setSecondError(true);
  //   } else if (
  //     multiformadv?.compositionType === "cover" &&
  //     !multiformadv?.coverSongFile
  //   ) {
  //     toast.error("Cover Song File is required");
  //   } else if (!multiformadv.file) {
  //     toast.error("song is required");
  //   } else if (!artistdetail?.primaryfirstname) {
  //     toast.error("Primary artist first name is required");
  //   } else if (!artistdetail?.lyricistfirstname) {
  //     toast.error("Lyricist first name is required");
  //   } else if (!artistdetail?.lyricistlastname) {
  //     toast.error("Lyricist last name is required");
  //   } else if (!artistdetail?.composerfirstname) {
  //     toast.error("Composer first name is required");
  //   } else if (!artistdetail?.composerlastname) {
  //     toast.error("Composer first name is required");
  //   } else {
  //     for (let i = 0; i < listData?.length; i++) {
  //       if (listData[i]?.role === "Composer") {
  //         if (listData[i]?.name === "") {
  //           toast.error("Composer first name is required");
  //           return;
  //         } else if (listData[i]?.lastname === "") {
  //           toast.error("Composer last name is required");
  //           return;
  //         }
  //       } else if (listData[i]?.role === "Lyricist") {
  //         if (listData[i]?.name === "") {
  //           toast.error("Lyricist first name is required");
  //           return;
  //         } else if (listData[i]?.lastname === "") {
  //           toast.error("Lyricist last name is required");
  //           return;
  //         }
  //       } else if (listData[i]?.role === "Primary Artist") {
  //         if (listData[i]?.name === "") {
  //           toast.error("Primary Artist first name is required");
  //           return;
  //         }
  //       }
  //     }
  //     setFinalvalue((current) => [
  //       ...current,
  //       {
  //         name: multiformadv.name,
  //         videoLink: multiformadv.videoLink,
  //         isrcCode: multiformadv.isrcCode,
  //         compositionType: multiformadv.compositionType,
  //         coverSongFile: multiformadv.coverSongFile,
  //         cleanExplicit: multiformadv.cleanExplicit,
  //         file: multiformadv.file,
  //         ringtone: multiformadv?.ringtone,
  //         artists: [
  //           {
  //             role: "Primary Artist",
  //             name: artistdetail?.primaryfirstname,
  //             lastname: artistdetail.primarylastname,
  //           },
  //           {
  //             role: "Featuring Artist",
  //             name: artistdetail?.featuringfirstname,
  //             lastname: artistdetail?.featuringlastname,
  //           },
  //           {
  //             role: "Lyricist",
  //             name: artistdetail?.lyricistfirstname,
  //             lastname: artistdetail?.lyricistlastname,
  //           },
  //           {
  //             role: "Composer",
  //             name: artistdetail?.composerfirstname,
  //             lastname: artistdetail?.composerlastname,
  //           },
  //           ...listData,
  //         ],
  //         _id: uuidv4(),
  //       },
  //     ]);
  //     setmultiformadv({
  //       _id: "",
  //       name: "",
  //       videoLink: "",
  //       isrcCode: "",
  //       compositionType: "",
  //       coverSongFile: "",
  //       cleanExplicit: "",
  //       file: "",
  //       ringtone: "",
  //     });
  //     setArtistdetail({
  //       primaryfirstname: "",
  //       primarylastname: "",
  //       featuringfirstname: "",
  //       featuringlastname: "",
  //       lyricistfirstname: "",
  //       lyricistlastname: "",
  //       composerfirstname: "",
  //       composerlastname: "",
  //     });
  //     setListData([{ role: "", name: "", lastname: "" }]);
  //   }
  // };
  const addsong = () => {
    console.log(artistdetail,'artistdetailartistdetail')
    if (
      multiformadv?.name?.length === 0 ||
      multiformadv?.compositionType?.length === 0 ||
      multiformadv?.cleanExplicit?.length === 0
    ) {
      setSecondError(true);
    } else if (
      artistdetail?.primaryfirstname?.length === 0 ||
      artistdetail?.lyricistfirstname?.length === 0 ||
      artistdetail?.lyricistlastname?.length === 0 ||
      artistdetail?.composerfirstname?.length === 0 ||
      artistdetail?.composerlastname?.length === 0
    ) {
      setSecondError(true);
    } else if (
      multiformadv?.compositionType === "cover" &&
      !multiformadv?.coverSongFile
    ) {
      toast.error("Cover Song File is required");
    } else if (!multiformadv.file) {
      toast.error("Song is required");
    } else if (!artistdetail?.primaryfirstname) {
      toast.error("Primary artist first name is required");
    } else if (!artistdetail?.lyricistfirstname) {
      toast.error("Lyricist first name is required");
    } else if (!artistdetail?.lyricistlastname) {
      toast.error("Lyricist last name is required");
    } else if (!artistdetail?.composerfirstname) {
      toast.error("Composer first name is required");
    } else if (!artistdetail?.composerlastname) {
      toast.error("Composer last name is required");
    } else {
      for (let i = 0; i < listData?.length; i++) {
        if (listData[i]?.role === "Composer") {
          if (listData[i]?.name === "") {
            toast.error("Composer first name is required");
            return;
          } else if (listData[i]?.lastname === "") {
            toast.error("Composer last name is required");
            return;
          }
        } else if (listData[i]?.role === "Lyricist") {
          if (listData[i]?.name === "") {
            toast.error("Lyricist first name is required");
            return;
          } else if (listData[i]?.lastname === "") {
            toast.error("Lyricist last name is required");
            return;
          }
        } else if (listData[i]?.role === "Primary Artist") {
          if (listData[i]?.name === "") {
            toast.error("Primary Artist first name is required");
            return;
          }
        }
      }
      setFinalvalue((current) => [
        ...current,
        {
          name: multiformadv.name,
          videoLink: multiformadv.videoLink,
          isrcCode: multiformadv.isrcCode,
          compositionType: multiformadv.compositionType,
          coverSongFile: multiformadv.coverSongFile,
          cleanExplicit: multiformadv.cleanExplicit,
          file: multiformadv.file,
          ringtone: multiformadv?.ringtone,
          artists: [
            {
              role: "Primary Artist",
              name: artistdetail?.primaryfirstname,
              lastname: artistdetail.primarylastname,
              spotifyid: artistdetail?.primaryspotifyid,
              instagram: artistdetail?.primaryinstagram,
              twitter: artistdetail?.primarytwitter,
              facebook: artistdetail?.primaryfacebook,
            },
            {
              role: "Featuring Artist",
              name: artistdetail?.featuringfirstname,
              lastname: artistdetail?.featuringlastname,
              spotifyid: artistdetail?.featuringspotifyid,
              instagram: artistdetail?.featuringinstagram,
              twitter: artistdetail?.featuringtwitter,
              facebook: artistdetail?.featuringfacebook,
            },
            {
              role: "Lyricist",
              name: artistdetail?.lyricistfirstname,
              lastname: artistdetail?.lyricistlastname,
              spotifyid: artistdetail?.lyricistspotifyid,
              instagram: artistdetail?.lyricistinstagram,
              twitter: artistdetail?.lyricisttwitter,
              facebook: artistdetail?.lyricistfacebook,
            },
            {
              role: "Composer",
              name: artistdetail?.composerfirstname,
              lastname: artistdetail?.composerlastname,
              spotifyid: artistdetail?.composerspotifyid,
              instagram: artistdetail?.composerinstagram,
              twitter: artistdetail?.composertwitter,
              facebook: artistdetail?.composerfacebook,
            },
            ...listData,
          ],
          _id: uuidv4(),
        },
      ]);
      setmultiformadv({
        _id: "",
        name: "",
        videoLink: "",
        isrcCode: "",
        compositionType: "",
        coverSongFile: "",
        cleanExplicit: "",
        file: "",
        ringtone: "",
      });
      setArtistdetail({
        primaryfirstname: "",
        primarylastname: "",
        primaryspotifyid: "",
        primaryinstagram: "",
        primarytwitter: "",
        primaryfacebook: "",
        featuringfirstname: "",
        featuringlastname: "",
        featuringspotifyid: "",
        featuringinstagram: "",
        featuringtwitter: "",
        featuringfacebook: "",
        lyricistfirstname: "",
        lyricistlastname: "",
        lyricistspotifyid: "",
        lyricistinstagram: "",
        lyricisttwitter: "",
        lyricistfacebook: "",
        composerfirstname: "",
        composerlastname: "",
        composerspotifyid: "",
        composerinstagram: "",
        composertwitter: "",
        composerfacebook: "",
      });
      setListData([{ role: "", name: "", lastname: "" ,spotifyid: "", facebook: "", instagram: "", twitter: ""}]);
    }
  };
  
  const handleEditForm = (value) => {
    setShowsong(true);
    setmultiformadv({
      ...multiformadv,
      compositionType: value.compositionType,
      name: value.name,
      videoLink: value.videoLink,
      isrcCode: value.isrcCode,
      cleanExplicit: value.cleanExplicit,
      file: value.file,
      ringtone: value.ringtone,
      _id: value._id,
    });
    if (value.compositionType === "cover") {
      setTimeout(() => {
        setmultiformadv({
          compositionType: value.compositionType,
          name: value.name,
          videoLink: value.videoLink,
          isrcCode: value.isrcCode,
          cleanExplicit: value.cleanExplicit,
          file: value.file,
          ringtone: value.ringtone,
          _id: value._id,
          coverSongFile: value.coverSongFile,
        });
      }, 500);
    }
    const artisttop = [];
    const artistbottom = [];
  
    for (let artist of value.artists) {
      if (
        artist.role === "Primary Artist" ||
        artist.role === "Featuring Artist" ||
        artist.role === "Lyricist" ||
        artist.role === "Composer"
      ) {
        const findArtist = artisttop.find((ev) => ev.role === artist.role);
        if (!findArtist) {
          artisttop.push({
            role: artist?.role,
            name: artist?.name,
            lastname: artist?.lastname,
            spotifyid: artist?.spotifyid,
            facebook: artist?.facebook,
            twitter: artist?.twitter,
            instagram: artist?.instagram,
          });
        } else {
          artistbottom.push({
            role: artist?.role,
            name: artist?.name,
            lastname: artist?.lastname,
            spotifyid: artist?.spotifyid,
            facebook: artist?.facebook,
            twitter: artist?.twitter,
            instagram: artist?.instagram,
          });
        }
      } else {
        artistbottom.push({
          role: artist?.role,
          name: artist?.name,
          lastname: artist?.lastname,
          spotifyid: artist?.spotifyid,
          facebook: artist?.facebook,
          twitter: artist?.twitter,
          instagram: artist?.instagram,
        });
      }
    }
  
    setListData(
      artistbottom?.length > 0
        ? artistbottom
        : [{ role: "", name: "", lastname: "",spotifyid: "", facebook: "", instagram: "", twitter: "" }]
    );
    setEditbtn(true);
    const filterprimary = artisttop.filter(
      (ev) => ev.role === "Primary Artist"
    );
    const filterfeaturing = artisttop.filter(
      (ev) => ev.role === "Featuring Artist"
    );
    const filterlyricist = artisttop.filter((ev) => ev.role === "Lyricist");
    const filtercomposer = artisttop.filter((ev) => ev.role === "Composer");
    setArtistdetail({
      primaryfirstname: filterprimary[0]?.name,
      primarylastname: filterprimary[0]?.lastname,
      primaryspotifyid: filterprimary[0]?.spotifyid,
      primaryfacebook: filterprimary[0]?.facebook,
      primarytwitter: filterprimary[0]?.twitter,
      primaryinstagram: filterprimary[0]?.instagram,
      featuringfirstname: filterfeaturing[0]?.name,
      featuringlastname: filterfeaturing[0]?.lastname,
      featuringspotifyid: filterfeaturing[0]?.spotifyid,
      featuringfacebook: filterfeaturing[0]?.facebook,
      featuringtwitter: filterfeaturing[0]?.twitter,
      featuringinstagram: filterfeaturing[0]?.instagram,
      lyricistfirstname: filterlyricist[0]?.name,
      lyricistlastname: filterlyricist[0]?.lastname,
      lyricistspotifyid: filterlyricist[0]?.spotifyid,
      lyricistfacebook: filterlyricist[0]?.facebook,
      lyricisttwitter: filterlyricist[0]?.twitter,
      lyricistinstagram: filterlyricist[0]?.instagram,
      composerfirstname: filtercomposer[0]?.name,
      composerlastname: filtercomposer[0]?.lastname,
      composerspotifyid: filtercomposer[0]?.spotifyid,
      composerfacebook: filtercomposer[0]?.facebook,
      composertwitter: filtercomposer[0]?.twitter,
      composerinstagram: filtercomposer[0]?.instagram,
    });
  };
  
  
console.log(finalvalue,"finalvalueeee")
const handleUpdate = () => {
  setShowsong(false);

  const handlefinalvalue = finalvalue.map((final, index) => {
    if (final._id === multiformadv._id) {
      const finalartist = [];
      for (let artist of listData) {
        if (
          artist.role === "Primary Artist" ||
          artist.role === "Featuring Artist" ||
          artist.role === "Lyricist" ||
          artist.role === "Composer"
        ) {
          console.log("NO");
        } else if (
          multiformadv?.name?.length == 0 ||
          multiformadv?.compositionType?.length == 0 ||
          multiformadv?.cleanExplicit?.length == 0
        ) {
          setSecondError(true);
        } else if (
          artistdetail?.primaryfirstname?.length == 0 ||
          artistdetail?.primarylastname?.length == 0 ||
          artistdetail?.featuringfirstname?.length == 0 ||
          artistdetail?.featuringlastname?.length == 0 ||
          artistdetail?.lyricistfirstname?.length == 0 ||
          artistdetail?.lyricistlastname?.length == 0 ||
          artistdetail?.composerfirstname?.length == 0 ||
          artistdetail?.composerlastname?.length == 0
        ) {
          setSecondError(true);
        } else {
          finalartist.push({
            role: artist?.role,
            name: artist?.name,
            lastname: artist?.lastname,
            spotifyid: artist?.spotifyid,
            facebook: artist?.facebook,
            instagram: artist?.instagram,
            twitter: artist?.twitter,
          });
        }
      }

      return {
        name: multiformadv.name,
        videoLink: multiformadv.videoLink,
        isrcCode: multiformadv.isrcCode,
        compositionType: multiformadv.compositionType,
        cleanExplicit: multiformadv.cleanExplicit,
        coverSongFile: multiformadv.coverSongFile,
        file: multiformadv.file,
        ringtone: multiformadv.ringtone,
        _id: multiformadv._id,
        artists: [
          {
            role: "Primary Artist",
            name: artistdetail?.primaryfirstname,
            lastname: artistdetail?.primarylastname,
            spotifyid: artistdetail?.primaryspotifyid,
            facebook: artistdetail?.primaryfacebook,
            instagram: artistdetail?.primaryinstagram,
            twitter: artistdetail?.primarytwitter,
          },
          {
            role: "Featuring Artist",
            name: artistdetail?.featuringfirstname,
            lastname: artistdetail?.featuringlastname,
            spotifyid: artistdetail?.featuringspotifyid,
            facebook: artistdetail?.featuringfacebook,
            instagram: artistdetail?.featuringinstagram,
            twitter: artistdetail?.featuringtwitter,
          },
          {
            role: "Lyricist",
            name: artistdetail?.lyricistfirstname,
            lastname: artistdetail?.lyricistlastname,
            spotifyid: artistdetail?.lyricistspotifyid,
            facebook: artistdetail?.lyricistfacebook,
            instagram: artistdetail?.lyricistinstagram,
            twitter: artistdetail?.lyricisttwitter,
          },
          {
            role: "Composer",
            name: artistdetail?.composerfirstname,
            lastname: artistdetail?.composerlastname,
            spotifyid: artistdetail?.composerspotifyid,
            facebook: artistdetail?.composerfacebook,
            instagram: artistdetail?.composerinstagram,
            twitter: artistdetail?.composertwitter,
          },
          ...finalartist,
        ],
      };
    }

    return final;
  });

  setFinalvalue(handlefinalvalue);
  setEditbtn(false);
  setmultiformadv({
    _id: "",
    name: "",
    videoLink: "",
    isrcCode: "",
    compositionType: "",
    coverSongFile: "",
    cleanExplicit: "",
    file: "",
    ringtone: "",
  });
  setArtistdetail({
    primaryfirstname: "",
    primarylastname: "",
    primaryspotifyid: "",
    primaryfacebook: "",
    primaryinstagram: "",
    primarytwitter: "",
    featuringfirstname: "",
    featuringlastname: "",
    featuringspotifyid: "",
    featuringfacebook: "",
    featuringinstagram: "",
    featuringtwitter: "",
    lyricistfirstname: "",
    lyricistlastname: "",
    lyricistspotifyid: "",
    lyricistfacebook: "",
    lyricistinstagram: "",
    lyricisttwitter: "",
    composerfirstname: "",
    composerlastname: "",
    composerspotifyid: "",
    composerfacebook: "",
    composerinstagram: "",
    composertwitter: "",
  });
  setListData([{ role: "", name: "", lastname: "",spotifyid: "", facebook: "", instagram: "", twitter: ""}]);
  setSongValue("");
  setRingtone("");
  setCoverfile("");
};

  
  const navigate = useNavigate();

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...listData];
    list[index][name] = value;
    setListData(list);
    setData(list);
  };

  const handleServiceRemove = (index) => {
    const list = [...listData];
    list.splice(index, 1);
    setListData(list);
    setData(list);
  };

  const handleAdd = () => {
    setListData([...listData, { role: "", name: "", lastname: "", spotifyid: "", facebook: "", instagram: "", twitter: "" }]);
    setSongValue("");
    setRingtone("");
    setCoverfile("");
  };
  

  const handleSubmit = () => { };

  const [preview, setPreview] = React.useState("");
  const handlePreviewChange = (event) => {
    setPreview(event.target.value);
  };
  const [name, setName] = React.useState("");
  const handleName = (event) => {
    setName(event.target.value);
  };

  const { getRootProps, getInputProps, open, open1, acceptedFiles } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      accept: {
        "audio/.wav": [".wav"],
      },
    });
  useEffect(() => {
    if (acceptedFiles) {
      setmultiformadv({
        ...multiformadv,
        ringtone: acceptedFiles[0],
      });
    }
  }, [acceptedFiles]);

  const files1 = songValue?.path;

  return (
    <>
      <Tracks
        formData={formData}
        setFormData={setFormData}
        setTracktomaster={setTracktomaster}
        career={career}
        firstslidedata={firstslidedata}
        tracktomaster={tracktomaster}
      />

<Card className="tracks" sx={{ background: "rgba(255, 255, 255, 0.05)" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ ml: 3 , mt:2}} className="share">
            <Checkbox
              onChange={handleChangedata}
              checked={isInstagramprofile}
              inputProps={{ "aria-label": "controlled" }}
              sx={{
                width: "20px",
                marginRight: "12px",
                color: "#ffffff",
                "&.Mui-checked": {
                  color: "#fa9e0d",
                },
              }}
            />
            <Typography
              variant="h6"
              className="all"
              sx={{
                fontSize: { xs: "18px !important", sm: "24px !important" },
                lineHeight: { xs: "23px !important", sm: "31px !important" },
              }}
            >
              Want to link this song to your Instagram profile?
            </Typography>
          </Grid>
          {isInstagramprofile ? (
            <>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "21px",
                    mb: 1,
                  }}
                >
                  Registered Instagram Email ID
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  value={formData.instagramemail}
                  onChange={(event) =>
                    setFormData({ ...formData, instagramemail: event.target.value })
                  }
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "21px",
                    mb: 1,
                  }}
                >
                  Registered Facebook Email ID
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  value={formData.fbemail}
                  onChange={(event) =>
                    setFormData({ ...formData, fbemail: event.target.value })
                  }
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "21px",
                    mb: 1,
                  }}
                >
                  Instagram Artists Page
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  placeholder=" https://www.instagram.com/john.doe/"
                  value={formData.instagrampage}
                  onChange={(event) =>
                    setFormData({ ...formData, instagrampage: event.target.value })
                  }
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "21px",
                    mb: 1,
                  }}
                >
                  Facebook Artists Page
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label=""
                  placeholder="https://www.facebook.com/johndoe/"
                  value={formData.fbpage}
                  onChange={(event) =>
                    setFormData({ ...formData, fbpage: event.target.value })
                  }
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                  }}
                />
              </Grid>
            </>
          ) : (
            ""
          )}
        </Grid>
      </Card>

      <Box
        sx={{
          background: "rgba(255, 255, 255, 0.05)",
          padding: "30px",
          color: "white",
          borderRadius: "16px",
          mb: "30px",
        }}
      >
        {finalvalue.map((value, index) => (
          <Box
            sx={{
              border: "1px solid #595959",
              display: "flex",
              minHeight: "50px",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "16px",
              mb: 1,
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ marginLeft: "10px" }}>Song Title:</Typography>
              <Typography sx={{ marginLeft: "10px" }}>{value?.name}</Typography>
            </Box>
            <Button
              className="editbtnsongalbum"
              sx={{ textAlign: "end", marginRight: "10px" }}
              onClick={() => handleEditForm(value)}
            >
              Edit
            </Button>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          background: "rgba(255, 255, 255, 0.05)",
          padding: "30px",
          color: "white",
          borderRadius: "16px",

          margin: "auto",
        }}
      >
        <Typography
          component={"div"}
          variant="h6"
          sx={{
            fontFamily: "DM Sans",
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "31.25px",
          }}
        >
          Advance Details
        </Typography>
        <Grid container spacing={3} marginTop="32px">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
                mb: 1,
              }}
            >
              Song Title*
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              name="name"
              value={multiformadv?.name}
              onChange={handleChangeValue}
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
                mb: 1,
              }}
            >
              Video Link
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              name="videoLink"
              onChange={handleChangeValue}
              value={multiformadv?.videoLink}
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
                mb: 1,
              }}
            >
              ISRC code
            </Typography>
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              onChange={handleChangeValue}
              placeholder="To be filled by Deliver My Tune"
              value={multiformadv?.isrcCode}
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              variant="standard"
              name="isrcCode"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ mt: 1 }}>
            <Box
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "100%",
                padding: "20px",
                borderRadius: "8px",
              }}
            >
              <FormControl>
                <Typography
                  id="demo-row-radio-buttons-group-label"
                  sx={{
                    color: "#FFFFFF",
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "21px",
                    mb: 2,
                  }}
                >
                  Composition Type*
                </Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="compositionType"
                  onChange={handleChangeValue}
                  value={multiformadv.compositionType}
                >
                  <FormControlLabel
                    value="original"
                    control={
                      <Radio
                        sx={{
                          color: "#E1E1E1",
                          "&.Mui-checked": {
                            color: "#33D67C",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "DM Sans !important",
                          fontSize: "14px !important",
                          fontWeight: 400,
                          lineHeight: "18px !important",
                        }}
                      >
                        Original Composition
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="cover"
                    control={
                      <Radio
                        sx={{
                          color: "#E1E1E1",
                          "&.Mui-checked": {
                            color: "#33D67C",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "DM Sans !important",
                          fontSize: "14px !important",
                          fontWeight: 400,
                          lineHeight: "18px !important",
                        }}
                      >
                        Cover Composition
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="public"
                    control={
                      <Radio
                        sx={{
                          color: "#E1E1E1",
                          "&.Mui-checked": {
                            color: "#33D67C",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "DM Sans !important",
                          fontSize: "14px !important",
                          fontWeight: 400,
                          lineHeight: "18px !important",
                        }}
                      >
                        Public Domain
                      </Typography>
                    }
                  />
                </RadioGroup>
                {multiformadv?.compositionType === "cover" ? (
                  <Coverfile
                    coverSongFile={coverSongFile}
                    setCoverfile={setCoverfile}
                    career={career}
                    multiformadv={multiformadv}
                    setmultiformadv={setmultiformadv}
                  />
                ) : (
                  ""
                )}
              </FormControl>
              {seconderror && multiformadv.compositionType <= 0 ? (
                <label className="errorhandler">
                  Composition Type can't be Empty
                </label>
              ) : (
                ""
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ mt: 1 }}>
            <Box
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "100%",
                padding: "20px",
                borderRadius: "8px",
              }}
            >
              <FormControl>
                <Typography
                  id="demo-row-radio-buttons-group-label"
                  sx={{
                    color: "#FFFFFF",
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "21px",
                    mb: 2,
                  }}
                >
                  Clean/Explicit*
                </Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="cleanExplicit"
                  onChange={handleChangeValue}
                  value={multiformadv.cleanExplicit}
                // onChange={(event) =>
                //   setFormData({ ...formData, cleanExplicit: event.target.value })
                // }
                >
                  <FormControlLabel
                    value="clean"
                    control={
                      <Radio
                        sx={{
                          color: "#E1E1E1",
                          "&.Mui-checked": {
                            color: "#33D67C",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "DM Sans !important",
                          fontSize: "14px !important",
                          fontWeight: 400,
                          lineHeight: "18px !important",
                        }}
                      >
                        Clean
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="explicit"
                    control={
                      <Radio
                        sx={{
                          color: "#E1E1E1",
                          "&.Mui-checked": {
                            color: "#33D67C",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: "DM Sans !important",
                          fontSize: "14px !important",
                          fontWeight: 400,
                          lineHeight: "18px !important",
                        }}
                      >
                        Explicit Song
                      </Typography>
                    }
                  />
                </RadioGroup>
                {seconderror && multiformadv.cleanExplicit <= 0 ? (
                  <label className="errorhandler">
                    Clean/Explicit can't be Empty
                  </label>
                ) : (
                  ""
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Typography
          component={"div"}
          variant="h6"
          sx={{
            fontFamily: "DM Sans",
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "31.25px",
            marginTop: "40px !important",
          }}
        >
          Artist Details
        </Typography>
        <Grid container spacing={3} sx={{ my: 3 }}>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Primary Artist First Name*
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="primaryfirstname"
                value={artistdetail?.primaryfirstname}
                onChange={handleChangeartist}
                type="text"
                id="name"
                required
              />
            </Box>
            {seconderror && artistdetail.primaryfirstname <= 0 ? (
              <label className="errorhandler">
                Primary artist first name can't be Empty
              </label>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Primary Artist Last Name
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="primarylastname"
                value={
                  artistdetail?.primarylastname === "undefined"
                    ? ""
                    : artistdetail?.primarylastname
                }
                onChange={handleChangeartist}
                type="text"
                id="name"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Primary Artist Spotify ID
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="primaryspotifyid"
                value={artistdetail?.primaryspotifyid}
                onChange={handleChangeartist}
                type="text"
                id="spotifyid"
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Primary Artist Instagram Handle
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="primaryinstagram"
                value={artistdetail?.primaryinstagram}
                onChange={handleChangeartist}
                type="text"
                id="instagram"
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Primary Artist Twitter Handle
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="primarytwitter"
                value={artistdetail?.primarytwitter}
                onChange={handleChangeartist}
                type="text"
                id="twitter"
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Primary Artist Facebook Page URL
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="primaryfacebook"
                value={artistdetail?.primaryfacebook}
                onChange={handleChangeartist}
                type="text"
                id="facebook"

              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Featuring Artist First Name
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="featuringfirstname"
                value={
                  artistdetail?.featuringfirstname === "undefined"
                    ? ""
                    : artistdetail?.featuringfirstname
                }
                onChange={handleChangeartist}
                type="text"
                id="name"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Featuring Artist Last Name
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="featuringlastname"
                value={
                  artistdetail?.featuringlastname === "undefined"
                    ? ""
                    : artistdetail?.featuringlastname
                }
                onChange={handleChangeartist}
                type="text"
                id="name"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Featuring Artist Spotify ID
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="featuringspotifyid"
                value={artistdetail?.featuringspotifyid}
                onChange={handleChangeartist}
                type="text"
                id="spotifyid"
                
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Featuring Artist Instagram Handle
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="featuringinstagram"
                value={artistdetail?.featuringinstagram}
                onChange={handleChangeartist}
                type="text"
                id="instagram"
                
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Featuring Artist Twitter Handle
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="featuringtwitter"
                value={artistdetail?.featuringtwitter}
                onChange={handleChangeartist}
                type="text"
                id="twitter"
                
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Featuring Artist Facebook Page URL
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="featuringfacebook"
                value={artistdetail?.featuringfacebook}
                onChange={handleChangeartist}
                type="text"
                id="facebook"
                
              />
            </Box>

            {/* {seconderror && artistdetail?.featuringlastname <= 0 ? (
              <label className="errorhandler">
                Featuring last name can't be Empty
              </label>
            ) : (
              ""
            )} */}
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ my: 3 }}>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Lyricist First Name*
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="lyricistfirstname"
                value={artistdetail?.lyricistfirstname}
                onChange={handleChangeartist}
                type="text"
                id="name"
                required
              />
            </Box>
            {seconderror && artistdetail?.lyricistfirstname <= 0 ? (
              <label className="errorhandler">
                Lyricist first name can't be Empty
              </label>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Lyricist Last Name*
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="lyricistlastname"
                value={artistdetail?.lyricistlastname}
                onChange={handleChangeartist}
                type="text"
                id="name"
                required
              />
            </Box>
            {seconderror && artistdetail?.lyricistlastname <= 0 ? (
              <label className="errorhandler">
                Lyricist last name can't be Empty
              </label>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Lyricist Artist Spotify ID
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="lyricistspotifyid"
                value={artistdetail?.lyricistspotifyid}
                onChange={handleChangeartist}
                type="text"
                id="spotify"
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Lyricist Artist Instagram Handle
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="lyricistinstagram"
                value={artistdetail?.lyricistinstagram}
                onChange={handleChangeartist}
                type="text"
                id="instagram"
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Lyricist Artist Twitter Handle
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="lyricisttwitter"
                value={artistdetail?.lyricisttwitter}
                onChange={handleChangeartist}
                type="text"
                id="lyricisttwitter"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Lyricist Artist Facebook Page URL
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="lyricistfacebook"
                value={artistdetail?.lyricistfacebook}
                onChange={handleChangeartist}
                type="text"
                id="facebook"
                required
              />
            </Box>

            {/* {seconderror && artistdetail?.featuringlastname <= 0 ? (
              <label className="errorhandler">
                Featuring last name can't be Empty
              </label>
            ) : (
              ""
            )} */}
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Composer First Name*
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="composerfirstname"
                value={artistdetail?.composerfirstname}
                onChange={handleChangeartist}
                type="text"
                id="name"
                required
              />
            </Box>
            {seconderror && artistdetail?.composerfirstname <= 0 ? (
              <label className="errorhandler">
                Composer first name can't be Empty
              </label>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Composer Last Name*
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="composerlastname"
                value={artistdetail?.composerlastname}
                onChange={handleChangeartist}
                type="text"
                id="name"
                required
              />
            </Box>
            {seconderror && artistdetail?.composerlastname <= 0 ? (
              <label className="errorhandler">
                Composer last name can't be Empty
              </label>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Composer Artist Spotify ID
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="composerspotifyid"
                value={artistdetail?.composerspotifyid}
                onChange={handleChangeartist}
                type="text"
                id="composerspotifyid"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Composer Artist Instagram Handle
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="composerinstagram"
                value={artistdetail?.composerinstagram}
                onChange={handleChangeartist}
                type="text"
                id="composerinstagram"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Composer Artist Twitter Handle
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="composertwitter"
                value={artistdetail?.composertwitter}
                onChange={handleChangeartist}
                type="text"
                id="composertwitter"
                required
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                mb: 1,
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "21px",
              }}
            >
              Composer Artist Facebook Page URL
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                label=""
                sx={{
                  background: "rgba(255, 255, 255, 0.05) !important",
                  height: "44px !important",
                  borderRadius: "5px",
                  p: 1,
                  fontFamily: "DM Sans",
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  background: "rgba(255, 255, 255, 0.05) !important",
                }}
                name="composerfacebook"
                value={artistdetail?.composerfacebook}
                onChange={handleChangeartist}
                type="text"
                id="composerfacebook"
                required
              />
            </Box>

            {/* {seconderror && artistdetail?.featuringlastname <= 0 ? (
              <label className="errorhandler">
                Featuring last name can't be Empty
              </label>
            ) : (
              ""
            )} */}
          </Grid>
        </Grid>
        <Typography
          component={"div"}
          variant="h6"
          sx={{
            fontFamily: "DM Sans",
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "31.25px",
            marginTop: "40px !important",
          }}
        >
          Add More Artists
        </Typography>
        <Box></Box>
        {listData?.map((roleAdd, index) => (
  <Box key={index} className="role">
    <Grid container spacing={3} sx={{ my: 3 }}>
      {/* Role */}
      <Grid item xs={12} md={3}>
        <Typography
          sx={{
            mb: 1,
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "21px",
          }}
        >
          Role
        </Typography>
        <TextField
          fullWidth
          select
          name="role"
          id="role"
          value={roleAdd.role}
          onChange={(e) => handleChange(e, index)}
          sx={{
            background: "rgba(255, 255, 255, 0.05) !important",
            height: "44px !important",
            borderRadius: "5px",
            p: 1,
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "18px",
            color: "#E1E1E1",
          }}
          variant="standard"
          InputProps={{
            disableUnderline: true,
            background: "rgba(255, 255, 255, 0.05) !important",
          }}
        >
          {previewStart?.map((option, index) => (
            <MenuItem key={option.index} value={option.value}>
              <Typography sx={{ fontFamily: "DM sans" }}>
                {" "}
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {/* First Name */}
      <Grid item xs={12} md={3}>
        <Typography
          sx={{
            mb: 1,
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "21px",
          }}
        >
          First Name
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            fullWidth
            label=""
            sx={{
              background: "rgba(255, 255, 255, 0.05) !important",
              height: "44px !important",
              borderRadius: "5px",
              p: 1,
              fontFamily: "DM Sans",
            }}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              background: "rgba(255, 255, 255, 0.05) !important",
            }}
            name="name"
            type="text"
            id="name"
            value={roleAdd.name}
            onChange={(e) => handleChange(e, index)}
            required
          />
        </Box>
      </Grid>
      {/* Last Name */}
      <Grid item xs={12} md={3}>
        <Typography
          sx={{
            mb: 1,
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "21px",
          }}
        >
          Last Name
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            fullWidth
            label=""
            sx={{
              background: "rgba(255, 255, 255, 0.05) !important",
              height: "44px !important",
              borderRadius: "5px",
              p: 1,
              fontFamily: "DM Sans",
            }}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              background: "rgba(255, 255, 255, 0.05) !important",
            }}
            name="lastname"
            type="text"
            id="name"
            value={roleAdd.lastname}
            onChange={(e) => handleChange(e, index)}
            required
          />
        
        </Box>
      </Grid>
      {/* Spotify ID */}
      <Grid item xs={12} md={3}>
        <Typography
          sx={{
            mb: 1,
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "21px",
          }}
        >
          Spotify ID
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            fullWidth
            label=""
            sx={{
              background: "rgba(255, 255, 255, 0.05) !important",
              height: "44px !important",
              borderRadius: "5px",
              p: 1,
              fontFamily: "DM Sans",
            }}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              background: "rgba(255, 255, 255, 0.05) !important",
            }}
            name="spotifyid"
            type="text"
            id="spotifyid"
            value={roleAdd.spotifyid}
            onChange={(e) => handleChange(e, index)}
            required
          />
        </Box>
      </Grid>
      {/* Instagram Handle */}
      <Grid item xs={12} md={4}>
        <Typography
          sx={{
            mb: 1,
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "21px",
          }}
        >
          Instagram Handle
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            fullWidth
            label=""
            sx={{
              background: "rgba(255, 255, 255, 0.05) !important",
              height: "44px !important",
              borderRadius: "5px",
              p: 1,
              fontFamily: "DM Sans",
            }}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              background: "rgba(255, 255, 255, 0.05) !important",
            }}
            name="instagram"
            type="text"
            id="instagram"
            value={roleAdd.instagram}
            onChange={(e) => handleChange(e, index)}
            required
          />
        </Box>
      </Grid>
      {/* Twitter Handle */}
      <Grid item xs={12} md={4}>
        <Typography
          sx={{
            mb: 1,
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "21px",
          }}
        >
          Twitter Handle
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            fullWidth
            label=""
            sx={{
              background: "rgba(255, 255, 255, 0.05) !important",
              height: "44px !important",
              borderRadius: "5px",
              p: 1,
              fontFamily: "DM Sans",
            }}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              background: "rgba(255, 255, 255, 0.05) !important",
            }}
            name="twitter"
            type="text"
            id="twitter"
            value={roleAdd.twitter}
            onChange={(e) => handleChange(e, index)}
            required
          />
        </Box>
      </Grid>
      {/* Facebook Page URL */}
      <Grid item xs={12} md={4}>
        <Typography
          sx={{
            mb: 1,
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "21px",
          }}
        >
          Facebook Page URL
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            fullWidth
            label=""
            sx={{
              background: "rgba(255, 255, 255, 0.05) !important",
              height: "44px !important",
              borderRadius: "5px",
              p: 1,
              fontFamily: "DM Sans",
            }}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              background: "rgba(255, 255, 255, 0.05) !important",
            }}
            name="facebook"
            type="text"
            id="facebook"
            value={roleAdd.facebook}
            onChange={(e) => handleChange(e, index)}
            required
          />
        </Box>
        
       
      </Grid>
      <Box sx={{ ml: "26px",mt:"8px" }}>
            {listData.length === 1 ? (
              <DeleteOutlineOutlinedIcon
                sx={{ color: "red", display: "none" }}
              />
            ) : (
              <DeleteOutlineOutlinedIcon
                onClick={() => handleServiceRemove(index)}
                sx={{ color: "red" }}
              />
            )}
          </Box>
      {/* Add Artist Button */}
      {listData.length - 1 === index && (
        <Grid item xs={12}>
          <CustomButton
            btnText={"Add Artist"}
            endIcon={<AddIcon sx={{ ml: "13px" }} />}
            buttonStyle="artistbtn"
            handleClick={handleAdd}
          />
        </Grid>
      )}
    </Grid>
  </Box>
))}
        <Box>
          <Typography
            component={"div"}
            variant="h6"
            sx={{
              marginTop: "50px",
              mb: 4,
              fontFamily: "DM Sans",
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "31px",
            }}
          >
            Upload Your Song &nbsp;
            <span
              className="album"
              sx={{
                fontFamily: "dm sans",
                fontWeight: "700 !important",
                color: "rgb(255, 255, 255) !important",
                fontStyle: "normal !important",
                margin: "0px auto 32px !important",
              }}
            >
              (Audio less then 1 minute or more then 6.30 minute will be
              rejected)
            </span>
          </Typography>
        </Box>

        <Grid
          container
          spacing={1.5}
          sx={{ background: "#1e1e1e ", pb: "0px", borderRadius: "10px" }}
        >
          <Grid item xs={12} md={6} px="28px">
            <ChangeSong
              songalbum={songalbum}
              showsong={showsong}
              setSongValue={setSongValue}
              songValue={songValue}
              career={career}
              multiformadv={multiformadv}
              setmultiformadv={setmultiformadv}
              songprocessing={songprocessing}
              setSongprocessing={setSongprocessing}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          paddingY: "48px",
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "1200px",
          margin: "auto",
        }}
      >
        {editbtn ? (
          <CustomButton
            disabled={songprocessing == true}
            handleClick={handleUpdate}
            btnText={"Update Song"}
            endIcon={<AddIcon sx={{ ml: "13px" }} />}
            buttonStyle="artistbtn"
          />
        ) : (
          <CustomButton
            disabled={songprocessing == true}
            handleClick={addsong}
            btnText={"Add Song"}
            endIcon={<AddIcon sx={{ ml: "13px" }} />}
            buttonStyle="artistbtn"
          />
        )}
        <ToastContainer />
      </Box>
    </>
  );
};

export default Index;

import React, { useState } from 'react'

import {
    Accordion, AccordionSummary, AccordionDetails,
    Stack, Box, Typography, Card, Link
} from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import '../commonQuestions/CommonQus.css'

import {useNavigate} from 'react-router-dom'


function CommomQuestion() {
    const locateToFaqPage = useNavigate()

    const [expandOne, setExpandOne] = useState(false)
    const handleChange = (isExpanded, pannel) => {
        setExpandOne(isExpanded ? pannel : false)
    }

    const handleLocater = ()=>{
        locateToFaqPage("/Faq");
    }
    return (
        <Stack id='commQ-master-stack'>

            <Box id='commQ-master-box'  sx={{my:"20px" ,mx:{xs:'15px',sm:'30px',md:'100px'}}}>
                {/* ------------------------------------------------------------------1st Card Heading Start */}

                <Typography id='commQ-main-heading'>
                    Common Questions
                </Typography>

                {/* --------------------------------------------------------------------1st Card Heading End */}

                <Card>
                    <Box>

                        {/* -------------------------------------------------------------According 1 Start */}

                        <Accordion
                            id='commQ-accordian-parent'
                            expanded={expandOne === 'pannel1'}
                            onChange={(event, isExpanded) => handleChange(isExpanded, 'pannel1')} disableGutters>
                            <AccordionSummary
                                id='commQ-accordian-summary'
                                sx={{
                                    '.css-1tmu6hb-MuiButtonBase-root-MuiAccordionSummary-root': {
                                        borderBottom: '1px solid #6d7280 !important'
                                    }
                                }}
                                aria-controls='Pannel1-content'
                                expandIcon={<ExpandMoreIcon color='primary' sx={{ color: 'black' }} fontSize='large' />}>
                                <Typography id='commQ-accordiansummary-heading'>
                                    What do I need to get started with Deliver My Tune?
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Typography>
                                    You can sign up with your email address and you must be at least 14 years old to be eligible to sign up. <br /><br />

                                    Your submitted audio file should be in the following format: <br />


                                    <ul  style={{listStyle:'none',paddingLeft:'40px',paddingTop:'15px',padddingBottom:'15px'}}>
                                        <li style={{marginBottom:'8px'}}> Mp3 or Wav </li>
                                        <li style={{marginBottom:'8px'}}>Files must be stereo</li>
                                        <li style={{marginBottom:'8px'}}>44.1 kHz sampling frequency</li>
                                        <li style={{marginBottom:'15px'}}>320 Kbps or 24 bit per sample</li>

                                    </ul>

                                    Your submitted audio file should be in the following format: <br />

                                    <ul  style={{listStyle:'none',paddingLeft:'40px',paddingTop:'15px'}}>

                                        <li style={{marginBottom:'8px'}}>3000 x 3000 Pixels </li>
                                        <li style={{marginBottom:'8px'}}>JPG file type</li>
                                        <li style={{marginBottom:'8px'}} >RGB color scheme</li>
                                        <li style={{fontWeight:'700',marginBottom:'8px'}}>No Contact information, social media links/logo are allowed</li>
                                        <li style={{marginBottom:'25px',fontWeight:'700'}}>Credits to none Audio Performers allowed</li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        {/* --------------------------------------------------------------------According 1 End */}


                        {/* -------------------------------------------------------------According 2 Start */}

                        <Accordion
                            id='commQ-accordian-parent'
                            expanded={expandOne === 'pannel2'} disableGutters
                            onChange={(event, isExpanded) => handleChange(isExpanded, 'pannel2')}>
                            <AccordionSummary
                                id='commQ-accordian-summary'
                                sx={{
                                    '.css-1tmu6hb-MuiButtonBase-root-MuiAccordionSummary-root': {
                                        borderBottom: '1px solid white !important'
                                    }
                                }}
                                aria-controls='Pannel1-content'
                                expandIcon={<ExpandMoreIcon color='primary' sx={{ color: 'black' }} fontSize='large' />}>
                                <Typography id='commQ-accordiansummary-heading'>
                                    How does Deliver My Tune work?
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Typography sx={{paddingLeft:{xs:'0px',sm:'40px'}}}
                                //  id='accordiandetails-content'
                                >
                                    After signing up, artists deliver their music to Deliver My Tune <br /><br />

                                    After content verification by the Deliver My Tune team, the artist’s music is distributed on all partner digital music stores. <br /><br />

                                    The store’s content team verifies the audio content and makes the song live on their platforms <br /><br />

                                    Artists start receiving the sales report via Deliver My Tune. <br /><br />

                                    Deliver My Tune will transfer 90% of the earned royalty directly to the artist bank account. <br /><br />

                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        {/* --------------------------------------------------------------------According 2 End */}


                        {/* -------------------------------------------------------------According 3 Start */}

                        <Accordion
                            id='commQ-accordian-parent'
                            expanded={expandOne === 'pannel3'} disableGutters
                            onChange={(event, isExpanded) => handleChange(isExpanded, 'pannel3')}>
                            <AccordionSummary
                                id='commQ-accordian-summary'
                                sx={{
                                    '.css-1tmu6hb-MuiButtonBase-root-MuiAccordionSummary-root': {
                                        borderBottom: '1px solid white !important'
                                    }
                                }}
                                aria-controls='Pannel1-content'
                                expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} fontSize='large' />}>
                                <Typography id='commQ-accordiansummary-heading'>
                                    Does Deliver My Tune own the copyright to my work?
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Typography
                                // id='accordiandetails-content'
                                >

                                    No, artists own 100% copyright to their work. Deliver My Tune will only work as a representative.

                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        {/* --------------------------------------------------------------------According 3 End */}

                        {/* -------------------------------------------------------------According 4 Start */}

                        <Accordion
                            id='commQ-accordian-parent'>
                            <Box
                                id='commQ-accordian-summary'
                                sx={{
                                    '.css-1tmu6hb-MuiButtonBase-root-MuiAccordionSummary-root': {
                                        borderBottom: '1px solid white !important'
                                    }, height: '70px'
                                }}>
                                <Link onClick={handleLocater} sx={{color: '#a16eff', opacity: '0.9'}}
                                    underline="hover" >
                                    <Typography sx={{
                                        color: '#a16eff', fontSize: '20px', fontWeight: '400', opacity: '0.9',
                                        padding:'15px 20px'}}>
                                        See More</Typography>
                                </Link>
                            </Box>


                        </Accordion>

                        {/* --------------------------------------------------------------------According 4 End */}


                    </Box>
                </Card>

            </Box>

        </Stack>
    )
}

export default CommomQuestion

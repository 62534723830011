import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "@mui/material/TextField";
import "./style.css";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { useContactMutation } from "../../redux/services/login";
import axios from "axios";

const theme = createTheme();
const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  firstName: yup
    .string("Enter your FirstName")
    .required("First Name is required"),
  lastName: yup.string("Enter your LastName").required("Last Name is required"),
  mobile: yup
    .number("Enter your Mobile Number")
    .required("Mobile Number is required"),
});

export default function SignIn() {
  const [contact, responseInfo] = useContactMutation();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit:async (values, {resetForm}) => {
      const payload = {
        first: values.firstName,
        last: values.lastName,
        email: values.email,
        phone: values.mobile,
        message: values.message,
      };

      await axios
      .post(
        `${process.env.REACT_APP_DMT_API_PATH}/public/contacts`,
        payload
      )
      .then((res)=>{
        if (res.data.error === false) {
          // console.log("error");
          // toast.success(res?.data?.message)
          resetForm()
        }
      })
      // if (responseInfo.isLoading) return <div>Loading....</div>;
      // if (responseInfo.isError) alert("something went wrong");
      // contact(payload);
      // console.log("Success: ", responseInfo.isSuccess);
    },
  
  });
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" sx={{ textAlign: "-webkit-center" }}>
        <Paper className="paper-form-signup" component={Box} marginTop="80px">
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            padding="1rem 1.25rem;"
          >
            <Typography variant="h3" className="forgot-data">
              Get in Touch
            </Typography>

            <Grid container spacing={4} mt={2}>
              <Grid item md={6} xs={12} className="">
                <Box sx={{ marginTop: "20px", paddingLeft: "1.25rem" }}>
                  <Typography
                    sx={{
                      textAlign: "start",
                      color: "black",
                      fontFamily: "Open Sans, sans-serif",
                      fontSize: "0.75rem",
                      fontWeight: 700,
                      lineHeight: "1rem",
                    }}
                  >
                    First Name
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    id="firstName"
                    name="firstName"
                    placeholder="Enter Your First Name"
                    sx={{ background: "white", marginTop: "4px",  border: "1px solid #1b1b38",borderRadius:'4px' }}
                    
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    // error={
                    //   formik.touched.firstName &&
                    //   Boolean(formik.errors.firstName)
                    // }
                    // helperText={
                    //   formik.touched.firstName && formik.errors.firstName
                    // }
                   
                  />
                   {formik.touched.firstName && formik.errors.firstName ? (
                      <Typography sx={{ textAlign: "start" }} color="error">
                        {formik.errors.firstName}
                      </Typography>
                    ) : null}
                </Box>
              </Grid>

              <Grid item md={6} xs={12} className="">
                <Box sx={{ marginTop: "20px", paddingLeft: "1.25rem" }}>
                  <Typography
                    sx={{
                      textAlign: "start",
                      color: "black",
                      fontFamily: "Open Sans, sans-serif",
                      fontSize: "0.75rem",
                      fontWeight: 700,
                      lineHeight: "1rem",
                    }}
                  >
                    Last Name
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    id="lastName"
                    name="lastName"
                    placeholder="Enter Your Last Name"
                    sx={{ background: "white", marginTop: "4px",  border: "1px solid #1b1b38" ,borderRadius:'4px'}}
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    // error={
                    //   formik.touched.lastName && Boolean(formik.errors.lastName)
                    // }
                    // helperText={
                    //   formik.touched.lastName && formik.errors.lastName
                    // }
                  />
                   {formik.touched.lastName && formik.errors.lastName ? (
                      <Typography sx={{ textAlign: "start" }} color="error">
                        {formik.errors.lastName}
                      </Typography>
                    ) : null}
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item md={6} xs={12} className="">
                <Box sx={{ marginTop: "20px", paddingLeft: "1.25rem" }}>
                  <Typography
                    sx={{
                      textAlign: "start",
                      color: "black",
                      fontFamily: "Open Sans, sans-serif",
                      fontSize: "0.75rem",
                      fontWeight: 700,
                      lineHeight: "1rem",
                    }}
                  >
                    Email
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    id="email"
                    name="email"
                    placeholder="Enter Your Email ID"
                    sx={{ background: "white", marginTop: "4px",  border: "1px solid #1b1b38",borderRadius:'4px' }}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    // error={formik.touched.email && Boolean(formik.errors.email)}
                    // helperText={formik.touched.email && formik.errors.email}
                  />
                   {formik.touched.email && formik.errors.email ? (
                      <Typography sx={{ textAlign: "start" }} color="error">
                        {formik.errors.email}
                      </Typography>
                    ) : null}
                </Box>
              </Grid>

              <Grid item md={6} xs={12} className="">
                <Box sx={{ marginTop: "20px", paddingLeft: "1.25rem" }}>
                  <Typography
                    sx={{
                      textAlign: "start",
                      color: "black",
                      fontFamily: "Open Sans, sans-serif",
                      fontSize: "0.75rem",
                      fontWeight: 700,
                      lineHeight: "1rem",
                    }}
                  >
                    Mobile Number
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    id="mobile"
                    name="mobile"
                    type="number"
                    placeholder="Enter Your Mobile Number"
                    sx={{ background: "white", marginTop: "4px" ,  border: "1px solid #1b1b38",borderRadius:'4px'}}
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    // error={
                    //   formik.touched.mobile && Boolean(formik.errors.mobile)
                    // }
                    // helperText={formik.touched.mobile && formik.errors.mobile}
                  />
                   {formik.touched.mobile && formik.errors.mobile ? (
                      <Typography sx={{ textAlign: "start" }} color="error">
                        {formik.errors.mobile}
                      </Typography>
                    ) : null}
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Box sx={{ marginTop: "20px", paddingLeft: "1.25rem" }}>
                <Typography
                  sx={{
                    textAlign: "start",
                    color: "black",
                    fontFamily: "Open Sans, sans-serif",
                    fontSize: "0.75rem",
                    fontWeight: 700,
                    lineHeight: "1rem",
                  }}
                >
                  Message
                </Typography>
                <TextField
                  id="outlined-about"
                  fullWidth
                  size="small"
                  multiline
                  rows={3}
                  maxRows={4}
                  name="message"
                  type="text"
                  placeholder="Enter Message"
                  sx={{ background: "white", marginTop: "4px",   border: "1px solid #1b1b38",borderRadius:'4px'}}
                  value={formik.values.message}
                  onChange={formik.handleChange}
                />
              </Box>
            </Box>

            <Box textAlign="center" marginTop="35px" marginBottom="25px">
              <Button className="submit-btn" type="submit">
                <ClearIcon sx={{ marginRight: "10px", color: "black" }} />
                SEND MESSAGE
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}

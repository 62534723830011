import { Box, Button, Typography, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import UploadFileIcon from "@mui/icons-material/UploadFile";
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 70,
  height: 70,
  padding: 4,
  marginBottom: 2,
  boxSizing: "border-box",
  borderRadius: "10px",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "70px",
  height: "70px",
};

const Profile = ({ setProfile, profile }) => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },

    onDrop: (acceptedFiles) => {
      setProfile(acceptedFiles[0]);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const thumbs = (
    <div style={thumb} key={profile?.name}>
      <div style={thumbInner}>
        <img
          src={profile?.preview || profile}
          style={img}
          onLoad={() => {
            URL.revokeObjectURL(profile?.preview);
          }}
        />
      </div>
    </div>
  );

  return (
    <div>
      <Box
        sx={{ paddingRight: { md: "30px", xs: "15px", marginBottom: "20px" } }}
      >
        <div {...getRootProps({ className: "basic-song-drop" })}>
          <input {...getInputProps()} />
          <Box sx={{ width: "70px", height: "100px", mb: "20px" }}>
            <aside style={thumbsContainer}>{thumbs}</aside>
          </Box>
          <IconButton type="button" className="upload-song">
            <UploadFileIcon />
          </IconButton>
          <Typography
            className="drop-song"
            padding="20px !important"
            lineHeight="30px ! important"
          >
            Drop or Choose your Profile Photo
          </Typography>
        </div>
      </Box>
    </div>
  );
};

export default Profile;

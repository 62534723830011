import React from "react";
import BasicDetailsdata from "./basicDetail";
import Box from "@mui/material/Box";
import AdvancedDetails from "./advanceMulti";
import { useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import Stepper from "../../components/progressBar/index";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IoIosArrowForward } from "react-icons/io";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { userSlice } from "../../redux/slices/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ScaleLoader } from "react-spinners";
import { useEffect } from "react";

const DistributeSingleTrack = ({ career }) => {
  const navigate = useNavigate();
  const [albumkey, setAlbumkey] = useState("album");
  const [songValue, setSongValue] = useState();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [openSidebar, setOpenSidebar] = useState(false);
  const toggleSidebar = () => {
    dispatch(userSlice.actions.saveUser(""));
    setSongValue(undefined);
    setOpenSidebar((p) => !p);
  };
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [value, setValue] = React.useState(career?.releaseDate || null);

  let datevalue = JSON.stringify(value?.$d);
  datevalue = datevalue?.slice(1, 11);
  const [ringtone, setRingtone] = useState();
  const [moviesong, setMoviesong] = useState(false);
  const [tracktomaster, setTracktomaster] = useState(false);
  const [coverSongFile, setCoverfile] = useState();
  const [error, setError] = useState(false);
  const [seconderror, setSecondError] = useState(false);
  const [id, setId] = useState();
  const location = useLocation();
  const firstslidedata = useSelector((state) => state?.user);
  const [filedata, setFiledata] = useState(
    career?.cover || firstslidedata?.cover || ""
  );
  useEffect(() => {
    if (career?.releaseDate) {
      setValue(career?.releaseDate);
    }
  }, [career]);
  useEffect(() => {
    if (career?.filmBoolean) {
      setMoviesong(career?.filmBoolean);
    }
  }, [career]);
  useEffect(() => {
    if (career?.isMastered) {
      setTracktomaster(career?.isMastered);
    }
  }, [career]);

  const [finalvalue, setFinalvalue] = useState(career?.tracks || []);
  const [formData, setFormData] = useState({
    name: career?.name || "",
    artwork: career?.cover || firstslidedata?.cover || "",
    recordlabel: career?.recordLabel || "",
    songlang: career?.language || "",
    songgenre: career?.genre || "",
    previewstart: career?.tracks[0]?.previewStartTime || "",
    releasedate: career?.releaseDate || "",
    moviesong: career?.filmBoolean || moviesong,
    filmname: career?.filmDetail?.name || "",
    directorname: career?.filmDetail?.artists[0]?.name || "",
    actorname: career?.filmDetail?.artists[1]?.name || "",
    actressname: career?.filmDetail?.artists[2]?.name || "",
  });

  const [multiformadv, setmultiformadv] = useState({
    _id: "",
    name: "",
    videoLink: "",
    isrcCode: "",
    compositionType: "",
    coverSongFile: "",
    cleanExplicit: "",
    file: "",
    ringtone: "",
  });
  const [artistdetail, setArtistdetail] = useState({
    primaryfirstname: "",
    primarylastname: "",
    featuringfirstname: "",
    featuringlastname: "",
    lyricistfirstname: "",
    lyricistlastname: "",
    composerfirstname: "",
    composerlastname: "",
  });
  const [listData, setListData] = useState([
    { role: "", name: "", lastname: "" },
  ]);

  useEffect(() => {
    if (career?.tracks[0]?.file) {
      setSongValue(career?.tracks[0]?.file);
    }
  }, [career]);

  useEffect(() => {
    if (career?.tracks[0]?.ringtone) {
      setRingtone(career?.tracks[0]?.ringtone);
    }
  }, [career]);

  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;
  useEffect(() => {
    if (career) {
      setId(career?._id);
      const userid = career?._id;
    } else {
      setId(loginToken?.user?._id);
      const userid = loginToken?.user._id;
    }
  }, [career, setId]);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleChange = async () => {
    if (
      formData.name.length == 0 ||
      formData.recordlabel.length == 0 ||
      formData.songlang.length == 0 ||
      formData.songgenre.length == 0
    ) {
      setError(true);
      return;
    } else if (!filedata) {
      toast.error("Cover is required");
    } else if (finalvalue.length === 0) {
      toast.error("Please Click Add Or Update song Button for Moving ahead");
    } else if (
      multiformadv.name.length > 0 ||
      multiformadv.videoLink.length > 0 ||
      multiformadv.compositionType.length > 0 ||
      multiformadv.cleanExplicit.length > 0
    ) {
      toast.error("Please Click Add Or Update song Button for Moving ahead");
    } else {
      setIsLoading(true);
      const formDatavalue = new FormData();
      if (moviesong === true) {
        formDatavalue.append("filmBoolean", moviesong);
        formDatavalue.append("filmDetail[name]", formData.filmname);
        formDatavalue.append("filmDetail[artists][0][role]", "director");
        formDatavalue.append(
          "filmDetail[artists][0][name]",
          formData.directorname
        );
        formDatavalue.append("filmDetail[artists][1][role]", "actor");
        formDatavalue.append(
          "filmDetail[artists][1][name]",
          formData.actorname
        );
        formDatavalue.append("filmDetail[artists][2][role]", "actress");
        formDatavalue.append(
          "filmDetail[artists][2][name]",
          formData.actressname
        );
      } else {
        formDatavalue.append("filmBoolean", moviesong);
      }
      formDatavalue.append("name", formData.name);
      formDatavalue.append("cover", filedata);
      formDatavalue.append("recordLabel", formData.recordlabel);
      formDatavalue.append("language", formData.songlang);
      formDatavalue.append("genre", formData.songgenre);
      formDatavalue.append("releaseDate", value.$d || value);
      formDatavalue.append("type", "album");

      for (let i = 0; i < finalvalue.length; i++) {
        formDatavalue.append(`tracks[${i}][name]`, finalvalue[i].name);
        formDatavalue.append(
          `tracks[${i}][videoLink]`,
          finalvalue[i].videoLink
        );
        formDatavalue.append(`tracks[${i}][isrcCode]`, finalvalue[i].isrcCode);
        formDatavalue.append(
          `tracks[${i}][compositionType]`,
          finalvalue[i].compositionType
        );
        if (finalvalue[i]?.compositionType === "cover") {
          formDatavalue.append(
            `tracks[${i}][coverSongFile]`,
            finalvalue[i].coverSongFile
          );
        }
        formDatavalue.append(`tracks[${i}][file]`, finalvalue[i].file);
        formDatavalue.append(
          `tracks[${i}][cleanExplicit]`,
          finalvalue[i].cleanExplicit
        );
        formDatavalue.append(`tracks[${i}][ringtone]`, finalvalue[i].ringtone);
        for (let j = 0; j < finalvalue[i].artists.length; j++) {

          for (let k in finalvalue[i].artists[j]) {
            if (finalvalue[i].artists[j][k] !== "") {
              formDatavalue.append(
                `tracks[${i}][artists][${j}][${k}]`,
                finalvalue[i].artists[j][k]
              );
            }
          }
        }
      }
      setIsLoading(true);
      formDatavalue.append(
        "tracks[0][previewStartTime]",
        formData.previewstart
      );
      await axios
        .post(
          `${process.env.REACT_APP_DMT_API_PATH}/orders/updateartwork/${career?._id}`,
          formDatavalue,
          {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res?.data?.error !== true) {
            toast.success(res?.data?.message);
            navigate("/artist/dashboard");
            setIsLoading(false);

            window.scrollTo(0, 0);
          } else {
            toast.error(res?.message);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };
  const PageDisplay = () => {
    if (page === 0) {
      return isLoading ? (
        <ScaleLoader
          size={350}
          textAlign="center"
          className="music-loader"
          color="#FBCE2E"
        />
      ) : (
        <>
          <BasicDetailsdata
            formData={formData}
            setFormData={setFormData}
            setFiledata={setFiledata}
            filedata={filedata}
            value={value}
            setValue={setValue}
            setMoviesong={setMoviesong}
            career={career}
            error={error}
            firstslidedata={firstslidedata}
            albumkey={albumkey}
          />
          <AdvancedDetails
            finalvalue={finalvalue}
            setFinalvalue={setFinalvalue}
            ringtone={ringtone}
            formData={formData}
            setFormData={setFormData}
            data={data}
            setData={setData}
            setRingtone={setRingtone}
            setTracktomaster={setTracktomaster}
            tracktomaster={tracktomaster}
            setSongValue={setSongValue}
            songValue={songValue}
            career={career}
            seconderror={seconderror}
            firstslidedata={firstslidedata}
            listData={listData}
            setListData={setListData}
            multiformadv={multiformadv}
            setmultiformadv={setmultiformadv}
            setSecondError={setSecondError}
            coverSongFile={coverSongFile}
            setCoverfile={setCoverfile}
            setArtistdetail={setArtistdetail}
            artistdetail={artistdetail}
          />
        </>
      );
    }
  };

  const FormTitles = ["Basic Details", "Advance Details"];

  return (
    <div className="container">
      <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
      <div className="main-container">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="pages">
          <Box className="custom-dmt-container">
            <div className="body">{PageDisplay()}</div>
            {isLoading === true ? (
              ""
            ) : (
              <div className="stepperBtn">
                <Button
                  className="nextbtn"
                  sx={{
                    textTransform: "capitalize  !important",
                    marginTop: "-70px !important",
                  }}
                  onClick={() => {
                    if (page === 0) {
                      handleChange();
                    }
                  }}
                >
                  {page === FormTitles.length - 1 ? "Update" : `Update`}{" "}
                  <IoIosArrowForward fontSize="16px" />
                </Button>
              </div>
            )}
          </Box>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DistributeSingleTrack;

import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import "./choosePlatform.css";
import { choosePlatfrom } from "./utils";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Index = ({
  formData,
  setFormData,
  setPlatform,
  platform,
  thirdError,
  career,
  firstslidedata,
  setBackflow,
  backflow,
}) => {
  const songGenre = [
    {
      value: "2017",
      label: "2017",
    },
    {
      value: "2018",
      label: "2018",
    },
    {
      value: "2019",
      label: "2019",
    },
    {
      value: "2020",
      label: "2020",
    },
    {
      value: "2021",
      label: "2021",
    },
    {
      value: "2022",
      label: "2022",
    },
  ];
  const previewStart = [
    {
      value: "2017",
      label: "2017",
    },
    {
      value: "2018",
      label: "2018",
    },
    {
      value: "2019",
      label: "2019",
    },
    {
      value: "2020",
      label: "2020",
    },
    {
      value: "2021",
      label: "2021",
    },
    {
      value: "2022",
      label: "2022",
    },
  ];

  // useEffect(() => {
  //   if (firstslidedata?.platforms) {
  //     const platforminfo = platform?.map((info) => {
  //       if (firstslidedata.platforms?.includes(info?.name)) {
  //         return { ...info, isChecked: true };
  //       } else {
  //         return { ...info, isChecked: false };
  //       }
  //     });
  //     setPlatform(platforminfo);
  //   }
  // }, [firstslidedata]);

  useEffect(() => {
    if (backflow === false) {
      if (career?.platforms) {
        const platforminfo = platform?.map((info) => {
          if (career?.platforms?.includes(info?.name)) {
            return { ...info, isChecked: true };
          } else {
            return { ...info, isChecked: false };
            // return info;
          }
        });
        setPlatform(platforminfo);
      }
    }
  }, [career]);
    useEffect(() => {
      handleChange({ target: { name: 'allSelect', checked: true } });
    }, []);
  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === 'allSelect') {
      let tempUser = platform.map((plat) => ({ ...plat, isChecked: checked }));
      setPlatform(tempUser);
    }
  };


  return (
    <Box sx={{ pb: "30px !important" }}>
      <Box
        sx={{
          background: "rgba(255, 255, 255, 0.05) !important",
          height: "auto",
          padding: "30px",
          borderRadius: "8px",
          mt: "28px",
        }}
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "700",
            fontFamily: "DM Sans",
            pt: "20px",
            pb: "35px",
          }}
        >
          Publishing Details
        </Typography>
        <Grid container spacing={4} sx={{ pb: "20px" }}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{ fontFamily: "DM Sans", fontWeight: "700", mb: "8px" }}
            >
              Copyright Name*
            </Typography>
            {/* <TextField
              fullWidth
              id="outlined-select-currency"
              select
              value={formData.copyrightyear}
              onChange={(event) =>
                setFormData({ ...formData, copyrightyear: event.target.value })
              }
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,

                fontFamily: "DM Sans",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "18px",
                color: "#E1E1E1",
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            >
              {songGenre.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Typography sx={{ fontFamily: "DM sans" }}>
                    {" "}
                    {option.label}
                  </Typography>
                </MenuItem>
              ))}
            </TextField> */}
            <TextField
              fullWidth
              label=""
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
              name="name"
              type="text"
              id="name"
              value={formData.copyrightyear}
              onChange={(event) =>
                setFormData({ ...formData, copyrightyear: event.target.value })
              }
              required
            />
            {thirdError && formData.copyrightyear <= 0 ? (
              <label className="errorhandler">
                CopyRightName Can't be Empty
              </label>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{ fontFamily: "DM Sans", fontWeight: "700", mb: "8px" }}
            >
              Production Name*
            </Typography>
            {/* <TextField
              fullWidth
              id="outlined-select-currency"
              select
              value={formData.productionright}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  productionright: event.target.value,
                })
              }
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,

                fontFamily: "DM Sans",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "18px",
                color: "#E1E1E1",
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
            >
              {previewStart.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{ fontFamily: "DM Sans" }}
                >
                  <Typography sx={{ fontFamily: "DM sans" }}>
                    {" "}
                    {option.label}
                  </Typography>
                </MenuItem>
              ))}
            </TextField> */}
            <TextField
              fullWidth
              label=""
              sx={{
                background: "rgba(255, 255, 255, 0.05) !important",
                height: "44px !important",
                borderRadius: "5px",
                p: 1,
                fontFamily: "DM Sans",
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                background: "rgba(255, 255, 255, 0.05) !important",
              }}
              name="name"
              type="text"
              id="name"
              value={formData.productionright}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  productionright: event.target.value,
                })
              }
              required
            />
            {thirdError && formData.productionright <= 0 ? (
              <label className="errorhandler">
                ProductionName can't be Empty
              </label>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Box>
      <Paper elevation={1} className="mainContainer">
      <Box>
        <Typography variant="h6" className="mainHeading">
          Choose Platform
        </Typography>
        <Box className="firstCheckbox">
          <Box>
            <Checkbox
              name="allSelect"
              checked={true} // Always checked
              color="warning"
              size="large"
              sx={{
                color: "#ffffff",
                "&.Mui-checked": {
                  color: "#f9b410",
                },
              }}
              disabled // Disable the checkbox
              onChange={handleChange} // Call handleChange when "Select All" checkbox is changed
            />
          </Box>
          <Typography component="div" variant="body2" className="selectAll">
            Select All
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {platform?.map((values, index) => (
            <Grid key={index} item xs={6} md={4} lg={3}>
              <Box className="mainGridBox">
                <Box>
                  <Checkbox
                    name={values.name}
                     checked={true}
                    size="medium"
                    labelStyle={{ color: "white" }}
                    sx={{
                      color: "#ffffff",
                      "&.Mui-checked": {
                        color: "#f9b410",
                      },
                    }}
                    disabled // disable the checkbox
                  />
                </Box>
                <Box className="image">
                  <img src={values.img} width="100%" alt="" />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>

    </Box>
  );
};

export default Index;

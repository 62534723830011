import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import styles from "./index.css";
// import BadgeUnstyled, { badgeUnstyledClasses } from "@mui/base/BadgeUnstyled";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import { CardMedia, Container } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
// import { useRouter } from 'next/router'
import Avatar from "@mui/material/Avatar";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import IconButton from "@mui/material/IconButton";
// import Avatar from "@mui/material/Avatar";
// import Stack from "@mui/material/Stack";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Logo from "../../../assets/img/dmt.png";
// import Link from "next/link"
import Stack from "@mui/material/Stack";
// import InstagramIcon from "@mui/icons-material/Instagram";

const drawerWidth = 270;
const navItems = [
  "home",
  "about",
  "pricing",
  "stores",
  "login",
  "Sign up for free",
];


const Index = (props) => {
  //   const navigate = useNavigate()
  let navigate = useNavigate();
  let location = useLocation();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (item) => {
    switch (item) {
      case "home":
        return navigate("/home");
      case "about":
        return navigate("/about");
      case "pricing":
        return navigate("/pricing");
      case "stores":
        return navigate("/stores");
      case "login":
        return navigate("/login");
      case "Sign up for free":
        return navigate("/signup/artist");

      default:
        return null;
    }
  };
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        textTransform: "capitalize",
        background: "white !important",
      }}
    >
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => handleClick(item)}
            >
              <React.Fragment key={index}>
                {item == "contact Us" ? (
                  <Button
                    variant="contained"
                    key={index}
                    sx={{ color: "#fff" }}
                    className="btn"
                    onClick={() => handleClick(item)}
                  >
                    Contact Us
                  </Button>
                ) : (
                  <Button
                    key={index}
                    sx={{ color: "white", flexDirection: "column" }}
                    className="customNavitems"
                    onClick={() => handleClick(item)}
                  >
                    <div
                      className={`${
                        navigate.pathname === item.path ? "activeNav " : ""
                      } `}
                    >
                      <span className="admin_link_text">{item}</span>
                    </div>
                    <Box className={styles.small_div}></Box>
                  </Button>
                )}
              </React.Fragment>
            </ListItemButton>
          </ListItem>
        ))}

        <Stack
          direction="row"
          spacing={2}
          size="small"
          sx={{
            marginBottom: { xs: "20px", sm: "30px", md: "40px" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <a target="_blank" href="https://www.instagram.com/deliver_my_tune/">
            <IconButton aria-label="delete" size="small">
              <InstagramIcon fontSize="inherit" sx={{ color: "#FD6003;" }} />
            </IconButton>
          </a>
          <a target="_blank" href="https://www.facebook.com/delivermytune/">
            <IconButton aria-label="" size="small">
              <FacebookIcon fontSize="inherit" sx={{ color: "#FD6003;" }} />
            </IconButton>
          </a>

          <a target="_blank" href="https://twitter.com/delivermytune">
            <IconButton aria-label="" size="small">
              <TwitterIcon fontSize="inherit" sx={{ color: "#FD6003;" }} />
            </IconButton>
          </a>

          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCtMUJDqOaZvutHL3adHr1kA"
          >
            <IconButton aria-label="" size="small">
              <YouTubeIcon fontSize="inherit" sx={{ color: "#FD6003;" }} />
            </IconButton>
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/deliver-my-tune/"
          >
            <IconButton aria-label="" size="small">
              <LinkedInIcon fontSize="inherit" sx={{ color: "#FD6003;" }} />
            </IconButton>
          </a>
        </Stack>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box className="bannerBackground">
        <Container
          maxWidth="90%"
          className="nav-item-data"
          style={{ zIndex: 1000 }}
        >
          <AppBar
            component="nav"
            sx={{ background: "none", boxShadow: "none", position: "static" }}
          >
            <Box
              component="div"
              sx={{ flexGrow: 0, display: { xs: "block", sm: "block",pt:'10px' } }}
            >
              <Link href="/home" underline="none">
                <CardMedia
                  component="img"
                  sx={{
                    width: { xs: "45px", md: "72px" },
                    height: { xs: "40px", md: "66px" },
                    marginBottom: "8px",
                    marginTop: "10px",
                    ml:{xs:'0px',md:"62px"}
                  }}
                  image={Logo}
                  alt="dmt"
                />
              </Link>
            </Box>
            <Toolbar
              sx={{
                alignSelf: "self-end",
                display: "flex",
                position: "absolute",
                flexDirection: "row",
                color: "black",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                sx={{
                  display: { sm: "block", md: "none", textAlignLast: "end" },
                  marginTop: "-5px",
                }}
              >
                <MenuIcon />
              </IconButton>

              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "block" },
                  color: "black",mt:'15px'
                }}
              >
                {navItems.map((item, index) => (
                  <React.Fragment key={index}>
                    {item == "Sign up for free" ? (
                      <Button
                        variant="contained"
                        key={index}
                        sx={{ color: "black" }}
                        className="customNavitems"
                        onClick={() => handleClick(item)}
                      >
                        Sign up for free
                      </Button>
                    ) : (
                      <Button
                        key={index}
                        sx={{
                          color: "black !important",
                          flexDirection: "column",
                        }}
                        className="customNavitems"
                        onClick={() => handleClick(item)}
                      >
                        {/* <Box className={
                          router.pathname === `/${item}` ? `${styles.active}` : ""
                        }>{item}</Box>
                        
 */}

                        <div
                          className={`${
                            location.pathname === `/${item}` ? "navactive" : ""
                          } `}
                        >
                          {item}
                        </div>
                        <Box className="smalldiv"></Box>
                      </Button>
                    )}
                  </React.Fragment>
                ))}
              </Box>
            </Toolbar>
          </AppBar>
          <Box component="nav">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              anchor="right"
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "block", md: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                  background: "white",
                },
              }}
            >
              {drawer}
            </Drawer>
          </Box>
        </Container>
      </Box>
    </>
  );
};
Index.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Index;

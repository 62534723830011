import React from "react";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import Grid from "@mui/material/Grid";
import "./style.css";
import Typography from "@mui/material/Typography";
import CustomButton from "../../components/common/customButton/index";
import dollar from "../../assets/img/dollar.png";
import Book from "../../assets/img/book.png";
import song from "../../assets/img/music.png";
import ManageImage from "../../assets/img/static/offeringFullSuiteSite.webp";

const Index = () => {
  return (
    <Container sx={{ maxWidth:'1200px !important'}}>
      <Box>
        <Typography className="offering-suite" sx={{fontSize:{xs:'22px',sm:'40px'},lineHeight:{xs:"30px",sm:"60px"}}}>
          Offering Full-Suite Of Tools And Services For Music Distribution
        </Typography>
      </Box>
      <Grid
        container
        spacing={4}
        sx={{ marginTop: {md:"40px",xs:'10px'}, marginBottom: {md:"60px",xs:'20px'} }}
      >
        <Grid item md={6} xs={12}>
          <Box sx={{ display: "flex" }}>
            <Box className="rounded-img">
              <img src={dollar} alt="img" />
            </Box>
            <Box
              className="services-points"
              sx={{ marginTop: "15px", marginLeft: "15px" }}
            >
              <Typography variant="h6" className="services-points-heading">
              Distribute Music Within 48 Hours
              </Typography>
              <Typography variant="p" className="services-points-para">
              Distribute your music on 60+ music streaming platforms within 48 hours.
              </Typography>
            </Box>
          </Box>
          <hr className="hr-break" style={{marginTop:'15px',marginBottom:'15px'}}/>
           <Box sx={{ display: "flex" }}>
            <Box className="rounded-img">
              <img src={Book} alt="img"  />
            </Box>
            <Box
              className="services-points"
              sx={{ marginTop: "15px", marginLeft: "15px" }}
            >
              <Typography variant="h6" className="services-points-heading">
              Keep 100% of the Copyrights and Control
              </Typography>
              <Typography variant="p" className="services-points-para">
              We make sure that only artist should become the sole owner of her songs
              </Typography>
            </Box>
          </Box>
          <hr className="hr-break" style={{marginTop:'15px',marginBottom:'15px'}}/>
          <Box sx={{ display: "flex" }}>
            <Box className="rounded-img">
              <img src={song} alt="img" />
            </Box>
            <Box
              className="services-points"
              sx={{ marginTop: "15px", marginLeft: "15px" }}
            >
              <Typography variant="h6" className="services-points-heading">
              Artificial intelligence for music mastering
              </Typography>
              <Typography variant="p" className="services-points-para">
              State of the art technology helps our users to experience the best out of the best
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} xs={12} sx={{px:{sm:'100px !important',md:'0px !important'}}}>
          <Box className="manageimgBoxabout"  >
            <Box className="manageBlueDotContainer">
              <Box className="manageBlueDot"></Box>
              <Box className="manageDot"></Box>
              <Box className="manageDot"></Box>
            </Box>
            <img
              src={ManageImage}
              alt="image"
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Index;

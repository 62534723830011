import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const LoginApi = createApi({
  reducerPath: "LoginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_DMT_API_PATH}`,
  }),

  // This is for Login.
  
  endpoints: (builder) => ({
    LoginArtist: builder.mutation({
      query: (newPost) => {
        return {
          url: `/authentication/local`,
          method: "POST",
          // transformResponse: (response, meta, arg) => response.data,
          body: newPost,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          
        
        };
      },
    }),

    //this is for Forget Password
    ForgetPassword: builder.mutation({
      query: (forget) => {
        return {
          url: `authentication/local/forgot`,
          method: "POST",
          body: forget,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
    }),
    //this is for Send otp in Signup
    SendOtpSignup: builder.mutation({
      query: (sendotp) => {
        return {
          url: `authentication/mobile/register-user`,
          method: "POST",
          body: sendotp,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
    }),

    //This is for Signup
    SignupArtist: builder.mutation({
      query: (signup) => {
        return {
          url: `authentication/mobile/verify-register-otp`,
          method: "POST",
          body: signup,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
    }),
    //this is for Contact
    Contact: builder.mutation({
      query: (contact) => {
        return {
          url: `public/contacts`,
          method: "POST",
          body: contact,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
    }),
  }),
});
export const {
  useLoginArtistMutation,
  useForgetPasswordMutation,
  useSignupArtistMutation,
  useContactMutation,
  useSendOtpSignupMutation,
} = LoginApi;

import React from "react";
import "./style.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import Modal from "./modal";

import {
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share";
import {
  WhatsappIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";
import { Container } from "@mui/system";
import Copy from "../../../assets/img/profile/copy.png";
import { toast, ToastContainer } from "react-toastify";

const Showcase = ({ data, tokenedit }) => {
  const couponvalue = `delivermytune.com/creative-professionals/${data?.slug}`;
  const onClickCopy = () => {
    navigator.clipboard.writeText(couponvalue);
    toast.success("Text Copied on Clipboard");
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box
      className="case"
      sx={{
        marginTop: { md: "0px", xs: "0px" },
      }}
    >
      {tokenedit ? (
        <Container sx={{ maxWidth: { md: "80%", xs: "100% !important" } }}>
          <Typography
            className="live"
            sx={{
              fontSize: { xs: "15px !important", sm: "20px !important" },
              lineHeight: { xs: "21px !important", sm: "28px !important" },
              marginLeft: { lg: "80px !important", xs: "none" },
            }}
          >
            Your showcase is live!
          </Typography>
          <Box
            sx={{
              maxWidth: { xs: "100%", md: "90%" },
            }}
          >
            <Box className="combine">
              <Typography
                className="most"
                sx={{
                  fontSize: { xs: "12px !important", sm: "14px !important" },
                  mt: 0.5,
                  lineHeight: { xs: "18px !important", sm: "25px !important" },
                  marginLeft: { lg: "80px", xs: "none" },
                }}
              >
                Your showcase is live at &nbsp;
              </Typography>
              <Typography
                component="span"
                className="most line"
                sx={{
                  fontSize: { xs: "12px !important", sm: "13px !important" },
                  lineHeight: { xs: "18px !important", sm: "32px !important" },
                  maxWidth: { xs: "40% !important", md: "100%" },
                }}
              >
                {couponvalue}
              </Typography>
              <img
                style={{ marginLeft: "10px" }}
                src={Copy}
                alt=""
                onClick={onClickCopy}
              />
              <ShareIcon
                style={{ marginLeft: "8px", color: "#1E1E1E" }}
                onClick={handleOpen}
              />
            </Box>
          </Box>
          <ToastContainer />
        </Container>
      ) : (
        ""
      )}
      <Modal handleClose={handleClose} handleOpen={handleOpen} open={open} data={data} />
    </Box>
  );
};

export default Showcase;

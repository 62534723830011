import React, { useState, useRef, useEffect } from "react";
import Konva from "konva";
import { Stage, Layer, Text, Rect, Image } from "react-konva";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid, IconButton, MenuItem, Select, Stack } from "@mui/material";
import { FormatBold, FormatItalic } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import './artwork.css'

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
 
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "auto",
  maxWidth: 920,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
  borderRadius: 4,
};
export default function BasicModal({
  open,
  setOpen,
  handleClose,
  handleOpen,
  sendimage,
  setSaveimage,
  saveImage,
  isArtWork,
  isId
}) {
  const navigate = useNavigate();
  const stageRef = useRef(null);
  const [text, setText] = useState("");
  const [font, setFont] = useState("Arial");
  const [color, setColor] = useState("#000000");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [thumbnailDataUrl, setThumbnailDataUrl] = useState(sendimage);
  const [image, setImage] = useState(null);
  const [editedImage, setEditedImage] = useState(null);
  const [imageDownloaded, setImageDownloaded] = useState(false);
  const [fontSize, setFontSize] = useState(30);
  const [x, setX] = useState(50);
  const [y, setY] = useState(50);


  useEffect(() => {
    const textNode = textRef.current;
    textNode && textNode.focus();
  }, []);

  const textRef = React.createRef();

  const [lines, setLines] = useState([
    {
      text: "",
      x: 0,
      y: 0,
    },
  ]);
  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && e.target.nodeName === "INPUT") {
      e.preventDefault();
      // handle your logic for the Enter key in the textarea here
    } else if (e.keyCode === 13 && e.target.nodeName === "TEXT") {
      e.preventDefault();
      // handle your logic for the Enter key in the Text component here
    }
  };

  function handleChange(e) {
    const textValue = e.target.value;
    const newLines = textValue.split("\n").map((line, i) => ({
      text: line,
      x: 50,
      y: 50 + i * (fontSize + 5), // adjust line spacing as desired
    }));
    setLines(newLines);
    setText(textValue);
  }
  useEffect(() => {
    if (sendimage) {
      setThumbnailDataUrl(sendimage);
    }
  }, [sendimage]);

  useEffect(() => {
    // const img = new window.Image();
    // img.src = thumbnailDataUrl;
    // img.onload = () => {
    //   setImage(img);
    //   setImageLoaded(true);
    // };
    const img = new window.Image();
    img.onload = () => {
      setImage(img);
    };
    img.crossOrigin = "Anonymous";
    img.src = thumbnailDataUrl;
  }, [thumbnailDataUrl]);

  Konva.Image.fromURL(image, (img) => {
    Layer.add(img);
  });
  useEffect(() => {
    if (editedImage) {
      const img = new window.Image();
      img.onload = function () {
        if (img.processed) return; // Check if image has already been processed

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const maxWidth = 3000;
        const maxHeight = 3000;

        // Set canvas dimensions based on aspect ratio of the image
        const aspectRatio = img.width / img.height;
        let canvasWidth = maxWidth;
        let canvasHeight = maxHeight;
        if (aspectRatio < 1) {
          canvasHeight = canvasWidth / aspectRatio;
          canvasWidth = canvasHeight * aspectRatio;
        }

        // Set canvas dimensions
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        // Draw image onto canvas
        ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);

        // Get base64-encoded string of resized image
        const resizedBase64 = canvas.toDataURL();

        // Convert the base64-encoded string to a Blob object
        const byteString = atob(resizedBase64.split(",")[1]);
        const mimeString = resizedBase64
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });

        // Create a new File object from the Blob object
        const file = new File([blob], "resized-image.jpg", { type: blob.type });

        // Set the resized image file as the savefile state
        setSaveimage(file);

        img.processed = true; // Set flag to indicate that image has been processed
      };
      img.src = editedImage;
    }
  }, [editedImage]);

  useEffect(() => {
    if (saveImage) {
      var _URL = window.URL || window.webkitURL;
      var image = new window.Image();
      image.src = _URL.createObjectURL(saveImage);
      image.onload = function () {
        console.log(image.naturalWidth);
        console.log(image.naturalHeight);
        if (image.naturalWidth === 3000 && image.naturalHeight === 3000) {
          if (isArtWork) {
            navigate(`/artist/change_artwork/${isId?.id}`);
          }
        } else {
          alert("Please Upload 3000X3000 Dimension Image!");
        }
      };
    }
  }, [saveImage]);

  const handleTextChange = (event) => {
    const newText = event.target.value.replace(/\r?\n/g, "\n");
    setText(newText);
    // setText(event.target.value);
  };
  const [textAlign, setTextAlign] = useState("center");

  const handleFontChange = (event) => {
    setFont(event.target.value);
  };

  const handleColorChange = (event) => {
    setColor(event.target.value);
  };

  useEffect(() => {
    if (sendimage) {
      setImageDownloaded(false);
      setText("");
      setFontSize(20);
      setColor("#000000");
      setIsBold(false);
      setIsItalic(false);
      setX(0);
      setY(0);
    }
  }, [sendimage]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage(new window.Image());
      setImageDownloaded(false);

      const img = new window.Image();
      img.onload = () => {
        setImage(img);
      };
      img.crossOrigin = "Anonymous";
      img.src = reader.result;

      // const img = new window.Image();
      // img.src = reader.result;
      // img.onload = () => {
      //   setImage(img);
      // };
    };
  };
  const handleDownload = () => {
    const stage = stageRef.current;
    const tempCanvas = document.createElement("canvas");
    const tempCtx = tempCanvas.getContext("2d");

    stage.toDataURL({
      pixelRatio: 2,
      callback: (dataURL) => {
        const img = new window.Image();
        img.onload = () => {
          tempCanvas.width = img.width;
          tempCanvas.height = img.height;
          tempCtx.drawImage(img, 0, 0);
          const editedImage = tempCanvas.toDataURL();
          setEditedImage(editedImage);
        };
        img.src = dataURL;
      },
    });
  };

  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);

  const handleBoldClick = () => {
    setIsBold(!isBold);
  };

  const handleItalicClick = () => {
    setIsItalic(!isItalic);
  };

  function handleIncrement() {
    setFontSize(fontSize + 1);
  }

  function handleDecrement() {
    setFontSize(fontSize - 1);
  }
  return (
    <Box sx={{overflowY:"scroll"}}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
         sx={{overflowY:"scroll "}}
      >
    
        <Box className="splash-div" sx={style}>
          <Grid container spacing={2} >
            <Grid item xs={12} lg={7.5} sx={{display:{xs:"none", sm:"block"},alignSelf:"center"}}>
          <Box  sx={{width: '100%', height: '100%'}}>
            {sendimage && (
              <Stage
              style={{width: '100%', height: '100%'}}
                width={550}
                height={490} 
                ref={stageRef}
                // onClick={(e) => console.log(e, "test")}
              >
                <Layer>
                  <Image
                    image={image}
                    x={0}
                    y={0}
                    width={550}
                    height={490}
                    crossOrigin="anonymous"
                  />
                  {/* <textarea
                    value={text}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    style={{ height: "100px" }}
                  /> */}
                  {lines.map((line, i) => (
                    <Text
                      text={text}
                      fontFamily={font}
                      fill={color}
                      fontSize={fontSize}
                      fontStyle={isItalic ? "italic" : "normal"}
                      fontWeight={isBold ? "bold" : "normal"}
                      align={"textAlign"}
                      x={x}
                      y={y}
                      draggable={true}
                      onDragMove={(e) => {
                        setX(e.target.x());
                        setY(e.target.y());
                      }}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                  ))}

                  {/* <Image
                    image={editedImage}
                    visible={imageDownloaded}
                    width={300}
                    height={300}
                    // crossOrigin="anonymous"
                    fillPatternRepeat="no-repeat"
                    fillPatternScaleX={300 / image?.width}
                    fillPatternScaleY={300 / image?.height}
                  /> */}
                </Layer>
              </Stage>
            )}
          </Box>
          </Grid>

          <Grid item xs={12} lg={7.5} sx={{display:{xs:"block", sm:"none"}, justifyContent:"center", alignItems:"center"}}>
          <Box  sx={{width: '100%', height: '100%'}}>
            {sendimage && (
              <Stage
             
                width={300}
                height={250} 
                ref={stageRef}
                onClick={(e) => console.log(e, "test")}
              >
                <Layer>
                  <Image
                    image={image}
                    x={0}
                    y={0}
                    width={300}
                    height={250}
                    crossOrigin="anonymous"
                  />
                  {/* <textarea
                    value={text}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    style={{ height: "100px" }}
                  /> */}
                  {lines.map((line, i) => (
                    <Text
                      text={text}
                      fontFamily={font}
                      fill={color}
                      fontSize={fontSize}
                      fontStyle={isItalic ? "italic" : "normal"}
                      fontWeight={isBold ? "bold" : "normal"}
                      align={"textAlign"}
                      x={x}
                      y={y}
                      draggable={true}
                      onDragMove={(e) => {
                        setX(e.target.x());
                        setY(e.target.y());
                      }}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                  ))}

                  {/* <Image
                    image={editedImage}
                    visible={imageDownloaded}
                    width={300}
                    height={300}
                    // crossOrigin="anonymous"
                    fillPatternRepeat="no-repeat"
                    fillPatternScaleX={300 / image?.width}
                    fillPatternScaleY={300 / image?.height}
                  /> */}
                </Layer>
              </Stage>
            )}
          </Box>
          </Grid>
          <Grid item xs={12} lg={4.5}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 2,ml:{xs:0,lg:1},
              background: " rgba(255, 255, 255, 0.05)",
              borderRadius: "16px !important",
            }}
          >
            <Typography className="splash-text">Font Size</Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              {/* <input
                type="text"
                text="Font Size" className="splash-text-input"
                fontFamily={font}
                // fill="#000000"
                onDragMove={(e) => {
                  setX(e.target.x());
                  setY(e.target.y());
                }}
                fontSize={20}
                align="center"
                x={stageRef.current ? 250 : 0}
                y={stageRef.current ? 5 : 0}
                onChange={(e) => {
                  const parsedInput = parseInt(e.target.value, 10);
                  if (parsedInput) {
                    setFontSize(parsedInput);
                  }
                }}
                placeholder="Enter Font-Size"
                style={{ padding: "5px", marginRight: "5px", width: "80px" }}
              />
              <Typography
                variant="body1" className="splash-text"
              >
                px
              </Typography> */}
              <Box sx={{ display: "flex" }}>
                <Box
                  className="fontBtn"
                  sx={{ mr: 1 }}
                  onClick={handleDecrement}
                >
                  <AiOutlineMinus />
                </Box>
                <input
                  type="number"
                  className="splash-text-input"
                  value={fontSize}
                  onChange={(e) => setFontSize(parseInt(e.target.value))}
                  style={{ width: "50px", textAlign: "center" }}
                />
                <Box
                  className="fontBtn"
                  sx={{ ml: 1 }}
                  onClick={handleIncrement}
                >
                  <AiOutlinePlus />
                </Box>
              </Box>
            </div>
            <div
              style={{
                marginBottom: "10px",
              }}
            >
              <Typography className="splash-text">Font Family</Typography>
              <select
                value={font}
                onChange={handleFontChange}
                className="splash-text-input"
              >
                <option value="Arial">Arial</option>
                <option value="Calibri">Calibri</option>
                <option value="Comic Sans MS">Comic Sans MS</option>
                <option value="Georgia">Georgia</option>
                <option value="Tahoma">Tahoma</option>
                <option value="Times New Roman">Times New Roman</option>
                <option value="Courier New">Courier New</option>
              </select>
            </div>
            <div style={{ marginBottom: "10px" }}>
              {/* <input
                // type="text"
                // placeholder="Enter Text"
                // value={text}
                // onChange={handleTextChange}
                // onKeyDown={handleKeyDown}
                style={{ padding: "5px", width: "300px" }}
              /> */}
              <textarea
                id="message"
                name="message"
                rows="8"
                cols="33"
                className="splash-text-area"
                type="text"
                placeholder="Enter Text"
                value={text}
                onChange={handleTextChange}
                onKeyDown={handleKeyDown}
                style={{ marginTop: "7px" }}
              ></textarea>
            </div>
            <div>
              <label
                htmlFor="color-input"
                className="splash-text"
                style={{ marginRight: "15px" }}
              >
                Color:
              </label>
              <input
                type="color"
                id="color-input"
                value={color}
                onChange={handleColorChange}
                className="splash-text-input"
              />
            </div>
            <Box sx={{ mt: 1.35 }}>
              <IconButton onClick={handleBoldClick}>
                <FormatBold style={{ color: isBold ? "#f9a40d" : "grey" }} />
              </IconButton>
              <IconButton onClick={handleItalicClick}>
                <FormatItalic
                  style={{ color: isItalic ? "#f9a40d" : "grey" }}
                />
              </IconButton>
            </Box>
            <Stack alignItems={"end"}>
              <Button onClick={handleDownload} className="splash-submit">
                Submit
              </Button>
            </Stack>
          </Box>
          </Grid>
          </Grid>

        </Box>


      </Modal>
    </Box>
  );
}

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookMessengerShareButton,
} from "react-share";
import {
  WhatsappIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ handleOpen, handleClose, open, data }) {
  const royalTitle = `delivermytune.com/creative-professionals/${data?.slug}`;
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ textAlign: "center !important" }}>
            <WhatsappShareButton url={" "} title={royalTitle}>
              <WhatsappIcon
                size={40}
                className=""
                logofillcolor="white"
                round={true}
              ></WhatsappIcon>
            </WhatsappShareButton>
            {/* <FacebookMessengerShareButton
                  className="share-icons"
                  url={royalTitle}
                  //   title={royalTitle}
                >
                  <FacebookIcon
                    onClick={handleiconclick}
                    size={40}
                    className="m-2"
                    logofillcolor="white"
                    round={true}
                  ></FacebookIcon>
                </FacebookMessengerShareButton> */}
            <FacebookShareButton
              className="share-icons"
              url={" "}
              quote={royalTitle}
              // title={"test its me"}
            >
              <FacebookIcon
                size={40}
                className="m-2"
                logofillcolor="white"
                round={true}
              ></FacebookIcon>
            </FacebookShareButton>

            {/* <LinkedinShareButton
                  className="share-icons"
                  url={" "}
                  title={royalTitle}
                >
                  <LinkedinIcon
                    onClick={handleiconclick}
                    size={40}
                    className="m-2"
                    logofillcolor="white"
                    round={true}
                  ></LinkedinIcon>
                </LinkedinShareButton> */}
            <TwitterShareButton
              className="share-icons"
              url={" "}
              title={royalTitle}
            >
              <TwitterIcon
                size={40}
                className="m-2"
                logofillcolor="white"
                round={true}
              ></TwitterIcon>
            </TwitterShareButton>

            <EmailShareButton
              className="share-icons"
              url={" "}
              title={royalTitle}
            >
              <EmailIcon
                size={40}
                className="m-2"
                logofillcolor="white"
                round={true}
              ></EmailIcon>
            </EmailShareButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

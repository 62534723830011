import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import { useRef } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CustomButton from "../editButton";
import AddIcon from "@mui/icons-material/Add";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import Blind from "../../../assets/img/album-thumbnail-one.svg";
import Animal from "../../../assets/img/album-thumbnail-two.svg";
import Paint from "../../../assets/img/album-thumbnail-three.svg";
import Diamond from "../../../assets/img/album-thumbnail-four.svg";
import Bar from "../../../assets/img/bar.svg";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import ListenForm from "./listenForm";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import ClearIcon from "@mui/icons-material/Clear";
import "./style.css";

function PaperComponent(props) {
  return <Paper {...props} />;
}

const Student_Data = [
  {
    id: 1,
    listenimage: Blind,
    listentitle: "Blinding Lights",
    listensinger: "Arlo Brown Ft. Sza",
    views: "4.1K",
  },

  {
    id: 2,
    listenimage: Animal,
    listentitle: "Blinding Lights",
    listensinger: "Arlo Brown Ft. Sza",
    views: "4.1K",
  },
  {
    id: 3,
    listenimage: Animal,
    listentitle: "Blinding Lights",
    listensinger: "Arlo Brown Ft. Sza",
    views: "4.1K",
  },
  {
    id: 4,
    listenimage: Animal,
    listentitle: "Blinding Lights",
    listensinger: "Arlo Brown Ft. Sza",
    views: "4.1K",
  },
  {
    id: 5,
    listenimage: Animal,
    listentitle: "Blinding Lights",
    listensinger: "Arlo Brown Ft. Sza",
    views: "4.1K",
  },
];

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "green" }}
      onClick={onClick}
    />
  );
}

const Listen = ({ value, data, getOrder, tokenedit }) => {
  const [pics, setPics] = useState([]);

  const removeImage = (id) => {
    setPics((oldState) => oldState.filter((item) => item.id !== id));
  };

  useEffect(() => {
    setPics(Student_Data);
  }, []);
  const [finddata, setfinddata] = useState();
  setTimeout(() => {
    setfinddata(sliderRef);
  }, 500);
  const [viewmodal, setViewmodal] = useState([]);
  const [number, setNumber] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (value) => {
    setOpen(true);
    setViewmodal(value);
  };
  const handleClose = () => setOpen(false);
  const sliderRef = useRef(null);

  const datavalue = data?.listen.length + data?.tracks?.length;
  const slidesToShow = datavalue < 4 ? datavalue : 4;
  // const slidedatafile=data?.tracks?.length < 4 ? data?.tracks.length: 4;

  const settings = {
    fade: false,
    dots: false,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    initialSlide: 4,
    // centerMode: true,
    infinite: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: false,

    responsive: [
      {
        breakpoint: 2040,
        settings: {
          slidesToShow: slidesToShow > 4 ? slidesToShow + 0.25 : slidesToShow,
          slidesToScroll: 1,
          initialSlide: 4,
          // centerMode: true,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1740,
        settings: {
          slidesToShow: slidesToShow > 4 ? slidesToShow + 0.12 : slidesToShow,
          slidesToScroll: 1,
          initialSlide: 4,
          // centerMode: true,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1430,
        settings: {
          slidesToShow: slidesToShow > 3 ? slidesToShow + 0.12 : slidesToShow,
          slidesToScroll: 1,
          initialSlide: 3,
          centerMode: false,
        },
      },
      {
        breakpoint: 1320,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
          centerMode: false,
        },
      },

      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div>
      <Container
        sx={{
          maxWidth: "90% !important",
          marginTop: { md: "60px", xs: "0px" },
        }}
      >
        <Box
          id="listen"
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: { md: "12px !important", xs: "0px !important" },
          }}
        >
          <Typography
            variant="h3"
            className="contact-data"
            sx={{ fontSize: { md: "48px", xs: "32px" } }}
          >
            Listen
          </Typography>
          <Box
            sx={{
              marginLeft: "20px",
              marginTop: { lg: "15px", md: "15px", xs: "0px" },
            }}
          >
            {tokenedit ? (
              <CustomButton
                btnText="Add"
                handleClick={() => {
                  handleOpen();
                  setNumber(0);
                }}
                startIcon={<AddIcon sx={{ marginRight: "5px" }} />}
                width="75px"
                height="30px"
                border="1px solid #ffffff !important"
                padding="15px"
              />
            ) : (
              ""
            )}
          </Box>
        </Box>
        {datavalue >= 3 ? (
          <Grid container rowSpacing={3}>
            <Grid
              item
              xs={12}
              sx={{ textAlign: "center", padding: "0px !important" }}
            >
              <Box>
                <Button
                  onClick={() => sliderRef.current.slickPrev()}
                  sx={{
                    border: { md: "1px solid #ffffff", xs: "none" },
                    padding: "4px",
                    borderRadius: "50%",
                    minWidth: "56px !important",
                    height: "56px",
                    mx: 1,
                    color: "#1B1B1B",
                    position: "absolute",
                    left: { lg: "80px", md: "60px", sm: "60px", xs: "-1%" },
                    marginTop: { lg: "200px", xs: "250px" },
                    zIndex: "1111",
                  }}
                >
                  <ArrowBackIosIcon
                    sx={{ color: "#ffffff !important" }}
                    className="arrow"
                  />
                </Button>
                <Button
                  onClick={() => sliderRef.current.slickNext()}
                  sx={{
                    border: { md: "1px solid #ffffff", xs: "none" },
                    padding: "4px",
                    borderRadius: "50%",
                    minWidth: "56px !important",
                    height: "56px",
                    color: "#1B1B1B",
                    position: "absolute",
                    right: { lg: "80px", md: "60px", sm: "60px", xs: "1%" },
                    marginTop: { lg: "200px", xs: "250px" },
                    zIndex: "1111",
                  }}
                >
                  <ArrowForwardIosIcon
                    sx={{ color: "#ffffff !important" }}
                    className="arrow"
                  />
                </Button>
              </Box>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        <Box
          sx={{
            position: "relative",
            margin: { xs: "10px auto !important", md: "30px auto" },
          }}
        >
          {datavalue === 0 ? (
            <>
              <Box
                sx={{
                  margin: "30px 0px",
                  padding: "0px !important",
                }}
              >
                {tokenedit ? (
                  <Box
                    sx={{
                      border: "2px dashed white",
                      width: { md: "760px", xs: "280px" },
                      height: "210px !important",
                      textAlign: "center",
                      margin: "auto",
                    }}
                    onClick={() => {
                      handleOpen();
                      setNumber(0);
                    }}
                  >
                    <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                      <Typography>+</Typography>
                      <Typography sx={{  fontFamily:'DM Sans !important'}}>Add Your First Song Here</Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      border: "2px dashed white",
                      width: { md: "760px", xs: "280px" },
                      height: "210px !important",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  >
                    <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                      {/* <Typography>+</Typography> */}
                      <Typography sx={{   fontFamily:'DM Sans !important'}}> Song is Empty</Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <Slider ref={finddata} {...settings}>
              {data?.tracks?.map((value, index) => (
                <Box
                  key={index}
                  sx={{
                    margin: "30px 0px",
                    padding: "0px !important",
                  }}
                >
                  <Card
                    sx={{
                      position: "relative",
                      maxWidth: "270px",
                      padding: "20px !important",
                      height: "100%",
                      margin: "auto !important",
                      borderRadius: "15px",
                      backgroundColor: "#1e1e1e",
                    }}
                  >
                    <img
                      src={value?.cover}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                    {/* {tokenedit ? (
                        <Box
                          sx={{
                            border: "1px solid #ffffff",
                            width: "52px",
                            height: "30px",
                            borderRadius: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            top: "33px",
                            right: "10%",
                          }}
                          onClick={() => {
                            handleOpen(value);
                            setNumber(1);
                          }}
                        >
                          <ModeEditOutlinedIcon />
                        </Box>
                      ) : (
                        ""
                      )} */}

                    <Typography
                      component="div"
                      sx={{
                        fontSize: {
                          xs: "13px",
                          sm: "16px",
                        },
                        fontWeight: 500,
                        lineHeight: {
                          xs: "15px",
                          sm: "21px",
                        },
                        color: "#f0f0f0",
                        paddingTop: "10px",
                        marginBottom: "5px",
                        fontDamily: "DM Sans !important",
                      }}
                    >
                      {value?.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "rgba(240, 240, 240, 0.8)",
                        marginBottom: "33px",
                        fontDamily: "DM Sans !important",
                      }}
                    >
                      {value?.tracks.length > 0
                        ? value?.tracks[0]?.artists[0]?.lastname
                          ? value?.tracks[0]?.artists[0]?.name +
                            " " +
                            value?.tracks[0]?.artists[0]?.lastname
                          : value?.tracks[0]?.artists[0]?.name
                        : ""}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <audio controls style={{}}>
                        <source
                          src={
                            value?.tracks.length > 0
                              ? value?.tracks[0]?.file
                              : ""
                          }
                          type="audio/wav"
                        />
                      </audio>
                    </Box>
                  </Card>
                </Box>
              ))}

              {data?.listen?.map((value, index) => (
                <Box
                  key={index}
                  sx={{
                    margin: "30px 0px",
                    padding: "0px !important",
                  }}
                >
                  <Card
                    sx={{
                      position: "relative",
                      maxWidth: "270px",
                      padding: "20px !important",
                      height: "100%",
                      margin: "auto !important",
                      borderRadius: "15px",
                      backgroundColor: "#1e1e1e",
                    }}
                  >
                    <img
                      src={value?.listenimage}
                      alt=""
                      className="listenimage"
                      style={{ width: "100%", height: "226px !important" }}
                    />
                    {tokenedit ? (
                      <Box
                        sx={{
                          border: "1px solid #ffffff",
                          width: "52px",
                          height: "30px",
                          borderRadius: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          top: "33px",
                          right: "10%",
                        }}
                        onClick={() => {
                          handleOpen(value);
                          setNumber(1);
                        }}
                      >
                        <ModeEditOutlinedIcon />
                      </Box>
                    ) : (
                      ""
                    )}

                    <Typography
                   
                      sx={{
                        fontSize: {
                          xs: "13px",
                          sm: "16px",
                        },
                        fontWeight: 500,
                        lineHeight: {
                          xs: "15px",
                          sm: "21px",
                        },
                        color: "#f0f0f0",
                        paddingTop: "10px",
                        marginBottom: "5px",
                        fontDamily: "DM Sans !important",
                      }}
                    >
                      {value?.listentitle}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "rgba(240, 240, 240, 0.8)",
                        marginBottom: "33px",
                        fontDamily: "DM Sans !important",
                      }}
                    >
                      {value?.listensinger}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* <PlayArrowIcon
                      fontSize="large"
                      sx={{ marginLeft: "-5px" }}
                    /> */}
                      {/* <img src={Bar} alt="" /> */}
                      <audio controls style={{}}>
                        <source src={value?.listensong} type="audio/wav" />
                      </audio>
                      {/* <Box sx={{ display: "flex" }}>
                      <Typography sx={{ marginRight: "8px" }}>4.1K</Typography>
                      <HeadphonesIcon />
                    </Box> */}
                    </Box>
                    {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      maxWidth: { md: "70%", xs: "100%" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "8px",
                        lineHeight: "10px",
                        color: "rgba(255, 255, 255, 0.6)",
                      }}
                    >
                      0:05
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "8px",
                        lineHeight: "10px",
                        color: "rgba(255, 255, 255, 0.6)",
                      }}
                    >
                      7:56 PM
                    </Typography>
                  </Box> */}
                  </Card>
                </Box>
              ))}
            </Slider>
          )}
        </Box>
      </Container>
      <div>
        <Dialog
          height="auto"
          style={{ zIndex: "9999922" }}
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <Box textAlign="end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <DialogTitle
              style={{ cursor: "move" }}
              align="center"
              mt="-50px"
              id="draggable-dialog-title"
              fontFamily='DM Sans !important'
            >
              Listen
            </DialogTitle>
          </Box>

          <ListenForm
            view={viewmodal}
            handleClose={handleClose}
            getOrder={getOrder}
            number={number}
            tokenedit={tokenedit}
          />
        </Dialog>
      </div>
    </div>
  );
};

export default Listen;

import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/common/customButton";
import "./style.css";

const Index = ({ data, getOrder }) => {
  const [buttonColor, setButtonColor] = useState("");
  const [buttonColor1, setButtonColor1] = useState("");
  const [buttonColor2, setButtonColor2] = useState("");
  const [buttonColor3, setButtonColor3] = useState("");
  const [buttonColor4, setButtonColor4] = useState("");

  const handleClick = () => {
    setButtonColor("#f9a40d");
    setButtonColor4("");
    setButtonColor3("");
    setButtonColor1("");
    setButtonColor2("");
  };
  const handleClick1 = () => {
    setButtonColor1("#f9a40d");
    setButtonColor4("");
    setButtonColor3("");
    setButtonColor2("");
    setButtonColor("");
  };
  const handleClick2 = () => {
    setButtonColor2("#f9a40d");
    setButtonColor4("");
    setButtonColor3("");
    setButtonColor1("");
    setButtonColor("");
  };
  const handleClick3 = () => {
    setButtonColor3("#f9a40d");
    setButtonColor4("");
    setButtonColor2("");
    setButtonColor1("");
    setButtonColor("");
  };
  const handleClick4 = () => {
    setButtonColor4("#f9a40d");
    setButtonColor3("");
    setButtonColor2("");
    setButtonColor1("");
    setButtonColor("");
  };
  return (
    <>
      <Box
        className="deshboard-view"
        sx={{
          display: { md: "flex !important" },
          justifyContent: "center !important",
          marginTop: { md: "10px", xs: "0px" },
          marginBottom: { md: "25px", xs: "0px" },
        }}
      >
        <a href="#listen">
          <Button
            className="button-nav"
            sx={{
              marginTop: { md: "30px", xs: "0px" },
              marginLeft: "15px",
              color: "white",
              fontSize: { md: "16px", xs: "12px" },
              fontWeight: 700,
              fontFamily: "DM Sans",
              textTransform: "capitalize",
              backgroundColor: buttonColor,
            }}
            onClick={handleClick}
          >
            Listen ({data?.listen?.length + data?.tracks?.length})
          </Button>
        </a>
        <a href="#video">
          <Button
            className="button-nav"
            sx={{
              marginTop: { md: "30px", xs: "0px" },
              marginLeft: "15px",
              color: "white",
              fontSize: { md: "16px", xs: "12px" },
              fontWeight: 700,
              fontFamily: "DM Sans",
              textTransform: "capitalize",
              backgroundColor: buttonColor1,
            }}
            onClick={handleClick1}
          >
            Videos ({data?.videos?.length})
          </Button>
        </a>
        <a href="#Event">
          <Button
            className="button-nav"
            sx={{
              marginTop: { md: "30px", xs: "0px" },
              marginLeft: "15px",
              color: "white",
              fontSize: { md: "16px", xs: "12px" },
              fontWeight: 700,
              fontFamily: "DM Sans",
              textTransform: "capitalize",
              backgroundColor: buttonColor2,
            }}
            onClick={handleClick2}
          >
            Events ({data?.events?.length})
          </Button>
        </a>
        <a href="#gallary">
          <Button
            className="button-nav"
            sx={{
              marginTop: { md: "30px", xs: "0px" },
              marginLeft: "15px",
              color: "white",
              fontSize: { md: "16px", xs: "12px" },
              fontWeight: 700,
              fontFamily: "DM Sans",
              textTransform: "capitalize",
              backgroundColor: buttonColor3,
            }}
            onClick={handleClick3}
          >
            Gallery ({data?.gallary?.length})
          </Button>
        </a>
        <a href="#contact">
          <Button
            className="button-nav"
            sx={{
              marginTop: { md: "30px", xs: "0px" },
              marginLeft: "15px",
              color: "white",
              fontSize: { md: "16px", xs: "12px" },
              fontWeight: 700,
              fontFamily: "DM Sans",
              textTransform: "capitalize",
              backgroundColor: buttonColor4,
            }}
            onClick={handleClick4}
          >
            Contact ({data?.contact?.length})
          </Button>
        </a>
      </Box>
      <Grid container className="mobile-view-contact">
        <Grid item xs={4} md={3} sx={{ textAlign: "-webkit-center" }}>
          <a href="#listen">
            <Button
              className="button-nav"
              sx={{
                color: "white",
                fontSize: { md: "16px", xs: "12px" },
                fontWeight: 700,
                fontFamily: "DM Sans",
                textTransform: "capitalize",
              }}
            >
              Listen 7
            </Button>
          </a>
        </Grid>
        <Grid item xs={4} md={3} sx={{ textAlign: "-webkit-center" }}>
          <a href="#video">
            <Button
              className="button-nav"
              sx={{
                color: "white",
                fontSize: { md: "16px", xs: "12px" },
                fontWeight: 700,
                fontFamily: "DM Sans",
                textTransform: "capitalize",
              }}
            >
              Videos 12
            </Button>
          </a>
        </Grid>
        <Grid item xs={4} md={3} sx={{ textAlign: "-webkit-center" }}>
          <a href="#Event">
            <Button
              className="button-nav"
              sx={{
                color: "white",
                fontSize: { md: "16px", xs: "12px" },
                fontWeight: 700,
                fontFamily: "DM Sans",
                textTransform: "capitalize",
              }}
            >
              Events 3
            </Button>
          </a>
        </Grid>
      </Grid>
      <Grid container className="mobile-view-contact">
        <Grid item xs={4} md={3} sx={{ textAlign: "-webkit-center" }}>
          <a href="#gallary">
            <Button
              className="button-nav"
              sx={{
                color: "white",
                fontSize: { md: "16px", xs: "12px" },
                fontWeight: 700,
                fontFamily: "DM Sans",
                textTransform: "capitalize",
              }}
            >
              Gallery 5
            </Button>
          </a>
        </Grid>
        <Grid item xs={4} md={3} sx={{ textAlign: "-webkit-center" }}>
          <a href="#contact">
            <Button
              className="button-nav"
              sx={{
                color: "white",
                fontSize: { md: "16px", xs: "12px" },
                fontWeight: 700,
                fontFamily: "DM Sans",
                textTransform: "capitalize",
              }}
            >
              Contact
            </Button>
          </a>
        </Grid>
      </Grid>
    </>
  );
};

export default Index;

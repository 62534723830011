import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Play from "../../assets/img/play.png";
import CustomButton from "../../components/common/customButton/index";
import axios from "axios";

const Index = ({ songValue, setSongValue, career, firstslidedata,songprocessing,setSongprocessing }) => {
  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [internetSpeed, setInternetSpeed] = useState(0);
  const [totalUploadPercent, setTotalUploadPercent] = useState(0);

  const { getRootProps, getInputProps, open, open1, acceptedFiles } =
    useDropzone({
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      accept: {
        "audio/.wav": [".wav"],
      },
    });

    useEffect(() => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setSongValue(file);
        let percentCompleted;
        const handleUpload = async () => {
          setUploadPercentage(0);
          setSongprocessing(true);
          const formData = new FormData();
          formData.append("track", file);
    
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_DMT_API_PATH}/orders/upload-song`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${token}`,
                },
                onUploadProgress: (progressEvent) => {
                  const fileSizeInBytes = file.size;
                  const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
                  const uploadedSizeInMB = progressEvent.loaded / (1024 * 1024);
                   percentCompleted =
                    (uploadedSizeInMB / fileSizeInMB) * 100;
                  const elapsedSeconds = progressEvent.timeStamp / 1000;
                  const uploadingSpeedInMBps =
                    uploadedSizeInMB / elapsedSeconds;
    
                  setInternetSpeed(Math.floor(uploadingSpeedInMBps));
                  setTotalUploadPercent(Math.floor(percentCompleted));
    
                  
                },
              }
            );
            console.log(percentCompleted)
            if (percentCompleted === 99 && !response.data) {
              setUploadPercentage(99);
            }
console.log(response.data,"response.data")
            if (response.data) {
              console.log("Upload success:", response.data.url);
              setSongValue(response.data.url);
              setUploadPercentage(100);
            }
            console.log(response);
          } catch (error) {
            console.error("Upload error:", error);
            // Handle error
          }
        };
    
        handleUpload();
      }
    }, [acceptedFiles]);
    

  // const handleMultipleUpload = async () => {
  //   if (values?.video?.length > 0) {
  //     let counter = 0;
  //     let multiple = "";

  //     for (let evvv of values.video) {
  //       const extension = getFileExtension(evvv.name);
  //       const filesize = getFileSize(evvv);
  //       setFilesize(filesize);
  //       setUploadStart(true);
  //       const startTime = new Date().getTime();
  //       let bytesUploaded = 0;
  //       const key = `${decryptin.folder.shorts}/${uuidv4()}.${extension}`;
  //       const fileuploads3 = s3uploadFile(key, evvv, decryptin);
  //       fileuploads3.on("httpUploadProgress", (progress) => {
  //         setFileProgress(Math.round((progress.loaded / progress.total) * 100));
  //         bytesUploaded = progress.loaded;
  //         const elapsedTime = new Date().getTime() - startTime;
  //         const uploadSpeed = (bytesUploaded / elapsedTime) * 1000;
  //         setuploadspeed(Math.ceil(uploadSpeed / 1000000));
  //       });
  //       await fileuploads3.done().then((evv) => {
  //         counter += 1;
  //         multiple = evv.Key;
  //         setCompleteFile(counter);
  //       });
  //     }
  //   } else if (values?.video?.length > 0) {
  //     multiple = values?.video[0];
  //   }
  // };
const [status, setStatus] = useState("");
  useEffect(() => {
    if (uploadPercentage === 100) {
      setStatus("Uploading... 100%");
      
      setTimeout(() => {
        setStatus("Upload Complete");
        setSongprocessing(false);
      }, 1000);
    } else {
      setStatus(`Uploading... ${uploadPercentage}%`);

    }
  }, [uploadPercentage]);

  return (
    <div>
      <Box
        sx={{
          background: "#292929",
          borderRadius: "16px",
          width: "100%",
        }}
        {...getRootProps()}
        onClick={open}
      >
        <Box
          textAlign="center"
          sx={{ py: "20px", px: "20px", overflow: "hidden" }}
        >
          <input {...getInputProps()} />
          <Box>
            <img src={Play} alt="play-icon" />
          </Box>
          <CustomButton
            btnText={songValue ? "Change Song" : "Upload Song"}
            buttonStyle="basic-btn"
            marginTop="10px"
          />

          <Typography
            sx={{
              listStyle: "none",
              mt: "17px !important",
              mb: "17px !important",
              wordBreak: "break-all",
            }}
          >
            { songValue?.path || songValue}
          </Typography>
          {totalUploadPercent > 0 && (
            <Typography
              sx={{
                listStyle: "none",
                mt: "17px !important",
                mb: "17px !important",
                wordBreak: "break-all",
              }}
            >
              {uploadPercentage === 100
                ? status
                : `Uploading... ${totalUploadPercent===100?99:totalUploadPercent}%`}
            </Typography>
          )}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography className="quality">
              Quality is important to us, please use the highest-quality stereo
              assets available (WAV, AIFF, or FLAC only).
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Index;



// import { Box, Typography } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { useDropzone } from "react-dropzone";
// import Play from "../../assets/img/play.png";
// import CustomButton from "../../components/common/customButton/index";
// import axios from "axios";
// import { getFileSize } from "./filesize";
// import getFileExtension from "./fileextension";
// import { decrypt } from "./crypto";
// import { v4 as uuidv4 } from "uuid";
// import { s3uploadFile } from "./aws";

// const Index = ({ songValue, setSongValue, career, firstslidedata }) => {
//   const loginToken = JSON.parse(localStorage.getItem("login"));
//   const token = loginToken?.token;
//   const config = {
//     headers: { Authorization: `Bearer ${token}` },
//   };
//   let counter = 0;
//   const [fileProgress, setFileProgress] = useState(0);
//   const [uploadspeed, setuploadspeed] = useState(0);
//   const [uploadPercentage, setUploadPercentage] = useState(0);
//   const [filesize,setFilesize]=useState();
//   const [uploadStart, setUploadStart] = useState(false);
//   const [completefile, setCompleteFile] = useState(0);
//   const [internetSpeed, setInternetSpeed] = useState(0);
//   const [totalUploadPercent, setTotalUploadPercent] = useState(0);
//   // const { userinfo } = useSelector((state) => state.userinfo);
//   const decryptin = decrypt(loginToken.user);
//   const { getRootProps, getInputProps, open, open1, acceptedFiles } =
//     useDropzone({
//       // Disable click and keydown behavior
//       noClick: true,
//       noKeyboard: true,
//       accept: {
//         "audio/.wav": [".wav"],
//       },
//     });
//     const multiple = [];
    
    
//     useEffect(() => {
//       if (acceptedFiles.length > 0) {
//         const evvv = acceptedFiles[0];
//         setSongValue(evvv);
    
//         const handleUpload = async () => {
//           const extension = getFileExtension(evvv.name);
//           const filesize = getFileSize(evvv);
//           setFilesize(filesize);
//           setUploadStart(true);
//           const startTime = new Date().getTime();
//           let bytesUploaded = 0;
//           console.log(decryptin, "decryptin");
//           let decryptinValue = decryptin || ''; // Assign an empty string as default if decryptin is undefined
//   const key = `${decryptinValue}/${uuidv4()}.${extension}`;
//           const fileuploads3 = s3uploadFile(key, evvv, decryptin);
//           fileuploads3.on("httpUploadProgress", (progress) => {
//             setFileProgress(Math.round((progress.loaded / progress.total) * 100));
//             bytesUploaded = progress.loaded;
//             const elapsedTime = new Date().getTime() - startTime;
//             const uploadSpeed = (bytesUploaded / elapsedTime) * 1000;
//             setuploadspeed(Math.ceil(uploadSpeed / 1000000));
//           });
//           await fileuploads3.done().then((evv) => {
//             counter += 1;
//             multiple = evv.Key;
//             setCompleteFile(counter);
//           });
    
//           const formData = new FormData();
//           formData.append("track", evvv);
    
//           let response; // Define response variable
    
//           try {
//             response = await axios.post(
//               `${process.env.REACT_APP_DMT_API_PATH}/orders/upload-song`,
//               formData,
//               {
//                 headers: {
//                   "Content-Type": "multipart/form-data",
//                   Authorization: `Bearer ${token}`,
//                 },
//                 onUploadProgress: (progressEvent) => {
//                   const fileSizeInBytes = evvv.size;
//                   const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
//                   const uploadedSizeInMB = progressEvent.loaded / (1024 * 1024);
//                   const percentCompleted = (uploadedSizeInMB / fileSizeInMB) * 100;
//                   const elapsedSeconds = progressEvent.timeStamp / 1000;
//                   const uploadingSpeedInMBps = uploadedSizeInMB / elapsedSeconds;
    
//                   setInternetSpeed(Math.floor(uploadingSpeedInMBps));
//                   setTotalUploadPercent(Math.floor(percentCompleted));
    
//                   if (percentCompleted >= 99 && !response?.data) {
//                     // Assuming response is not yet received
//                     setUploadPercentage(99);
//                   }
    
//                   if (percentCompleted === 100) {
//                     console.log("Upload success:", response.data.url);
//                     setSongValue(response.data.url);
//                     setUploadPercentage(100);
//                   }
//                 },
//               }
//             );
//           } catch (error) {
//             console.error("Upload error:", error);
//             // Handle error
//           }
//         };
    
//         handleUpload();
//       }
//     }, [acceptedFiles]);
    

//   // const handleMultipleUpload = async () => {
//   //   if (values?.video?.length > 0) {
//   //     let counter = 0;
//   //     let multiple = "";

//   //     for (let evvv of values.video) {
//       //   const extension = getFileExtension(evvv.name);
//       //   const filesize = getFileSize(evvv);
//       //   setFilesize(filesize);
//       //   setUploadStart(true);
//       //   const startTime = new Date().getTime();
//       //   let bytesUploaded = 0;
//       //   const key = `${decryptin.folder.shorts}/${uuidv4()}.${extension}`;
//       //   const fileuploads3 = s3uploadFile(key, evvv, decryptin);
//       //   fileuploads3.on("httpUploadProgress", (progress) => {
//       //     setFileProgress(Math.round((progress.loaded / progress.total) * 100));
//       //     bytesUploaded = progress.loaded;
//       //     const elapsedTime = new Date().getTime() - startTime;
//       //     const uploadSpeed = (bytesUploaded / elapsedTime) * 1000;
//       //     setuploadspeed(Math.ceil(uploadSpeed / 1000000));
//       //   });
//       //   await fileuploads3.done().then((evv) => {
//       //     counter += 1;
//       //     multiple = evv.Key;
//       //     setCompleteFile(counter);
//       //   });
//       // }
//   //   } else if (values?.video?.length > 0) {
//   //     multiple = values?.video[0];
//   //   }
//   // };

//   return (
//     <div>
//       <Box
//         sx={{
//           background: "#292929",
//           borderRadius: "16px",
//           width: "100%",
//         }}
//         {...getRootProps()}
//         onClick={open}
//       >
//         <Box
//           textAlign="center"
//           sx={{ py: "20px", px: "20px", overflow: "hidden" }}
//         >
//           <input {...getInputProps()} />
//           <Box>
//             <img src={Play} alt="play-icon" />
//           </Box>
//           <CustomButton
//             btnText={songValue ? "Change Song" : "Upload Song"}
//             buttonStyle="basic-btn"
//             marginTop="10px"
//           />

//           <Typography
//             sx={{
//               listStyle: "none",
//               mt: "17px !important",
//               mb: "17px !important",
//               wordBreak: "break-all",
//             }}
//           >
//             {songValue?.path || songValue}
//           </Typography>
//           {totalUploadPercent > 0 && (
//             <Typography
//               sx={{
//                 listStyle: "none",
//                 mt: "17px !important",
//                 mb: "17px !important",
//                 wordBreak: "break-all",
//               }}
//             >
//               {totalUploadPercent === 100
//                 ? "Upload Complete"
//                 : `Uploading... ${totalUploadPercent}%`}
//             </Typography>
//           )}
//           {internetSpeed > 0 && (
//             <Typography
//               sx={{
//                 listStyle: "none",
//                 mt: "17px !important",
//                 mb: "17px !important",
//                 wordBreak: "break-all",
//               }}
//             >
//               Internet Speed: {internetSpeed.toFixed(2)} Mbps
//             </Typography>
//           )}
//           <Box sx={{ display: "flex", justifyContent: "center" }}>
//             <Typography className="quality">
//               Quality is important to us, please use the highest-quality stereo
//               assets available (WAV, AIFF, or FLAC only).
//             </Typography>
//           </Box>
//         </Box>
//       </Box>
//     </div>
//   );
// };

// export default Index;

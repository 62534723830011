import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import "./style.css";
import Typography from "@mui/material/Typography";
import CustomButton from "../../components/common/customButton/index";
import MangeDeshboard from "../../assets/img/static/song.PNG";

const Index = () => {
  return (
    <Container sx={{ maxWidth:'1200px !important'}}>
      <Grid
        container
        spacing={{ md:5,lg: 12, xs: 4 }}
        sx={{ marginTop: { md: "40px", xs: "0px" }, marginBottom: "60px" }}
      >
        <Grid item md={7} xs={12} sx={{px:{xs:"auto !important",sm:'100px !important',md:'0px !important'}}}>
          <Box
            className="manageimgBoxaboutusp"
            sx={{ marginTop: { xs: "10px", md: "none" } ,}}
          >
            <img
              src={MangeDeshboard}
              alt="image"
              style={{ width: "100%", height: "100%", marginTop: "30px" }}
            />
          </Box>
        </Grid>
        <Grid item md={5} xs={12} >
          <Box sx={{ marginTop: "40px" }}>
            <Typography variant="p" className="homemanageBoldTextusp">
              USP Of Our Product
            </Typography>
          </Box>
          <Box>
            <Box display="flex" justifyContent="start" mt={2}>
              <Box
                className="usp-li-dot-usp"
                mt={{xs:2.5,md:1}}
                sx={{ marginRight: "15px" }}
              ></Box>
              <Box>
                <Typography
                  variant="p"
                  className="homemanageNormalTextaboutusp"
                >
                  Keep 100% of your CopyRights and Control
                </Typography>
              </Box>
            </Box>

            <Box display="flex" justifyContent="start" mt={2}>
              {/* <Box> */}
              <Box className="usp-li-dot-usp" mt={{xs:2.5,md:1}}  sx={{ marginRight: "15px" }}></Box>
              {/* </Box> */}

              <Box>
                <Typography
                  variant="p"
                  className="homemanageNormalTextaboutusp"
                >
                  Artificial intelligence for music mastering
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="start" mt={2}>
              {/* <Box> */}
              <Box className="usp-li-dot-usp" mt={{xs:2.5,md:1}}  sx={{ marginRight: "15px" }} ></Box>
              {/* </Box> */}

              <Box justifyContent="center">
                <Typography
                  variant="p"
                  className="homemanageNormalTextaboutusp"
                >
                  Deliver your music to digital stores within 48 hours
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <CustomButton
              marginTop="35px"
              width="189px"
              height="54px"
              borderRadius="27px"
              border="1.35px solid #f36905!important;"
              fontWeight="700"
              btnText="Sign up for free"
              buttonStyle='light-theme-btn'
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Index;

import React from "react";
import {
  Box,
  Card,
  Container,
  Dialog,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import admireArtist from "../../../assets/img/admireArtist.png";
import roundMusic from "../../../assets/img/roundMusic.png";
import pendent from "../../../assets/img/pendent.png";
import squaretwo from "../../../assets/img/squaretwo.png";
import trisquare from "../../../assets/img/trisquare.png";
import radio from "../../../assets/img/radio.png";
import gun from "../../../assets/img/gun.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import Form_Carrier from "./admireForm";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import CustomButton from "../editportfoliosecondbtn";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import CustomButtonedit from "../editportfoliosecondbtn";

const Index = ({ data, getOrder, tokenedit }) => {
 
  const [open, setOpen] = React.useState(false);
  const [viewmodal, setViewmodal] = useState([]);

  const handleClose = () => setOpen(false);
  const navigateSinguppage = useNavigate();
  const [number, setNumber] = useState(0);

  const handleOpen = (value) => {
    setOpen(true);
    setViewmodal(value);
  };
  function PaperComponent(props) {
    return <Paper sx={{ background: "#FF599D" }} {...props} />;
  }
  const slidesToShow =
    data?.artistadmire?.length < 3 ? data?.artistadmire?.length : 3;
  const settings = {
    fade: false,
    dots: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    initialSlide: 2,
    infinite: true,
    arrows: false,
    centerMode: true,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: slidesToShow,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
        },
      },

      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
        },
      },

      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
          dots: false,
        },
      },
    ],
  };
  return (
    <Box
      sx={{ background: "#FF599D", position: "relative", minHeight: "900px" }}
    >
      <Box
        sx={{
          position: "absolute",
          left: "0px",
          top: "-100px",
          display: { xs: "none", sm: "block" },
        }}
      >
        <img src={squaretwo} alt="" width="100%" />
      </Box>

      <Box
        sx={{
          position: "absolute",
          left: "32%",
          top: "-70px",
          display: { xs: "none", md: "block" },
        }}
      >
        <img src={gun} alt="" width="100%" height="200px" />
      </Box>

      <Box sx={{ position: "absolute", right: "0px", top: "0px" }}>
        <img src={pendent} alt="" />
      </Box>
      <Box
        sx={{
          position: "absolute",
          right: { lg: "20%", md: "15%" },
          top: "50px",
          display: { xs: "none", lg: "block" },
        }}
      >
        <img src={radio} alt="" />
      </Box>
      <Container>
        <Box
          textAlign="center"
          sx={{ display: "flex", justifyContent: "center", pt: 8 }}
        >
          <Typography
            className="textStroke"
            sx={{
              color: "#FAC915",
              fontSize: { xs: "40px", md: "64px" },
              zIndex: "5",
              fontFamily: "Anton",
              transform: "matrix(1, 0.01, -0.29, 0.96, 0, 0) ",
            }}
          >
            Artist I Admire
          </Typography>
        </Box>
        <Box
          sx={{
            // marginLeft: "20px",
            textAlign: "center",
            marginTop: { lg: "15px", md: "15px", xs: "0px" },
          }}
        >
          {tokenedit ? (
            <CustomButton
              btnText="Add"
              handleClick={() => {
                handleOpen();
                setNumber(0);
              }}
              startIcon={<AddIcon sx={{ marginRight: "5px" }} />}
              width="75px"
              height="30px"
              border="1px solid #ffffff !important"
              padding="15px"
            />
          ) : (
            ""
          )}
        </Box>

        <Box sx={{ mt: 8 }}>
          {data?.artistadmire?.length === 0 ? (
            <>
              <Box
                sx={{
                  margin: "30px 0px",
                  padding: "0px !important",
                }}
              >
                {tokenedit ? (
                  <Box
                    sx={{
                      border: "2px dashed #00D8C0 ",
                      width: { md: "760px", xs: "280px" },
                      height: "210px !important",
                      textAlign: "center",
                      margin: "auto",
                    }}
                    onClick={() => {
                      handleOpen();
                      setNumber(0);
                    }}
                  >
                    <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                      <Typography
                        sx={{
                          color: "#00D8C0",
                          fontSize: "30px",
                          lineHeight: "20px",
                        }}
                      >
                        +
                      </Typography>
                      <Typography
                        sx={{
                          color: "#00D8C0",
                          fontSize: "20px",
                          fontWeight: "700",
                        }}
                      >
                        Add Your First Admire Here
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      border: "2px dashed #00D8C0",
                      width: { md: "760px", xs: "280px" },
                      height: "210px !important",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  >
                    <Box sx={{ textAlign: "center", marginTop: "75px" }}>
                      <Typography sx={{
                            color: "#00D8C0",
                            fontSize: "20px",
                            fontWeight: "700",
                          }}>Admire is Empty</Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <Slider className="admireSlider" {...settings}>
              {data?.artistadmire?.map((item, index) => (
                <Box sx={{ height: "500px", zIndex: 10000, marginLeft:"auto" ,marginRight:"auto" }}>
                  <Box key={index} sx={{ position: "relative", m: 2 }}>
                    <Box className='admierBox'
                      sx={{
                        maxWidth: "296px",
                        // width: {xs:"246px", sm:"296px"},
                        // height: "305px",
                        p: "3px",
                        background: "#FCE23B",
                        transform: "rotate(3deg)",
                        position:'relative'
                      }}
                    >
                      <img src={item?.admireimg} width="100%" height='100%' alt="" />
                      <Box
                      sx={{
                        marginLeft: { lg: "70px", xs: "10px !important" },
                        position:"absolute",top:"8px", right:"8px",
                        display: { lg: "block", xs: "flex" },
                      }}
                    >
                      {tokenedit ? (
                        <Box onClick={() => {
                          handleOpen(item);
                          setNumber(1);
                        }}>
                        
                        {/*   <CustomButtonedit
                          handleClick={() => {
                            handleOpen(item);
                            setNumber(1);
                          }}
                          startIcon={
                            <ModeEditOutlinedIcon
                              sx={{ marginRight: "5px" }}
                            />
                          }
                          width="20px !important"
                          height="20px !important"
                          border="1px solid #00D8C0 !important"
                          padding="15px"
                        />*/}
                        <ModeEditOutlinedIcon   
                        sx={{ marginRight: "5px" }}
                        />
                        </Box>
                      ) : (
                        ""
                      )}
                    </Box>
                    </Box>

                    <Box
                    className='admiredesc'
                      sx={{
                        maxWidth: "320px",
                        height: "102px",
                        p: "3px",
                        position: "absolute",
                        bottom: "-40px",
                        p: 2,
                        // left: "50px",
                        background: "#FCE23B",
                        transform: "rotate(3deg)",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          lineHeight: "20px",
                          color: "#00D8C0",
                          fontFamily: "Anton",
                        }}
                      >
                        {item?.title}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "11px",
                          lineHeight: "18px",
                          color: "#141414",
                          fontFamily: "Anton",
                          mt: 1,wordBreak:"break-all"
                        }}
                      >
                        {item?.description}
                      </Typography>
                
                    </Box>
                  </Box>
                </Box>
              ))}
            </Slider>
          )}
        </Box>
      </Container>

      <Box
        sx={{
          position: "absolute",
          bottom: { xs: "-12px", md: "-7px" },
          left: "0px",
          pt: 10,
        }}
      >
        <img src={roundMusic} alt="" height="250px" width="100%" />
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: { xs: "-12px", md: "-7px" },
          right: "0px",
          display: { xs: "none", sm: "block" },
        }}
      >
        <img src={trisquare} alt="" width="100%" />
      </Box>
      <div>
        <Dialog
          height="auto"
          sx={{}}
          style={{ zIndex: "9999922" }}
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <Box textAlign="end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <DialogTitle
              style={{ cursor: "move" }}
              align="center"
              mt="-30px"
              padding="0px !important"
              id="draggable-dialog-title"
            >
              Admire
            </DialogTitle>
          </Box>

          <Form_Carrier
            view={viewmodal}
            handleClose={handleClose}
            getOrder={getOrder}
            number={number}
            tokenedit={tokenedit}
          />
        </Dialog>
      </div>
    </Box>
  );
};

export default Index;

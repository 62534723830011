import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import "./amount.css";
import CustomButton from "../common/customButton/index";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const Plan = ({
  setFormData,
  formData,
  setPaymentDetail,
  paymentget,
  setPaymentdata,
  paymentdata,
  setBillshow,
  data,
  setDistribution_type,
}) => {
  const [border1, setBorder1] = useState("");
  const [border2, setBorder2] = useState("");
  const showBorder1 = () => {
    setBorder1("3px solid #fac915");
    // setPaymentdata(0);
    setBillshow("Free");
    setBorder2("none");
    setPaymentdata(599);
  };
  const showBorder2 = () => {
    // setPaymentdata(paymentget?.base_amount);
    setBorder1("none");
    setBorder2("3px solid #fac915");
    setBillshow("Paid");
    setPaymentdata(paymentget?.base_amount);
  };
  return (
    <>
      <Card className="plans" sx={{ background: "rgba(255, 255, 255, 0.05)" }}>
        <Typography
          variant="h6"
          className="choose"
          sx={{
            marginBottom: { xs: "24px !important", sm: "32px !important" },
          }}
        >
          Choose Plan
        </Typography>
        <Grid container spacing={3.5}>
          {data?.plan === "free" ? (
            <>
              {" "}
              <Grid item xs={12} lg={6}>
                <Paper
                  onClick={() => {
                    showBorder1(data);
                  }}
                  elevation={10}
                  className="service"
                  sx={{
                    background: "#2b2b2b",
                    minHeight: "350px",
                    border: border1,
                  }}
                >
                  <CardContent className="content">
                    <Typography
                      className="one"
                      sx={{
                        fontSize: {
                          xs: "14.41px !important",
                          sm: "24px !important",
                        },
                        lineHeight: {
                          xs: "19px !important",
                          sm: "31px !important",
                        },
                        marginBottom: {
                          xs: "9.61px !important",
                          sm: "16px !important",
                        },
                      }}
                    >
                      Starter Plan
                    </Typography>
                    <ul className="keep">
                      <li className="suit"> Revenue from platforms 70%</li>
                      <li className="suit"> Customizable Label name</li>
                      <li className="suit"> Instagram profile linking</li>
                      <li className="suit"> Release on 60+ Digital stores</li>
                      <li className="suit"> Release on social platforms</li>
                      <li className="suit"> Free UPC & Free ISRC</li>
                    </ul>
                    <Stack>
                      <CustomButton
                        btnText="FREE"
                        buttonStyle="extra"
                        sx={{ backgroundColor: "#2 b2b2b !important" }}
                      />
                    </Stack>
                  </CardContent>
                </Paper>
              </Grid>
            </>
          ) : data?.plan === "paid" ? (
            <Grid item xs={12} lg={6}>
              <Paper
                onClick={() => {
                  showBorder2(data);
                }}
                elevation={10}
                className="service upc"
                sx={{
                  background: "#2b2b2b",
                  minHeight: "350px",
                  border: border2,
                }}
              >
                <CardContent className="content">
                  <Typography
                    component="div"
                    variant="body4"
                    sx={{ fontSize: { xs: "8px", sm: "14px !important" } }}
                    className="top"
                  >
                    Recommended
                  </Typography>
                  <Typography
                    className="one"
                    sx={{
                      fontSize: {
                        xs: "14.41px !important",
                        sm: "24px !important",
                      },
                      lineHeight: {
                        xs: "19px !important",
                        sm: "31px !important",
                      },
                      marginBottom: {
                        xs: "9.61px !important",
                        sm: "16px !important",
                      },
                    }}
                  >
                     Pro Plan
                  </Typography>
                  <ul className="cost">
                  <li className="suit"> Everything in Starter Pack +</li>
                    <li className="suit"> Revenue from platforms 90%</li>
                    <li className="suit"> Sync licensing for TV, films, and games
                    </li>
                    <li className="suit"> Youtube Official artist channel Request
                    </li>
                    <li className="suit"> Lyrics Royalty from Major Platforms
                    </li>
                    <li className="suit"> Verification request with Spotify and Apple Music for
                        Artists
                    </li>
                    <li className="suit"> Editorial Playlist Pitch
                    </li>
                  </ul>
                  <Stack>
                    <CustomButton
                      variant="body4"
                      startIcon={
                        <CurrencyRupeeIcon sx={{ fontSize: "14px" }} />
                      }
                      btnText={paymentget?.base_amount}
                      buttonStyle="oneTime"
                    />
                  </Stack>
                </CardContent>
              </Paper>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} lg={6}>
                <Paper
                  onClick={showBorder1}
                  elevation={10}
                  className="service"
                  sx={{
                    background: "#2b2b2b",
                    minHeight: "350px",
                    border: border1,
                  }}
                >
                  <CardContent className="content">
                    <Typography
                      className="one"
                      sx={{
                        fontSize: {
                          xs: "14.41px !important",
                          sm: "24px !important",
                        },
                        lineHeight: {
                          xs: "19px !important",
                          sm: "31px !important",
                        },
                        marginBottom: {
                          xs: "9.61px !important",
                          sm: "16px !important",
                        },
                      }}
                    >
                      Starter Plan
                    </Typography>
                    <ul className="keep">
                      <li className="suit"> Revenue from platforms 70%</li>
                      <li className="suit"> Customizable Label name</li>
                      <li className="suit"> Instagram profile linking</li>
                      <li className="suit"> Release on 60+ Digital stores</li>
                      <li className="suit"> Release on social platforms</li>
                      <li className="suit"> Free UPC & Free ISRC</li>
                    </ul>
                    <Stack>
                      <CustomButton
                        startIcon={
                          <CurrencyRupeeIcon sx={{ fontSize: "14px" }} />
                        }
                        btnText="599"
                        buttonStyle="extra"
                        sx={{ backgroundColor: "#2 b2b2b !important" }}
                      />
                    </Stack>
                  </CardContent>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Paper
                  onClick={showBorder2}
                  elevation={10}
                  className="service upc"
                  sx={{
                    background: "#2b2b2b",
                    minHeight: "350px",
                    border: border2,
                  }}
                >
                  <CardContent className="content">
                    <Typography
                      component="div"
                      variant="body4"
                      sx={{ fontSize: { xs: "8px", sm: "14px !important" } }}
                      className="top"
                    >
                      Recommended
                    </Typography>
                    <Typography
                      className="one"
                      sx={{
                        fontSize: {
                          xs: "14.41px !important",
                          sm: "24px !important",
                        },
                        lineHeight: {
                          xs: "19px !important",
                          sm: "31px !important",
                        },
                        marginBottom: {
                          xs: "9.61px !important",
                          sm: "16px !important",
                        },
                      }}
                    >
                      Pro Plan
                    </Typography>
                    <ul className="cost">
                    <li className="suit"> Everything in Starter Pack +</li>
                      <li className="suit"> Revenue from platforms 90%</li>
                      <li className="suit"> Sync licensing for TV, films, and games
                      </li>
                      <li className="suit"> Youtube Official artist channel Request
                      </li>
                      <li className="suit"> Lyrics Royalty from Major Platforms
                      </li>
                      <li className="suit"> Verification request with Spotify and Apple Music for
                        Artists
                      </li>
                      <li className="suit"> Editorial Playlist Pitch</li>
                    </ul>
                    <Stack>
                      <CustomButton
                        variant="body4"
                        startIcon={
                          <CurrencyRupeeIcon sx={{ fontSize: "14px" }} />
                        }
                        btnText={paymentget?.base_amount}
                        buttonStyle="oneTime"
                      />
                    </Stack>
                  </CardContent>
                </Paper>
              </Grid>
            </>
          )}
        </Grid>
      </Card>
    </>
  );
};

export default Plan;

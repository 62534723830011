import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Accountstyle from "../account/account.css";
import Profilephoto from "./profilephoto";
import { Button, TextField, Typography } from "@mui/material";
import Socialdetalis from "./socialmediadetals";
import Arrtis from "./artispage";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Select from "@mui/material/Select";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { countries } from "./country";
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CustomButton from "../common/customButton/index";
import AddIcon from "@mui/icons-material/Add";
import { useUpdateuserMutation } from "../../redux/services/user";
import { useUserprofileQuery } from "../../redux/services/user";

// import { useUserprofileQuery } from "../../redux/services/user";

const Account = ({ setData, data, getContact }) => {
  const initialValues = {
    firstName: "",
    lastName: "",
    contactNumber: "",
    email: "",
    gender: "female",
    birthDay: "",
    zipCode: "",
    address: "",
    profileBio: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    country: "India",
    stageName: "",
  };

  const loginToken = JSON.parse(localStorage.getItem("login"));
  const [profile, setProfile] = useState();
  const token = loginToken?.token;
  const userid = loginToken?.user._id;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  // const responseInfo = useUserprofileQuery();
  // const [data, setData] = useState([]);
  const [saveinfo, setSaveinfo] = useState();
  const [formData, setformData] = React.useState({
    country: data?.address?.country || "India",
  });
  const validationSchema = Yup.object({
    firstName: Yup.string().required("Please Enter Your First Name"),

    lastName: Yup.string().required("Please Enter Your Last Name"),

    // contactNumber: Yup.number().required("Please Provide Valid Contact Number"),

    email: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
  });

  useEffect(() => {
    formik.setFieldValue("firstName", data?.name?.first);
    formik.setFieldValue("lastName", data?.name?.last);
    formik.setFieldValue("contactNumber", data?.phone?.replace(/^\+91/, ""));
    formik.setFieldValue("email", data?.email);
    formik.setFieldValue("gender", data?.gender);
    formik.setFieldValue("zipCode", data?.address?.pincode);
    formik.setFieldValue("address", data?.address?.line1);
    formik.setFieldValue("profileBio", data?.profileDescription);
    // formik.setFieldValue("birthDay", data?.dob)
    formik.setFieldValue("facebook", data?.socialMedia?.facebook);
    formik.setFieldValue("instagram", data?.socialMedia?.instagram);
    formik.setFieldValue("twitter", data?.socialMedia?.twitter);
    formik.setFieldValue("youtube", data?.socialMedia?.youtube);
    formik.setFieldValue("country", data?.address?.country);
    formik.setFieldValue("stageName", data?.stageName);
    // formik.setFieldValue("listData", data?.role);
  }, [data]);

  const [value, setValue] = React.useState(" ");

  const handleChanged = (newValue) => {
    setValue(newValue);
  };
  const handlePreviewChange = (event) => {
    setPreview(event.target.value);
  };

  useEffect(() => {
    if (data?.dob) {
      setValue(moment(data?.dob).format("DD-MM-YYYY"));
    }
  }, [data, setValue]);
  useEffect(() => {
    if (data?.profilePicture) {
      setProfile(data?.profilePicture);
    }
  }, [data, setProfile]);

  const onSubmit = async (values) => {
    const dobdata = value.$d;
    const payload = {
      name: {
        first: values.firstName,
        last: values.lastName,
      },
      // email: values.email,
      gender: values.gender,
      dob: value.$d,
      // zipCode: values.pincode,
      address: {
        line1: values.address,
        pincode: values.zipCode,
        country: formData?.country,
      },

      socialMedia: {
        facebook: values.facebook,
        twitter: values.twitter,
        instagram: values.instagram,
        youtube: values.youtube,
      },
      profileDescription: values.profileBio,
      profilePicture: profile,
      stageName: values.stageName,
      contactNumber: values.contactNumber,
    };

    await axios
      .put(`${process.env.REACT_APP_DMT_API_PATH}/users`, payload, config)
      .then((res) => {
        if (res.data.error !== true && payload?.profilePicture) {
          const formDatavalue = new FormData();
          formDatavalue.append("file", payload?.profilePicture);
          const loginToken = JSON.parse(localStorage.getItem("login"));
          const token = loginToken.token;
          axios
            .post(
              `${process.env.REACT_APP_DMT_API_PATH}/users/profilepictureupdate/${userid}`,
              formDatavalue,
              {
                headers: {
                  "content-type": "multipart/form-data",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              if (res?.data?.error !== true) {
                getContact();
              }
            })
            .catch((err) => {
              toast.error(err.message);
            });
        }
        toast.success("Profile Updated successfully");
        getContact();
        // else{
        //   toast.success("Profile Updated successfully");
        //   getContact();
        // }
        // responseInfo();
      })
      .catch((err) => {
        toast.error("something went wrong");
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const [totalShow, setTotalShow] = React.useState("");
  const [age, setAge] = React.useState("");
  const [preview, setPreview] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
    const handlePreviewChange = (event) => {
      setPreview(event.target.value);
    };
  };

  return (
    <Box className="custom-dmt-container">
      <Formik>
        <Form onSubmit={formik.handleSubmit}>
          <Card
            className="accountcard"
            sx={{ backgroundColor: "black !important" }}
          >
            <Typography className="basicdetails" paddingLeft="10px">
              Basic Details
            </Typography>

            {/* main container */}

            <Grid container padding="10px">
              {/* -----------Photo Section Start----------------------- */}
              <Grid item md={4} xs={12}>
                <Box>
                  <Typography id="fontfamilys" fontWeight="700 !important">
                    Profile Photo
                  </Typography>
                  <br />
                  <Profilephoto setProfile={setProfile} profile={profile} />
                </Box>
              </Grid>
              <Grid item md={8} xs={12}>
                {/* -----------Photo Section Start----------------------- */}
                {/* inside main grid  1-------------*/}
                <Grid container spacing={3}>
                  {/* ------------------------------------Formik Start-------------------------------- */}

                  {/*------------------------- First Name Start------------------------ */}
                  <Grid item md={6} xs={12}>
                    <Typography
                      id="fontfamilys"
                      fontWeight="700 ! important"
                      marginBottom="8px"
                    >
                      First Name *
                    </Typography>

                    <TextField
                      fullWidth
                      variant="standard"
                      name="firstName"
                      id="outlined-basic"
                      label=""
                      type="text"
                      sx={{
                        background: "rgba(255, 255, 255, 0.05) !important",
                        height: "44px !important",
                        borderRadius: "5px",
                        p: 1,
                        fontFamily: "DM Sans",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        background: "rgba(255, 255, 255, 0.05) !important",
                      }}
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <Typography color="error">
                        {" "}
                        {formik.errors.firstName}{" "}
                      </Typography>
                    ) : null}
                  </Grid>

                  {/*------------------------- First Name End------------------------ */}

                  {/*------------------------- Last Name Start------------------------ */}

                  <Grid item md={6} xs={12}>
                    <Typography
                      id="fontfamilys"
                      fontWeight="700 ! important"
                      marginBottom="8px"
                    >
                      Last Name *
                    </Typography>

                    <TextField
                      fullWidth
                      variant="standard"
                      name="lastName"
                      id="outlined-basic"
                      type="text"
                      sx={{
                        background: "rgba(255, 255, 255, 0.05) !important",
                        height: "44px !important",
                        borderRadius: "5px",
                        p: 1,
                        fontFamily: "DM Sans",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        background: "rgba(255, 255, 255, 0.05) !important",
                      }}
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <Typography color="error">
                        {" "}
                        {formik.errors.lastName}{" "}
                      </Typography>
                    ) : null}
                  </Grid>

                  {/*------------------------- Last Name End------------------------ */}
                </Grid>
                <br />
                {/*----------------------------- Stage Name Start-------------------- */}
                <Typography
                  id="fontfamilys"
                  fontWeight="700 ! important"
                  marginBottom="8px"
                >
                  Stage Name
                </Typography>
                <TextField
                  fullWidth
                  variant="standard"
                  name="stageName"
                  id="outlined-basic"
                  label=""
                  value={formik.values.stageName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={{
                    background: "rgba(255, 255, 255, 0.05) !important",
                    height: "44px !important",
                    borderRadius: "5px",
                    p: 1,
                    fontFamily: "DM Sans",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    background: "rgba(255, 255, 255, 0.05) !important",
                  }}
                />
                {/*----------------------------- Stage Name End-------------------- */}
                <br /> <br />
                {/* ----------------------------Contact Number Start--------------- */}
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Typography
                      id="fontfamilys"
                      fontWeight="700 ! important"
                      marginBottom="8px"
                    >
                      Contact Number*{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      variant="standard"
                      name="contactNumber"
                      id="outlined-basic"
                      type="number"
                      label=""
                      sx={{
                        background: "rgba(255, 255, 255, 0.05) !important",
                        height: "44px !important",
                        borderRadius: "5px",
                        p: 1,
                        fontFamily: "DM Sans",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        background: "rgba(255, 255, 255, 0.05) !important",
                      }}
                      value={formik.values.contactNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.contactNumber &&
                    formik.errors.contactNumber ? (
                      <Typography color="error">
                        {" "}
                        {formik.errors.contactNumber}{" "}
                      </Typography>
                    ) : null}

                    {/* ----------------------------Contact Number End--------------- */}
                  </Grid>

                  {/* ----------------------------Email ID Start--------------- */}

                  <Grid item md={6} xs={12}>
                    <Typography
                      id="fontfamilys"
                      fontWeight="700 ! important"
                      marginBottom="8px"
                    >
                      {" "}
                      Email ID *{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      variant="standard"
                      name="email"
                      id="outlined-basic"
                      label=""
                      sx={{
                        background: "rgba(255, 255, 255, 0.05) !important",
                        height: "44px !important",
                        borderRadius: "5px",
                        p: 1,
                        fontFamily: "DM Sans",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        background: "rgba(255, 255, 255, 0.05) !important",
                      }}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <Typography color="error">
                        {" "}
                        {formik.errors.email}{" "}
                      </Typography>
                    ) : null}
                  </Grid>

                  {/* ----------------------------Email ID End--------------- */}
                </Grid>
                <br />
                {/* -------------------------------Gender Start------------- */}
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography
                      id="fontfamilys"
                      fontWeight="700 ! important"
                      marginBottom="8px"
                    >
                      {" "}
                      Gender *{" "}
                    </Typography>
                    {/* <TextField
                      fullWidth
                      variant="standard"
                      name="gender"
                      id="outlined-basic"
                      label=""
                      sx={{
                        background: "rgba(255, 255, 255, 0.05) !important",
                        height: "44px !important",
                        borderRadius: "5px",
                        p: 1,
                        fontFamily: "DM Sans",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        background: "rgba(255, 255, 255, 0.05) !important",
                      }}
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    /> */}
                    <Box>
                      <TextField
                        className="bfTextField"
                        sx={{
                          background: "rgba(255, 255, 255, 0.05) !important",
                          height: "44px !important",
                          borderRadius: "5px",
                          p: 1,
                          fontFamily: "DM Sans",
                        }}
                        InputProps={{
                          disableUnderline: true,
                          background: "rgba(255, 255, 255, 0.05) !important",
                        }}
                        select
                        variant="standard"
                        label=""
                        fullWidth
                        defaultValue="female"
                        // placeholder="Account Type"
                        name="gender"
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <MenuItem value="male"> Male </MenuItem>
                        <MenuItem selected value="female">
                          {" "}
                          Female{" "}
                        </MenuItem>
                        <MenuItem value="others"> Other </MenuItem>
                      </TextField>

                      {formik.touched.accountType &&
                      formik.errors.accountType ? (
                        <Typography color="error">
                          {formik.errors.accountType}
                        </Typography>
                      ) : null}
                    </Box>
                    {formik.touched.gender && formik.errors.gender ? (
                      <Typography color="error">
                        {" "}
                        {formik.errors.gender}{" "}
                      </Typography>
                    ) : null}
                  </Grid>
                  {/* -------------------------------Gender End------------- */}

                  <Grid item xs={12} md={6}>
                    <Typography
                      id="fontfamilys"
                      fontWeight="700 ! important"
                      marginBottom="8px"
                    >
                      {" "}
                      Birthday *{" "}
                    </Typography>
                    <Box sx={{ display: { xs: " block", sm: "block" } }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          inputFormat="DD/MM/YYYY"
                          // value={value}
                          value={value}
                          onChange={handleChanged}
                          // onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="MuiOutlinedInput-notchedOutline"
                              fullWidth
                              name="birthDay"
                              sx={{
                                backgroundColor:
                                  "rgba(255, 255, 255, 0.05) !important",
                                height: "44px",
                              }}
                              // value={formik.values.birthDay}
                              // onChange={formik.handleChange}
                              // onBlur={formik.handleBlur}
                            />
                          )}
                        />
                        {formik.touched.birthDay && formik.errors.birthDay ? (
                          <Typography color="error">
                            {" "}
                            {formik.errors.birthDay}{" "}
                          </Typography>
                        ) : null}
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                </Grid>
                <br />
                {/* -----------------------------------Address Start---------------------------- */}
                <Typography
                  id="fontfamilys"
                  fontWeight="700 ! important"
                  marginBottom="8px"
                >
                  {" "}
                  Address
                </Typography>
                <TextareaAutosize
                  minRows="6"
                  maxRows="6"
                  id="fontfamilys"
                  name="address"
                  style={{
                    width: "100%",
                    // height: "44px",
                    color: "white",
                    background: "rgba(255, 255, 255, 0.05)",
                    borderRadius: "5px",
                    border: "none",
                    outline: "none",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                  }}
                  type="text"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {/* -----------------------------------Address End---------------------------- */}
                <br /> <br />
                {/* ---------------------------Country Start------------------------------------ */}
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Typography
                      id="fontfamilys"
                      fontWeight="700 ! important"
                      marginBottom="8px"
                    >
                      {" "}
                      Country{" "}
                    </Typography>
                    <Box width="100%">
                      {/* <Country /> */}
                      <Autocomplete
                        id="country-select-demo"
                        sx={{ width: "100%" }}
                        options={countries}
                        // defaultValue={'India'}
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.label}
                            {/* ({option.value}) +{option.phone} */}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            id="outlined-select-currency"
                            select
                            value={formData.country}
                            onChange={(event) =>
                              setformData({
                                ...formData,
                                country: event.target.value,
                              })
                            }
                            // value={formik.values.country}
                            // onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            // onChange={handleChange}
                            sx={{
                              background:
                                "rgba(255, 255, 255, 0.05) !important",
                              height: "44px !important",
                              borderRadius: "5px",
                              p: 1,
                              fontFamily: "DM Sans",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "18px",
                              color: "#E1E1E1",
                            }}
                            variant="standard"
                            InputProps={{
                              disableUnderline: true,
                              background:
                                "rgba(255, 255, 255, 0.05) !important",
                            }}
                          >
                            {countries.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                <Typography sx={{ fontFamily: "DM sans" }}>
                                  {" "}
                                  {option.label}
                                </Typography>
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography
                      id="fontfamilys"
                      fontWeight="700 ! important"
                      marginBottom="8px"
                    >
                      {" "}
                      Zipcode{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      name="zipCode"
                      id="outlined-basic"
                      label=""
                      value={formik.values.zipCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{
                        background: "rgba(255, 255, 255, 0.05) !important",
                        height: "44px !important",
                        borderRadius: "5px",
                        p: 1,
                        fontFamily: "DM Sans",
                      }}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        background: "rgba(255, 255, 255, 0.05) !important",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* ---------------------------Country End------------------------------------ */}

              {/* profile bio-------- */}
              <Box width="100%" sx={{ mt: 2 }}>
                <Typography
                  id="fontfamilys"
                  fontWeight="700 ! important"
                  marginBottom="8px"
                >
                  Profile Bio
                </Typography>

                <TextareaAutosize
                  fullWidth
                  // id="outlined-basic"
                  name="profileBio"
                  label=""
                  minRows="6"
                  maxRows="6"
                  value={formik.values.profileBio}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{
                    width: "100%",
                    background: "rgba(255, 255, 255, 0.05)",
                    borderRadius: " 5px",
                    height: "44px !important",
                    color: "white",
                    border: "none ",
                    fontWeight: "700",
                    outline: "none",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    fontFamily: "DM Sans",
                    // disableUnderline:false,
                    // lineHeight:"1rem",
                    // padding:"10px !important"
                  }}
                />
              </Box>
            </Grid>
            <Box paddingLeft="12px">
              <br />
              <Typography className="socialmedia">
                Social Media Details
              </Typography>
              <br />
              {/* facebook -------- */}
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography
                    id="fontfamilys"
                    fontWeight="700 ! important"
                    marginBottom="8px"
                  >
                    {" "}
                    Facebook Page Link{" "}
                  </Typography>
                  <TextField
                    fullWidth
                    name="facebook"
                    id="outlined-basic"
                    label=""
                    value={formik.values.facebook}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{
                      background: "rgba(255, 255, 255, 0.05) !important",
                      height: "44px !important",
                      borderRadius: "5px",
                      p: 1,
                      fontFamily: "DM Sans",
                    }}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      background: "rgba(255, 255, 255, 0.05) !important",
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    id="fontfamilys"
                    fontWeight="700 ! important"
                    marginBottom="8px"
                  >
                    {" "}
                    Instagram Profile{" "}
                  </Typography>
                  <TextField
                    fullWidth
                    name="instagram"
                    id="outlined-basic"
                    label=""
                    value={formik.values.instagram}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{
                      background: "rgba(255, 255, 255, 0.05) !important",
                      height: "44px !important",
                      borderRadius: "5px",
                      p: 1,
                      fontFamily: "DM Sans",
                    }}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      background: "rgba(255, 255, 255, 0.05) !important",
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              {/*  youtube -------- */}
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography
                    id="fontfamilys"
                    fontWeight="700 ! important"
                    marginBottom="8px"
                  >
                    {" "}
                    Youtube Channel{" "}
                  </Typography>
                  <TextField
                    fullWidth
                    name="youtube"
                    id="outlined-basic"
                    label=""
                    value={formik.values.youtube}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{
                      background: "rgba(255, 255, 255, 0.05) !important",
                      height: "44px !important",
                      borderRadius: "5px",
                      p: 1,
                      fontFamily: "DM Sans",
                    }}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      background: "rgba(255, 255, 255, 0.05) !important",
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    id="fontfamilys"
                    fontWeight="700 ! important"
                    marginBottom="8px"
                  >
                    {" "}
                    Twitter{" "}
                  </Typography>
                  <TextField
                    fullWidth
                    name="twitter"
                    id="outlined-basic"
                    label=""
                    value={formik.values.twitter}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{
                      background: "rgba(255, 255, 255, 0.05) !important",
                      height: "44px !important",
                      borderRadius: "5px",
                      p: 1,
                      fontFamily: "DM Sans",
                    }}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      background: "rgba(255, 255, 255, 0.05) !important",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            {/* <Socialdetalis /> */}
            <br />
            {/* <Arrtis /> */}
            <Box>
              {/* <Box component="form" autoComplete="off" mt="15px" ml="10px">
                {listData.map((roleAdd, index) => (
                  <Box key={index} className="role">
                    <Grid
                      container
                      spacing={3}
                      sx={{
                        my: 4,
                        ".css-1brrso7-MuiGrid-root>.MuiGrid-item": {
                          paddingLeft: "14px",
                        },
                      }}
                    >
                      <Grid item xs={12} md={12}>
                        <Typography
                          sx={{
                            mb: 1,
                            fontFamily: "DM Sans",
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "21px",
                          }}
                        >
                          Artist Page
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <TextField
                            fullWidth
                            label=""
                            sx={{
                              background:
                                "rgba(255, 255, 255, 0.05) !important",
                              height: "44px !important",
                              borderRadius: "5px",
                              p: 1,
                              fontFamily: "DM Sans",
                            }}
                            variant="standard"
                            InputProps={{
                              disableUnderline: true,
                              background:
                                "rgba(255, 255, 255, 0.05) !important",
                            }}
                            name="role"
                            type="text"
                            id="name"
                            value={roleAdd.role}
                            onChange={(e) => handleChange2(e, index)}
                            required
                          />
                          <Box sx={{ ml: "5px" }}>
                            {listData.length === 1 ? (
                              <DeleteOutlineOutlinedIcon
                                sx={{ color: "red", display: "none" }}
                              />
                            ) : (
                              <DeleteOutlineOutlinedIcon
                                onClick={() => handleServiceRemove(index)}
                                sx={{ color: "red" }}
                              />
                            )}
                          </Box>
                        </Box>
                      </Grid>
                      {listData.length - 1 === index && listData.length < 5 && (
                        <Grid item xs={12}>
                          <CustomButton
                            btnText={"Add More Links"}
                            endIcon={<AddIcon sx={{ ml: "13px" }} />}
                            buttonStyle="artistbtn"
                            handleClick={handleAdd}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                ))}
              </Box> */}
            </Box>
            <Box display="flex" justifyContent="center" mt="10px">
              <Button
                type="submit"
                sx={{
                  background:
                    "linear-gradient(180deg, #FAC915 0%, #FB900B 100%)",
                  borderRadius: "24px",
                  color: "#0D0D0D !important",
                  fontWeight: "700 !important",
                  width: "100px",
                  height: "40px",
                  marginBottom: "50px",
                  textTransform: "capitalize",
                }}
              >
                Submit
              </Button>
            </Box>
          </Card>
          <ToastContainer />
        </Form>
      </Formik>
    </Box>
  );
};

export default Account;

// const countries = [
//   { value: "AD", label: "Andorra", phone: "376" },
//   {
//     value: "AE",
//     label: "United Arab Emirates",
//     phone: "971",
//   },
//   { value: "AF", label: "Afghanistan", phone: "93" },
//   {
//     value: "AG",
//     label: "Antigua and Barbuda",
//     phone: "1-268",
//   },
//   { value: "AI", label: "Anguilla", phone: "1-264" },
//   { value: "AL", label: "Albania", phone: "355" },
//   { value: "AM", label: "Armenia", phone: "374" },
//   { value: "AO", label: "Angola", phone: "244" },
//   { value: "AQ", label: "Antarctica", phone: "672" },
//   { value: "AR", label: "Argentina", phone: "54" },
//   { value: "AS", label: "American Samoa", phone: "1-684" },
//   { value: "AT", label: "Austria", phone: "43" },
//   {
//     value: "AU",
//     label: "Australia",
//     phone: "61",
//     suggested: true,
//   },
//   { value: "AW", label: "Aruba", phone: "297" },
//   { value: "AX", label: "Alland Islands", phone: "358" },
//   { value: "AZ", label: "Azerbaijan", phone: "994" },
//   {
//     value: "BA",
//     label: "Bosnia and Herzegovina",
//     phone: "387",
//   },
//   { value: "BB", label: "Barbados", phone: "1-246" },
//   { value: "BD", label: "Bangladesh", phone: "880" },
//   { value: "BE", label: "Belgium", phone: "32" },
//   { value: "BF", label: "Burkina Faso", phone: "226" },
//   { value: "BG", label: "Bulgaria", phone: "359" },
//   { value: "BH", label: "Bahrain", phone: "973" },
//   { value: "BI", label: "Burundi", phone: "257" },
//   { value: "BJ", label: "Benin", phone: "229" },
//   { value: "BL", label: "Saint Barthelemy", phone: "590" },
//   { value: "BM", label: "Bermuda", phone: "1-441" },
//   { value: "BN", label: "Brunei Darussalam", phone: "673" },
//   { value: "BO", label: "Bolivia", phone: "591" },
//   { value: "BR", label: "Brazil", phone: "55" },
//   { value: "BS", label: "Bahamas", phone: "1-242" },
//   { value: "BT", label: "Bhutan", phone: "975" },
//   { value: "BV", label: "Bouvet Island", phone: "47" },
//   { value: "BW", label: "Botswana", phone: "267" },
//   { value: "BY", label: "Belarus", phone: "375" },
//   { value: "BZ", label: "Belize", phone: "501" },
//   {
//     value: "CA",
//     label: "Canada",
//     phone: "1",
//     suggested: true,
//   },
//   {
//     value: "CC",
//     label: "Cocos (Keeling) Islands",
//     phone: "61",
//   },
//   {
//     value: "CD",
//     label: "Congo, Democratic Republic of the",
//     phone: "243",
//   },
//   {
//     value: "CF",
//     label: "Central African Republic",
//     phone: "236",
//   },
//   {
//     value: "CG",
//     label: "Congo, Republic of the",
//     phone: "242",
//   },
//   { value: "CH", label: "Switzerland", phone: "41" },
//   { value: "CI", label: "Cote d'Ivoire", phone: "225" },
//   { value: "CK", label: "Cook Islands", phone: "682" },
//   { value: "CL", label: "Chile", phone: "56" },
//   { value: "CM", label: "Cameroon", phone: "237" },
//   { value: "CN", label: "China", phone: "86" },
//   { value: "CO", label: "Colombia", phone: "57" },
//   { value: "CR", label: "Costa Rica", phone: "506" },
//   { value: "CU", label: "Cuba", phone: "53" },
//   { value: "CV", label: "Cape Verde", phone: "238" },
//   { value: "CW", label: "Curacao", phone: "599" },
//   { value: "CX", label: "Christmas Island", phone: "61" },
//   { value: "CY", label: "Cyprus", phone: "357" },
//   { value: "CZ", label: "Czech Republic", phone: "420" },
//   {
//     value: "DE",
//     label: "Germany",
//     phone: "49",
//     suggested: true,
//   },
//   { value: "DJ", label: "Djibouti", phone: "253" },
//   { value: "DK", label: "Denmark", phone: "45" },
//   { value: "DM", label: "Dominica", phone: "1-767" },
//   {
//     value: "DO",
//     label: "Dominican Republic",
//     phone: "1-809",
//   },
//   { value: "DZ", label: "Algeria", phone: "213" },
//   { value: "EC", label: "Ecuador", phone: "593" },
//   { value: "EE", label: "Estonia", phone: "372" },
//   { value: "EG", label: "Egypt", phone: "20" },
//   { value: "EH", label: "Western Sahara", phone: "212" },
//   { value: "ER", label: "Eritrea", phone: "291" },
//   { value: "ES", label: "Spain", phone: "34" },
//   { value: "ET", label: "Ethiopia", phone: "251" },
//   { value: "FI", label: "Finland", phone: "358" },
//   { value: "FJ", label: "Fiji", phone: "679" },
//   {
//     value: "FK",
//     label: "Falkland Islands (Malvinas)",
//     phone: "500",
//   },
//   {
//     value: "FM",
//     label: "Micronesia, Federated States of",
//     phone: "691",
//   },
//   { value: "FO", label: "Faroe Islands", phone: "298" },
//   {
//     value: "FR",
//     label: "France",
//     phone: "33",
//     suggested: true,
//   },
//   { value: "GA", label: "Gabon", phone: "241" },
//   { value: "GB", label: "United Kingdom", phone: "44" },
//   { value: "GD", label: "Grenada", phone: "1-473" },
//   { value: "GE", label: "Georgia", phone: "995" },
//   { value: "GF", label: "French Guiana", phone: "594" },
//   { value: "GG", label: "Guernsey", phone: "44" },
//   { value: "GH", label: "Ghana", phone: "233" },
//   { value: "GI", label: "Gibraltar", phone: "350" },
//   { value: "GL", label: "Greenland", phone: "299" },
//   { value: "GM", label: "Gambia", phone: "220" },
//   { value: "GN", label: "Guinea", phone: "224" },
//   { value: "GP", label: "Guadeloupe", phone: "590" },
//   { value: "GQ", label: "Equatorial Guinea", phone: "240" },
//   { value: "GR", label: "Greece", phone: "30" },
//   {
//     value: "GS",
//     label: "South Georgia and the South Sandwich Islands",
//     phone: "500",
//   },
//   { value: "GT", label: "Guatemala", phone: "502" },
//   { value: "GU", label: "Guam", phone: "1-671" },
//   { value: "GW", label: "Guinea-Bissau", phone: "245" },
//   { value: "GY", label: "Guyana", phone: "592" },
//   { value: "HK", label: "Hong Kong", phone: "852" },
//   {
//     value: "HM",
//     label: "Heard Island and McDonald Islands",
//     phone: "672",
//   },
//   { value: "HN", label: "Honduras", phone: "504" },
//   { value: "HR", label: "Croatia", phone: "385" },
//   { value: "HT", label: "Haiti", phone: "509" },
//   { value: "HU", label: "Hungary", phone: "36" },
//   { value: "ID", label: "Indonesia", phone: "62" },
//   { value: "IE", label: "Ireland", phone: "353" },
//   { value: "IL", label: "Israel", phone: "972" },
//   { value: "IM", label: "Isle of Man", phone: "44" },
//   { value: "IN", label: "India", phone: "91" },
//   {
//     value: "IO",
//     label: "British Indian Ocean Territory",
//     phone: "246",
//   },
//   { value: "IQ", label: "Iraq", phone: "964" },
//   {
//     value: "IR",
//     label: "Iran, Islamic Republic of",
//     phone: "98",
//   },
//   { value: "IS", label: "Iceland", phone: "354" },
//   { value: "IT", label: "Italy", phone: "39" },
//   { value: "JE", label: "Jersey", phone: "44" },
//   { value: "JM", label: "Jamaica", phone: "1-876" },
//   { value: "JO", label: "Jordan", phone: "962" },
//   {
//     value: "JP",
//     label: "Japan",
//     phone: "81",
//     suggested: true,
//   },
//   { value: "KE", label: "Kenya", phone: "254" },
//   { value: "KG", label: "Kyrgyzstan", phone: "996" },
//   { value: "KH", label: "Cambodia", phone: "855" },
//   { value: "KI", label: "Kiribati", phone: "686" },
//   { value: "KM", label: "Comoros", phone: "269" },
//   {
//     value: "KN",
//     label: "Saint Kitts and Nevis",
//     phone: "1-869",
//   },
//   {
//     value: "KP",
//     label: "Korea, Democratic People's Republic of",
//     phone: "850",
//   },
//   { value: "KR", label: "Korea, Republic of", phone: "82" },
//   { value: "KW", label: "Kuwait", phone: "965" },
//   { value: "KY", label: "Cayman Islands", phone: "1-345" },
//   { value: "KZ", label: "Kazakhstan", phone: "7" },
//   {
//     value: "LA",
//     label: "Lao People's Democratic Republic",
//     phone: "856",
//   },
//   { value: "LB", label: "Lebanon", phone: "961" },
//   { value: "LC", label: "Saint Lucia", phone: "1-758" },
//   { value: "LI", label: "Liechtenstein", phone: "423" },
//   { value: "LK", label: "Sri Lanka", phone: "94" },
//   { value: "LR", label: "Liberia", phone: "231" },
//   { value: "LS", label: "Lesotho", phone: "266" },
//   { value: "LT", label: "Lithuania", phone: "370" },
//   { value: "LU", label: "Luxembourg", phone: "352" },
//   { value: "LV", label: "Latvia", phone: "371" },
//   { value: "LY", label: "Libya", phone: "218" },
//   { value: "MA", label: "Morocco", phone: "212" },
//   { value: "MC", label: "Monaco", phone: "377" },
//   {
//     value: "MD",
//     label: "Moldova, Republic of",
//     phone: "373",
//   },
//   { value: "ME", label: "Montenegro", phone: "382" },
//   {
//     value: "MF",
//     label: "Saint Martin (French part)",
//     phone: "590",
//   },
//   { value: "MG", label: "Madagascar", phone: "261" },
//   { value: "MH", label: "Marshall Islands", phone: "692" },
//   {
//     value: "MK",
//     label: "Macedonia, the Former Yugoslav Republic of",
//     phone: "389",
//   },
//   { value: "ML", label: "Mali", phone: "223" },
//   { value: "MM", label: "Myanmar", phone: "95" },
//   { value: "MN", label: "Mongolia", phone: "976" },
//   { value: "MO", label: "Macao", phone: "853" },
//   {
//     value: "MP",
//     label: "Northern Mariana Islands",
//     phone: "1-670",
//   },
//   { value: "MQ", label: "Martinique", phone: "596" },
//   { value: "MR", label: "Mauritania", phone: "222" },
//   { value: "MS", label: "Montserrat", phone: "1-664" },
//   { value: "MT", label: "Malta", phone: "356" },
//   { value: "MU", label: "Mauritius", phone: "230" },
//   { value: "MV", label: "Maldives", phone: "960" },
//   { value: "MW", label: "Malawi", phone: "265" },
//   { value: "MX", label: "Mexico", phone: "52" },
//   { value: "MY", label: "Malaysia", phone: "60" },
//   { value: "MZ", label: "Mozambique", phone: "258" },
//   { value: "NA", label: "Namibia", phone: "264" },
//   { value: "NC", label: "New Caledonia", phone: "687" },
//   { value: "NE", label: "Niger", phone: "227" },
//   { value: "NF", label: "Norfolk Island", phone: "672" },
//   { value: "NG", label: "Nigeria", phone: "234" },
//   { value: "NI", label: "Nicaragua", phone: "505" },
//   { value: "NL", label: "Netherlands", phone: "31" },
//   { value: "NO", label: "Norway", phone: "47" },
//   { value: "NP", label: "Nepal", phone: "977" },
//   { value: "NR", label: "Nauru", phone: "674" },
//   { value: "NU", label: "Niue", phone: "683" },
//   { value: "NZ", label: "New Zealand", phone: "64" },
//   { value: "OM", label: "Oman", phone: "968" },
//   { value: "PA", label: "Panama", phone: "507" },
//   { value: "PE", label: "Peru", phone: "51" },
//   { value: "PF", label: "French Polynesia", phone: "689" },
//   { value: "PG", label: "Papua New Guinea", phone: "675" },
//   { value: "PH", label: "Philippines", phone: "63" },
//   { value: "PK", label: "Pakistan", phone: "92" },
//   { value: "PL", label: "Poland", phone: "48" },
//   {
//     value: "PM",
//     label: "Saint Pierre and Miquelon",
//     phone: "508",
//   },
//   { value: "PN", label: "Pitcairn", phone: "870" },
//   { value: "PR", label: "Puerto Rico", phone: "1" },
//   {
//     value: "PS",
//     label: "Palestine, State of",
//     phone: "970",
//   },
//   { value: "PT", label: "Portugal", phone: "351" },
//   { value: "PW", label: "Palau", phone: "680" },
//   { value: "PY", label: "Paraguay", phone: "595" },
//   { value: "QA", label: "Qatar", phone: "974" },
//   { value: "RE", label: "Reunion", phone: "262" },
//   { value: "RO", label: "Romania", phone: "40" },
//   { value: "RS", label: "Serbia", phone: "381" },
//   { value: "RU", label: "Russian Federation", phone: "7" },
//   { value: "RW", label: "Rwanda", phone: "250" },
//   { value: "SA", label: "Saudi Arabia", phone: "966" },
//   { value: "SB", label: "Solomon Islands", phone: "677" },
//   { value: "SC", label: "Seychelles", phone: "248" },
//   { value: "SD", label: "Sudan", phone: "249" },
//   { value: "SE", label: "Sweden", phone: "46" },
//   { value: "SG", label: "Singapore", phone: "65" },
//   { value: "SH", label: "Saint Helena", phone: "290" },
//   { value: "SI", label: "Slovenia", phone: "386" },
//   {
//     value: "SJ",
//     label: "Svalbard and Jan Mayen",
//     phone: "47",
//   },
//   { value: "SK", label: "Slovakia", phone: "421" },
//   { value: "SL", label: "Sierra Leone", phone: "232" },
//   { value: "SM", label: "San Marino", phone: "378" },
//   { value: "SN", label: "Senegal", phone: "221" },
//   { value: "SO", label: "Somalia", phone: "252" },
//   { value: "SR", label: "Suriname", phone: "597" },
//   { value: "SS", label: "South Sudan", phone: "211" },
//   {
//     value: "ST",
//     label: "Sao Tome and Principe",
//     phone: "239",
//   },
//   { value: "SV", label: "El Salvador", phone: "503" },
//   {
//     value: "SX",
//     label: "Sint Maarten (Dutch part)",
//     phone: "1-721",
//   },
//   {
//     value: "SY",
//     label: "Syrian Arab Republic",
//     phone: "963",
//   },
//   { value: "SZ", label: "Swaziland", phone: "268" },
//   {
//     value: "TC",
//     label: "Turks and Caicos Islands",
//     phone: "1-649",
//   },
//   { value: "TD", label: "Chad", phone: "235" },
//   {
//     value: "TF",
//     label: "French Southern Territories",
//     phone: "262",
//   },
//   { value: "TG", label: "Togo", phone: "228" },
//   { value: "TH", label: "Thailand", phone: "66" },
//   { value: "TJ", label: "Tajikistan", phone: "992" },
//   { value: "TK", label: "Tokelau", phone: "690" },
//   { value: "TL", label: "Timor-Leste", phone: "670" },
//   { value: "TM", label: "Turkmenistan", phone: "993" },
//   { value: "TN", label: "Tunisia", phone: "216" },
//   { value: "TO", label: "Tonga", phone: "676" },
//   { value: "TR", label: "Turkey", phone: "90" },
//   {
//     value: "TT",
//     label: "Trinidad and Tobago",
//     phone: "1-868",
//   },
//   { value: "TV", label: "Tuvalu", phone: "688" },
//   {
//     value: "TW",
//     label: "Taiwan, Republic of China",
//     phone: "886",
//   },
//   {
//     value: "TZ",
//     label: "United Republic of Tanzania",
//     phone: "255",
//   },
//   { value: "UA", label: "Ukraine", phone: "380" },
//   { value: "UG", label: "Uganda", phone: "256" },
//   {
//     value: "US",
//     label: "United States",
//     phone: "1",
//     suggested: true,
//   },
//   { value: "UY", label: "Uruguay", phone: "598" },
//   { value: "UZ", label: "Uzbekistan", phone: "998" },
//   {
//     value: "VA",
//     label: "Holy See (Vatican City State)",
//     phone: "379",
//   },
//   {
//     value: "VC",
//     label: "Saint Vincent and the Grenadines",
//     phone: "1-784",
//   },
//   { value: "VE", label: "Venezuela", phone: "58" },
//   {
//     value: "VG",
//     label: "British Virgin Islands",
//     phone: "1-284",
//   },
//   {
//     value: "VI",
//     label: "US Virgin Islands",
//     phone: "1-340",
//   },
//   { value: "VN", label: "Vietnam", phone: "84" },
//   { value: "VU", label: "Vanuatu", phone: "678" },
//   { value: "WF", label: "Wallis and Futuna", phone: "681" },
//   { value: "WS", label: "Samoa", phone: "685" },
//   { value: "XK", label: "Kosovo", phone: "383" },
//   { value: "YE", label: "Yemen", phone: "967" },
//   { value: "YT", label: "Mayotte", phone: "262" },
//   { value: "ZA", label: "South Africa", phone: "27" },
//   { value: "ZM", label: "Zambia", phone: "260" },
//   { value: "ZW", label: "Zimbabwe", phone: "263" },
// ];

import { Box, Button, Grid, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Radio, RadioGroup, FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import React, { useState, useEffect } from "react";
import Port from "../../src/assets/img/profile/port2.png";
import "./portfolio.css";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import secondProtFolio from "../../src/assets/img/profile/secondProtFolio.png";
import portFoliothree from "../../src/assets/img/portFoliothree.png";
import ContactTable from "../components/contactTable/index";
import { LoadingButton } from "@mui/lab";

const portfolioData = [
  {
    portfolioNo: 1,
    image: Port,
    path: `/creative-professionals`,
    price: 1999,
  },
  {
    portfolioNo: 2,
    image: secondProtFolio,
    path: `/creative-professionals-second`,
    price: 1999,
  },
  {
    portfolioNo: 3,
    image: portFoliothree,
    path: `/creative-professionals-third`,
    price: 1999,
  },
];

const Portfolio = () => {
  const navigate = useNavigate();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [datafind, setDatafind] = useState();
  const [purchasedPortfolio, setPurchasedPortfolio] = useState([]);
  const [walletBalance, setWalletBalance] = useState(0);
  const [useWallet, setUseWallet] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('online');
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow((prevShow) => !prevShow);
  };
  const loginToken = JSON.parse(localStorage.getItem("login"));
  const token = loginToken?.token;
  const userid = loginToken?.user._id;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleCheckboxChange = (event) => {
    setUseWallet(event.target.checked);
    if (event.target.checked) {
      setTotalAmount(Math.max(totalAmount - walletBalance, 0));
    } else {
      setTotalAmount(portfolioData?.price || 0);
    }
  };

  const toggleSidebar = () => {
    setOpenSidebar((p) => !p);
  };

  const HandlePortfolioPreview = (path) => {
    navigate(`${path}/${datafind?.slug}`);
  };
  const HandlePortfolioPreviewsample = (path) => {
    navigate(`${path}/deliver-my-tune`);
  }
  const getOrder = async () => {
    await axios
      .get(`${process.env.REACT_APP_DMT_API_PATH}/creative/check/${userid}`)
      .then((res) => {
        if (!res?.data?.error) {
          setPurchasedPortfolio(
            res?.data?.data?.map((porfolio) => porfolio?.portfoliono) || []
          );
          setDatafind(res?.data?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPortfolioPrices = async () => {
    await axios
      .get(`${process.env.REACT_APP_DMT_API_PATH}/creative/prices`)
      .then((res) => {
        if (!res?.data?.error) {
          portfolioData?.map((data) => {
            const priceData = res?.data?.data?.find(
              (price) => price.portfolioNo === data.portfolioNo
            );
            data.price = priceData?.price || 0;
          });
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const fetchWalletBalance = async () => {
    await axios
      .get(`${process.env.REACT_APP_DMT_API_PATH}/wallet/?user_id=${userid}`, config)
      .then((res) => {
        setWalletBalance(res.data.data.total[0].total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrder();
    getPortfolioPrices();
    fetchWalletBalance();
  }, []);

  const handlePurchaseClick = (portfolio) => {
    setTotalAmount(portfolio.price);
    setModalOpen(true);
  };

  const handlePaymentOptionChange = (event) => {
    setSelectedPaymentOption(event.target.value);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalSubmit = async () => {
    setModalOpen(false);
    const selectedPortfolio = portfolioData.find(portfolio => portfolio.price === totalAmount);

    var formDatavalue = new FormData();
    formDatavalue.append("title", loginToken?.user?.role);
    formDatavalue.append("name", loginToken?.user?.fullName);
    formDatavalue.append("portfoliono", selectedPortfolio.portfolioNo);
    formDatavalue.append("description", loginToken?.user?.profileDescription);
    formDatavalue.append("banner", loginToken?.user?.profilePicture);
    formDatavalue.append("paymentMethod", selectedPaymentOption);

    formDatavalue.append(
      "autograph",
      "https://delivermytunedmt.s3.us-west-2.amazonaws.com/banner/9546bd1f1e82daa8_sign.png"
    );

    if (selectedPaymentOption === 'wallet-online') {
      const orderResponse = await axios.post(
        `${process.env.REACT_APP_DMT_API_PATH}/creative/create-walletonline-order`,
        {
          portfolioNo: selectedPortfolio.portfolioNo,
          userId: userid,
          walletBalance: walletBalance
        },
        config
      );
    
      const orderAmount = orderResponse.data.data.amount / 100;
    
      const options = {
        key: "rzp_live_UOZD55VDM3YeQI",
        amount: orderAmount,
        currency: "INR",
        name: "DELIVER MY TUNE",
        description: "RazorPay",
        order_id: orderResponse.data.data.razorpayOrderId,
        handler: async function (response) {

          formDatavalue.append("razorpay_payment_id", response.razorpay_payment_id);
          formDatavalue.append("razorpay_order_id", response.razorpay_order_id);
          formDatavalue.append("razorpay_signature", response.razorpay_signature);
          try {
            const res = await axios.post(
              `${process.env.REACT_APP_DMT_API_PATH}/creative`,
              formDatavalue,
              {
                headers: {
                  "content-type": "multipart/form-data",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
    
            if (res.data.error) {
              toast.error(res.data.message);
            } else {
              toast.success("Portfolio successfully added to your account");
              navigate(`${selectedPortfolio.path}/${res?.data?.data?.slug}`);
            }
          } catch (err) {
            toast.error(err.message);
          }
        },
        theme: {
          color: "#121212",
        },
      };
    
      const razor = new window.Razorpay(options);
      razor.open();
    }
    
    else {
      const orderResponse = await axios.post(
        `${process.env.REACT_APP_DMT_API_PATH}/creative/create-order`,
        {
          portfolioNo: selectedPortfolio.portfolioNo,
          userId: userid,
        },
        config
      );

      if (orderResponse.data.error !== true) {
        const razorpayOrderId = orderResponse.data.data.razorpayOrderId;
        const orderAmount = (orderResponse.data.data.amount) / 100;

        if (selectedPaymentOption === 'wallet' && walletBalance >= orderAmount) {
          formDatavalue.append("wallet_used", true);
          formDatavalue.append("wallet_amount", orderAmount);
          const payload2 = {
            portfolioNo: selectedPortfolio.portfolioNo,
            amount: orderAmount,
            userId: loginToken?.user._id,
            paymentId: orderResponse.data.data._id
          };

          await axios
            .post(
              `${process.env.REACT_APP_DMT_API_PATH}/creative/deduct-wallet`,
              payload2,
              config
            )
            .then((res) => {
              if (res.data.error !== true) {
                toast.success("Wallet payment successful");
                console.log(res.data)
                formDatavalue.append("razorpay_payment_id", res.data.data.paymentdata);
                formDatavalue.append("razorpay_order_id", res.data.data.razorpayOrderId);
                axios.post(
                  `${process.env.REACT_APP_DMT_API_PATH}/creative`,
                  formDatavalue,
                  {
                    headers: {
                      "content-type": "multipart/form-data",
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                  .then((res) => {
                    if (res.data.error) {
                      toast.error(res.data.message);
                    } else {
                      toast.success("Portfolio successfully added to your account");
                      navigate(`${selectedPortfolio.path}/${res?.data?.data?.slug}`);
                    }
                  })
                  .catch((err) => {
                    toast.error(err.message);
                  });
              } else {
                toast.error(res.data.message);
              }
            })
            .catch((err) => {
              toast.error(err.message);
            });
        }
        else {
          const options = {
            key: "rzp_live_UOZD55VDM3YeQI",
            amount: orderAmount,
            currency: "INR",
            name: "DELIVER MY TUNE",
            description: "RazorPay",
            order_id: razorpayOrderId,
            handler: async function (response) {
              formDatavalue.append("razorpay_payment_id", response.razorpay_payment_id);
              formDatavalue.append("razorpay_order_id", response.razorpay_order_id);
              formDatavalue.append("razorpay_signature", response.razorpay_signature);

              await axios
                .post(
                  `${process.env.REACT_APP_DMT_API_PATH}/creative`,
                  formDatavalue,
                  {
                    headers: {
                      "content-type": "multipart/form-data",
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((res) => {
                  if (res.data.error) {
                    toast.error(res.data.message);
                  } else {
                    toast.success("Portfolio successfully added to your account");
                    navigate(`${selectedPortfolio.path}/${res?.data?.data?.slug}`);
                  }
                })
                .catch((err) => {
                  toast.error(err.message);
                });
            },
            theme: {
              color: "#121212",
            },
          };
          const razor = new window.Razorpay(options);
          razor.open();
        }
      } else {
        toast.error("Something Went Wrong");
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container">
        <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
        <div className="main-container">
          <Navbar toggleSidebar={toggleSidebar} />
          <Box marginTop="60px" marginLeft={{ md: "30px", xs: "0px" }}>
            <Grid container spacing={{ md: 4, xs: 2 }} sx={{ mb: 4 }}>
              {portfolioData?.map((portfolio, index) => {
                return (
                  <Grid key={portfolio.portfolioNo} item lg={4} sm={6} xs={12} className="main-port-box">
                    <Box
                      sx={{
                        position: "relative",
                        height: "250px",
                        borderRadius: "16px",
                      }}
                    >
                      <img
                        src={portfolio.image}
                        alt=""
                        loading="lazy"
                        width="100%"
                        height="100%"
                      />
                    </Box>

                    <Box textAlign="center">
                      {purchasedPortfolio?.includes(portfolio.portfolioNo) ? (
                        <LoadingButton
                          className="prrviewbtn"
                          onClick={() => HandlePortfolioPreview(portfolio.path)}
                        >
                          Preview
                        </LoadingButton>
                      ) : (
                        <Box sx={{ textAlign: 'center' }}>
                          <LoadingButton className="prrviewbtn" onClick={() => handlePurchaseClick(portfolio)}>
                            Purchase Now at ₹{portfolio.price}
                          </LoadingButton>
                          <LoadingButton
                            className="prrviewbtn"
                            sx={{ marginLeft: "5px" }}
                            onClick={() => HandlePortfolioPreviewsample(portfolio.path)}
                          >
                            View Sample Preview
                          </LoadingButton>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            {
              purchasedPortfolio.length && (
                <>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button className="prrviewbtn" onClick={handleShow}>
                      {show === true ? "Hide Enquiries" : "Show Enquiries"}
                    </Button>
                  </Box>

                  <Box sx={{ mb: 45 }}>{show === true ? <ContactTable /> : ""}</Box>
                </>
              )
            }

            <ToastContainer />
          </Box>
        </div>
      </div>

      {/* Payment Modal */}
      <Dialog open={modalOpen} onClose={handleModalClose} PaperProps={{ style: { borderRadius: 16 } }}>
        <DialogTitle style={{ backgroundColor: '#2196f3', color: '#fff', textAlign: 'center' }}>
          Choose Payment Method
        </DialogTitle>
        <DialogContent style={{ padding: '20px', textAlign: 'center' }}>
          <DialogContentText style={{ marginBottom: '20px' }}>
            Your total amount is ₹{totalAmount}. Please choose a payment method.
          </DialogContentText>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="payment-option"
              name="payment-option"
              value={selectedPaymentOption}
              onChange={handlePaymentOptionChange}
            >
              <FormControlLabel value="online" control={<Radio />} label="Online Payment" />
              {walletBalance > 0 && totalAmount <= walletBalance && (
                <FormControlLabel value="wallet" control={<Radio />} label={`Wallet Payment (₹${walletBalance})`} />
              )}
              {walletBalance > 0 && totalAmount > walletBalance && (
              <FormControlLabel value="wallet-online" control={<Radio />}     label={`Wallet and Online Payment (Wallet Balance: ₹${walletBalance})`}              />
            )}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions style={{ padding: '20px', justifyContent: 'center' }}>
          <Button variant="outlined" style={{ color: '#2196f3', marginRight: '10px' }} onClick={handleModalClose}>
            Cancel
          </Button>
          <Button variant="contained" style={{ backgroundColor: '#2196f3', color: '#fff' }} onClick={handleModalSubmit}>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>


    </>
  );
};

export default Portfolio;

import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Box,
  Typography,
  Card,
  Divider,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "../faqPage/FaqCss.css";
import ReactPlayer from "react-player";

function NewFaq() {
  const [expandOne, setExpandOne] = useState(false);
  const handleChange = (isExpanded, pannel) => {
    setExpandOne(isExpanded ? pannel : false);
  };
  return (
    <Stack id="master-stack">
      <Box id="master-box">

        {/* <Typography id="card-out-heading">GENERAL QUESTIONS</Typography> */}

        <Card elevation={24} sx={{ boxShadow: "2px 15px 40px 5px gray" }}>
          <Box id="accordian-container">

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel1"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel1")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  Does DeliverMyTune provide Content ID?
                </Typography>
                <Divider />
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withTextandList">
                  Absolutely! When your song is released on YouTube Music
                  through DeliverMyTune, Content ID is automatically allocated
                  to it.
                  <br />
                 
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel2"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel2")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  How do I get my song linked to my Instagram profile?
                </Typography>
                <Divider />
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withTextandList">
                  Linking your song to your Instagram profile is easy! To get
                  started, make sure you compulsorily fill all the below
                  mentioned details at the time of uploading your song.
                  <br />
                  <br />
                  <ul id="accordianList">
                    <li>First name and Last name </li>
                    <li>Song Name</li>
                    <li>Label Name </li>
                    <li>Registered Facebook Email ID</li>
                    <li>Registered Instagram Email ID</li>
                    <li>
                      Facebook Artists Page Link - Placeholder-
                      https://www.facebook.com/johndoe/
                    </li>
                    <li>
                      Instagram Artists Page Link - Placeholder-
                      https://www.instagram.com/john.doe/
                    </li>
                    <li>ISRC </li>
                  </ul>
                  <br />
                  <br />
                  By providing these details, we'll ensure your song is
                  seamlessly linked to your Instagram profile for all your fans
                  to enjoy!
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              id="accordian-parent"
              expanded={expandOne === "pannel3"}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, "pannel3")
              }
              disableGutters
            >
              <AccordionSummary
                id="Pannel1-heder"
                aria-controls="Pannel1-content"
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                <Typography id="accordiansummary-heading">
                  How can I add my new song to my existing Spotify account?
                </Typography>
                <Divider />
              </AccordionSummary>

              <AccordionDetails>
                <Typography id="accordiandetails-content-withTextandList">
                  Adding your new song to your existing Spotify account is
                  simple with DeliverMyTune! Just follow these simple steps:
                  <br />
                  <br />
                  <ul id="accordianList">
                    <li>
                      During the song upload process on DeliverMyTune, provide
                      your existing Spotify account link in the specified field.
                    </li>
                    <li>
                      Submit your song details, and we'll take care of the rest!
                    </li>
                  </ul>
                  <br />
                  <br />
                  By following these steps, your new song will be added to your existing Spotify account.
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Card>
      </Box>
    </Stack>
  );
}

export default NewFaq;

import React, { useEffect, useState } from 'react'
import {Box} from "@mui/material";
import Sidebar from '../components/sidebar/Sidebar'
import Navbar from '../components/Navbar/Navbar'
import ReferralTopCard from '../components/referrals/ReferralTopCard'
import axios from 'axios';
import { TableHeader } from '../components/referrals/TableHeader';
import ReferralTable from '../components/referrals/ReferralTable';
import ReferralCode from '../components/referrals/ReferralCode';

const Referrals = () => {
    const [openSidebar, setOpenSidebar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [coins , setCoins] = useState(0);
    const [count , setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const toggleSidebar = () => {
      setOpenSidebar((p) => !p);
    };
    const loginToken = JSON.parse(localStorage.getItem("login"));
    const token = loginToken?.token;
    const code = loginToken?.user?.referralCode;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setRows([]);
      };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    async function getReferrals() {
        const headers = {
            Authorization: `Bearer ${token}`
        };
        setIsLoading(true);
        await axios
          .get(`${process.env.REACT_APP_DMT_API_PATH}/users/coin-transactions?page=${page+1}&limit=${rowsPerPage}`, { headers })
          .then((res) => {
            const data = res?.data?.data;
            setCoins(data.totalCoins);
            setCount(data.count);
            setRows(data.transactions);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }
      useEffect(() => {
        getReferrals();
      }, [page , rowsPerPage]);

  return (
    <>
    <div className="container">
      <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
      <div className="main-container">
        <Navbar toggleSidebar={toggleSidebar}  />
        <div className="pages">
          <Box className='custom-dmt-container'>
            <ReferralTopCard totalCoins={coins}/>
            <ReferralCode
              code = {code}
            />
            <ReferralTable
                count = {count}
                HandleChangePage = {handleChangePage}
                OnRowsPerPageChange={handleChangeRowsPerPage}
                page= {page}
                rowsPerPage = {rowsPerPage}
                columns = {TableHeader}
                rows = {rows}
                isLoading = {isLoading}
            />
          </Box>
        </div>
      </div>
    </div>
  </>
  )
}

export default Referrals